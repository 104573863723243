import {Injectable} from '@angular/core';
import {EquityChartCommandSenderService} from '@common/communication/command-sender/equity-chart-command-sender.service';
import {EquityChartResolution} from '@common/shared/services/equity-chart/equity-chart-resolution';
import {LineData, UTCTimestamp} from 'lightweight-charts';
import {Accounting} from '@common/trader/models/accounting.service';

const ONE_DAY_IN_MLS = 1000 * 60 * 60 * 24;
const ONE_HOUR_IN_MLS = 1000 * 60 * 60;

export interface EquityChartDataEntry {
  value: number;
  dateTime: Date;
}

export interface EquityChartData {
  chartData: EquityChartDataEntry[];
}

interface EquityChartDataMap {
  [key: number]: EquityChartData;
}

interface ResolutionsLastUpdateMap {
  [key: number]: number;
}

@Injectable({
  providedIn: 'root'
})
export class EquityChartService {

  private _isInitialized = false;

  private _chartDataCache: EquityChartDataMap = {};

  private _chartData: EquityChartData;
  private _lastUpdateTime: ResolutionsLastUpdateMap = {};

  constructor(private equityChartCommandSender: EquityChartCommandSenderService,
              private accounting: Accounting) {
    this.resetLastUpdateTime();
  }

  public async refreshAll(beginDate: Date) {
    const resolutions = [1, 2, 3, 4, 5];

    for (const r of resolutions) {
      await this.refreshResolution(<EquityChartResolution> r, beginDate);
    }

    this._isInitialized = true;
  }

  public async refreshResolution(resolution: EquityChartResolution, beginDate: Date) {
    const dataStr = await this.equityChartCommandSender.getEquityChartData(resolution, beginDate);

    const data = this.parse(dataStr);

    this._chartDataCache[<number> resolution] = data;
  }

  public async getForResolution(resolution: EquityChartResolution, beginDate: Date) {
    if (!this._isInitialized) {
      await this.refreshAll(beginDate);
    }

    return this._chartDataCache[+resolution];
  }

  private parse(str: string) {
    return <EquityChartData> JSON.parse(str);
  }

  public async getLineData(resolution: EquityChartResolution, beginDate: Date): Promise<LineData[]> {
    await this.updateCache(resolution, beginDate);

    if (this._chartData !== undefined && this._chartData.chartData.length > 0) {
      console.log('beginDate', 23, beginDate);
      console.log('last chartData dateTime', 23, this._chartData.chartData[this._chartData.chartData.length - 1].dateTime);
    }

    const lineData = this._chartData.chartData
      .filter((equityChartData) => {
        if (new Date(equityChartData.dateTime).getTime() >= beginDate.getTime()) {
          return equityChartData;
        }
      })
      .map<LineData>((data) => {
        const a = (new Date(data.dateTime).valueOf() / 1000) as UTCTimestamp;
        const b = data.value;
        return {time: a, value: b};
      });

    const currentTime = (new Date(Date.now()).valueOf() / 1000) as UTCTimestamp;
    lineData.push({time: currentTime, value: this.accounting.Equity});

    return lineData;
  }

  private async updateCache(resolution: EquityChartResolution, beginDate: Date): Promise<void> {
    if (this._lastUpdateTime[<number> resolution] !== null) {
      if (resolution === EquityChartResolution.OneDay || resolution === EquityChartResolution.OneWeek) {
        if (Date.now() - this._lastUpdateTime[<number> resolution] > ONE_HOUR_IN_MLS) {
          await this.refreshResolution(resolution, beginDate);
          this._lastUpdateTime[<number> resolution] = Date.now();
        }
      } else {
        if (Date.now() - this._lastUpdateTime[<number> resolution] > ONE_DAY_IN_MLS) {
          await this.refreshResolution(resolution, beginDate);
          this._lastUpdateTime[<number> resolution] = Date.now();
        }
      }
      this._chartData = this._chartDataCache[<number> resolution];
    } else {
      await this.firstLoadCache(resolution, beginDate);
      for (let i = <number> resolution; i >= 1; i--) {
        this._lastUpdateTime[i] = Date.now();
      }
    }
  }

  private async firstLoadCache(resolution: EquityChartResolution, beginDate: Date): Promise<void> {
    this._isInitialized = false;
    this._chartData = await this.getForResolution(resolution, beginDate);
    console.log('this._chartDataCache', 23, this._chartDataCache);
  }

  public clear(): void {
    this._chartDataCache = {};
    this._isInitialized = false;
    this._chartData = undefined;
    this.resetLastUpdateTime();
  }

  private resetLastUpdateTime(): void {
    for (let i = 1; i <= 5; i++) {
      this._lastUpdateTime[i] = null;
    }
  }
}
