import {ProtocolMediatorBase_CommandBase} from './protocol-mediator-base-command-base';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Notification_Disconnect} from '../classes.g';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';

export class ProtocolNotificationDisconnect extends ProtocolMediatorBase_CommandBase {
  public onMessageReceived(message: ProtoV2MessageBase) {
    if (message.MessageType === Notification_Disconnect.GetMessageType()) {
      super.disconnect(DisconnectionCodes.AccountAlreadyInUse);
    } else {
      super.onMessageReceived(message);
    }
  }
}
