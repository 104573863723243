

export class OperationsWithJsonObject {

  public static testJSON(text: string): boolean {
    if (typeof text !== 'string') {
      return false;
    }
    try {
      const json = JSON.parse(text);
      return (typeof json === 'object');
    } catch (error) {
      return false;
    }
  }
}
