import {Injectable} from '@angular/core';
import {Settings} from '../models/settings';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {Trader} from '@common/trader/models/trader';
import {SettingsRepository} from '@common/trader/services/settings.repository';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private logger = LoggerFactory.getLogger(this);

  private settings: Settings = new Settings();

  constructor(private settingsRepository: SettingsRepository, private trader: Trader) { }

  public get Settings(): Settings {
    return this.settings;
  }

  public async load(): Promise<Settings> {
    this.logger.debug('Load settings');

    const settings = await this.settingsRepository.load(this.trader);

    this.updateSettings(settings);

    return this.Settings;
  }

  public async save() {
    this.logger.debug('Save settings');
    await this.settingsRepository.save(this.trader, this.settings);
  }

  public getSettingsSnapshot(): Settings {
    const snapshot = new Settings();
    snapshot.AskLine = this.Settings.AskLine;
    snapshot.BidLine = this.Settings.BidLine;
    snapshot.OneClickTrading = this.Settings.OneClickTrading;
    snapshot.AlwaysShowDefaultView = this.Settings.AlwaysShowDefaultView;
    snapshot.RegularSLTP = this.Settings.RegularSLTP;
    snapshot.Sound = this.Settings.Sound;
    snapshot.TradesOnChart = this.Settings.TradesOnChart;
    snapshot.OneClickAmount = this.Settings.OneClickAmount;
    snapshot.OneClickSL = this.Settings.OneClickSL;
    snapshot.OneClickTP = this.Settings.OneClickTP;
    snapshot.OneClickTS = this.Settings.OneClickTS;
    snapshot.ChartCount = this.Settings.ChartCount;
    snapshot.CiqLayouts = this.Settings.CiqLayouts;
    snapshot.CiqPreferences = this.Settings.CiqPreferences;
    snapshot.CiqDrawings = this.Settings.CiqDrawings;
    snapshot.PendingOrderAccountRiskPercentage = this.Settings.PendingOrderAccountRiskPercentage;
    // snapshot.PendingOrderSlPips = this.Settings.PendingOrderSlPips;
    // snapshot.PendingOrderTpPips = this.Settings.PendingOrderTpPips;
    snapshot.UseAutoTradeSizing = this.Settings.UseAutoTradeSizing;
    snapshot.UseAdvancedVolume = this.Settings.UseAdvancedVolume;
    snapshot.TvLayout = this.Settings.TvLayout;
    snapshot.TvStudies = this.Settings.TvStudies;
    snapshot.ViewMode = this.Settings.ViewMode;
    snapshot.FavoriteSymbols = new Set<number>(this.Settings.FavoriteSymbols);
    snapshot.UseLotTrading = this.Settings.UseLotTrading;
    snapshot.OneClickLot = this.Settings.OneClickLot;

    return snapshot;
  }

  public updateSettings(settings: Settings) {
    this.settings.setFromModel(settings);
  }
}

