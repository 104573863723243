import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TradeService} from '@common/trade/services/trade.service';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {TradeType} from '@common/trade/models/trade-type';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {PendingOrderForm} from '@common/trade/components/pending-order-form/pending-order-form';
import {translate} from '@common/locale/servises/translator.service';
import {Router} from '@angular/router';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';
import {LocaleModule} from '@common/locale/locale.module';
import {NgForOf, NgIf} from '@angular/common';
import {SharedModule} from '@common/shared/shared.module';
import {TradeModule} from '@common/trade/trade.module';
import {SlTpInputComponent} from '@common/trade/components/sltp-input/sltp-input.component';
import {Trader} from '@common/trader/models/trader';
import {NotificationService} from '@common/notification/services/notification.service';
import {Settings} from '@common/trader/models/settings';

@Component({
  selector: 'app-modal-open-pending-order',
  templateUrl: './modal-open-pending-order.component.html',
  styleUrls: ['./modal-open-pending-order.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    ModalPlaceholderComponent,
    LocaleModule,
    NgForOf,
    ReactiveFormsModule,
    SharedModule,
    NgIf,
    TradeModule,
    SlTpInputComponent
  ],
  standalone: true
})
export class ModalOpenPendingOrderComponent extends PendingOrderForm implements OnInit {
  public Header: string = translate('Modal_PendingOrderFormComponent_Header');

  constructor(symbolStorage: SymbolStorageService,
              tradeStorage: TradeStorage,
              tradeService: TradeService,
              protected router: Router,
              trader: Trader,
              notificationService: NotificationService,
              settings: Settings) {
    super(tradeService, symbolStorage, tradeStorage, trader, notificationService, settings);
  }

  public onSymbolChange(v: number) {
    this.order.Symbol = this.Symbols[v];

    this.rate.setValue(this.CurrentRate);
    this.rate.updateValueAndValidity();

    this.subscribeToSymbol();
  }

  protected initOrder() {
    const order = new TradeDTO();

    order.Volume = 10000;

    if (!order.Symbol) {
      order.Symbol = this.Symbols[0];
    }

    order.Type = TradeType.Buy;

    order.OpenPrice = order.CurrentRate;

    this.order = order;
  }

  protected formInit() {
    super.formInit();
    this.pendingForm.setControl('symbol', new FormControl(this.getIndexOfSymbol(), Validators.compose([Validators.required])));

    this.subscribeToSymbol();
  }

  protected onSubmitSuccess() {
    this.close();
  }

  public close() {
    this.router.navigate(['/terminal', { outlet: { modal: null } }]).then();
  }
}
