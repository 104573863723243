import {LibrarySubsessionInfo,
  LibrarySymbolInfo,
  ResolutionString,
  SearchSymbolResultItem,
  SymbolInfoPriceSource,
  Timezone,
  VisiblePlotsSet
} from '@tradingview/charting_library';
import {SymbolCategory} from './symbol-category';
import {SupportedResolutions} from '../../charting/models/supported-resolutions';
import {SeriesFormat} from '@tradingview/charting_library/datafeed-api';

export class SymbolInfo implements LibrarySymbolInfo, SearchSymbolResultItem {
  // get category(): SymbolCategory {
  //   return this.category;
  // }

  public get symbol(): string {
    return this.name;
  }

  public get SymbolCategoryName(): string {
    return this.symbolCategoryName;
  }

  public name: string;
  public ticker: string;
  public base_name: [string];
  public currency_code: string;
  public data_status: 'streaming' | 'endofday' | 'delayed_streaming';
  public description: string;
  public exchange: string;
  public expiration_date: number;
  public expired: boolean;
  public force_session_rebuild: boolean;
  public fractional: boolean;
  public full_name: string;
  public has_daily: boolean;
  public has_empty_bars: boolean;
  public has_intraday: boolean;
  public has_no_volume: boolean;
  public has_seconds: boolean;
  public has_weekly_and_monthly: boolean;
  public industry: string;
  public intraday_multipliers: ResolutionString[];
  public listed_exchange: string;
  public minmov: number;
  public minmove2: number;
  public pricescale: number;
  public seconds_multipliers: string[];
  public sector: string;
  public session: string;
  public supported_resolutions: ResolutionString[];
  public timezone: Timezone;
  public type: string;
  public category: SymbolCategory;
  public volume_precision: number;
  public externalName: string;
  public symbolCategoryName: string;
  public format: SeriesFormat;

  constructor(name: string,
              baseName: string,
              fullName: string,
              description: string,
              decimalPlaces: number,
              type: SymbolCategory,
              externalName: string,
              symbolCategoryName: string) {
    this.name = name;
    this.base_name = [baseName];
    this.full_name = fullName;
    this.description = description;
    this.pricescale = Math.pow(10, decimalPlaces);
    this.type = SymbolInfo.resolveSymbolCategory(type);
    this.category = type;
    this.exchange = ''; // SymbolInfo.resolveExchange(); // TODO: Move exchange to a more appropriate place
    this.volume_precision = 0;
    this.timezone = 'Etc/UTC';
    this.supported_resolutions = SupportedResolutions.get();
    this.session = SymbolInfo.getSessionForCategory(type);
    this.seconds_multipliers = ['5'];
    this.data_status = 'streaming';
    this.expired = false;
    this.force_session_rebuild = true;
    this.fractional = false;
    this.has_daily = true;
    this.has_empty_bars = false;
    this.has_intraday = true;
    this.has_no_volume = false;
    this.has_seconds = true;
    this.has_weekly_and_monthly = true;
    this.intraday_multipliers = ['1', '5', '15', '30', '60', '240'] as ResolutionString[];
    this.minmov = 1;
    this.minmove2 = 0;
    this.externalName = externalName;
    this.symbolCategoryName = symbolCategoryName;
    // this.session_display =   '0900-1400:2|1100-2230|0800-1200:17';
    // this.session_display =   '0900-1200,1512-2047:17';
  }

  long_description?: string;
  session_display?: string;
  session_holidays?: string;
  corrections?: string;
  variable_tick_size?: string;
  has_ticks?: boolean;
  daily_multipliers?: string[];
  weekly_multipliers?: string[];
  monthly_multipliers?: string[];
  visible_plots_set?: VisiblePlotsSet;
  delay?: number;
  original_currency_code?: string;
  unit_id?: string;
  original_unit_id?: string;
  unit_conversion_types?: string[];
  subsession_id?: string;
  subsessions?: LibrarySubsessionInfo[];
  price_source_id?: string;
  price_sources?: SymbolInfoPriceSource[];
  logo_urls?: [string] | [string, string];
  exchange_logo?: string;

  public static resolveSymbolCategory(symbolCategory: SymbolCategory): string {
    switch (+symbolCategory) {
      case SymbolCategory.DollarPairs:
      case SymbolCategory.CrossPairs: return 'Forex';
      case SymbolCategory.CFDs: return 'CFD';
      case SymbolCategory.PreciousMetals: return 'Metal';
      case SymbolCategory.Cryptos: return 'Cryptos';
      case SymbolCategory.Equities: return 'Equities';
      case SymbolCategory.Energies: return 'Energies';
      case SymbolCategory.Futures: return 'Futures';
      case SymbolCategory.Indices: return 'Indices';
    }
  }

  public static getSessionForCategory(symbolCategory: SymbolCategory): string {
    return '24x7';
    // return '1430-2000';
    // return '0930-1400,1430-2000';
    // return '1715F-0300,0915-1200,1300-1630:3456|1715F3-0300F2,0915-1200,1300-1630:2';

    // switch (+symbolCategory) {
    //   case SymbolCategory.DollarPairs:
    //   case SymbolCategory.CrossPairs:
    //   case SymbolCategory.CFDs:
    //   case SymbolCategory.PreciousMetals: return '24x7'; // Sunday-Friday 17:00-17:00 EST/EDT
    // }
  }

  public static resolveExchange(): string {
    return 'PrimeXM';
  }

  get SymbolType() {
    return this.type;
  }
}
