import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LocaleModule} from '@common/locale/locale.module';
import {SharedModule} from '@common/shared/shared.module';
import {RouterModule} from '@angular/router';
import {TradeModule} from '@common/trade/trade.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ModalChangesSavingConfirmationComponent} from '@common/modals/components/modal-changes-saving-confirmation/modal-changes-saving-confirmation.component';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';
import {ModalChangePasswordComponent} from '@common/modals/components/modal-change-password/modal-change-password.component';


@NgModule({
  declarations: [
    ModalChangesSavingConfirmationComponent,
  ],
  imports: [
    LocaleModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    TradeModule,
    RouterModule,
    DragDropModule,
    ModalChangePasswordComponent,
    ModalPlaceholderComponent,
  ],
  exports: [
    ModalChangesSavingConfirmationComponent,
  ]
})
export class ModalModule { }
