import {Injectable} from '@angular/core';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {LocalStorageService} from './local-storage.service';
import {ServerInteractionService} from '../../communication/connection/server-interaction.service';
import {ConnectionState} from '@common/communication/connection/connection.state';
import {Timer} from '@common/common/utils/timer';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {Answer_LoginSuccess} from '@common/communication/connection/classes.g';
import {PortfolioService} from '@common/portfolio/portfolio.service';
import {TradingViewItemHistoryConnectionService} from '@common/trading-view-charts/services/trading-view-item-history-connection.service';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private logger = LoggerFactory.getLogger('AuthenticationService');

  private logoutTimer: Timer;
  public isAnswerLoginSuccessReceived = false;

  public get IsConnecting(): boolean {
    return this.serverConnection.ConnectionState === ConnectionState.Connecting;
  }

  public get IsReconnecting(): boolean {
    return this.serverConnection.ConnectionState === ConnectionState.Reconnecting;
  }

  public get IsConnected(): boolean {
    return this.serverConnection.ConnectionState === ConnectionState.Connected;
  }

  private sessionErrorEvent: SmartEmitter<number> = new SmartEmitter<number>();
  public get LoginError(): ISmartObserver<number> {
    return this.sessionErrorEvent;
  }

  private loginSuccessEvent: SmartEmitter<Answer_LoginSuccess> = new SmartEmitter<Answer_LoginSuccess>();
  public get LoginSuccess(): ISmartObserver<Answer_LoginSuccess> {
    return this.loginSuccessEvent;
  }

  public constructor(private serverConnection: ServerInteractionService,
                     private tradingViewItemHistoryConnectionService: TradingViewItemHistoryConnectionService,
                     private portfolioService: PortfolioService,
                     private usersInSystemsService: UsersInSystemsService,
                     private itemHistoryService: TradingViewItemHistoryConnectionService) {
    LocalStorageService.SessionUpdated.subscribe(() => this.setLogOutTimer());
  }

  private setLogOutTimer() {
    if (this.logoutTimer) {
      this.logoutTimer.remove();
    }
    this.logger.debug('Reset Timer');
    // this.logoutTimer = new Timer(() => this.logout(), 15 * 60 * 1000).start();
  }
  public async loginByAccessToken(token: string, login: string) {
    this.logger.debug('Login by token');
    await this.serverConnection.loginByAccessToken(token, login);
  }
  public async login(login: string, password: string) {
    this.logger.debug('Login by credentials');
    await this.serverConnection.login(login, password);
  }
  public async loginByTokenLink(token: string) {
    this.logger.debug('Login by guid link');
    await this.serverConnection.loginByTokenLink(token);
  }
  public abortConnection() {
    this.serverConnection.abortConnection();
  }
  public async logout() {
    this.logger.debug('Logout');
    this.isAnswerLoginSuccessReceived = false;
    this.portfolioService.destroyTraderChallengeEODSnapshot();

    if (!this.tradingViewItemHistoryConnectionService.IsUseNewItemHistoryConnect) {
      this.tradingViewItemHistoryConnectionService.stopConnect();
    }

    await this.serverConnection.logout();
  }

  public async sendLogout() {
    this.logger.debug('Sending logout');
    await this.serverConnection.softDisconnect();
  }

  public onLoginError(code: number) {
    this.sessionErrorEvent.emit(code);
  }
  public onLoginSuccess(answer: Answer_LoginSuccess) {
    this.isAnswerLoginSuccessReceived = true;
    this.loginSuccessEvent.emit(answer);
  }

  public saveUserInSystems(login: string, password: string, staySignedIn: boolean): void {
    this.usersInSystemsService.saveUserInSystems(login, password, staySignedIn);
  }

  public clearUsersInSystem(traderName: string): void {
    this.usersInSystemsService.clearUsersInSystem(traderName);
  }
}
