import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {Environment} from '@common/environment';

if (Environment.IsProduction) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  if ('serviceWorker' in navigator && Environment.IsProduction) {
    navigator.serviceWorker.register('./ngsw-worker.js');
  }
}).catch(err => console.log(err));
