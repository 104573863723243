import {Injectable} from '@angular/core';
import {SettingsService} from '@common/trader/services/settings.service';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {LayoutMode, LayoutService} from '@desktop-core/terminal/services/layout.service';
import {TvChartService} from '@common/trading-view-charts/services/tv-chart.service';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {ChartService} from '@common/charting/services/chart.service';
import {LocaleEnum} from '@common/locale/servises/locale';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {SavedViewCommandSender} from '@common/communication/command-sender/saved-view-command-sender';
import {Trader} from '@common/trader/models/trader';
import {Answer_GetAllSavedViews} from '@common/communication/connection/classes.g';
import {AppConfig} from '@common/configuration/app-config';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {ViewMode} from '@common/trader/models/view-mode.enum';
import {SessionStorageService} from '@common/auth/services/session-storage.service';
import {Environment} from '@common/environment';
import {parse, stringify} from 'zipson';
import {OperationsWithJsonObject} from '@common/trade/utils/operations-with-json-object';
import {DrawingEventType} from '@tradingview/charting_library';

export interface ShowSymbols {
  showForex: boolean;
  showCFD: boolean;
  showMetals: boolean;
  showCryptos: boolean;
  showEquities: boolean;
  showEnergies: boolean;
  showFutures: boolean;
  showIndices: boolean;
}

export interface PlatformData {
  locale: LocaleEnum;
  layoutMode: LayoutMode;
  showFullMarketDepth: boolean;
  favoritesSymbols: number[];
  showSymbols: ShowSymbols;
}

export interface ChartData {
  tvLayout: object;
  priceDirection: PriceDirection;
  // tvStudies: object;
}

export interface PlatformSavedDataSettings {
  isFavoriteView: boolean;
  platformData: PlatformData;
  charts: ChartData;
  saveTheme: string;
  saveInterval: string;
  saveAskBidLine: boolean;
  viewName?: string;
}

export interface PlatformSavedDataSettingsMap {
  [key: string]: PlatformSavedDataSettings;
}

export interface PlatformSavedDataSettingsMapAndFavoriteView {
  platformSavedDataSettingsMap: PlatformSavedDataSettingsMap;
  favoritesViews: string[];
}

@Injectable({
  providedIn: 'root'
})

export class SaveViewService {

  private logger = LoggerFactory.getLogger('SaveViewService');
  public _showNewSavedView = new SmartEmitter<PlatformSavedDataSettings>();
  private tvChartService: TvChartService;
  private readonly _savedViews: PlatformSavedDataSettingsMapAndFavoriteView | null = null;
  private _currentViewName: string | null = null;
  private _saveInterval: any = undefined;
  private _saveIntervalUsers: any = undefined;
  private _chartReadySub: ISmartSubscription;
  private _chartChangedSub: ISmartSubscription;
  private _chartDrawingSub: ISmartSubscription;
  private _saveIntervalDrawing: any = undefined;

  constructor(private layoutService: LayoutService,
              private symbolSelectService: SymbolSelectService,
              private chartService: ChartService,
              private settingsService: SettingsService,
              private translatorService: TranslatorService,
              private localStorageService: LocalStorageService,
              private savedViewCommandSender: SavedViewCommandSender,
              private trader: Trader,
              private appConfig: AppConfig,
              private serverInteractionService: ServerInteractionService,
              private sessionStorageService: SessionStorageService) {

    this.tvChartService = <TvChartService>this.chartService;

    if (!Environment.IsMobileVersion) {
      this.runChartReadySub();
      this.runChartChangedSub();
      this.runChartDrawingSub();
    }

    this._savedViews = {
      favoritesViews: [],
      platformSavedDataSettingsMap: {}
    };

    // периодическое сохранения настроек для графика в локале
    this.runSaveIntervalUsers();

    // this.initViewList();

    this.tvChartService.savedChartChangeTheme.subscribe(() => {
      this.savedViewDataUsers().then();
    });
  }

  public get SavedViews(): PlatformSavedDataSettingsMapAndFavoriteView {
    return this._savedViews;
  }

  public destroy() {
    if (this._saveInterval) {
      clearInterval(this._saveInterval);
      this._saveInterval = undefined;
    }

    if (this._saveIntervalUsers) {
      clearInterval(this._saveIntervalUsers);
      this._saveIntervalUsers = undefined;
    }

    if (this._chartReadySub) {
      this._chartReadySub.unsubscribe();
    }
    if (this._chartChangedSub) {
      this._chartChangedSub.unsubscribe();
    }

    if (this._chartDrawingSub) {
      this._chartDrawingSub.unsubscribe();
    }

    this.tvChartService.firstEnterMobile = true;
    this.tvChartService.TvWidgetChartReady = false;
  }

  public runChartReadySub() {
    this._chartReadySub = this.tvChartService.subscribeOnChartReady(async (chartId) => {
      await this.onChartReady(chartId);
    });
  }

  public runChartReadySubMobile() {
    this._chartReadySub = this.tvChartService.subscribeOnChartReady(async () => {
      await this.openViewsDefault();
    });
  }

  public runChartChangedSub() {
    this._chartChangedSub = this.tvChartService.OnChartChanged.subscribe(async () => {
      await this.onChartChanged();
    });
  }

  public runChartDrawingSub() {
    this._chartDrawingSub = this.tvChartService.OnDrawingEvent.subscribe(async (type: DrawingEventType) => {
      if (type ===  'remove' || type === 'hide' || type === 'show' || type === 'create' || type === 'properties_changed' || type === 'points_changed' ) {
        if (this._saveIntervalDrawing) {
          clearTimeout(this._saveIntervalDrawing);
          this._saveIntervalDrawing = undefined;
        }
        this._saveIntervalDrawing = setTimeout(() => {
          this.savedViewDataUsers().then();
          this._saveIntervalDrawing = undefined;
        }, 500);
      }
    });
  }

  private runSaveInterval() {
    this._saveInterval = setInterval(async () => {
      const checker = await this.hashSumMainViewChecker();
      if (checker) {
        await this.saveChartLayoutToSessionStorage();
      }
    }, this.appConfig.Settings.platformLayout.AutosaveInterval || 5000);
  }

  public runSaveIntervalUsers() {
    this._saveIntervalUsers = setInterval(() => this.savedViewDataUsers(), 5000);
  }

  public async loadView(nameView: string) {
    const viewsMap = this._savedViews.platformSavedDataSettingsMap;

    if (!viewsMap[nameView]) {
      return;
    }

    await this.loadViewByData(viewsMap[nameView]);

    this._currentViewName = nameView;

    this.sessionStorageService.setViewToOpen(nameView);
  }

  private async loadViewByData(data: PlatformSavedDataSettings) {
    const platformData = data.platformData;
    this.layoutService.setLayout(platformData.layoutMode);
    const favSymbolsArray = platformData.favoritesSymbols;
    await this.symbolSelectService.setFavorites(favSymbolsArray);
    // this.symbolSelectService.Favorites = new Set<number>(viewsMap[nameView].platformData.favoritesSymbols);
    this.layoutService.ShowFullMarketDepth = platformData.showFullMarketDepth || false;
    this.symbolSelectService.ShowForex = platformData.showSymbols.showForex;
    this.symbolSelectService.ShowCFD = platformData.showSymbols.showCFD;
    this.symbolSelectService.ShowMetals = platformData.showSymbols.showMetals;
    this.symbolSelectService.ShowCryptos = platformData.showSymbols.showCryptos;
    this.symbolSelectService.ShowEquities = platformData.showSymbols.showEquities;
    this.symbolSelectService.ShowEnergies = platformData.showSymbols.showEnergies;
    this.symbolSelectService.ShowFutures = platformData.showSymbols.showFutures;
    this.symbolSelectService.ShowIndices = platformData.showSymbols.showIndices;
    this.loadViewByDataCharts(data);
  }

  private async loadViewByDataMobile(data: PlatformSavedDataSettings) {
    const platformData = data.platformData;
    this.loadViewByDataCharts(data);
  }

  private loadViewByDataCharts(data: PlatformSavedDataSettings) {
    this.tvChartService.PriceDirection = data.charts.priceDirection;
    this.tvChartService.loadTvLayout(data.charts.tvLayout, data.saveTheme, data.saveInterval, data.saveAskBidLine, data.viewName);
  }

  public async saveView(viewName: string) {
    const data = await this.mainViewDataCompose(viewName);
    this._savedViews.platformSavedDataSettingsMap[viewName] = data;
    let dataString = '';
    let dataStringLocalStorage = '';

    try {
      dataString = stringify(data);
      dataStringLocalStorage = JSON.stringify(data);
    } catch (ex) {
      this.logger.error('error while stringify data: ', ex);
      return;
    }

    this._currentViewName = viewName;
    this._showNewSavedView.emit(data);
    await this.saveViewInternal(viewName, dataString);
    this.localStorageService.setSavedViewData(dataStringLocalStorage);
  }

  public async deleteView(viewName: string) {
    this._savedViews[viewName] = undefined;
    delete this._savedViews[viewName];

    await this.saveViewInternal(viewName, '');
  }

  public async setFavorite(viewName: string) {
    this._savedViews.platformSavedDataSettingsMap[viewName].isFavoriteView = true;
    await this.saveAllInternal();
  }

  public async deleteFavorite(viewName: string) {
    this._savedViews.platformSavedDataSettingsMap[viewName].isFavoriteView = false;
    await this.saveAllInternal();
  }

  private async onChartChanged() {
    this._currentViewName = null;
  }

  private async saveViewInternal(viewName: string, dataJson: string) {
    this.savedViewCommandSender.saveView(this.trader, viewName, dataJson);
  }

  private async saveAllInternal() {
    Object.keys(this._savedViews.platformSavedDataSettingsMap).forEach( (key) => {
      this.saveViewInternal(key, stringify(this._savedViews.platformSavedDataSettingsMap[key])).then();
    });
  }

  private composePlatformData(): PlatformData {
    return {
      layoutMode: this.layoutService.LayoutMode || LayoutMode.Default,
      showFullMarketDepth: this.layoutService.ShowFullMarketDepth,
      locale: this.translatorService.CurrentLocale,
      showSymbols: {
        showCFD: this.symbolSelectService.ShowCFD,
        showCryptos: this.symbolSelectService.ShowCryptos,
        showEnergies: this.symbolSelectService.ShowEnergies,
        showEquities: this.symbolSelectService.ShowEquities,
        showForex: this.symbolSelectService.ShowForex,
        showFutures: this.symbolSelectService.ShowFutures,
        showIndices: this.symbolSelectService.ShowIndices,
        showMetals: this.symbolSelectService.ShowMetals
      },
      favoritesSymbols: this.getFavoriteSymbolsIds()
    };
  }

  private async composeChartData(): Promise<ChartData> {
    const tvLayout = await this.tvChartService.getCurrentLayoutObject();

    return {
      tvLayout: tvLayout,
      priceDirection: this.tvChartService.PriceDirection
    };
  }

  private async composeChartTheme(): Promise<string> {
    return this.tvChartService.getThemeChart();
  }

  private async composeChartInterval(): Promise<string> {
    return this.tvChartService.getCurrentInterval();
  }

  private async composeAskBidLineChart(): Promise<boolean> {
    return this.tvChartService.getAskBidLineChart();
  }

  private getFavoriteSymbolsIds(): number[] {
    const result: number[] = [];

    this.symbolSelectService.Favorites.forEach(id => {
      result.push(id);
    });

    return result;
  }

  public async initViewList(): Promise<void> {
    const result = <Answer_GetAllSavedViews>await this.savedViewCommandSender.loadSavedViews(this.trader).toNativePromise();

    result.ViewList.forEach(view => {
      if (view.ViewData && view.ViewData !== '' && view.ViewData !== ' ') {
        let data: PlatformSavedDataSettings;

        if (OperationsWithJsonObject.testJSON(view.ViewData)) {
          data = <PlatformSavedDataSettings>JSON.parse(view.ViewData);
        } else {
          data = <PlatformSavedDataSettings>parse(view.ViewData);
        }

        this._savedViews.platformSavedDataSettingsMap[view.ViewName] = data;

        if (data.isFavoriteView) {
          this._savedViews.favoritesViews.push(view.ViewName);
        }
      }
    });
  }

  public openInNewTab(key: string, isClickUser: boolean, sum: number = 0): void {

    if (isClickUser) {
      this.createListOfTemplatesToDisplay([key]);
    }

   if (this.checkIsAllOpenTemplate(sum, isClickUser)) {
     this.localStorageService.setViewToOpen(key);
     window.open('/terminal', '_blank', 'width=max,height=max');
   }
  }

  private async checkTemplatesToDisplay(arrayNameTemplates: string[]) {
    const listTemplate: string[] = JSON.parse(this.localStorageService.getTemplatesToDisplay());
    if (listTemplate == null) {
      this.createListOfTemplatesToDisplay(arrayNameTemplates);
    } else {
      await this.loadView(listTemplate[0]);
      if (listTemplate.length > 1) {
        this.localStorageService.setTemplatesToDisplay(JSON.stringify(listTemplate.slice(1)));
      } else {
        this.localStorageService.clearTemplatesToDisplay();
      }
    }
  }

  private createListOfTemplatesToDisplay(arrayNameTemplates: string[]): void {
    this.localStorageService.setTemplatesToDisplay(JSON.stringify(arrayNameTemplates));
  }

  private checkIsAllOpenTemplate(sum: number, isClickUser: boolean): boolean {
    let countAllTemplateIsOpen: number = Number(this.localStorageService.getSumAllTemplateIsOpen());

    if (countAllTemplateIsOpen == null) {
      this.localStorageService.setSumAllTemplateIsOpen(1);
      return true;
    } else {
      if (!isClickUser) {
        if (countAllTemplateIsOpen < sum) {
          this.localStorageService.setSumAllTemplateIsOpen(++countAllTemplateIsOpen);
          return true;
        }
      } else {
        this.localStorageService.setSumAllTemplateIsOpen(++countAllTemplateIsOpen);
        return true;
      }
    }

    return false;
  }

  public async openFavoritesInNewTab() {
    const favorite = this.SavedViews.favoritesViews;
    if (favorite.length !== 0) {
      this.openViewsInNewTab(favorite);
    } else {
      if (!this.settingsService.Settings.AlwaysShowDefaultView) {
        const nameViewToOpen = Object.keys(this._savedViews.platformSavedDataSettingsMap)[0];
        await this.loadView(nameViewToOpen);
      }
    }
  }

  public openAllViewInNewTab(isClickUser: boolean = false): void {
    const layoutKeys = Object.keys(this.SavedViews.platformSavedDataSettingsMap);

    if (isClickUser) {
      this.localStorageService.clearSumAllTemplateIsOpen();
    }

    this.openViewsInNewTab(layoutKeys);
  }

  public openViewsInNewTab(views: string[]): void {
    const layoutKeys = views;
    if (layoutKeys.length !== 0) {

      this.checkTemplatesToDisplay(layoutKeys).then();

      if (this.localStorageService.getIsOpenOnlyOneWindow() !== null) {
        this.localStorageService.removeIsOpenOnlyOneWindow();
      } else {
        for (let i = 0; i < layoutKeys.length; i++) {
          setTimeout(() => {
            this.openInNewTab(layoutKeys[i], false, layoutKeys.length);
          }, i * 4000);
        }
      }
    }
  }

  public isOpenOnlyOneWindow() {
    if (this.settingsService.Settings.ViewMode !== ViewMode.OpenDefault) {
      this.localStorageService.setIsOpenOnlyOneWindow();
    }
  }

  private async onChartReady(chartId: string) {
    if (Environment.IsMobileVersion) {
      const autoSaveViewData = this.sessionStorageService.getViewDataToOpen();
      if (autoSaveViewData !== undefined && autoSaveViewData !== null) {
        await this.loadViewByData(JSON.parse(autoSaveViewData));
      }
    } else {
      if (this.serverInteractionService.getIsLogIn()) {
        const viewMode = +this.settingsService.Settings.ViewMode;
        if (this.settingsService.Settings.AlwaysShowDefaultView) {
          if (viewMode === ViewMode.OpenDefault) {
            await this.openViewsDefault();
          }
          if (viewMode === ViewMode.OpenAllFavorites) {
            await this.openFavoritesInNewTab();
          }
          if (viewMode === ViewMode.OpenAll) {
            this.openAllViewInNewTab();
          }
        } else {
          if (viewMode === ViewMode.OpenDefault) {
            await this.openViewsDefault();
          }
          if (viewMode === ViewMode.OpenAllFavorites) {
            if (this.SavedViews.favoritesViews.length !== 0) {
              await this.loadView(this._savedViews.favoritesViews[0]);
              if (this.SavedViews.favoritesViews.length > 1) {
                this.openViewsInNewTab(this.SavedViews.favoritesViews.slice(1));
              }
            } else {
              await this.loadView(Object.keys(this.SavedViews.platformSavedDataSettingsMap)[0]);
            }
          }
          if (viewMode === ViewMode.OpenAll) {
            await this.loadView(Object.keys(this.SavedViews.platformSavedDataSettingsMap)[0]);
            if (Object.keys(this.SavedViews.platformSavedDataSettingsMap).length > 1) {
              this.openViewsInNewTab(Object.keys(this.SavedViews.platformSavedDataSettingsMap).slice(1));
            }
          }
        }
      } else {
        let nameViewToOpen = this.localStorageService.getViewToOpen();
        if (nameViewToOpen !== undefined && nameViewToOpen !== null) {
          await this.loadView(nameViewToOpen);
          this.localStorageService.clearViewToOpen();
        } else {
          nameViewToOpen = this.sessionStorageService.getViewToOpen();
          if (nameViewToOpen !== undefined && nameViewToOpen !== null) {
            await this.loadView(nameViewToOpen);
            this.sessionStorageService.clearViewToOpen();
            await this.saveChartLayoutToSessionStorage();
          } else {
            try {
              let viewDataToOpen = this.getDataViewToOpen();
              if (viewDataToOpen !== undefined && viewDataToOpen !== null) {
                this.loadViewByData(JSON.parse(viewDataToOpen)).then();
              } else {
                viewDataToOpen = this.settingsService.Settings.TvLayout;
                if (viewDataToOpen.length > 0) {
                  await this.loadViewByData(JSON.parse(viewDataToOpen));
                }
              }

            } catch (e) {
            }

          }
        }
      }
    }
    if (!this._saveInterval) {
      this.runSaveInterval();
    }
  }

  private async mainViewDataCompose(viewName?: string ): Promise<PlatformSavedDataSettings> {
    const platformData = this.composePlatformData();
    const chartData = await this.composeChartData();
    const saveTheme = await this.composeChartTheme();
    const saveInterval = await this.composeChartInterval();
    const saveAskBidLine = await this.composeAskBidLineChart();

    return {
      platformData: platformData,
      charts: chartData,
      isFavoriteView: false,
      saveTheme: saveTheme,
      saveInterval: saveInterval,
      saveAskBidLine: saveAskBidLine,
      viewName: viewName
    };
  }

  private async hashSumMainViewChecker() {
    const data = await this.mainViewDataCompose();

    const dataStr = JSON.stringify(data);
    let hash = 0;
    for (let i = 0; i < dataStr.length; i++) {
      const char = dataStr.charCodeAt(i);
      // tslint:disable-next-line:no-bitwise
      hash = ((hash << 5) - hash) + char;
      // tslint:disable-next-line:no-bitwise
      hash = hash & hash;
    }

    if (hash !== this.localStorageService.getHashSumMainView()) {
      this.localStorageService.setHashSumMainView(hash);
      return true;
    } else {
      return false;
    }
  }

  private async saveChartLayout() {
    const data = await this.mainViewDataCompose();
    this.settingsService.Settings.TvLayout = JSON.stringify(data);
    await this.settingsService.save();
  }

  private async saveChartLayoutToSessionStorage() {
    const data = await this.mainViewDataCompose();
    let dataString = '';
    let viewDataUsers: any;
    const viewDataToOpen = this.sessionStorageService.getViewDataToOpen();

    if (viewDataToOpen == null) {
      viewDataUsers = {
        [this.trader.TraderId]: data,
      };
    } else {
      const dataLocalStorage = JSON.parse(viewDataToOpen);
      if (dataLocalStorage[this.trader.TraderId] === undefined) {
        viewDataUsers = {
          ...dataLocalStorage,
          [this.trader.TraderId]: data,
        };
      } else {
        delete dataLocalStorage[this.trader.TraderId];
        viewDataUsers = {
          ...dataLocalStorage,
          [this.trader.TraderId]: data,
        };
      }
    }

    try {
      dataString = JSON.stringify(viewDataUsers);
    } catch (ex) {
      this.logger.error('error while stringifying data: ', ex);
      return;
    }

    this.sessionStorageService.setViewDataToOpen(dataString);
  }

  public checkUsedName(nameView: string) {
    let res = true;
    const currentNames = Object.keys(this._savedViews.platformSavedDataSettingsMap);
    currentNames.forEach((curName) => {
      if (curName === nameView) {
        res = false;
      }
    });

    return res;
  }

  // метод для сохранения настроек графика пользователя в локале под свои трайдер ID в роле ключа
  private async savedViewDataUsers() {
    if (this._saveIntervalUsers !== undefined) {
      const data = await this.mainViewDataCompose();
      let dataString = '';
      let viewDataUsers: { [x: number]: PlatformSavedDataSettings; };
      const localStorageViewsDefault = this.localStorageService.getSavedViewData('savedViewDataUsers');

      if (localStorageViewsDefault == null) {
        viewDataUsers = {
          [this.trader.TraderId]: data,
        };
      } else {
        const dataLocalStorage = JSON.parse(localStorageViewsDefault);
        if (dataLocalStorage[this.trader.TraderId] === undefined) {
          viewDataUsers = {
            ...dataLocalStorage,
            [this.trader.TraderId]: data,
          };
        } else {
          delete dataLocalStorage[this.trader.TraderId];
          viewDataUsers = {
            ...dataLocalStorage,
            [this.trader.TraderId]: data,
          };
        }
      }

      try {
        dataString = JSON.stringify(viewDataUsers);
      } catch (ex) {
        this.logger.error('error while stringifying data: ', ex);
        return;
      }

      this.localStorageService.setSavedViewData(dataString, 'savedViewDataUsers');
      this.sessionStorageService.setViewDataToOpen(dataString);
    }

  }

  // загрузка дефолтных настроек графика
  public async openViewsDefault() {
    const viewsDefault = this.sessionStorageService.getViewDataToOpen() || this.localStorageService.getSavedViewData('savedViewDataUsers');
    if (viewsDefault !== null) {
      const data = JSON.parse(viewsDefault);
      if (data[this.trader.TraderId] !== undefined) {
        console.log('Default settings for charts', data[this.trader.TraderId]);
        if (Environment.IsMobileVersion) {
          await this.loadViewByDataMobile(data[this.trader.TraderId]);
        } else {
          await this.loadViewByData(data[this.trader.TraderId]);
          await this.checkTemplatesToDisplay([]);
        }
      }
    }
  }

  // загрузка дефолтных настроек графика из sessionStorage
  private getDataViewToOpen() {
    const dataViewToOpen = this.sessionStorageService.getViewDataToOpen();
    if (dataViewToOpen !== null) {
      const data = JSON.parse(dataViewToOpen);
      if (data[this.trader.TraderId] !== undefined) {
        console.log('Default settings for charts sessionStorage', data[this.trader.TraderId]);
        return data[this.trader.TraderId];
      } else {
        return undefined;
      }
    }
  }

  public getTvChartService() {
    return this.tvChartService;
  }
}
