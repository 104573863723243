import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';

export class Time {
  public readonly hours: number;
  public readonly minutes: number;

  public constructor(hours: number, minutes: number){
    this.hours = hours;
    this.minutes = minutes;
  }
}

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  private enterActivated: boolean;

  private amHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  private pmHours = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0];
  private minutes = Array(60).fill(0).map((x, i) => (i + 1) % 60);

  private hour;
  private minute;

  private timeSetEvent = new EventEmitter<Time>();

  @Output()
  get time(): Observable<Time>{
    return this.timeSetEvent.asObservable();
  }

  private mode: 'hours' | 'minutes' = 'hours';

  constructor() {
  }

  onMouseDown(){
    this.enterActivated = true;
  }

  public selectHour(hour: number){
    if(!this.enterActivated) return;
    this.selectHourByClick(hour);
  }

  public selectHourByClick(hour: number){
    this.Hour = hour;
  }

  public isHourSelected(hour: number): boolean {
    return this.hour == hour;
  }

  public selectMinute(minute: number){
    if(!this.enterActivated) return;
    this.selectMinuteByClick(minute);
  }

  public selectMinuteByClick(minute: number){
    this.Minute = minute;
  }

  public isMinuteSelected(minute: number): boolean {
    return this.minute == minute;
  }

  @HostListener('mouseup')
  onMouseUp(){
    if(!this.enterActivated) return;

    this.enterActivated = false;

    if(this.mode == 'hours'){
      this.setMinutesMode()
    }
  }

  ngOnInit() {
    const date = OperationsWithDate.getDateUTC();

    this.Hour = date.getHours();
    this.Minute = date.getMinutes();
  }

  public get Hour(): number {
    return this.hour;
  }

  public set Hour(hour: number) {
    this.hour = hour;

    this.timeSetEvent.emit(new Time(this.hour, this.minute));
  }

  public get Minute(): number {
    return this.minute;
  }

  public set Minute(minute: number) {
    this.minute = minute;

    this.timeSetEvent.emit(new Time(this.hour, this.minute));
  }

  public format(num: number){
    if(num < 10){
      return `0${num}`
    }
    else {
      return num.toString();
    }
  }

  public get AMHours(): Array<number> {
    return this.amHours;
  }

  public get PMHours(): Array<number> {
    return this.pmHours;
  }

  public get Minutes(): Array<number> {
    return this.minutes;
  }

  public get Mode(): 'hours' | 'minutes' {
    return this.mode;
  }

  public setHoursMode(){
    this.mode = 'hours';
  }

  public setMinutesMode(){
    this.mode = 'minutes';
  }
}
