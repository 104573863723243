import {ServerInteractionService} from '../connection/server-interaction.service';
import {Injectable} from '@angular/core';
import {Command_ChangeLoginPassword, Command_GetAuthTokens} from '../connection/classes.g';
import {ICommandPromise} from '../connection/command-answer-service';
import {AbstractCommandSender} from './abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {PasswordChangeData} from '@common/trader/models/password-change-data';

@Injectable({
  providedIn: 'root'
})
export class PasswordCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger(this);
  public constructor(serverConnection: ServerInteractionService){
    super(serverConnection);
  }

  public changePassword(data: PasswordChangeData): ICommandPromise {
    const command = new Command_ChangeLoginPassword();

    command.CurrentPassword = data.currentPassword;
    command.NewPassword = data.newPassword;

    return this.sendCommand(command);
  }
  public requestToken(): ICommandPromise {
    const command = new Command_GetAuthTokens();

    return this.sendMessage(command);
  }
}
