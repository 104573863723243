import {Component, HostListener, Input, OnInit} from '@angular/core';
import {LayoutMode, LayoutService} from '@desktop-core/terminal/services/layout.service';

@Component({
  selector: 'lib-layout-button',
  templateUrl: './layout-button.component.html',
  styleUrls: ['./layout-button.component.css']
})
export class LayoutButtonComponent implements OnInit {

  @Input() hiddenIconTopMenu: boolean;

  public get LayoutMode(): LayoutMode {
    return this.layoutService.LayoutMode;
  }

  public get ShowFullMarketDepth(): boolean {
    return this.layoutService.ShowFullMarketDepth;
  }
  public set ShowFullMarketDepth(value: boolean) {
    this.layoutService.ShowFullMarketDepth = value;
  }

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
  }

  defaultMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.defaultMode();
  }

  quotesMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.quotesMode();
  }

  positionsMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.positionsMode();
  }

  chartMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.chartMode();
  }

  positionsOnlyMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.positionsOnlyMode();
  }

  quotesAndPositionsMode($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.layoutService.quotesAndPositionsMode();

  }

  getIconClass(): any {
    return {
      'menu-layout-desktop': this.LayoutMode == null,
      'layout-default': this.LayoutMode == null || this.LayoutMode == LayoutMode.Default,
      'layout-positions': this.LayoutMode == LayoutMode.Positions,
      'layout-quotes': this.LayoutMode == LayoutMode.Quotes || this.LayoutMode == LayoutMode.QuotesAndPositions,
      'layout-chart': this.LayoutMode == LayoutMode.ChartOnly || this.LayoutMode == LayoutMode.PositionsOnly,
    };
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyboardKeyDown(event: KeyboardEvent) {


    if (event.ctrlKey) {
      if (event.key === '1') {
        event.preventDefault();
        this.layoutService.defaultMode();
      }
      if (event.key === '2') {
        event.preventDefault();
        this.layoutService.positionsMode();
      }
      if (event.key === '3') {
        event.preventDefault();
        this.layoutService.quotesMode();
      }
      if (event.key === '4') {
        event.preventDefault();
        this.layoutService.chartMode();
      }
      if (event.key === '5') {
        event.preventDefault();
        this.layoutService.positionsOnlyMode();
      }
      if (event.key === '6') {
        event.preventDefault();
        this.layoutService.quotesAndPositionsMode();
      }
    }
  }

  public  get IsHiddenIconTopMenu(): boolean {
    return this.hiddenIconTopMenu;
  }
}
