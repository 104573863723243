import {Symbol} from '@common/symbol/models/symbol';
import {IRolloverCalcSymbol} from '@common/shared/calculators/Rollover/IRolloverCalcSymbol.g';
import {ProfitCalcType} from '@common/trade/utils/calculations/profit-calc-type.enum';

export class RolloverCalcSymbol implements IRolloverCalcSymbol {
  private readonly _symbolModel: Symbol;

  constructor(symbolModel: Symbol) {
    this._symbolModel = symbolModel;
  }

  getRolloverBuy(): number {
    return this.SymbolModel.RolloverBuy;
  }

  getRolloverSell(): number {
    return this.SymbolModel.RolloverSell;
  }

  public get SymbolModel(): Symbol {
    return this._symbolModel;
  }
  getPipSize(): number {
    return this.SymbolModel.PipSize;
  }
  getContractSize(): number {
    return this.SymbolModel.ContractSize;
  }

  public getAsk(): number {
    return this.SymbolModel.LastQuote !== undefined ? this.SymbolModel.LastQuote.Ask : NaN;
  }

  public getBid(): number {
    return this.SymbolModel.LastQuote !== undefined ? this.SymbolModel.LastQuote.Bid : NaN;
  }

  getConversionToUSD(): number {
    if (this.SymbolModel.ProfitCalcType === ProfitCalcType.Direct) {
      return this.getAveragePrice(this.SymbolModel);
    }

    if ([ProfitCalcType.Indirect, ProfitCalcType.Indirect_index].includes(this.SymbolModel.ProfitCalcType) ) {
      return 1;
    }

    if ([ProfitCalcType.USDCC_USDBC, ProfitCalcType.BCUSD_USDCC, ProfitCalcType.CFD_USDCC, ProfitCalcType.CFD_USDCC_index].includes(this.SymbolModel.ProfitCalcType)) {
      const SymbolCC = this.SymbolModel.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'] as Symbol;
      return 1 / this.getAveragePrice(SymbolCC);
    }

    if ([ProfitCalcType.BCUSD_CCUSD, ProfitCalcType.CFD_CCUSD, ProfitCalcType.CFD_CCUSD_index].includes(this.SymbolModel.ProfitCalcType)) {
      const SymbolCC = this.SymbolModel.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'] as Symbol;
      return this.getAveragePrice(SymbolCC);
    }

    return 0;
  }

  public getAveragePrice(symbol: Symbol): number {
    if (symbol.LastQuoteInfo !== undefined) {
      return (symbol.Ask + symbol.Bid) / 2 ;
    }
    return 0;
  }
}
