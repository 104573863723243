import {Trade} from './trade';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {TradeSet} from './trade-set';

export class OpenTradeSet extends TradeSet {

  public constructor() {
    super();
    this._logger = LoggerFactory.getLogger('TradeSet');
  }

  protected tradeDeleteSideEffect(trade: Trade) {
    const tradeStored = this._tradesDict.get(trade.TradeId);
    if(tradeStored) {
      tradeStored.unsubscribeFromSymbol();
    }
  }

  public createOrUpdate(...trades: Trade[]): Trade[] {
    this._logger.debug('createOrUpdate');
    const diff = [];

    trades.forEach(trade => {
      const current = this._tradesDict.get(trade.TradeId);

      if (!current) {
        this._wasChanged = true;
        diff.push(trade);
      } else {
        this._tradesDict.get(trade.TradeId).unsubscribeFromSymbol();
        trade.UPL = current.UPL;
      }

      this._tradesDict.set(trade.TradeId, trade);
      this._tradesChanged.emit(this.Trades);
    });

    return diff;
  }

  public clear(): void {
    this.Trades.forEach(trade => {
      trade.unsubscribeFromSymbol();
    });

    super.clear();
  }

  protected sort(trades: Trade[]): Trade[] {
    trades.sort((a, b) => {
      if (a.TradeId < b.TradeId) { return 1; }
      if (a.TradeId === b.TradeId) { return 0; }
      if (a.TradeId > b.TradeId) { return -1; }
    });

    return trades;
  }
}
