import { Theme } from '../symbols';

export const tvZetradexDarkTheme: Theme = {
  name: 'tvZetradexDarkTheme',
  category: 'dark',
  properties: {
    '--input-background-color': '#111D26',
    '--input-background-color-0': '#0F1920',
    '--footer-background-color': '#111D26',
    '--main-page-light-color': '#0F1920',
    '--main-page-dark-color': '#111D26',
    '--blue-0': '#111D26',
    '--background-light': '#0F1920',
    '--background-light-2': '#0F1920',
    '--background': '#111D26',
    '--blue-1': '#111D26',
    '--blue-2': '#111D26',
    '--blue-3': '#15222B',
    '--blue-4': '#111D26',
    '--blue-5': '#0F1920',
    '--blue-6': '#9199B1',
    '--blue-9': '#ffffff',
    '--blue-10': '#767E9C',
    '--blue-13': 'rgba(33, 34, 34, 0.7)',
    '--blue-15': '#162936',
    '--blue-20': '#111D26',
    '--blue-22': '#002E4E',
    '--blue-23': '#9199B1',
    '--blue-25': '#9199B1',
    '--blue-26': '#002E4E',

    '--blue-24': '#162936',
    '--blue-7': '#77829c',
    '--blue-8': '#848fad',
    '--blue-11': '#111122',
    '--blue-12': '#00badb',
    '--blue-14': '#606a85',
    '--blue-16': '#2e75bd',
    '--blue-17': '#273161',
    '--blue-18': '#313a67',
    '--blue-19': '#374174',
    '--blue-21': 'rgb(59, 153, 252)',

    '--blue-btn': '#0792FF',
    '--blue-btn-hover': '#0980DD',

    '--red': '#E51849',
    '--red-e': '#E24704',
    '--red-fa': '#fa5d00',
    '--red-f': '#9E0000',
    '--red-0': '#ca6868',
    '--red-1': '#671a1a',
    '--red-2': '#960000',
    '--red-3': 'rgba(142, 6, 3, 0.4)',
    '--red-4': '#cf282c',
    '--red-5': '#ff4300',
    '--red-6': 'rgb(232, 0, 8)',
    '--red-7': '#b30404',
    '--red-8': '#f74f43',
    '--red-9': '#d74304',
    '--red-10': '#EE5615',
    '--red-11': '#ed2c03',
    '--color-icon-sign-out': '#B01212',
    '--red-text': '#D83232',
    '--red-bg-color': '#362323',
    '--red-text-2': '#B01212',
    '--red-bg-color-2': '#362323',


    '--green-1': '#1bc68b',
    '--green-3': '#33BA86',
    '--green-5': '#33ba8661',
    '--green-0': '#008000',
    '--green-4': '#0bffa1a8',
    '--green-6': '#30b17f',
    '--green-7': '#31b482',
    '--green-8': '#1d6a4b',
    '--green-text': '#2AA861',
    '--green-text-2': '#008037',
    '--green-bg-color': '#23362B',

    '--yellow-f': '#FFFF00',
    '--yellow-0': '#CCA866',

    '--white': '#f0f1f5',
    '--white-0': '#ADB1C3',
    '--white-1': '#eeeeee',
    '--white-2': '#767E9C',
    '--white-3': '#efefef',

    '--black': '#000000',

    '--grey': '#aaaaaa',
    '--grey-0': '#dddddd',
    '--grey-1': '#A9A9A9',
    '--grey-2': '#bfbfbf',
    '--grey-3': '#cccccc',

    '--bg-color-table': '#111D26',
    '--bg-color-item': '#0F1920',
    '--bg-light-fon': '#0F1920AA',
    '--bg-light-fon-2': 'rgba(7,7,7,.6)',
    '--bg-fon': '#162936',

    '--bg-dark-fon': 'rgba(17,29,38,.3)',

    '--color-icon-theme': '#9199B1',

    '--check-box-color': '#0792FF',
    '--color-active-text-btn': '#0792FF',
    '--bg-spread': '#0F19201',

    '--bg-item': '#0F1920',
    '--text-color': '#E3E5EB',
    '--text-color-2': '#767E9C',
    '--text-color-3': '#5A6689',
    '--text-color-4': '#202121',

    '--border-color-grey': '#111d26',

    '--bg-color-avatar': '#182731',
    '--color-avatar': '#2A3A45',
    '--color-border': '#1E282E',
    '--color-icon': '#767E9C',

    '--bg-disabled-btn': '#162936',
    '--color-text-disabled-btn': '#767E9C',
    '--color-text-input-placeholder': '#474D65',


    '--bg-context-menu-chart': '#1e222d',
    '--shadow-context-menu-chart': '#0006',
    '--color-context-menu-chart': '#d1d4dc',
    '--hover-context-menu-chart': '#2a2e39',
    '--color-input-text': '#000000',

    '--full-market-color-bg': '#131722',
  }
};
