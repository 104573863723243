import { Injectable } from '@angular/core';
import {LocalStorageService} from '@common/auth/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountRiskHandlerService {

  constructor(private localStorageService: LocalStorageService) { }

  public getAccountRiskPercentageForTradeId(tradeId: number): number | null {
    return this.localStorageService.getAccountRiskPercentageForTradeId(tradeId);
  }

  public saveAccountRiskPercentageForTradeId(tradeId: number, accountRiskPercentage: number): void {
    return this.localStorageService.saveAccountRiskPercentageForTradeId(tradeId, accountRiskPercentage);
  }

  public deleteAccountRiskPercentageForTradeId(tradeId: number) {
    this.localStorageService.deleteAccountRiskPercentageForTradeId(tradeId);
  }
}
