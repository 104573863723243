import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../servises/translator.service';
import {ITranslatable} from '../../i-translatable';
import {TranslateManagerService} from '../../servises/translate-manager.service';
import {LocalComponent} from '../local-component';

@Component({
  selector: 'l-unit',
  templateUrl: './l-unit.component.html',
  styleUrls: ['./l-unit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LUnitComponent extends LocalComponent implements OnInit, OnDestroy, ITranslatable {
  @Input() key;
  @Input() upperCase: boolean;

  public get LocalValue(): string {
    const value = this.translator.getLocalPhrase(this.key);
    if (this.upperCase && value !== undefined && typeof value === 'string') {
      return value.toUpperCase();
    } else {
      return value;
    }
  }

  constructor(detector: ChangeDetectorRef, private translator: TranslatorService, translateManager: TranslateManagerService) {
    super(detector, translateManager);
  }
  public localize() {
    super.localize();
  }
}
