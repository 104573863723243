import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {Symbol} from '@common/symbol/models/symbol';
import {Settings} from '@common/trader/models/settings';
import {Environment} from '@common/environment';
import {ChartService} from '@common/charting/services/chart.service';
import {SettingsService} from '@common/trader/services/settings.service';
import {AppConfig} from '@common/configuration/app-config';
import {SymbolInfoService} from '@common/shared/services/symbol-info.service';
import {SearchComponent} from '@common/symbol-search/components/search/search.component';
import {translate} from '@common/locale/servises/translator.service';

@Component({
  selector: '[app-symbol-search]',
  templateUrl: './symbol-search.component.html',
  styleUrls: ['./symbol-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SymbolSearchComponent implements OnInit {

  private _textIcon: string;
  public appName = '';
  private symbol: Symbol;

  @Input()
  public set symbolModel(v: Symbol) {
    this.symbol = v;
  }

  @Output() setSymbolToPath: EventEmitter<Symbol> = new EventEmitter<Symbol>();

  constructor(private chartService: ChartService,
              private symbolSelector: SymbolSelectService,
              private searchComponent: SearchComponent,
              private settingsService: SettingsService,
              private appConfig: AppConfig,
              private symbolInfoService: SymbolInfoService) {

    this.appName = this.appConfig.Settings.common.AppName;
  }

  public async openChart(event: MouseEvent) {
    event.stopPropagation();
    await this.chartService.setSymbol(this.SymbolName);
  }

  public async toggleFavorite(event: MouseEvent) {
    event.stopPropagation();
    await this.symbolSelector.toggleFavorites(this.symbolModel.SymbolId);
    // при нажатии на иконку звездочки список фаворит обновляется и отображает актуальные значения
    this.searchComponent.changeVisibleSymbols();
  }

  public ngOnInit(): void {
    this._textIcon = this.symbol.SymbolName['0'].toUpperCase();
    if (this.IsZeTradex && this.symbol.getSymbolIconPath() === undefined) {
      this.symbol.setSymbolIconPath(this.symbolInfoService.getSymbolIcon(this.symbol));
    }

  }

  public get SymbolName(): string {
    return this.symbolModel.SymbolName;
  }

  public get SymbolDescription(): string {
    return  this.symbolModel.SymbolInfo.description;
  }

  public get SymbolCategory(): string {
    return  this.symbolModel.CategoryName;
  }

  public get SymbolIconPath(): string {
    return this.symbolModel.getSymbolIconPath();
  }

  get TextIcon(): string {
    return this._textIcon;
  }

  public get SymbolId(): number {
    return this.symbolModel.SymbolId;
  }

  public get IsFavorite(): boolean {
    return this.symbolSelector.isFavorite(this.symbolModel.SymbolId);
  }

  public get symbolModel(): Symbol {
    return this.symbol;
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  get IsMobileVersion(): boolean {
    return Environment.IsMobileVersion;
  }

  public get IsZeTradex() {
    return this.appConfig.Settings.common.AppName === 'Zetradex';
  }

  setSymbolTo() {
    this.setSymbolToPath.emit(this.symbol);
  }

  public translateSymbolsCategories(category: string, isToUpperCase: boolean = false): string {
    let categoryTranslate = translate(`Quotes_QuotesComponent_${category}`);
    if (categoryTranslate === undefined) {
      categoryTranslate = category;
    }
    return isToUpperCase ? categoryTranslate.toUpperCase() : categoryTranslate;
  }
}
