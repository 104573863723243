import {IProtocol} from './protocol-interface';
import {Answer_CustomSessionError, Answer_LoginSuccess, Command_LoginByAccessToken, } from '../classes.g';
import {CommandIdGenerator} from '../command-id-generator';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {ProtocolMediatorBase_CommandBase} from './protocol-mediator-base-command-base';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';

export class ProtocolAuthByAccessToken extends ProtocolMediatorBase_CommandBase {
  private _loginMessage: Command_LoginByAccessToken;
  private _accessToken: string;

  public constructor (subProtocol: IProtocol, commandIDGenerator: CommandIdGenerator, accessToken: string) {
    super(subProtocol, commandIDGenerator);
    this._accessToken = accessToken;
    this._logger = LoggerFactory.getLogger(this);
  }

  public onConnectionEstablished() {
    this._loginMessage = new Command_LoginByAccessToken();
    this._loginMessage.AppName = 'WebTrader';
    this._loginMessage.AccessToken = this._accessToken;
    this._loginMessage.CommandID = this._commandIDGenerator.getNextCommandID();
    this._loginMessage.ClientData = JSON.stringify(this.createClientData());

    this._logger = LoggerFactory.getLogger(this);

    this.send(this._loginMessage);
  }

  public onMessageReceived(message: ProtoV2MessageBase) {
    if (this.isAnswerToCommand(this._loginMessage, message)) {
      this.handleLoginAnswer(message);
    } else {
      super.onMessageReceived(message);
    }
  }

  private handleLoginAnswer(message: ProtoV2MessageBase) {
    if (message.MessageType === 'Answer_LoginSuccess') {
      this._logger.debug('LoginSuccess');
      this.handleLoginAnswer_Success(message as Answer_LoginSuccess);
    } else if (message.MessageType === 'Answer_CustomSessionError') {
      this._logger.error('CustomSessionError');
      this.handleLoginAnswer_Failed(message as Answer_CustomSessionError);
    }
  }

  private handleLoginAnswer_Success(message: Answer_LoginSuccess) {
    super.onMessageReceived(message);
    super.onConnectionEstablished();
  }

  private handleLoginAnswer_Failed(message: Answer_CustomSessionError) {
    super.onMessageReceived(message);
    super.onConnectionEstablishedError(DisconnectionCodes.IncorrectToken);
  }
}
