import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Trade} from '@common/trade/models/trade';
import {isBuyTrade, isSellTrade, TradeType, TradeTypeStrings} from '@common/trade/models/trade-type';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeAction} from '@common/actions/trade.action';
import {Trader} from '@common/trader/models/trader';
import {TradeStateSnapshot} from '@common/trade/models/trade-state-snapshot';
import {StateType, StateTypeStrings} from '@common/trade/models/trade-state-enum';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {Environment} from '@common/environment';
import {ChartService} from '@common/charting/services/chart.service';
import {SettingsService} from '@common/trader/services/settings.service';
import {Settings} from '@common/trader/models/settings';
import {AppConfig} from '@common/configuration/app-config';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {LocaleModule} from '@common/locale/locale.module';
import {TradeModule} from '@common/trade/trade.module';

@Component({
  selector: ' lib-trade-line',
  templateUrl: './trade-line.component.html',
  styleUrls: ['./trade-line.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    LocaleModule,
    NgIf,
    NgForOf,
    TradeModule
  ],
  standalone: true
})
export class TradeLineComponent implements OnInit, OnDestroy, AfterViewInit {
  protected isOpened = false;
  private icon = this.getIcon();
  private uplSubscription: ISmartSubscription;
  private updateSubscription: ISmartSubscription;
  private _minLength = 2;

  private visibility = true;

  @Input()
  public trade: Trade;

  constructor(private tradeAction: TradeAction,
              private chartingService: ChartService,
              private trader: Trader,
              private detector: ChangeDetectorRef,
              private settingsService: SettingsService,
              private appConfig: AppConfig,
              private symbolService: SymbolService) {

  }

  public toggle() {
    this.isOpened = !this.isOpened;
    this.icon = this.getIcon();
  }

  public async closeTrade(event: MouseEvent) {
    event.stopImmediatePropagation();
    const promise = await this.tradeAction.closeTrade(this.trade);
    if (promise) {
      this.unsubscribeFromUpl();

      promise
        .error(() => this.subscribeToUpl());
    }
  }

  public modifyTrade(event: MouseEvent) {
    event.stopImmediatePropagation();
    this.tradeAction.modifyTrade(this.trade);
  }

  // переключение для отображения линий на графике
  public toggleVisibilityLineCharts() {
    this.visibility = !this.visibility;
    this.trade.VisibilityLine = this.visibility;
  }

  public getVisibility() {
    return this.trade.VisibilityLine;
  }

  public async openChart() {
    await this.chartingService.setSymbol(this.SymbolName);
  }

  ngOnInit() {
    if (!this.trade) {
      return;
    }

    this.detector.reattach();
    // this.trade.subscribeToSymbol();
    this.subscribeToUpl();
    this.detector.detectChanges();

    this.updateSubscription = this.trade.Updated.subscribe(() => this.reload());

    this.checkSubscribePrice(this.trade.Symbol);

    // проверка на подписку для конвертирующей валюты

    if (!this.trade.Symbol.ProfitCalcSymbol.getCCConvertSymbol().getIsValidQuote()) {

      const convertSymbol = this.trade.Symbol.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'];

      console.log( `Subscription for converting currency ${convertSymbol._symbolMetadata._symbolName}`);

      this.subscribeToConvertSymbol(convertSymbol._symbolId).then();

    }
    this.checkMinLengthMoney();
  }

  // проверка на подписку изминения цен, если её нет, то подписывается
  private checkSubscribePrice(v: Symbol) {
    if (v.LastQuote === undefined) {
      this.symbolService.subscribeToSymbol(v.SymbolId).then();
    }
  }

  // подписка на конвертирующую валюту

  private async subscribeToConvertSymbol(symbolId: number): Promise<void> {
    await this.symbolService.subscribeToSymbol(symbolId);
  }

  private reload() {
    this.detector.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromUpl();
    this.updateSubscription.unsubscribe();
    // this.trade.unsubscribeFromSymbol();
    this.detector.detach();
  }

  subscribeToUpl() {
    this.uplSubscription = this.trade.UPLChanged.subscribe(() => this.reload());
  }

  unsubscribeFromUpl() {
    if (this.uplSubscription) {
      this.uplSubscription.unsubscribe();
      this.uplSubscription = null;
    }
  }

  ngAfterViewInit(): void {
  }

  public get IsTradeOpen(): boolean {
    return this.trade.IsOpen;
  }

  public get States(): TradeStateSnapshot[] {
    return this.trade.States;
  }

  public get Icon(): string {
    return this.icon;
  }

  public get IsOpened(): boolean {
    return this.isOpened;
  }

  public get IsReadOnly(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  // возвращает значения на иконки глаза и отрисовки его в зависимости от результата
  public get IsReadOnlyForCarbona(): boolean {
    if (this.appConfig.Settings.orderLineCharts) {
      return this.IsReadOnly;
    } else {
      return true;
    }
  }

  public get TradeId(): number {
    return this.trade.TradeId;
  }

  public get SymbolName(): string {
    return this.trade.Symbol.SymbolName;
  }

  public get Symbol(): Symbol {
    return this.trade.Symbol;
  }

  public get OpenTime(): Date {
    return this.trade.OpenTime;
  }

  public get OpenPrice(): number {
    return this.trade.OpenPrice;
  }

  public get TradeType(): string {
    return this.getTradeTypeName(this.trade.Type);
  }

  public get Volume(): number {
    if (this.Settings.UseLotTrading) {
      return this.convertVolume(this.trade.Volume, this.Symbol.ContractSize);
    } else {
      return this.trade.Volume;
    }
  }

  public get Margin(): number {
    return this.trade.Margin;
  }

  public get TrailingStop(): number {
    return this.trade.TrailingStop;
  }

  public get PriceTrailingStop(): number {
    return this.trade.PriceTrailingStop;
  }

  public get ClosePrice(): number {
    return this.trade.ClosePrice || this.trade.CurrentPrice;
  }

  public get TakeProfit(): number {
    return this.trade.TakeProfit;
  }

  public get StopLoss(): number {
    return this.trade.StopLoss;
  }

  public get BaseCurrency(): string {
    if (this.Settings.UseLotTrading) {
      return 'lot';
    } else {
      return this.trade.SymbolName.substr(0, 3);
    }
  }

  public get TransactionFee(): number {
    return this.trade.TransactionFee;
  }

  public get SubaccountFee(): number {
    return this.trade.SubaccountFee;
  }

  public get UPL(): number {
    return this.trade.UPL;
  }

  public get Pips(): number {
    return this.trade.Pips;
  }

  public get Profit(): number {
    return this.trade.Profit;
  }

  public get Storage(): number {
    return this.trade.Storage;
  }

  public get Total(): number {
    return this.trade.Total;
  }

  private getIcon() {
    return this.isOpened ? '-' : '+';
  }

  get IsOpenChartVisible(): boolean {
    return (Environment.ChartType === 'tv' ||
      Environment.ChartCount.length === 1 && // We only allow chart to be opened via the chart button if there may be only one chart
      Environment.ChartCount[0] === 1);
  }

  public getBorderColor(): any {
    return {'red': isSellTrade(this.trade.Type), 'green': isBuyTrade(this.trade.Type)};
  }

  public getTradeClass() {
    return {
      'green-color': isBuyTrade(this.trade.Type),
      'red-color': isSellTrade(this.trade.Type),
    };
  }

  public getUPLClasses(): any {
    return {'green-color': this.trade.UPL > 0, 'red-color': this.UPL < 0};
  }

  public getPipsClasses() {
    return {'green-color': this.trade.UPL > 0, 'red-color': this.trade.UPL < 0};
  }

  public getProfitClasses() {
    return {'green-color': this.Profit > 0, 'red-color': this.Profit < 0};
  }

  public getStorageClasses() {
    return {'green-color': this.Storage > 0, 'red-color': this.Storage < 0};
  }

  public getTotalClasses(): object {
    return {'green-color': this.Total > 0, 'red-color': this.Total < 0};
  }

  public getTransactionFeeClasses(): object {
    return {'green-color': this.TransactionFee > 0, 'red-color': this.TransactionFee < 0};
  }

  public getSubaccountFeeClasses(): object {
    return {'green-color': this.SubaccountFee > 0, 'red-color': this.SubaccountFee < 0};
  }

  public getStateName(type: StateType): string {
    return StateTypeStrings[type];
  }

  public getTradeTypeName(type: TradeType): string {
    return TradeTypeStrings[type];
  }

  public getStateProfitClasses(state: TradeStateSnapshot) {
    return {'green-color': state.Profit > 0, 'red-color': state.Profit < 0};
  }

  public getStateStorageClasses(state: TradeStateSnapshot) {
    return {'green-color': state.Storage > 0, 'red-color': state.Storage < 0};
  }

  public getStateTransactionFeeClasses(state: TradeStateSnapshot) {
    return {'green-color': state.TransactionFee > 0, 'red-color': state.TransactionFee < 0};
  }

  public getStateSubaccountFeeClasses(state: TradeStateSnapshot) {
    return {'green-color': state.SubaccountFee > 0, 'red-color': state.SubaccountFee < 0};
  }

  public getCommandSign(volume: number) {
    if (volume > 0) {
      return '+';
    } else {
      return '';
    }
  }

  public getStatePipsClasses(statePips: number) {
    return {'green-color': statePips > 0, 'red-color': statePips < 0};
  }

  public shouldShowPrice(state: TradeStateSnapshot) {
    return state.StateType === StateType.Closed || state.StateType === StateType.PartialClosed;
  }

  public trackStateById(index: any, item: TradeStateSnapshot) {
    return item.StateId;
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  // если торговля в лотах, то идет конвертация
  public convertVolume(v: number, contractSize: number) {
    return OperationsWithVolume.convertFromVolumeToLots(v, contractSize, this.trader.LotDelimeter);
  }

  public get DecimalPlacesForMoney(): number {
    return this._minLength;
  }

  private checkMinLengthMoney(): void {
    const minLength = OperationsWithVolume.numberOfDigitsAfterDot(this.Symbol.TradingStep);

    if (minLength > 2) {
      this._minLength = minLength;
    }
  }
}
