import {Component, Input, OnInit} from '@angular/core';
import {PriceDirection} from '@common/symbol/models/last-quote-info';
import {SymbolPriceFormatter} from '@common/shared/utils/symbol-price-formatter';
import {Symbol} from '@common/symbol/models/symbol';

@Component({
  selector: '[app-symbol-price]',
  templateUrl: './symbol-price.component.html',
  styleUrls: ['./symbol-price.component.scss']
})
/**
 * Component for showing Ask/Bid price at quotes table
 * Should use with {@link SpreadComponent | spread component}
 */
export class SymbolPriceComponent implements OnInit {
  @Input()
  public set priceInfo(value: PriceDirection) {
    if (this._symbolPriceFormatter) {
      this._symbolPriceFormatter.PriceInfo = value;
    } else {
      this._priceInfo = value;
    }
  }

  public get priceInfo(): PriceDirection {
    if (this._symbolPriceFormatter) {
      return this._symbolPriceFormatter.PriceInfo;
    }
    return this._priceInfo;
  }

  @Input()
  public set symbol(value: Symbol) {
    this._symbol = value;
  }
  public get symbol(): Symbol {
    return this._symbol;
  }

  private _symbol: Symbol;
  private _priceInfo: PriceDirection;
  private _symbolPriceFormatter: SymbolPriceFormatter;

  public get Price(): string {
    return this._symbolPriceFormatter.Price;
  }

  get Basis(): string {
    return this._symbolPriceFormatter.Basis;
  }
  get Last2Pips(): string {
    return this._symbolPriceFormatter.Last2Pips;
  }
  get Tick(): string {
    return this._symbolPriceFormatter.Tick;
  }
  public get PriceIsCorrect(): boolean {
    return this._symbolPriceFormatter.PriceIsCorrect;
  }

  ngOnInit() {
    this._symbolPriceFormatter = new SymbolPriceFormatter(this.symbol, this.priceInfo);
    // this._symbolPriceFormatter.recalculateData();

    // // повторно вызываем методы через 800 мс т.к. при отрисовки, не успевает срабатывать подписка и данные цен передаются некоректно
    // setTimeout(() => {
    //   this._symbolPriceFormatter.recalculateData();
    //   }, 800);
  }
}
