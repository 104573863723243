import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {ChangeableValidator} from '@common/shared/directives/validators/changeable-validator';

@Directive({
  selector: '[non-zero]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NonZeroValidator,
    multi: true
  }]
})
export class NonZeroValidator extends ChangeableValidator implements Validator {

  @Input() ignored = false;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = Number(control.value);

    if (this.ignored) {
      return null;
    }

    return value != 0 ?
      null :
      {
        zeroValue: true
      };
  }
}
