export class SymbolSettings {
  private _initialMarginRate: number;
  private _maintenanceMarginRate: number;
  private _askCorrection: number;
  private _bidCorrection: number;
  private _pipSize: number;
  private _decimalPlaces: number;
  private _pipDecimalPlaces: number;
  private _volumeDecimalPlaces = 0;

  private readonly _cMul: number[] = [1, 0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001, 0.0000001, 0.00000001];

  get InitialMarginRate(): number {
    return this._initialMarginRate;
  }

  set InitialMarginRate(value: number) {
    this._initialMarginRate = value;
  }

  get MaintenanceMarginRate(): number {
    return this._maintenanceMarginRate;
  }

  set MaintenanceMarginRate(value: number) {
    this._maintenanceMarginRate = value;
  }

  get AskCorrection(): number {
    return this._askCorrection;
  }

  set AskCorrection(value: number) {
    this._askCorrection = value;
  }

  get BidCorrection(): number {
    return this._bidCorrection;
  }

  set BidCorrection(value: number) {
    this._bidCorrection = value;
  }

  get PipSize(): number {
    return this._pipSize;
  }

  set PipSize(value: number) {
    this._pipSize = value;
    this._pipDecimalPlaces = this.calcPipDecimalPlaces(this._pipSize);
  }

  get DecimalPlaces(): number {
    return this._decimalPlaces;
  }

  set DecimalPlaces(value: number) {
    this._decimalPlaces = value;
  }

  get PipDecimalPlaces(): number {
    return this._pipDecimalPlaces;
  }

  get PointToPipsMul(): number {
    return this._cMul[this.DecimalPlaces - this.PipDecimalPlaces];
  }

  get VolumeDecimalPlaces(): number {
    return this._volumeDecimalPlaces;
  }

  set VolumeDecimalPlaces(value: number) {
    this._volumeDecimalPlaces = value;
  }

  constructor() { }

  public pipsToPoint(pips: number): number {
    return Math.round(pips / this.PointToPipsMul);
  }

  private calcPipDecimalPlaces(pipSize: number): number {
    return Math.ceil(Math.log(pipSize) / Math.log(0.1));
  }
}
