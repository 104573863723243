<div class="trade-table custom-scroll-container">

  <lib-table-header [appName]="appName" [section]="'History'" [orders]="OrderHistory" (ordersArrayShow)="historyOrdersShowEvent($event)"></lib-table-header>

  <div class="body list custom-scroll">
    <div class="virtual-scroll-container">

      <cdk-virtual-scroll-viewport itemSize="40" class="virtual-scroll list">
        <div class="row-container list-row" *cdkVirtualFor="let report of OrdersSortingShow; let index = index;">
          <div class="table-row">
            <div class="states-toggle">
              <div class="trade-type-marker"
                   [class.green-text]="orderTypeIsBuy(report.orderTypeID)"
                   [class.red-text]="!orderTypeIsBuy(report.orderTypeID)">

              </div>
              <div class="show-states-button" (click)="toggle(index)">
                {{ IsOpenedIcon(index) }}
              </div>
            </div>
            <div class="order-info">
              <div [class.green-text]="orderTypeIsBuy(report.orderTypeID)"
                   [class.red-text]="!orderTypeIsBuy(report.orderTypeID)" class="main-info">
                #{{report.orderID}} {{getSymbolName(report.symbolID)}} {{ getOrderType(report.orderTypeID) }}
              </div>
              <div class="sub-info">
                <l-date [date]="OpenTime(report.commandTime)"></l-date>
              </div>
            </div>
            <div class="open-price-info">
              <div class="main-info">
                {{report.openPrice }}
              </div>
              <div class="sub-info">
                {{ getOrderType(report.orderTypeID) }}
              </div>
            </div>
            <div class="volume-info">
              <div class="main-info" *ngIf="ShowValue(report.volume)">
                <span *ngIf="!Settings.UseLotTrading">{{report.volume | money}} {{BaseCurrency(getSymbolName(report.symbolID))}}</span>
                <span *ngIf="Settings.UseLotTrading">{{showVolume(report.volume, report.symbolID) }} lot </span>
              </div>
            </div>
            <div class="margin-info">
              <div class="main-info" *ngIf="ShowValueMargin(report.commandMargin)">
                {{report.commandMargin | money}}
              </div>
            </div>
            <div class="sl-info">
              <div class="main-info" *ngIf="ShowValue(report.stopLoss)">
                {{report.stopLoss }}
              </div>
            </div>
            <div class="tp-info">
              <div class="main-info" *ngIf="ShowValue(report.takeProfit)">
                {{report.takeProfit }}
              </div>
            </div>
            <div class="ts-info">
              <div class="main-info" *ngIf="ShowValue(report.trailingStop)">
                {{report.trailingStop }} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
              </div>
            </div>
            <div class="price-info">
              <div class="main-info">
                {{report.commandPrice }}
              </div>
              <div class="sub-info"
                   [class.green-text]="checkingPipsResult(report.profit, report.openPrice, report.commandPrice, report.symbolID) > 0"
                   [class.red-text]="checkingPipsResult(report.profit, report.openPrice, report.commandPrice, report.symbolID) < 0">
                {{checkingPipsResult(report.profit, report.openPrice, report.commandPrice, report.symbolID) | pips}}
                <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
              </div>
            </div>
            <div class="pnl-info upl-info">
              <div class="main-info profit">
          <span *ngIf="report.profit > 0"><l-unit
            [key]="'TradeModule_OpenTradeLineComponent_ProfitLabel'"></l-unit>: </span>
                <span *ngIf="report.profit < 0"><l-unit
                  [key]="'TradeModule_OpenTradeLineComponent_LossLabel'"></l-unit>: </span>
                <span class="float-right"
                      [class.green-text]="report.profit > 0"
                      [class.red-text]="report.profit < 0">{{report.profit | money}}</span>
              </div>
              <div class="main-info swap" *ngIf="ShowValue(report.swaps)">
                <span><l-unit [key]="'TradeModule_TradeStateLineComponent_StorageLabel'"></l-unit>: </span>
                <span class="float-right"
                      [class.green-text]="report.swaps > 0"
                      [class.red-text]="report.swaps < 0">{{ report.swaps | money}}
            </span>
              </div>
              <div class="main-info trans-fee" *ngIf="ShowValue(report.transactionFee)">
                <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_TransFee'"></l-unit>: </span>
                <span class="float-right"
                      [class.green-text]="report.transactionFee > 0"
                      [class.red-text]="report.transactionFee < 0" >{{ report.transactionFee | money}}</span>
              </div>
              <!--                  <div class="main-info trans-fee" *ngIf="SubaccountFee">-->
              <!--                    <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_SubaccFee'"></l-unit>: </span>-->
              <!--                    <span class="float-right" [ngClass]="getSubaccountFeeClasses()">{{SubaccountFee | money}}</span>-->
              <!--                  </div>-->
            </div>
            <div class="total-info">
              <div class="main-info"
                   [class.green-text]="getTotal(report.profit, report) > 0"
                   [class.red-text]="getTotal(report.profit, report) < 0">
                {{getTotal(report.profit, report) | money}}
              </div>
            </div>
          </div>

          <div class="states" *ngIf="IsOpened(index, report)">
            <div class="additional-block-border"></div>
            <div class="table-row" *ngFor="let order of showOrderArray">
              <div></div>
              <div class="order-info">
                <div class="main-info">
                  <l-unit [key]="getStateName(order.commandTypeID)"></l-unit>
                </div>
                <div class="sub-info">
                  <l-date [date]="OpenTime(order.commandTime)"></l-date>
                </div>
              </div>
              <div class="open-price-info">
                <div class="main-info">
                  {{order.openPrice }}
                </div>
                <div class="sub-info">
                  {{ getOrderType(order.orderTypeID) }}
                </div>
              </div>
              <div class="volume-info">
                <div class="main-info">
                  <span *ngIf="!Settings.UseLotTrading">{{order.volume | money}}</span>
                  <span *ngIf="Settings.UseLotTrading"> {{showVolume(order.volume, order.symbolID) }}</span>
                </div>
                <div class="sub-info" *ngIf="ShowValue(order.commandVolume)">
                  <span *ngIf="!Settings.UseLotTrading">{{getCommandSign(order.commandVolume)}}{{order.commandVolume | money}}</span>
                  <span *ngIf="Settings.UseLotTrading"> {{getCommandSign(order.commandVolume)}} {{showVolume(order.commandVolume, order.symbolID) }}</span>
                </div>
              </div>
              <div class="margin-info">
                <div class="main-info">
                  {{order.margin | money}}
                </div>
                <div class="sub-info" *ngIf="ShowValue(order.commandMargin)">
                  {{getCommandSign(order.commandMargin)}}{{order.commandMargin | money}}
                </div>
              </div>
              <div class="sl-info">
                <div class="main-info" *ngIf="ShowValue(order.stopLoss)">
                  {{order.stopLoss }}
                </div>
              </div>
              <div class="tp-info">
                <div class="main-info" *ngIf="ShowValue(order.takeProfit)">
                  {{order.takeProfit }}
                </div>
              </div>
              <div class="ts-info">
                <div class="main-info" *ngIf="ShowValue(order.trailingStop)">
                  {{order.trailingStop }} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
                </div>
              </div>
              <div class="price-info">
                <div class="main-info" *ngIf="ShowValueForIsOpen(order.stateAction)">
                  {{order.commandPrice }}
                </div>
                <div class="sub-info"
                     [class.green-text]="checkingPipsResult(order.profit, order.openPrice, order.commandPrice, order.symbolID) > 0 "
                     [class.red-text]="checkingPipsResult(order.profit, order.openPrice, order.commandPrice, order.symbolID) < 0"
                     *ngIf="ShowValueForIsOpen(order.stateAction)">
                  {{checkingPipsResult(order.profit, order.openPrice, order.commandPrice, order.symbolID ) | pips}}
                  <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit>
                </div>
              </div>
              <div class="pnl-info">
                <div class="main-info profit" >
                  <div *ngIf="ShowValueForIsOpen(order.stateAction)">
              <span *ngIf="order.profit > 0"><l-unit
                [key]="'TradeModule_OpenTradeLineComponent_ProfitLabel'"></l-unit>: </span>
                    <span *ngIf="order.profit < 0"><l-unit
                      [key]="'TradeModule_OpenTradeLineComponent_LossLabel'"></l-unit>: </span>
                    <span class="float-right"
                          [class.green-text]="order.profit > 0"
                          [class.red-text]="order.profit < 0">{{order.profit | money}}</span>
                  </div>
                </div>
                <div class="main-info swap" *ngIf="ShowValue(order.swaps)">
                  <span><l-unit [key]="'TradeModule_TradeStateLineComponent_StorageLabel'"></l-unit>: </span>
                  <span class="float-right"
                        [class.green-text]="order.swaps > 0"
                        [class.red-text]="order.swaps < 0">{{order.swaps | money}}
            </span>
                </div>
                <div class="main-info trans-fee" *ngIf="ShowValue(order.transactionFee) && showOrderArray.length >1">
                  <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_TransFee'"></l-unit>: </span>
                  <span class="float-right"
                        [class.green-text]="order.transactionFee > 0"
                        [class.red-text]="order.transactionFee < 0" >{{order.transactionFee | money}}</span>
                </div>
                <!--                <div class="main-info trans-fee" *ngIf="SubaccountFee">-->
                <!--                  <span><l-unit [key]="'TradeModule_OpenTradeLineComponent_SubaccFee'"></l-unit>: </span>-->
                <!--                  <span class="float-right" [ngClass]="getSubaccountFeeClasses()">{{SubaccountFee | money}}</span>-->
                <!--                </div>-->
              </div>
              <div class="total-info">
                <!--                    <div class="main-info" *ngIf="ShowValueForIsOpen(order.stateAction)"-->
                <!--                         [class.green-text]="getTotalIsOpen(order.profit, showOrderArray) > 0"-->
                <!--                         [class.red-text]="getTotalIsOpen(order.profit, showOrderArray) < 0">-->
                <!--                      {{getTotalIsOpen(order.profit, showOrderArray) | money}}-->
                <!--                    </div>-->
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
    <ng-content select=".bottom-content"></ng-content>
  </div>
</div>






