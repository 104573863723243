export class MailMessageBody {
  private _mailId: number;
  private _messageBody: string;

  public get MailId(): number {
    return this._mailId;
  }

  public get MessageBody(): string {
    return this._messageBody;
  }

  constructor(mailId: number,
              messageBody: string) {
    this._mailId = mailId;
    this._messageBody = messageBody;
  }
}
