<div theme style="height: 100%; width: 100%">

  <router-outlet name="test-panel" *ngIf="IsTesting"></router-outlet>
  <router-outlet></router-outlet>
  <div class="connection-status-container" [hidden]="IsConnected">
    <img ngSrc="./assets/img/spinner.svg" width="32" height="32" alt="spinner">
    <span class="reconnecting-text">Reconnecting...</span>
  </div>

  <div class="connection-internet-status-container" [hidden]="IsConnectedInternet">
    <div *ngIf="noInternetConnection" class="connection-internet-status-box">
      <span class="internet-icon internet-off" ></span>
      <span style="font-size: 10px; line-height: 12px; font-weight: bold;" >THERE IS NO INTERNET CONNECTION</span>
    </div>
    <div *ngIf="!noInternetConnection" class="connection-internet-status-box">
      <span class="internet-icon internet-on" ></span>
      <span style="font-size: 10px; line-height: 12px; font-weight: bold;">INTERNET CONNECTION IS THERE</span>
    </div>
  </div>



  <!--этот блок нужен чтоб прогрузились ссылки на иконы статуса интернета-->

  <div style="opacity: 0; position: absolute; bottom: 0; right: 0">
    <span class="internet-icon internet-on" ></span>
    <span class="internet-icon internet-off" ></span>
  </div>

</div>


