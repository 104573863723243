import {AbstractTradeStrategy} from '@common/actions/strategies/trade/abstract-trade.strategy';
import {Trade} from '@common/trade/models/trade';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {Router} from '@angular/router';

export class SimpleTradeStrategy extends AbstractTradeStrategy {
  public constructor(private router: Router) {
    super();
  }

  async closeTrade(trade: Trade) {
    await this.router.navigate(['/terminal', {outlets: { modal: ['close', trade.TradeId] }} ]);
    return null;
  }

  async openOrder(order: TradeDTO) {
    const params = {};
    if (order.Symbol) {
      params['symbolId'] = order.Symbol.SymbolId;
    }

    if (order) {
      params['prices'] = order.CurrentPrice;
      params['order'] = true;
      params['sl'] = order.StopLoss;
      params['tp'] = order.TakeProfit;
      params['volume'] = order.Volume;
    }

    await this.router.navigate( ['/terminal', {outlets: {modal: ['multi-order', order.Type, params] } }]);
  }

}
