import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class USDCC_USDBC extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.USDCC_USDBC;
	}
        
  public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  	
	if (this.Symbol.getMarginRate() * this.Symbol.getBCConvertSymbol().getBid() == 0)
			{
				return 0;
			}
  			return unsignedAmount  / this.Symbol.getBCConvertSymbol().getBid() * this.Symbol.getMarginRate();
}

  public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  	
	if (this.Symbol.getMarginRate() * this.Symbol.getBCConvertSymbol().getAsk() == 0)
			{
				return 0;
			}
  			return unsignedAmount / this.Symbol.getMarginRate() * this.Symbol.getBCConvertSymbol().getAsk();
}

  public CalculateMaxLotCountInternalLong(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin * this.Symbol.getBCConvertSymbol().getBid() / (unsignedVolume * this.Symbol.getMarginRate());
}

  public CalculateMaxLotCountInternalShort(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin * this.Symbol.getBCConvertSymbol().getAsk() / (unsignedVolume * this.Symbol.getMarginRate());
}

  }