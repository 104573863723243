<div class="sign-in-container" *ngIf="!isZeTradex(); else zetradex">
  <div class="c-form-container body-sign-in" [class.section-transition]="isShowSectionTransition()">
    <app-l-switch class="login-form-locale"></app-l-switch>
    <span class="login-logo" [ngClass]="getLogoCurrentThemeColor()"></span>
    <div></div>
    <app-login-form></app-login-form>
    <div class="container-other-buttons" [hidden]="isConnecting">
      <div class="transition-container" *ngIf="isShowSectionTransition()">
        <span class="login-logo logo-transition" [class.logo-transition-mobile]="isMobile()" [ngClass]="getLogoTransitionCurrentThemeColor()"></span>
        <a href="{{ GoToLink }}" class="transition-button button-success">
          <span>{{ GoToName }}</span>
        </a>
      </div>
      <div class="clear-cache-container" *ngIf="IsShowButtonClearCache">
        <button class="transition-button button-success button-clear" (click)="clearCacheReloadPage()"><l-unit [key]="'AuthModule_SignInComponent_ClearCacheReloadPage'"></l-unit></button>
      </div>
      <a href="{{ CreateAccountLink }}" class="create-account-button"  *ngIf="IsCreateAccountLinkAvailable">
        <l-unit [key]="'AuthModule_SignInComponent_create-account-button'"></l-unit>
      </a>
    </div>
    <span class="version">V: {{Version}}</span>
  </div>

  <div class="connection-status-container" [hidden]="IsConnected">
    <l-unit [key]="'AuthModule_SignInComponent_DisabledAccount'"></l-unit>
  </div>

  <div class="connection-status-container" [hidden]="IsConnected">
    <img src="assets/img/spinner.svg">
    <l-unit [key]="'AuthModule_SignInComponent_Reconnecting'"></l-unit>
  </div>

</div>

<ng-template #zetradex>
  <div class="c-form-container z-body-sign-in">

    <span class="login-logo login-logo-light-theme z-login-logo" [class.z-login-logo-mobile]="isMobile()"></span>

    <div></div>

    <app-login-form></app-login-form>

    <div class="container-other-buttons">
      <span *ngIf="IsCreateAccountLinkAvailable">
        <l-unit [key]="'AuthModule_SignInComponent_DontHaveAnAccountZetradex'"></l-unit> <a href="{{ CreateAccountLink }}" class="z-text-link"> <l-unit [key]="'AuthModule_SignInComponent_SignUpZetradex'"></l-unit> </a>
      </span>

      <!--      <button class="z-button-google">-->
      <!--        <span class="z-google-icon"></span> Continue with Google-->
      <!--      </button>-->

    </div>

  </div>

</ng-template>
