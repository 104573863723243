import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
  selector: '[character-length]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CharacterLengthValidator,
    multi: true
  }]
})
export class CharacterLengthValidator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const check = control.value.length >= 5;
    return check ? null : {
      characterLength : true
    };
  }
}
