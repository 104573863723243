<div class="sltp-view">
  <div class="c-form-group stop-loss-container" [class.incorrect]="!SLValidity">
    <div class="price-views">
      <div class="bound-input">
        <label class="c-label"><l-unit [key]="'TradeModule_SlTpInputComponent_StopLossLabel'"></l-unit></label>
        <div class="app-modify-order-form-box-input">
          <number-input class="stop-loss"
                        [class.z-input-style]="IsZeTradex"
                        (blur)="isBlur('SL')"
                        [step]="IncrementStep"
                        [modify]="getOpenPrice()"
                        min-value="0"
                        name="SL"
                        [usePipsBreakEven]="UsePipsBreakEven"
                        [(ngModel)]="StopLoss"
                        (inputBlur)="onSLBlur()"
                        (click)="onFocus('SL')"
                        [class.ng-invalid]="!SLValidity"
                        [class.error-valid]="!SLValidity"
                        [class.hidden-volume]="getIsHidden(StopLoss, 'SL')"
                        [min]="0"
                        [disabled]="disabled"></number-input>
          <button class="app-modify-order-form-button-zeroing-out" [class.z-modify-button-zero]="IsZeTradex" (click)="zeroingOut($event, 'SL')">
            <span class="app-modify-order-form-button-icon" ></span>
          </button>
          <button *ngIf="IsShowBtnBreakEven"
                  class="app-modify-order-form-button-break-even"
                  [disabled]="ValidBtnBreakEven"
                  [class.button-success-blue-color]="IsZeTradex"
                  [class.button-b-e]="IsZeTradex"
                  (click)="breakEvenClick($event)">B/E
          </button>
        </div>
      </div>
      <div class="another-views another-views-with-button" *ngIf="StopType === 0">
        <div class="pips" [class.z-price-pips]="IsZeTradex">
          {{getPips(SL.Pips, StopLoss, 'SL') | pips}} <l-unit [key]="'TradeModule_SlTpInputComponent_PipsLabel'"></l-unit>
        </div>
        <div class="profit" [class.z-profit]="IsZeTradex" [ngClass]="getClassShowBtnBreakEven()">
          {{ getProfit(SL.Profit, StopLoss, 'SL') | money}}$
        </div>
      </div>
      <div class="another-views another-views-with-button" *ngIf="StopType === 1">
        <div class="price" [class.z-price-pips]="IsZeTradex">
          {{SL.Price | price: trade.Symbol}}
        </div>
        <div class="profit" [class.z-profit]="IsZeTradex" [ngClass]="getClassShowBtnBreakEven()">
          {{ getProfit(SL.Profit, StopLoss, 'SL', false)  | money}}$
        </div>
      </div>
    </div>
  </div>

  <div class="c-form-group take-profit-container" [class.incorrect]="!TPValidity">
    <div class="price-views">
      <div class="bound-input form-group">
        <label class="c-label"><l-unit [key]="'TradeModule_SlTpInputComponent_TakeProfitLabel'"></l-unit></label>
        <div class="app-modify-order-form-box-input">
          <number-input class="take-profit"
                        [class.z-input-style]="IsZeTradex"
                        min-value="0"
                        [modify]="getOpenPrice()"
                        [step]="IncrementStep"
                        name="take-profit"
                        [class.ng-invalid]="!TPValidity"
                        [class.error-valid]="!TPValidity"
                        [class.hidden-volume]="getIsHidden(TakeProfit, 'TP')"
                        [(ngModel)]="TakeProfit"
                        (inputBlur)="onTPBlur()"
                        (click)="onFocus('TP')"
                        (blur)="isBlur('TP')"
                        [min]="0"
                        [disabled]="disabled"></number-input>
          <button class="app-modify-order-form-button-zeroing-out" [class.z-modify-button-zero]="IsZeTradex" (click)="zeroingOut($event,'TP')">
            <span class="app-modify-order-form-button-icon" ></span>
          </button>
        </div>
      </div>
      <div class="another-views another-views-with-button" *ngIf="StopType === 0">
        <div class="pips" [class.z-price-pips]="IsZeTradex">
          {{getPips(TP.Pips, TakeProfit, 'TP') | pips}} <l-unit [key]="'TradeModule_SlTpInputComponent_PipsLabel'"></l-unit>
        </div>
        <div class="profit" [class.z-profit]="IsZeTradex">
          {{ getProfit(TP.Profit, TakeProfit, 'TP') | money}}$
        </div>
      </div>
      <div class="another-views another-views-with-button" *ngIf="StopType === 1">
        <div class="price" [class.z-price-pips]="IsZeTradex">
          {{TP.Price | price: trade.Symbol}}
        </div>
        <div class="profit" [class.z-profit]="IsZeTradex">
          {{getProfit(TP.Profit, TakeProfit, 'TP', false) | money}}$
        </div>
      </div>
    </div>
  </div>

  <div class="form-group" [class.z-radio-group]="IsZeTradex">
    <app-radio-group [initial]="StopType" [kvMap]="variants" (onSelect)="chooseStopType($event)" [disabled]="disabled">
    </app-radio-group>
  </div>
</div>

<div class="c-form-group trailing-stop-input" [class.incorrect]="!TSValidity">
  <div class="c-form-group">
    <div class="price-views">
      <div class="bound-input form-group">
        <label class="c-label"><l-unit [key]="'TradeModule_SlTpInputComponent_TrailingStopLabel'"></l-unit></label>
        <div class="app-modify-order-form-box-input">
          <number-input
            [step]="1" name="trailing-stop"
            (blur)="isBlur('TS')"
            [(ngModel)]="TrailingStop"
            (inputBlur)="onTSBlur()"
            (click)="onFocus('TS')"
            [min]="0"
            [disabled]="disabled"
            [class.ng-invalid]="!TSValidity"
            [class.error-valid]="!TSValidity"
            [class.hidden-volume]="getIsHidden(TrailingStop, 'TS')"
            [class.z-input-style]="IsZeTradex"
          ></number-input>
          <button class="app-modify-order-form-button-zeroing-out" [class.z-modify-button-zero]="IsZeTradex" (click)="zeroingOut($event,'TS')">
            <span class="app-modify-order-form-button-icon" ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

