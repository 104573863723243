import {IProfitCalcSymbol} from '../../trade/utils/calculations/iprofit-calc-symbol';
import {Symbol} from './symbol';

export class ProfitCalcSymbol implements IProfitCalcSymbol {
  private readonly _symbolModel: Symbol;
  private readonly _ccConvertSymbol: IProfitCalcSymbol;
  private readonly _bcConvertSymbol: IProfitCalcSymbol;

  constructor(symbolModel: Symbol,
              bcConvertSymbol: IProfitCalcSymbol,
              ccConvertSymbol: IProfitCalcSymbol) {
    this._symbolModel = symbolModel;
    this._bcConvertSymbol = bcConvertSymbol;
    this._ccConvertSymbol = ccConvertSymbol;
  }

  public get SymbolModel(): Symbol {
    return this._symbolModel;
  }

  public getAsk(): number {
    return this.SymbolModel.LastQuote !== undefined ? this.SymbolModel.LastQuote.Ask : NaN;
  }

  public getBid(): number {
    return this.SymbolModel.LastQuote !== undefined ? this.SymbolModel.LastQuote.Bid : NaN;
  }

  public getDecimalPlaces(): number {
    return this.SymbolModel.DecimalPlaces;
  }

  public getIsValidQuote(): boolean {
    return !isNaN(this.getAsk()) &&
      !isNaN(this.getBid()) &&
      this.getAsk() !== Infinity &&
      this.getBid() !== Infinity &&
      this.getAsk() !== 0 &&
      this.getBid() !== 0;
  }

  public getCCConvertSymbol(): IProfitCalcSymbol {
    if (!this._ccConvertSymbol) {
      return this;
    }

    return this._ccConvertSymbol;
  }

  public checkAvailabilityBCConvertSymbol(): boolean {
    return this._bcConvertSymbol !== undefined;
  }

  public getBCConvertSymbol(): IProfitCalcSymbol {
    if (!this._bcConvertSymbol) {
      return this;
    }

    return this._bcConvertSymbol;
  }

  public getContractSize(): number {
    return this.SymbolModel.ContractSize;
  }

  public getTickPrice(): number {
    return this.SymbolModel.TickPrice;
  }

  public getTickSize(): number {
    return this.SymbolModel.TickSize;
  }
}
