import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";


export class Direct extends ProfitCalcBase {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.Direct;
	}
        
	public CalculateInternal(signedAmount: number, openPrice: number, closePrice: number): number {
	
				return signedAmount * (1 - (openPrice / closePrice));
}

	public CalculateInternalWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number {
	
				return this.CalculateInternal(signedAmount, openPrice, closePrice);
}

	public CalculatePriceAtProfitInternal(signedAmount: number, openPrice: number, profit: number): number {
	
				return signedAmount * openPrice / (signedAmount - profit);
}

	
}