import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class Indirect_index extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.Indirect_index;
	}
        
  public CalculateInternal(signedAmount: number, openPrice: number): number {
  	
	if (this.Symbol.getTickSize() == 0)
			{
				return 0;
			}
  			return Math.abs(signedAmount) * this.Symbol.getPercentage() * openPrice * this.Symbol.getTickPrice() / this.Symbol.getTickSize();
}

  public CalculateMaxLotCountInternal(signedVolume: number, openPrice: number, freeMargin: number): number {
  	
		if ( (this.Symbol.getTickSize() == 0) || (Math.abs(signedVolume) * this.Symbol.getPercentage() == 0))
			{
				return 0;
			}
  			return freeMargin / (Math.abs(signedVolume) * this.Symbol.getPercentage() * openPrice * this.Symbol.getTickPrice() / this.Symbol.getTickSize());
}

  
  
  }