import {SymbolInfo} from './symbol-info';
import {SymbolCategory} from './symbol-category';

export class SymbolMetadata {

  private readonly _symbolName: string;
  private readonly _symbolLongName: string;
  private readonly _baseCurrency: string;
  private readonly _counterCurrency: string;
  private readonly _symbolCategory: SymbolCategory;
  private readonly _symbolInfo: SymbolInfo;
  private readonly _externalName: string;
  private readonly _symbolCategoryName: string;
  private _symbolIconPath: string;

  get SymbolIconPath(): string {
    return this._symbolIconPath;
  }

  set SymbolIconPath(value: string) {
    this._symbolIconPath = value;
  }

  get SymbolName(): string {
    return this._symbolName;
  }

  get SymbolLongName(): string {
    return this._symbolLongName;
  }

  get BaseCurrency(): string {
    return this._baseCurrency;
  }

  get CounterCurrency(): string {
    return this._counterCurrency;
  }

  get SymbolCategory(): SymbolCategory {
    return this._symbolCategory;
  }

  get SymbolInfo(): SymbolInfo {
    return this._symbolInfo;
  }

  get ExternalName(): string {
    return this._externalName;
  }

  get SymbolType() {
    return this._symbolInfo.SymbolType;
  }

  get SymbolCategoryName(): string {
    return this._symbolCategoryName;
  }

  constructor(symbolName: string,
              symbolLongName: string,
              baseCurrency: string,
              counterCurrency: string,
              symbolCategory: SymbolCategory,
              decimalPlaces: number,
              externalName: string,
              symbolCategoryName: string) {
    this._symbolName = symbolName;
    this._symbolLongName = symbolLongName;
    this._baseCurrency = baseCurrency;
    this._counterCurrency = counterCurrency;
    this._symbolCategory = symbolCategory;
    this._externalName = externalName;
    this._symbolCategoryName = symbolCategoryName;

    this._symbolInfo = new SymbolInfo(
      this._symbolName,
      this._symbolName,
      this._symbolName,
      this._symbolLongName,
      decimalPlaces,
      this._symbolCategory,
      this._externalName,
      this._symbolCategoryName
    );
  }
}
