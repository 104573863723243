<form #alertForm="ngForm" >

  <div class="alert-price-form-container">

    <div class="alert-price-block">
      <span class="block-header"> <l-unit [key]="'Alert_Form_AlertPrice'"></l-unit> </span>
      <div class="input-group horizontal-input-group">
        <div>
          <number-input class="absolute-price" name="absolute-price"
                        [decimalPlaces]="Symbol.DecimalPlaces" [(ngModel)]="Prices"
                        (incDecrementEvent)="changeVolume($event)"
                        [blockFreeChange]="true"></number-input>
        </div>
      </div>
    </div>

    <div class="alert-price-block">
      <span class="block-header"><l-unit [key]="'Alert_Form_Condition'"></l-unit></span>
      <div class="alert-prices-selected">
        <select name="selected-alert-mode" class="c-select alert-price-input" [(ngModel)]="Condition">
          <option *ngFor="let mode of Conditions" [value]="mode.code">{{mode.name}}</option>
        </select>
      </div>
    </div>

    <div class="alert-price-block">
      <span class="block-header"><l-unit [key]="'Alert_Form_Trigger'"></l-unit></span>
      <div class="tabs alert-price-tabs" [class.alert-none-b]="IsZeTradex">
        <div class="all_symbols" [class.active]="TriggerMode == 'Only' || TriggerMode == undefined" (click)="changeTriggerMode('Only')">
          <l-unit [key]="'Alert_Form_OnlyOnce'"></l-unit>
        </div>
        <div [class.active]="TriggerMode == 'Every'" (click)="changeTriggerMode('Every')">
          <l-unit [key]="'Alert_Form_EveryTime'"></l-unit>
        </div>
      </div>
    </div>

    <div class="alert-price-block">
      <span class="block-header"><l-unit [key]="'Alert_Form_Expiration'"></l-unit></span>
      <div class="period-value" (click)="onDateClick($event)">
        {{DateExpiration}}
      </div>
      <div class="datetime-picker" *ngIf="ShowPicker">
        <datetime-picker alwaysOnScreen name="expiration-date" (onClickOutside)="closeDateTimePicker()"
                         (datetime)="onSelectDate($event)"></datetime-picker>
      </div>
    </div>

    <div class="alert-price-block">
      <span class="block-header"><l-unit [key]="'Alert_Form_AlertName'"></l-unit></span>
      <div class="input-group horizontal-input-group">
        <input ngModel type="text" class="alert-price-input" name="alertName" />
      </div>
    </div>

    <div class="alert-price-block">
      <span class="block-header"><l-unit [key]="'Alert_Form_Message'"></l-unit></span>
      <div class="input-group horizontal-input-group">
        <textarea rows="5" class="alert-price-input" [(ngModel)]="TextAreaAlertPrice"  name="messageAlertPrice"></textarea>
      </div>
    </div>
  </div>



  <div class="alert-price-block-button">
    <span class="alert-price-button button-danger"
          (click)="close()"
          [class.z-button-danger]="IsZeTradex"
          [class.z-button]="IsZeTradex"
          [class.z-button-alert]="IsZeTradex"
    ><l-unit [key]="'Alert_Form_Close'"></l-unit>
    </span>
    <span class="alert-price-button button-success"
          [class.z-button-success]="IsZeTradex"
          [class.z-button]="IsZeTradex"
          [class.z-button-alert]="IsZeTradex"
          (click)="createAlertPrice()"
    ><l-unit [key]="'Alert_Form_Create'"></l-unit>
    </span>
  </div>



</form>
