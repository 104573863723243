import {Injectable} from '@angular/core';
import {AbstractMessageProcessor} from '@common/communication/message-processor/processors/abstract-message-processor';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {
  Command_GetMailMessageBody, Command_GetMailMessageHeaders,
  Notification_Mail,
  Notification_MailMessageBody
} from '@common/communication/connection/classes.g';
import {MailMessageBody} from '@common/notification/models/mail-message-body';
import {MailMessage} from '@common/notification/models/mail-message';
import {MailComposed} from '@common/notification/models/mail-composed';
import {Trader} from '@common/trader/models/trader';
import {MailMessageBodyCommandSender} from '@common/communication/command-sender/mail-message-body-command-sender';
import {Notification, NotificationType, ReadingType} from '@common/notification/models/notification';
import {NotificationService} from '@common/notification/services/notification.service';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {MailHeadersCommandSender} from '@common/communication/command-sender/mail-headers-command-sender';
import {TradeNotificationCreationArgs} from '@common/notification/services/notification-provider.service';

export interface MailMap {
  [key: number]: MailComposed;
}

@Injectable({
  providedIn: 'root'
})
export class MailMessageProcessor extends AbstractMessageProcessor {

  private _logger = LoggerFactory.getLogger('MailMessageProcessor');

  private _mailMap: MailMap = {};

  public constructor(serverConnection: ServerInteractionService,
                     private mailMessageBodyCommandSender: MailMessageBodyCommandSender,
                     private notificationService: NotificationService,
                     private messagechangescommandsender: MailHeadersCommandSender) {
    super(serverConnection);
  }

  public subscribe(): void {
    const mailSub = this.serverConnection.on(Notification_Mail.GetMessageType(),
      (answer) => this.onMailHandle(answer),
      error => this._logger.error('Mail handling error:', error));

    this.saveSubscription(Notification_Mail.GetMessageType(), mailSub);
    this._logger.info(`Subscribed for ${Notification_Mail.GetMessageType()}`);

    const mailMessageBodySub = this.serverConnection.on(Notification_MailMessageBody.GetMessageType(),
      (answer) => this.onMailMessageBodyHandle(answer),
      error => this._logger.error('MailMessageBody handling error:', error));

    this.saveSubscription(Notification_MailMessageBody.GetMessageType(), mailMessageBodySub);
    this._logger.info(`Subscribed for ${Notification_MailMessageBody.GetMessageType()}`);
  }

  private onMailHandle(mail: Notification_Mail) {
    mail.MailMessages.forEach(async item => {
      const cmd = new Command_GetMailMessageBody();
      cmd.MailID = Number(item.MailID);

      this.serverConnection.sendCommand(cmd).failed(message => {
        this._logger.error(`Error sending ${Command_GetMailMessageBody.GetMessageType()}: `, message);
      });



      const messageObj = new MailMessage(
        item.From,
        Boolean(item.IsNews),
        Number(item.MailID),
        new Date(item.SendTime),
        item.SenderLoginName,
        new Date(item.ServerTime),
        item.Subject,
        item.IsRead.toString().toLowerCase() == 'true',
      );


      const mailComposed = new MailComposed();
      mailComposed.MailMessage = messageObj;

      this._mailMap[messageObj.MailId] = mailComposed;
    });
  }

  private onMailMessageBodyHandle(mailMessageBody: Notification_MailMessageBody) {

    if (mailMessageBody.MessageBody.startsWith('<!DOCTYPE')) {
      if (mailMessageBody.MessageBody.includes('Your account equity')) {
        mailMessageBody.MessageBody = 'Your account equity has fallen below maintenance margin requirement';
      }
    }

    const messageObj = new MailMessageBody(
      Number(mailMessageBody.MailID),
      mailMessageBody.MessageBody
    );

    const mail = this._mailMap[messageObj.MailId];

    if (!mail) {
      throw new Error('Message body for unexisting mail, mail id: ' + messageObj.MailId);
    }

    mail.MailMessageBody = messageObj;

    const notification: Notification = new Notification(
      mail.MailMessage.Subject,
      'MailMessage',
      mail.MailMessageBody.MessageBody,
      '',
      NotificationType.BackOffice,
      ReadingType.AlwaysRead,
      mail.MailMessage.IsRead,
      mail.MailMessage.SendTime,
      mail.MailMessage.MailId,
      {} as TradeNotificationCreationArgs,
      mail.MailMessage.SenderLoginName,
    );

    this.notificationService.push(notification);
    console.log('handling: ', mail);
  }

  public async readMessage(mailId: number): Promise<ICommandPromise> {
    return this.messagechangescommandsender.changeMessageRead(mailId);
  }

  public async deleteMessage(mailId: number): Promise<ICommandPromise> {
    return this.messagechangescommandsender.deleteMessage(mailId);
  }
}
