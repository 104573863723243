import {Component, OnInit} from '@angular/core';
import {PasswordService} from '@common/trader/services/password.service';
import {Trader} from '@common/trader/models/trader';
import {Router} from '@angular/router';
import {ProtoV2MessageBase} from '@common/communication/connection/proto-v2-message-base';
import {Answer_CustomSessionError} from '@common/communication/connection/classes.g';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {AbstractModal} from '@desktop-core/modal/component/modals/abstract-modal';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {AppConfig} from '@common/configuration/app-config';
import {LocaleModule} from '@common/locale/locale.module';
import {FormsModule} from '@angular/forms';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';
import {translate} from '@common/locale/servises/translator.service';
import {SharedModule} from '@common/shared/shared.module';
import {NgClass, NgIf} from '@angular/common';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';

@Component({
  selector: 'lib-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.css'],
  imports: [
    LocaleModule,
    FormsModule,
    SharedModule,
    NgIf,
    NgClass,
    ModalPlaceholderComponent
  ],
  standalone: true
})
export class ModalChangePasswordComponent extends AbstractModal implements OnInit {
  public currentPassword: string;
  public newPassword: string;
  public repeatPassword: string;

  public error: string;

  public hideCurrentPassword = true;
  public hideNewPassword = true;
  public hideConfirmNewPassword = true;

  public get TraderShouldChangePassword(): boolean {
    return this.trader.ShouldChangePassword;
  }

  constructor(private passwordService: PasswordService,
              private trader: Trader,
              protected router: Router,
              private localStorage: LocalStorageService,
              protected appConfig: AppConfig,
              private usersInSystemsService: UsersInSystemsService) {
    super(router, appConfig);
  }

  ngOnInit() {
  }

  close() {
    if (this.TraderShouldChangePassword) {
      return;
    } else {
      super.close();
    }
  }

  public submit() {
    const promise = this.passwordService.changePassword({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword
    });

    promise.toNativePromise().then((answer: ProtoV2MessageBase) => {
      console.log(answer);
      // при смене пароля новый пароль и имя пользователя пересохроняются в локал стородже и меняется булевое значение перезагрузки страницы
      this.usersInSystemsService.saveNewPasswordUsers(this.newPassword, this.trader.TraderName);
      this.trader.passwordChanged();
      this.localStorage.deleteSessionKey();

      if (answer['SessionID']) {
        this.localStorage.saveSessionKey(answer['SessionID']);
      }

      this.router.navigate(['/terminal']).then();
    }).catch((answer) => {
      console.log(answer);
      if (answer.Error && answer.Error.Message === 'Invalid user name or password.') {
        sessionStorage.setItem('CurrentPasswordIncorrectly', 'true');
        this.usersInSystemsService.clearUsersInSystem(this.trader.TraderName);
      }

    });
  }

  public  getVisibilityIcon(hidden: boolean) {
    if ( !hidden ) {
      return 'z-visibility';
    } else {
      return 'z-visibility-off';
    }
  }

  public translatePlaceholderInput(keyPlaceholder: string): string {
    return translate(`Modal_ChangePasswordComponent_${keyPlaceholder}`);
  }
}
