import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {AppConfig} from '@common/configuration/app-config';
import {Accounting} from '@common/trader/models/accounting.service';
import {MarginStatus} from '@common/trader/models/margin-status';
import {MarginStatusService} from '@common/trader/services/margin-status.service';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';
import {TimeService} from '@common/shared/services/Time/time.service';
import {LocaleEnum} from '@common/locale/servises/locale';
import {TranslatorService} from '@common/locale/servises/translator.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Output() serverTime: Date;

  private readonly _connectUrlPort: string = '0';
  private readonly currentYear: number;

  constructor(public accounting: Accounting,
              private marginService: MarginStatusService,
              private usersInSystemsService: UsersInSystemsService,
              private translator: TranslatorService,
              private timeService: TimeService) {

    this.serverTime = new Date();
    this.currentYear = new Date().getFullYear();

    setInterval(() => this.serverTime = new Date(), 1000);

    const parseUrlFirst = this.usersInSystemsService.ConnectUrl.split(':');
    const parseUrlSecond = parseUrlFirst[2].split('/');
    this._connectUrlPort = parseUrlSecond[0];
  }

  public get TitleTime(): string {
    return this.timeService.TitleTime;
  }

  ngOnInit() {
    this.accounting.subscribeToUpdateUpl();
  }

  ngOnDestroy() {
    this.accounting.unsubscribeToUpdateUpl();
  }

  public get CompanyName(): string {
    return AppConfig.settings.common.CompanyName;
  }

  public get CurrentYear(): number {
    return this.currentYear;
  }

  public get Balance(): number {
    return this.accounting.Balance;
  }

  public get UsedMargin(): number {
    return this.accounting.UsedMargin;
  }

  public get UPL(): number {
    return this.accounting.UPL;
  }

  public get UplClass(): any {
    return { 'positive': this.accounting.UPL > 0, 'negative': this.accounting.UPL < 0, 'neutral': this.accounting.UPL === 0 };
  }

  public get Equity(): number {
    return this.accounting.Equity;
  }

  public get FreeMargin(): number {
    return this.accounting.FreeMargin;
  }

  public get MarginLevel(): string {
    return this.accounting.MarginLevel;
  }

  public get MarginStatus(): MarginStatus {
    return this.marginService.MarginStatus;
  }

  public getAnimationClasses(): object {
    if (this.MarginStatus !== MarginStatus.Ok) {
      return {
        wave: true,
        'from-left': true,
        'margin-call': true
      };
    } else {
      return {};
    }
  }

  public get ConnectUrlPort(): string {
    return this._connectUrlPort;
  }

  public get Locale(): LocaleEnum {
    return this.translator.CurrentLocale;
  }
}
