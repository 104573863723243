import {Trade} from './trade';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {JsonProperty} from '@common/shared/utils/json-decorators';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

export class TradeSet {
  protected _logger = LoggerFactory.getLogger('TradeSet');

  protected _tradesDict: Map<number, Trade> = new Map<number, Trade>();

  protected _tradesChanged = new SmartEmitter<Trade[]>();

  protected _invalidated = new SmartEmitter<void>();

  @JsonProperty(Trade, true)
  protected _trades: Trade[] = [];

  protected _wasChanged = false;

  public get Trades(): Trade[] {
    if (this._wasChanged) {
      this._wasChanged = false;
      this._trades = this.sort(Array.from(this._tradesDict.values()));
    }
    return this._trades;
  }

  public constructor() {

  }

  public update(trades: Trade[]): Trade[] {
    this._logger.info(`Update for ${trades.length} trades`);
    trades = this.sort(trades);

    this.deleteNotExisting(trades);
    return this.createOrUpdate(...trades);
  }

  protected deleteNotExisting(trades: Trade[]) {
    this._trades.forEach(trade => {
      const temp = <Trade> trade;
      if (this.searchIndexByTradeId(trades, temp.TradeId) === -1) {
        this.delete(trade);
      }
    });
  }

  private delete(trade: Trade): void {
    this.tradeDeleteSideEffect(trade);
    this._tradesDict.delete(trade.TradeId);
    this._wasChanged = true;
    this._tradesChanged.emit(this.Trades);
  }

  public deleteById(tradeId: number): void {
    const trade = this._tradesDict.get(tradeId);
    if(trade) {
      this.delete(trade);
    }
  }

  public getById(tradeId: number): Trade {
    return this._tradesDict.get(tradeId);
  }

  protected tradeDeleteSideEffect(trade: Trade){

  }

  public createOrUpdate(...trades: Trade[]): Trade[] {
    trades.forEach(trade => {
      this._tradesDict.set(trade.TradeId, trade);
    });

    if(trades.length > 0) {
      this._wasChanged = true;
      this._tradesChanged.emit(this.Trades);
    }


    return null;
  }

  protected sort(trades: Trade[]): Trade[] {
    trades.sort((a, b) => {
      if (a.CloseTime < b.CloseTime) { return 1; }
      if (a.CloseTime === b.CloseTime) { return 0; }
      if (a.CloseTime > b.CloseTime) { return -1; }
    });

    return trades;
  }

  protected searchIndexByTradeId (trades: Trade[], tradeId: number): number {
    if (trades.length === 0) return -1;

    let start = 0;
    let stop = trades.length - 1;
    let middle = Math.floor((start + stop) / 2);

    while (start < stop && trades[middle].TradeId !== tradeId) {
      if (tradeId > trades[middle].TradeId) {
        stop = middle - 1;
      } else {
        start = middle + 1;
      }

      middle = Math.floor((start + stop) / 2);
    }

    return (middle < 0 || trades[middle].TradeId !== tradeId) ? -1 : middle;
  }

  public clear(): void {
    this._tradesDict.clear();
    this._wasChanged = true;
    this._logger.debug('Cleaned')
  }
}
