<div class="number-wrapper" [attr.disabled]="disabled ? 'true' : null" *ngIf="!IsZeTradex; else zetradexInput" >
  <input
    #mainInput
    class="number-input carbon-number-input"
    [disabled]="disabled"
    type="text"
    [min]="min"
    [max]="max"
    number-mask
    [decimalSeparator]="decimalSeparator"
    (number)="setValue($event)"
    [number]="getValue()"
    (blur)="blur()"
    [decimalPlaces]="decimalPlaces"
    [separator]="separator"
    (focusin)="touch()"
    (focus)="onInputFocus($event)"
  />
  <div class="numbers" *ngIf="ShowArrowsFocus">

    <div class="button-up z-arrow-button"  (click)="increment($event)"></div>
    <div class="button-down z-arrow-button" (click)="decrement($event)"></div>

  </div>
</div>

<ng-template #zetradexInput>
  <div class="number-wrapper z-number-wrapper" [attr.disabled]="disabled ? 'true' : null" >

    <div class="z-button-crement z-decrement" (click)="decrement($event)"> - </div>

    <input
      #mainInput
      class="number-input carbon-number-input z-number-input"
      [disabled]="disabled"
      type="text"
      [min]="min"
      [max]="max"
      number-mask
      [decimalSeparator]="decimalSeparator"
      (number)="setValue($event)"
      [number]="getValue()"
      (blur)="blur()"
      [decimalPlaces]="decimalPlaces"
      [separator]="separator"
      (focusin)="touch()"
      (focus)="onInputFocus($event)"
    />

    <div class="z-button-crement z-increment" (click)="increment($event)"> + </div>

  </div>
</ng-template>
