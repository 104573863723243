import {EventEmitter} from '@angular/core';
import {TfcTrade} from '@chartiq-core/trade-from-chart/models/tfc-trade';
import {Symbol} from '@common/symbol/models/symbol';
import {Trade} from '@common/trade/models/trade';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {ITradePromise} from '@common/trade/models/trade-promise';

export type TradeFromChartRightClickCallback = (selectedPrice: number) => void;
export type TradeFromChartContextMenuCallback = (contextMenu: IChartContextMenu) => void;
export type TradeFromChartContextMenuItemSelectedCallback = (item: IChartContextMenuItem) => void;

export interface IPanel {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ITradeFromChart {
  orderOpen: EventEmitter<TfcTrade>;

  getSymbol(): Symbol;
  getOpenTrades(): Array<Trade>;

  modifyOrder(tradeDto: TradeDTO): Promise<ITradePromise>;
  closeOrder(tradeDto: TradeDTO): Promise<ITradePromise>;
}

export interface ITfcFacadeApi {
  orderOpen: EventEmitter<TfcTrade>;
  orderClose: EventEmitter<TfcTrade>;
  orderModify: EventEmitter<TfcTrade>;

  openOrder(order: TfcTrade);
  modifyOrder(order: TfcTrade);
  closeOrder(order: TfcTrade);
}

/*export interface ITfcMiddleApi {
  // orderModified: () => void;
  // contextMenuItemSelected: TradeFromChartContextMenuItemSelectedCallback;
  // contextMenuCreated: TradeFromChartContextMenuCallback;

  // openOrder(tradeDto: TradeDTO): ITradePromise;
  modifyOrder(tradeDto: TradeDTO): ITradePromise;
  closeOrder(orderId: number): ITradePromise;
}*/

export interface ITfcSpecialApi {
  rightClick: TradeFromChartRightClickCallback;
  contextMenuItemSelected: TradeFromChartContextMenuItemSelectedCallback;
  orderModified: () => void;

  showContextMenu(contextMenu: IChartContextMenu);
  modifyOrder();
  convertPixelToPrice(y: number): number;
  convertPriceToPixel(price: number): number;
  getPanel(): IPanel;
}

export interface IChartContextMenu {
  items: Array<IChartContextMenuItem>;
  x?: number;
  y?: number;
  selectedPrice: number;
  symbolName: string;
}

export enum ContextMenuActionType {
  SellStop,
  SellLimit,
  BuyStop,
  BuyLimit,
  MarketSellQuick,
  MarketBuyQuick,
  MarketSellDialog,
  MarketBuyDialog,
  TradeSettings
}

export interface IChartContextMenuItem {
  label: string;
  description?: string;
  actionType: ContextMenuActionType;
  volume?: number;
}

export interface ITradeInfoDialogData {
  trade: TfcTrade;
}

export interface IStopLossData extends ITradeInfoDialogData {
  // If pips is set, pip value is null and vice versa.
  pips?: number;
  pipValue?: number;
}

export interface ITakeProfitData extends ITradeInfoDialogData {
  pips?: number;
  totalProfit?: number;
}

export interface IEntryData extends ITradeInfoDialogData {
  tradeRiskDollars?: number;
  tradeRiskPercents?: number;
  volume?: number;
}
