import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {LayoutService} from '@desktop-core/terminal/services/layout.service';
import {Observable} from 'rxjs';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {Repeater} from '@common/common/utils/repeater';

@Directive({
  selector: '[resize-sensor]'
})
export class ResizeSensorDirective {
  private resizeEmitter = new EventEmitter<void>();

  private layoutSubscription: ISmartSubscription;

  private oldHeight: number;
  private oldWidth: number;

  private waste = 0;

  private readonly wasteThreshold = 10;
  private readonly checkDelay = 150;

  private checkRepeater: Repeater;

  constructor(private layoutService: LayoutService, private elRef: ElementRef) {
    this.layoutSubscription = this.layoutService.ModeChanged.subscribe(() => this.onResize());
    this.checkRepeater = new Repeater(() => this.check(), this.checkDelay);
  }

  @HostListener('window:resize')
  private onResize() {
    this.checkRepeater.start();
  }

  private check() {
    if(this.oldHeight == this.Height && this.oldWidth == this.Width) {
      this.waste++;

      if(this.waste >= this.wasteThreshold) {
        this.checkRepeater.stop();
        this.waste = 0;
      }
    }
    else {
      this.oldWidth = this.Width;
      this.oldHeight = this.Height;
      this.resizeEmitter.emit();
    }
  }

  @Output()
  public get resized(): Observable<void> {
    return this.resizeEmitter.asObservable();
  }

  private get Height(): number {
    return this.elRef.nativeElement.offsetHeight;
  }

  private get Width(): number {
    return this.elRef.nativeElement.offsetWidth;
  }
}
