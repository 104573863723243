import {Notification_SymbolSettingsUpdated} from '../../connection/classes.g';
import {SymbolStorageService} from '../../../symbol/services/symbol-storage.service';
import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {SymbolSettings} from '../../../symbol/models/symbol-settings';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SymbolMessageProcessor extends AbstractMessageProcessor {

  private get SymbolStorage(): SymbolStorageService {
    return this.symbolStorage;
  }

  constructor(serverConnection: ServerInteractionService,
              private symbolStorage: SymbolStorageService) {
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToNotificationSymbolSettingsUpdated();
  }

  private subscribeToNotificationSymbolSettingsUpdated() {
    const subscription = this.serverConnection.on(Notification_SymbolSettingsUpdated.GetMessageType(),
      (notification: Notification_SymbolSettingsUpdated) => this.onSymbolSettingsUpdated(notification),
      error => this.logger.error('Symbol Settings Updating Error: ', error));

    this.saveSubscription(Notification_SymbolSettingsUpdated.GetMessageType(), subscription);

    this.logger.debug(`Subscribed for ${Notification_SymbolSettingsUpdated.GetMessageType()}`);
  }

  private onSymbolSettingsUpdated(notification: Notification_SymbolSettingsUpdated) {
    try {
      let symbol = this.SymbolStorage.findSymbolById(notification.SymbolID);

      if (!symbol || !notification) {
        return;
      }

      const updatedSymbolSettings = new SymbolSettings();
      updatedSymbolSettings.DecimalPlaces = notification.DecimalPlaces;
      updatedSymbolSettings.MaintenanceMarginRate = notification.MaintenanceMarginRate;
      updatedSymbolSettings.InitialMarginRate = notification.InitialMarginRate;
      updatedSymbolSettings.PipSize = notification.PipSize;
      updatedSymbolSettings.AskCorrection = notification.AskCorrection;
      updatedSymbolSettings.BidCorrection = notification.BidCorrection;

      // TODO: Add MaxVolume field to the notification
      symbol.updateSymbolSettings(updatedSymbolSettings);
    }
    catch (e) {
      this.logger.warn(e)
    }
  }
}
