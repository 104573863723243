import {EventEmitter, Injectable} from '@angular/core';
import {ReportService, TransferHistoryReport} from '@common/shared/services/Report/report.service';


@Injectable({
  providedIn: 'root'
})

export class TransactionsService {

  private updateArrayTransactions: EventEmitter<TransferHistoryReport[]> = new EventEmitter<TransferHistoryReport[]>();

  private reports: TransferHistoryReport[] = [];

  constructor(private reportService: ReportService) {
  }

  public run(typePlatform: string, orderRows: number) {
    this.reportService.cfaLoadTransferPage(typePlatform, orderRows).then(async () => {
      const reports = this.reportService.TransferHistoryReports;
      console.log(reports);

      this.reports = reports;

      this.updateArrayTransactions.emit(this.reports);
    });
  }


  public get TransferHistory(): TransferHistoryReport[] {
    return this.reports;
  }

  get UpdateArrayTransferHistory() {
    return this.updateArrayTransactions;
  }

  public OpenTime(date: Date): Date {
    return date;
  }

  public destroyArray() {
    this.reports = [];
  }

  public getSignTransfer(operation: string): string {
    if (operation.toUpperCase() === 'DEPOSIT') {
      return '+';
    }

    if (operation.toUpperCase() === 'WITHDRAWAL') {
      return '-';
    }

    return '';
  }

  public getColorAmount(operation: string): string {
    if (operation.toUpperCase() === 'DEPOSIT') {
      return 'deposit-color-text';
    }
    if (operation.toUpperCase() === 'WITHDRAWAL') {
      return 'withdrawal-color-text';
    }
    return '';
  }

}
