<span class="notification menu-item" (mousedown)="$event.stopPropagation()" (click)="toggleHistory($event)">
  <span class="menu-icon icons-carbons menu-icon-notifcations"
        [class.z-menu-icon-notifcations]="isZeTradex()"
        [attr.after-content]="Unread > 0 ? UnreadPreview : null">
  </span>
</span>


<div class="notification-tape" *ngIf="ShowTape && !ShowHistory">
  <div class="notification hidden colored-body" app-notification [notification]="notification"
       *ngFor="let notification of Notifications; trackBy: trackByNotificationId"
       visible [isVisible]="visibility.visibility" #visibility="visible" debounce="10"
       (destructed)="delete(notification)"
       (created)="onTapeUpdated()">
  </div>
</div>

<div class="notification-history-overlay" *ngIf="ShowHistory"></div>
<div class="notification-history colored-body custom-scroll" visibility-frame (onClickOutside)="closeHistory()" *ngIf="ShowHistory">

  <div class="notification split-border colored-body" app-notification-history [notification]="notification" (click)="clickNotificationHistory($event, notification)"
       *ngFor="let notification of NotificationLog; trackBy: trackByNotificationId" visible [wasShown]="visibility.visibility" #visibility="visible" debounce="50"
       (created)="onHistoryUpdated()">
  </div>



  <div class="nothing-to-show colored-body" *ngIf="NotificationLog.length == 0">
    <l-unit [key]="'Notification_NotificationTapeComponent_nothing-to-show'"></l-unit>
  </div>
</div>
