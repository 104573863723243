import {IWatchListService} from '@common/shared/interfaces/i-watch-list-service';
import {WatchListStorageService} from '@common/watchlist/services/watch-list-storage.service';
import {WatchList} from '@common/watchlist/model/watch-list';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WatchListLocalStorage extends IWatchListService {

  private static watchListsKey = 'watchLists';
  private static currentWatchListKey = 'currentWatchListID';

  constructor(private watchListStorageService: WatchListStorageService) {
    super();
  }

  public saveWatchLists(traderID: number): void {
    const userWatchListsToSave = this.watchListStorageService.getWatchListsDataToSave(traderID);
    const jsonUserWatchListsToSave = JSON.stringify(userWatchListsToSave);
    localStorage.setItem(WatchListLocalStorage.watchListsKey, jsonUserWatchListsToSave);
  }

  public getWatchLists(): WatchList[] {
    const jsonUserWatchLists = localStorage.getItem(WatchListLocalStorage.watchListsKey);
    return JSON.parse(jsonUserWatchLists);
  }

  public deleteWatchLists(): void {
    localStorage.removeItem(WatchListLocalStorage.watchListsKey);
  }

  public setCurrentWatchListID(id: number): void {
    if (id) {
      localStorage.setItem(WatchListLocalStorage.currentWatchListKey, id.toString());
    }
  }

  public getCurrentWatchListID(): number {
    const currentWLID = localStorage.getItem(WatchListLocalStorage.currentWatchListKey);
    return Number(currentWLID);
  }
}
