import {Component, Input, OnInit} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {TradeService} from '@common/trade/services/trade.service';
import {OpenOrderForm} from '@common/trade/components/open-order-form/open-order-form';
import {ITradePromise} from '@common/trade/models/trade-promise';
import {Notification_TicketDecline} from '@common/communication/connection/classes.g';
import {SettingsService} from '@common/trader/services/settings.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {OrderModalInitData} from '@desktop-core/modal/component/modals/modal-open-order/modal-open-order.component';

@Component({
  selector: 'app-open-order-form',
  templateUrl: './open-order-form.component.html',
  styleUrls: ['./open-order-form.component.css']
})
export class OpenOrderFormComponent extends OpenOrderForm implements OnInit {
  private error: string;

  public get Error(): string {
    return this.error;
  }

  @Input()
  public init: OrderModalInitData;

  private _buttonText: string;

  public get ButtonText(): string {
    return this._buttonText || this.init.buttonText;
  }

  public get ButtonDisabled(): boolean {
    return this.buttonDisabled || !this.AmountValid;
  }

  protected buttonDisabled = false;

  public get Symbols(): Symbol[] {
    return this.symbols.Symbols;
  }

  public get Rate(): number {
    return TradePriceProvider.getTradeCurrentPrice(this.Symbol, this.TradeType);
  }

  constructor(private settingsService: SettingsService, private symbols: SymbolStorageService, private tradeService: TradeService) {
    super(settingsService.Settings);
  }

  ngOnInit() {
    if(this.init.order)
      this.copyOrder();
    else this.fillOrder();
  }

  private fillOrder(){
    this.TradeType = this.init.orderType;
    this.Symbol = this.symbols.Symbols[0];
  }

  private copyOrder(){
    this.Order = this.init.order;
    this.Symbol = this.init.order.Symbol;
    this.Amount = this.init.order.Volume;
    this.TradeType = this.init.order.Type;

    if(!this.Order.Symbol){
      this.Symbol = this.getDefaultSymbol();
    }
  }

  public getDefaultSymbol(): Symbol {
    if(this.Order && this.Order.Symbol){
      return this.Order.Symbol;
    }
    else if (this.Symbols.length > 0) {
      return this.Symbols[0];
    }
    else {
      return null;
    }
  }

  public async submit() {
    try {
      const promise = await this.openOrder();
      this.buttonDisabled = true;

      if(!promise) return;

      promise.success(() => {
        this.submitEmitter.emit();
      }).error((message: Notification_TicketDecline) => {
        this.error = message.Message;
      });
    }
    catch (e) {

    }
    finally {
      this.buttonDisabled = false;
    }
  }

  protected async openOrder(): Promise<ITradePromise> {
    const order = this.compileOrder(this.tradeService.getLotDelimiter());

    try {
      this.validateOrder();

      return await this.tradeService.openOrder(order);
    }
    catch (e) {
      this.error = e.message;
      return null;
    }
  }
}
