export class Quote {
  private _bid: number;
  private _ask: number;
  private _spread: number;
  private _askMarketDepth: number;
  private _bidMarketDepth: number;
  private _serverTime: Date;
  private _symbolId: number;

  constructor(symbolId: number,
              bid: number,
              ask: number,
              bidMarketDepth: number,
              askMarketDepth: number,
              serverTime: Date) {
    this._symbolId = symbolId;
    this._bid = bid;
    this._ask = ask;
    this._bidMarketDepth = bidMarketDepth;
    this._askMarketDepth = askMarketDepth;
    this._spread = this._ask - this._bid;
    this._serverTime = serverTime;
  }

  get Bid(): number {
    return this._bid;
  }

  get Ask(): number {
    return this._ask;
  }

  get BidMarketDepth(): number {
    return this._bidMarketDepth;
  }

  get AskMarketDepth(): number {
    return this._askMarketDepth;
  }

  get Spread(): number {
    return this._spread;
  }

  get SymbolId(): number {
    return this._symbolId;
  }

  get ServerTime(): Date {
    return this._serverTime;
  }
}
