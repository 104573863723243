
import {IRolloverCalcFactory} from "@common/shared/calculators/Rollover/IRolloverCalcFactory.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";
import {FixedUSD} from "@common/shared/calculators/Rollover/FixedUSD.g";
import {ShareUSD} from "@common/shared/calculators/Rollover/ShareUSD.g";
import {TomPoints} from "@common/shared/calculators/Rollover/TomPoints.g";
import {ByInterest} from "@common/shared/calculators/Rollover/ByInterest.g";
import {ByPoints} from "@common/shared/calculators/Rollover/ByPoints.g";
import {Zero} from "@common/shared/calculators/Rollover/Zero.g";

export class Factory implements IRolloverCalcFactory {
	public CreateCalculator(type: RolloverCalcType, symbol: IRolloverCalcSymbol): IRolloverCalc {
		switch (type) {
			case RolloverCalcType.FixedUSD:
				return new FixedUSD(symbol);
			case RolloverCalcType.ShareUSD:
				return new ShareUSD(symbol);
			case RolloverCalcType.TomPoints:
				return new TomPoints(symbol);
			case RolloverCalcType.ByPoints:
				return new ByPoints(symbol);
			case RolloverCalcType.ByInterest:
				return new ByInterest(symbol);
			default:
				return new Zero();
		}
	}
}
