
import {IMarginCalcFactory} from '@common/shared/calculators/Margin/IMarginCalcFactory.g';
import {MarginCalcType} from '@common/shared/calculators/Margin/MarginCalcType.g';
import {IMarginCalcSymbol} from '@common/shared/calculators/Margin/IMarginCalcSymbol.g';
import {IMarginCalc} from '@common/shared/calculators/Margin/IMarginCalc.g';
import {Direct} from '@common/shared/calculators/Margin/Direct.g';
import {Indirect} from '@common/shared/calculators/Margin/Indirect.g';
import {USDCC_USDBC} from '@common/shared/calculators/Margin/USDCC_USDBC.g';
import {BCUSD_CCUSD} from '@common/shared/calculators/Margin/BCUSD_CCUSD.g';
import {BCUSD_USDCC} from '@common/shared/calculators/Margin/BCUSD_USDCC.g';
import {CFD_CCUSD} from '@common/shared/calculators/Margin/CFD_CCUSD.g';
import {CFD_USDCC} from '@common/shared/calculators/Margin/CFD_USDCC.g';
import {Zero} from '@common/shared/calculators/Margin/Zero.g';
import {Indirect_index} from '@common/shared/calculators/Margin/Indirect_index.g';
import {CFD_CCUSD_index} from '@common/shared/calculators/Margin/CFD_CCUSD_index.g';
import {CFD_USDCC_index} from '@common/shared/calculators/Margin/CFD_USDCC_index.g';

export class Factory implements IMarginCalcFactory {
	createCalculator(type: MarginCalcType, symbol: IMarginCalcSymbol): IMarginCalc {
		switch (type) {
			case MarginCalcType.Direct:
				return new Direct(symbol);
			case MarginCalcType.Indirect:
				return new Indirect(symbol);
			case MarginCalcType.USDCC_USDBC:
				return new USDCC_USDBC(symbol);
			case MarginCalcType.BCUSD_CCUSD:
				return new BCUSD_CCUSD(symbol);
			case MarginCalcType.BCUSD_USDCC:
				return new BCUSD_USDCC(symbol);
			case MarginCalcType.CFD_CCUSD:
				return new CFD_CCUSD(symbol);
			case MarginCalcType.CFD_USDCC:
				return new CFD_USDCC(symbol);
			case MarginCalcType.CFD_USDCC_index:
				return new CFD_USDCC_index(symbol);
			case MarginCalcType.CFD_CCUSD_index:
				return new CFD_CCUSD_index(symbol);
			case MarginCalcType.Indirect_index:
				return new Indirect_index(symbol);
			default:
				return new Zero();
		}
	}
}
                