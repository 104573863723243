import {CommandIdGenerator} from './command-id-generator';
import {ProtoV2MessageBase} from './proto-v2-message-base';
import {
  Answer_CreateExternalAccountError,
  Answer_CustomSessionError,
  Answer_UpdateExternalAccountError,
  Message_CustomCommand
} from './classes.g';
import {MessageDispatcherService} from './message-dispatcher-service';
import {ConnectionService} from './connection.service';
import {EventEmitter, Injectable} from '@angular/core';
import {CommunicationModule} from '@common/communication/communication.module';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {AppConfig} from '@common/configuration/app-config';

export type ICommandCallbackType = (message: any) => void;
export type ICommandErrorCallbackType = (message: Answer_CustomSessionError) => void;
export type ICommandFinallyCallbackType = () => void;

export interface ICommandPromise {
  success(onSuccess: ICommandCallbackType): ICommandPromise;
  failed(onError: ICommandErrorCallbackType): ICommandPromise;
  finally(onFinally: ICommandFinallyCallbackType): ICommandPromise;
  toNativePromise(): Promise<ProtoV2MessageBase>;
  toTypedNativePromise<T>(): Promise<T>;
}

class CommandPromise implements ICommandPromise {
  private readonly _command: ProtoV2MessageBase;
  private readonly _timestamp: Date;
  private readonly _commandId: number;
  private _onSuccess: ICommandCallbackType[] = [];
  private _onError: ICommandErrorCallbackType[] = [];
  private _onFinally: ICommandFinallyCallbackType[] = [];

  private answer: ProtoV2MessageBase;

  /**
   *
   *
   * @param command Command to be executed
   * @param commandId Command ID
   * @param expectedAnswer Expected answer, set to null if not used or is unknown
   * @param onSuccess Success callback
   * @param onError Error callback
   */
  constructor(command: ProtoV2MessageBase,
              commandId: number,
              ) {
    this._command = command;
    this._timestamp = new Date();
    this._commandId = commandId;
  }

  public success(onSuccess: ICommandCallbackType): ICommandPromise {
    this._onSuccess.push(onSuccess);
    return this;
  }

  public failed(onError: ICommandErrorCallbackType): ICommandPromise {
    this._onError.push(onError);
    return this;
  }

  public finally(onFinally: ICommandFinallyCallbackType): ICommandPromise {
    this._onFinally.push(onFinally);
    return this;
  }

  public getCommandID(): number {
    return this._commandId;
  }

  public onMessageFromConnection(answer: ProtoV2MessageBase) {
    this.answer = answer;

    if (!('Answer_Error' in answer)
        && (answer.MessageType !== 'Answer_CustomSessionError')
        && (answer.MessageType !== 'Answer_CreateExternalAccountError')
        && (answer.MessageType !== 'Answer_UpdateExternalAccountError')
    ) {
      this.commandSucceeded(answer);
      this.runFinally();
      return;
    }

    let answerError = null;

    if (answer.MessageType === 'Answer_CustomSessionError') {
      answerError = answer as Answer_CustomSessionError;
    }

    if (answer.MessageType === 'Answer_CreateExternalAccountError') {
      answerError = answer as Answer_CreateExternalAccountError;
      this.commandFailed(answerError);
      this.runFinally();
    }

    if (answer.MessageType === 'Answer_UpdateExternalAccountError') {
      answerError = answer as Answer_UpdateExternalAccountError;
      this.commandFailed(answerError);
      this.runFinally();
    }


    if (!answerError.Error || answerError.Error.Code === 0) {
      this.commandSucceeded(answer);
      this.runFinally();
      return;
    }

    this.commandFailed(answerError);
    this.runFinally();
  }

  public toNativePromise(): Promise<ProtoV2MessageBase> {
    const promise = this.toTypedNativePromise<ProtoV2MessageBase>();
    return promise;
  }
  public toTypedNativePromise<T>(): Promise<T> {
    let res, rej;

    const promise = new Promise<T>((resolve, reject) => {
      res = resolve;
      rej = reject;

      // @ts-ignore
      this.success(resolve as T);
      this.failed(reject);
    });

    promise.finally(() => this.runFinally());

    if (this.IsError) {
      rej(this.answer);
    } else if (this.IsAnswered) {
      res(this.answer);
    }

    return promise;
  }

  private commandSucceeded(answer: ProtoV2MessageBase): void {
    this._onSuccess.forEach(item => item(answer));
  }

  private commandFailed(answer: Answer_CustomSessionError): void {
    this._onError.forEach(item => item(answer));
  }

  private runFinally(): void {
    this._onFinally.forEach(item => item());
  }

  public get IsAnswered(): boolean {
    return this.answer != null;
  }

  public get IsError(): boolean {
    if (!this.IsAnswered) {
      return false;
    }

    return this.answer instanceof Answer_CustomSessionError;
  }
}

@Injectable({
  /*providedIn: CommunicationModule*/
  providedIn: 'root'
})
export class CommandAnswerService {
  private _sentCommands: Map<number, CommandPromise> = new Map<number, CommandPromise>();
  private _commandIDGenerator: CommandIdGenerator;
  private _deliveryLogger = LoggerFactory.getLogger('MessageDeliveryLogger');
  public newEventEmitterCommand: EventEmitter<void> = new EventEmitter<void>();

  private _successEvent: EventEmitter<string> = new EventEmitter<string>();
  get SuccessEvent(): EventEmitter<string> {
    return this._successEvent;
  }
  public constructor(protected _messageDispatcherService: MessageDispatcherService, protected _connection: ConnectionService,  private appConfig: AppConfig) {}

  public init(commandIDGenerator: CommandIdGenerator) {
    this._commandIDGenerator = commandIDGenerator;
  }


  /**
   * Send command to server and subscribe to result like a promise
   * This method guaranties callback invokation
   * @param command
   */
  public sendCommand(command: ProtoV2MessageBase | Message_CustomCommand): ICommandPromise {

    if (!('CommandID' in command)) {
      throw new Error(`type is not a valid command`);
    }
    const result =  new CommandPromise(command, this._commandIDGenerator.getNextCommandID());
    (command as Message_CustomCommand).CommandID = result.getCommandID();

    this._sentCommands.set(result.getCommandID(), result);

    // @ts-ignore
    this._deliveryLogger.info(`sent command with id: ${command.CommandID}`, command);

    this._connection.send(command);

    if (this.checkModeDebugUsers()) {
      result.success((answer) =>  this.sendSessionStorageCommand(result));
    }

    return result;
  }

  /**
   * Send command to server and handle result like a promise
   * @param command
   */
  public sendMessage(command: ProtoV2MessageBase | Message_CustomCommand): ICommandPromise {
    if (!('CommandID' in command)) {
      throw new Error(`type ${command.constructor.name} is not a valid command`);
    }
    const result = new CommandPromise(command, this._commandIDGenerator.getNextCommandID());

    this._connection.send(command);
    return result;
  }

  public onMessageFromConnection(message: ProtoV2MessageBase) {
    if (!('CommandID' in message)) {
      this.sendMessageToDispatcher(message);
      return;
    }

    (message as Message_CustomCommand).CommandID = Number((message as Message_CustomCommand).CommandID);
    const commandPromise = this.getCommandPromiseFromSentCommands((message as Message_CustomCommand).CommandID);
    if (!commandPromise) {
      this.sendMessageToDispatcher(message);
      return;
    }

    commandPromise.onMessageFromConnection(message);
  }

  private getCommandPromiseFromSentCommands(cmdId: number): CommandPromise {
    const promise = this._sentCommands.get(cmdId);
    this._sentCommands.delete(cmdId);
    return promise;
  }

  private sendMessageToDispatcher(message: ProtoV2MessageBase) {
    this._messageDispatcherService.onMessageFromConnection(message);
    this._successEvent.emit(message.MessageType);
  }

  private checkModeDebugUsers(): boolean {
    const result = JSON.parse(sessionStorage.getItem('ModeDebugUsers'));
    if (result === null ) {
      return false;
    }

    return result;
  }

  private sendSessionStorageCommand(result) {
    let tempArray =  JSON.parse(sessionStorage.getItem('modeDebugUsers'));
    const item = {
      command: result._command,
      timestamp: result._timestamp,
      answer: result.answer
    };

    if (tempArray == null) {
      sessionStorage.setItem('modeDebugUsers', JSON.stringify([item]));
    } else {
      if (tempArray.length > 50) {
        tempArray = tempArray.slice(20);
      }
      tempArray = [...tempArray, item];
      sessionStorage.setItem('modeDebugUsers', JSON.stringify(tempArray));
    }

    this.newEventEmitterCommand.emit();
  }
}
