import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[min-value]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MinValueValidator,
    multi: true
  }]
})
export class MinValueValidator implements Validator {

  constructor() { }

  @Input('min-value')
  public minValue = 0;

  validate(control: AbstractControl): ValidationErrors | null {
    const value = Number(control.value);

    return this.minValue <= value || value == 0 ?
      null :
      {
        minValue: {limit: this.minValue, actual: value}
      }
  }

}
