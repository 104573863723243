import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {SymbolStorageService} from '../../symbol/services/symbol-storage.service';
import {TradeStorage} from '../../trade/models/trade-storage';
import {EventEmitter, Injectable} from '@angular/core';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {AppConfig} from '@common/configuration/app-config';
import {TraderCommandSender} from '@common/communication/command-sender/trader-command-sender';
import {Answer_GetTraderInfo} from '@common/communication/connection/classes.g';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {RateType} from '@common/currency/models/rate-type';
import {MailHeadersCommandSender} from '@common/communication/command-sender/mail-headers-command-sender';
import {Router} from '@angular/router';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {ConnectionService} from '@common/communication/connection/connection.service';
import {WatchListViewControlService} from '@common/watchlist/services/watch-list-view-control.service';
import {WatchListMap} from '@common/watchlist/services/watch-list-storage.service';
import {OperationsWithUrl} from '@common/trade/utils/operations-with-url';


@Injectable({
  providedIn: 'root'
})
export class Trader {

  private logger = LoggerFactory.getLogger('Trader');
  private _traderName: string | null;
  private _firstName: string | null;
  private _lastName: string | null;
  private _middleName: string | null;
  private _traderId: number | null;
  private _tradeGroupId: number | null;
  private _tradeGroupName: string | null;
  private _backOfficeUrl: string | null;
  private _shouldChangePassword: boolean;
  private _changePasswordEvent = new SmartEmitter<'Success' | 'Error'>();
  private _tradeHistoryUrl: string | null;
  private _backOfficeIndexPageUrl: string | null;
  private _isReadOnlyAccount: boolean | null;
  private _loaded: boolean;
  private _watchListID: number;
  private _externalAccountID: string | null;
  private _watchlistContentID: number;
  private _isDeposited = false;
  private _isDepositedChangeEmitter: EventEmitter<void> = new EventEmitter<void>();
  private _isGuest = true;
  private _isLiveAccount = false;
  private _isLiveAccountEmitter: EventEmitter<void> = new EventEmitter<void>();
  private _isAdvancedSubscription = true;
  private _isNakedSellsAllowed = false;
  private _connectionChangedEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _withdrawalLimit: number | null;
  private _serverTime: Date | null;
  private _isLoggedIn: boolean;
  // приватны значения типов минимальных сум для депозитов первого и последующего
  private _minFirst: number | string;
  private _minNext: number | string;
  // значения для групп учавствующих в челендже
  private _isChallengeAccount: boolean | null;
  public urlToOpenAfterLogin = 'terminal';
  private _lotDelimeter: number | null;
  private _lotTypeName: string | null;

  set ExternalAccountID(value: string) {
    this._externalAccountID = value;
  }

  get ExternalAccountID() {
    return this._externalAccountID;
  }


  get TraderName(): string {
    return this._traderName;
  }

  set TraderName(value: string) {
    this._traderName = value;
  }

  // гетеры и сеторы на получения минимальных сум для депозитов первого и последующего

  get MinFirst(): any {
    if (this._minFirst == 0) {
      this._minFirst = this.appConfig.Settings.deposit.FirstPayment;
    }
    return this._minFirst;
  }

  set MinFirst(value: any) {
    this._minFirst = Number(value);
  }

  get MinNext(): any {
    if (this._minNext == 0) {
      this._minNext = this.appConfig.Settings.deposit.SubsequentPayment;
    }
    return this._minNext;
  }

  set MinNext(value: any) {
    this._minNext = Number(value);
  }

  get FirstName(): string {
    return this._firstName;
  }

  set FirstName(value: string) {
    this._firstName = value;
  }

  get LastName(): string {
    return this._lastName;
  }

  set LastName(value: string) {
    this._lastName = value;
  }

  get MiddleName(): string {
    return this._middleName;
  }

  set MiddleName(value: string) {
    this._middleName = value;
  }

  get TraderId(): number {
    return this._traderId;
  }

  set TraderId(traderId: number) {
    this._traderId = traderId;
  }

  get WithdrawalLimit(): number | null {
    return this._withdrawalLimit;
  }

  set WithdrawalLimit(value: number | null) {
    this._withdrawalLimit = value;
  }

  get IsLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  set TradeGroupId(tradeGroupId: number) {
    this._tradeGroupId = tradeGroupId;
    this._symbolStorage.traderGroupId = tradeGroupId;
  }

  get TradeGroupId(): number {
    return this._tradeGroupId;
  }

  set TradeGroupName(tradeGroupName: string) {
    this._tradeGroupName = tradeGroupName;
  }

  get TradeGroupName(): string {
    return this._tradeGroupName;
  }

  get BackOfficeUrl(): string {
    return this._backOfficeUrl;
  }

  set BackOfficeUrl(value: string) {
    this._backOfficeUrl = value;
  }

  get ShouldChangePassword(): boolean {
    return this._shouldChangePassword;
  }

  set ShouldChangePassword(v: boolean) {
    this._shouldChangePassword = v;
  }

  get ChangePasswordEvent(): ISmartObserver<'Success' | 'Error'> {
    return this._changePasswordEvent;
  }

  set WatchListID(v: number) {
    this._watchListID = v;
  }

  set WatchListContentID(v: number) {
    this._watchlistContentID = v;
  }

  get TradeHistoryUrl(): string {
    let customHistoryUrl = this.checkCustomTradeHistoryUrl(this.translatorService.CurrentLocale.toString());
    const sessionID = this.localStorage.getSessionKey();

    if (customHistoryUrl !== undefined) {
      customHistoryUrl = OperationsWithUrl.replaceDataUrl(customHistoryUrl, this, sessionID);
      return customHistoryUrl;
    }

    const interval = 'Day';
    const now = new Date();
    const periodBegin = `${now.getUTCMonth() + 1}/${now.getUTCDate()}/${now.getUTCFullYear()}`;
    const timeZoneId = 'UTC';
    const configHistoryUrl = this.appConfig.Settings.urls && this.appConfig.Settings.urls.HistoryUrl ? this.appConfig.Settings.urls.HistoryUrl : undefined;

    return !configHistoryUrl ? `${this.BackOfficeIndexPageUrl}/IndividualTrader/TradeHistoryReport.aspx` : configHistoryUrl + `?SessionID=${sessionID}&Interval=${interval}&PeriodBegin=${periodBegin}&TimeZoneID=${timeZoneId}`;
  }

  set TradeHistoryUrl(value: string) {
    this._tradeHistoryUrl = value;
  }

  get Loaded(): boolean {
    return this._loaded;
  }

  get BackOfficeIndexPageUrl(): string {
    return this._backOfficeIndexPageUrl;
  }

  set BackOfficeIndexPageUrl(value: string) {
    this._backOfficeIndexPageUrl = value;
  }

  /**
   * @deprecated
   */
  get TradeStorage(): TradeStorage {
    return this._tradeStorage;
  }

  get IsReadOnlyAccount(): boolean {
    return this._isReadOnlyAccount;
  }

  set IsReadOnlyAccount(value: boolean) {
    this._isReadOnlyAccount = value;
  }

  public set IsDeposited(v: boolean) {
    this._isDeposited = v;
    this._isDepositedChangeEmitter.emit();
  }

  public get IsDeposited(): boolean {
    return this._isDeposited;
  }

  public get DepositChangeEmitter(): EventEmitter<void> {
    return this._isDepositedChangeEmitter;
  }

  public get IsGuest(): boolean {
    return this._isGuest;
  }

  public set IsGuest(v: boolean) {
    this._isGuest = v;
  }

  public get IsLiveAccount(): boolean {
    return this._isLiveAccount;
  }

  public set IsLiveAccount(v: boolean) {
    this._isLiveAccount = v;
  }

  public get IsAdvancedSubscription(): boolean {
    return this._isAdvancedSubscription;
  }

  public set isNakedSellAllowed(v: boolean) {
    this._isNakedSellsAllowed = v;
  }

  public get isNakedSellAllowed(): boolean {
    return this._isNakedSellsAllowed;
  }

  public get ConnectionChangedEmitter(): EventEmitter<boolean> {
    return this._connectionChangedEmmiter;
  }

  get IsChallengeAccount(): boolean {
    return this._isChallengeAccount;
  }

  set IsChallengeAccount(value: boolean) {
    this._isChallengeAccount = value;
  }

  set ServerTime(value: Date) {
    this._serverTime = value;
  }

  get ServerTime() {
    return this._serverTime;
  }

  get LotDelimeter(): number | null {
    return this._lotDelimeter;
  }

  set LotDelimeter(value: number | null) {
    this._lotDelimeter = value;
  }

  get LotTypeName(): string | null {
    return this._lotTypeName;
  }

  set LotTypeName(value: string | null) {
    this._lotTypeName = value;
  }

  constructor(
    private _symbolStorage: SymbolStorageService,
    private _tradeStorage: TradeStorage,
    private localStorage: LocalStorageService,
    private appConfig: AppConfig,
    private traderCommandSender: TraderCommandSender,
    private mailHeadersCommandSender: MailHeadersCommandSender,
    private router: Router,
    private translatorService: TranslatorService,
    private connectionService: ConnectionService,
    private watchListControlService: WatchListViewControlService) {

    this.connectionService.onTraderBaseInfoReceived.subscribe(async (value: [boolean, boolean]) => {
      await this.onTraderBaseInfoReceived(value[0], value[1]);
    });
  }

  private checkCustomTradeHistoryUrl(language: string): string {
    let customHistoryUrlMap =  undefined;

    if (this.appConfig.Settings.customIntegrationSetting && this.appConfig.Settings.customIntegrationSetting.TradingHistoryUrlLanguageMap &&
        this.appConfig.Settings.customIntegrationSetting.TradingHistoryUrlLanguageMap[language] && this.appConfig.Settings.customIntegrationSetting.TradingHistoryUrlLanguageMap[language].length > 0) {
      customHistoryUrlMap = this.appConfig.Settings.customIntegrationSetting.TradingHistoryUrlLanguageMap[language];
    }

    return customHistoryUrlMap;
  }

  // public setIsReadOnlyAccount(value: boolean) {
  //   this._isReadOnlyAccount = value;
  // }

  public getIsLiveAccountEventEmitter(): EventEmitter<void> {
    return this._isLiveAccountEmitter;
  }

  public logout() {
    this._traderName = '';
    this._traderId = 0;
    this._isLoggedIn = false;
    this._loaded = false;
    this._connectionChangedEmmiter.emit(true);
  }

  public onConnectionLost() {
    this._connectionChangedEmmiter.emit(false);
  }

  public onConnectionRestored() {
    this._connectionChangedEmmiter.emit(true);
  }

  private getBoolean(value) {
    switch (value) {
      case true:
      case 'true':
      case 'True':
      case 'TRUE':
        return true;
      default:
        return false;
    }
  }

  private async onTraderBaseInfoReceived(isReadOnly: boolean, isDeposited: boolean) {
    this.IsReadOnlyAccount = this.getBoolean(isReadOnly);
    this._isDeposited = this.getBoolean(isDeposited);
    await this.login();
  }

  public async login() {
    if (localStorage.getItem('wasLoggedInFromNotGuest') === 'true' && localStorage.getItem('watchListsWasCopied') === null) {
      this.watchListControlService.copyWLFromLocalStorageToDataBase(this).then(() => {
        localStorage.setItem('watchListsWasCopied', 'true');
      });
    }

    if (this.TraderName === this.appConfig.Settings.connection.GuestAccountLogin) {
      this.IsGuest = true;
    } else {
      this.IsGuest = false;

      if (!this.IsReadOnlyAccount) {
        this.IsLiveAccount = true;
      } else {
        this.IsLiveAccount = false;
      }
    }

    this._isLoggedIn = true;
    this._loaded = false;
    await this.router.navigateByUrl(this.urlToOpenAfterLogin);
  }

  public async load(): Promise<Trader> {
    const answer = await this.traderCommandSender.getTrader().toTypedNativePromise<Answer_GetTraderInfo>();
    this.BackOfficeUrl = answer.BackOfficeUrl;
    this.TradeHistoryUrl = answer.TradeHistoryUrl;
    this.BackOfficeIndexPageUrl = answer.BackOfficeIndexPageUrl;

    this.IsReadOnlyAccount =
      answer.IsInvestorAccount.toString() === 'true' ||
      answer.IsInvestorAccount.toString() === 'True';

    AccountCurrency.Instance.ISOCurrencyId = Number(answer.TraderCurrency_ISOCurrencyID);
    AccountCurrency.Instance.ISOCurrencyName = answer.TraderCurrency_ISOCurrencyName;
    AccountCurrency.Instance.RateType = <RateType>Number(answer.TraderCurrency_RateType);
    AccountCurrency.Instance.RateSymbolId = Number(answer.TraderCurrency_RateSymbolID);
    AccountCurrency.Instance.RateAsk = Number(answer.TraderCurrency_RateAsk);
    AccountCurrency.Instance.RateBid = Number(answer.TraderCurrency_RateBid);

    await this.mailHeadersCommandSender.getMessageHeaders(this.TraderId).toNativePromise();

    console.log(answer);

    return this;
  }

  public markTraderLoaded() {
    this._loaded = true;
  }

  public passwordChanged() {
    this._shouldChangePassword = false;
    this._changePasswordEvent.emit('Success');
  }

  public passwordChangeError() {
    this._changePasswordEvent.emit('Error');
  }

  public getNextWatchListID(): number {
    this._watchListID++;
    return this._watchListID;
  }

  public getNextWatchListContentID(): number {
    this._watchlistContentID++;
    return this._watchlistContentID;
  }

  public async createWatchList(name: string): Promise<string> {
    return await this.watchListControlService.createWatchList(name, this);
  }

  public async updateWatchList(watchListID: number, watchListName: string): Promise<string> {
    return await this.watchListControlService.updateWatchList(watchListID, watchListName, this);
  }

  public async deleteWatchList(id: number): Promise<number> {
    return await this.watchListControlService.deleteWatchList(id, this);
  }

  public async addSymbolToWatchList(watchListID: number, symbolID: number) {
    await this.watchListControlService.addSymbolToWatchList(watchListID, symbolID, this);
  }

  public async removeSymbolFromWatchList(watchListID: number, contentID: number): Promise<boolean> {
    return await this.watchListControlService.removeSymbolFromWatchList(watchListID, contentID, this);
  }

  public getTraderWatchLists(): WatchListMap[] {
    return this.watchListControlService.getTraderWatchLists();
  }

  public onNotificationBalance(balance: number) {
    if (this.IsDeposited === false) {
      this.IsDeposited = true;
    }
  }


  public checkUsersWhoHaveAccess(key: string, keyList: string): boolean {
    let access = false;

    if (this.appConfig.Settings[key] !== undefined) {
      access = this.appConfig.Settings[key];
    }

    if (this.appConfig.Settings[keyList] !== undefined && this.appConfig.Settings[keyList].length > 0) {
      this.appConfig.Settings[keyList].forEach((traderId: number) => {
        if (traderId === this.TraderId) {
          access = true;
        }
      });
    }

    return access;
  }

}
