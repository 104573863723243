<app-modal-placeholder [header]="Header" (onClose)="close()">
  <div class="app-modal-mobile-header-container">
    <div class="title-right">
    </div>
  </div>
  <div class="c-form-container">
    <form name="orderForm" action="#" [formGroup]="pendingForm" class="c-form order-form highlited-inputs">

      <div class="sector-header">
        <span>
          <l-unit [key]="'Modal_PendingOrderFormComponent_OrderParametersTitle'"></l-unit>
        </span>
      </div>

      <div class="container">
        <div class="first-column column">
          <div class="c-form-group">
            <label for="selected-symbol" class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_SymbolTitle'"></l-unit></label>
            <select name="selected-symbol" (change)="onSymbolChange($event.target.value)" id="selected-symbol" class="c-select" formControlName="symbol">
              <option *ngFor="let symbol of Symbols; let i = index" [value]="i">{{symbol.SymbolName}}</option>
            </select>
          </div>

          <div class="c-form-group">
            <label for="pending-type" class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_TypeTitle'"></l-unit></label>
            <select name="pending-type" (change)="onTypeChange($event.target.value)" id="pending-type" class="c-select" formControlName="pending-type">
              <option *ngFor="let type of types" [value]="type.value">{{type.name}}</option>
            </select>
          </div>

          <div class="c-form-group exp-date-group" [class.incorrect]="expDate.invalid && expDate.dirty">
            <label class="c-label">
              <l-unit [key]="'Modal_PendingOrderFormComponent_ExpirationDateTitle'"></l-unit>
            </label>
            <span class="exp-date-value" (click)="onDateClick($event)">{{DatePreview}}</span>
            <span class="app-modal-reset-date-button" (click)="onResetDate($event)" [hidden]="resetDateHidden"></span>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectDateError'"></l-unit>
            </span>

            <div class="datetime-picker" *ngIf="ShowPicker">
              <datetime-picker alwaysOnScreen name="expiration-date" (onClickOutside)="closeDateTimePicker()" (datetime)="onSelectDate($event)"></datetime-picker>
            </div>
          </div>
        </div>
        <div class="second-column column">
          <div class="c-form-group" [class.incorrect]="amount.invalid && amount.dirty">

            <span class="block-header">
              <l-unit *ngIf="!Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_quantity-amount-header'"></l-unit>
              <l-unit *ngIf="Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_lot-header'"></l-unit>
               <span [hidden]="Order.Symbol.TradingStep === 0">(step: {{showStep()}})</span>
            </span>

            <number-input name="volume"
                          (changed)="onAmountChange($event)"
                          formControlName="amount"
                          [disabled]="isDisabledButtons()"
                          [decimalPlaces]="VolumeDecimalPlaces"
                          [step]="TradingStep">
            </number-input>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectAmountError'"></l-unit>
            </span>
          </div>

          <div class="c-form-group" [class.incorrect]="rate.invalid && type.dirty">
            <label class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_RateTitle'"></l-unit></label>
            <number-input [step]="Step" name="rate" (changed)="onRateChange($event)"
                          formControlName="open-price" [disabled]="isDisabledButtons()">
            </number-input>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectPriceError'"></l-unit>
            </span>
          </div>

          <div class="c-form-group">
            <label class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_CurrentPriceTitle'"></l-unit>: </label>
            <div class="current-price-value"><span>{{CurrentRate}}</span></div>
          </div>
        </div>

      </div>

      <div class="sector-header">
        <span>
          <l-unit [key]="'Modal_PendingOrderFormComponent_StopOrdersTitle'"></l-unit>
        </span>
      </div>

      <div class="container">
          <app-sltp-input
            [appName]="appName"
            [trade]="Order"
            [price]="OpenPrice"
            [stopType]="0"
            [disabled]="isDisabledButtons()"
            (validityError)="ValidityError($event)"></app-sltp-input>
      </div>

      <red-alert *ngIf="Error">
        {{Error}}
      </red-alert>

      <red-alert [hidden]="isHiddenTriggerNotification()">
        <l-unit [key]="'Modal_PendingOrderFormComponent_OrderTriggered'"></l-unit>
      </red-alert>

      <div style="width: 100%; text-align: center">
        <button class="c-button c-form-button-submit order-button"
               [class.button-success-blue-color]="IsZeTradex"
               [disabled]=" (pendingForm.invalid ? 'disabled' : null) || isDisabledButtons() || ValidityErrorModify || Error"
               (click)="submit()">
          <l-unit [key]="'Modal_ModifyPendingOrderModalComponent_modify-order-button'"></l-unit>
        </button>
      </div>
    </form>
  </div>
</app-modal-placeholder>
