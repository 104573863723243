import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {Answer_GetAuthTokens, Answer_LoginSuccess} from '../../connection/classes.g';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Session} from '@common/auth/models/session';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenMessageProcessor extends AbstractMessageProcessor {
  public constructor(serverConnection: ServerInteractionService, private credentialsStorage: LocalStorageService){
    super(serverConnection);
  }

  public subscribe(): void {
    const subscription = this.serverConnection.on(Answer_LoginSuccess.GetMessageType(),
      (answer: Answer_LoginSuccess) => this.onTokenChanged(answer),
      (error: any) => this.logger.error('Token handling error:', error)
    );

    this.saveSubscription(Answer_LoginSuccess.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Answer_LoginSuccess.GetMessageType()}`);
  }

  private onTokenChanged(answer: Answer_LoginSuccess) {
    this.logger.debug('Tokens updated');

    const date = new Date();

    const validityTime = 15;

    const session = new Session(
      answer.AccessToken,
      new Date(date.setMinutes(date.getMinutes() + validityTime)),
      validityTime,
      answer.TraderID,
      answer.LoginName
    );

    this.credentialsStorage.saveSession(session);
  }
}
