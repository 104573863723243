import {Injectable} from '@angular/core';
import {BarProviderService, IBarProvider} from '@common/charting/services/bar-provider.service';
import {QuoteTick} from '@common/charting/models/quote-tick';
import {LibrarySymbolInfo, PeriodParams, ResolutionString} from '@tradingview/charting_library';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {DateUtils} from '@common/common/utils/date-utils';
import {BarsAndMetadata} from '@common/trading-view-charts/models/bars-and-metadata';
import {HistoryBar} from '@common/trading-view-charts/models/history-bar';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Environment} from '@common/environment';

export interface BarAggregator {
  barTick(barTick: QuoteTick, resolution: ResolutionString, symbolName: string): Promise<HistoryBar>;

  getBars(symbolInfo: LibrarySymbolInfo,
          resolution: ResolutionString,
          periodParams: PeriodParams,
          priceDirection: PriceDirection): Promise<BarsAndMetadata>;

  reset(): void;
}

@Injectable({
  providedIn: 'root'
})
export class BarAggregatorService implements BarAggregator {
  private barProvider: IBarProvider;
  private logger = LoggerFactory.getLogger('BarAggregatorService');
  private lastBar: HistoryBar;
  private currentBar: HistoryBar;
  private nextTime: number;
  private readonly tzOffset: number | null;
  private currentInterval: string;
  private currentResolution: string;
  private currentSymbolName: string;
  private checkBar: HistoryBar;
  private isFilteringOutEmptyCandles = true;

  public set IsFilteringOutEmptyCandles(v: boolean) {
    this.isFilteringOutEmptyCandles = v;
  }

  public constructor(barProvider: BarProviderService) {
    this.barProvider = barProvider;
    this.tzOffset = -new Date().getTimezoneOffset();
  }

  barTick(barTick: QuoteTick, resolution: string, symbolName: string): Promise<HistoryBar> {
    return new Promise<HistoryBar>((resolve, reject) => {
      try {
        // при пустом массиве HistoryBars и this.currentBar undefined и this.lastBar = undefined создаются на основании тика,
        // чтобы график мог показывать динамику цен и отображать свечи
        // if (this.currentBar === undefined && this.lastBar === undefined || resolution !== this.lastResolution) {
        //   const date = new Date();
        //   date.setUTCSeconds(0);
        //   const timeUTC = this.getNextBarTime(date.getTime(), resolution);
        //   this.currentBar = this.createNewBar(this.getCurrentPrice(barTick), timeUTC);
        //   this.lastBar = this.currentBar;
        //   this.lastResolution = resolution;
        // }

        this.recalculateBar(barTick, resolution, symbolName);

        if (this.currentBar) {
          resolve(this.currentBar.clone()); // clone to not send object to the TV as it changes its time for some reason
        }

      } catch (e) {
        console.log(e);
        this.logger.warn('Bar tick reject', e);
        reject(e);
      }
    });
  }

  private recalculateBar(barTick: QuoteTick, resolution: string, symbolName: string) {
    if (this.currentSymbolName === symbolName) {
      if (!this.lastBar && !this.currentBar) {
        this.lastBar = this.checkBar;
        this.currentBar = this.checkBar;
        this.checkBar = undefined;
      }

      const currentPrice = this.getCurrentPrice(barTick);

      let lastBarTime = 0;

      if (this.lastBar && this.lastBar.time) {
        lastBarTime = this.lastBar.time;
      }

      const nextTime = this.getNextBarTime(lastBarTime, resolution);

      if (lastBarTime !== 0) {
        if (this.convertFromUTC(barTick.ServerTime.getTime(), resolution ) >= nextTime) {
          this.forceNewBar(currentPrice, nextTime);
        } else {
          this.currentBar.high = Math.max(this.currentBar.high, currentPrice);
          this.currentBar.low = Math.min(this.currentBar.low, currentPrice);
          this.currentBar.close = currentPrice;
          this.currentBar.volume += 1;
        }
        this.lastBar = this.currentBar;
      }


    }
  }

  private forceNewBar(currentPrice: number, nextTime: number): void {
    this.lastBar = this.currentBar;
    this.currentBar = this.createNewBar(currentPrice, nextTime);
    this.currentBar.open = this.lastBar.close;
  }

  private createNewBar(currentPrice: number, time: number): HistoryBar {
    const bar = new HistoryBar();
    bar.time = time;
    bar.open = currentPrice;
    bar.high = currentPrice;
    bar.low = currentPrice;
    bar.close = currentPrice;
    bar.volume = 1;

    return bar;
  }

  private getCurrentPrice(barTick: QuoteTick): number {
    return (barTick.Ask !== null) ? barTick.Ask : barTick.Bid;
  }

  private getNextBarTime(barTime: number, resolution: string) {
    const date = new Date(barTime);

    if (resolution === '5S') { // 5 Second interval
      date.setUTCSeconds(date.getUTCSeconds() + 5);
    } else if (resolution === 'D' || resolution === '1D') { // 1 Day interval
      date.setUTCDate(date.getUTCDate() + 1);
    } else if (resolution === 'W' || resolution === '1W') { // 1 Week interval
      date.setUTCDate(date.getUTCDate() + 7);
    } else if (resolution === 'M' || resolution === '1M') { // 1 Month interval
      date.setUTCMonth(date.getUTCMonth() + 1); // TODO: Check 1 month offset
    } else { // Minute/Hour interval
      try {
        // tslint:disable-next-line:radix
        const minutes = parseInt(resolution);

        if (minutes >= 60) { // Hours
          date.setUTCHours(date.getUTCHours() + minutes / 60);
        } else { // Minutes
          date.setUTCMinutes(date.getUTCMinutes() + minutes);
        }
      } catch (ex) {
        throw new Error('Couldn\'t parse minutes for resolution: ' + resolution);
      }
    }

    return date.getTime();
  }

  private convertFromUTC(time: number, period: string): number {
    // добавил еще один аргумент для функции, который проверяет период если он не NAN то он передается для добавления количества минут
    // иначе добавляется this.tzOffset которая равна 300 минутам (в нашем часовом поясе) для корректного отображения свечи создание новых

    let minutes = this.tzOffset;
    if (Environment.IsMobileVersion ) {
      minutes = Number(period);
      return time;
    }

    return DateUtils.addMinutes(new Date(time), minutes).getTime();
  }

  async getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, priceDirection: PriceDirection ): Promise<BarsAndMetadata> {

    this.currentInterval = JSON.parse(sessionStorage.getItem('currentIntervalForChart')) !== null ? JSON.parse(sessionStorage.getItem('currentIntervalForChart')) : resolution;

    if (!this.currentResolution && !this.currentSymbolName) {
      this.currentSymbolName = symbolInfo.name;
      this.currentResolution = resolution;
    } else {
      if (this.currentResolution !== this.currentInterval) {
        this.currentResolution = this.currentInterval;
      }
      if (this.currentSymbolName !== symbolInfo.name) {
        this.currentSymbolName = symbolInfo.name;
      }
    }

    const bars = await this.barProvider.retrieveBars(symbolInfo, resolution, periodParams.from, periodParams.to, periodParams.firstDataRequest, priceDirection);

    /*Tv starts week from monday while we are start it from sunday*/
    if (resolution === 'W') {
      for (const el of bars.Bars) {
        el.time = el.time + (1000 * 60 * 60 * 24);
      }
    }

    if (periodParams.firstDataRequest) {
      this.reset();
      if (bars.Bars.length > 0) {
        const bar = bars.Bars.slice(-1)[0];
        if (this.currentInterval === resolution) {
          this.lastBar = bar.clone();
          this.currentBar = bar.clone();
          this.checkBar = bar.clone();
          this.getNextBarTime(this.convertFromUTC(bar.time, resolution), resolution);
        }
      }
    }

    // проверка на наличие пустых свечей для их фильтрации и отображении только активных свечей для графика
    if (this.isFilteringOutEmptyCandles) {
      const s = bars.Bars.filter((e) =>  e.volume === 0 );
      if (s.length > 0) {
        // console.log('Total candles', bars.Bars.length);
        // console.log('Total empty candles', s.length);
        // console.log('Total active candles', r.length);

        const r = bars.Bars.filter((e) =>  e.volume !== 0  );
        bars.Bars = r;
      }
    }

    return bars;
  }

  resetCurrent(): void {
    this.currentResolution = undefined;
    this.currentSymbolName = undefined;
  }

  reset(): void {
    this.lastBar = undefined;
    this.currentBar = undefined;
    this.nextTime = 0;
  }
}

