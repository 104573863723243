import {MailMessage} from '@common/notification/models/mail-message';
import {MailMessageBody} from '@common/notification/models/mail-message-body';

export class MailComposed {
  private _mailMessage: MailMessage;
  private _mailMessageBody: MailMessageBody;

  public get MailMessage(): MailMessage {
    return this._mailMessage;
  }
  public set MailMessage(value: MailMessage) {
    this._mailMessage = value;
  }

  public get MailMessageBody(): MailMessageBody {
    return this._mailMessageBody;
  }
  public set MailMessageBody(value: MailMessageBody) {
    this._mailMessageBody = value;
  }

  constructor() {
  }
}
