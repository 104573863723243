import {IProfitCalc} from './iprofit-calc';
import {getTradeSign, TradeType} from '../../models/trade-type';
import {TradeProfitSign} from '../../models/trade-profit-sign';
import {Symbol} from '@common/symbol/models/symbol';
import {ProfitFactory} from './profit/profitFactory';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {Factory} from '@common/shared/calculators/Profit/Factory.g';
import {IProfitCalc as IProfitCalc2 } from '@common/shared/calculators/Profit/IProfitCalc.g';

export class ProfitCalculator {
  private readonly calculator: IProfitCalc2;
  private tradeSign: TradeProfitSign;

  public get CurrentPrice() {
    return TradePriceProvider.getTradeCurrentPrice(this.symbol, this.tradeType);
  }

  public get PipOffset() {
    return this.CurrentPrice + this.symbol.PipSize;
  }

  public constructor(private symbol: Symbol, private tradeType: TradeType) {
    if (!this.symbol) {
      this.calculator = null;
    } else {
      // this.calculator = new ProfitFactory().createCalculator(this.symbol.ProfitCalcType, this.symbol.ProfitCalcSymbol);

      this.calculator = new Factory().createCalculator(this.symbol.ProfitCalcType, this.symbol.ProfitCalcSymbol);

    }
    this.resolveSign();
  }

  public calculatePriceAtProfit(volume: number, openPrice: number, profit: number): number {
    const exchanged = AccountCurrency.Instance.reverseExchange(profit);

    this.resolveSign();
    return this.calculator.CalculatePriceAtProfit(volume * this.tradeSign, openPrice, exchanged) ;
  }

  public calculateProfit(volume: number, openPrice: number, closePrice: number): number {
    if (!this.calculator) {
      throw new Error('Calculator does not exists');
    }
    this.resolveSign();

    let profit = 0;

    // если текущая цена равна нулю, то расчеты не производятся и передается нуль
    if (closePrice !== 0 ) {
      profit = this.calculator.Calculate(volume * this.tradeSign, openPrice, closePrice);
    }

    if (Number.isNaN(profit) || !Number.isFinite(profit)) {
      throw new Error(`Profit is incorrect | Volume: ${volume}, Open Price: ${openPrice}, Close Price: ${closePrice}`);
    }

    const result = AccountCurrency.Instance.exchange(profit);

    return result;
  }

  public calculatePipVolume(pipValue: number, openPrice: number, closePrice: number): number {
    if (!this.calculator) {
      return NaN;
    }
    this.resolveSign();

    const convertPrice: number = (this.symbol.ProfitCalcSymbol.getCCConvertSymbol().getAsk() + this.symbol.ProfitCalcSymbol.getCCConvertSymbol().getBid()) / 2.0;

    return this.calculator.CalculateWithConvertion(pipValue, openPrice, closePrice, convertPrice);
    // return this.calculator.calculatePipVolume(pipValue, openPrice, closePrice);
  }

  public calculatePipVolumeAtValue(pipValue: number): number {
    return this.calculatePipVolume(pipValue, this.CurrentPrice, this.PipOffset);
  }

  public calculatePipValue(volume: number): number {
    return this.calculateProfit(volume, this.CurrentPrice, this.PipOffset);
  }

  public calculatePipValueForPending(volume: number, openPrice: number): number {
    return this.calculateProfit(volume, openPrice, openPrice + this.symbol.PipSize);
  }

  private resolveSign() {
    this.tradeSign = getTradeSign(this.tradeType);
  }

  public calculatePips(openPrice: number, closePrice: number): number {
    return (closePrice - openPrice) * Math.pow(10, this.symbol.PipDecimalPlaces);
  }

  public calculatePriceTrailingStop(pips: number, price: number, type: TradeType, isLastPriceSymbol: boolean = false): number {
    let lastPrice = 0;
    const addition = (first: number, second: number, divisor: number) => {
      return  first + (second / Math.pow(10, divisor));
    };

    const subtraction = (first: number, second: number, divisor: number) => {
      return  first - (second / Math.pow(10, divisor));
    };

    if (type === TradeType.Buy || type === TradeType.BuyStop || type === TradeType.BuyLimit) {
      if (isLastPriceSymbol) {
        lastPrice = addition(price, pips, this.symbol.PipDecimalPlaces);
      } else {
        lastPrice = subtraction(price, pips, this.symbol.PipDecimalPlaces);
      }

    }

    if (type === TradeType.Sell || type === TradeType.SellStop || type === TradeType.SellLimit) {
      if (isLastPriceSymbol) {
        lastPrice = subtraction(price, pips, this.symbol.PipDecimalPlaces);
      } else {
        lastPrice = addition(price, pips, this.symbol.PipDecimalPlaces);
      }
    }

    return Number(lastPrice.toFixed(this.symbol.DecimalPlaces));
  }
}
