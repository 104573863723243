import {IMarginCalcSymbol} from '@common/trade/utils/calculations/imargin-calc-symbol';
import {Symbol} from '@common/symbol/models/symbol';

export class MarginCalcSymbol implements IMarginCalcSymbol {
  private readonly _symbolModel: Symbol;
  private readonly _ccConvertSymbol: IMarginCalcSymbol;
  private readonly _bcConvertSymbol: IMarginCalcSymbol;

  constructor(symbolModel: Symbol,
              bcConvertSymbol: IMarginCalcSymbol,
              ccConvertSymbol: IMarginCalcSymbol) {
    this._symbolModel = symbolModel;
    this._bcConvertSymbol = bcConvertSymbol;
    this._ccConvertSymbol = ccConvertSymbol;
  }

  public get SymbolModel(): Symbol {
    return this._symbolModel;
  }

  getAsk(): number {
    return this.SymbolModel.LastQuote != undefined ? this.SymbolModel.LastQuote.Ask : NaN;
  }

  getBCConvertSymbol(): IMarginCalcSymbol {
    if (!this._bcConvertSymbol) {
      return this;
    }

    return this._bcConvertSymbol;
  }

  getBid(): number {
    return this.SymbolModel.LastQuote != undefined ? this.SymbolModel.LastQuote.Bid : NaN;
  }

  getCCConvertSymbol(): IMarginCalcSymbol {
    if (!this._ccConvertSymbol) {
      return this;
    }

    return this._ccConvertSymbol;
  }

  getMarginRate(): number {
    return this._symbolModel.InitialMarginRate;
  }

  getTickPrice(): number {
    return this._symbolModel.TickPrice;
  }

  getPercentage(): number {
    return this._symbolModel.Percentage;
  }

  getTickSize(): number {
    return this._symbolModel.TickSize;
  }

}
