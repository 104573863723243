import {Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {Command_DeleteMail, Command_GetMailMessageHeaders, Command_MarkMailRead} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class MailHeadersCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger('MailHeadersCommandSender');

  public constructor(serverConnection: ServerInteractionService) {
    super(serverConnection);
  }

  public getMessageHeaders(traderId: number, lastMailId?: number): ICommandPromise {
    const msg = new Command_GetMailMessageHeaders();
    msg.TraderID = traderId;
    if (lastMailId) {
      msg.LastReceivedMailID = lastMailId;
    }

    return this.sendCommand(msg);
  }

  public changeMessageRead(mailId: number): ICommandPromise {
    // console.log(mailId);
    const msg = new Command_MarkMailRead();
    msg.MailID = mailId;

    // console.log(msg);

    return this.sendCommand(msg);
  }

  public deleteMessage(mailId: number): ICommandPromise {
    // console.log(mailId);
    const msg = new Command_DeleteMail();
    msg.MailID = mailId;

    // console.log(msg);

    return this.sendCommand(msg);
  }
}
