import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TooltipComponent} from './tooltip.component';
import {TooltipDirective} from './tooltip.directive';
import {ThemeModule} from '@common/shared/services/theme';

@NgModule({
  declarations: [
    TooltipComponent,
    TooltipDirective
  ],
  imports: [
    CommonModule,
    ThemeModule
  ],
  exports: [TooltipDirective]
})
export class TooltipCustomModule { }
