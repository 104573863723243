import {RolloverCalcBase} from "@common/shared/calculators/Rollover/RolloverCalcBase.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";

export class ShareUSD extends RolloverCalcBase {
	
	constructor(symbol: IRolloverCalcSymbol) {
		super(symbol);
	}
        
	
	getRolloverCalcType(): RolloverCalcType {
		return RolloverCalcType.ShareUSD;
	}
        
	public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return -unsignedAmount * this.Symbol.getBid() * (0.035 + 0.03) / 360;
}

	public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return unsignedAmount * this.Symbol.getAsk() * (0.035 + 0.03) / 360;
}

	
	
}