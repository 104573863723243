import {Symbol} from '../../symbol/models/symbol';
import {isBuyTrade, isPendingTrade, isSellTrade, TradeType} from './trade-type';
import {TradeStateSnapshot} from './trade-state-snapshot';
import {TradeSnapshotStorage} from './trade-snapshot-storage';
import {TradeCalculateObject} from '../utils/calculations/trade-calculate-object';
import {ITradeInternalState} from '../utils/states/i-trade-internal-state';
import {ProfitCalculator} from '../utils/calculations/profit-calculator';
import {ITradeObservable} from '@common/trade/models/i-event-observable';
import {JsonProperty} from '@common/shared/utils/json-decorators';
import {ISmartObserver, ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {EventEmitter} from '@angular/core';

export interface ITradeObserver {
  PendingActivated: ISmartObserver<Trade>;
  ClosedByStopLoss: ISmartObserver<Trade>;
  ClosedByTakeProfit: ISmartObserver<Trade>;
  Closed: ISmartObserver<Trade>;
  TradeId: number;
}

class TradeObserver implements ITradeObserver {
  private tradeId: number = undefined;
  private pendingActivated = new SmartEmitter<Trade>();
  private closedByStopLoss = new SmartEmitter<Trade>();
  private closedByTakeProfit = new SmartEmitter<Trade>();
  private closed = new SmartEmitter<Trade>();
  private uplChangeEvent = new SmartEmitter<Trade>();
  private tradeUpdateEvent = new SmartEmitter<Trade>();

  public get TradeId(): number {
    return this.tradeId;
  }
  public set TradeId(v: number) {
    this.tradeId = v;
  }
  public get PendingActivated(): SmartEmitter<Trade> {
    return this.pendingActivated;
  }
  public get ClosedByStopLoss(): SmartEmitter<Trade> {
    return this.closedByStopLoss;
  }
  public get ClosedByTakeProfit(): SmartEmitter<Trade> {
    return this.closedByTakeProfit;
  }
  public get Closed(): SmartEmitter<Trade> {
    return this.closed;
  }
  public get UPLChanged(): SmartEmitter<Trade> {
    return this.uplChangeEvent;
  }
  public get Updated(): SmartEmitter<Trade> {
    return this.tradeUpdateEvent;
  }

  public constructor() {
    this.closed.subscribe(() => this.release());
  }

  public release() {
    this.uplChangeEvent.kill();
    this.closedByTakeProfit.kill();
    this.closedByStopLoss.kill();
    this.pendingActivated.kill();
    this.tradeUpdateEvent.kill();
  }
}

export class Trade implements ITradeObservable {

  public constructor(calc?: TradeCalculateObject) {
    if (calc) {
      this._calculateObject = calc;
      this._stateSnapshotStorage = new TradeSnapshotStorage(calc);
      this._tradeObserver.TradeId = calc.TradeId;
    } else {
      this._stateSnapshotStorage = new TradeSnapshotStorage();
    }
  }

  public get VisibilityLine(): boolean {
    return this._visibility;
  }

  public set VisibilityLine(value: boolean) {
    this._visibility = value;
    this.changeVisibility.emit();
  }

  public get IsClosedByStopLoss(): boolean {
    return this.IsClosed && this.StopLoss &&
      (
        (isSellTrade(this.Type) && (this.ClosePrice >= this.StopLoss)) ||
        (isBuyTrade(this.Type) && (this.ClosePrice <= this.StopLoss))
      );
  }

  public get IsClosedByTakeProfit(): boolean {
    return this.IsClosed && this.TakeProfit &&
      (
        (isSellTrade(this.Type) && (this.ClosePrice <= this.TakeProfit)) ||
        (isBuyTrade(this.Type) && (this.ClosePrice >= this.TakeProfit))
      );
  }

  public get Symbol(): Symbol {
    return this._calculateObject.Symbol;
  }

  public get Pips(): number {
    return this._pips;
  }

  public get States(): TradeStateSnapshot[] {
    return this._stateSnapshotStorage.States;
  }

  public get CalculateObject(): TradeCalculateObject {
    return this._calculateObject;
  }

  public get TradeId(): number {
    return this._calculateObject.TradeId;
  }

  public get OpenTime(): Date {
    return this._calculateObject.OpenTime;
  }

  public get CloseTime(): Date {
    return this._calculateObject.CloseTime;
  }

  public get Type(): TradeType {
    return this._calculateObject.Type;
  }

  public get ReadableType(): string {
    switch (this.Type) {
      case TradeType.Buy: return 'BUY';
      case TradeType.Sell: return 'SELL';
      default: return 'UNKN';
    }
  }

  public get Volume(): number {
    return this._state.getVolume(this);
  }

  public get CommandVolumeUSD(): number {
    return this._state.getCommandVolumeUSD(this);
  }

  public get CommandVolumeAC(): number {
    return this._state.getCommandVolumeAC(this);
  }

  public get VolumeUSD(): number {
    return this._state.getVolumeUSD(this);
  }

  public get VolumeAC(): number {
    return this._state.getVolumeAC(this);
  }

  public get StopLoss(): number {
    return this._calculateObject.StopLoss;
  }

  public get TakeProfit(): number {
    return this._calculateObject.TakeProfit;
  }

  public get Storage(): number {
    return this._calculateObject.Rollover;
  }

  public get Comment(): string {
    return this._calculateObject.Comment;
  }

  public get SymbolCurrentPrice(): number {
    switch (this.Type) {
      case TradeType.Buy: return this.Symbol.Ask;
      case TradeType.Sell: return this.Symbol.Bid;
      default: return 0;
    }
  }

  public get CurrentPrice(): number {
    if (this.IsPending) {
      return this.OpenPrice;
    } else { return this.ClosePrice; }
  }

  public get CurrentRate(): number {
    return this.ClosePrice;
  }

  public get OpenPrice(): number {
    return this._calculateObject.OpenPrice;
  }

  public get ClosePrice(): number {
    // если текущая цена не равна нулю, то сохраняем его в переменную и передаем для расчета UPL, если равна нулю, то передается сохраненное значение
    if (this._state.getClosePrice(this) !== 0) {
      this._saveClosePrice = this._state.getClosePrice(this);
      return this._state.getClosePrice(this);
    } else {
      return this._saveClosePrice;
    }
  }

  public get Commissions(): number {
    return this.TransactionFee;
  }
  //
  // public get Swaps(): number {
  //   return (this.Volume / 100000) * (this.Type === TradeType.Buy ? this.Symbol.RolloverBuy : this.Symbol.RolloverSell);
  // }

  public get UPL(): number {
    return this._state.getUPL(this);
  }

  public get UplPercent(): number {
    return this.goodRound2(this._state.getUPL(this) * 100 / this.Margin);
  }

  public set UPL(upl: number) {
    // if (isPendingTrade(this.Type)) return;

    this._calculateObject.UPL = upl;

    this._tradeObserver.UPLChanged.emit(this);
  }

  public get Profit(): number {
    return this._state.getProfit(this);
  }

  public get Total(): number {
    return this._state.getTotal(this);
  }

  public get TransactionFee(): number {
    return this._calculateObject.TransactionFee;
  }

  public get SubaccountFee(): number {
    return this._calculateObject.SubaccountFee;
  }

  public get ExpirationDate(): Date {
    return this._calculateObject.ExpirationDate;
  }

  public get IsPending(): boolean {
    return isPendingTrade(this.Type);
  }

  public get SymbolName(): string {
    return this.Symbol.SymbolName;
  }

  public get IsOpen(): boolean {
    return this._state.IsOpen;
  }

  public get IsClosed(): boolean {
    return !this.IsOpen;
  }

  public get Margin(): number {
    return this._calculateObject.Margin;
  }

  public get TrailingStop(): number {
    return this._calculateObject.TrailingStop;
  }

  public get LastPriceTrailingStop(): number {
    return this._lastPriceSymbol;
  }

  public get PriceTrailingStop(): number {
    return this._priceTrailingStop;
  }

  public get ProfitCalculator(): ProfitCalculator {
    if (!this._profitCalculator) {
      this._profitCalculator = new ProfitCalculator(this.Symbol, this.Type);
    }
    return this._profitCalculator;
  }

  public get UPLChanged(): ISmartObserver<Trade> {
    return this._tradeObserver.UPLChanged;
  }

  public get PendingActivated(): ISmartObserver<Trade> {
    return this._tradeObserver.PendingActivated;
  }

  public get ClosedByStopLoss(): ISmartObserver<Trade> {
    return this._tradeObserver.ClosedByStopLoss;
  }

  public get ClosedByTakeProfit(): ISmartObserver<Trade> {
    return this._tradeObserver.ClosedByTakeProfit;
  }

  public get Closed(): ISmartObserver<Trade> {
    return this._tradeObserver.Closed;
  }

  public get Updated(): ISmartObserver<Trade> {
    return this._tradeObserver.Updated;
  }

  public get TradeObserver(): ITradeObserver {
    return this._tradeObserver;
  }

  public get CommandMargin(): number {
    return this._calculateObject.CommandMargin;
  }

  public get CommandVolume(): number {
    return this._calculateObject.CommandVolume;
  }
  @JsonProperty(TradeCalculateObject)
  private _calculateObject: TradeCalculateObject;

  @JsonProperty(TradeSnapshotStorage)
  private _stateSnapshotStorage: TradeSnapshotStorage = new TradeSnapshotStorage();

  protected _state: ITradeInternalState;

  private _pips: number;

  private _priceTrailingStop: number;

  public changePriceTrailingStop: EventEmitter<void> = new EventEmitter<void>();

  private _lastPriceSymbol: number;

  private _visibility = true;

  private _saveClosePrice = 0;

  public changeVisibility: EventEmitter<void> = new EventEmitter<void>();

  private _profitCalculator: ProfitCalculator;

  private _symbolSubscription: ISmartSubscription;

  private _tradeObserver: TradeObserver = new TradeObserver();

  public static tradeToTradeDto(trade: Trade): TradeDTO {
    const dto = new TradeDTO();

    dto.Symbol = trade.Symbol;
    dto.OpenPrice = trade.OpenPrice;
    dto.Type = trade.Type;
    dto.TakeProfit = trade.TakeProfit;
    dto.StopLoss = trade.StopLoss;
    dto.TradeId = trade.TradeId;
    dto.Volume = trade.Volume;
    dto.TrailingStop = trade.TrailingStop;

    return dto;
  }

  public getDealID(): number {
    return this._state.getDealID();
  }

  private onSymbolPriceChange() {
    this.calculate();
  }

  protected calculate() {
    this.pipsCalculate();
    this.uplCalculate();
    if (this.TrailingStop && this._calculateObject.TrailingStopValue) {
      if (this._lastPriceSymbol === undefined) {
        this._priceTrailingStop = this._calculateObject.TrailingStopValue;
        this._lastPriceSymbol = this.ProfitCalculator.calculatePriceTrailingStop(this.TrailingStop, this._priceTrailingStop, this.Type, true);
      }
      this.lastPriceChange();
    }
  }

  private uplCalculate(): void {
    this.UPL = CustomNumber.roundForPlace(this.ProfitCalculator.calculateProfit(this.Volume, this.OpenPrice, this.ClosePrice), 2);
  }

  private pipsCalculate(): void {
    this._pips = this.ProfitCalculator.calculatePips(this.OpenPrice, this.ClosePrice);
  }

  private lastPriceChange(): void {

    if (this.Type === TradeType.BuyStop || this.Type === TradeType.BuyLimit || this.Type === TradeType.SellLimit || this.Type === TradeType.SellStop ) {
      this.priceTrailingStopCalculate(this.OpenPrice);
    }

    if ( this._lastPriceSymbol !== 0 && this.ClosePrice !== 0 &&
        ((this.Type === TradeType.Buy && this._lastPriceSymbol < this.ClosePrice) || (this.Type === TradeType.Sell && this._lastPriceSymbol > this.ClosePrice))) {
      this._lastPriceSymbol = this.ClosePrice;
      this.priceTrailingStopCalculate(this._lastPriceSymbol);
    }
  }

  private priceTrailingStopCalculate(startPrice: number): void {
    this._priceTrailingStop = this.ProfitCalculator.calculatePriceTrailingStop(this.TrailingStop, startPrice, this.Type);
    this.changePriceTrailingStop.emit();
  }

  public subscribeToSymbol() {
    this._symbolSubscription = this.Symbol.PriceChange.subscribe(() => {
      this.onSymbolPriceChange();
    });
    this.onSymbolPriceChange();
  }

  public unsubscribeFromSymbol() {
    if (this._symbolSubscription) {
      this._symbolSubscription.unsubscribe();
      this._symbolSubscription = null;
    }
  }

  public updateState(state: TradeStateSnapshot): void {
    this._lastPriceSymbol = undefined;
    const oldState = this._state;
    this._state = this._stateSnapshotStorage.push(state);
    const newStateIsOpened = !this._state.IsPending && this._state.IsOpen;
    this._calculateObject = this._state.calculateObject;

    this.calculate();

    this._tradeObserver.TradeId = state.OrderId;

    if (oldState && oldState.IsPending && newStateIsOpened) {
      this._tradeObserver.PendingActivated.emit(this);
    }

    if (oldState && oldState.IsOpen && this.IsClosedByStopLoss) {
      this._tradeObserver.ClosedByStopLoss.emit(this);
    }

    if (oldState && oldState.IsOpen && this.IsClosedByTakeProfit) {
      this._tradeObserver.ClosedByTakeProfit.emit(this);
    }

    if (oldState && oldState.IsOpen && this.IsClosed) {
      this._tradeObserver.Closed.emit(this);
    }

    this._tradeObserver.Updated.emit(this);
  }

  private goodRound(value: number): number {
    return Math.round(value * Math.pow(10, this.Symbol.DecimalPlaces)) / Math.pow(10, this.Symbol.DecimalPlaces);
  }

  private goodRound2(value: number): number {
    return Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2);
  }

}
