import {Injectable} from '@angular/core';
import {AbstractTradeStrategy} from './abstract-trade.strategy';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractTradeStrategyFactory {
  protected constructor() {

  }

  public abstract getStrategy(): AbstractTradeStrategy;
}
