import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppConfig} from './configuration/app-config';
import {SEOService} from './shared/services/seo.service';
import {LoggerFactory} from './common/utils/logging/logger-factory';
import {MessageProcessor} from '@common/communication/message-processor/message-processor';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private logger = LoggerFactory.getLogger(this);

  constructor(private messageProcessor: MessageProcessor,
              private titleService: Title,
              private seoService: SEOService) {

    this.logger.debug('Created');
    this.messageProcessor.subscribe();
    titleService.setTitle(AppConfig.settings.common.AppName);
  }
}

