import {Component, OnInit} from '@angular/core';
import {CurrencyConfig} from '@common/currency/utils/currency-config';
import {AccountCurrency} from '@common/currency/services/account.currency';

@Component({
  selector: 'app-account-currency-icon',
  templateUrl: './account-currency-icon.component.html',
  styleUrls: ['./account-currency-icon.component.scss']
})
export class AccountCurrencyIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  get Icon(): string {
    return CurrencyConfig[AccountCurrency.Instance.ISOCurrencyName.toLowerCase()].icon;
  }
}
