import { Injectable } from '@angular/core';
import {DatafeedConfiguration, ResolutionString} from '@tradingview/charting_library';
import {translate} from '@common/locale/servises/translator.service';

@Injectable({
  providedIn: 'root'
})
export class ChartConfigurationService implements DatafeedConfiguration {
  exchanges = [ // TODO: Take exchanges on symbol fill
    { value: '', name: 'All Exchanges', desc: '' },
    // { value: 'PrimeXM', name: 'PrimeXM', desc: 'PrimeXM' },
  ];
  supported_resolutions: ResolutionString[] = [ '1', '5', '15', '30', '60', '240', '1D', 'D', '1W', '1M', 'M'] as ResolutionString[];
  // сменил на true чтоб можно было показывать маркеры на графике
  supports_marks = false;
  supports_time = true;
  supports_timescale_marks = false;
  supports_search: false;
  supports_group_request: true;
  symbols_types = [];

  constructor() {}

  getSettings(): DatafeedConfiguration {
    this.upDateSymbolsTypes();
    return this;
  }

  public upDateSymbolsTypes(): void {
    this.symbols_types = [];
    this.symbols_types = [
      { name: translate(`Quotes_QuotesComponent_All`), value: '' },
      { name: translate(`Quotes_QuotesComponent_Forex`), value: 'Forex' },
      { name: translate(`Quotes_QuotesComponent_CFD`), value: 'CFD' },
      { name: translate(`Quotes_QuotesComponent_Metals`), value: 'Metal' },
      { name: translate(`Quotes_QuotesComponent_Cryptos`), value: 'Cryptos' },
      { name: translate(`Quotes_QuotesComponent_Equities`), value: 'Equities' },
      { name: translate(`Quotes_QuotesComponent_Energies`), value: 'Energies' },
      { name: translate(`Quotes_QuotesComponent_Futures`), value: 'Futures' },
      { name: translate(`Quotes_QuotesComponent_Indices`), value: 'Indices' },
    ];
  }
}
