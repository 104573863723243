import {APP_INITIALIZER} from '@angular/core';
import {AppConfig} from './app-config';
import {TranslatorService} from '../locale/servises/translator.service';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {Settings} from '@common/trader/models/settings';
import {SettingsService} from '@common/trader/services/settings.service';


export function initializeApp(appConfig: AppConfig, translator: TranslatorService, notificationsProvider: NotificationProviderService) {
  return async () => {
    await appConfig.load();
    await translator.load();
    await notificationsProvider.load();
  }
}

export function getSettings(service: SettingsService) { return service.Settings }

export const AppInitializer = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [AppConfig, TranslatorService, NotificationProviderService], multi: true
};

export const SettingsFactory = {
  provide: Settings,
  useFactory: getSettings,
  deps: [SettingsService]
};
