<div class="wrapper" (mouseleave)="close()">
  <input height #height="height" class="number-input c-select" type="number" readonly [step]="step" [placeholder]="placeholder()"
         [(ngModel)]="Value" (focus)="touch()" (blur)="blur()"/>

  <div class="variants" *ngIf="opened" scroll (scrolldown)="onScrollDown()" (scrollup)="onScrollUp()"
       (swipeup)="onScrollUp()" (swipedown)="onScrollDown()"
       [step]="height.value">
    <div  *ngFor="let item of list"  (click)="onSelect(item)">
      {{format(item)}}
    </div>
  </div>
</div>
