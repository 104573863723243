import {Injectable} from '@angular/core';
import {SoundPlayerService} from '@common/player/sound-player.service';
import {TradeEventsAggregatorService} from '@common/trade/services/trade-events-aggregator.service';

@Injectable({
  providedIn: 'root'
})
export class TradeSoundPlayerService extends SoundPlayerService {
  constructor(private tradeEventAggregator: TradeEventsAggregatorService) {
    super();

    this.tradeEventAggregator.PendingActivated.subscribe(async () => {
      await this.pendingOrderActivated();
    })
  }

  public async pendingOrderActivated() {
    await this.play('/assets/sounds/trade-opened.mp3');
  }

  public async newMessage() {
    await this.play('/assets/sounds/newMessage.mp3');
  }

  public async errorMessage() {
    await this.play('/assets/sounds/errorMessage.mp3');
  }
}
