import {ITransport} from './transport/transport-interface';
import {IProtocol} from './proto/protocol-interface';
import {IProtocolEventHandler} from './proto/protocol-event-handler-interface';
import {TransportToProtocol} from './proto/transport-to-protocol';
import {ProtoV2MessageBase} from './proto-v2-message-base';
import {TransportLogger} from './transport/transport-logger';
import {ProtocolLogger} from './proto/protocol-logger';
import {ProtocolPing} from './proto/protocol-ping';
import {CommandIdGenerator} from './command-id-generator';
import {ProtocolAuthByLoginName} from './proto/protocol-auth-by-login-name';
import {ProtocolSessionIDLevel} from './proto/protocol-session-id-level';
import {Notification_FullMarketDepth, Notification_ItemTick, Ping} from './classes.g';
import {ProtocolAuthByAccessToken} from './proto/protocol-auth-by-access-token';
import {ConnectionState, InternalNotificationConnectionStateChanges} from './connection.state';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {ProtocolNotificationDisconnect} from './proto/protocol-notification-disconnect';
import {ProtocolRefreshToken} from '@common/communication/connection/proto/protocol-refresh-token';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';
import {EventEmitter, Injectable} from '@angular/core';
import {TransportFactory} from '@common/communication/connection/transport/transport-base';
import {ProtocolAuthHandler} from '@common/communication/connection/proto/protocol-auth-handler';
import {environment} from '../../../environments/environment';
import {BalanceLoaderService} from '@common/shared/balance-loader/balance-loader-service';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';
import {EntrySpotCommandSender} from '@common/communication/command-sender/entry-spot-command-sender';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {LocationPositionService} from '@common/trade/services/location-position.service';


export type IConnection2OnMessage = (message: ProtoV2MessageBase) => void;

export class ConnectionConfig {
  public connectionUri: string = null;
  public login: string = null;
  public password: string = null;
  public accessToken: string = null;
  public autoReconnectOnFirstFail = false;
  public sessionId: string = null;
  public linkId: string = null;
  public reconnectInterval = 5000;
}

@Injectable({
  /*providedIn: CommunicationModule*/
  providedIn: 'root'
})
export class ConnectionService implements IProtocolEventHandler {

  private _protocol: IProtocol;
  private _protocolToSwitch: IProtocol;
  private _commandIdGenerator: CommandIdGenerator;
  private _config: ConnectionConfig;
  private _onMessage: IConnection2OnMessage;
  private _logger = LoggerFactory.getLogger('ConnectionService');
  private _autoReconnect = false;
  public onTraderBaseInfoReceived: EventEmitter<[boolean, boolean]> = new EventEmitter<[boolean, boolean]>();
  private intervalReconnectTimer = null;
  private _connecting = false;
  private _state: ConnectionState;
  private _errorConnectServer = '';
  private _connectionAttempts = 0;
  private _typeStateConnected: string;
  public commandAnswerEvent: EventEmitter<ProtoV2MessageBase> = new EventEmitter<ProtoV2MessageBase>();
  public connectionEvent: EventEmitter<string> = new EventEmitter<string>();

  public constructor( private _transportFactory: TransportFactory,
                      private translator: TranslatorService,
                      private _balanceLoaderService: BalanceLoaderService,
                      private usersInSystemsService: UsersInSystemsService,
                      private entrySpotCommandSender: EntrySpotCommandSender) {
    this._state = ConnectionState.Disconnected;
    this._logger.debug('Instance created');
    this.connectionEvent.emit('Instance created');
  }

  /**
   * Use this property only for tests
   */
  public get AutoReconnect(): boolean {
    return this._autoReconnect;
  }

  public get Connecting(): boolean {
    return this._connecting;
  }

  public get State(): ConnectionState {
    return this._state;
  }

  get ErrorConnectServer(): string {
    return this._errorConnectServer;
  }

  set ErrorConnectServer(v: string) {
    this._errorConnectServer = v;
  }

  private static needToReconnect(msg: string) {
    return msg !== DisconnectionCodes.Logout
        && msg !== DisconnectionCodes.Abort
        && msg !== DisconnectionCodes.Reconnect
        && msg !== DisconnectionCodes.Reset
        && msg !== DisconnectionCodes.IncorrectCredentials
        && msg !== DisconnectionCodes.AccountIsLocked // "1" == 1 => true
        && msg !== DisconnectionCodes.AccountAlreadyInUse
        && msg !== DisconnectionCodes.SessionExpired
        && msg !== DisconnectionCodes.InvalidPool;
  }

  private static stateToString(state: ConnectionState) {
    switch (state) {
      case ConnectionState.Connected:
        return 'Connected';
      case ConnectionState.Connecting:
        return 'Connecting';
      case ConnectionState.ConnectionEstablishingError:
        return 'ConnectionEstablishingError';
      case ConnectionState.Disconnected:
        return 'Disconnected';
      case ConnectionState.Disconnecting:
        return 'Disconnecting';
      case ConnectionState.Reconnecting:
        return 'Reconnecting';
      case ConnectionState.RemoteDisconnected:
        return 'RemoteDisconnected';
      case ConnectionState.LoggedOut:
        return 'LoggedOut';
      case ConnectionState.Reset:
        return 'Reset';
      case ConnectionState.InvalidConnection:
        return 'InvalidConnection';
      default:
          return `Unknown state : ${state}`;
    }
  }

  public setCommandIdGenerator(commandIDGenerator: CommandIdGenerator) {
    this._commandIdGenerator = commandIDGenerator;
  }

  public setOnMessage(onMessage: IConnection2OnMessage) {
    this._onMessage = onMessage;
  }

  public getIsConnected(): boolean {
    return this._state === ConnectionState.Connected;
  }

  public init(config: ConnectionConfig) {
    this._config = config;
    this._autoReconnect = config.autoReconnectOnFirstFail;
    this._logger.debug('Connection Initialized');
    this.connectionEvent.emit('Connection Initialized');
  }

  private createIntervalReconnectTimer() {
    this.intervalReconnectTimer = setInterval(() => {
      this._errorConnectServer = '';
      this._logger.info('Reconnection');
      this.connect();
      this.connectionStateChanged(ConnectionState.Reconnecting);
    }, this._config.reconnectInterval);
  }

  public async silentReconnect(): Promise<void> {
    this.initConnectProtocol('silentReconnect', 'Reconnect silent', 'after silentReconnect');
  }

  public connect() {
    this.initConnectProtocol('connect', 'Connection start', 'Connecting...');
  }

  private initConnectProtocol(typeConnect: string, event: string, log: string) {
    this._logger.debug('Connection start');

    if (this._connecting) {
      return;
    }

    this.usersInSystemsService.checkSaveConnectUrlForUsers();

    const connectUrl = this.usersInSystemsService.ConnectUrl;

    // const connectUrl = this._balanceLoaderService.getConnectionString();

    this._connecting = true;
    console.log('initConnectProtocol ', connectUrl);
    this.connectionEvent.emit(event);

    if (typeConnect === 'connect') {
      this.connectionStateChanged(ConnectionState.Connecting);
      this._logger.info('Connecting...');
      this.connectionEvent.emit(`Connecting: ${connectUrl}`);
    }

    try {

      this.clearReconnectionInterval();
      this._connectionAttempts = 0;
      console.log(log);

      if (typeConnect === 'silentReconnect') {
        this._protocolToSwitch = this.createProtocolChain(connectUrl);
        this._protocolToSwitch.setEventHandler(this);
        this._protocolToSwitch.connect();
        this._protocol = this._protocolToSwitch;
        this._protocolToSwitch = null;
      }

      if (typeConnect === 'connect') {
        this._protocol = this.createProtocolChain(connectUrl);
        this._protocol.setEventHandler(this);
        this._protocol.connect();
      }

    } catch (e) {
      this.connectErrors(e);
    }
  }

  private connectErrors(e: Error) {

    this.sendSaveErrors('Error connect protocol', e);
    this.disconnect(false);
    this._logger.fatal('Connection failed:', e);
    this.connectionEvent.emit('Error connect protocol');
    console.log('Connection failed:', e);
  }

  public disconnect(force: boolean, msg?: string) {

    this._logger.info(`Disconnection: ${msg}`);
    this.connectionEvent.emit(`Disconnection: ${msg}`);

    if (msg === DisconnectionCodes.Abort || msg === DisconnectionCodes.Logout || force || msg === DisconnectionCodes.Reset) {
      this.clearReconnectionInterval();
      this._autoReconnect = false;
    }

    if (this._protocol != null) {

      if (msg === DisconnectionCodes.Logout) {
        this.connectionStateChanged(ConnectionState.LoggedOut);
      } else if (msg === DisconnectionCodes.Reset) {
        this.connectionStateChanged(ConnectionState.Reset);
      } else if (msg === DisconnectionCodes.Abort) {
        this.connectionStateChanged(ConnectionState.Disconnecting);
      }

      this._protocol.disconnect(msg);
      this._protocol = null;
      this._connecting = false;
      this._balanceLoaderService.clearBalancedConnectionStoredValues();
    }

    this._logger.debug(`AutoReconnect: ${this._autoReconnect}`);
    this.connectionEvent.emit(`AutoReconnect: ${this._autoReconnect}`);
  }

  public send(message: ProtoV2MessageBase) {
    if (this._protocol) {
      this._protocol.send(message);
    }
  }

  onMessageReceived(message: ProtoV2MessageBase) {
    this._onMessage(message);
  }

  onDisconnected(msg: string) {
    this._logger.info(`onDisconnected: ${msg}`);
    this.connectionEvent.emit(`onDisconnected: ${msg}`);
    this._connecting = false;
    this.solveDisconnectionState(msg);
    this._protocol = null;

    console.log('msg', 23, msg);

    if (ConnectionService.needToReconnect(msg)) {
      this._logger.debug('AutoReconnect');
      this.connectionEvent.emit('AutoReconnect');
      this._autoReconnect = true;
      this.startReconnectTimer();
    } else {
      this._autoReconnect = false;
      this.clearReconnectionInterval();
    }

    this.sendSaveErrors(msg);
  }

  protected solveDisconnectionState(msg: string) {
    if (msg === 'RemoteDisconnected') {
      this.connectionStateChanged(ConnectionState.RemoteDisconnected);
    } else {
      this.connectionStateChanged(ConnectionState.Disconnected);
    }
  }

  onConnectionEstablished() {
    this._connecting = false;
    this.connectionStateChanged(ConnectionState.Connected);
  }

  onConnectionEstablishedError(msg: string) {
    this._logger.info(`onConnectionEstablishedError: ${msg}`);
    this.connectionEvent.emit(`onConnectionEstablishedError: ${msg}`);
    this.sendSaveErrors(msg);

    this._connecting = false;
    this._logger.error('connection error:', msg);

    if (msg === DisconnectionCodes.IncorrectCredentials ) {
      this.connectionStateChanged(ConnectionState.InvalidConnection);
      return;
    }

    // при смене пароля или другие случаи когда токкен не корректен идет остановка соединения чтоб пользователя не заблокировали
    if ( msg === DisconnectionCodes.IncorrectToken) {
      this.connectionStateChanged(ConnectionState.LoggedOut);
      return;
    }

    // Need for stop throwing error when we do reconnect
    if (msg === '__Negotiate Aborted__') {
      this._errorConnectServer = this.translator.getLocalPhrase('AuthModule_SignInComponent_ConnectionErrorReconnecting');
      if (this._connectionAttempts === 5) {
        this.init(this._config);
        // this.connectionStateChanged(ConnectionState.ConnectionEstablishingError);
        // this._errorConnectServer = 'Connection error.';
        return;
      }
      this._connectionAttempts++;
    }

    // this.connectionStateChanged(ConnectionState.Reconnecting);

    if ( msg === DisconnectionCodes.RemoteDisconnect) {
      this.connectionStateChanged(ConnectionState.RemoteDisconnected);
      return;
    }

    if ( msg === DisconnectionCodes.Reset) {
      this.connectionStateChanged(ConnectionState.Reset);
      return;
    }

    if ( msg === DisconnectionCodes.AccountAlreadyInUse) {
      this.connectionStateChanged(ConnectionState.Reset);
      return;
    }

    if ( msg === DisconnectionCodes.GatewayTimeout) {
      this._errorConnectServer = this.translator.getLocalPhrase('AuthModule_SignInComponent_ServerIsNotResponding');
      if (this._connectionAttempts === 5) {
        this.connectionStateChanged(ConnectionState.ConnectionEstablishingError);
        return;
      }
      this._connectionAttempts++;
    }

    if (this._state !== ConnectionState.Reconnecting) {
      this.connectionStateChanged(ConnectionState.ConnectionEstablishingError);
    }

    this.startReconnectTimer();
  }

  private createTransport(connectionUri: string): ITransport {
    const transport = this._transportFactory.getTransport(this);
    transport.init(connectionUri);
    return transport;
  }

  public clearReconnectionInterval() {
    if (this.intervalReconnectTimer) {
      clearInterval(this.intervalReconnectTimer);
      this.intervalReconnectTimer = undefined;
    }
  }

  private startReconnectTimer() {
    if (this.intervalReconnectTimer === undefined) {
      this.createIntervalReconnectTimer();
    }
  }

  private createProtocolChain(connectionUri: string): IProtocol {
    let transport = this.createTransport(connectionUri);
    transport = new TransportLogger(transport, false);
    let result: IProtocol = new TransportToProtocol(transport);

    result = new ProtocolLogger( result,
                        [
                                        Notification_ItemTick.GetBinaryID(),
                                        Ping.GetBinaryID(),
                                        Notification_FullMarketDepth.GetBinaryID()
                                      ],
                                  this.commandAnswerEvent
                                );
    result = new ProtocolPing(result);
    result = new ProtocolAuthHandler(result, this.onTraderBaseInfoReceived);
    result = new ProtocolRefreshToken(result, this.usersInSystemsService);
    result = new ProtocolSessionIDLevel(result, this._config.sessionId);

    if (this._config.accessToken != null) {

      // если пользователь обновил страницу после смены пароля подключение идет по старому токену, из локал стороджа берутся обновленные
      // данные пользователя и проверяется статус булевого значения перезагрузки страницы для входа в приложение
      // по токенну или по логину и паролю
      const currentUser = JSON.parse(this.usersInSystemsService.getFromLocalStorage());

      if (currentUser !== null) {
        const index = currentUser.findIndex((el: any) => el.user === this._config.login);
        if (index !== -1) {
          currentUser[index].pageReload = false;
          result = new ProtocolAuthByLoginName( result,
                                                this._commandIdGenerator, currentUser[index].user,
                                                OperationsEncryptDecryptData.decryptText(currentUser[index].password), null);

          this.usersInSystemsService.saveInLocalStorage(JSON.stringify(currentUser));
        } else {
          result = new ProtocolAuthByAccessToken(result, this._commandIdGenerator, this._config.accessToken);
          this.sendSaveErrors('LoginByAccessToken');
        }
      } else {
        result = new ProtocolAuthByAccessToken(result, this._commandIdGenerator, this._config.accessToken);
        this.sendSaveErrors('LoginByAccessToken');
      }

    } else {
      result = new ProtocolAuthByLoginName(result, this._commandIdGenerator, this._config.login, this._config.password, this._config.linkId);
      this.sendSaveErrors('Login');
    }

    // Not needed anymore with the new token handling system
    // result = new ProtocolGetAuthToken(result, this._commandIdGenerator, this.onTraderBaseInfoReceived);

    result = new ProtocolNotificationDisconnect(result, this._commandIdGenerator);

    return result;
  }

  protected connectionStateChanged(newState: ConnectionState) {

    console.log('newState', newState);

    if (this._state === newState) {
      return;
    }

    if (newState === ConnectionState.Connecting) {
      OperationsWithDate.initTimestamp('Start connecting', true);
    }

    const stateToString = ConnectionService.stateToString(newState);

    this.sendSaveErrors(stateToString);

    if (newState === ConnectionState.Connected) {
      OperationsWithDate.initTimestamp('Connected' );
      OperationsWithDate.getItTookSecondsToConnect();
      this.clearReconnectionInterval();
    }

    const message = new InternalNotificationConnectionStateChanges(this, newState);

    this._state = newState;
    this._logger.debug(`State changed: ${stateToString}`);
    this.connectionEvent.emit(`State changed: ${stateToString}`);
    this._onMessage(message);
  }

  public sendTransportSignalRErrors(error: Error) {
    this.sendSaveErrors('TransportSignalRError', error);
  }

  public sendSaveErrors(type: string, error?: Error) {
    if (environment.production) {
      if ( this._typeStateConnected !== type) {
        this._typeStateConnected = type;
        const regionConnection = this._balanceLoaderService.getRegion();
        this.entrySpotCommandSender.saveToErrorDB(type, this._config, regionConnection, error);
      }
    }
  }
}

