import {Component, Directive, OnChanges, SimpleChanges} from '@angular/core';


@Directive()
export class ChangeableValidator implements OnChanges {
  registerOnValidatorChange(fn: () => void): void { this.onChange = fn; }
  private onChange: () => void;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChange) this.onChange();
  }
}
