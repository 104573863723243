import {Notification_FullMarketDepth_Item} from '@common/communication/connection/classes.g';

export class MultiTierQuoteLevel {
  private static _zero = new MultiTierQuoteLevel(0, 0, 0, 0);

  private _ask: number;
  private _bid: number;
  private _askMd: number;
  private _bidMd: number;
  private _spread: number | undefined;

  public get Ask(): number {
    return this._ask;
  }

  public get Bid(): number {
    return this._bid;
  }

  public get AskVolume(): number {
    return this._askMd;
  }

  public get BidVolume(): number {
    return this._bidMd;
  }

  public get Spread(): number | undefined {
    return this._spread;
  }

  constructor(ask: number, bid: number, askMd: number, bidMd: number) {
    this._ask = ask;
    this._bid = bid;
    this._askMd = askMd;
    this._bidMd = bidMd;
    if (this._ask === 0 || this._bid === 0) {
      this._spread = undefined;
    } else {
      this._spread = this._ask - this._bid;
    }
  }

  public static fromFullMarketDepthItem(fullMdItem: Notification_FullMarketDepth_Item): MultiTierQuoteLevel {
    return new MultiTierQuoteLevel(fullMdItem.Ask, fullMdItem.Bid, fullMdItem.AskVolume, fullMdItem.BidVolume);
  }

  public static get Zero(): MultiTierQuoteLevel {
    return this._zero;
  }
}
