import {IProtocol} from './protocol-interface';
import {IProtocolEventHandler} from './protocol-event-handler-interface';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Logger} from '../../../common/utils/logging/logger';
import {LoggerFactory} from '../../../common/utils/logging/logger-factory';

export abstract class ProtocolBase implements IProtocol {
  protected _logger: Logger;
  protected constructor() {
    this._logger = LoggerFactory.getLogger(this.constructor.name);
  }

  private _eventHandler: IProtocolEventHandler;
  public setEventHandler(eh: IProtocolEventHandler) {
    this._eventHandler = eh;
  }
  public getEventHandler(): IProtocolEventHandler {
    return this._eventHandler;
  }

  public abstract send(message: ProtoV2MessageBase);
  public abstract connect();
  public abstract disconnect(msg?: string);

}
