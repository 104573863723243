import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, decimalPoint?: number): any {
    value = Number(value);

    decimalPoint = decimalPoint || 2;

    return value.toFixed(2);
  }

}
