import {EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';

export class SymbolSearch {
  private _pattern = '';
  private _patternChanged = new EventEmitter<string>();

  public set Pattern(v: string) {
    const newValue = v.trim();

    if (this._pattern === newValue) { return; }

    this._pattern = newValue;
    this._patternChanged.emit(newValue);
  }
  public get Pattern(): string {
    return this._pattern;
  }
  public get PatternChanged(): Observable<string> {
    return this._patternChanged.asObservable();
  }
}
