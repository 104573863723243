import {ProtocolMediatorBase} from './protocol-mediator-base';
import {CommandIdGenerator} from '../command-id-generator';
import {IProtocol} from './protocol-interface';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Answer_GetAuthTokens, Message_CustomCommand} from '../classes.g';

export class ProtocolMediatorBase_CommandBase  extends ProtocolMediatorBase {
  protected _commandIDGenerator: CommandIdGenerator;

  public constructor ( subProtocol: IProtocol, commandIDGenerator: CommandIdGenerator) {
    super(subProtocol);
    this._commandIDGenerator = commandIDGenerator;
  }

  protected isAnswerToCommand(command: ProtoV2MessageBase, answer: ProtoV2MessageBase): boolean {
    if (command == null || answer == null) {
      return false;
    }
    if (answer.MessageType === Answer_GetAuthTokens.GetMessageType()) {
      this._logger.debug('Bingo');
    }

    return ('CommandID' in command) && ('CommandID' in answer) && ((answer as Message_CustomCommand).CommandID == (command as Message_CustomCommand).CommandID);

  }
}
