import {VolumeCalculator, VolumeCalculatorFactory, VolumeType} from '@common/trade/utils/volume/volume-calculator';
import {Accounting} from '@common/trader/models/accounting.service';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {TradeType} from '@common/trade/models/trade-type';
import {Symbol} from '@common/symbol/models/symbol';
import {Observable} from 'rxjs';
import {DateUtils} from '@common/common/utils/date-utils';
import {StopType} from '@common/trade/utils/calculations/sltp/stop-type';
import {TradeBound, TradeBoundFactory} from '@common/trade/models/trade-bound';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {EventEmitter} from '@angular/core';

export class VolumeComposer {
  private volumeCalculator: VolumeCalculator;

  constructor() {
  }

  public setVolumeCalculator(calc: VolumeCalculator) {
    if (this.volumeCalculator) {
      calc.VolumeUnits = this.volumeCalculator.VolumeUnits;
    }
    this.volumeCalculator = calc;
  }

  public get VolumeType(): VolumeType {
    return this.volumeCalculator.VolumeType;
  }

  public get VolumePercent(): number {
    return this.volumeCalculator.VolumePercent;
  }

  public set VolumePercent(v: number) {
    this.volumeCalculator.VolumePercent = v;
  }

  public get VolumeUnits(): number {
    return this.volumeCalculator.VolumeUnits;
  }

  public set VolumeUnits(v: number) {
    this.volumeCalculator.VolumeUnits = v;
  }

  public get VolumeCurrency(): number {
    return this.volumeCalculator.VolumeCurrency;
  }

  public set VolumeCurrency(v: number) {
    this.volumeCalculator.VolumeCurrency = v;
  }
}

type DateMode = 'UNSET' | 'DAY' | 'WEEK' | 'MONTH' | 'GTD';

export class DateTimeComposer {
  private dateMode: DateMode = 'UNSET';

  private selectedDate: Date;

  public get DateMode(): DateMode {
    return this.dateMode;
  }
  public set DateMode(v: DateMode) {
    if (v === 'GTD') {
      this.selectedDate = DateUtils.addDays(this.Today, 1);
    }
    this.dateMode = v;
  }

  public get Today(): Date {
    const day = new Date();
    return day;
  }

  public get SelectedDate(): Date {
    if (this.dateMode === 'UNSET') {
      return null;
    } else if (this.dateMode === 'DAY') {
      return DateUtils.addDays(this.Today, 1);
    } else if (this.dateMode === 'WEEK') {
      return DateUtils.addDays(this.Today, 7);
    } else if (this.dateMode === 'MONTH') {
      return DateUtils.addMonths(this.Today, 1);
    } else if (this.dateMode === 'GTD') {
      return this.selectedDate;
    }
  }
  public set SelectedDate(v: Date) {
    this.selectedDate = v;
  }
}

export class SymbolSelector {
  private currentSymbol: Symbol;

  private onSymbolChange = new EventEmitter<Symbol>();

  public get CurrentSymbol(): Symbol {
    return this.currentSymbol;
  }
  public set CurrentSymbol(v: Symbol) {
    this.currentSymbol = v;
    this.onSymbolChange.emit(v);
  }

  public get OnSymbolChange(): Observable<Symbol> {
    return this.onSymbolChange.asObservable();
  }
}

export class OrderTypeComposer {
  private position: PositionType = null;
  private opening: OpeningType = null;

  private typeChange = new EventEmitter<TradeType>();

  public get TypeChange(): Observable<TradeType> {
    return this.typeChange.asObservable();
  }

  public set Position(v: PositionType) {
    if (this.Position === v) {
      return;
    }
    this.position = v;
    this.typeChange.emit(this.TradeType);
  }
  public get Position(): PositionType {
    return this.position;
  }

  public set Opening(v: OpeningType) {
    if (this.Opening === v) {
      return;
    }
    this.opening = v;
    this.typeChange.emit(this.TradeType);
  }
  public get Opening(): OpeningType {
    return this.opening;
  }

  public get TradeType(): TradeType {
    if (this.position === PositionType.Buy && this.opening === OpeningType.Market) {
      return TradeType.Buy;
    } else if (this.position === PositionType.Buy && this.opening === OpeningType.Limit) {
      return TradeType.BuyLimit;
    } else if (this.position === PositionType.Buy && this.opening === OpeningType.Stop) {
      return TradeType.BuyStop;
    } else if (this.position === PositionType.Sell && this.opening === OpeningType.Market) {
      return TradeType.Sell;
    } else if (this.position === PositionType.Sell && this.opening === OpeningType.Limit) {
      return TradeType.SellLimit;
    } else {
      return TradeType.SellStop;
    }
  }

  public set TradeType(v: TradeType) {
    switch (+v) {
      case TradeType.Buy: {
        this.opening = OpeningType.Market;
        this.position = PositionType.Buy;
        break;
      }
      case TradeType.Sell: {
        this.opening = OpeningType.Market;
        this.position = PositionType.Sell;
        break;
      }
      case TradeType.BuyLimit: {
        this.opening = OpeningType.Limit;
        this.position = PositionType.Buy;
        break;
      }
      case TradeType.SellLimit: {
        this.opening = OpeningType.Limit;
        this.position = PositionType.Sell;
        break;
      }
      case TradeType.BuyStop: {
        this.opening = OpeningType.Stop;
        this.position = PositionType.Buy;
        break;
      }
      case TradeType.SellStop: {
        this.opening = OpeningType.Stop;
        this.position = PositionType.Sell;
        break;
      }
    }
  }
}

export class PriceComposer {
  private absolutePrice: number;

  private relativePrice: number;

  constructor(private symbol: SymbolSelector,
              private type: OrderTypeComposer) {

  }

  public get Mode(): 'relative' | 'absolute' {
    return !this.relativePrice ? 'absolute' : 'relative';
  }

  public get OpenPrice(): number {
    if (this.type.Opening === OpeningType.Market) {
      return this.CurrentPrice;
    } else if (this.relativePrice) {
      return this.CurrentPrice + this.RelativePrice * this.symbol.CurrentSymbol.PipSize / 10;
    } else {
      return this.absolutePrice;
    }
  }

  public set OpenPrice(v: number) {
    this.absolutePrice = Number(v);
    this.relativePrice = 0;
  }

  private get CurrentPrice(): number {
    return TradePriceProvider.getTradeCurrentPrice(this.symbol.CurrentSymbol, this.type.TradeType);
  }

  public set RelativePrice(v: number) {
    this.relativePrice = v;
  }

  public get RelativePrice(): number {
    return this.relativePrice;
  }
}

export class DynamicTradeDTO extends TradeDTO {
  public constructor(private volumeCal: VolumeComposer,
                     private symbolSel: SymbolSelector,
                     private typeCmp: OrderTypeComposer,
                     private price: PriceComposer) {
    super();
  }

  get Symbol(): Symbol {
    return this.symbolSel.CurrentSymbol;
  }

  get Volume(): number {
    return this.volumeCal.VolumeUnits;
  }

  get Type(): TradeType {
    return this.typeCmp.TradeType;
  }

  get CurrentPrice(): number {
    return this.price.OpenPrice;
  }
}

export abstract class StopComposer {
  private enabled: boolean;
  private _stop: TradeBound;
  protected logger = LoggerFactory.getLogger(this);
  private volume: VolumeComposer;

  protected boundFactory: TradeBoundFactory;

  private inited = false;

  public set VolumeComposer(v: VolumeComposer) {
    this.volume = v;
  }

  public constructor(private symbol: SymbolSelector,
                     private type: OrderTypeComposer,
                     private price: PriceComposer,
                     private accounting: Accounting) {
    this.boundFactory = new TradeBoundFactory(accounting);
  }

  public init() {
    this.setPipsType();

    this.inited = true;

    this.type.TypeChange.subscribe(() => {
      const pips = this.stop.Pips;
      this.createPipsStop();
      this.stop.NativeValue = Math.abs(pips);
    });
  }

  protected getTradeDTO(): TradeDTO {
    const dto = new DynamicTradeDTO(this.volume, this.symbol, this.type, this.price);

    return dto as TradeDTO;
  }

  public setPipsType() {
    if (this.StopType === StopType.ByPips) {
      return;
    }

    let price = 0;
    if (this.inited) {
      price = this.stop.Price;
    }

    this.createPipsStop();

    if (this.inited) {
      this.stop.Price = price;
    }
  }
  public setProfitType() {
    if (!this.enabled) {
      return;
    }
    if (this.StopType === StopType.ByProfit) {
      return;
    }

    let price = 0;
    if (this.inited) {
      price = this.stop.Price;
    }

    this.createProfitStop();

    if (this.inited) {
      this.stop.Price = price;
    }
  }
  public setPercentType() {
    if (!this.enabled) {
      return;
    }
    if (this.StopType === StopType.ByPercent) {
      return;
    }

    let price = 0;
    if (this.inited) {
      price = this.stop.Price;
    }

    this.createPercentStop();

    if (this.inited) {
      this.stop.Price = price;
    }
  }
  public setPriceType() {
    if (!this.enabled) {
      return;
    }
    if (this.StopType === StopType.ByPrice) {
      return;
    }

    let price = 0;
    if (this.inited) {
      price = this.stop.Price;
    }

    this.createPriceStop();

    if (this.inited) {
      this.stop.Price = price;
    }
  }

  protected get stop(): TradeBound {
    return this._stop;
  }
  protected set stop(v: TradeBound) {
    if (this._stop && this.symbol.CurrentSymbol) {
      v.Price = this._stop.Price;
    }
    this._stop = v;
  }

  protected createPriceStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByPrice, trade);
    this.logger.debug('Price Stop Created');
  }
  protected abstract createProfitStop();
  protected abstract createPercentStop();
  protected abstract createPipsStop();

  public get Enabled(): boolean {
    return this.enabled;
  }
  public set Enabled(v: boolean) {
    this.enabled = v;
  }

  public get Pips(): number {
    if (this.accounting.Equity === 0) {
      return 0;
    }
    return this.stop.Pips;
  }
  public set Pips(v: number) {
    if (this.StopType !== StopType.ByPips) {
      return;
    }
    if (v === this.Pips) {
      return;
    }

    this.stop.NativeValue = v;
  }

  public get Price(): number {
    this.logger.debug(this.stop.Price);
    if (!this.symbol.CurrentSymbol || this.accounting.Equity === 0) {
      return 0;
    }
    // if(this.stop.Price < 0){
    //   return 0;
    // }

    return this.stop.Price;
  }
  public set Price(v: number) {
    if (this.StopType !== StopType.ByPrice) {
      return;
    }
    if (v === this.Price) {
      return;
    }

    this.stop.NativeValue = v;
  }

  public get Profit(): number {
    if (this.accounting.Equity === 0) {
      return 0;
    }
    return this.stop.Profit;
  }
  public set Profit(v: number) {
    if (this.StopType !== StopType.ByProfit) {
      return;
    }
    if (v === this.Profit) {
      return;
    }

    this.stop.NativeValue = v;
  }

  public get Percent(): number {
    if (this.Price === 0 || this.accounting.Equity === 0) {
      return 0;
    }
    return this.stop.Percent;
  }
  public set Percent(v: number) {
    if (this.StopType !== StopType.ByPercent) {
      return;
    }
    if (v === this.Percent) {
      return;
    }

    this.stop.NativeValue = v;
  }

  public get StopType(): StopType {
    if (!this.stop) {
      return null;
    }
    return this.stop.StopType;
  }
}

export interface IRiskPipsProvider {
  Pips: number;
}

export class StopLossStopComposer extends StopComposer implements IRiskPipsProvider {
  protected createPipsStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByPips, trade, false);
    this.logger.debug('Pip Stop Created');
  }
  protected createPercentStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByPercent, trade, false);
    this.logger.debug('Percent Stop Created');
  }
  protected createProfitStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByProfit, trade, false);
    this.logger.debug('Price Stop Created');
  }
}

class TakeProfitStopComposer extends StopComposer {
  protected createPipsStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByPips, trade);
    this.logger.debug('Pip Stop Created');
  }
  protected createPercentStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByPercent, trade);
    this.logger.debug('Percent Stop Created');
  }
  protected createProfitStop() {
    const trade = this.getTradeDTO();
    this.stop = this.boundFactory.GetBound(StopType.ByProfit, trade);
    this.logger.debug('Profit Stop Created');
  }
}

export enum PositionType {
  Buy = 'Buy',
  Sell = 'Sell'
}

export enum OpeningType {
  Market = 'Market',
  Limit = 'Limit',
  Stop = 'Stop'
}

export class TradeFormSettings {
  private readonly symbolSelector: SymbolSelector;
  private readonly orderTypeComposer: OrderTypeComposer;
  private readonly volumeComposer: VolumeComposer;
  private readonly priceComposer: PriceComposer;
  private readonly dateTimeComposer: DateTimeComposer;
  private readonly volumeCalculatorFactory: VolumeCalculatorFactory;
  private readonly stoploss: StopLossStopComposer;
  private readonly takeprofit: TakeProfitStopComposer;

  private eventChangeVolumeOrSymbol: EventEmitter<string> = new EventEmitter<string>();

  public constructor(accounting: Accounting) {
    this.symbolSelector = new SymbolSelector();

    this.orderTypeComposer = new OrderTypeComposer();
    this.priceComposer = new PriceComposer(this.symbolSelector, this.orderTypeComposer);
    this.dateTimeComposer = new DateTimeComposer();

    this.stoploss = new StopLossStopComposer(this.symbolSelector, this.orderTypeComposer, this.priceComposer, accounting);
    this.takeprofit = new TakeProfitStopComposer(this.symbolSelector, this.orderTypeComposer, this.priceComposer, accounting);

    this.volumeCalculatorFactory = new VolumeCalculatorFactory(
      this.symbolSelector,
      this.orderTypeComposer,
      this.priceComposer,
      accounting, this.stoploss
    );

    this.volumeComposer = new VolumeComposer();
    this.volumeComposer.setVolumeCalculator(this.volumeCalculatorFactory.getCalculatorByType(VolumeType.ByUnits));

    this.stoploss.VolumeComposer = this.volumeComposer;
    this.stoploss.init();
    this.stoploss.Pips = 75;
    this.takeprofit.VolumeComposer = this.volumeComposer;
    this.takeprofit.init();
    this.takeprofit.Pips = 75;
  }

  public EventChangeVolumeOrSymbol() {
    return this.eventChangeVolumeOrSymbol;
  }

  public setBuyPosition(): void {
    this.orderTypeComposer.Position = PositionType.Buy;
  }

  public setSellPosition(): void {
    this.orderTypeComposer.Position = PositionType.Sell;
  }

  public setMarketOpening(): void {
    if (this.orderTypeComposer.Opening === OpeningType.Market) { return; }
    this.orderTypeComposer.Opening = OpeningType.Market;
    this.OpenPrice = 0;
  }

  public setLimitOpening(): void {
    if (this.orderTypeComposer.Opening === OpeningType.Limit) { return; }
    if (this.orderTypeComposer.Opening === OpeningType.Market) {
      this.OpenPrice = TradePriceProvider.getTradeCurrentPrice(this.CurrentSymbol, this.orderTypeComposer.TradeType);
    }

    this.orderTypeComposer.Opening = OpeningType.Limit;
  }

  public setStopOpening(): void {
    if (this.orderTypeComposer.Opening === OpeningType.Stop) { return; }
    if (this.orderTypeComposer.Opening === OpeningType.Market) {
      this.OpenPrice = TradePriceProvider.getTradeCurrentPrice(this.CurrentSymbol, this.orderTypeComposer.TradeType);
    }

    this.orderTypeComposer.Opening = OpeningType.Stop;
  }

  public setVolumeUnits(): void {
    const units = this.volumeComposer.VolumeUnits;

    this.volumeComposer.setVolumeCalculator(this.volumeCalculatorFactory.getCalculatorByType(VolumeType.ByUnits));

    this.volumeComposer.VolumeUnits = units;
  }

  public setVolumePercent(): void {
    const units = this.volumeComposer.VolumeUnits;

    this.volumeComposer.setVolumeCalculator(this.volumeCalculatorFactory.getCalculatorByType(VolumeType.ByPercent));

    this.volumeComposer.VolumeUnits = units;
  }

  public setVolumeCurrency(): void {
    const units = this.volumeComposer.VolumeUnits;

    this.volumeComposer.setVolumeCalculator(this.volumeCalculatorFactory.getCalculatorByType(VolumeType.ByCurrency));

    this.volumeComposer.VolumeUnits = units;
  }

  public get OrderType(): TradeType {
    return this.orderTypeComposer.TradeType;
  }

  public set OrderType(v: TradeType) {
    this.orderTypeComposer.TradeType = v;
  }

  public get CurrentSymbol(): Symbol {
    return this.symbolSelector.CurrentSymbol;
  }

  public set CurrentSymbol(v: Symbol) {
    this.symbolSelector.CurrentSymbol = v;
    this.eventChangeVolumeOrSymbol.emit('Symbol');
  }

  public get OpenPrice(): number {
    return this.priceComposer.OpenPrice;
  }

  public set OpenPrice(v: number) {
    this.priceComposer.OpenPrice = v;
  }

  public get RelativePrice(): number {
    return this.priceComposer.RelativePrice;
  }

  public set RelativePrice(v: number) {
    this.priceComposer.RelativePrice = v;
  }

  public get BidPrice(): number {
    return this.CurrentSymbol.Bid;
  }

  public get AskPrice(): number {
    return this.CurrentSymbol.Ask;
  }

  public get Spread(): string {
    if ( this.CurrentSymbol.SpreadPips !== undefined) {
      return this.CurrentSymbol.SpreadPips.toFixed(1);
    }
  }

  public get Position(): PositionType {
    return this.orderTypeComposer.Position;
  }

  public get Opening(): OpeningType {
    return this.orderTypeComposer.Opening;
  }

  public get VolumePercent(): number {
    if (isNaN(this.volumeComposer.VolumePercent) ||
      this.volumeComposer.VolumePercent === Infinity || this.volumeComposer.VolumePercent === -Infinity) {
      return 0;
    }

    return this.volumeComposer.VolumePercent;
  }

  public set VolumePercent(v: number) {
    this.volumeComposer.VolumePercent = v;
  }

  public get DateTimeMode(): DateMode {
    return this.dateTimeComposer.DateMode;
  }

  public set DateTimeMode(v: DateMode) {
    this.dateTimeComposer.DateMode = v;
  }

  public get SelectedDate(): Date {
    return this.dateTimeComposer.SelectedDate;
  }

  public set SelectedDate(v: Date) {
    this.dateTimeComposer.SelectedDate = v;
  }

  public get Volume(): number {
    return this.volumeComposer.VolumeUnits;
  }

  public set Volume(v: number) {
    this.volumeComposer.VolumeUnits = v;
    this.eventChangeVolumeOrSymbol.emit('Volume');
  }

  public get VolumeCurrency(): number {
    return this.volumeComposer.VolumeCurrency;
  }

  public set VolumeCurrency(v: number) {
    this.volumeComposer.VolumeCurrency = v;
  }

  public get StopLoss(): StopComposer {
    return this.stoploss;
  }

  public get TakeProfit(): StopComposer {
    return this.takeprofit;
  }

  public get ShortOpeningType(): string {
    if (this.Opening === OpeningType.Limit) {
      return 'LMT';
    } else if (this.Opening === OpeningType.Market) {
      return 'MKT';
    } else { return 'STP'; }
  }

  public get OnSymbolChange(): Observable<Symbol> {
    return this.symbolSelector.OnSymbolChange;
  }

  public get PriceMode(): 'relative' | 'absolute' {
    return this.priceComposer.Mode;
  }

  public get VolumeType(): VolumeType {
    return this.volumeComposer.VolumeType;
  }
}
