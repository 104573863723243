import { Injectable } from '@angular/core';
import {AppConfig} from '@common/configuration/app-config';
import {UsersGeoData} from '@common/trade/services/location-position.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceLoaderService {

  private localStorageKey = 'BalancedConnectionNumber';
  private historyLocalStorageKey = 'BalancedHistoryConnectionNumber';

  constructor(private config: AppConfig) {}

  public clearBalancedConnectionStoredValues() {
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(this.historyLocalStorageKey);
  }

  public getRegion(): string|undefined {
    const usersGeoData: UsersGeoData = JSON.parse(localStorage.getItem('usersGeoData'));
    if (usersGeoData) {
      return usersGeoData.regionConnect;
    }
    const region = sessionStorage.getItem('RegionConnect');
    if (region !== null) {
      return region;
    }
    return undefined;
  }

  public getItemHistoryConnectionUrlsBalanced(): string[] {
    const region = this.getRegion();
    if (this.config.Settings.connection.regionsToConnectTo && region) {
      return this.config.Settings.connection.regionsToConnectTo[region].ItemHistoryConnectionUrlsBalanced;
    }
    return this.config.Settings.connection.ItemHistoryConnectionUrlsBalanced;
  }

  public getHistoryConnectionString(): string {
    const region = this.getRegion();
    if (region && this.config.Settings.connection.regionsToConnectTo) {
      return this.getUrlConnectString(this.config.Settings.connection.regionsToConnectTo[region].ItemHistoryConnectionUrlsBalanced, this.historyLocalStorageKey);
    }
    return this.getUrlConnectString(this.config.Settings.connection.ItemHistoryConnectionUrlsBalanced, this.historyLocalStorageKey, this.config.Settings.connection.ItemHistoryConnectionUrl);
  }

  public getConnectionString(): string {
    const region = this.getRegion();
    if (region && this.config.Settings.connection.regionsToConnectTo) {
      return this.getUrlConnectString(this.config.Settings.connection.regionsToConnectTo[region].ConnectionUrlsBalanced, this.historyLocalStorageKey);
    }
    return this.getUrlConnectString(this.config.Settings.connection.ConnectionUrlsBalanced, this.localStorageKey, this.config.Settings.connection.ConnectionUri);
  }

  private getUrlConnectString(urlArray: string[], localStorageKey: string, urlString: string = undefined): string {
    // 1 checking if balance loaded
    if (urlArray !== undefined && urlArray.length > 0) {
      const balancedConnectionArray = urlArray;
      const lsk = localStorage.getItem(localStorageKey);

      if (lsk !== null) {
        const balancedConnectionNumber = Number(localStorage.getItem(localStorageKey));
        // if we have this number stored - always return the index from the array if exists
        if (balancedConnectionNumber < balancedConnectionArray.length) {
          return balancedConnectionArray[balancedConnectionNumber];
        } else {
          return balancedConnectionArray[0];
        }

      } else {
        // else we generate random number from 0 to array length
        const secNum = new Date().getSeconds();
        const newConnectionNumber =  secNum % balancedConnectionArray.length;
        localStorage.setItem(localStorageKey, newConnectionNumber.toString());
        return balancedConnectionArray[newConnectionNumber];
      }

    } else {
      // 2 if no balance loading specified - use usual url
      if (urlString !== undefined) {
        return urlString;
      } else {
        return null;
      }
    }
  }


}
