import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {Injectable} from '@angular/core';
import {Command_GetMarginStatus} from '@common/communication/connection/classes.g';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {Trader} from '@common/trader/models/trader';

@Injectable({
  providedIn: 'root'
})
export class MarginCommandSender extends AbstractCommandSender {

  public constructor(serverConnection: ServerInteractionService, private trader: Trader) {
    super(serverConnection);
  }

  public load(): ICommandPromise {
    const command = new Command_GetMarginStatus();
    command.TraderID = this.trader.TraderId;

    return this.sendCommand(command);
  }
}
