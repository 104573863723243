import {Injectable} from '@angular/core';
import {AbstractMessageProcessor} from '@common/communication/message-processor/processors/abstract-message-processor';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Notification_FullMarketDepth, Notification_FullMarketDepth_Item} from '@common/communication/connection/classes.g';
import {MultiTierQuoteLevel} from '@common/symbol/models/multi-tier-quote-level';
import {Symbol} from '@common/symbol/models/symbol';

@Injectable({
  providedIn: 'root'
})
export class FullMarketDepthMessageProcessor extends AbstractMessageProcessor {
  private readonly _logger = LoggerFactory.getLogger('FullMarketDepthMessageProcessor');

  constructor(serverConnection: ServerInteractionService,
              private symbolStorage: SymbolStorageService) {
    super(serverConnection);
  }

  public subscribe() {
    const subscription = this.serverConnection.on(Notification_FullMarketDepth.GetMessageType(),
      (answer) => this.onFullMarketDepth(answer),
      (error) => this._logger.error('Full market depth handling error: ', error));

    this.saveSubscription(Notification_FullMarketDepth.GetMessageType(), subscription);
    this._logger.info(`Subbed for ${Notification_FullMarketDepth.GetMessageType()}`);
  }

  private async onFullMarketDepth(fullMd: Notification_FullMarketDepth) {
    if (!fullMd) {
      throw new Error('Incorrect FullMD');
    }

    try {
      const symbolId = Number(fullMd.ItemID);
      const symbol = this.symbolStorage.findSymbolById(symbolId);

      if (!symbol) {
        this._logger.error('No symbol found with id: ', symbolId);
        return;
      }

      await symbol.fullMarketDepth(fullMd.LevelList.map(item => this.convertFullMdItemToLevel(item, symbol)));
    } catch (e) {
      this._logger.error(e);
    }
  }

  private convertFullMdItemToLevel(item: Notification_FullMarketDepth_Item, symbol: Symbol): MultiTierQuoteLevel {
    return new MultiTierQuoteLevel(
      Number(item.Ask) / Math.pow(10, symbol.DecimalPlaces),
      Number(item.Bid) / Math.pow(10, symbol.DecimalPlaces),
      item.AskVolume,
      item.BidVolume
    );
  }
}
