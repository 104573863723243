import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Timer} from '@common/common/utils/timer';
import {NotificationHistoryComponent} from '@common/notification/component/notification-history/notification-history.component';
import {MailMessageProcessor} from '@common/communication/message-processor/processors/mail-message-processor';
import {NotificationService} from '@common/notification/services/notification.service';

@Component({
  selector: '[app-notification]',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends NotificationHistoryComponent implements OnInit, AfterViewInit {
  @Output('destructed')
  public get destructedOut(): Observable<void> {
    return this.destructed.asObservable();
  }

  private destructed = new EventEmitter<void>();

  private renderTimer: Timer;
  private removeTimer: Timer;
  private destructTimer: Timer;

  constructor(elementRef: ElementRef, mailmessageprocessor?: MailMessageProcessor, notificationService?: NotificationService) {
    super(elementRef, mailmessageprocessor, notificationService);

    this.renderTimer = new Timer(() => {
      this.elementRef.nativeElement.classList.remove('hidden');
    });

    this.removeTimer = new Timer(() => {
      this.hide();
    }, 5500);

    this.destructTimer = new Timer(() => {
      this.destructSend();
    }, 6000);
  }

  private destructSend() {
    this.destructed.emit();
  }

  @Input() isVisible: boolean = false;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.renderTimer.start();
    this.removeTimer.start();
    this.destructTimer.start();
  }

  private hide() {
    if (this.isVisible) {
      this.elementRef.nativeElement.style.top = '-' + (this.ElementTop + this.ElementHeight + 200) + 'px';
      this.elementRef.nativeElement.classList.add('disappear');
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

  @HostListener('click', ['$event'])
  public close(event: MouseEvent) {
    event.stopPropagation();

    if (this.renderTimer.IsExecuted) {
      this.hide();
      this.removeTimer.remove();
      this.destructTimer.remove();
      this.notification.Unread = false;
      new Timer(() => {
        this.destructSend();
      }, 500).start();
    }
  }

  private get ElementTop(): number {
    return this.elementRef.nativeElement.getBoundingClientRect().top;
  }

  private get ElementHeight(): number {
    return this.elementRef.nativeElement.offsetHeight;
  }
}
