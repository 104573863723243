<app-modal-placeholder
  [class.hide-close-button]="TraderShouldChangePassword"
  [header]="'Modal_ChangePasswordComponent_Header' | translation"
  (onClose)="close()"
  [appName]="appName"
  [setChange]="true"
  [modalWindowName]="'ChangePassword'"
>
  <div class="c-form-container">
    <form #f="ngForm" class="c-form highlited-inputs" (ngSubmit)="submit()" passwords-match *ngIf="!IsZeTradex; else zetradexFormChangePassword">
      <div class="c-form-group" [class.incorrect]="pass.invalid && pass.dirty">
        <label class="c-label"><l-unit [key]="'Modal_ChangePasswordComponent_CurrentPasswordHeader'"></l-unit></label>
        <input #pass=ngModel class="c-input c-input-variant" type="password" [(ngModel)]="currentPassword" name="currentPassword"
               valid-password-verification
        />
        <span class="error" *ngIf="pass.errors && pass.errors.validPasswordVerification">
            <l-unit [key]="'Modal_ChangePasswordComponent_CurrentPasswordError'"></l-unit>
        </span>
      </div>
      <div class="c-form-group" [class.incorrect]="newPass.invalid && newPass.dirty">
        <label class="c-label"><l-unit [key]="'Modal_ChangePasswordComponent_NewPasswordHeader'"></l-unit></label>
        <input #newPass=ngModel class="c-input c-input-variant" type="password" [(ngModel)]="newPassword" name="newPassword"
               one-lower-case
               one-upper-case
               character-length
               number-or-special-symbol
        />
<!--        <span class="error" *ngIf="newPass.errors && newPass.errors.haventNumber">-->
<!--            <l-unit [key]="'Modal_ChangePasswordComponent_NumbersError'"></l-unit>-->
<!--        </span>-->
<!--        <span class="error" *ngIf="newPass.errors && newPass.errors.passwordToShort">-->
<!--            <l-unit [key]="'Modal_ChangePasswordComponent_8SymbolsError'"></l-unit>-->
<!--        </span>-->
<!--        <span class="error" *ngIf="newPass.errors && newPass.errors.haventSpecialSymbol">-->
<!--            <l-unit [key]="'Modal_ChangePasswordComponent_SpecialError'"></l-unit>-->
<!--        </span>-->
        <span class="error" *ngIf="newPass.errors && newPass.errors.haventLowerCase">
            <l-unit [key]="'Modal_ChangePasswordComponent_LowercaseError'"></l-unit>
        </span>
        <span class="error" *ngIf="newPass.errors && newPass.errors.haventUpperCase">
            <l-unit [key]="'Modal_ChangePasswordComponent_UppercaseError'"></l-unit>
        </span>
        <span class="error" *ngIf="newPass.errors && newPass.errors.haventSpecialSymbolOrNumber">
            <l-unit [key]="'Modal_ChangePasswordComponent_SpecialOrNumberError'"></l-unit>
        </span>
        <span class="error" *ngIf="newPass.errors && newPass.errors.characterLength">
            <l-unit [key]="'Modal_ChangePasswordComponent_CharacterLengthError'"></l-unit>
        </span>

      </div>
      <div class="c-form-group" [class.incorrect]="f.errors && f.errors.match && repeat.dirty">
        <label class="c-label"><l-unit [key]="'Modal_ChangePasswordComponent_ConfirmHeader'"></l-unit></label>
        <input #repeat=ngModel class="c-input c-input-variant" type="password" [(ngModel)]="repeatPassword" name="repeatPassword"/>
        <span class="error">
          <l-unit [key]="'Modal_ChangePasswordComponent_MatchError'"></l-unit>
        </span>
      </div>

      <red-alert *ngIf="error">{{error}}</red-alert>

      <input
        [disabled]="f.invalid"
        type="submit"
        class="button-success c-button"
        [value]="'Modal_ChangePasswordComponent_ChangeButton' | translation"
      />
    </form>

    <ng-template #zetradexFormChangePassword>
      <form #f="ngForm" class="c-form highlited-inputs z-position-form" (ngSubmit)="submit()" passwords-match>
        <div class="c-form-group" [class.incorrect]="pass.invalid && pass.dirty">
          <label class="c-label z-label-mb"><l-unit [key]="'Modal_ChangePasswordComponent_CurrentPasswordHeader'"></l-unit></label>

          <div class="c-form-group z-form-group">
            <input #pass=ngModel
                   class="c-input z-input-variant"
                   [type]="hideCurrentPassword ? 'password' : 'text'"
                   [(ngModel)]="currentPassword"
                   name="currentPassword"
                   valid-password-verification
                   [placeholder]="translatePlaceholderInput('EnterYourPassword')"
            />
            <span class="z-visibility-icon" (click)="hideCurrentPassword = !hideCurrentPassword" [ngClass]="getVisibilityIcon(hideCurrentPassword)"></span>
          </div>

          <span class="error" *ngIf="pass.errors && pass.errors.validPasswordVerification">
            <l-unit [key]="'Modal_ChangePasswordComponent_CurrentPasswordError'"></l-unit>
        </span>
        </div>
        <div class="c-form-group" [class.incorrect]="newPass.invalid && newPass.dirty">
          <label class="c-label z-label-mb"><l-unit [key]="'Modal_ChangePasswordComponent_NewPasswordHeader'"></l-unit></label>

          <div class="c-form-group z-form-group">
            <input #newPass=ngModel
                   class="c-input z-input-variant"
                   [(ngModel)]="newPassword"
                   name="newPassword"
                   one-lower-case one-upper-case character-length number-or-special-symbol
                   [type]="hideNewPassword ? 'password' : 'text'"
                   [placeholder]="translatePlaceholderInput('EnterNewPassword')"
            />
            <span class="z-visibility-icon" (click)="hideNewPassword = !hideNewPassword" [ngClass]="getVisibilityIcon(hideNewPassword)"></span>
          </div>

          <span class="error" *ngIf="newPass.errors && newPass.errors.haventLowerCase">
            <l-unit [key]="'Modal_ChangePasswordComponent_LowercaseError'"></l-unit>
        </span>
          <span class="error" *ngIf="newPass.errors && newPass.errors.haventUpperCase">
            <l-unit [key]="'Modal_ChangePasswordComponent_UppercaseError'"></l-unit>
        </span>
          <span class="error" *ngIf="newPass.errors && newPass.errors.haventSpecialSymbolOrNumber">
            <l-unit [key]="'Modal_ChangePasswordComponent_SpecialOrNumberError'"></l-unit>
        </span>
          <span class="error" *ngIf="newPass.errors && newPass.errors.characterLength">
            <l-unit [key]="'Modal_ChangePasswordComponent_CharacterLengthError'"></l-unit>
        </span>

        </div>
        <div class="c-form-group" [class.incorrect]="f.errors && f.errors.match && repeat.dirty">
          <label class="c-label z-label-mb"><l-unit [key]="'Modal_ChangePasswordComponent_ConfirmHeader'"></l-unit></label>

          <div class="c-form-group z-form-group">
            <input #repeat=ngModel
                   class="c-input z-input-variant"
                   [(ngModel)]="repeatPassword"
                   [type]="hideConfirmNewPassword ? 'password' : 'text'"
                   [placeholder]="translatePlaceholderInput('ConfirmNewPassword')"
                   name="repeatPassword"
            />
            <span class="z-visibility-icon" (click)="hideConfirmNewPassword = !hideConfirmNewPassword" [ngClass]="getVisibilityIcon(hideConfirmNewPassword)"></span>
          </div>
          <span class="error">
          <l-unit [key]="'Modal_ChangePasswordComponent_MatchError'"></l-unit>
        </span>
        </div>

        <red-alert *ngIf="error">{{error}}</red-alert>

        <input
          [disabled]="f.invalid"
          type="submit"
          class="button-success c-button z-button-modal z-confirm-button"
          [value]="'Modal_ChangePasswordComponent_ChangeButton' | translation"
        />
      </form>
    </ng-template>
  </div>
</app-modal-placeholder>
