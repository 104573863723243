import {CommandIdGenerator} from './command-id-generator';
import {CommandAnswerService, ICommandPromise} from './command-answer-service';
import {MessageDispatcherService} from './message-dispatcher-service';
import {ConnectionConfig, ConnectionService} from './connection.service';
import {AppConfig} from '../../configuration/app-config';
import {ProtoV2MessageBase} from './proto-v2-message-base';
import {Injectable} from '@angular/core';
import {ConnectionState} from './connection.state';
import {LocalStorageService} from '../../auth/services/local-storage.service';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {Command_Logout, Message_CustomCommand} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class ServerInteractionService
{
  protected _commandIdGenerator: CommandIdGenerator;

  /**
   * Use this property only for tests
   */
  public get AutoReconnect(): boolean {
    return this._connection.AutoReconnect;
  }

  private _logger = LoggerFactory.getLogger('ServerInteractionService');

  private _isLogIn = false;

  public get ConnectionState(): ConnectionState {
    return this._connection.State;
  }

  public get ConnectionUri(): string {
    return  AppConfig.settings.connection.ConnectionUri;
  }

  public constructor(private _credentialsStorage: LocalStorageService,
                     protected _messageDispatcherService: MessageDispatcherService,
                     protected _commandAnswerService: CommandAnswerService,
                     protected _connection: ConnectionService,
                     private appConfig: AppConfig)
  {
     this._commandIdGenerator = new CommandIdGenerator();

     this._connection.setOnMessage((message: ProtoV2MessageBase) => this._commandAnswerService.onMessageFromConnection(message));
     this._connection.setCommandIdGenerator(this._commandIdGenerator);

     this._commandAnswerService.init(this._commandIdGenerator);
  }

  /**
   * Send command
   * @param command
   * @return callback. Will be guaranteed to be called
   */
  public sendCommand(command: Message_CustomCommand): ICommandPromise
  {
     return this._commandAnswerService.sendCommand(command);
  }

  /**
   * Send message
   * @param command
   * @return callback. Maybe will be called
   */
  public sendMessage(command: ProtoV2MessageBase): ICommandPromise {
    return this._commandAnswerService.sendMessage(command);
  }

  public async login(login: string, password: string) {
    this._logger.debug('Login by credentials');
    console.log('Login by credentials');
    const config = new ConnectionConfig();
    config.login = login;
    config.password = password;
    config.autoReconnectOnFirstFail = false;
    config.connectionUri = this.ConnectionUri;
    config.sessionId = this._credentialsStorage.getSessionKey();
    config.reconnectInterval = this.appConfig.Settings.connection.ReconnectInterval;
    this._connection.init(config);
    this._connection.connect();
    this._isLogIn = true;
    console.log('end Login by credentials');
  }

  public async loginByTokenLink(token: string) {
    this._logger.debug('Login by guid link');
    console.log('Login by guid link');
    const config = new ConnectionConfig();
    config.linkId = token;
    config.autoReconnectOnFirstFail = false;
    config.connectionUri = this.ConnectionUri;
    config.reconnectInterval = this.appConfig.Settings.connection.ReconnectInterval;
    this._connection.init(config);
    this._connection.connect();
  }

  public async loginByAccessToken(accessToken: string, login: string) {
    this._logger.debug('Login by token');
    const config = new ConnectionConfig();
    config.accessToken = accessToken;
    config.autoReconnectOnFirstFail = true;
    config.connectionUri = this.ConnectionUri;
    this._connection.init(config);
    this._connection.connect();
  }

  protected disconnect(cmd?: DisconnectionCodes) {
    this.sendLogout().toNativePromise().then(() => {
      console.log('log out successfull');
    });
    this._connection.disconnect(true, cmd);
  }

  public async softDisconnect() {
    return this.sendLogout().toNativePromise();
  }
  /**
   * Use this method only for tests
   */
  // public reset() {
  //   const config = new ConnectionConfig();
  //   this._connection.init(config);
  //   this._connection.disconnect(false, DisconnectionCodes.Reset);
  //   this._logger.info('Connection resetted');
  // }

  public abortConnection() {
    this._connection.disconnect(true, DisconnectionCodes.Abort);
    this._logger.info('Abort connection');
  }

  public async logout() {
    this._logger.debug('Logout');
    this.disconnect(DisconnectionCodes.Logout);
    this._isLogIn = false;
  }

  public getIsConnected(): boolean {
    return this._connection.getIsConnected();
  }

  public on(messageClassName: string, callback?: any, error?: any): ISmartSubscription {
    return this._messageDispatcherService.on(messageClassName, callback, error);
  }

  public unsubscribeAll() {
    this._messageDispatcherService.unsubscribeAll();
  }

  public getIsLogIn(): boolean {
    return this._isLogIn;
  }

  private sendLogout(): ICommandPromise {
    const cmd = new Command_Logout();
    if (this.appConfig && this.appConfig.Settings && this.appConfig.Settings.common) {
      cmd.AppName = this.appConfig.Settings.common.AppName;
    }
    return this.sendCommand(cmd);
  }
}
