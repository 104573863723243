import {EventEmitter} from '@angular/core';
import {Observable, ObservableInput, Subscription} from 'rxjs';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Environment} from '@common/environment';
import 'rxjs-compat/add/operator/debounceTime';
import {catchError, filter, map} from 'rxjs/operators';

export interface ISmartSubscription{
  unsubscribe(): void;
  readonly closed: boolean;
}

class SmartSubscription implements ISmartSubscription {
  private static _nextId = 0;

  private logger = LoggerFactory.getLogger('SmartSubscription');

  private readonly _id: number = undefined;

  public constructor(private _onKill: () => void, private subscription: Subscription){
    this._id = SmartSubscription._nextId++;
  }

  public unsubscribe() {
    this.nativeUnsubscribe();
    this._onKill();
  }

  private nativeUnsubscribe() {
    if(this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    else this.logger.warn('Try unsubscribe second time')
  }

  public get Id(): number {
    return this._id;
  }

  public get closed(): boolean {
    return this.subscription === null;
  }
}

export interface ISmartObserver<T> {
  subscribe(callback: (v: T) => void, error?: any, complete?: any): ISmartSubscription;
  filter(callback: (v: T) => boolean): ISmartObserver<T>;
  map<M>(callback: (v: T) => M): ISmartObserver<M>;
  debounce(ms: number): ISmartObserver<T>;
  catchError(callback: (v: T, caught) => ObservableInput<any>): ISmartObserver<T>;
}

export class SmartEmitter<T> implements ISmartObserver<T> {
  private static countOfInstances = 0;
  private static countOfSubscriptions = 0;
  private static countOfCalls = 0;
  private static totalTime = 0;

  private subscriptionMap: Map<number, SmartSubscription>;
  private emitter: EventEmitter<T>;
  private observable: Observable<T>;
  private isAsync: boolean;

  public constructor(isAsync?: boolean) {
    if(Environment.IsTesting) {
      SmartEmitter.countOfInstances++;
    }

    this.isAsync = isAsync;
    this.subscriptionMap = new Map<number, SmartSubscription>();
    this.emitter = new EventEmitter<T>(isAsync);
    this.observable = this.emitter.asObservable();
  }

  public subscribe(callback: (T) => void, error?: any, complete?: any): ISmartSubscription {
    const callbackFunc = this.getCallbackFunc(callback);

    const sub = this.observable.subscribe(value => callbackFunc(value), error, complete);

    const result = new SmartSubscription(() => {
      this.subscriptionMap.delete(result.Id);

      if(Environment.IsTesting) {
        SmartEmitter.countOfSubscriptions--;
      }
    }, sub);

    this.subscriptionMap.set(result.Id, result);

    if(Environment.IsTesting) {
      SmartEmitter.countOfSubscriptions++;
    }

    return result;
  }

  public filter(callback: (v: T) => boolean): ISmartObserver<T> {
    const result = this.copy();

    result.observable = this.observable.pipe(filter(callback));

    return result;
  }

  public catchError(callback: (v: T, caught) => ObservableInput<any>): ISmartObserver<T> {
    const result = this.copy();

    result.observable = this.observable.pipe(catchError(callback));

    return result;
  }

  public map<M>(callback: (v: T) => M): ISmartObserver<M> {
    const result = this.copy() as any as SmartEmitter<M>;

    result.observable = this.observable.pipe(map(callback));

    return result;
  }

  public debounce(ms: number): ISmartObserver<T> {
    const result = this.copy();

    result.observable = this.observable.debounceTime(ms);

    return result;
  }

  private getCallbackFunc(callback: (T) => void) {
    if(Environment.IsTesting) {
      return (next) => {
        SmartEmitter.countOfCalls++;
        const start = performance.now();
        callback(next);
        const end = performance.now();

        SmartEmitter.totalTime += end - start;
      }
    } else { return callback; }
  }

  public emit(next: T): void {
    this.emitter.emit(next);
  }

  public kill() {
    if(Environment.IsTesting) {
      SmartEmitter.countOfInstances--;
    }

    this.subscriptionMap.forEach(item => {
      item.unsubscribe();
    })
  }

  private copy(): SmartEmitter<T> {
    const result = new SmartEmitter<T>();

    result.isAsync = this.isAsync;
    result.subscriptionMap = this.subscriptionMap;
    result.emitter = this.emitter;
    result.observable = this.observable;

    return result;
  }

  public static get CountOfInstances(): number {
    return this.countOfInstances;
  }

  public static get CountOfCalls(): number {
    return SmartEmitter.countOfCalls
  }

  public static get ExecutionTime(): number {
    return SmartEmitter.totalTime
  }

  public static get TotalCountOfSubscriptions(): number {
    return SmartEmitter.countOfSubscriptions
  }

  public get CountOfSubscribers(): number {
    return this.subscriptionMap.size;
  }
}
