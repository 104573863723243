import { Injectable } from '@angular/core';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {TradeService} from '@common/trade/services/trade.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Trader} from '@common/trader/models/trader';
import {MarginStatusService} from '@common/trader/services/margin-status.service';
import {Accounting} from '@common/trader/models/accounting.service';
import {SettingsService} from '@common/trader/services/settings.service';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {WatchListStorageService} from '@common/watchlist/services/watch-list-storage.service';
import {WatchListService} from '@common/watchlist/services/watch-list.service';
import {WatchListLocalStorage} from '@common/shared/services/watch-list-local-storage';
import {FIFONettedTradesManagementService} from '@common/trade/services/fifonetted-trades-management.service';
import {SymbolInfoService} from '@common/shared/services/symbol-info.service';
import {AppConfig} from '@common/configuration/app-config';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataLoaderService {

  private logger = LoggerFactory.getLogger('DataResolver');
  private _isLoading = false;

  public constructor(private symbolService: SymbolService,
                     private symbolStorage: SymbolStorageService,
                     private tradeService: TradeService,
                     private tradeStorage: TradeStorage,
                     private currentTrader: Trader,
                     private marginService: MarginStatusService,
                     private accounting: Accounting,
                     private settingsService: SettingsService,
                     private watchListStorageService: WatchListStorageService,
                     private watchListService: WatchListService,
                     private watchListLocalStorage: WatchListLocalStorage,
                     private fifoNetPositionService: FIFONettedTradesManagementService,
                     private symbolInfoService: SymbolInfoService,
                     private appConfig: AppConfig) {

  }

  public async doLoad(): Promise<any> {
    if (this.isLoginAction()) {
      await this.loadTrader();
      await this.loadData();
      if (environment.platform == 'cfa-mobile') {
        await this.watchListsLoadAction();
      }
      this.currentTrader.markTraderLoaded();
    }
  }

  private isLoginAction() {
    return !this.currentTrader.Loaded;
  }

  private async loadTrader(): Promise<void> {
    await this.currentTrader.load();
  }
  private async loadData(): Promise<void> {
    const settingsPromise = this.settingsService.load();
    const rateSymbolSub = AccountCurrency.Instance.RateSymbolId ?
      await this.symbolService.subscribeToSymbol(AccountCurrency.Instance.RateSymbolId) :
      null;
    const marginStatusPromise = this.marginService.load();
    const balancePromise = this.accounting.loadBalance();
    const symbolPromise = await this.loadSymbols();
    const tradePromise = this.loadTrades();
    this.symbolInfoService.init();

    await Promise.all([settingsPromise, rateSymbolSub, marginStatusPromise, balancePromise, symbolPromise, tradePromise]);

    if (AccountCurrency.Instance.RateSymbolId) {
      this.setAccountCurrencySymbol();
    }
  }

  private async loadSymbols(): Promise<void> {
    const symbols = await this.symbolService.loadSymbols();
    await this.symbolStorage.insertSymbols(this.currentTrader.TradeGroupId, ...symbols);
    this.symbolStorage.setInternalSymbols(this.symbolService.InternalSymbols);
    // в if убрал ! тогда срабатывает подписка на те валютные пары, что есть у пользователя
    // вернул в if !
    // закомментировал для того чтоб не осуществлялась подписка на все символы что есть в торговой группе ,
    // проверка на подписку производится лишь в тех символах что отображены на экране
    // if (!this.appConfig.Settings.connection.SymbolSubscriptionOnDemand) {
    //   await this.symbolService.subscribeToSymbols(symbols.map(symbol => symbol.SymbolId));
    // }
  }

  public async loadTrades(): Promise<void> {
    const trades = await this.tradeService.loadAll();
    await this.tradeStorage.update(trades);
  }

  private setAccountCurrencySymbol() {
    const sId = AccountCurrency.Instance.RateSymbolId;

    const rateSymbol = this.symbolStorage.findSymbolById(sId);

    AccountCurrency.Instance.setRateSymbol(rateSymbol);
  }

  public async watchListsLoadAction(): Promise<void> {
    const traderWatchListVersion = await this.watchListService.getTraderWatchListVersion(this.currentTrader.TraderId);
    this.watchListStorageService.Suggestions = await this.watchListService.getTraderSuggestionsWatchList(this.currentTrader.TraderId);
    this.watchListStorageService.loadSuggestionsSymbolsList();

    if (this.currentTrader.IsGuest) {
      this.watchListStorageService.MyWatchLists = this.watchListLocalStorage.getWatchLists();
      this.watchListStorageService.loadWatchlistMapFromLocalStorage();
      this.currentTrader.WatchListID = this.watchListStorageService.getMaxWatchListIDInMap();
      this.currentTrader.WatchListContentID = this.watchListStorageService.getMaxContentID();
    } else {
      const currentWatchListVersion = this.watchListStorageService.CurrentWatchListVersion;
      if (currentWatchListVersion === undefined || traderWatchListVersion >= currentWatchListVersion) {
        this.watchListStorageService.CurrentWatchListVersion = traderWatchListVersion;
        this.watchListStorageService.MyWatchLists = await this.watchListService.getTraderWatchLists(this.currentTrader.TraderId);
        this.watchListStorageService.loadWatchlistMapFromDataBase();
      }
    }
  }
}
