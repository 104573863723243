<span class="menu-icon" [ngClass]="getIconClass()" *ngIf="IsHiddenIconTopMenu"></span>
<span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_layout'"></l-unit></span>

<ul class="dropdown layout-dropdown">
  <li class="menu-item" (click)="defaultMode($event)">
    <span class="menu-icon layout-default"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_default'"></l-unit></span>
    <span class="hotkey">Ctrl+1</span>
  </li>
  <li class="menu-item" (click)="positionsMode($event)">
    <span class="menu-icon layout-positions"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_positions'"></l-unit></span>
    <span class="hotkey">Ctrl+2</span>
  </li>
  <li class="menu-item" (click)="quotesMode($event)">
    <span class="menu-icon layout-quotes"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_quotes'"></l-unit></span>
    <span class="hotkey">Ctrl+3</span>
  </li>
  <li class="menu-item" (click)="chartMode($event)">
    <span class="menu-icon layout-chart"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_chart-only'"></l-unit></span>
    <span class="hotkey">Ctrl+4</span>
  </li>
  <li class="menu-item" (click)="positionsOnlyMode($event)">
    <span class="menu-icon layout-chart"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_positions-only'"></l-unit></span>
    <span class="hotkey">Ctrl+5</span>
  </li>
  <li class="menu-item" (click)="quotesAndPositionsMode($event)">
    <span class="menu-icon layout-quotes"></span>
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_quotes-and-positions'"></l-unit></span>
    <span class="hotkey">Ctrl+6</span>
  </li>
  <li class="menu-item" style="cursor: default;">
    <span class="menu-text"><l-unit [key]="'UserInterface_LayoutButtonComponent_show-full-market-depth'"></l-unit></span>
    <app-check-box name="show-full-md" id="show-full-md" style="float: right; cursor: pointer" [(ngModel)]="ShowFullMarketDepth"></app-check-box>
  </li>
</ul>
