<div class="app-modal-placeholder fullscreen-mode">
  <div class="app-modal-container" [class.stats-page]="listenStatsPage()" (onClickOutside)="clickOutside()">

    <div class="app-modal-body app-modal-body-colored" [class.z-app-modal-body]="IsZeTradex" [class.z-app-modal-body-w]="IsSetChange" [class.search-symbol-position]="IsTradingViewDesktop">
      <div class="app-modal-header split-border">
        <span class="app-modal-top-close-button" *ngIf="IsHiddenCloseButton" [class.z-modal-top-button]="IsModalClosePosition" (click)="close()"></span>

        <h4 class="app-modal-header-title">
          {{ header }}<ng-content select=".header"></ng-content>
        </h4>
      </div>
      <div class="app-modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
