import {ProtocolMediatorBase} from '@common/communication/connection/proto/protocol-mediator-base';
import {ProtoV2MessageBase} from '@common/communication/connection/proto-v2-message-base';
import {Answer_LoginSuccess, Reply_AnswerLoginSuccess} from '@common/communication/connection/classes.g';
import {EventEmitter} from '@angular/core';
import {IProtocol} from '@common/communication/connection/proto/protocol-interface';

export class ProtocolAuthHandler extends ProtocolMediatorBase {

  private _onTraderBaseDataLoaded: EventEmitter<[boolean, boolean]>;

  constructor(subProtocol: IProtocol, onTraderBaseDataLoaded: EventEmitter<[boolean, boolean]>) {
    super(subProtocol);
    this._onTraderBaseDataLoaded = onTraderBaseDataLoaded;
  }

  public onMessageReceived(message: ProtoV2MessageBase) {
    if (message.BinaryID === Answer_LoginSuccess.GetBinaryID()) {
      const loginSuccess = message as Answer_LoginSuccess;

      // sending message that we received answer login success to avoid situation when user refreshes the page and session
      // remains on the server for a long time. if session at the CP haven't replied with reply_answerLoginSuccess message
      // it's deleting at the server in 1 minute
      const replyMessage = new Reply_AnswerLoginSuccess();
      replyMessage.SessionID = loginSuccess.SessionID;
      this.send(replyMessage);


      this._onTraderBaseDataLoaded.emit([loginSuccess.IsInvestorAccount, loginSuccess.IsDeposited]);
    }

    super.onMessageReceived(message);
  }

  public send(message: ProtoV2MessageBase) {
    super.send(message);
  }
}
