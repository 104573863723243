import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RegistrationComponent} from './components/registration/registration.component';
import {DoneComponent} from './components/done/done.component';
import {MessageService} from '../shared/services/messageServices/message.service';
import {SignInComponent} from './components/signin/sign-in.component';
import {LocaleModule} from '../locale/locale.module';
import {RouterModule, Routes} from '@angular/router';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {ThemeModule} from '@common/shared/services/theme';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';


const auth_routes: Routes = [
  {path: '', redirectTo : 'sign-in', pathMatch: 'full'},
  {path: 'sign-in', component: SignInComponent},
  // {path: 'registration', component: RegistrationComponent},
  // {path: 'registration-done', component: DoneComponent},
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(auth_routes),
        LocaleModule,
        ThemeModule,
        MatFormFieldModule,
        MatIconModule,
        NgOptimizedImage
    ],
  declarations: [SignInComponent, RegistrationComponent, DoneComponent, LoginFormComponent],
  providers: [
    MessageService
  ]
})
export class AuthModule {
}
