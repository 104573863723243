import {NettedTrade} from '@common/trade/models/netted-trade';
import {Trade} from '@common/trade/models/trade';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';


export class NettedTradeMap {
  private _nettedTradeMap: Map<string, NettedTrade> = new Map<string, NettedTrade>();

  private readonly _positionClosed: SmartEmitter<string>;

  public get PositionClosedSubscription(): SmartEmitter<string> {
     return this._positionClosed;
  }

  constructor() {
    this._positionClosed  = new SmartEmitter<string>();
  }

  public put(order: Trade) {
    let nettedPosition: NettedTrade;
    if (this._nettedTradeMap.has(order.SymbolName)) {
      nettedPosition = this._nettedTradeMap.get(order.SymbolName);
    } else {
      nettedPosition = new NettedTrade();
    }
    nettedPosition.addOrder(order);
    this._nettedTradeMap.set(order.SymbolName, nettedPosition);
  }


  public remove(order: Trade) {
    let nettedPosition: NettedTrade;
    if (this._nettedTradeMap.has(order.SymbolName)) {
      nettedPosition = this._nettedTradeMap.get(order.SymbolName);
    } else {
      console.log('trade is not in the netted storage');
    }
    nettedPosition.removeOrder(order);
    if (nettedPosition.OpenTradesList.length === 0) {
      this._nettedTradeMap.delete(order.SymbolName);
      this._positionClosed.emit(order.SymbolName);
    }
  }

  public getAsArray(): NettedTrade[] {
    return Array.from(this._nettedTradeMap.values());
  }

  public clear() {
    this._nettedTradeMap.clear();
  }

  public getObjects() {
    return this._nettedTradeMap.values();
  }
}
