import {Injectable} from '@angular/core';
import {PasswordCommandSender} from '@common/communication/command-sender/password-command-sender';
import {PasswordChangeData} from '@common/trader/models/password-change-data';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {LocalStorageService} from '@common/auth/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  public constructor(private passwordCommandSender: PasswordCommandSender, private localStorage: LocalStorageService) {
  }

  public changePassword(data: PasswordChangeData): ICommandPromise {
    return this.passwordCommandSender.changePassword(data);
  }
  public requestTokens() {
    this.passwordCommandSender.requestToken();
  }
}

