import {Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {Command_GetBalance} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class BalanceCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger('BalanceCommandSender');

  public constructor(serverConnection: ServerInteractionService) {

    super(serverConnection);
  }

  public load(): ICommandPromise {
    return this.sendCommand(new Command_GetBalance());
  }
}
