import {Injectable} from '@angular/core';
import {TranslatorService} from './translator.service';
import {ILocalized} from '../i-localized';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';

@Injectable({
  providedIn: 'root'
})
export class TranslateManagerService {
  private subscription: ISmartSubscription;
  private components: Map<ILocalized, ILocalized> = new Map<ILocalized, ILocalized>();

  public constructor(private translator: TranslatorService){
    this.subscription = this.translator.LocaleChanged.subscribe(() => this.update());
  }

  public subscribe(component: ILocalized) {
    this.components.set(component, component);
  }
  public unsubscribe(component: ILocalized) {
    this.components.delete(component);
  }

  private update(){
    const temp = Array.from(this.components.keys());

    temp.forEach((item) => {
      item.localize();
    })
  }
}
