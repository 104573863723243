import {Symbol} from '../../symbol/models/symbol';
import {CustomNumber} from '@common/trade/utils/custom-number';

export class PriceCalculator {
  public static priceToPips(price: number, symbol: Symbol){
    return CustomNumber.cutNumber(price / symbol.PipSize, 2);
  }
  public static addPipsToPrice(price: number, pips: number, symbol: Symbol){
    return CustomNumber.cutNumber(price + pips * symbol.PipSize, symbol.DecimalPlaces);
  }

  public static pipsToPrice(pips: number, symbol: Symbol){
    return CustomNumber.cutNumber(pips * symbol.PipSize, symbol.DecimalPlaces);
  }
}
