import {Injectable} from '@angular/core';
import {ITradeObserver, Trade} from '@common/trade/models/trade';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {ISmartObserver, ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

@Injectable({
  providedIn: 'root'
})
export class TradeEventsAggregatorService {
  private tradesSubscriptions = new Map<number, ISmartSubscription[]>();

  private tradePendingActivated = new SmartEmitter<Trade>();
  private tradeClosedByStopLoss = new SmartEmitter<Trade>();
  private tradeClosedByTakeProfit = new SmartEmitter<Trade>();
  private tradeUplChanged = new SmartEmitter<Trade>();
  private tradeClosed = new SmartEmitter<Trade>();

  constructor(private tradeStorage: TradeStorage) {
    this.tradeStorage.onTradeOpened(trade => this.pushTrade(trade.TradeObserver));
  }

  public pushTrade(tradeObserver: ITradeObserver){
    const subscriptions: ISmartSubscription[] = [];

    subscriptions.push(tradeObserver.PendingActivated.subscribe((trade) => {
      this.onPendingActivated(trade);
    }));

    subscriptions.push(tradeObserver.ClosedByStopLoss.subscribe((trade) => {
      this.onClosedByStopLoss(trade);
    }));

    subscriptions.push(tradeObserver.ClosedByTakeProfit.subscribe((trade) => {
      this.onClosedByTakeProfit(trade);
    }));

    subscriptions.push(tradeObserver.Closed.subscribe((trade) => {
      this.onClosed(trade);
    }));

    this.tradesSubscriptions.set(tradeObserver.TradeId, subscriptions);
  }

  private onPendingActivated(trade: Trade){
    this.tradePendingActivated.emit(trade);
  }

  private onClosedByStopLoss(trade: Trade){
    this.tradeClosedByStopLoss.emit(trade);
  }

  private onClosedByTakeProfit(trade: Trade){
    this.tradeClosedByTakeProfit.emit(trade);
  }

  private onClosed(trade: Trade){
    this.tradeClosed.emit(trade);

    const subscriptions = this.tradesSubscriptions.get(trade.TradeId);

    subscriptions.forEach(item => item.unsubscribe());

    this.tradesSubscriptions.delete(trade.TradeId);
  }

  public get PendingActivated(): ISmartObserver<Trade>{
    return this.tradePendingActivated;
  }

  public get ClosedByStopLoss(): ISmartObserver<Trade>{
    return this.tradeClosedByStopLoss;
  }

  public get ClosedByTakeProfit(): ISmartObserver<Trade>{
    return this.tradeClosedByTakeProfit;
  }

  public get Closed(): ISmartObserver<Trade>{
    return this.tradeClosed;
  }

  public get SubscriptionsCount(): number {
    return this.tradesSubscriptions.size;
  }
}
