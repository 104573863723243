import {Component, OnInit} from '@angular/core';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {AppConfig} from '@common/configuration/app-config';
import {TradeService} from '@common/trade/services/trade.service';
import {SettingsService} from '@common/trader/services/settings.service';
import {SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {AuthenticationService} from '@common/auth/services/authentication.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';

@Component({
  selector: 'app-testing-panel',
  templateUrl: './testing-panel.component.html',
  styleUrls: ['./testing-panel.component.scss']
})
export class TestingPanelComponent implements OnInit {
  private uriBackup;
  private startTime: Date;

  private showDashboard;

  constructor(private serverConnection: ServerInteractionService,
              private settingsService: SettingsService,
              private tradeService: TradeService,
              private authService: AuthenticationService,
              private tradeStorage: TradeStorage,
              private symbolsStorage: SymbolStorageService) {
    this.uriBackup = AppConfig.settings.connection.ConnectionUri;
    this.authService.LoginSuccess.subscribe(() => this.onLogin());
  }

  ngOnInit() {
  }

  toggle() {
    this.showDashboard = !this.showDashboard;
  }

  public onLogin() {
    this.startTime = new Date();
  }

  public disableConnection(){
    AppConfig.settings.connection.ConnectionUri = '';
    // this.serverConnection.reset();
  }
  public enableConnection(){
    AppConfig.settings.connection.ConnectionUri = this.uriBackup;
    // this.serverConnection.reset()
  }
  public disconnect(){
    this.serverConnection.logout()
  }
  public reset(){
    this.settingsService.Settings.OneClickTrading = false;
    this.settingsService.Settings.RegularSLTP = false;
    this.settingsService.save();

    this.tradeService.closeAllOrNegativeOrPositiveTrades().then();
    this.tradeService.cancelPendings().then();

    this.disconnect();
  }

  public get ShowDashboard(): boolean {
    return this.showDashboard;
  }

  public get EmittersCount(): number {
    return SmartEmitter.CountOfInstances;
  }

  public get SubscriptionsCount(): number {
    return SmartEmitter.TotalCountOfSubscriptions;
  }

  public get CallsCount(): number {
    return SmartEmitter.CountOfCalls;
  }

  public get TotalTime(): number {
    return SmartEmitter.ExecutionTime;
  }

  public get MiddleTime(): number {
    return this.TotalTime / this.CallsCount;
  }

  public get MiddleSubscriptionsCount(): number {
    return CustomNumber.accountingRoundForPlace(this.SubscriptionsCount / this.EmittersCount, 2);
  }

  public get EventsPerSecond(): number {
    return this.CallsCount / this.ElapsedSeconds;
  }

  public get ElapsedSeconds(): number {
    return (new Date().getTime() - this.startTime.getTime()) / 1000;
  }

  public get TradesCount(): number {
    return this.tradeStorage.OpenedTrades.length;
  }

  public get SymbolsCount(): number {
    return this.symbolsStorage.Symbols.length;
  }
}
