import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Trader} from '@common/trader/models/trader';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {MessageProcessor} from '@common/communication/message-processor/message-processor';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Accounting} from '@common/trader/models/accounting.service';
import {EquityChartService} from '@common/shared/services/equity-chart/equity-chart.service';
import {FIFONettedTradesManagementService} from '@common/trade/services/fifonetted-trades-management.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard  {
  public constructor(private currentTrader: Trader,
                     private symbolService: SymbolService,
                     private credentialsStorage: LocalStorageService,
                     private symbolStorage: SymbolStorageService,
                     private messageProcessor: MessageProcessor,
                     private notificationService: NotificationProviderService,
                     private tradeStorage: TradeStorage,
                     private serverConnection: ServerInteractionService,
                     private accounting: Accounting,
                     private equityChartService: EquityChartService,
                     private fifoNettedTradesManagementService: FIFONettedTradesManagementService) {

  }

  async canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot,
                      currentState: RouterStateSnapshot,
                      nextState?: RouterStateSnapshot): Promise<any> {
    if (this.changePasswordError(currentState, nextState)) {
      return false;
    } else {
      if (this.isLogout(nextState)) {
        await this.logoutRoutine().then();
      }
      return true;
    }
  }

  // метод, который блокирует выход пользователя при смене пароля, когда пришел ответ об неверном текущем пароле
  private changePasswordError(currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    if (currentState.url == '/profile/change-password' && nextState.url == '/sign-in') {
      return true;
    } else {
      return false;
    }
  }

  private isLogout(nextState?: RouterStateSnapshot): boolean {
    if (!nextState) { return false; }

    return nextState.url === '/sign-in';
  }

  public async logoutRoutine(): Promise<void> {
    this.currentTrader.logout();

    const symbolsUnsub = this.unsubscribeFromSymbols();

    this.credentialsStorage.removeAll();
    this.resetSubscriptions();
    this.notificationService.clear();
    this.tradeStorage.clearTrades();
    this.accounting.clear();
    this.equityChartService.clear();
    this.fifoNettedTradesManagementService.clear();

    await Promise.all([symbolsUnsub]);
  }

  private async unsubscribeFromSymbols(): Promise<void> {
    const symbolsUnsub = this.symbolService.unsubscribeFromSymbols(this.symbolStorage.Symbols);
    const historyUnsub = this.symbolService.unsubscribeFromSymbolsHistories(this.symbolStorage.Symbols);

    this.symbolStorage.clearSymbols();

    // await Promise.all([symbolsUnsub, historyUnsub]);
  }

  private resetSubscriptions(): void {
    this.messageProcessor.unsubscribe();
    this.serverConnection.unsubscribeAll();
    this.messageProcessor.subscribe();
  }
}
