import {RateType} from '@common/currency/models/rate-type';
import {AccountCurrency} from '@common/currency/services/account.currency';

export class Exchanger {
  public constructor(private accountCurrency: AccountCurrency) {}

  public exchange(amount: number): number {
    if (this.accountCurrency.RateType in [RateType.FixedDirect, RateType.SymbolAverageDirect, RateType.SymbolDirect]) {
      return this.exchangeDirect(amount);
    } else {
      return this.exchangeIndirect(amount);
    }
  }

  private exchangeDirect(amount: number): number {
    const rate = this.getExchangeRate(amount);

    const exchanged = amount * rate;

    if (Number.isNaN(exchanged) || !Number.isFinite(exchanged)) {
      throw new Error(`Exchanged is incorrect | Amount: ${amount}, Exchange Rate: ${rate}`);
    }

    return exchanged;
  }

  private exchangeIndirect(amount: number): number {
    const rate = this.getExchangeRate(amount);

    const exchanged = amount / rate;

    if (Number.isNaN(exchanged) || !Number.isFinite(exchanged)) {
      throw new Error(`Exchanged is incorrect | Amount: ${amount}, Exchange Rate: ${rate}`);
    }

    return exchanged;
  }

  public reverseExchange(amount: number): number {
    if (this.accountCurrency.RateType in [RateType.FixedDirect, RateType.SymbolAverageDirect, RateType.SymbolDirect]) {
      return this.reverseExchangeDirect(amount);
    } else {
      return this.reverseExchangeIndirect(amount);
    }
  }

  private reverseExchangeDirect(amount: number): number {
    const rate = this.getExchangeRate(amount);

    const exchanged = amount / rate;

    if (Number.isNaN(exchanged) || !Number.isFinite(exchanged)) {
      throw new Error(`Exchanged is incorrect | Amount: ${amount}, Exchange Rate: ${rate}`);
    }

    return exchanged;
  }

  private reverseExchangeIndirect(amount: number): number {
    const rate = this.getExchangeRate(amount);

    const exchanged = amount * rate;

    if (Number.isNaN(exchanged) || !Number.isFinite(exchanged)) {
      throw new Error(`Exchanged is incorrect | Amount: ${amount}, Exchange Rate: ${rate}`);
    }

    return exchanged;
  }

  private getExchangeRate(amount: number) {
    return amount > 0 ? this.accountCurrency.ConvertBid : this.accountCurrency.ConvertAsk;
  }
}
