import {TransportBase} from './transport-base';
import {ITransport} from './transport-interface';
import {TransportEventHandlerInterface} from './transport-event-handler-interface';

export class TransportLogger extends TransportBase implements TransportEventHandlerInterface {
  private _subTransport: ITransport;
  private _enableMessageLogging: boolean;

  public constructor(subTransport: ITransport, enableMessageLogging: boolean) {
    super();
    this._enableMessageLogging = enableMessageLogging;
    this._subTransport = subTransport;
    this._subTransport.setEventHandler(this);
  }

  public send(message: string) {
    if (this._enableMessageLogging) {
      this._logger.debug(`Sending message:${message}`);
    }
    this._subTransport.send(message);
  }

  public connect() {
    this._logger.debug(`Connecting to remote host: :${this.getRemoteHostAsStr()}`);
    this._subTransport.connect();
  }

  public disconnect(msg?: string) {
    this._logger.debug(`Disconnecting from remote host: :${this.getRemoteHostAsStr()}`);
    this._subTransport.disconnect(msg);
  }

  public getRemoteHostAsStr(): string {
    return this._subTransport.getRemoteHostAsStr();
  }

  onMessageReceived(message: string) {
    if (this._enableMessageLogging) {
      this._logger.debug(`Received message :${message}`);
    }
    this.getEventHandler().onMessageReceived(message);
  }

  onDisconnected(msg: string) {
    this._logger.debug(`Disconnected from remote host: :${this.getRemoteHostAsStr()}, msg: ${msg}`);
    this.getEventHandler().onDisconnected(msg);
  }

  onConnectionEstablished() {
    this._logger.debug(`Connection established to host: :${this.getRemoteHostAsStr()}`);
    this.getEventHandler().onConnectionEstablished();
  }

  onConnectionEstablishedError(msg: any) {
    this._logger.debug(`Connection established  error to host: :${this.getRemoteHostAsStr()}, msg:${msg}`);
    if (msg.context !== undefined && msg.context.statusText !== undefined) {
      this.getEventHandler().onConnectionEstablishedError(msg.context.statusText);
    }
  }
}
