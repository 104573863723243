import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {LocaleEnum} from '../servises/locale';

@Pipe({
  name: 'local_date'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: Date, locale: LocaleEnum, isUtc?: boolean, format?: string): string {

    moment.locale(locale);

    if (format) {
      if (isUtc) {
        return moment.utc(value).format(format);
      }
      return moment(value).format(format);
    } else {
      if (isUtc) {
        return moment.utc(value).format('ll, h:mm:ss a');
      }
      return moment(value).format('ll, h:mm:ss a');
    }
  }
}
