import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberMaskDirective} from './directives/number-mask.directive';
import {DecimalPipe} from './decimal.pipe';

@NgModule({
  declarations: [NumberMaskDirective, DecimalPipe],
  exports: [
    NumberMaskDirective,
    DecimalPipe
  ],
  imports: [
    CommonModule
  ]
})
export class FormattingModule { }
