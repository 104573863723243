import {Pipe, PipeTransform} from '@angular/core';
import {TranslatorService} from '../servises/translator.service';

@Pipe({
  name: 'translation',
  pure: false
})
export class TranslationPipe implements PipeTransform {

  public constructor(private translator: TranslatorService){}

  transform(key: string, args?: any): any {
    return this.translator.getLocalPhrase(key);
  }

}
