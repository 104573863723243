import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Notification, NotificationType, ReadingType} from '@common/notification/models/notification';
import {Observable} from 'rxjs';
import {Timer} from '@common/common/utils/timer';
import {VisibilitySyncService} from '@common/shared/services/visibility-sync.service';
import {MailMessageProcessor} from '@common/communication/message-processor/processors/mail-message-processor';
import {NotificationService} from '@common/notification/services/notification.service';

@Component({
  selector: '[app-notification-history]',
  templateUrl: './notification-history.component.html',
  styleUrls: ['./notification-history.component.scss'],
  providers: [VisibilitySyncService]
})
export class NotificationHistoryComponent implements OnInit, AfterViewInit {
  @Input()
  public notification: Notification;
  private _wasShown = false;

  private _created = new EventEmitter<void>();

  constructor(protected elementRef: ElementRef,
              private mailmessageprocessor: MailMessageProcessor,
              private notificationService: NotificationService,
              ) {
  }

  ngOnInit() {
    if (!this.notification.Unread) {
      this.elementRef.nativeElement.querySelector('.marker').classList.add('kill-marker');
    }
    this.messageUrlRegExp(this.notification);
  }

  ngAfterViewInit(): void {
    this._created.emit();
  }

  public getHeaderClass() {
    return {
      info: this.notification.Type === NotificationType.Info,
      profit: this.notification.Type === NotificationType.Profit,
      loss: this.notification.Type === NotificationType.Loss,
      error: this.notification.Type === NotificationType.Error
    };
  }

  public get Header(): string {
    return this.notification.Header;
  }

  public get Body(): string {
    let link: string;
    let body: string;
    if (this.notification.text === '') {
      link = 'Links...';
      return link;
    } else if (this.notification.text.length >= 20) {
      body = `${this.notification.text.slice(0, 17)}...`;
      return body;
    } else {
      return this.notification.text;
    }
  }

  public get Image(): string {
    return this.notification.Image;
  }

  public get Date(): Date {
    return this.notification.Time;
  }

  public get Read(): boolean {
    return !this.notification.isRead;
  }

  public get IsStatusAlwaysRead(): boolean {
    return this.notification.isRead;
  }

  @Input()
  set wasShown(v: boolean) {
    if (v && !this._wasShown) {
      new Timer(() =>
        this.notification.Unread = false,
        500).start();
    }

    this._wasShown = v;
  }

  get wasShown(): boolean {
    return this._wasShown;
  }

  @Output()
  public get created(): Observable<void> {
    return this._created.asObservable();
  }

  getIsOpened() {
    return this.notification.isOpen;
  }

  messageUrlRegExp(notification: Notification) {
    const temp = this.notificationService.messageUrlRegExp(notification);

    if (temp.linkArray.join() !== '') {
      this.notification.messageUrl = true;
    } else {
      this.notification.messageUrl = false;
    }
    this.notification.text = temp.textStr.join(' ');
    this.notification.link = temp.linkArray.join();
  }

  public async onRemoveMessageClick($event: MouseEvent, notification: Notification) {
    $event.stopPropagation();
    await this.notificationService.onRemoveMessage(notification);

    if (notification.From !== 'System') {
      await this.mailmessageprocessor.deleteMessage(notification.Id);
    }
  }
}
