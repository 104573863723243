import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommandAnswerService} from '@common/communication/connection/command-answer-service';
import {Clipboard} from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mode-debug-users',
  templateUrl: './mode-debug-users.component.html',
  styleUrls: ['./mode-debug-users.component.scss']
})
export class ModeDebugUsersComponent implements OnInit, OnDestroy {

  private _modeDebugUsers = [];
  private subscribeCommandAnswerService: Subscription;
  private _hiddenNotification = true;

  constructor(private commandAnswerService: CommandAnswerService,
              private clipboard: Clipboard) {
  }

  get ModeDebugUsers(): any[] {
    return this._modeDebugUsers.reverse();
  }

  ngOnInit(): void {
    let modeDebugUsers = JSON.parse(sessionStorage.getItem('modeDebugUsers'));

    if (modeDebugUsers !== null) {
      this._modeDebugUsers = modeDebugUsers;
    }

    this.subscribeCommandAnswerService = this.commandAnswerService.newEventEmitterCommand.subscribe(() => {
      modeDebugUsers = JSON.parse(sessionStorage.getItem('modeDebugUsers'));

      if (modeDebugUsers !== null) {
        this._modeDebugUsers = modeDebugUsers;
      }
    });
  }

  ngOnDestroy() {
    this.subscribeCommandAnswerService.unsubscribe();
  }

  get HiddenNotification() {
    return this._hiddenNotification;
  }

  get CheckArrayErrors(): boolean {
    return this._modeDebugUsers.length === 0;
  }

  public copyAll(event) {
    event.stopPropagation();

    const copyText = sessionStorage.getItem('modeDebugUsers');

    if (copyText !== null) {
      this.clipboard.copy(copyText);
      this._hiddenNotification = false;
      setTimeout(() => this._hiddenNotification = true, 3000);
    }
  }

}
