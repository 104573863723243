import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisibilitySyncService {
  private isFrameReady = false;

  constructor() { }

  public markFrameReady(): void {
    this.isFrameReady = true;
  }

  public get IsFrameReady(): boolean {
    return this.isFrameReady;
  }
}
