import {EventEmitter, Injectable} from '@angular/core';
import {TradeEventsAggregatorService} from '@common/trade/services/trade-events-aggregator.service';
import {NotificationService} from '@common/notification/services/notification.service';
import {Trade} from '@common/trade/models/trade';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {NotificationTemplate, NotificationTemplates} from '@common/notification/models/notification-templates';
import {HttpClient} from '@angular/common/http';
import {TradeSoundPlayerService} from '@common/player/trade-sound-player.service';
import {CurrencyConfig} from '@common/currency/utils/currency-config';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {PriceFormatter} from '@common/trade/utils/price-formatter';

type TemplatesBundle = Map<string, NotificationTemplate>;
export type TemplatesStorage = Map<string, TemplatesBundle>;

export interface TradeNotificationCreationArgs {
  TradeId: number;
  ClosePrice: number;
  SymbolName: string;
  Reason: string;
  KeyTranslate: string;
  TemplateName: string;
  AdditionalDataObj: Object;
}

export interface AdditionalData {
  icon: string;
  neededMargin: string;
  freeMargin: string;
  amount?: string;
  symbol?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationProviderService {

  // подписка на отслеживания изменения значение при открытии или закрытии ордера
  private _traderStatusChange: EventEmitter<string> = new EventEmitter<string>();
  // firing when pending order opened
  private _pendingOrderOpened: EventEmitter<number> = new EventEmitter<number>();

  public get PositionVolumeChange(): EventEmitter<string> {
    return this._traderStatusChange;
  }

  public get PendingOrderOpened(): EventEmitter<number> {
    return this._pendingOrderOpened;
  }

  constructor(private tradeEventAggregator: TradeEventsAggregatorService,
              private notificationService: NotificationService,
              private http: HttpClient,
              private tradeSoundPlayer: TradeSoundPlayerService) {

    this.tradeEventAggregator.PendingActivated.subscribe(trade => this.onTradePendingActivated(trade));
    this.tradeEventAggregator.ClosedByStopLoss.subscribe(trade => this.onTradeClosedByStopLoss(trade));
    this.tradeEventAggregator.ClosedByTakeProfit.subscribe(trade => this.onTradeClosedByTakeProfit(trade));
  }

  private async onTradePendingActivated(trade: Trade) {
    this.pushTradeNotification('TradePendingActivatedNotification', trade);
    this._traderStatusChange.emit('TradePendingActivated');
    await this.tradeSoundPlayer.pendingOrderActivated();
  }

  private onTradeClosedByStopLoss(trade: Trade) {
    this.pushTradeNotification('TradeClosedByStopLossNotification', trade);
  }

  private onTradeClosedByTakeProfit(trade: Trade) {
    this.pushTradeNotification('TradeClosedByTakeProfitNotification', trade);
  }

  public onTradeOpenError(reason: string, keyTranslate?: string, additionalDataObj?: Object) {
    this.pushTradeNotification('TradeCouldNotOpenNotification', null, reason, keyTranslate, additionalDataObj);
  }

  public onTradeOpen(trade: Trade | TradeDTO) {
    this.pushTradeNotification('TradeOpenedNotification', trade);
    this._traderStatusChange.emit('TradeOpened');
    if (trade.IsPending) {
      this._pendingOrderOpened.emit();
    }
  }

  public onTradePendingCreateError(reason: string, keyTranslate?: string) {
    this.pushTradeNotification('TradePendingCouldNotCreateNotification', null, reason, keyTranslate);
  }

  public onTradeUpdateError(trade: Trade | TradeDTO, reason: string, keyTranslate?: string) {
    this.pushTradeNotification('TradeCouldNotUpdateNotification', trade, reason, keyTranslate);
  }

  public onTradeUpdate(trade: Trade | TradeDTO) {
    this.pushTradeNotification('TradeUpdatedNotification', trade);
  }

  public onTradePendingUpdateError(trade: Trade | TradeDTO, reason: string, keyTranslate?: string) {
    this.pushTradeNotification('TradePendingCouldNotUpdateNotification', trade, reason, keyTranslate);
  }

  public onTradePendingUpdate(trade: Trade | TradeDTO) {
    this.pushTradeNotification('TradePendingUpdatedNotification', trade);
  }

  public onTradeCloseError(trade: Trade | TradeDTO, reason: string, keyTranslate?: string) {
    this.pushTradeNotification('TradeCouldNotCloseNotification', trade, reason, keyTranslate);
  }

  public onTradeClose(trade: Trade | TradeDTO) {
    this.pushTradeNotification('TradeClosedNotification', trade);
  }

  public onTradePendingDeleteError(trade: Trade | TradeDTO, reason: string, keyTranslate?: string) {
    this.pushTradeNotification('TradePendingCouldNotDeleteNotification', trade, reason, keyTranslate);
  }

  public onTradePendingDelete(trade: Trade | TradeDTO) {
    this.pushTradeNotification('TradePendingDeletedNotification', trade);
  }

  async load() {
    const jsonFile = `assets/notifications/templates.json`;
    await this.http.get(jsonFile).toPromise().then(async (response: NotificationTemplates) => {
      this.notificationService.StorageTemplates = await this.parseTemplates(response);
    });
  }

  clear() {
    this.notificationService.clear();
  }

  private async parseTemplates(templates: NotificationTemplates) {
    const storage: TemplatesStorage = new Map<string, TemplatesBundle>();
    const tradeBundle = await this.parseBundle(templates.TradeNotifications);
    storage.set('TradeNotifications', tradeBundle);
    return storage;
  }

  private async parseBundle(bundle: NotificationTemplate[]) {
    const result = new Map<string, NotificationTemplate>();
    bundle.forEach(item => result.set(item.Name, item));
    return result;
  }

  private pushTradeNotification(templateName: string, trade?: Trade | TradeDTO, reason?: string, keyTranslate?: string, additionalDataObj?: Object) {
    this.notificationService.pushTradeNotification(templateName, trade, reason, keyTranslate, additionalDataObj);
  }
}
