import {CommunicationModule} from '@common/communication/communication.module';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ISignalR, ISignalRHubProxy} from '@common/communication/connection/transport/ISignalR';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';
import {Injectable} from '@angular/core';
import {TransportBase} from '@common/communication/connection/transport/transport-base';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import {LogLevel} from '@microsoft/signalr';
import {AppConfig} from '@common/configuration/app-config';
import {ConnectionService} from '@common/communication/connection/connection.service';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {UsersGeoData} from '@common/trade/services/location-position.service';

declare const jQuery: any;

@Injectable({
  providedIn: CommunicationModule
})

export class TransportSignalRFactory {
  getTransport(connectionService?: ConnectionService): TransportBase {

    let useNewConnection = AppConfig.settings.connection.UseNewConnection;
    const usersGeoData: UsersGeoData = JSON.parse(localStorage.getItem('usersGeoData'));

    if (usersGeoData != null && AppConfig.settings.connection.regionsToConnectTo) {
      console.log('RegionConnect', usersGeoData.regionConnect);
      if (AppConfig.settings.connection.regionsToConnectTo[usersGeoData.regionConnect].UseNewConnection) {
        useNewConnection = AppConfig.settings.connection.regionsToConnectTo[usersGeoData.regionConnect].UseNewConnection;
      }

    }

    console.log('UseNewConnection', useNewConnection);

    if (useNewConnection) {
      return new TransportNewSignalR(connectionService);
    }
    return new TransportSignalR(connectionService);

  }
}

export class TransportSignalR extends TransportBase {
  protected _connection: ISignalR = null;
  protected _hubProxy: ISignalRHubProxy = null;
  private _connectionUri: string = null;
  private readonly _connectionService: ConnectionService;

  constructor(connectionService: ConnectionService) {
    super();
    this._connectionService = connectionService;
    this._logger = LoggerFactory.getLogger('TransportSignalR');
  }

  public init(connectionUri: string) {
    this._connectionUri = connectionUri;
  }

  send(message: string) {
    try {
      this._hubProxy.invoke('Message', message);
    } catch (e) {
      this._connectionService.sendTransportSignalRErrors(e);
      if (e.message.toString().startsWith('SignalR: Connection must be started before data can be sent.')) {
        console.log(e.message);
        // this.disconnect(DisconnectionCodes.ConnectionStopped);
        this.onReconnect();
        this.connect();
      } else {
        throw e;
      }
    }
  }

  connect() {
    if (this._connection != null) {
      this.disconnect();
    }

    this.createConnection();

    this.createHubProxy();

    setTimeout(() => {
      this._hubProxy.on('Message', (message: string) => {
        this.onMessage(message);
      });

      if (this._connection !== null) {
        this._connection.start({}, (msg: string) => {
          OperationsWithDate.initTimestamp('Start connecting SignalR');
          this.onStart(msg);
        })
          .done(() => {
            OperationsWithDate.initTimestamp('Done connecting SignalR');
            this.onStartDone();
          });

        this._connection.reconnecting(() => {
          console.log('TransportSignalR/reconnecting', 23);
          this.onReconnect();
        });

        this._connection.error((err: any) => {
          console.log('TransportSignalR/error', 23);
          this.onReconnect();
          this.connect();
          this.changeConnectionUrl();
        });
      }
    }, 1000);
  }

  private changeConnectionUrl() {
    if (AppConfig.settings.connection.ConnectionUrlsBalanced !== undefined &&
        AppConfig.settings.connection.ConnectionUrlsBalanced.length > 1 &&
        localStorage.getItem('BalancedConnectionNumber') !== null) {

      const connectionUrlsBalancedLength = AppConfig.settings.connection.ConnectionUrlsBalanced.length;
      const balancedConnectionNumber = Number(localStorage.getItem('BalancedConnectionNumber'));
      let arrayConnectionUrlsBalancedLengths = [];

      if (localStorage.getItem('ArrayConnectionUrlsBalancedLengths') !== null) {
        arrayConnectionUrlsBalancedLengths = JSON.parse(localStorage.getItem('ArrayConnectionUrlsBalancedLengths'));
      } else {
        for (let i = 0; i < connectionUrlsBalancedLength; i++) {
          arrayConnectionUrlsBalancedLengths.push(i);
        }
      }

      arrayConnectionUrlsBalancedLengths = arrayConnectionUrlsBalancedLengths.filter((item) => item !== balancedConnectionNumber);

      if (arrayConnectionUrlsBalancedLengths.length > 0) {
        localStorage.setItem('ArrayConnectionUrlsBalancedLengths', JSON.stringify(arrayConnectionUrlsBalancedLengths));
        localStorage.setItem('BalancedConnectionNumber', arrayConnectionUrlsBalancedLengths[0].toString());
        location.reload();
      }
    }
  }

  private onMessage(message: string) {
    if (this.getEventHandler() !== null) {
      this.getEventHandler().onMessageReceived(message);
    } else {
      console.log('on message with null event handler happened: ' + message);
    }

  }

  private onStart(message: string) {
    this._logger.info(`Connection start`, message);
    console.log(`Connection start`, message);
  }

  private onStartDone() {
    this._logger.info(`Connection done`);
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onConnectionEstablished();
    }
    // this.getEventHandler().onConnectionEstablished()
  }

  private onStartFail(msg: string) {
    this.getEventHandler().onConnectionEstablishedError(msg);
  }

  private onReconnect() {
    this.disconnect(DisconnectionCodes.Reconnect);
  }

  protected createConnection() {
    this._connection = jQuery.hubConnection(this._connectionUri, {});
  }

  protected createHubProxy() {
    this._hubProxy = this._connection.createHubProxy('ConnectionPointHub');
  }

  public disconnect(msg?: string) {
    if (this._connection != null) {
      this._connection.stop(false, true);
      this.getEventHandler().onDisconnected(msg || 'RemoteDisconnected');
      this._connection = null;
      this.releaseHandler();
    }
  }

  public getRemoteHostAsStr(): string {
    return this._connectionUri;
  }
}


export class TransportNewSignalR extends TransportBase {
  protected _connection: HubConnection | null = null;
  private _connectionUri: string = null;
  private readonly _connectionService: ConnectionService;

  constructor(connectionService: ConnectionService) {
    super();
    this._connectionService = connectionService;
    this._logger = LoggerFactory.getLogger('TransportSignalR');
  }

  public init(connectionUri: string) {
    this._connectionUri = connectionUri;
  }

  async send(message: string) {
    try {
      // this._hubProxy.invoke('Message', message);
      this._logger.debug('message sent :', message);
      await this._connection.invoke('Message', message);
    } catch (e) {
      this._connectionService.sendTransportSignalRErrors(e);
      if (e.message.toString().startsWith('SignalR: Connection must be started before data can be sent.')) {
        console.log('doing reconnect on this error :SignalR: Connection must be started before data can be sent.');
        // this.disconnect(DisconnectionCodes.ConnectionStopped);
        this.onReconnect();
        await this.connect();
      } else {
        throw e;
      }
    }
  }

  async connect() {
    if (this._connection != null) {
      await this.disconnect();
    }
    console.log('transport create connection');
    this.createConnection();

    this.createHubProxy();

    setTimeout(() => {
      console.log('transport Set timeout handler');
      this._logger.debug('connection: ', this._connection);
      if (this._connection !== null) {
        this._connection.on('Message', (message: string) => {
          this.onMessage(message);
        });

        try {
          this.onStart('started');
          this._connection.start().then(() => {
            console.log('SignalR SUCCESS');
            this.onStartDone();
          }).catch((reason) => {
            console.log('SignalR failed: ', reason);
            this.onStartFail(reason);
          });

          // this.onStartDone();
        } catch (e) {
          this.onStartFail(e);
          throw e;
        }
      }

      // TODO: Do we need it?
      this._connection.onreconnecting(() => {
        console.log('TransportSignalR/reconnecting', 23);
        this.onReconnect();
      });


    }, 1000);
  }

  private onMessage(message: string) {
    if (this.getEventHandler() !== null) {
      this.getEventHandler().onMessageReceived(message);
    } else {
      console.log('on message with null event handler happened: ' + message);
    }

  }

  private onStateChanged(message: string) {

  }

  private onStart(message: string) {
    this._logger.info(`Connection start`, message);
    console.log(`Connection start`, message);
  }

  private onStartDone() {
    this._logger.info(`Connection done`);
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onConnectionEstablished();
    }
    // this.getEventHandler().onConnectionEstablished()
  }

  private onStartFail(msg: string) {
    this.getEventHandler().onConnectionEstablishedError(msg);
  }

  private onReconnect() {
    this.disconnect(DisconnectionCodes.Reconnect);
  }

  protected createConnection() {
    // this._connection = jQuery.hubConnection(this._connectionUri, {});
    this._connection = new HubConnectionBuilder()
      .withUrl(this._connectionUri)
      .configureLogging(LogLevel.Critical)
      .build();
    /*
        this._connection.start().then((value) => {
          console.log('then: ', value);
        }).catch((reason) => {
          console.log('catch: ', reason);
        });*/
  }

  protected createHubProxy() {
    // this._hubProxy = this._connection.createHubProxy('ConnectionPointHub');
  }

  public async disconnect(msg?: string) {
    /*if (this._connection != null) {
      this._connection.stop(false, true);
      this.getEventHandler().onDisconnected(msg || 'RemoteDisconnected');
    }
    this._connection = null;
    this.releaseHandler();*/

    if (this._connection != null) {
      await this._connection.stop();
      this.getEventHandler().onDisconnected(msg || 'RemoteDisconnected');
      this._connection = null;
      this.releaseHandler();
    }

  }

  public getRemoteHostAsStr(): string {

    return this._connectionUri;
  }
}

