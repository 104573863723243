import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateUtils} from '../../../common/utils/date-utils';
import {TradeDTO} from '../../models/trade-d-t-o';
import {TradeType} from '../../models/trade-type';
import {PendingTradeValidator} from '../../utils/pending-trade.validator';
import {TradeService} from '../../services/trade.service';
import {Component, Directive, OnDestroy, OnInit} from '@angular/core';
import {ITradePromise} from '@common/trade/models/trade-promise';
import {Notification_TicketDecline} from '@common/communication/connection/classes.g';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeValidator} from '@common/trade/utils/trade.validator';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {SettingsService} from '@common/trader/services/settings.service';
import {Settings} from '@common/trader/models/settings';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Trader} from '@common/trader/models/trader';
import {NotificationService} from '@common/notification/services/notification.service';

@Directive()
export class PendingOrderForm implements OnInit, OnDestroy {

  pendingForm: FormGroup;
  private error: string;
  protected settingsService: SettingsService;
  protected validityError = false;
  private symbolSubscription: ISmartSubscription = undefined;
  private subscribeGetLastMessage: ISmartSubscription;
  private messageTimeOut: number;

  types = [
    {value: TradeType.SellLimit, name: 'Sell Limit'},
    {value: TradeType.SellStop, name: 'Sell Stop'},
    {value: TradeType.BuyLimit, name: 'Buy Limit'},
    {value: TradeType.BuyStop, name: 'Buy Stop'},
  ];



  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public get Step() {
    return Math.pow(10, -this.order.Symbol.DecimalPlaces);
  }

  public get VolumeDecimalPlaces(): number {
    return this.order.Symbol.VolumeDecimalPlaces;
  }

  public get VolumeStep(): number {
    return Math.pow(10, -this.order.Symbol.VolumeDecimalPlaces);
  }

  public get Symbols(): Symbol[] {
    return this.symbolStorage.Symbols;
  }

  public get Error(): string {
    return this.error;
  }

  protected order: TradeDTO;

  public get Order(): TradeDTO {
    return this.order;
  }

  public get amount(): AbstractControl {
    return this.pendingForm.controls['amount'];
  }

  public get symbol(): AbstractControl {
    return this.pendingForm.controls['symbol'];
  }

  public get rate(): AbstractControl {
    return this.pendingForm.controls['open-price'];
  }

  public get type(): AbstractControl {
    return this.pendingForm.controls['pending-type'];
  }

  public get CurrentDate(): string {
    return DateUtils.CurrentDateString();
  }

  public get expDate(): AbstractControl {
    return this.pendingForm.controls['expiration-date'];
  }

  public get CurrentRate(): number {
    return this.order.CurrentRate;
  }

  public get OpenPrice(): number {
    return this.order.OpenPrice;
  }

  constructor(protected tradeService: TradeService,
              protected symbolStorage: SymbolStorageService,
              protected tradeStorage: TradeStorage,
              protected trader: Trader,
              protected notificationService: NotificationService,
              protected settings: Settings) {
  }

  ngOnInit(): void {
    this.initOrder();
    this.formInit();
    this.subscribeGetLastMessage = this.subGetLastMessage();
  }

  ngOnDestroy(): void {
    this.subscribeGetLastMessage.unsubscribe();
  }

  protected initOrder() {
  }

  protected formInit() {
    this.pendingForm = new FormGroup({
      'pending-type': new FormControl(null, Validators.compose([Validators.required])),
      'expiration-date': new FormControl(null, Validators.compose([(control: AbstractControl) => this.expirationDateValidator(control)])),
      'amount': new FormControl( null, Validators.compose([Validators.required, (control) => this.amountValidator(control)]), ),
      'open-price': new FormControl(this.order.OpenPrice, Validators.compose([Validators.required, (control) => this.rateValidator(control)])),
    });
  }

  public onCurrentPriceChange() {
    this.pendingForm.controls['open-price'].updateValueAndValidity({});
  }

  protected getIndexOfSymbol(): number {
    const ind = this.Symbols.indexOf(this.Order.Symbol);

    return ind !== -1 ? ind : 0;
  }

  public onRateChange(v: number) {
    this.order.OpenPrice = Number(v);

    this.pendingForm.controls['open-price'].updateValueAndValidity({});
  }

  public onAmountChange(v: number) {
    this.order.Volume = Number(this.getConvertVolume(v));

    this.pendingForm.controls['amount'].updateValueAndValidity({});
  }

  public onTypeChange(v: number) {
    this.order.Type = Number(v);

    this.pendingForm.controls['open-price'].updateValueAndValidity({});
  }

  public onDateChange(v: Date) {
    this.order.ExpirationDate.Date = v;

    this.pendingForm.controls['expiration-date'].updateValueAndValidity({});
  }

  public subscribeToSymbol() {
    if (this.symbolSubscription) {
      this.symbolSubscription.unsubscribe();
    }

    this.symbolSubscription = this.order.Symbol.PriceChange.subscribe(() => this.onCurrentPriceChange());
  }


  protected expirationDateValidator(control: AbstractControl): { [s: string]: boolean } {
    if (control.value && new Date(control.value) < new Date()) {
      return {'expDate-invalid': false};
    }

    return null;
  }

  protected amountValidator(control: AbstractControl): { [s: string]: boolean } {
    const value = Number(control.value);

    if (value <= 0) {
      return {'amount-invalid': false};
    }

    return null;
  }

  protected rateValidator(control: AbstractControl): { [s: string]: boolean } {
    try {
      new PendingTradeValidator().checkPrice(this.order);
      return null;
    } catch (e) {
      return {'rate-invalid': true};
    }
  }

  protected onSubmitSuccess() {
  }

  protected orderAction(): Promise<ITradePromise> {
    try {
      new TradeValidator()
        .checkPrice(this.order)
        .checkBounds(this.order)
        .checkVolume(this.order);

      return this.tradeService.openOrder(this.order);
    } catch (e) {
      this.error = e.message;
      return null;
    }
  }

  public async submit() {
    const promise = await this.orderAction();

    if (!promise) {
      return;
    }

    promise.success(() => {
      this.onSubmitSuccess();
    }).error((message: Notification_TicketDecline) => {
      this.error = message.Message;
    });
  }

  protected getVolume(volume) {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(volume, this.order.Symbol.ContractSize, this.tradeService.getLotDelimiter());
    } else {
      return volume;
    }
  }

  private getConvertVolume(volume: number) {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.checkVolume(volume * this.order.Symbol.ContractSize) / this.tradeService.getLotDelimiter();
    } else {
      return volume;
    }
  }

  public get TradingStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.order.Symbol.getInputTradingStep(), this.order.Symbol.ContractSize, this.tradeService.getLotDelimiter());
    }
    return this.order.Symbol.getInputTradingStep();
  }


  public ValidityError(event: boolean) {
    this.validityError = event;
  }

  public get ValidityErrorModify() {
    return this.validityError;
  }

  // при включении торговать в лотах идет конвертация
  public showStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.Order.Symbol.TradingStep, this.Order.Symbol.ContractSize, this.trader.LotDelimeter);
    }
    return this.Order.Symbol.TradingStep;
  }

  private subGetLastMessage(): ISmartSubscription {
    return this.notificationService.NotificationReceived.subscribe((notification) => {

      console.log('notification', 23, notification);

      if (this.messageTimeOut !== undefined) {
        clearTimeout(this.messageTimeOut);
      }

      this.messageTimeOut = setTimeout(() => {
        this.messageTimeOut = undefined;
        this.error = undefined;
      }, 3000);

      this.error = this.checkLongName(notification.ShowMessage);
    });
  }

  checkLongName(text: string) {
    if (text == null) {
      return '';
    } else {
      const width = 40;
      if (text.length < width) {
        return text;
      } else {
        return text.substr(0, width).trim() + '...';
      }
    }
  }


}
