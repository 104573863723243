import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckBoxComponent),
    multi: true
  }]
})
export class CheckBoxComponent extends ValueAccessorBase<boolean> implements OnInit {

  @Input() checkBoxDisabled = false;

  public get IsDisabled() {
    return this.checkBoxDisabled ? true : null;
  }

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public toggle() {
    if(this.checkBoxDisabled) return;
    this.value = !this.value;
  }
}
