import {Bar} from '@tradingview/charting_library';


export class HistoryBar implements Bar {
  close: number;
  high: number;
  low: number;
  open: number;
  // Open time or start time of the quote
  time: number;
  volume: number;

  public clone () {
      const barToReturn = new HistoryBar();
      barToReturn.time = this.time;
      barToReturn.close = this.close;
      barToReturn.open = this.open;
      barToReturn.high = this.high;
      barToReturn.low = this.low;
      barToReturn.volume = this.volume;
      return barToReturn;
  }
}


