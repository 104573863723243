import {SymbolMessageProcessor} from './processors/symbol-message-processor';
import {TraderMessageProcessor} from './processors/trader-message-processor';
import {QuoteMessageProcessor} from './processors/quote-message-processor';
import {BalanceMessageProcessor} from './processors/balance-message-processor';
import {TradeStateMessageProcessor} from './processors/trade-state-message-processor';
import {ConnectionStateMessageProcessor} from './processors/connection-state-message-processor';
import {MarginStatusMessageProcessor} from './processors/margin-status-message-processor';
import {TokenMessageProcessor} from './processors/token-message-processor';
import {SessionMessageProcessor} from './processors/session-message-processor';
import {Injectable} from '@angular/core';
import {FullMarketDepthMessageProcessor} from '@common/communication/message-processor/processors/full-market-depth-message-processor';
import {MailMessageProcessor} from '@common/communication/message-processor/processors/mail-message-processor';

@Injectable({
  providedIn: 'root'
})
export class MessageProcessor {

  constructor(
    private symbolMessageProcessor: SymbolMessageProcessor,
    private traderMessageProcessor: TraderMessageProcessor,
    private tradeStateMessageProcessor: TradeStateMessageProcessor,
    private quoteMessageProcessor: QuoteMessageProcessor,
    private balanceMessageProcessor: BalanceMessageProcessor,
    private connectionStateMessageProcessor: ConnectionStateMessageProcessor,
    private marginStatusMessageProcessor: MarginStatusMessageProcessor,
    private tokenMessageProcessor: TokenMessageProcessor,
    private sessionMessageProcessor: SessionMessageProcessor,
    private fullMarketDepthMessageProcessor: FullMarketDepthMessageProcessor,
    private mailMessageProcessor: MailMessageProcessor
    ) {

  }

  public unsubscribe() {
    console.log('All unsubscribe message processor');
    this.symbolMessageProcessor.unsubscribeAll();
    this.traderMessageProcessor.unsubscribeAll();
    this.tradeStateMessageProcessor.unsubscribeAll();
    this.quoteMessageProcessor.unsubscribeAll();
    this.balanceMessageProcessor.unsubscribeAll();
    this.balanceMessageProcessor.resetCount();
    this.connectionStateMessageProcessor.unsubscribeAll();
    this.marginStatusMessageProcessor.unsubscribeAll();
    this.tokenMessageProcessor.unsubscribeAll();
    this.sessionMessageProcessor.unsubscribeAll();
    this.fullMarketDepthMessageProcessor.unsubscribeAll();
    this.mailMessageProcessor.unsubscribeAll();
  }

  public subscribe() {
    console.log('All subscribe message processor');
    this.symbolMessageProcessor.subscribe();
    this.traderMessageProcessor.subscribe();
    this.tradeStateMessageProcessor.subscribe();
    this.quoteMessageProcessor.subscribe();
    this.balanceMessageProcessor.subscribe();
    this.connectionStateMessageProcessor.subscribe();
    this.marginStatusMessageProcessor.subscribe();
    this.tokenMessageProcessor.subscribe();
    this.sessionMessageProcessor.subscribe();
    this.fullMarketDepthMessageProcessor.subscribe();
    this.mailMessageProcessor.subscribe();
  }
}
