import {Injectable} from '@angular/core';

export interface IClientCache<T, K, I> {
  getKey(item: I): K;
  getItems(item: I): Array<T> | object;
}

export class CachedDataContainer <T, K> {
  private _data: Map<K | string, Array<T> | object>;
  public refreshTime: Date;

  get data(): Map<K | string, Array<T> | object> {
    return this._data;
  }

  set data(value: Map<K | string, Array<T> | object>) {
    this._data = value;
    this.refreshTime = new Date();
  }

  constructor() {
    this._data = new Map<K, Array<T> | object>();
  }
}


export abstract class BaseTimedCache<T, K, I> implements IClientCache<T, K, I> {
  protected dataContainer: CachedDataContainer <T, K>;
  protected retentionPeriod: number; // in seconds, defaults is 1 hour
  protected abstract fillStorageOnStart(): void;
  protected abstract dumpStorage(): void;
  abstract refreshData();
  abstract getReturnData(key: K): Array<T> | undefined | object;
  abstract getKey(item: I): K ;

  constructor(retentionPeriod: number| null = 3600) {
    this.dataContainer = new CachedDataContainer<T, K>();
    this.retentionPeriod = retentionPeriod;
  }
  public getItems(item: I): Array<T> | object {
    const key: K = this.getKey(item);

    if (this.dataContainer.data.size == 0) {
      // сначала пробуем обновить данные из посеянного хранилища
      this.fillStorageOnStart();

      // если все еще пусто - обновить данные
      if (this.dataContainer.data.size == 0) {
        this.refreshData();
      }
    }

    if (!this.dataContainer.data.has(key)) {
      // обновить данные
      this.refreshData();
    }

    if (this.dataContainer.data.has(key) && Date.now().valueOf() - this.dataContainer.refreshTime.valueOf() > this.retentionPeriod) {
      // обновить данные асинхнронно
      this.refreshData();
    }

    // trying to use is as is maybe this is just a compiler issue
    return this.getReturnData(key);

  }

}

export abstract class LocalStorageTimedCache<T, K, I> extends  BaseTimedCache<T, K, I> {

  protected dumpStorage(): void {}

  // для текущей группы идет запись данных в мап
  protected  fillStorageOnStart(): void {
    const result = JSON.parse(localStorage.getItem(this.getLocalStorageName()));
    const t = new Map<K | string, Array<T> | object>();
    if (result !== null) {
      for (const [key, value] of Object.entries(result)) {
        // @ts-ignore
        t.set(Number(key), value);
      }
      this.dataContainer.data = t;
    }
  }

  // сохранение данных для групп в локал стородже
  protected writeLocalStorage(mapData, item) {
    const data = { [item]: mapData };
    if (localStorage.getItem(this.getLocalStorageName()) == null) {
      localStorage.setItem(this.getLocalStorageName(), JSON.stringify(data));
    } else {
      const tempMapData: any[] = JSON.parse(localStorage.getItem(this.getLocalStorageName()));
      tempMapData[item] = data[item];
      localStorage.setItem(this.getLocalStorageName(), JSON.stringify(tempMapData));
    }
  }

  protected abstract getLocalStorageName(): string;

}

@Injectable({
  providedIn: 'root'
})

export class DataBaseLocalStorageTimedCache  extends LocalStorageTimedCache<string, number, number> {

  constructor() {
    super();
  }

  private nameLocalStorage = 'DataBaseLocalStorageTimedCache';
  getReturnData(key: number): string[] | undefined | object {
    if (this.dataContainer.data == undefined) {
      return undefined;
    }
    return this.dataContainer.data.get(key);
  }
  async refreshData() {
    this.dumpStorage();
  }
  getKey(item: number): number {
    return item;
  }
  public setDataLocalStorageTimedCache(key: number, item: string, data) {
    const t = new Map();
    let tempData = { [item]: data};
    if (this.dataContainer.data.get(key) !== undefined) {
      tempData = {
        ...this.dataContainer.data.get(key),
        [item]: data,
      };
    }
    t.set(key, tempData);
    this.dataContainer.data = t;
    this.writeLocalStorage(this.dataContainer.data.get(key), key);
  }

  getLocalStorageName(): string {
    return this.nameLocalStorage;
  }

}
