import {Trader} from '@common/trader/models/trader';
import {Answer_LoginSuccess, Notification_TraderReadOnly} from '../../connection/classes.g';
import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TraderMessageProcessor extends AbstractMessageProcessor {
  public constructor(serverConnection: ServerInteractionService,
                     private trader: Trader,
                     private credentialsStorage: LocalStorageService) {
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToLoginSuccess();
    this.subscribeOnTraderReadOnly();
  }

  private subscribeToLoginSuccess(): void {
    const subscription = this.serverConnection.on(Answer_LoginSuccess.GetMessageType(),
      (answer: Answer_LoginSuccess) => this.onTraderHandle(answer),
      (error: any) => this.logger.error('Trader data handling error:', error));

    this.saveSubscription(Answer_LoginSuccess.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Answer_LoginSuccess.GetMessageType()}`);
  }

  private onTraderHandle(answer: Answer_LoginSuccess): void {
    this.trader.TraderName = answer.LoginName;
    this.trader.TraderId = Number(answer.TraderID);
    this.trader.TradeGroupId = Number(answer.GroupID);
    this.trader.TradeGroupName = answer.TradeGroupName;
    this.trader.FirstName = answer.FirstName;
    this.trader.MiddleName = answer.MiddleName;
    this.trader.LastName = answer.LastName;
    this.trader.ShouldChangePassword = answer.LoginPwdMustChanged.toString().toLowerCase() === 'true';
    // TODO: Handle answer field: LoginPwdMustChanged
    this.trader.IsReadOnlyAccount = answer.IsInvestorAccount.toString().toLowerCase() === 'true';
    this.credentialsStorage.saveSessionKey(answer.SessionID);
    this.trader.ExternalAccountID = answer.ExternalAccountID;
    this.trader.isNakedSellAllowed = answer.IsNakedSellAllowed.toString().toLowerCase() === 'true';
    // присвоение из ответа минимальных сум для депозитов первого и последующего
    this.trader.MinFirst = answer.MinFirst;
    this.trader.MinNext = answer.MinNext;
    this.trader.IsChallengeAccount = answer.IsChallengeAccount.toString().toLowerCase() === 'true';
    this.trader.WithdrawalLimit = Number(answer.WithdrawalLimit);
    this.trader.ServerTime = answer.FXServerTime;
    this.trader.LotTypeName = answer.LotTypeName;

    if (answer.LotDelimeter === undefined || Number(answer.LotDelimeter) === 0) {
      this.trader.LotDelimeter = 1;
    } else {
      this.trader.LotDelimeter = Number(answer.LotDelimeter);
    }

    console.log(answer);
  }

  public subscribeOnTraderReadOnly(): void {

    const subscription = this.serverConnection.on(Notification_TraderReadOnly.GetMessageType(),
      (answer: Notification_TraderReadOnly) => this.onTraderReadOnly(answer),
      (error: any) => this.logger.error('Trader data handling error:', error));

    this.saveSubscription(Notification_TraderReadOnly.GetMessageType(), subscription);

    this.logger.debug(`Subscribed for ${Notification_TraderReadOnly.GetMessageType()}`);
  }

  public onTraderReadOnly(answer: Notification_TraderReadOnly): void {
    this.trader.IsReadOnlyAccount = true;
    console.log('The status of your account is read-only');
    console.log(answer);
  }
}
