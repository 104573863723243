import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BuySellButtonsComponent} from './components/buy-sell-buttons/buy-sell-buttons.component';
import {MessageComponent} from './components/message/message.component';
import {OneClickTradingIconComponent} from './components/one-click-trading-icon/one-click-trading-icon.component';
import {LocaleModule} from '../locale/locale.module';
import {OnClickOutsideDirective} from './directives/on-click-outside/on-click-outside.directive';
import {NumberInputComponent} from './components/number-input/number-input.component';
import {RedAlertComponent} from './components/red-alert/red-alert.component';
import {CheckBoxComponent} from './components/check-box/check-box.component';
import {IsVisibleDirective} from './directives/is-visible/is-visible.directive';
import {MinValueValidator} from './directives/validators/min-value-validator.directive';
import {MaxValueValidator} from './directives/validators/max-value-validator.directive';
import {NonZeroValidator} from '@common/shared/directives/validators/non-zero-validator.directive';
import {MovableDirective} from '@common/shared/directives/movable/movable.directive';
import {SelectableInputComponent} from './components/selectable-input/selectable-input.component';
import {CustomSelectComponent} from './components/custom-select/custom-select.component';
import {ScrollDirective} from './directives/scroll.directive';
import {DatetimePickerComponent} from './components/datetime-picker/datetime-picker.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {TimePickerComponent} from './components/time-picker/time-picker.component';
import {AlwaysOnScreenDirective} from './directives/always-on-screen/always-on-screen.directive';
import {OrderPriceValidatorDirective} from './directives/validators/order-price.validator.directive';
import {TakeProfitValidatorDirective} from './directives/validators/take-profit.validator.directive';
import {StopLossValidatorDirective} from './directives/validators/stop-loss-validator.directive';
import {ExpirationsDateValidatorDirective} from './directives/validators/expirations-date-validator.directive';
import {AutoTradeSizingStopLossValidator} from './directives/validators/auto-trade-sizing-sl.validator.directive';
import {HeightOfDirective, SwipeDownDirective, SwipeUpDirective} from '@common/shared/directives/swipe.directive';
import {OneUpperCaseValidator} from '@common/shared/directives/validators/one-upper-case.validator';
import {OneLowerCaseValidator} from '@common/shared/directives/validators/one-lower-case.validator';
import {OneSpecialSymbolValidator} from '@common/shared/directives/validators/one-special-symbol.validator';
import {EightSymbolsValidator} from '@common/shared/directives/validators/eight-symbols.validator';
import {PasswordsMatchValidator} from '@common/shared/directives/validators/passwords-match.validator';
import {OneNumberValidator} from '@common/shared/directives/validators/one-number.validator';
import {FormattingModule} from '@common/formatting/formatting.module';
import {MoneyInputComponent} from '@common/shared/components/money-input/money-input.component';
import {VisibilityFrameDirective} from './directives/visibility-frame/visibility-frame.directive';
import {ResizeSensorDirective} from './directives/resize-sensor/resize-sensor.directive';
import {VisibilityForDirective} from './directives/visibility-for/visibility-for.directive';
import { VolumeInputComponent } from './components/volume-input/volume-input.component';
import { BuySellSelectorComponent } from './components/buy-sell-selector/buy-sell-selector.component';
import { ChartPeriodChangerComponent } from './components/chart-period-changer/chart-period-changer.component';
import { MatChipsModule} from '@angular/material/chips';
import { TradingViewChartsModule } from '@common/trading-view-charts/trading-view-charts.module';
import { SymbolIconComponent } from './components/symbol-icon/symbol-icon.component';
import {HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { HistoryChartComponent } from './components/tv-history-chart/history-chart.component';
import { TvLightHistoryChartComponent } from './components/tv-light-history-chart/tv-light-history-chart.component';
import { TvSmallLightHistoryChartComponent } from './components/tv-small-light-history-chart/tv-small-light-history-chart.component';
import { CfaVolumeInputComponent } from './components/cfa-inputs/cfa-volume-input/cfa-volume-input.component';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatButtonModule} from '@angular/material/button';
import { CfaPriceInputComponent } from './components/cfa-inputs/cfa-price-input/cfa-price-input.component';
import { CfaStoplossTakeprofitInputComponent } from './components/cfa-inputs/cfa-stoploss-takeprofit-input/cfa-stoploss-takeprofit-input.component';
import { AgreementPageComponent } from './agreement/agreement-page/agreement-page.component';
import { PdfPageComponent } from './agreement/pdf-page/pdf-page.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MobileAgreementPageComponent } from './agreement/mobile-agreement-page/mobile-agreement-page.component';
import { TvLightHistoryCandleSubscribeChartComponent } from './components/tv-light-history-candle-subscribe-chart/tv-light-history-candle-subscribe-chart.component';
import {CharacterLengthValidator} from '@common/shared/directives/validators/character-length.validator';
import {NumberOrSpecialSymbolValidator} from '@common/shared/directives/validators/number-or-special-symbol.validator';
import {ValidPasswordVerification} from '@common/shared/directives/validators/valid-password-verification.validator';
import {StopLossPipsValidatorDirective} from '@common/shared/directives/validators/stop-loss-pips.validator.directive';
import {SymbolNameComponent} from '@common/shared/components/symbol-name/symbol-name.component';
import {SymbolHeaderSectionComponent} from '@common/shared/components/symbol-header-section/symbol-header-section.component';
import {SpinnerComponent} from '@common/shared/components/spinner/spinner.component';


@NgModule({
  declarations: [
    BuySellButtonsComponent,
    MessageComponent,
    OneClickTradingIconComponent,
    OnClickOutsideDirective,
    NumberInputComponent,
    RedAlertComponent,
    IsVisibleDirective,
    CheckBoxComponent,
    NonZeroValidator,
    MinValueValidator,
    MaxValueValidator,
    MovableDirective,
    SelectableInputComponent,
    CustomSelectComponent,
    ScrollDirective,
    DatetimePickerComponent,
    DatePickerComponent,
    TimePickerComponent,
    AlwaysOnScreenDirective,
    OrderPriceValidatorDirective,
    TakeProfitValidatorDirective,
    StopLossValidatorDirective,
    ExpirationsDateValidatorDirective,
    AutoTradeSizingStopLossValidator,
    SwipeDownDirective,
    SwipeUpDirective,
    HeightOfDirective,
    OneUpperCaseValidator,
    OneLowerCaseValidator,
    OneSpecialSymbolValidator,
    EightSymbolsValidator,
    PasswordsMatchValidator,
    OneNumberValidator,
    MoneyInputComponent,
    VisibilityFrameDirective,
    ResizeSensorDirective,
    VisibilityForDirective,
    VolumeInputComponent,
    BuySellSelectorComponent,
    ChartPeriodChangerComponent,
    SymbolIconComponent,
    HistoryChartComponent,
    TvLightHistoryChartComponent,
    TvSmallLightHistoryChartComponent,
    CfaVolumeInputComponent,
    CfaPriceInputComponent,
    CfaStoplossTakeprofitInputComponent,
    AgreementPageComponent,
    PdfPageComponent,
    MobileAgreementPageComponent,
    TvLightHistoryCandleSubscribeChartComponent,
    CharacterLengthValidator,
    NumberOrSpecialSymbolValidator,
    ValidPasswordVerification,
    StopLossPipsValidatorDirective,
    SymbolNameComponent,
    SymbolHeaderSectionComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    LocaleModule,
    FormattingModule,
    MatChipsModule,
    TradingViewChartsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatButtonModule,
    PdfViewerModule
  ],
    exports: [
      BuySellButtonsComponent,
      MessageComponent,
      OneClickTradingIconComponent,
      OnClickOutsideDirective,
      NumberInputComponent,
      RedAlertComponent,
      IsVisibleDirective,
      CheckBoxComponent,
      HeightOfDirective,
      NonZeroValidator,
      MaxValueValidator,
      MinValueValidator,
      MovableDirective,
      SelectableInputComponent,
      ScrollDirective,
      CustomSelectComponent,
      DatetimePickerComponent,
      AlwaysOnScreenDirective,
      OrderPriceValidatorDirective,
      TakeProfitValidatorDirective,
      StopLossValidatorDirective,
      ExpirationsDateValidatorDirective,
      AutoTradeSizingStopLossValidator,
      OneUpperCaseValidator,
      OneLowerCaseValidator,
      OneSpecialSymbolValidator,
      EightSymbolsValidator,
      PasswordsMatchValidator,
      OneNumberValidator,
      MoneyInputComponent,
      VisibilityFrameDirective,
      ResizeSensorDirective,
      VisibilityForDirective,
      VolumeInputComponent,
      BuySellSelectorComponent,
      ChartPeriodChangerComponent,
      SymbolIconComponent,
      HistoryChartComponent,
      TvLightHistoryChartComponent,
      TvSmallLightHistoryChartComponent,
      CfaVolumeInputComponent,
      CfaPriceInputComponent,
      CfaStoplossTakeprofitInputComponent,
      TvLightHistoryCandleSubscribeChartComponent,
      CharacterLengthValidator,
      NumberOrSpecialSymbolValidator,
      ValidPasswordVerification,
      StopLossPipsValidatorDirective,
      SymbolNameComponent,
      SymbolHeaderSectionComponent,
      SpinnerComponent
    ],
  providers: [
  ],
})
export class SharedModule {
}
