import {ControlValueAccessor} from '@angular/forms';
import {AfterViewInit, Component, Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Logger} from '@common/common/utils/logging/logger';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';

enum State {
  Created,
  Init,
  Inited
}

@Directive()
export class ValueAccessorBase<T> implements ControlValueAccessor, OnInit, AfterViewInit {
  protected _innerValue: T;
  protected _disabled: boolean;

  protected logger: Logger;

  private _defaultValue: T;

  private changeEmitter: EventEmitter<T> = new EventEmitter<T>();
  private changed = new Array<(value: T) => void>();

  private touchedEmitter: EventEmitter<void> = new EventEmitter<void>();
  private touched = new Array<() => void>();

  private blurEmitter: EventEmitter<void> = new EventEmitter<void>();

  private touching = false;

  private state: State;

  protected constructor() {
    this.logger = LoggerFactory.getLogger(this);
    this.state = State.Created;
  }

  getValue(): T {
    return this.innerValue;
  }

  setValue(v: T) {
    this.innerValue = v;
    this.change(v);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * @deprecated
   * It is used for provider's interface. don't use it.
   * For value changing u should use "value" setter;
   * @param value
   */
  writeValue(value: T) {
    if (this.innerValue == value || (this.blockOnTouch && this.touching) && (this.innerValue != this._defaultValue)) {
      return;
    }

    this.setValue(value);
  }

  change(value: T) {
    if ((!this.touching && this.blockFreeChange) || this.disabled || (this.state != State.Inited && this.blockTillInit)) {
      return;
    }
    this.changed.forEach(f => f(value));
    this.changeEmitter.emit(value);
  }

  touch() {
    this.touching = true;
    this.touched.forEach(f => f());
    this.touchedEmitter.emit();
  }

  blur(): void {
    this.touching = false;
    this.writeValue(this.innerValue);
    this.blurEmitter.emit();
  }

  ngOnInit(): void {
    this._defaultValue = this.innerValue;

    if (this.innerValue && !this.value) {
      this.value = this.innerValue;
    }

    this.state = State.Init;
  }

  ngAfterViewInit(): void {
    this.state = State.Inited;
  }

  registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    this.writeValue(value);
  }

  get innerValue(): T {
    return this._innerValue;
  }

  @Input('value')
  set innerValue(v: T) {
    this._innerValue = v;
  }

  public get Touching() {
    return this.touching;
  }

  @Input()
  set disabled(v: boolean) {
    this._disabled = v;
  }

  @Input()
  blockOnTouch = false;
  @Input()
  blockFreeChange = false;
  @Input()
  blockTillInit = false;

  @Output('blur')
  get blurObservable(): Observable<void> {
    return this.blurEmitter.asObservable();
  }

  @Output('touch')
  get touchObservable(): Observable<void> {
    return this.touchedEmitter.asObservable();
  }

  @Output('changed')
  get changeObservable(): Observable<T> {
    return this.changeEmitter.asObservable();
  }
}
