import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountCurrencyTitleComponent} from './components/account-currency-title/account-currency-title.component';
import {CurrencySignPipe} from './pipes/currency-sign.pipe';
import {AccountCurrencyIconComponent} from './components/account-currency-icon/account-currency-icon.component';

@NgModule({
  declarations: [
    AccountCurrencyTitleComponent,
    CurrencySignPipe,
    AccountCurrencyIconComponent,
  ],
  exports: [
    AccountCurrencyTitleComponent,
    CurrencySignPipe,
    AccountCurrencyIconComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class CurrencyModule { }
