import {TransportEventHandlerInterface} from './transport-event-handler-interface';
import {ITransport} from './transport-interface';
import {Logger} from '@common/common/utils/logging/logger';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Injectable} from '@angular/core';
import {ConnectionService} from '@common/communication/connection/connection.service';

@Injectable({
  providedIn: 'root'
})
export abstract class TransportFactory {
  getTransport(connectionService?: ConnectionService): TransportBase {
    return null;
  }
}

export abstract class TransportBase implements ITransport {
  private _eventHandler: TransportEventHandlerInterface;
  protected _logger: Logger;

  protected constructor() {
    this._logger = LoggerFactory.getLogger(this.constructor.name);
  }

  protected releaseHandler() {
    this._eventHandler = null;
  }

  public getEventHandler(): TransportEventHandlerInterface {
    return this._eventHandler;
  }

  public setEventHandler(eh: TransportEventHandlerInterface) {
    this._eventHandler = eh;
  }

  public init(uri: string) {

  }

  public abstract send(message: string);
  public abstract connect();
  public abstract disconnect(msg?: string);
  public abstract getRemoteHostAsStr(): string;
}
