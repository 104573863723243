import {ChangeDetectorRef, Component, Directive, OnDestroy, OnInit} from '@angular/core';
import {TranslateManagerService} from '../servises/translate-manager.service';
import {ILocalized} from '../i-localized';

@Directive()
export abstract class LocalComponent implements OnInit, OnDestroy, ILocalized {
  protected constructor(private detector: ChangeDetectorRef, private translateManager: TranslateManagerService) {
    this.translateManager.subscribe(this);
  }

  ngOnInit() {
    this.translateManager.subscribe(this);
    this.localize();
  }

  ngOnDestroy() {
    this.translateManager.unsubscribe(this);
  }

  public localize(){
    this.detector.detectChanges();
  }
}
