export class CustomNumber {
  private static partialCut(v: number, decimalPlaces: number): number {
    let artifact = 0;

    if (decimalPlaces) {
      artifact = Math.pow(10, -(decimalPlaces > 4 ? 4 : decimalPlaces + 5)) * (v >= 0 ? 1 : -1);
    }

    const temp = Math.pow(10, decimalPlaces);
    const number = v * temp + artifact;
    const trunc = Math.trunc(number);
    return trunc /  temp;
  }

  public static cutNumber(v: number, decimalPlaces: number): number {
    let temp = null;
    // if(decimalPlaces > 0)
    //  temp = this.partialCut(v, decimalPlaces * 3);
    // else
      temp = v;

    return this.partialCut(temp, decimalPlaces);
  }

  public static roundForPlace(v: number, decimalPlaces: number): number {
    const temp = Math.pow(10, decimalPlaces);

    const number = v * temp;

    const trunc = Math.round(number);

    return trunc / temp;
  }

  public static accountingRoundForPlace(v: number, decimalPlaces: number): number {
    const temp = Math.pow(10, decimalPlaces + 1);

    const number = v * temp;

    const trunc = Math.round(number);

    if (Math.abs(trunc % 10) === 5) {
      return CustomNumber.roundForNearestEven(trunc) / temp;
    }

    return CustomNumber.roundForPlace(v, decimalPlaces);
  }

  public static roundForNearestEven(v: number) {
    const threshold = (v % 100 - v % 10) / 10;

    if (threshold > 0 && threshold % 2 === 0) {
      return v - 5;
    } else if (threshold > 0) {
      return v + 5;
    } else if (threshold < 0 && threshold % 2 === 0) {
      return v + 5;
    } else { return v - 5; }
  }
}
