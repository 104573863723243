import { Component, OnInit } from '@angular/core';
import {Trader} from '@common/trader/models/trader';
import {TraderCommandSender} from '@common/communication/command-sender/trader-command-sender';
import {Router} from '@angular/router';

@Component({
  selector: 'app-agreement-page',
  templateUrl: './agreement-page.component.html',
  styleUrls: ['./agreement-page.component.scss']
})
export class AgreementPageComponent implements OnInit {

  constructor(private trader: Trader,
              private traderCommandSender: TraderCommandSender,
              private router: Router) { }

  ngOnInit(): void {
  }

  agreeClick() {
    this.traderCommandSender.traderSignAgreement(this.trader.TraderId).then(() => {
      this.router.navigate(['terminal']);
    });
  }

}
