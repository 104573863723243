<div class="toolbar header-container" >
  <div class="settings-block back-button-container" *ngIf="!IsTradingViewDesktop">
    <div class="gear-icon icon z-back-icon" (click)="goToBack()"></div>
    <span><l-unit [key]="'Quotes_SearchComponent_SymbolSearch'"></l-unit></span>
  </div>
  <div class="search-block">
    <div class="search-input-container" [class.z-icon-container-search]="isZeTradex()">
      <div class="search-icon icon"></div>
      <input class="c-input search-input search-symbol-input" placeholder="{{PlaceholderSearch}}" [(ngModel)]="symbolSearch.Pattern" />
    </div>
  </div>
</div>

<div [class.list-more]="CheckCategoryList(Category, 4)">
  <div class="market-chip-list tabs-search">
    <div class="category-chip"
         *ngFor="let category of Category"
         [class.active]="categoryActive(category)"
         (click)="setCategory(category)">
      <span>{{ translateSymbolsCategories(category) }}</span>
    </div>
  </div>

</div>

<div class="z-search-legend-grid">
  <div class="z-search-legend-symbol" ><l-unit [key]="'Quotes_SearchComponent_Symbol'"></l-unit></div>
  <div class="z-search-legend-descriptio"><l-unit [key]="'Quotes_SearchComponent_Description'"></l-unit></div>
  <div class="z-search-legend-category"></div>
</div>

<div class="search-symbols-table" >
  <ul class="quotes-list custom-scroll list" [checkOnChange]="VisibleSymbols" visibility-frame resize-sensor bufferPercent="60">
    <li class="symbol-component list-row" (visible)="ref.visibility = $event"
        #ref *ngFor="let symbol of VisibleSymbols; trackBy: trackBySymbolId"
        [class.z-list-row]="isZeTradex()">
      <div *ngIf="ref.visibility" app-symbol-search [symbolModel]="symbol" (setSymbolToPath)="setSymbolToPath($event)"></div>
      <div style="height: 41px" *ngIf="!ref.visibility"></div>
    </li>

    <li class="list-row  symbol-component no-symbols-message" *ngIf="VisibleSymbols.length == 0">
      <l-unit [key]="'Quotes_QuotesComponent_NoSymbols'"></l-unit>
    </li>
  </ul>
</div>
