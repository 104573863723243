export class Repeater {
  private _interval: number;
  private _id = undefined;
  private _routine: () => void;

  public constructor(routine: () => void, interval: number){
    this._interval = interval;
    this._routine = routine;
  }

  public start(): Repeater {
    this._id = setInterval(this._routine, this._interval);

    return this;
  }

  public stop(): Repeater {
    clearInterval(this._id);
    this._id = null;

    return this;
  }

  public get IsKilled(): boolean {
    return this._id == null;
  }

  public get IsActive(): boolean {
    return this._id != null && this._id != undefined;
  }
}
