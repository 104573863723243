import {AdditionalData} from '@common/notification/services/notification-provider.service';


export class CustomValidationError extends Error {
  public keyTranslate: string;
  public additionalDataObj: AdditionalData;

  public constructor(message?: string, keyTranslate?: string, additionalDataObj?: AdditionalData) {
    super(message);
    this.keyTranslate = keyTranslate;
    this.additionalDataObj = additionalDataObj;
    Object.setPrototypeOf(this, CustomValidationError.prototype);
    this.name = this.constructor.name;
  }
}
