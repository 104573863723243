import {Component, OnInit} from '@angular/core';
import {MarginStatusService} from '@common/trader/services/margin-status.service';
import {MarginStatus} from '@common/trader/models/margin-status';
import {Accounting} from '../../../trader/models/accounting.service';

@Component({
  selector: 'app-margin-call-notification',
  templateUrl: './margin-call-notification.component.html',
  styleUrls: ['./margin-call-notification.component.scss']
})
export class MarginCallNotificationComponent implements OnInit {
  public get MarginStatus(): MarginStatus {
    return this.marginService.MarginStatus;
  }

  public get RequiredDeposit(): number {
    if(this.Equity < this.UsedMargin){
      return this.UsedMargin - this.Equity + 1;
    }
    else return 0;
  }

  public get LiquidationTime(): Date {
    return this.marginService.LiquidationTime;
  }

  private get Equity(): number {
    return this.accounting.Equity;
  }

  private get UsedMargin(): number {
    return this.accounting.UsedMargin;
  }

  constructor(private marginService: MarginStatusService, private accounting: Accounting) { }

  ngOnInit() {
  }
}
