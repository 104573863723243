import {SymbolStorageService} from '../../symbol/services/symbol-storage.service';
import {Symbol} from '../../symbol/models/symbol';
import {Message_AccountingDocument} from '../../communication/connection/classes.g';
import {AccountingDocumentType} from '../utils/accounting-document-type.enum';
import {ProfitCalculator} from '../utils/calculations/profit-calculator';
import {TradeType} from './trade-type';
import {StateType} from './trade-state-enum';
import {DateField} from '../../common/models/date-field';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {DateUtils} from '@common/common/utils/date-utils';

export class TradeStateSnapshot {
  private _profitCalculator: ProfitCalculator;
  public get ProfitCalculator(): ProfitCalculator {
    if(!this._profitCalculator) {
      this._profitCalculator = new ProfitCalculator(this.Symbol, this.OrderType);
    }
    return this._profitCalculator;
  }

  private _profit: number;

  public get Profit(): number {
    return this._profit;
  }

  public get ProfitPips(): number {
    return this.ProfitCalculator.calculatePips(this.OpenPrice, this.CommandPrice)
  }

  private _stateType: StateType;
  public get StateType(): StateType {
    return this._stateType;
  }

  private _clientOrderId: number;
  public get ClientOrderId(): number {
    return this._clientOrderId;
  }

  private _commandMargin: number;
  public get CommandMargin(): number {
    return this._commandMargin;
  }

  private _commandOriginId: number;
  public get CommandOriginId(): number {
    return this._commandOriginId;
  }

  private _commandPrice: number;
  public get CommandPrice(): number {
    return this._commandPrice;
  }

  private _commandSenderId: number;
  public get CommandSenderId(): number {
    return this._commandSenderId;
  }

  private _commandTime: Date;
  public get CommandTime(): Date {
    return this._commandTime;
  }

  private _commandTypeId: number;
  public get CommandTypeId(): number {
    return this._commandTypeId;
  }

  private _commandVolume: number;
  public get CommandVolume(): number {
    return this._commandVolume;
  }

  private _comment: string;
  public get Comment(): string {
    return this._comment;
  }

  private _expirationDate: DateField;
  public get ExpirationDate(): DateField {
    return this._expirationDate;
  }

  private _margin: number;
  public get Margin(): number {
    return this._margin;
  }

  private _openPrice: number;
  public get OpenPrice(): number {
    return this._openPrice;
  }

  private _orderId: number;
  public get OrderId(): number {
    return this._orderId;
  }

  private _orderType: TradeType;
  public get OrderType(): TradeType {
    return this._orderType;
  }

  private _stateId: number;
  public get StateId(): number {
    return this._stateId;
  }

  private _stopLoss: number;
  public get StopLoss(): number {
    return this._stopLoss;
  }

  private _symbol: Symbol;
  public get Symbol(): Symbol {
    return this._symbol;
  }

  private _takeProfit: number;
  public get TakeProfit(): number {
    return this._takeProfit;
  }

  private _traderId: number;
  public get TraderId(): number {
    return this._traderId;
  }

  private _trailingStop: number;
  public get TrailingStop(): number {
    return this._trailingStop;
  }

  private _trailingStopValue: number;
  public get TrailingStopValue(): number {
    return this._trailingStopValue;
  }

  private _rollover: number = 0;
  public get Storage(): number {
    return this._rollover;
  }

  private _volume: number;
  public get Volume(): number {
    return this._volume;
  }

  private _transactionFee: number;
  public get TransactionFee(): number {
    return this._transactionFee ? CustomNumber.cutNumber(this._transactionFee, 2) : 0;
  }

  private _subaccountFee: number;
  public get SubaccountFee(): number {
    return this._subaccountFee ? CustomNumber.cutNumber(this._subaccountFee, 2) : 0;
  }

  private _dealID: number;
  public get DealId(): number {
    return this._dealID;
  }

  private _commandVolumeUSD: number;
  public get CommandVolumeUSD(): number {
    return this._commandVolumeUSD;
  }

  private _commandVolumeAC: number;
  public get CommandVolumeAC(): number {
    return this._commandVolumeAC;
  }

  private _volumeUSD: number;
  public get VolumeUSD(): number {
    return this._volumeUSD;
  }

  private _volumeAC: number;
  public get VolumeAC(): number {
    return this._volumeAC;
  }

  public constructor() {
    this._expirationDate = new DateField();
  }

  public loadFromItem(item: any, symbolsStorage: SymbolStorageService) {
    this._commandMargin = Number(item.CommandMargin);
    this._commandOriginId = Number(item.CommandOriginID);
    this._commandVolume = Number(item.CommandVolume);
    this._commandPrice = Number(item.CommandPrice);
    this._commandSenderId = Number(item.CommandSenderID);
    this._commandTime = new Date(item.CommandTime);
    this._commandTypeId = Number(item.CommandTypeID);
    this._comment = item.Comment;
    this._expirationDate.Date = item.ExpirationDate == DateField.EmptyConstant ? null : DateUtils.addMinutes(item.ExpirationDate, -new Date().getTimezoneOffset());
    this._margin = Number(item.Margin);
    this._openPrice = Number(item.OpenPrice);
    this._orderId = Number(item.OrderID);
    this._orderType = <TradeType>Number(item.OrderTypeID - 1);
    this._stateId = Number(item.StateID);
    this._stopLoss = Number(item.StopLoss);
    this._symbol = symbolsStorage.findSymbolById(Number(item.SymbolID));
    this._takeProfit = Number(item.TakeProfit);
    this._traderId = Number(item.TraderID);
    this._trailingStop = Number(item.TrailingStop);
    this._trailingStopValue = Number(item.TrailingStopValue);
    this._volume = Number(item.Volume);
    this._profit = 0;
    this._transactionFee = 0;
    this._rollover = 0;
    this._subaccountFee = 0;
    this._dealID = Number(item.DealID);
    this._commandVolumeUSD = Number(item.CommandVolumeUSD);
    this._commandVolumeAC = Number(item.CommandVolumeAC);
    this._volumeUSD = Number(item.VolumeUSD);
    this._volumeAC = Number(item.VolumeAC);

    this.setType();

    item.AccountingDocuments.forEach((accounting: Message_AccountingDocument) => {
      this.readFromAccounting(accounting);
    });
  }

  private readFromAccounting(accountingDocument: Message_AccountingDocument){
    const Type: AccountingDocumentType = <AccountingDocumentType>accountingDocument.IncomeItemID;
    switch (+Type) {
      case AccountingDocumentType.Profit:
        this._profit = Number(accountingDocument.Amount || 0);
        break;
      case AccountingDocumentType.TransactionFee:
        this._transactionFee = Number(accountingDocument.Amount || 0);
        break;
      case AccountingDocumentType.Storage:
        this._rollover += Number(accountingDocument.Amount || 0);
        break;
      case AccountingDocumentType.SubaccountFee:
        this._subaccountFee += Number(accountingDocument.Amount || 0);
        break;
      case AccountingDocumentType.Commission:
        break;
      default:
        break;
    }
  }

  private setType(){
    this._stateType = this.CommandTypeId;

    if(this._stateType === StateType.Closed && this.CommandVolume < 0 && this.Volume > 0){
      this._stateType = StateType.PartialClosed;
    }
  }
}
