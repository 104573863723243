import {Injectable} from '@angular/core';
import {ITradePromise, TradePromise} from '@common/trade/models/trade-promise';

@Injectable({
  providedIn: 'root'
})
export class TradeDispatcherService {

  private commands: Map<number, TradePromise> = new Map<number, TradePromise>();

  constructor() { }

  public save(tradePromise: TradePromise){
    console.log('deal id', tradePromise.id);
    this.commands.set(tradePromise.id, tradePromise);
  }



  public release(id: number): TradePromise {
    // если закомментировать id, то нет уведомлений об ордере и звука
    id = Number(id);

    const command = this.commands.get(id);

    this.commands.delete(id);

    return command;
  }
}
