import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class CFD_USDCC extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.CFD_USDCC;
	}
        
  public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return unsignedAmount * this.Symbol.getMarginRate() * openPrice / this.Symbol.getCCConvertSymbol().getAsk();
}

  public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return unsignedAmount * this.Symbol.getMarginRate() * openPrice / this.Symbol.getCCConvertSymbol().getBid();
}

  public CalculateMaxLotCountInternalLong(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin / (unsignedVolume * this.Symbol.getMarginRate() * openPrice / this.Symbol.getCCConvertSymbol().getAsk());
}

  public CalculateMaxLotCountInternalShort(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin / (unsignedVolume * this.Symbol.getMarginRate() * openPrice / this.Symbol.getCCConvertSymbol().getBid());
}

  }