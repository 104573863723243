import {Injectable} from '@angular/core';
import {MarginStatus} from '@common/trader/models/margin-status';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {Answer_GetMarginStatus, Notification_MarginStatus} from '@common/communication/connection/classes.g';
import {MarginCommandSender} from '@common/communication/command-sender/margin-command-sender';

@Injectable({
  providedIn: 'root'
})
export class MarginStatusService {
  private marginStatus : MarginStatus;
  private liquidationTime : Date;

  private statusEmitter: SmartEmitter<void> = new SmartEmitter<void>();

  public get MarginStatus(): MarginStatus {
    return this.marginStatus;
  }

  public get LiquidationTime(): Date {
    return this.liquidationTime;
  }

  constructor(private marginCommandSender: MarginCommandSender) { }

  public requestMarginStatus(): void {
    this.marginCommandSender.load();
  }

  public setNewStatus(notification: Notification_MarginStatus | Answer_GetMarginStatus){
    this.marginStatus = Number(notification.MarginStatus);
    this.liquidationTime = new Date(notification.LiquidationTime);
    this.emitStatusChange();
  }

  private emitStatusChange(): void {
    this.statusEmitter.emit();
  }

  public get StatusChanged(): ISmartObserver<void> {
    return this.statusEmitter;
  }

  public clean(): void {
    this.marginStatus = 0;
  }

  public async load(): Promise<void> {
    const answer = await this.marginCommandSender.load().toTypedNativePromise<Answer_GetMarginStatus>();
    this.setNewStatus(answer);
  }
}
