export class CommandIdGenerator
{
  private _commandID:number;

  public constructor()
  {
    this._commandID = 1;
  }

  public getNextCommandID():number
  {
    return this._commandID++;
  }
}
