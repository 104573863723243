import { Theme } from '../symbols';

export const tvDefaultTheme: Theme = {
  name: 'tvDefaultTheme',
  category: 'dark',
  properties: {
    '--input-background-color': '#09102D',
    '--input-background-color-0': '#09102D',
    '--footer-background-color': 'rgba(19,16,45,0.7)',
    '--main-page-light-color': '#30365D',
    '--main-page-dark-color': '#09102D',
    '--blue-0': '#09102D',
    '--background-light': '#09102D',
    '--background-light-2': '#1b2243',
    '--background': '#09102D',
    '--blue-1': '#1b2243',
    '--blue-2': '#2b335a',
    '--blue-3': '#33395b',
    '--blue-4': '#4b5374',
    '--blue-5': '#252D53',
    '--blue-6': '#6a748e',
    '--blue-9': '#2f355c',
    '--blue-10': '#7a849d',
    '--blue-13': 'rgba(6, 11, 34, 0.7)',
    '--blue-15': '#1a2140',
    '--blue-20': '#474d73',
    '--blue-22': '#232d58',
    '--blue-23': '#474d73',
    '--blue-25': '#6a748e',
    '--blue-26': '#33395b',
    '--blue-24': '#242f65',
    '--blue-7': '#77829c',
    '--blue-8': '#848fad',
    '--blue-11': '#111122',
    '--blue-12': '#00badb',
    '--blue-14': '#606a85',
    '--blue-16': '#2e75bd',
    '--blue-17': '#273161',
    '--blue-18': '#313a67',
    '--blue-19': '#374174',
    '--blue-21': 'rgb(59, 153, 252)',

    '--blue-btn': '#33BA86',
    '--blue-btn-hover': '#30b17f',

    '--red': '#E51849',
    '--red-e': '#E24704',
    '--red-fa': '#fa5d00',
    '--red-f': '#9E0000',
    '--red-0': '#ca6868',
    '--red-1': '#671a1a',
    '--red-2': '#960000',
    '--red-3': 'rgba(142, 6, 3, 0.4)',
    '--red-4': '#cf282c',
    '--red-5': '#ff4300',
    '--red-6': 'rgb(232, 0, 8)',
    '--red-7': '#b30404',
    '--red-8': '#f74f43',
    '--red-9': '#d74304',
    '--red-10': '#EE5615',
    '--red-11': '#ed2c03',
    '--red-text': '#E51849',

    '--green-1': '#1bc68b',
    '--green-3': '#33BA86',
    '--green-5': '#33ba8661',
    '--green-0': '#008000',
    '--green-4': '#0bffa1a8',
    '--green-6': '#30b17f',
    '--green-7': '#31b482',
    '--green-8': '#1d6a4b',
    '--green-text': '#13AD7F',

    '--yellow-f': '#FFFF00',
    '--yellow-0': '#CCA866',

    '--white': '#ffffff',
    '--white-0': '#fcfcfd',
    '--white-1': '#eeeeee',
    '--white-2': '#e6e6e6',
    '--white-3': '#efefef',

    '--black': '#000000',

    '--grey': '#aaaaaa',
    '--grey-0': '#dddddd',
    '--grey-1': '#A9A9A9',
    '--grey-2': '#bfbfbf',
    '--grey-3': '#888888',

    '--bg-color-table': '#09102D',
    '--bg-color-item': '#1b2243',
    '--bg-light-fon': '#09102DAA',
    '--bg-light-fon-2': 'rgba(7,7,7,.6)',

    '--color-icon-theme': '#fcfcfd',

    '--check-box-color': '#33BA86',
    '--color-active-text-btn': '#ffffff',
    '--bg-spread': '#1b2243',

    '--bg-dark-fon': 'rgba(9,16,45,.3)',

    '--bg-item': '#1b2243',

    '--bg-context-menu-chart': '#1e222d',
    '--shadow-context-menu-chart': '#0006',
    '--color-context-menu-chart': '#d1d4dc',
    '--hover-context-menu-chart': '#2a2e39',
  }
};

