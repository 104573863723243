import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {Answer_GetMarginStatus, Notification_MarginStatus} from '../../connection/classes.g';
import {MarginStatusService} from '@common/trader/services/margin-status.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarginStatusMessageProcessor extends AbstractMessageProcessor {
  public constructor(serverConnection: ServerInteractionService, private marginStatus: MarginStatusService){
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToMarginStatusNotification();
    this.logger.debug(`Subscribed for ${Answer_GetMarginStatus.GetMessageType()}`);
  }

  private subscribeToMarginStatusNotification(): void {
    const subscription = this.serverConnection.on(Notification_MarginStatus.GetMessageType(),
      (answer) => this.onMarginStatusNotification(answer),
      error => this.logger.error('Margin status handling error:', error));

    this.saveSubscription(Notification_MarginStatus.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Notification_MarginStatus.GetMessageType()}`);
  }

  private onMarginStatusHandleAnswer(tick: Answer_GetMarginStatus): void {
    this.logger.debug('margin status got');
    this.marginStatus.setNewStatus(tick);
  }

  private onMarginStatusNotification(answer: Notification_MarginStatus){
    this.logger.debug('Got margin status');
    this.marginStatus.setNewStatus(answer);
  }
}
