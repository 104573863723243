import {Injectable} from '@angular/core';
import {WatchList} from '@common/watchlist/model/watch-list';
import {Symbol} from '@common/symbol/models/symbol';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';


export interface SymbolContent {
  watchlistContentID: number;
  symbol: Symbol;
}

export interface WatchListMap {
  id: number;
  name: string;
  symbolsContent: SymbolContent[];
}

@Injectable({
  providedIn: 'root'
})

export class WatchListStorageService {
  private myWatchLists: WatchList[];
  private suggestions: WatchList[];

  private watchListsVersion: number;

  private watchlistMap: WatchListMap[];
  private suggestionsSymbolList: Symbol[];

  private watchlistChangeDataEmitter: SmartEmitter<WatchListMap[]> = new SmartEmitter<WatchListMap[]>();

  constructor(private symbolStorageService: SymbolStorageService) {
  }

  public subscribeToDataChanges(callback: (watchListMaps: WatchListMap[]) => void): ISmartSubscription {
    const result = this.watchlistChangeDataEmitter.subscribe(callback);
    callback(this.watchlistMap);

    return result;
  }

  public get CurrentWatchListVersion(): number {
    return this.watchListsVersion;
  }
  public set CurrentWatchListVersion(number: number) {
    this.watchListsVersion = number;
  }

  public set MyWatchLists(watchlists: WatchList[]) {
    this.myWatchLists = watchlists;
  }

  public set Suggestions(suggestions: WatchList[]) {
    this.suggestions = suggestions;
  }

  public get WatchlistMap(): WatchListMap[] {
    return this.watchlistMap;
  }

  public get SuggestionsSymbolList(): Symbol[] {
    return this.suggestionsSymbolList;
  }

  public findWatchlistById(id: number): WatchListMap {
    const res = this.watchlistMap.find(watchlist => watchlist.id === id);
    if (res) {
      return res;
    } else {
      return this.watchlistMap.find(watchlist => Number(watchlist.id) === id);
    }
  }

  public findWatchlistByName(name: string): WatchListMap {
    return this.watchlistMap.find(watchlist => watchlist.name === name);
  }

  public getFirstWatchlist(): WatchListMap {
    if (this.watchlistMap !== undefined) {
      if (this.watchlistMap.length > 0) {
        return this.watchlistMap[0];
      } else {
        return null;
      }
    } else {
      return null;
    }

  }

  public loadWatchlistMapFromDataBase() {
    this.watchlistMap = [];

    if (this.myWatchLists) {
      this.myWatchLists.forEach((myWatchlist) => {
        const watchlist = this.watchlistMap.find((list) => list.id === myWatchlist.watchListID);

        if (watchlist && +myWatchlist.symbolID.toString() !== 0) {
          watchlist.symbolsContent.push({
            watchlistContentID: +myWatchlist.watchListContentID.toString(),
            symbol: this.symbolStorageService.findSymbolById(+myWatchlist.symbolID.toString())
          });

        } else {
          const newWatchListMap: WatchListMap = {
            id: myWatchlist.watchListID,
            name: myWatchlist.watchListName,
            symbolsContent: []
          };

          if (myWatchlist.symbolID && +myWatchlist.symbolID.toString() !== 0) {
            newWatchListMap.symbolsContent.push({
              watchlistContentID: +myWatchlist.watchListContentID.toString(),
              symbol: this.symbolStorageService.findSymbolById(+myWatchlist.symbolID.toString())
            });
          }

          this.watchlistMap.push(newWatchListMap);
        }
      });
    }
  }

  public loadWatchlistMapFromLocalStorage() {
    this.watchlistMap = [];

    if (this.myWatchLists) {

      for (const myWatchList of this.myWatchLists) {
        const watchlist = this.watchlistMap.find((list) => list.id === myWatchList['_watchListID']);

        if (watchlist && myWatchList['_symbolID'] !== 0) {
          watchlist.symbolsContent.push({
            watchlistContentID: myWatchList['_watchListContentID'],
            symbol: this.symbolStorageService.findSymbolById(myWatchList['_symbolID'])
          });
        } else {
          const newWatchListMap: WatchListMap = {
            id: myWatchList['_watchListID'],
            name: myWatchList['_watchListName'],
            symbolsContent: []
          };

          if (myWatchList['_symbolID'] !== 0) {
            newWatchListMap.symbolsContent.push({
              watchlistContentID: myWatchList['_watchListContentID'],
              symbol: this.symbolStorageService.findSymbolById(myWatchList['_symbolID'])
            });
          }

          this.watchlistMap.push(newWatchListMap);
        }
      }
    }
  }

  public loadSuggestionsSymbolsList(): void {
    this.suggestionsSymbolList = [];

    this.suggestions.forEach((watchlist) => {
      this.suggestionsSymbolList.push(this.symbolStorageService.findSymbolById(+watchlist.symbolID.toString()));
    });
  }

  public getWatchListsDataToSave(traderID: number): WatchList[] {
    const watchListsToSave: WatchList[] = [];

    this.WatchlistMap.forEach((watchList) => {
      if (watchList.symbolsContent.length > 0) {
        watchList.symbolsContent.forEach((symbolContent) => {
          const dataToSave: WatchList = new WatchList();
          dataToSave.traderID = traderID;
          dataToSave.watchListID = watchList.id;
          dataToSave.watchListName = watchList.name;
          dataToSave.watchListContentID = symbolContent.watchlistContentID;
          dataToSave.symbolID = symbolContent.symbol.SymbolId;
          watchListsToSave.push(dataToSave);
        });
      } else {
        const dataToSave: WatchList = new WatchList();
        dataToSave.traderID = traderID;
        dataToSave.watchListID = watchList.id;
        dataToSave.watchListName = watchList.name;
        dataToSave.watchListContentID = 0;
        dataToSave.symbolID = 0;
        watchListsToSave.push(dataToSave);
      }
    });

    return watchListsToSave;
  }

  public addNewWatchList(id: number, name: string): void {
    const newWatchlist: WatchListMap = {
      id: id,
      name: name,
      symbolsContent: []
    };

    this.watchlistMap.push(newWatchlist);
    this.watchlistChangeDataEmitter.emit(this.WatchlistMap);
  }

  public updateWatchList(watchListId: number, watchListName: string) {
    this.findWatchlistById(watchListId).name = watchListName;
    this.watchlistChangeDataEmitter.emit(this.WatchlistMap);
  }

  public removeWatchlist(id: number): number {
    const removableWatchlist = this.findWatchlistById(id);
    const indexRemovableWatchlist = this.watchlistMap.indexOf(removableWatchlist, 0);
    this.watchlistMap = this.watchlistMap.filter(watchlist => watchlist.id !== removableWatchlist.id);
    this.watchlistChangeDataEmitter.emit(this.WatchlistMap);

    if (this.watchlistMap.length === 0) {
      return null;
    } else if (this.watchlistMap.length === 1 || indexRemovableWatchlist === 0)  {
      return this.watchlistMap[0].id;
    } else {
      return this.watchlistMap[indexRemovableWatchlist - 1].id;
    }
  }

  public addSymbolToWatchList(watchlistID: number, contentID: number, symbolID: number): void {
    const watchlistToAdd = this.findWatchlistById(watchlistID);
    const symbolToAdd = this.symbolStorageService.findSymbolById(symbolID);

    if (this.findWatchlistById(watchlistID).symbolsContent.find(symbolContent =>
      symbolContent.symbol.SymbolId === symbolID) === undefined) {

      watchlistToAdd.symbolsContent.push({
        watchlistContentID: contentID,
        symbol: symbolToAdd
      });
    }

    this.watchlistChangeDataEmitter.emit(this.WatchlistMap);
  }

  public removeSymbolFromWatchList(watchlistID: number, contentID: number): void {
    const symbolContentToRemove = this.findWatchlistById(watchlistID).symbolsContent.find(symbolContent =>
      symbolContent.watchlistContentID === contentID);

    const watchlistWithSymbolToRemove = this.findWatchlistById(watchlistID);

    watchlistWithSymbolToRemove.symbolsContent = watchlistWithSymbolToRemove.symbolsContent.filter(symbolContent =>
      symbolContent !== symbolContentToRemove);

    this.watchlistChangeDataEmitter.emit(this.WatchlistMap);
  }

  public checkWatchListNameRepeat(name: string): boolean {
    if (this.WatchlistMap === undefined) {
      return false;
    } else {
      return this.WatchlistMap.find(watchlist => watchlist.name === name) !== undefined;
    }
  }

  public symbolIsContainedInWatchList(watchListId: number, symbolId: number): boolean {
    const symbolsContent = this.findWatchlistById(watchListId).symbolsContent;
    return symbolsContent.find(symbolContent => symbolContent.symbol.SymbolId === symbolId) !== undefined;
  }

  public getMaxWatchListIDInMap(): number {
    let maxID = 0;
    this.watchlistMap.forEach((watchList) => {
      if (watchList.id > maxID) {
        maxID = watchList.id;
      }
    });

    return maxID;
  }

  public getMaxContentID(): number {
    let maxID = 0;
    this.watchlistMap.forEach((watchList) => {
      watchList.symbolsContent.forEach((content) => {
        if (content.watchlistContentID > maxID) {
          maxID = content.watchlistContentID;
        }
      });
    });

    return maxID;
  }

  public removeWatchListsFromFrontEnd(): void {
    this.watchlistMap = [];
  }
}
