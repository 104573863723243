<div class="mode-debug-users-container ">
  <div class="mode-debug-users-list-box custom-scroll">
    <ul class="mode-debug-users-list">
      <li *ngFor="let item of ModeDebugUsers" class="mode-debug-users-items">
        <span><span class="mode-debug-users-items-title">Command</span> {{item.command | json}}</span>
        <span><span class="mode-debug-users-items-title">Answer</span> {{item.answer | json}}</span>
        <span><span class="mode-debug-users-items-title">Timestamp</span> {{item.timestamp | json}}</span>
      </li>
    </ul>
  </div>
  <button class="button-success button-mode-debug" [disabled]="CheckArrayErrors" (click)="copyAll($event)"> Copy All </button>
  <span class="mode-debug-users-notification" [hidden]="HiddenNotification" >The entire text has been copied.</span>
</div>


