import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {ChangeableValidator} from '@common/shared/directives/validators/changeable-validator';

@Directive({
  selector: '[stop-loss-pips]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: StopLossPipsValidatorDirective,
    multi: true
  }]
})
export class StopLossPipsValidatorDirective extends ChangeableValidator implements Validator {

  @Input() ignored = false;

  @Input() currentSpread;

  validate(control: AbstractControl): ValidationErrors | null {

    const value = Number(control.value);

    const currentSpread = Number(this.currentSpread);

    if (this.ignored) {
      return null;
    }

    return value > currentSpread ?
      null :
      {
        stopLossPipsValue: true
      };
  }
}
