import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input()
  public title: string;
  @Input()
  public set checked(v: boolean){
    this._checked = v;
  }
  public get checked(): boolean{
    return  this._checked;
  }

  private _checked: boolean;

  constructor() { }

  ngOnInit() {
  }

}
