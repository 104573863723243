import {Trade} from '../../models/trade';
import {ITradeInternalState} from './i-trade-internal-state';
import {TradeCalculateObject} from '../calculations/trade-calculate-object';
import {TradePriceProvider} from '../price-provider/trade-price-provider';

export abstract class AbstractTradeInternalState implements ITradeInternalState {
  calculateObject: TradeCalculateObject;

  private _dealID: number;

  protected constructor(calc: TradeCalculateObject, dealID: number) {
    this.calculateObject = calc;
    this._dealID = dealID;
  }


  public abstract get IsOpen(): boolean;

  public abstract getTotal(trade: Trade): number;

  public abstract getClosePrice(trade: Trade): number;

  public abstract getVolume(trade: Trade): number;

  public abstract getCommandVolumeUSD(trade: Trade): number;

  public abstract getCommandVolumeAC(trade: Trade): number;

  public abstract getVolumeUSD(trade: Trade): number;

  public abstract getVolumeAC(trade: Trade): number;

  public getDealID(): number {
    return this._dealID;
  }

  protected set DealID(dealID: number) {
    this._dealID = dealID;
  }

  public getProfit(trade: Trade): number {
    return trade.CalculateObject.Profit;
  }

  public getUPL(trade: Trade): number {
    return trade.CalculateObject.UPL;
  }

  public get IsPending(): boolean {
    return false;
  }
}

export class OpenTradeState extends AbstractTradeInternalState {
  public get IsOpen(): boolean {
    return true;
  }

  public getClosePrice(trade: Trade): number {
    return TradePriceProvider.getTradeClosingPrice(trade.Symbol, trade.Type);
  }

  public getTotal(trade: Trade): number {
    const total = trade.UPL + trade.Profit + trade.TransactionFee + trade.Storage;

    if (!Number.isFinite(total) || Number.isNaN(total)) {
      throw new Error(`Total is nan | Upl: ${trade.UPL}, Profit: ${trade.Profit}, TransactionFee: ${trade.TransactionFee}, Storage: ${trade.Storage}`);
    }

    return total;
  }

  public getVolume(trade: Trade): number {
    return trade.CalculateObject.Volume;
  }

  public getCommandVolumeUSD(trade: Trade): number {
    return trade.CalculateObject.CommandVolumeUSD;
  }

  public getCommandVolumeAC(trade: Trade): number {
    return trade.CalculateObject.CommandVolumeAC;
  }

  public getVolumeUSD(trade: Trade): number {
    return trade.CalculateObject.VolumeUSD;
  }

  public getVolumeAC(trade: Trade): number {
    return trade.CalculateObject.VolumeAC;
  }

  public constructor(calc: TradeCalculateObject, dealID: number) {
    super(calc, dealID);
  }
}

export class CloseTradeState extends AbstractTradeInternalState {
  public get IsOpen(): boolean {
    return false;
  }

  public getClosePrice(trade: Trade): number {
    return trade.CalculateObject.ClosePrice;
  }

  public getTotal(trade: Trade): number {
    return this.getPnL(trade) + trade.TransactionFee + trade.Storage;
  }

  public getVolume(trade: Trade): number {
    return trade.States[0].Volume;
  }

  public getCommandVolumeUSD(trade: Trade): number {
    return trade.States[0].CommandVolumeUSD;
  }

  public getCommandVolumeAC(trade: Trade): number {
    return trade.States[0].CommandVolumeAC;
  }

  public getVolumeUSD(trade: Trade): number {
    return trade.States[0].VolumeUSD;
  }

  public getVolumeAC(trade: Trade): number {
    return trade.States[0].VolumeAC;
  }

  public getPnL(trade: Trade): number {
    return trade.CalculateObject.Profit;
  }

  public getUPL(trade: Trade): number {
    return 0;
  }

  public constructor(calc: TradeCalculateObject, dealID: number) {
    super(calc, dealID);
  }
}

export class DeletedPendingTradeState extends AbstractTradeInternalState {
  public get IsOpen(): boolean {
    return false;
  }

  public getClosePrice(trade: Trade): number {
    return 0;
  }

  public getTotal(trade: Trade): number {
    return 0;
  }

  public getVolume(trade: Trade): number {
    return 0;
  }

  public getCommandVolumeUSD(trade: Trade): number {
    return 0;
  }

  public getCommandVolumeAC(trade: Trade): number {
    return 0;
  }

  public getVolumeUSD(trade: Trade): number {
    return 0;
  }

  public getVolumeAC(trade: Trade): number {
    return 0;
  }

  public getProfit(trade: Trade): number {
    return 0;
  }

  public getUPL(trade: Trade): number {
    return 0;
  }

  public constructor(calc: TradeCalculateObject, dealID: number) {
    super(calc, dealID);
  }
}

export class PendingTradeState extends AbstractTradeInternalState {
  public get IsOpen(): boolean {
    return true;
  }

  public get IsPending(): boolean {
    return true;
  }

  public getClosePrice(trade: Trade): number {
    return TradePriceProvider.getTradeCurrentPrice(trade.Symbol, trade.Type);
  }

  public getTotal(trade: Trade): number {
    return 0;
  }

  public getVolume(trade: Trade): number {
    return trade.CalculateObject.Volume;
  }

  public getCommandVolumeUSD(trade: Trade): number {
    return trade.CalculateObject.CommandVolumeUSD;
  }

  public getCommandVolumeAC(trade: Trade): number {
    return trade.CalculateObject.CommandVolumeAC;
  }

  public getVolumeUSD(trade: Trade): number {
    return trade.CalculateObject.VolumeUSD;
  }

  public getVolumeAC(trade: Trade): number {
    return trade.CalculateObject.VolumeAC;
  }

  public getProfit(trade: Trade): number {
    return 0;
  }

  public getUPL(trade: Trade): number {
    return 0;
  }

  public constructor(calc: TradeCalculateObject, dealID: number) {
    super(calc, dealID);
  }
}

