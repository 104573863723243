import {ProtocolBase} from './protocol-base';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {IProtocol} from './protocol-interface';
import {IProtocolEventHandler} from './protocol-event-handler-interface';
import {environment} from '../../../../environments/environment';
import {BrowserPageService} from '@common/trade/services/browser-page.service';
import { DisconnectionCodes } from '../disconnection-code';

export class ProtocolMediatorBase extends ProtocolBase implements IProtocolEventHandler {
  protected _subProtocol: IProtocol;

  constructor(subProtocol: IProtocol) {
    super();
    this._subProtocol = subProtocol;
    this._subProtocol.setEventHandler(this);
  }

  public send(message: ProtoV2MessageBase) {
    this._subProtocol.send(message);
  }

  public connect() {
    this._subProtocol.connect();
  }

  public disconnect(msg?: string) {
    this._subProtocol.disconnect(msg);
  }

  public onMessageReceived(message: ProtoV2MessageBase) {
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onMessageReceived(message);
    }
  }

  public onDisconnected(msg: string) {
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onDisconnected(msg);
    }
  }

  public onConnectionEstablished() {
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onConnectionEstablished();
    }
  }

  public onConnectionEstablishedError(msg: DisconnectionCodes) {
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onConnectionEstablishedError(msg);
    }
  }

  // данные о браузере пользователя и с какого приложения зашел (мобильное или десктоп);
  public createClientData () {
    const guidPage = BrowserPageService.getGuidPageData();
    return {
      origin: window.location.origin,
      vendor: window.navigator.vendor,
      appCodeName: window.navigator.appCodeName,
      appVersion: window.navigator.appVersion,
      browserName: BrowserPageService.getBrowserName(window.navigator.userAgent),
      isMobileVersion: environment.isMobileVersion,
      environmentPlatform: environment.platform,
      chart: environment.chart,
      platform: window.navigator.platform,
      guidPage: 'GuidPage ' + `${guidPage}`
    };
  }
}

