export enum TradeType {
  Sell = 0,
  Buy = 1,
  SellLimit = 2,
  SellStop = 3,
  BuyLimit = 4,
  BuyStop = 5
}

export const TradeTypeStrings = {
  0 : 'TradeModule_TradeTypeStrings_Sell',
  1 : 'TradeModule_TradeTypeStrings_Buy',
  2 : 'TradeModule_TradeTypeStrings_SellLimit',
  3 : 'TradeModule_TradeTypeStrings_SellStop',
  4 : 'TradeModule_TradeTypeStrings_BuyLimit',
  5 : 'TradeModule_TradeTypeStrings_BuyStop'
};

export const TradeTypeStringsEng = {
  0 : 'SELL',
  1 : 'BUY',
  2 : 'SELL LIMIT',
  3 : 'SELL STOP',
  4 : 'BUY LIMIT',
  5 : 'BUY STOP'
};

export function isBuyTrade(type: TradeType): boolean {
  return type == TradeType.Buy || type == TradeType.BuyStop || type == TradeType.BuyLimit;
}

export function isSellTrade(type: TradeType): boolean {
  return type == TradeType.Sell || type == TradeType.SellStop || type == TradeType.SellLimit;
}

export function isPendingTrade(type: TradeType) {
  return type > 1;
}

export function getTradeSign(type: TradeType) {
  return isBuyTrade(type) ? 1 : -1;
}


