import {EventEmitter, Injectable} from '@angular/core';
import {Accounting} from '@common/trader/models/accounting.service';
import {ChallengeEODSnapshots, ChallengeSettings, ReportService} from '@common/shared/services/Report/report.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  private daysGain: number;
  private daysGainPercent: number;
  private totalGain: number;
  private totalGainPercent: number;
  public traderChallengeSettings: ChallengeSettings;
  public traderChallengeEODSnapshot: ChallengeEODSnapshots;
  private emitterAnswerTraderChallenge = new EventEmitter;

  public get EmitterAnswerTraderChallenge() {
    return this.emitterAnswerTraderChallenge;
  }

  get NetAccountValue(): number {
    return this.accounting.Equity;
  }

  get DaysGain(): number {
    return this.daysGain;
  }

  get DaysGainPercent(): number {
    return this.daysGainPercent;
  }

  get TotalGain(): number {
    return this.totalGain;
  }

  get TotalGainPercent(): number {
    return this.totalGainPercent;
  }

  get Cash(): number {
    return Math.round(this.accounting.FreeMargin * 100) / 100;
  }

  constructor(private accounting: Accounting,
              private reportService: ReportService) {

  }

  public traderGainSummaryInit() {
    this.reportService.loadTraderGainSummary().then((gainSummary) => {
      console.log('gainSummary', gainSummary);
      this.daysGain = gainSummary.todayGain;
      this.totalGain = gainSummary.totalGain;

      if (gainSummary.totalWireOut !== 0) {
        this.daysGainPercent = Math.round(((gainSummary.todayGain / gainSummary.totalWireOut) * 100) * 100) / 100;
        this.totalGainPercent = Math.round(((gainSummary.totalGain / gainSummary.totalWireOut) * 100) * 100) / 100;
      } else {
        this.daysGainPercent = 0;
        this.totalGainPercent = 0;
      }
    });
  }

  public destroy() {
    this.daysGain = undefined;
    this.totalGain = undefined;
    this.daysGainPercent = undefined;
    this.totalGainPercent = undefined;
  }

  public loadTraderChallengeSettings() {
    this.reportService.loadTraderChallengeSettings().then((traderChallengeSettings) => {
      if (traderChallengeSettings !== undefined) {
        this.traderChallengeSettings = traderChallengeSettings;
        this.emitterAnswerTraderChallenge.emit('Settings');
      }
    });
  }

  public loadTraderChallengeEODSnapshot() {
    this.reportService.loadTraderChallengeEODSnapshot().then((answer) => {
      if (answer !== undefined) {
        this.traderChallengeEODSnapshot = answer;
        this.emitterAnswerTraderChallenge.emit('EODSnapshot');
      }
    });
  }

  public destroyTraderChallengeEODSnapshot() {
    this.traderChallengeSettings = undefined;
    this.traderChallengeEODSnapshot = undefined;
  }
}
