import {Logger} from './logger';
import {LogLevel} from './log-level.enum';
import {Environment} from '@common/environment';
import {TraderMessageProcessor} from '@common/communication/message-processor/processors/trader-message-processor';


export class LoggerFactory {
  static configuration = {
    'default': LogLevel.Disabled,
  };

  public static getLogger (name:  object | string): Logger {
    if (typeof name === 'string'){
      return LoggerFactory.getLoggerByString(name);
    }
    else if (typeof name === 'object') {
      return LoggerFactory.getLoggerByString(name.constructor.name);
    }
  }

  private static getLoggerByString(name: string) {
    if (name in LoggerFactory.configuration){
      return new Logger(name, LoggerFactory.configuration[name]);
    }
    else {
      return new Logger(name, LoggerFactory.configuration['default']);
    }
  }

  public static Init() {
    if(Environment.LoggerConfig){
      this.SetLogLevelsFromEnv();
    }
    this.SetMessageDeliveryLogging();
  }

  private static SetMessageDeliveryLogging(){
    const deliveryLoggingEnabled = localStorage.getItem('MessageDeliveryLogging');
    if(deliveryLoggingEnabled && deliveryLoggingEnabled.toLowerCase() == "true") {
      LoggerFactory.configuration['MessageDeliveryLogger'] = LogLevel.Debug;
    }
  }
  private static SetLogLevelsFromEnv() {
    LoggerFactory.configuration = Object.assign(LoggerFactory.configuration, Environment.LoggerConfig);
  }
}

LoggerFactory.Init();
