<div class="table-header">

  <div></div>

  <div class="table-header-items">
    <l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_order-cell'"></l-unit>
    <div (click)="sortingItemsPage('Order')" class="sort-icon-box">
      <span class="sort-up-icon" [class.active-icon]="getActiveSortIcon('Order', 'up')" ></span>
      <span class="sort-down-icon" [class.active-icon]="getActiveSortIcon('Order', 'down')"></span>
    </div>
  </div>

  <div class="table-header-items">
    <l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_open-price-cell'"></l-unit>
    <div (click)="sortingItemsPage('OpenPrice')" class="sort-icon-box">
      <span class="sort-up-icon" [class.active-icon]="getActiveSortIcon('OpenPrice', 'up')" ></span>
      <span class="sort-down-icon" [class.active-icon]="getActiveSortIcon('OpenPrice', 'down')"></span>
    </div>
  </div>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_volume-cell'"></l-unit></div>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_margin-cell'"></l-unit> (<app-account-currency-title></app-account-currency-title>)</div>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_sl-cell'"></l-unit></div>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_tp-cell'"></l-unit></div>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_ts-cell'"></l-unit></div>

  <div class="table-header-items" *ngIf="IsSectionHistory; else notSectionHistory">
    <l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_price-cell'"></l-unit>
    <div (click)="sortingItemsPage('ClosePrice')" class="sort-icon-box">
      <span class="sort-up-icon" [class.active-icon]="getActiveSortIcon('ClosePrice', 'up')" ></span>
      <span class="sort-down-icon" [class.active-icon]="getActiveSortIcon('ClosePrice', 'down')"></span>
    </div>
  </div>

  <ng-template #notSectionHistory>
    <div class="table-header-items">
      <l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_current-price-cell'"></l-unit>
      <div (click)="sortingItemsPage('CurrentPrice')" class="sort-icon-box">
        <span class="sort-up-icon" [class.active-icon]="getActiveSortIcon('CurrentPrice', 'up')" ></span>
        <span class="sort-down-icon" [class.active-icon]="getActiveSortIcon('CurrentPrice', 'down')"></span>
      </div>
    </div>
  </ng-template>

  <div><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_pnl-cell'"></l-unit> (<app-account-currency-title></app-account-currency-title>)</div>

  <div class="table-header-items">
    <l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_total-cell'"></l-unit> (<app-account-currency-title></app-account-currency-title>)
    <div (click)="sortingItemsPage('Total')" class="sort-icon-box">
      <span class="sort-up-icon" [class.active-icon]="getActiveSortIcon('Total', 'up')" ></span>
      <span class="sort-down-icon" [class.active-icon]="getActiveSortIcon('Total', 'down')"></span>
    </div>
  </div>

  <div></div>

</div>
