export class MinimumSpreadCorrector {
  private _minimumSpreadPoints = 0;
  private _bid = 0;
  private _ask = 0;

  public get Bid(): number {
    return this._bid;
  }

  public get Ask(): number {
    return this._ask;
  }

  public get MinimumSpreadPoints(): number {
    return this._minimumSpreadPoints;
  }

  public set MinimumSpreadPoints(value: number) {
    this._minimumSpreadPoints = value;
  }

  constructor() { }

  public async setOriginalQuote(bid: number, ask: number) {
    this._bid = bid;
    this._ask = ask;

    await this.checkMinimumSpread();
  }

  private async checkMinimumSpread(): Promise<void> {
    if  (this.MinimumSpreadPoints > 0 &&
        (this._ask - this._bid < this.MinimumSpreadPoints)) {
      this._bid = Math.floor((this._bid + this._ask) / 2.0) - Math.floor(this.MinimumSpreadPoints / 2.0);
      this._ask = this._bid + this.MinimumSpreadPoints;
    }
  }
}
