import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeType} from '../../models/trade-type';
import {Component, Directive, EventEmitter, Output} from '@angular/core';
import {TradeValidator} from '@common/trade/utils/trade.validator';

@Directive()
export abstract class AbstractOrderForm {
  @Output('submitForm')
  public submitEmitter: EventEmitter<void> = new EventEmitter();
  protected order: TradeDTO;
  protected amount: number | string;

  public get Order(): TradeDTO {
    return this.order;
  }

  public set Order(v: TradeDTO) {
    this.order = v;
  }

  public get Amount(): number | string {
    return this.order.Volume;
  }

  public set Amount(v: number | string) {
    this.amount = v.toString().trim();

    this.order.Volume = Number(v);
  }

  get Symbol(): Symbol {
    return this.order.Symbol;
  }

  set Symbol(value: Symbol) {
    this.order.Symbol = value;
  }

  get TradeType(): TradeType {
    return this.order.Type;
  }

  set TradeType(value: TradeType) {
    this.order.Type = value;
  }

  protected validateOrder() {
    new TradeValidator()
      .checkPrice(this.order)
      .checkBounds(this.order)
      .checkVolume(this.order);
  }
}
