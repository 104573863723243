import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Directive({
  selector: '[scroll]'
})
export class ScrollDirective {
  private _scrollup = new EventEmitter<void>();

  @Output()
  public get scrollup(): Observable<void> {
    return this._scrollup.asObservable();
  }

  private _scrolldown = new EventEmitter<void>();

  @Output()
  public get scrolldown(): Observable<void> {
    return this._scrolldown.asObservable();
  }

  constructor() { }

  @HostListener('wheel', ['$event'])
  public onWheel(event: WheelEvent){
    if(event.deltaY < 0) {
      this._scrollup.emit();
    }
    else {
      this._scrolldown.emit();
    }
  }
}
