import {EventEmitter, Injectable} from '@angular/core';
import {FIFONettedTrade, FIFONettedTradeOperation, OperationType} from '@common/trade/models/FIFONettedTrade';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {isPendingTrade, TradeType} from '@common/trade/models/trade-type';
import {TradeService} from '@common/trade/services/trade.service';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Trade} from '@common/trade/models/trade';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {ITradePromise, TradePromise} from '@common/trade/models/trade-promise';
import {Trader} from '@common/trader/models/trader';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {Accounting} from '@common/trader/models/accounting.service';
import { Quote } from '@common/symbol/models/quote';


@Injectable({
  providedIn: 'root'
})
export class FIFONettedTradesManagementService {
  public static tradeID = 100;
  private _openPositionMap: Map<number, FIFONettedTrade> = new Map<number, FIFONettedTrade>();

  // подписка на изменение размера мапа открытых позиция при открытии на другом гаджете

  private _changePositionMap: EventEmitter<void> = new EventEmitter<void>();

  private _onTicketAccepted: EventEmitter<number> = new EventEmitter<number>();
  private _onTicketDeclined: EventEmitter<number> = new EventEmitter<number>();

  public get ChangePositionMap(): EventEmitter<void> {
    return this._changePositionMap;
  }

  public get OnTicketAccepted(): EventEmitter<number> {
    return this._onTicketAccepted;
  }

  public get OnTicketDeclined(): EventEmitter<number> {
    return this._onTicketDeclined;
  }

  public get PositionMap() {
    return this._openPositionMap;
  }

  constructor(private tradeService: TradeService,
              private notificationProvider: NotificationProviderService,
              private tradeStorage: TradeStorage,
              private symbolStorage: SymbolStorageService,
              private trader: Trader,
              private symbolService: SymbolService,
              private accounting: Accounting) {
    this.tradeStorage.onTradeOpened(trade => {
      this.onTradeOpen(trade);
    });

    this.tradeStorage.onTradeChanged(trade => {
      this.onTradeChanged(trade);
      // this.accounting.loadBalance().then();
    });

  }

  private onTradeOpen(trade: Trade) {
    // получаем размер мапа открытых ордеров
    const mapSize = this._openPositionMap.size;
    const position = this.getPositionBySymbolID(trade.Symbol.SymbolId);

    // при первой загрузки условие не выполняется т.к. ордера подгружаются, а при последующим открытии ордеров размер мапа совподает и в сессион стородж записывается значение
    if (mapSize === this._openPositionMap.size && this._openPositionMap.size !== 0) {
      sessionStorage.setItem('positionsToShowRevers', String(true));
    } else {
      sessionStorage.setItem('positionsToShowRevers', String(false));
    }

    if (trade.Type === TradeType.Buy) {
      position.applyOpenBuy(trade.getDealID(), trade);
    } else if (trade.Type === TradeType.Sell) {
      position.applyOpenSell(trade.getDealID(), trade);
    }

    this._changePositionMap.emit();

    const currentSymbolId = trade.CalculateObject.Symbol.SymbolId;
    const lastQuoteCurrentSymbol = trade.CalculateObject.Symbol.LastQuote;
    const isValidQuoteConvertSymbol = trade.CalculateObject.Symbol.ProfitCalcSymbol.getCCConvertSymbol().getIsValidQuote();
    const convertSymbolId = trade.CalculateObject.Symbol.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel']._symbolId;

    this.checkSubscribePrice(currentSymbolId, lastQuoteCurrentSymbol, isValidQuoteConvertSymbol, convertSymbolId);
  }

  // проверка на подписку изменения цен, если её нет, то подписывается

  private checkSubscribePrice(currentSymbolId: number,
                              lastQuoteCurrentSymbol: Quote,
                              isValidQuoteConvertSymbol: boolean,
                              convertSymbolId: number) {

    if (lastQuoteCurrentSymbol == undefined) {
      this.symbolService.subscribeToSymbol(currentSymbolId).then();
    }
    // проверка на подписку для конвертирующей валюты
    if (!isValidQuoteConvertSymbol) {
      // подписка на конвертирующую валюту
      this.symbolService.subscribeToSymbol(convertSymbolId).then();
    }
  }

  /* for closing and partial closing trades*/
  private onTradeChanged(trade: Trade) {

    if (isPendingTrade(trade.Type)) {

    } else {
      const position = this.getPositionBySymbolID(trade.Symbol.SymbolId);

      if (trade.Type === TradeType.Buy) {
        const currentTradeVersion = position.getCurrentBuy(trade, trade.getDealID());
        /*we won't find prev order for just resumed pending order*/
        if (currentTradeVersion === undefined) {
          this.onTradeOpen(trade);
          return;
        }
        if (currentTradeVersion.trade.Volume === Math.abs(trade.CommandVolume)) {
          console.log('tr close tradeID: ' + trade.TradeId + ' volume: ' + trade.CommandVolume);
          position.closeBuy(trade.getDealID(), trade);
        } else {
          console.log('tr partial close tradeID: ' + trade.TradeId + ' volume: ' + trade.CommandVolume);
          position.replaceBuy(trade.getDealID(), trade);
        }
      } else if (trade.Type === TradeType.Sell) {
        const currentTradeVersion = position.getCurrentSell(trade, trade.getDealID());
        /*we won't find prev order for just resumed pending order*/
        if (currentTradeVersion === undefined) {
          this.onTradeOpen(trade);
          return;
        }
        if (currentTradeVersion.trade.Volume === Math.abs(trade.CommandVolume)) {
          console.log('tr close tradeID: ' + trade.TradeId + ' volume: ' + trade.CommandVolume);
          position.closeSell(trade.getDealID(), trade);
        } else {
          console.log('tr partial close tradeID: ' + trade.TradeId + ' volume: ' + trade.CommandVolume);
          position.replaceSell(trade.getDealID(), trade);
        }
      }
      this._changePositionMap.emit();
    }
  }

  private checkBoundsNAN(order: TradeDTO): boolean {
    if (isNaN(order.TakeProfit)) {
      throw new Error ('TP is NAN');
    }
    if (isNaN(order.StopLoss)) {
      throw new Error ('SL is NAN');
    }
    if (isNaN(order.TrailingStop)) {
      throw new Error ('TS is NAN');
    }

    return true;
  }

  public getPositionBySymbolID(symbolID: number): FIFONettedTrade {
    if (this._openPositionMap.has(symbolID)) {
      return this._openPositionMap.get(symbolID);
    } else {
      const position = new FIFONettedTrade();
      const symbol = this.symbolStorage.findSymbolById(symbolID);
      if (symbol !== undefined) {
        position.Symbol =  symbol;
      } else {
        // TODO raise error;
      }

      this._openPositionMap.set(symbolID, position);
      return position;
    }
  }

  public clear(): void {
    this._openPositionMap.clear();
    sessionStorage.removeItem('positionsToShowRevers');
  }

  private async doCloseTrade(order: TradeDTO): Promise<ITradePromise> {
    return await this.tradeService.closeTrade(order);
  }

  private async doCloseTradeWithPromise(order: TradeDTO, tradePromise: TradePromise) {
    return await this.tradeService.closeTradeWithTradePromise(order, tradePromise);
  }

  private async doOpenTrade(order: TradeDTO): Promise<ITradePromise> {
    return await this.tradeService.openOrderWithValidateError2(order);
  }

  private async doOpenTradeWithPromise(order: TradeDTO, tradePromise: TradePromise) {
    return await this.tradeService.openOrderWithValidateErrorAndTradePromise(order, tradePromise);
  }

  private async uncoverCloseTradeOperation(order: TradeDTO, position: FIFONettedTrade): Promise<OperationResult> {

    // Оператор результата создается до получения ответа и в зависимости от результата в нем меняются переменные
    // т.к. бывают моменты когда OperationResult не создается и в результате нет уведомления о результате об ордере

    const resultOperation = new OperationResult(false, false);

    return new Promise<OperationResult>((resolve, reject) => {
      try {
        const s = new OperationResult(false, false);

        const tradePromise = new TradePromise();

        tradePromise.success((msg, dealID) => {
          resultOperation.dealID = dealID;
          if (msg.MessageType === 'Answer_TicketAccept') {
            // resolve(new OperationResult(false, false));
            resolve(resultOperation);
          }
          if (msg.MessageType === 'Notification_TicketDecline') {
            // position.unlock();
            // localErrorMessage = msg.Message;
            position.unlock();
            // resolve(new OperationResult(true, true, msg.Message));
            resultOperation.isBreaking = true;
            resultOperation.isDeclined = true;
            resultOperation.errorMessage = msg.Message;
            resolve( resultOperation );
          }
        });
        tradePromise.error( err => {
          position.unlock();
          if (err.MessageType === 'Notification_TicketDecline') {
            // resolve(new OperationResult(true, true, err.Message));
            resultOperation.isBreaking = true;
            resultOperation.isDeclined = true;
            resultOperation.errorMessage = err.Message;
            resolve( resultOperation );
          } else {
            // resolve(new OperationResult(true, false, err));
            resultOperation.isBreaking = true;
            resultOperation.errorMessage = err.Message;
            resolve( resultOperation );
          }
        });

        this.doCloseTradeWithPromise(order, tradePromise).catch(err => {
          position.unlock();
          // resolve(new OperationResult(true, false, err));
          resultOperation.isBreaking = true;
          resultOperation.errorMessage = err;
          resolve( resultOperation );
        });
      } catch (ex) {
        // resolve(new OperationResult(true, false, ex.toString()));
        resultOperation.isBreaking = true;
        resultOperation.errorMessage = ex.toString();
        resolve( resultOperation );
      }

    });
  }


  private async uncoverOpenTradeOperation(order: TradeDTO, position: FIFONettedTrade): Promise<OperationResult> {

    // оператор результата создается до получения ответа и в зависимости от результата в нем меняются переменные
    // т.к. бывают моменты когда OperationResult не создается и в результате нет уведомления о результате об ордере

    const resultOperation = new OperationResult(false, false);
    return new Promise<OperationResult>((resolve, reject) => {
      try {

        const tradePromise = new TradePromise();

        tradePromise.success((msg, dealID) => {
          resultOperation.dealID = dealID;
          if (msg.MessageType === 'Answer_TicketAccept') {
            if (order.Type === TradeType.Buy) {
              position.registerBuy(Number(msg.DealID));
            } else if (order.Type === TradeType.Sell) {
              position.registerSell(Number(msg.DealID));
            }
            resolve(resultOperation);
            // resolve(new OperationResult(false, false));
          }
          if (msg.MessageType === 'Notification_TicketDecline') {
            // position.unlock();
            // localErrorMessage = msg.Message;

            position.unlock();
            // resolve(new OperationResult(true, true, msg.Message));
            resultOperation.isBreaking = true;
            resultOperation.isDeclined = true;
            resultOperation.errorMessage = msg.Message;
            resolve( resultOperation );
          }
        });

        tradePromise.error(err => {
          position.unlock();
          if (err.MessageType === 'Notification_TicketDecline') {
            // resolve(new OperationResult(true, true, err.Message));
            resultOperation.isBreaking = true;
            resultOperation.isDeclined = true;
            resultOperation.errorMessage = err.Message;
            resolve( resultOperation );
          } else {
            // resolve(new OperationResult(true, false, err));
            resultOperation.isBreaking = true;
            resultOperation.errorMessage = err;
            resolve( resultOperation );
          }
        });

        this.doOpenTradeWithPromise(order, tradePromise).catch(err => {
          position.unlock();
          // resolve(new OperationResult(true, false, err));
          resultOperation.isBreaking = true;
          resultOperation.errorMessage = err;
          resolve( resultOperation );
        });
      } catch (ex) {
        // resolve(new OperationResult(true, false, ex.toString()));
        resultOperation.isBreaking = true;
        resultOperation.errorMessage = ex.toString();
        resolve( resultOperation );
      }

    });
  }

  private async processOperation(step: FIFONettedTradeOperation, position: FIFONettedTrade): Promise<OperationResult> {

    if (step.operation === OperationType.CloseOrder) {
      return await this.uncoverCloseTradeOperation(step.order, position);
    } else if (step.operation === OperationType.OpenOrder) {
      return await this.uncoverOpenTradeOperation(step.order, position);
    }
  }


  public async doTrade(order: TradeDTO, errorCallback?: (msg: string) => void, successCallback?: (msg: string, dealID?: number) => void) {
    if (isPendingTrade(order.Type)) {
      try {
        const tr = new TradePromise();
        const res = await this.tradeService.openOrderWithValidateErrorAndTradePromise(order, tr);

        //const res = await this.tradeService.openOrderWithValidateError2(order);

        let success = true;

        res.error(err => {
          console.log('Err: ', err);
          success = false;

          if (errorCallback) {
            errorCallback(err.Message);
          }
        }).success((message, dealID) => {
          successCallback(message, dealID);
        });

/*        if (success && successCallback) {
            successCallback('Order opened');
        }*/

      } catch (ex) {
        // console.log('internal!', ex);
        if (errorCallback) {
          errorCallback(ex);
        }

        return;
      }

    } else {
      console.log('market order do trade');

      let volumeProceed = 0;
      const position = this.getPositionBySymbolID(order.Symbol.SymbolId);

      let operations:  Set<FIFONettedTradeOperation>;
      if (order.Type === TradeType.Buy) {
        operations = position.doBuy(order);
      } else if (order.Type === TradeType.Sell)  {
        // checking the nakes sells allowance
        if (!this.trader.isNakedSellAllowed) {
          // if current position is not buy
          if (position.PositionVolume < 0 && order.Symbol.SymbolType === 'Equities') {
            // const errorMessage = 'naked sells aren\'t allowed. Current position is not buy';
            const errorMessage = 'Current position is not buy naked sells aren\'t allowed.';
            console.log(errorMessage);
            errorCallback(errorMessage);
            return;
          }
          // if current position is buy but we trying to sell more we have
          if (position.PositionVolume > 0 && position.PositionVolume < order.Volume) {
            // const errorMessage = 'naked sells aren\'t allowed. Current position volume is less that selling one';
            const errorMessage = 'Current position volume is less that selling one naked sells aren\'t allowed.';
            console.log(errorMessage);
            errorCallback(errorMessage);
            return;
          }
          if (position.PositionVolume === 0 && order.Symbol.SymbolType === 'Equities') {
            // const errorMessage = 'naked sells aren\'t allowed. Cannot open position';
            const errorMessage = 'Sell without buy is not allowed';
            console.log(errorMessage);
            errorCallback(errorMessage);
            return;
          }
        }

        operations = position.doSell(order);
      }

      const operationArray = Array.from(operations);
      /* locking the position to process all trades */
      position.lockWithСount(operationArray.length);

      operationArray.forEach((step) => {
        try {
          this.checkBoundsNAN(step.order);
        } catch (ex) {

          position.unlock();
          if (errorCallback) {
            errorCallback((ex as Error).message);
          }
          console.log((ex as Error).message);
          return;
        }

        this.processOperation(step, position).then((r) => {
          // console.log(r);
          if (r.isBreaking) {
            console.log(r.errorMessage);
            errorCallback(this.makeErrorMessage(r.errorMessage, volumeProceed));
            return;
          } else {
            volumeProceed += Math.abs(step.order.Volume);
          }

          successCallback('Trade opened', r.dealID);
        });
      });

      // for (const step  of operationArray) {
      //   // stop the processing when any of TP/SL/TS is NaN
      //   try {
      //     this.checkBoundsNAN(step.order);
      //   } catch (ex) {
      //
      //     position.unlock();
      //     if (errorCallback) {
      //       errorCallback((ex as Error).message);
      //     }
      //     console.log((ex as Error).message);
      //     break;
      //   }
      //
      //   console.log(23, 'step');
      //
      //   const r = await this.processOperation(step, position);
      //
      //   // console.log(r);
      //
      //   if (r.isBreaking) {
      //     console.log(r.errorMessage);
      //     errorCallback(this.makeErrorMessage(r.errorMessage, volumeProceed));
      //     break;
      //   } else {
      //     volumeProceed += Math.abs(step.order.Volume);
      //   }
      //
      //   successCallback('Trade opened');
      // }
    }
  }

  private makeErrorMessage(errText: string, volProceed: number) {
    if (volProceed === 0) {
      return errText;
    } else {
      return 'only ' + volProceed + ' proceed, error: ' + errText;
    }

  }
}

export class OperationResult {
  constructor(
    public isBreaking: boolean,
    public isDeclined: boolean,
    public errorMessage?: string,
    public dealID?: number,
    public tradeID?: number
  ) {}
}
