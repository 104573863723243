import {LocaleEnum} from '@common/locale/servises/locale';
import {IAppConfig} from '@common/configuration/app-config-interface';
import {Trader} from '@common/trader/models/trader';

export class OperationsWithUrl {

  public static goToBackoffice(settings: IAppConfig, trader: Trader, currentLocale: string, sessionID: string, key: string   ): void {
    const configUrl = settings.urls[key];
    const url = configUrl === undefined || configUrl === '' ? trader.BackOfficeUrl : configUrl;
    /* greenstone needs special urls for their crm */
    if (settings.customIntegrationSetting && settings.customIntegrationSetting.BackOfficeUrlLanguageMap ) {
      /* go to chinese version when we have chinese language on */
      if (currentLocale === LocaleEnum.Chinese) {
        let gsChineseUrl = settings.customIntegrationSetting.BackOfficeUrlLanguageMap['zh-cn'];
        gsChineseUrl = gsChineseUrl === undefined || gsChineseUrl === '' ? trader.BackOfficeUrl : gsChineseUrl;
        OperationsWithUrl.openWindow(gsChineseUrl + '/' + trader.ExternalAccountID);
      } else { /* go to english crm version if any other locale is on */
        let gsEnglishUrl = settings.customIntegrationSetting.BackOfficeUrlLanguageMap['en-us'];
        gsEnglishUrl = gsEnglishUrl === undefined || gsEnglishUrl === '' ? trader.BackOfficeUrl : gsEnglishUrl;

        gsEnglishUrl = this.replaceDataUrl(gsEnglishUrl, trader, sessionID);

        // OperationsWithUrl.openWindow(gsEnglishUrl + '/' + trader.ExternalAccountID);
        OperationsWithUrl.openWindow(gsEnglishUrl);

      }
    } else {
      OperationsWithUrl.openWindow(url + '?SessionID=' + sessionID);
    }
  }

  public static openWindow(url: string) {
    window.open(url);
  }

  public static replaceDataUrl(url: string, trader: Trader, sessionID: string): string {
    let currentUrl = url;

    currentUrl = currentUrl.replace('$LoginName', trader.TraderName);
    currentUrl = currentUrl.replace('$ExternalAccountID', trader.ExternalAccountID);
    currentUrl = currentUrl.replace('$TraderId', `${trader.TraderId}`);
    currentUrl = currentUrl.replace('$SessionID', sessionID);

    return currentUrl;
  }

}
