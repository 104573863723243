import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {MultiTierQuoteLevel} from '@common/symbol/models/multi-tier-quote-level';
import {ChartService} from '@common/charting/services/chart.service';
import {SymbolPriceFormatter} from '@common/shared/utils/symbol-price-formatter';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {SettingsService} from '@common/trader/services/settings.service';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {TradeType} from '@common/trade/models/trade-type';
import {TradeAction} from '@common/actions/trade.action';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TvChartService} from '@common/trading-view-charts/services/tv-chart.service';
import {Settings} from '@common/trader/models/settings';
import {Trader} from '@common/trader/models/trader';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {TradeService} from '@common/trade/services/trade.service';
import {AppConfig} from '@common/configuration/app-config';
import {Environment} from '@common/environment';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {SharedModule} from '@common/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {LocaleModule} from '@common/locale/locale.module';

@Component({
  selector: 'app-full-market-depth',
  templateUrl: './full-market-depth.component.html',
  styleUrls: ['./full-market-depth.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SharedModule,
    FormsModule,
    LocaleModule,
    NgClass,
    NgForOf
  ]
})
export class FullMarketDepthComponent implements OnInit, OnDestroy {

  private _currentSymbol: Symbol;
  private _amount = 0;
  private _bidPriceFormatter: SymbolPriceFormatter;
  private _askPriceFormatter: SymbolPriceFormatter;
  private _priceChangeSubscription: ISmartSubscription;
  private _volumeChangeSubscription: ISmartSubscription;
  private _fullMarketDepthChangeSubscription: ISmartSubscription;
  private _chartService: TvChartService;

  private _isLoading = false;

  private renderSizeVolumeMD: number;

  chartUrl: string | null = null;
  closeAvailable = true;

  private _decimalAbbreviationMap = {
    1000: 'K',
    1000000: 'M'
  };

  @Output()
  public hide: EventEmitter<void> = new EventEmitter<void>();

  @Input() private heightContainer: boolean;

  public appName = '';

  public get VolumeDivider(): number {
    if (this.renderSizeVolumeMD == 3) {
      return 1000000; // 1 Million
    } else if (this.renderSizeVolumeMD == 2) {
      return 1000; // 1 Thousand
    } else {
      return 1; // 1
    }

  }

  public get IsLoading(): boolean {
    return this._isLoading;
  }

  public get DecimalAbbreviationMap() {
    return this._decimalAbbreviationMap;
  }

  public get BidPriceFormatter(): SymbolPriceFormatter {
    return this._bidPriceFormatter;
  }
  public get AskPriceFormatter(): SymbolPriceFormatter {
    return this._askPriceFormatter;
  }

  public get Amount(): number {
    return this._amount;
  }

  public set Amount(value: number) {
    this._amount = value;
  }

  public get Symbol(): Symbol {
    return this._currentSymbol;
  }

  public set Symbol(value: Symbol) {
    this._isLoading = true;

    if (this._currentSymbol) {
      this.symbolService.unsubscribeFullMarketDepth(this._currentSymbol.SymbolId).then();
    }

    this._currentSymbol = value;

    this.symbolService.subscribeToFullMarketDepth(this._currentSymbol.SymbolId).then(() => {
      this._isLoading = false;
      this.initializeSymbol();
    });
  }

  public get CurrentSymbolName(): string {
    if (!this.Symbol) {
      return '';
    }

    return this.Symbol.SymbolName;
  }

  public set CurrentSymbolName(value: string) {
    const symbol = this.symbolStorage.findSymbolByNameSync(value);

    if (symbol) {
      this.Symbol = symbol;

      // при смене символа проверка на подписку изминения цен, если её нет, то подписывается
      if (this.Symbol.LastQuote == undefined) {
        this.symbolService.subscribeToSymbol(this.Symbol.SymbolId).then();
      }

      console.log('contractSize', this._currentSymbol.ContractSize);
      this.checkContractSize();
    }
  }

  public get SymbolName(): string {
    if (!this._currentSymbol) {
      return '';
    }

    return this._currentSymbol.SymbolName;
  }

  get IsAnySymbolAvailable(): boolean {
    return this.symbolStorage.Symbols.length > 0;
  }

  public get AskPriceClass(): any {
    if (!this._currentSymbol) {
      return {};
    }

    if (this.IsZeTradex) {
      return {
        'z-positive': this._currentSymbol.LastQuoteInfo.Ask.IsUp,
        'z-negative': this._currentSymbol.LastQuoteInfo.Ask.IsDown,
        'z-neutral': !this._currentSymbol.LastQuoteInfo.Ask.IsDown && !this._currentSymbol.LastQuoteInfo.Ask.IsUp
      };
    }

    return {
      'positive': this._currentSymbol.LastQuoteInfo.Ask.IsUp,
      'negative': this._currentSymbol.LastQuoteInfo.Ask.IsDown,
      'neutral': !this._currentSymbol.LastQuoteInfo.Ask.IsDown && !this._currentSymbol.LastQuoteInfo.Ask.IsUp
    };
  }

  public get BidPriceClass(): any {
    if (!this._currentSymbol) {
      return {};
    }

    if (this.IsZeTradex) {
      return {
        'z-positive': this._currentSymbol.LastQuoteInfo.Ask.IsUp,
        'z-negative': this._currentSymbol.LastQuoteInfo.Ask.IsDown,
        'z-neutral': !this._currentSymbol.LastQuoteInfo.Ask.IsDown && !this._currentSymbol.LastQuoteInfo.Ask.IsUp
      };
    }

    return {
      'positive': this._currentSymbol.LastQuoteInfo.Bid.IsUp,
      'negative': this._currentSymbol.LastQuoteInfo.Bid.IsDown,
      'neutral': !this._currentSymbol.LastQuoteInfo.Bid.IsDown && !this._currentSymbol.LastQuoteInfo.Bid.IsUp
    };
  }

  public get AvailableSymbols(): Symbol[] {
    return this.symbolStorage.Symbols;
  }

  public get FullMarketDepth(): MultiTierQuoteLevel[] {
    if (!this._currentSymbol) {
      return [];
    }

    return this._currentSymbol.FullMarketDepth;
  }

  public get BestLevel(): MultiTierQuoteLevel {
    if (!this._currentSymbol) {
      return null;
    }
    if (this._currentSymbol.FullMarketDepth && this._currentSymbol.FullMarketDepth.length > 0) {
      return this._currentSymbol.FullMarketDepth[0];
    }

    if (this.Symbol.LastQuote !== undefined) {
      return new MultiTierQuoteLevel(
        this.Symbol.LastQuote.Ask,
        this.Symbol.LastQuote.Bid,
        this.Symbol.LastQuote.AskMarketDepth,
        this.Symbol.LastQuote.BidMarketDepth
      );
    }  else {
      return null;
    }


  }

  constructor(private symbolStorage: SymbolStorageService,
              chartService: ChartService,
              private settingsService: SettingsService,
              private symbolService: SymbolService,
              protected tradeAction: TradeAction,
              private route: ActivatedRoute,
              private router: Router,
              protected settings: Settings,
              private trader: Trader,
              private tradeService: TradeService,
              private appConfig: AppConfig) {

    this._chartService = <TvChartService> chartService;

    // условия для десктопа
    if (this._chartService.SymbolName !== undefined) {
      const symbol = this.symbolStorage.findSymbolByNameSync(this._chartService.SymbolName);
      if (symbol !== undefined) {
        this.Symbol = symbol;
      } else {
        this.Symbol = symbolStorage.Symbols[0];
      }
      // дополнительная проверка на подписку изминения цен, если её нет, то подписывается

      if (this.Symbol.LastQuote == undefined) {
        this.symbolService.subscribeToSymbol(this.Symbol.SymbolId).then();
      }
    }

    if (this.readParams(route.snapshot.params)) {
    } else if (symbolStorage.Symbols.length > 0) {
      if (this.Symbol == undefined) {
        this.Symbol = symbolStorage.Symbols[0];
      }
    } else {
      return;
    }

    this._bidPriceFormatter = new SymbolPriceFormatter(this._currentSymbol, this._currentSymbol.LastQuoteInfo.Bid);
    this._askPriceFormatter = new SymbolPriceFormatter(this._currentSymbol, this._currentSymbol.LastQuoteInfo.Ask);

    this._bidPriceFormatter.recalculateData();
    this._askPriceFormatter.recalculateData();

    this.Amount = this.getVolumeForTrading();


    this._volumeChangeSubscription = this.settingsService.Settings.OneClickAmountChanged.subscribe(v => {
      this.Amount = v;
    });

    this.Symbol.FullMarketDepthChange.subscribe(fullMd => {
      this._isLoading = false;
    });

    this.appName = this.appConfig.Settings.common.AppName;
  }

  ngOnInit() {

    // подписка на изминения торогов с лотов на объёмы , сбрасывает значение amount
    this.settings.UseTradingChangedEmitter.subscribe(() => {
      this.Amount = this.getVolumeForTrading();
    });

    this.checkContractSize();
  }

  private getVolumeForTrading() {
    return this.tradeService.getVolumeForOpenOrders(this.Symbol, this.Symbol.LastQuote.Bid );
  }

  private readParams(params: Params): boolean {
    console.log(params);

    if (params['chartUrl']) {
      this.chartUrl = params['chartUrl'];
    }
    if (params['closeAvailable'] !== undefined && params['closeAvailable'] == 'false') {
      this.closeAvailable = false;
    }
    if (params['symbolId']) {
      this.Symbol = this.symbolStorage.findSymbolById(Number(params['symbolId']));
      return true;
    }

    return false;
  }

  async ngOnDestroy() {
    if (this.Symbol) {
      await this.symbolService.unsubscribeFullMarketDepth(this.Symbol.SymbolId);
    }

    if (this._priceChangeSubscription !== undefined) {
      this._priceChangeSubscription.unsubscribe();
      this._priceChangeSubscription = null;
    }

    if (this._volumeChangeSubscription) {
      this._volumeChangeSubscription.unsubscribe();
      this._volumeChangeSubscription = null;
    }

    if (this._fullMarketDepthChangeSubscription) {
      this._fullMarketDepthChangeSubscription.unsubscribe();
      this._fullMarketDepthChangeSubscription = null;
    }
  }

  public formatToDecimalPlaces(int: number) {
    if ( int != null || int != undefined ) {
      return int.toFixed(this._currentSymbol.DecimalPlaces);
    } else {
      return '';
    }
  }

  public getAskForLevelFormatted(level: MultiTierQuoteLevel): string {
    if (level.Ask !== undefined && level.Ask > 0) {
      return this.formatToDecimalPlaces(level.Ask);
    }

    return '-';
  }

  public getAskVolumeForLevelFormatted(level: MultiTierQuoteLevel): string {
    if (level.AskVolume !== undefined && level.AskVolume > 0) {
      return this.formatVolume(level.AskVolume);
    }

    return '-';
  }

  public getBidForLevelFormatted(level: MultiTierQuoteLevel): string {
    if (level.Bid !== undefined && level.Bid > 0) {
      return this.formatToDecimalPlaces(level.Bid);
    }

    return '-';
  }

  public getBidVolumeForLevelFormatted(level: MultiTierQuoteLevel): string {
    if (level.BidVolume !== undefined && level.BidVolume > 0) {
      return this.formatVolume(level.BidVolume);
    }

    return '-';
  }

  public getSpreadForLevelFormatted(level: MultiTierQuoteLevel): string {
    if (level.Spread === undefined) {
      return '-';
    }

    if ((level.Ask === undefined || level.Ask <= 0) ||
      (level.Bid === undefined || level.Bid <= 0)) {
      return '-';
    }

    return this.formatSpread(level);
  }

  public formatVolume(volume: number): string {
    return (volume / this.VolumeDivider).toFixed(1) + this.getAbbreviationForDecimal();
  }

  public formatSpread(level: MultiTierQuoteLevel): string {
    return level.Spread.toFixed(this._currentSymbol.DecimalPlaces);
  }

  public async openChart($event: MouseEvent) {
    $event.stopPropagation();
    await this.openChartNewSymbol();
  }

  public close($event: MouseEvent) {
    $event.stopPropagation();
    this.hide.emit();
  }

  public async onSellClick($event: MouseEvent) {
    $event.stopPropagation();

    const dto = this.createOrder(TradeType.Sell);
    await this.tradeAction.openOrder(dto);
  }

  public async onBuyClick($event: MouseEvent) {
    $event.stopPropagation();

    const dto = this.createOrder(TradeType.Buy);
    await this.tradeAction.openOrder(dto);
  }

  private createOrder(tradeType: TradeType): TradeDTO {
    const order = new TradeDTO();
    if (this.settings.OneClickTrading && this.settings.UseLotTrading) {
      order.Volume = OperationsWithVolume.checkVolume(Number(this.Amount) * this.Symbol.ContractSize) / this.trader.LotDelimeter;
    } else {
      order.Volume = Number(this.Amount);
    }

    order.OpenPrice = tradeType === TradeType.Sell ? this.BestLevel.Bid : this.BestLevel.Ask;
    order.Type = tradeType;
    order.Symbol = this.Symbol;

    return order;
  }

  private initializeSymbol() {
    if (this._bidPriceFormatter) {
      this._bidPriceFormatter.Symbol = this._currentSymbol;
      this._bidPriceFormatter.PriceInfo = this._currentSymbol.LastQuoteInfo.Bid;
      setTimeout(() => this._bidPriceFormatter.recalculateData(), 300 );
      // this._bidPriceFormatter.recalculateData();
    }
    if (this._askPriceFormatter) {
      this._askPriceFormatter.Symbol = this._currentSymbol;
      this._askPriceFormatter.PriceInfo = this._currentSymbol.LastQuoteInfo.Ask;
      setTimeout(() => this._askPriceFormatter.recalculateData(), 300 );
      // this._askPriceFormatter.recalculateData();
    }

    if (this._priceChangeSubscription !== undefined) {
      this._priceChangeSubscription.unsubscribe();
      this._priceChangeSubscription = null;
    }

    this._priceChangeSubscription = this._currentSymbol.PriceChange.subscribe(v => {
      if (this._bidPriceFormatter) {
        this._bidPriceFormatter.PriceInfo = this._currentSymbol.LastQuoteInfo.Bid;
      }
      if (this._askPriceFormatter) {
        this._askPriceFormatter.PriceInfo = this._currentSymbol.LastQuoteInfo.Ask;
      }
    });
  }

  private getAbbreviationForDecimal(): string {
    if (this.renderSizeVolumeMD === 3) {
      return 'M';
    } else if (this.renderSizeVolumeMD === 2) {
      return  'K';
    } else {
      return '';
    }
  }

  public hiddenHeightContainer() {
    return this.heightContainer;
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  // проверяет, если зашел гость то в разделе отображения скрываются кнопки манипуляции данных, можно только просматривать

  public getTraderOrGuest(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  // проверка величины contractSize для отрисовки правельной величины сткана цен

  private checkContractSize() {
    const contractSize = this._currentSymbol.ContractSize;
    if (contractSize >= 100000) {
      this.renderSizeVolumeMD = 3;
    } else if (contractSize >= 100) {
      this.renderSizeVolumeMD = 2;
    } else {
      this.renderSizeVolumeMD = 1;
    }

  }

  public get IsZeTradex() {
    return this.appName === 'Zetradex';
  }

  public getStylesButton(type: string): string {
    if (this.IsZeTradex) {

      if (type === 'Sell') {
        return 'z-button-danger';
      }

      if (type === 'Buy') {
        return 'z-button-success';
      }
    } else {
      if (type === 'Sell') {
        return 'button-danger';
      }

      if (type === 'Buy') {
        return 'button-success';
      }
    }
  }

  changeCurrentSymbolName($event: string): void {
    this.CurrentSymbolName = $event;
  }

  closeFullMarket(): void {
    this.hide.emit();
  }

  async openChartNewSymbol() {
    if (this.chartUrl !== null) {
      await this.router.navigate([this.chartUrl, {symbolName: this.Symbol.SymbolName, symbolId: this.Symbol.SymbolId}]);
    } else {
      await this._chartService.setSymbol(this.CurrentSymbolName);
    }
  }

  get IsMobileVersion(): boolean {
    return Environment.IsMobileVersion;
  }

  public get DecimalPlaces(): number {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.numberOfDigitsAfterDot(this.showStep());
    }
    return this.Symbol.VolumeDecimalPlaces;
  }

  public showStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.Symbol.TradingStep, this.Symbol.ContractSize, this.trader.LotDelimeter);
    }
    return this.Symbol.TradingStep;
  }

}
