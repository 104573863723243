import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {TradeDispatcherService} from '@common/trade/services/trade-dispatcher.service';
import {Trader} from '@common/trader/models/trader';
import {IWatchListPromise} from '@common/watchlist/model/watch-list-promice';
import {
  Command_WatchListAddSymbol,
  Command_WatchListCreate,
  Command_WatchListDelete,
  Command_WatchListRemoveSymbol,
  Command_WatchListRetrieveAllByTraderId, Command_WatchListSuggestionsRetrieveAllByTraderId,
  Command_WatchListUpdate
} from '@common/communication/connection/classes.g';
import {WatchList} from '@common/watchlist/model/watch-list';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class WatchlistCommandSender extends AbstractCommandSender {

  private _logger = LoggerFactory.getLogger('WatchlistCommandSender');
  public constructor(serverConnection: ServerInteractionService,
                     private tradeDispatcher: TradeDispatcherService) {

    super(serverConnection);
  }

  public async createWatchList(watchListName: string, trader: Trader): Promise<number> {
    const command = new Command_WatchListCreate();

    command.WatchListName = watchListName;
    command.TraderID = trader.TraderId;

    const result = new Promise<number>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(answer.WatchListID))
        .failed(message => reject(message));
    });

    return result;
  }

  public async deleteWatchList(watchListID: number): Promise<IWatchListPromise> {
    const command = new Command_WatchListDelete();

    command.WatchListID = watchListID;

    const result = new Promise<IWatchListPromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(null))
        .failed(message => reject(message));
    });

    return result;
  }

  public async addSymbolToWatchList(watchListID: number, symbolID: number): Promise<number> {
    const command = new Command_WatchListAddSymbol();

    command.WatchListID = watchListID;
    command.SymbolID = symbolID;

    const result = new Promise<number>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(answer.WatchListContentID))
        .failed(message => reject(message));
    });

    return result;
  }

  public async removeSymbolFromWatchList(watchListContentID: number): Promise<IWatchListPromise> {
    const command = new Command_WatchListRemoveSymbol();

    command.WatchListContentID = watchListContentID;

    const result = new Promise<IWatchListPromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(null))
        .failed(message => reject(message));
    });

    return result;
  }

  public async updateWatchList(watchListId: number, watchListName: string): Promise<IWatchListPromise> {
    const command = new Command_WatchListUpdate();

    command.WatchListID = watchListId;
    command.NewWatchListName = watchListName;

    const result = new Promise<IWatchListPromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(null))
        .failed(message => reject(message));
    });

    return result;
  }

  public async getTraderWatchLists(traderID: number): Promise<WatchList[]> {
    const command = new Command_WatchListRetrieveAllByTraderId();
    command.TraderId = traderID;

    const result = new Promise<WatchList[]>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          const convertedWatchLists: WatchList[] = new Array();
          answer.WatchLists.forEach(answerWatchList => {
            const convertedWL = new WatchList();
            convertedWL.watchListID = answerWatchList.WatchListID;
            convertedWL.watchListName = answerWatchList.WatchListName;
            convertedWL.traderID = answerWatchList.TraderID;
            convertedWL.watchListContentID = answerWatchList.WatchListContentID;
            convertedWL.symbolID = answerWatchList.SymbolID;
            convertedWatchLists.push(convertedWL);
          });
          resolve(convertedWatchLists);
        })
        .failed(message => reject(message));
    });

    return result;
  }

  public  async getTraderSuggestionsWatchList(traderID: number): Promise<WatchList[]> {
    const command = new Command_WatchListSuggestionsRetrieveAllByTraderId();
    command.TraderId = traderID;

    const result = new Promise<WatchList[]>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          const convertedWatchLists: WatchList[] = new Array();
          answer.WatchListSuggestions.forEach(answerWatchListSuggestion => {
            const convertedWL = new WatchList();
            convertedWL.watchListID =  answerWatchListSuggestion.WatchListSuggestionID;
            convertedWL.watchListName = answerWatchListSuggestion.WatchListSuggestionName;
            convertedWL.traderID = answerWatchListSuggestion.TraderID;
            convertedWL.watchListContentID = answerWatchListSuggestion.WatchListSuggestionContentID;
            convertedWL.symbolID = answerWatchListSuggestion.SymbolID;
            convertedWatchLists.push(convertedWL);
          });
          resolve(convertedWatchLists);
        })
        .failed(
          message => reject(message)
        );
    });

    return result;
  }

  public async getTraderWatchListVersion(traderID: number): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      resolve(1);
    });
  }

}
