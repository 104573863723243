import {Pipe, PipeTransform} from '@angular/core';
import {Symbol} from '../../symbol/models/symbol';
import {CustomNumber} from '@common/trade/utils/custom-number';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: string | number, symbol: Symbol): any {
    if(value === '' || value == null) return '0';

    value = this.cutNumber(Number(value), symbol);

    return value
  }

  private cutNumber(value: number, symbol: Symbol): string{
    const price = CustomNumber.cutNumber(Number(value), symbol.DecimalPlaces);
    return price.toFixed(symbol.DecimalPlaces);
  }
}
