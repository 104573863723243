<number-input
  [min]="min"
  [max]="max"
  [decimalPlaces]="decimalPlaces"
  [disabled]="disabled"
  [step]="getTradingStep()"
  [value]="value"
  [(ngModel)]="value"
  [name]="name"
  separator=","
  decimalSeparator="."
  [blockOnTouch]="blockOnTouch"
  [blockFreeChange]="blockFreeChange"
  (touch)="touch()"
  (blur)="blur()"
  blockTillInit="true"
  [appName]="appName"
>
</number-input>
