import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class CFD_USDCC_index extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.CFD_USDCC_index;
	}
        
  public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  	
	if (this.Symbol.getTickSize() == 0)
			{
				return 0;
			}
  			return unsignedAmount * this.Symbol.getPercentage() * openPrice * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()) / this.Symbol.getCCConvertSymbol().getAsk();
}

  public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  	
	if (this.Symbol.getTickSize() == 0)
			{
				return 0;
			}
  			return unsignedAmount * this.Symbol.getPercentage() * openPrice * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()) / this.Symbol.getCCConvertSymbol().getBid();
}

  public CalculateMaxLotCountInternalLong(unsignedAmount: number, openPrice: number, freeMargin: number): number {
  	
		if ((this.Symbol.getTickSize() == 0) || (unsignedAmount * this.Symbol.getPercentage() == 0))
			{
				return 0;
			}
  			return freeMargin / (unsignedAmount * this.Symbol.getPercentage() * openPrice * (this.Symbol.getTickPrice() / this.Symbol.getTickSize() / this.Symbol.getCCConvertSymbol().getAsk()));
}

  public CalculateMaxLotCountInternalShort(unsignedAmount: number, openPrice: number, freeMargin: number): number {
  	
		if ((this.Symbol.getTickSize() == 0) || (unsignedAmount * this.Symbol.getPercentage() == 0))
			{
				return 0;
			}
  			return freeMargin / (unsignedAmount * this.Symbol.getPercentage() * openPrice * (this.Symbol.getTickPrice() / this.Symbol.getTickSize() / this.Symbol.getCCConvertSymbol().getBid()));
}

  }