import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";


export class CFD_USDCC_index extends ProfitCalcBase {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.CFD_USDCC_index;
	}
        
	public CalculateInternal(signedAmount: number, openPrice: number, closePrice: number): number {
		
	if (!this.Symbol.getCCConvertSymbol().getIsValidQuote() || this.Symbol.getContractSize() * this.Symbol.getTickSize() == 0 || ((this.Symbol.getCCConvertSymbol().getAsk() + this.Symbol.getCCConvertSymbol().getBid()) / 2) == 0 )
			{
				return 0;
			}
				return (signedAmount / this.Symbol.getContractSize()) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()) * (closePrice - openPrice);
}

	public CalculateInternalWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number {
		
	if (!this.Symbol.getCCConvertSymbol().getIsValidQuote() || this.Symbol.getContractSize() * this.Symbol.getTickSize() == 0)
			{
				return 0;
			}
				return (signedAmount / this.Symbol.getContractSize()) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()) * (closePrice - openPrice) * conversion;
}

	public CalculatePriceAtProfitInternal(signedAmount: number, openPrice: number, profit: number): number {
		
	if (this.Symbol.getContractSize() * this.Symbol.getTickSize() == 0 || signedAmount*this.Symbol.getTickPrice() == 0 )
			{
				return 0;
			}
				return openPrice + (profit * ((this.Symbol.getCCConvertSymbol().getAsk() + this.Symbol.getCCConvertSymbol().getBid()) / 2) ) / ((signedAmount / this.Symbol.getContractSize()) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()));
}

	
}