import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChartService} from '@common/charting/services/chart.service';
import {ActivatedRoute} from '@angular/router';
import {AppConfig} from '@common/configuration/app-config';
import {TvChartService} from '@common/trading-view-charts/services/tv-chart.service';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {TradingViewNotificationService} from '@common/trading-view-charts/services/trading-view-notification.service';
import {Trader} from '@common/trader/models/trader';
import {Environment} from '@common/environment';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {

  private symbolName: string;

  private chartService: TvChartService;

  private hidden: boolean;

  private localeChangedSubscribe: ISmartSubscription;

  constructor(chartService: ChartService,
              private route: ActivatedRoute,
              private translationService: TranslatorService,
              private appConfig: AppConfig,
              private tradingViewNotificationService: TradingViewNotificationService,
              private trader: Trader) {
    this.chartService = <TvChartService>chartService;
    this.setChartNameForSymbolIfExist();
    this.localeChangedSubscribe = this.translationService.LocaleChanged.subscribe(() => this.chartService.setLanguage(this.CurrentLanguage));
  }

  ngOnInit() {
    this.chartService.configureChart(this.symbolName, null, this.CurrentLanguage);
  }

  ngOnDestroy(): void {
    this.chartService.destroy();
    if (Environment.IsMobileVersion) {
      this.chartService.closeChartForMobile();
    }

    if (this.localeChangedSubscribe) {
      this.localeChangedSubscribe.unsubscribe();
      this.localeChangedSubscribe = undefined;
    }

  }

  private setChartNameForSymbolIfExist() {
    const symbol = this.route.snapshot.params.symbolName;
    if (symbol) {
      this.symbolName = symbol;
      this.chartService.saveLocalStorageSymbolNameForTrader(symbol);
    } else {
      this.symbolName = this.appConfig.resolveDefaultSymbol(this.trader.TradeGroupId);
    }
  }

  private get DefaultLanguage(): string {
    return this.appConfig.Settings.localisation.defaultLang.slice(0, 2);
  }

  private get CurrentLanguage(): string {
    return this.translationService.CurrentLocale.slice(0, 2);
  }

  public get notificationHidden(): boolean {
    this.hidden = this.tradingViewNotificationService.hiddenValue;
    return this.hidden;
  }

  public closeNotification(): void {
    this.hidden = true;
  }

  public textNotification(): string {
    return this.tradingViewNotificationService.textNotification;
  }
}
