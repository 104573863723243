import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'red-alert',
  templateUrl: './red-alert.component.html',
  styleUrls: ['./red-alert.component.scss']
})
export class RedAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
