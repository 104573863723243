import {Injectable} from '@angular/core';
import {EntrySpotCommandSender} from '@common/communication/command-sender/entry-spot-command-sender';
import {translate} from '@common/locale/servises/translator.service';


@Injectable({
  providedIn: 'root'
})

export class TimeService {

  private serverTimeWithoutMilliseconds: number;
  private titleTime = 'Shared_FooterComponent_Time';


  constructor(private entrySpotCommandSender: EntrySpotCommandSender) {
  }


  public async getServerTime() {
    return this.entrySpotCommandSender.GetServerTime().then((data) => {
      const serverTimeWithoutMilliseconds = Math.floor(new Date(data).getTime() / 1000);
      const timeWithoutMilliseconds = Math.floor(new Date().getTime() / 1000);

      console.log('--------------------------------------');
      console.log('ServerTimeWithoutMilliseconds:', serverTimeWithoutMilliseconds);
      console.log('TimeWithoutMilliseconds:', timeWithoutMilliseconds);
      console.log('dif', serverTimeWithoutMilliseconds - timeWithoutMilliseconds );
      console.log('--------------------------------------');

      if (Math.abs(serverTimeWithoutMilliseconds - timeWithoutMilliseconds) > 10) {
        this.titleTime = 'Shared_FooterComponent_TimeOnComputer';
      } else {
        this.titleTime = 'Shared_FooterComponent_ServerTime';
      }

      this.serverTimeWithoutMilliseconds = Math.floor(new Date(data).getTime() / 1000);

    }).catch((data) => {
      console.log('Error', data);
      this.serverTimeWithoutMilliseconds = Math.floor(new Date().getTime() / 1000);
    });
  }

  public get ServerTimeWithoutMilliseconds(): number {
    return this.serverTimeWithoutMilliseconds;
  }

  public get TitleTime(): string {
    return translate(this.titleTime);
  }

}
