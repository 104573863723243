import {RolloverCalcBase} from "@common/shared/calculators/Rollover/RolloverCalcBase.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";

export class Zero implements IRolloverCalc {
	
	
	getRolloverCalcType(): RolloverCalcType {
		return RolloverCalcType.Zero;
	}
        
	public Calculate(signedAmount: number, openPrice: number): number {
  
  			return 0;
}

	
	
	
}