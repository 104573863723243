import {PriceDirection} from '@common/symbol/models/price-direction';
import {EventEmitter, Injectable} from '@angular/core';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {ResolutionString} from '@tradingview/charting_library';


export interface IChartAdapter {
  SymbolName: string;

  Language: string;

  openChart(symbol: string, resolution: string, type: PriceDirection): Promise<void>;
}

export type ChartReadyCallback = (chartId: string) => void;

@Injectable({
  providedIn: 'root'
})
export abstract class ChartService implements IChartAdapter {
  protected symbolName: string;
  protected language: string;
  protected interval: string = '30' as ResolutionString ;
  protected priceDirection: PriceDirection = PriceDirection.Bid;
  protected onChartReady: SmartEmitter<string>;

  public changeFavoriteSymbols: EventEmitter<void> = new EventEmitter<void>();

  protected constructor() {
    this.onChartReady = new SmartEmitter<string>();
  }

  public get EventChangeFavoriteSymbols() {
    return this.changeFavoriteSymbols;
  }

  public subscribeOnChartReady(callback: ChartReadyCallback): ISmartSubscription {
    return this.onChartReady.subscribe(callback);
  }

  public abstract configureChart(containerId?: string): void;


  public abstract openChart(symbol: string, resolution: string, type: PriceDirection): Promise<void>;

  public abstract setSymbol(symbol: string): Promise<void>;

  protected abstract setLanguage(v: string): void;

  public get SymbolName(): string {
    return this.symbolName;
  }

  public get Language(): string {
    return this.language;
  }

  public get PriceDirection(): PriceDirection {
    return this.priceDirection;
  }

  public set Language(v: string) {
    this.setLanguage(v);
  }
}
