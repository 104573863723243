import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LUnitComponent} from './components/l-unit/l-unit.component';
import {TranslationPipe} from './pipes/translation.pipe';
import {LocalDatePipe} from './pipes/local-date.pipe';
import {LDateComponent} from './components/l-date/l-date.component';
import {LSwitchComponent} from './components/l-switch/l-switch.component';
import {FormsModule} from '@angular/forms';
import {LSwitchRegionConnectComponent} from '@common/locale/components/l-switch-region-connect/l-switch-region-connect.component';

@NgModule({
  declarations: [
    LUnitComponent,
    TranslationPipe,
    LocalDatePipe,
    LDateComponent,
    LSwitchComponent,
    LSwitchRegionConnectComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    LUnitComponent,
    TranslationPipe,
    LocalDatePipe,
    LDateComponent,
    LSwitchComponent,
    LSwitchRegionConnectComponent
  ]
})
export class LocaleModule { }
