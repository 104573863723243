import {ErrorHandler, Injectable} from '@angular/core';
import {ErrorCommandSender} from '@common/communication/command-sender/error-command-sender';
import {Trader} from '@common/trader/models/trader';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {AppConfig} from '@common/configuration/app-config';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';

@Injectable()
export class ErrorSaveService implements  ErrorHandler {

  constructor(private errorCommandSender: ErrorCommandSender,
              private trader: Trader,
              private localStorageService: LocalStorageService,
              private config: AppConfig) { }

  handleError(error: Error): void {
    // если происходит такая ошибка TypeError связанная с canvas в vendor.js или NG0100 которая появляется только в режиме разработки, то она игнорируется
    if ( error.message !== undefined && error.message.includes('getContext') !== true) {
      const errorNG0100 = error.message.includes('NG0100:');
      const isConnectionClosed = error.message.includes('connection being closed.');
      if ( !errorNG0100 && !isConnectionClosed) {
        if (this.config !== undefined && this.config.Settings !== undefined && this.config.Settings.saveErrorDataBase !== undefined && this.config.Settings.saveErrorDataBase) {
          const loginName = this.trader.TraderName;
          const sessionId = this.localStorageService.getSessionKey();
          const timeStamp = OperationsWithDate.createTimeStamp();
          const context = this.trader.urlToOpenAfterLogin;
          const appVersion = this.config.Settings.AppVersion !== undefined ? this.config.Settings.AppVersion : '1.0.0.0';
          this.errorCommandSender.errorSave(sessionId, loginName, error, timeStamp, context, appVersion);
        } else {
          console.error(error);
        }
      }
    }
  }
}
