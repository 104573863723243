import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList
} from '@angular/core';
import {IsVisibleDirective} from '@common/shared/directives/is-visible/is-visible.directive';
import {ISmartObserver, ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {Timer} from '@common/common/utils/timer';
import {ResizeSensorDirective} from '@common/shared/directives/resize-sensor/resize-sensor.directive';
import {Subscription} from 'rxjs';
import {VisibilitySyncService} from '@common/shared/services/visibility-sync.service';

@Directive({
  selector: '[visibility-frame]'
})
export class VisibilityFrameDirective implements OnInit, OnDestroy, AfterContentInit {
  @ContentChildren(IsVisibleDirective) elements: QueryList<IsVisibleDirective>;

  private bufferSizePercent: number = 0;
  private minBufferSz: number;
  private maxBufferSz: number;

  private itemsLength: number;

  private _scrollSpeed = 1;
  private _debounceSpeed: number;

  private scrollEmitter = new SmartEmitter<any>();
  private scrollObserver: ISmartObserver<any>;

  private firstBuffered = 0;
  private lastBuffered = 0;
  private firstVisible: number;
  private lastVisible: number;

  private lastScrollTop = 0;

  private scrollSubscription: ISmartSubscription;
  private resizeSubscription: Subscription;

  private onScrollFunc = (scrollEvent) => this.scrollEmitter.emit(scrollEvent);

  @Input()
  public set checkOnChange(v: any[]) {
    if(v.length != this.itemsLength) {
      this.itemsLength = v.length;
      new Timer(() => this.onResize(), 0).start();
    }
  }

  @Input() set bufferPercent(v: number) {
    this.bufferSizePercent = Number(v);
  }

  @Input() set minBufferSize(v: number) {
    this.minBufferSz = Number(v);
  }

  @Input() set maxBufferSize(v: number) {
    this.maxBufferSz = Number(v);
  }

  @Input() set scrollSpeed(v: number) {
    this._scrollSpeed = Number(v);
  }
  @Input() set debounceSpeed(v: number) {
    this._debounceSpeed = Number(v);
  }

  constructor(private elementRef: ElementRef,
              @Optional() private sensor: ResizeSensorDirective,
              private vSync: VisibilitySyncService,
              private changeDetector: ChangeDetectorRef) {
    this.scrollObserver = this.scrollEmitter;
    this.scrollSubscription = this.scrollObserver.subscribe(event => this.onScroll(event));
    if(sensor) {
      this.resizeSubscription = this.sensor.resized.subscribe(() => this.onResize())
    }
  }

  ngOnInit() {
    this.elementRef.nativeElement.addEventListener('scroll', this.onScrollFunc);
    this.elementRef.nativeElement.addEventListener('wheel', this.onScrollFunc);
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener('wheel', this.onScrollFunc);
    this.scrollSubscription.unsubscribe();

    if(this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  ngAfterContentInit(): void {
    this.scanVisibility();
    this.vSync.markFrameReady();
  }

  private onResize() {
    this.scanVisibility();
    this.hideAllInvisible();
    this.changeDetector.detectChanges();
  }

  private scanVisibility() {
    if(this.Items.length == 0) return;
    this.firstBuffered = 0;
    this.lastBuffered = 0;
    this.scanVisibilityIndexes();
    this.emitVisible();
  }

  private hideAllInvisible() {
    const items = this.Items;

    this.hideFromTo(0, this.firstBuffered);
    this.hideFromTo(this.lastBuffered + 1, items.length);
  }

  private scanVisibilityIndexes() {
    const items = this.Items;

    if (items.length == 1) {
      this.firstVisible = 0;
      this.lastVisible = 0;
      return;
    }

    this.firstVisible = null;
    this.lastVisible = null;

    for(let i = 0; i < items.length; i++) {
      if(items[i].IsVisible && this.firstVisible == null) {
        this.firstVisible = i;
      }
      else if (items[i].IsVisible && this.firstVisible != null) {
        this.lastVisible = i;
      }
      else if (!items[i].IsVisible && this.lastVisible != null) {
        break;
      }
    }
  }

  private onScroll(event: any) {
    const dY = this.ScrollTop - this.lastScrollTop;

    if(dY == 0) return;

    this.lastScrollTop += dY;

    this.ScrollTop = this.lastScrollTop;

    if(dY < 0) this.onScrollUp();
    else this.onScrollDown();
    this.emitVisible();
  }

  private onScrollUp() {
    let wasVisible = false;
    const items = this.Items;

    for (let i = this.lastVisible; i >= 0; i--) {
      if(!items[i]) continue;

      if(!wasVisible && items[i].IsVisible) {
        wasVisible = true;
        this.lastVisible = i;
      }
      else if(wasVisible && items[i].IsVisible) {
        this.firstVisible = i;
      }
      else if(wasVisible && !items[i].IsVisible) {
        break;
      }
    }
  }

  private onScrollDown() {
    let wasVisible = false;
    const items = this.Items;

    for (let i = this.firstVisible; i < items.length; i++) {
      if(!items[i]) continue;

      if(!wasVisible && items[i].IsVisible) {
        wasVisible = true;
        this.firstVisible = i;
      }
      else if(wasVisible && items[i].IsVisible) {
        this.lastVisible = i;
      }
      else if(wasVisible && !items[i].IsVisible) {
        break;
      }
    }
  }

  private async hideFromTo(from: number, to: number) {
    const items = this.Items;

    for(let i = from; i < to; i++) {
      if(!items[i]) continue;

      items[i].setVisibility(false);
    }
  }

  private emitVisible() {
    const items = this.Items;

    let bufferElements = Math.floor((this.lastVisible - this.firstVisible) * this.bufferSizePercent / 100);

    if(this.maxBufferSz) {
      bufferElements = Math.min(bufferElements, this.maxBufferSz)
    }
    if(this.minBufferSz) {
      bufferElements = Math.max(bufferElements, this.minBufferSz)
    }

    const oldFB = this.firstBuffered;
    const oldLB = this.lastBuffered;

    this.firstBuffered = (this.firstVisible - bufferElements) < 0 ? 0 : (this.firstVisible - bufferElements);
    this.lastBuffered = (this.lastVisible + bufferElements) >= items.length ? (items.length - 1) : (this.lastVisible + bufferElements);

    if (oldLB <= this.firstBuffered || oldFB > this.lastBuffered) {
      if(oldFB != oldLB) {
        this.hideFromTo(oldFB, oldLB + 1);
      }

      for(let i = this.firstBuffered; i <= this.lastBuffered; i++) {
        items[i].setVisibility(true);
      }
    }
    else if (this.firstBuffered == oldFB || this.lastBuffered == oldLB) {
      for(let i = this.firstBuffered; i <= this.lastBuffered; i++) {
        items[i].setVisibility(true);
      }
    }
    else if (this.firstBuffered < oldLB && this.firstBuffered > oldFB) {
      this.hideFromTo(oldFB, this.firstBuffered);

      for(let i = oldLB; i <= this.lastBuffered; i++) {
        items[i].setVisibility(true);
      }
    }
    else if (this.lastBuffered > oldFB && this.lastBuffered < oldLB) {
      this.hideFromTo(this.lastBuffered + 1, oldLB + 1);

      for(let i = this.firstBuffered; i <= oldFB; i++) {
        items[i].setVisibility(true);
      }
    }
  }

  public get ScrollTop(): number {
    return this.elementRef.nativeElement.scrollTop;
  }
  public set ScrollTop(v: number) {
    this.elementRef.nativeElement.scrollTop = v;
  }

  public get Items(): IsVisibleDirective[] {
    if(this.elements) {
      return this.elements.toArray();
    }
    else return [];
  }
}
