import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  private delay = 2000;
  private timeoutId;

  constructor(private router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = true;
        } else {
          // clear alert
          this.clear();
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'success', text: message});

    this.setDelay();
  }

  error(message: string, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'error', text: message});

    this.setDelay();
  }

  warning(message: string, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: 'warning', text: message});

    this.setDelay();
  }

  message(message: string, type: string, keepAfterNavigationChange = true) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({type: type, text: message});

    this.setDelay();
  }

  setDelay() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.clear();
    }, this.delay);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clear() {
    // clear alerts
    this.subject.next(null);
  }
}
