
export class OperationsWithText {

  public static getTextNeedLength(text: string, length: number) {
    if (text.length >= length) {
      text = `${text.slice(0, length - 3)}...`;
      return text;
    } else {
      return text;
    }
  }

}
