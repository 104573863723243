import {CustomNumber} from '@common/trade/utils/custom-number';

interface ConverterInfo {
  decimalSeparator: string;
  decimalPlaces: number;
  separator: string;
}

export class NumberFormatter {
  public constructor(private info: ConverterInfo) {

  }

  public formattedStringToNumber(formatted: string) {
    const s = formatted.split(',').join('');
    const num = this.clearValue(s);
    return Number(num);
  }

  private clearValue(value: string): string {
    const result = value.split(this.separator).join('')
      .replace(this.decimalSeparator, '.');

    return result;
  }

  // Добавил булевое значение "input" для полей ввода, чтоб расчет цифр после запятой убирал лишние нули (пример 0. 0.0 0.1 0.02)
  // значение прокидывается до метода getDecimals там метод deleteZeroAfter делает все преобразования

  public numberToFormatted(value: number, input = false, newValueTemp?) {
    this.checkDecimalPlaces(value);

    const body = this.getNumberBody(value);

    const decimals = this.getDecimals(CustomNumber.cutNumber(value, this.decimalPlaces), input, newValueTemp);

    return body + decimals;
  }

  private getNumberBody(value: number): string {
    const body = CustomNumber.cutNumber(value, 0);

    let result = body.toString();

    let index = result.length;

    const treshold = value < 0 ? 4 : 3;

    while (index > treshold) {
      const first = result.slice(0, index - 3);
      const second = result.slice(index - 3);

      result = first + this.separator + second;

      index -= 3;
    }

    if (value < 0 && body == 0) {
      result = '-' + result;
    }

    return result;
  }

  private getDecimals(value: number, input: boolean, newValueTemp: string): string {
    const decimals = this.getSymbolsAfterPoint(value.toFixed(this.decimalPlaces));

    if (!decimals) return '';

    if (input) {
      return this.decimalSeparator + this.getSymbolsAfterPoint(newValueTemp).slice(0, this.decimalPlaces);
    }

    return this.decimalSeparator + decimals;
  }

  private getSymbolsAfterPoint(line: string): string {
    const pointIdx = line.indexOf('.');

    if (pointIdx == -1) return '';

    else return line.slice(pointIdx + 1);
  }

  public get separator(): string {
    return this.info.separator;
  }

  public get decimalPlaces(): number {
    return this.info.decimalPlaces < 0 ? 0 : this.info.decimalPlaces;
  }

  public get decimalSeparator(): string {
    return this.info.decimalSeparator;
  }

  private checkDecimalPlaces(value: number): void {
    const numberArray: string[] = value.toString().split('.');
    if (numberArray.length == 2 && this.decimalPlaces == 0) {
      if (numberArray[1].length == 1) {
        this.info.decimalPlaces = 2;
      } else {
        this.info.decimalPlaces = numberArray[1].length;
      }

    }
  }

  public static format(number: number, decimalPlaces: number = 0, separator: string = ',', decimalSeparator: string = '.'): string {
    const temp = new NumberFormatter({
      decimalSeparator: (decimalPlaces ? decimalSeparator : ''),
      decimalPlaces: decimalPlaces,
      separator: separator
    });

    return temp.numberToFormatted(number);
  }
}
