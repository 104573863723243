import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class Direct extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.Direct;
	}
        
  public CalculateInternal(signedAmount: number, openPrice: number): number {
  
  			return Math.abs(signedAmount) * this.Symbol.getMarginRate();
}

  public CalculateMaxLotCountInternal(signedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (Math.abs(signedVolume) * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin / (Math.abs(signedVolume) * this.Symbol.getMarginRate());
}

  
  
  }