import {AfterViewInit, Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  selector: '[alwaysOnScreen]'
})
export class AlwaysOnScreenDirective implements OnInit, AfterViewInit {
  private dragEnabled: boolean;

  private get LeftPx(): number{
    return Number(this.elementRef.nativeElement.style.left.slice(0, -2));
  }
  private set LeftPx(v: number){
    this.elementRef.nativeElement.style.left = v + 'px'
  }

  private get TopPx(): number{
    return Number(this.elementRef.nativeElement.style.top.slice(0, -2));
  }
  private set TopPx(v: number){
    this.elementRef.nativeElement.style.top = v + 'px'
  }

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.checkAndCorrectPosition();
  }

  ngAfterViewInit(): void {
    this.checkAndCorrectPosition();
  }

  @HostListener('document:mousedown')
  public onDragStart(){
    this.dragEnabled = true;
    this.checkAndCorrectPosition();
  }

  @HostListener('document:mousemove')
  public onMouseMove(){
    if(!this.dragEnabled) return;

    this.checkAndCorrectPosition();
  }

  @HostListener('document:mouseup',)
  public onMouseUp(){
    this.disabseDrag();
    this.checkAndCorrectPosition();
  }

  @HostListener('click')
  public onClick(){
    this.checkAndCorrectPosition();
  }

  private disabseDrag(){
    this.dragEnabled = false;
  }

  private checkAndCorrectPosition(){
    if((this.ElementLeft + this.ElementWidth) > window.innerWidth) {
      this.LeftPx = this.LeftPx - (this.ElementLeft + this.ElementWidth - window.innerWidth);
    }
    if((this.ElementTop + this.ElementHeight) > window.innerHeight) {
      this.TopPx = this.TopPx - (this.ElementTop + this.ElementHeight - window.innerHeight);
    }
    if(this.ElementTop < 0){
      this.TopPx = -this.ElementTop;
    }
    if(this.ElementLeft < 0){
      this.LeftPx = -this.ElementLeft;
    }
  }

  public get ElementLeft(): number {
    return this.elementRef.nativeElement.getBoundingClientRect().left;
  }
  public get ElementTop(): number {
    return this.elementRef.nativeElement.getBoundingClientRect().top;
  }
  public get ElementHeight(): number {
    return this.elementRef.nativeElement.offsetHeight;
  }
  public get ElementWidth(): number {
    return this.elementRef.nativeElement.offsetWidth;
  }
}
