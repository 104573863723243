import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";
import {BCUSD_USDCC} from "@common/shared/calculators/Profit/BCUSD_USDCC.g";

export class CFD_USDCC extends BCUSD_USDCC {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.CFD_USDCC;
	}
        
	
	
	
	
}