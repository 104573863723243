import {Injectable} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {ProfitCalcType} from '@common/trade/utils/calculations/profit-calc-type.enum';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {PositionType} from '@common/shared/trade/classes/TradeFormSettings';


@Injectable({
  providedIn: 'root'
})

export class ConversionCalculatorService {

  constructor() {}


  private checkingForCorrectNumber(number: number): number {
    if (number !== Number.POSITIVE_INFINITY && number !== Number.NEGATIVE_INFINITY && !isNaN(number)) {
      return OperationsWithVolume.roundingToHundredths(number);
    }

    return 0;
  }

  public getPipValueInAC(symbol: Symbol, volume: number): number {
    if (symbol) {
      const result = this.checkingForCorrectNumber(this.getPipValueInUSD(symbol, volume));
      return AccountCurrency.Instance.exchange(result);
    }
    return 0;
  }

  private getPipValueInUSD(symbol: Symbol, volume: number): number {
    let result = 0;


    if (symbol.ProfitCalcType === ProfitCalcType.Direct) {
      result = symbol.PipSize * volume / this.getAveragePrice(symbol);
      return result;
    }

    if ([ ProfitCalcType.Indirect, ProfitCalcType.Indirect_index ].includes(symbol.ProfitCalcType)) {
      result = symbol.PipSize * volume;
      return result;
    }

    const SymbolCC: Symbol = symbol.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'];

    if (SymbolCC) {
      const averagePriceCC = this.getAveragePrice(SymbolCC);

      if (SymbolCC.ProfitCalcType === ProfitCalcType.Direct) {
        result = symbol.PipSize * volume / averagePriceCC;
        return result;
      }

      if (SymbolCC.ProfitCalcType === ProfitCalcType.Indirect) {
        result = symbol.PipSize * volume * averagePriceCC;
        return result;
      }
    }

    return result;
  }

  public getTransFeeValueInAC(symbol: Symbol, volume: number, orderType: PositionType) {
    if (symbol) {
      const result = this.checkingForCorrectNumber(this.getTransFeeValueInUSD(symbol, volume, orderType));
      return AccountCurrency.Instance.exchange(result);
    }
    return 0;
  }

  private getTransFeeValueInUSD(symbol: Symbol, volume: number, orderType: PositionType): number {
    let result = 0;
    let convertPriceSymbol: number;

    if (orderType === PositionType.Buy) {
      convertPriceSymbol = symbol.Ask;
    } else {
      convertPriceSymbol = symbol.Bid;
    }

    if (symbol.ProfitCalcType === ProfitCalcType.Direct) {
      result = volume;
      return result;
    }

    if ([ ProfitCalcType.Indirect, ProfitCalcType.Indirect_index ].includes(symbol.ProfitCalcType)) {
      result = volume * convertPriceSymbol;
      return result;
    }

    if (symbol.ProfitCalcSymbol.checkAvailabilityBCConvertSymbol()) {
      const SymbolBC = symbol.ProfitCalcSymbol.getBCConvertSymbol()['_symbolModel'] as Symbol;
      let convertPriceBC: number;

      if ((orderType === PositionType.Buy && SymbolBC.ProfitCalcType === ProfitCalcType.Indirect) ||
          (orderType === PositionType.Sell && SymbolBC.ProfitCalcType === ProfitCalcType.Direct)) {
        convertPriceBC = SymbolBC.Ask;
      } else {
        convertPriceBC = SymbolBC.Bid;
      }

      if (SymbolBC.ProfitCalcType === ProfitCalcType.Direct) {
        result = volume / convertPriceBC;
        return result;
      }

      if (SymbolBC.ProfitCalcType === ProfitCalcType.Indirect) {
        result = volume * convertPriceBC;
        return result;
      }

    }

    const SymbolCC = symbol.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'] as Symbol;
    let convertPriceCC: number;

    if (orderType === PositionType.Buy ) {
      convertPriceCC = SymbolCC.Ask;
    } else {
      convertPriceCC = SymbolCC.Bid;
    }

    if (SymbolCC.ProfitCalcType === ProfitCalcType.Direct) {
      result = volume * convertPriceSymbol / convertPriceCC;
      return result;
    }

    if (SymbolCC.ProfitCalcType === ProfitCalcType.Indirect) {
      result = volume * convertPriceSymbol * convertPriceCC;
      return result;
    }

    return result;
  }

  public getSpreadValueInAC(symbol: Symbol, spreadPips: number): number {
    if (symbol) {
      const result = this.checkingForCorrectNumber(this.getSpreadValueInUSD(symbol, spreadPips));
      return AccountCurrency.Instance.exchange(result);
    }
    return 0;
  }

  private getSpreadValueInUSD(symbol: Symbol, spreadPips: number): number {
    let result = 0;
    const spread = spreadPips * symbol.PipSize;
    const SymbolCC = symbol.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'] as Symbol;
    const averagePriceCC = this.getAveragePrice(SymbolCC);

    if (SymbolCC.ProfitCalcType === ProfitCalcType.Direct) {
      result = spread / averagePriceCC;
      return result;
    }

    if (SymbolCC.ProfitCalcType === ProfitCalcType.Indirect) {
      result = spread * averagePriceCC;
      return result;
    }
    return result;
  }

  public getVolumeInAC(volume: number) {
    const result = this.checkingForCorrectNumber(volume);
    return AccountCurrency.Instance.exchange(result);
  }

  public getAveragePrice(symbol: Symbol): number {
    if (symbol.LastQuoteInfo !== undefined) {
      return (symbol.Ask + symbol.Bid) / 2 ;
    }
    return 0;
  }

}
