import {ConnectionService} from './connection.service';
import {ProtoV2MessageBase} from './proto-v2-message-base';
import {DisconnectionCodes} from '@common/communication/connection/disconnection-code';

export enum  ConnectionState {
  Disconnected = 0,
  Connecting = 1,
  Connected = 2,
  ConnectionEstablishingError = 3,
  Disconnecting = 4,
  RemoteDisconnected = 5,
  Reset = 6,
  Reconnecting = 7,
  LoggedOut = 8,
  InvalidConnection = 9
}

export class InternalNotificationConnectionStateChanges extends ProtoV2MessageBase {

  private readonly _connection: ConnectionService;
  private readonly _state: ConnectionState;
  private readonly _disconnectionCode: DisconnectionCodes;

  public constructor(connection: ConnectionService, state: ConnectionState) {
    super();
    this.MessageType = InternalNotificationConnectionStateChanges.GetMessageType();
    this._connection = connection;
    this._state = state;
  }

  public static GetBinaryID(): number { throw new Error('BinaryId not applicable for internal usages'); }

  public static GetMessageType(): string { return  'InternalNotificationConnectionStateChanges'; }

  public getConnection(): ConnectionService {
    return this._connection;
  }

  public getState(): ConnectionState {
    return this._state;
  }

  public getDisconnectionCode(): DisconnectionCodes {
    return this._disconnectionCode;
  }
}
