import {Injectable} from '@angular/core';
import {SettingsCommandSender} from '@common/communication/command-sender/settings-command-sender';
import {Settings} from '@common/trader/models/settings';
import {Trader} from '@common/trader/models/trader';
import {LocalSettingsManagerService} from '@common/trader/services/local-settings-manager.service';
import {Answer_GetSettings} from '@common/communication/connection/classes.g';
import {SavedViewCommandSender} from '@common/communication/command-sender/saved-view-command-sender';

@Injectable({
  providedIn: 'root'
})
export class SettingsRepository {

  constructor(private settingsCommandSender: SettingsCommandSender,
              private localSettingsManager: LocalSettingsManagerService) {
  }

  public async load(trader: Trader): Promise<Settings> {
    if (trader.IsReadOnlyAccount) {
      return this.loadLocal(trader);
    } else {
      return await this.loadGlobal(trader);
    }
  }

  public async save(trader: Trader, settings: Settings) {
    if (trader.IsReadOnlyAccount) {
      return await this.saveLocal(trader, settings);
    } else {
      return await this.saveGlobal(trader, settings);
    }
  }

  private async loadGlobal(trader: Trader): Promise<Settings> {
    const settingsAnswer = this.settingsCommandSender.loadSettings(trader).toNativePromise();

    const result = settingsAnswer.then(answer => Settings.getFromGetSettingsAnswer(answer as Answer_GetSettings), error => error);

    return result;
  }

  private async loadLocal(trader: Trader) {
    const result = this.localSettingsManager.loadSettings(trader);

    if (!result) {
      return await this.loadGlobal(trader);
    } else {
      return result;
    }
  }

  private async saveGlobal(trader: Trader, settings: Settings) {
    this.settingsCommandSender.saveSettings(trader, settings);
  }

  private async saveLocal(trader: Trader, settings: Settings) {
    this.localSettingsManager.saveSettings(trader, settings);
  }
}
