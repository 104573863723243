import {ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {NumberInputComponent} from '@common/shared/components/number-input/number-input.component';
import {SettingsService} from '@common/trader/services/settings.service';
import {Settings} from '@common/trader/models/settings';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Trader} from '@common/trader/models/trader';

@Component({
  selector: 'app-volume-input',
  templateUrl: './volume-input.component.html',
  styleUrls: ['./volume-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VolumeInputComponent),
    multi: true
  }]
})
export class VolumeInputComponent extends ValueAccessorBase<number> implements OnInit, OnDestroy {

  private _symbol: Symbol;
  private _decimalPlaces = 2;
  @Input() name: string = '';
  @ViewChild(NumberInputComponent, {}) numberInput: NumberInputComponent;

  @Input() min = 0;
  @Input() max = Number.MAX_VALUE;

  @Input() appName: string;

  @Input() set Symbol(value: Symbol) {
    this._symbol = value;
  }

  get Symbol(): Symbol {
    return this._symbol;
  }

  @Input() set decimalPlaces(decimalPlaces: number) {
    this._decimalPlaces = decimalPlaces;
  }

  get decimalPlaces(): number {
    return this._decimalPlaces;
  }

  constructor(private changeDetector: ChangeDetectorRef, private settingsService: SettingsService, private trader: Trader) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.changeDetector.detach();
  }

  blur() {
    /* Auto change the volume if it does not fit the trading step - NOT NEEDED ANYMORE
    const ts = this.Symbol.TradingStep;

    if (ts !== 0) {
      const mod = this.value % this.Symbol.TradingStep;

      if (mod !== 0) {
        this.value -= mod;
        this.numberInput.setValue(this.value);
        this.changeDetector.detectChanges();
      }
    }*/

    super.blur();
  }

  change(value: number) {
    super.change(Number(value));

    this.changeDetector.detectChanges();
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public getTradingStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.Symbol.getInputTradingStep(), this.Symbol.ContractSize,  this.trader.LotDelimeter);
    }
    return this.Symbol.getInputTradingStep();
  }
}
