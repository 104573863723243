export class DateField {
  // Use it only for tests
  public static readonly EmptyConstant: string = '1899-12-30T00:00:00.0000000';
  private _isEmpty = true;

  public get IsEmpty(): boolean {
    return this._isEmpty;
  }

  public set IsEmpty(value: boolean) {
    this._isEmpty = value;
  }

  private _dateValue: string | Date = undefined;

  private set DateValue(v: string | Date){
    if(v && v != DateField.EmptyConstant) {
      this._isEmpty = false;
      this._dateValue = v;
    }
  }

  /**
   * Can return "empty" string. I did this because FXServer require this const string for do ExpDate unsetted
   */
  public get NativeDate(): Date | string {
    if(!this._isEmpty) {
      return new Date(this._dateValue);
    }
    else {
      return DateField.EmptyConstant;
    }
  }

  public get Date(): Date | string {
    if(this._isEmpty){
      return null;
    }
    else {
      return new Date(this._dateValue);
    }
  }

  public set Date(v: Date | string) {
    this.DateValue = v;
  }

  public constructor(value?: string){
    this.DateValue = value;
  }
}
