import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {Symbol} from '@common/symbol/models/symbol';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ViewMode} from '@common/shared/services/SymbolSelect/symbol-settings';
import {VisibilitySyncService} from '@common/shared/services/visibility-sync.service';
import {AppConfig} from '@common/configuration/app-config';
import {SymbolSearch} from '@common/shared/services/SymbolSelect/symbol-search';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ChartService} from '@common/charting/services/chart.service';
import {translate} from '@common/locale/servises/translator.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [VisibilitySyncService]
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() public modalWindowName: string;

  public visibleSymbols: Symbol[];
  private tempVisibleSymbols: Symbol[];
  public symbolSearch = new SymbolSearch();
  private _currentCategory = 'All';
  private _uniqueCategories = ['All', 'Favorites'];
  private readonly currentViewMode: ViewMode;
  private symbolIdTo: string;
  private symbolNameTo: string;
  private pathTo: string;

  private _placeholderSearch: string;

  constructor(private symbolSelector: SymbolSelectService,
              private vSync: VisibilitySyncService,
              private detector: ChangeDetectorRef,
              private appConfig: AppConfig,
              private route: ActivatedRoute,
              private router: Router,
              private chartService: ChartService) {
    this.currentViewMode = 'All';
    // this.viewAllSymbols();
    this.initSymbolsArray();
    this.uniqueCategories();

    this.readParams(route.snapshot.params);

    this.symbolSearch.PatternChanged.subscribe(() => {
      if (this.symbolSearch.Pattern.length === 0) {
        if (this.CurrentCategory === 'All') {
          this.visibleSymbols = this.tempVisibleSymbols;
        } else {
          this.visibleSymbols = this.filterSymbolArrayForCategory(this.CurrentCategory);
        }
      } else {
        if (this.CurrentCategory === 'All') {
          this.visibleSymbols = this.searchSymbols(this.tempVisibleSymbols, this.symbolSearch.Pattern);
        } else {
          this.visibleSymbols = this.searchSymbols(this.filterSymbolArrayForCategory(this.CurrentCategory), this.symbolSearch.Pattern);
        }
      }
      this.detector.detectChanges();
    });

    sessionStorage.setItem('searchPage', 'true');
  }

  private readParams(params: Params): void {
    if (params['to']) {
      this.pathTo = params['to'];
    }

    if (params['symbolId']) {
      this.symbolIdTo = params['symbolId'];
    }

    if (params['symbolName']) {
      this.symbolNameTo = params['symbolName'];
    }
  }

  ngAfterViewInit(): void {
    this.vSync.markFrameReady();
  }

  ngOnInit(): void {
    this.visibleSymbols = this.tempVisibleSymbols;
    console.log('Symbols count', this.visibleSymbols.length);
    this.createPlaceholderSearch();
  }

  ngOnDestroy(): void {
    this.detector.detach();
    // this.ViewMode = this.currentViewMode;
    sessionStorage.removeItem('searchPage');
  }

  public viewFavorites() {
    this.ViewMode = 'Favorites';
  }

  public viewAllSymbols() {
    this.ViewMode = 'All';
  }

  private initSymbolsArray() {
    const tempArray = this.symbolSelector.getSymbolsForSearchPageDesktop('');
    this.tempVisibleSymbols = this.symbolSelector.getSymbolsArray(tempArray);
  }

  public trackBySymbolId(index: any, item: Symbol): number {
    if (!item) { return null; } else {
      return item.SymbolId;
    }
  }

  public drop(event: CdkDragDrop<Symbol[]>) {
    const prevId = this.VisibleSymbols[event.previousIndex].SymbolId;
    const currId = this.VisibleSymbols[event.currentIndex].SymbolId;

    this.symbolSelector.swapSymbols(prevId, currId);
  }
  get VisibleSymbols(): Symbol[] {
    if (this.visibleSymbols !== undefined) {
      return this.visibleSymbols;
    } else {
      return [];
    }
  }

  public get ViewMode(): ViewMode {
    return this.symbolSelector.ViewMode;
  }
  public set ViewMode(v: ViewMode) {
    this.symbolSelector.ViewMode = v;
  }

  // возвращает массив имен всех категорий для группы
  get Category(): any[] {
    return this._uniqueCategories;
  }

  // создает уникальный массив имен для категорий символов торговой группы
  private uniqueCategories() {
    this._uniqueCategories = ['All', 'Favorites'];
    this.tempVisibleSymbols.forEach((e: Symbol) => {
      if (!this._uniqueCategories.includes(e.CategoryName)) {
        this._uniqueCategories.push(e.CategoryName);
      }
    });
  }

  // метод показывает актуальное значение массива символов с учетом всех фильтров
  public changeVisibleSymbols() {
    if (this.CurrentCategory === 'Favorites') {
      const tempArray = this.symbolSelector.getSymbols(this.symbolSearch.Pattern, true);
      this.visibleSymbols = this.symbolSelector.getSymbolsArray(tempArray);
    }

    this.chartService.changeFavoriteSymbols.emit();
  }

  public isZeTradex() {
    return this.appConfig.Settings.common.AppName === 'Zetradex';
  }

  goToBack() {

    if (this.pathTo === 'Full-Market') {
      this.router.navigate(['/terminal/full-market-depth', {symbolId: this.symbolIdTo, closeAvailable: false, chartUrl: '/terminal/charts'}]).then();
    }

    if (this.pathTo === 'Mobile-TV') {
      this.router.navigate(['/terminal/charts', {symbolName: this.symbolNameTo, symbolId: this.symbolIdTo}]).then();
    }
  }

  categoryActive(chipName: string): boolean {
    return chipName === this.CurrentCategory;
  }

  public CheckCategoryList(item: string[], length: number) {
    return item !== undefined && item.length > length && !this.modalWindowName;
  }

  setCategory(categoryName: string) {
    console.log('categoryName', categoryName);
    this.CurrentCategory = categoryName;
    this.symbolSearch.Pattern = '';

    if (categoryName === 'All') {
      this.visibleSymbols = this.tempVisibleSymbols;
    } else if (categoryName === 'Favorites') {
      // this.viewFavorites();
      this.changeVisibleSymbols();
    } else {
      this.visibleSymbols = this.filterSymbolArrayForCategory(categoryName);
    }
  }

  private filterSymbolArrayForCategory( categoryName: string): Symbol[] {
    return this.tempVisibleSymbols.filter((e: Symbol) => e.CategoryName === categoryName);
  }

  private searchSymbols(symbolsArray: Symbol[], pattern: string): Symbol[] {
    return symbolsArray.filter(item =>
      item.SymbolName.toLowerCase().replace(/[^a-zа-я0-9]+/g, '')
        .indexOf(pattern.toLowerCase().replace(/[^a-zа-я0-9]+/g, '')) !== -1);
  }

  set CurrentCategory(activeChipName: string) {
    this._currentCategory = activeChipName;
  }

  get CurrentCategory(): string {
    return this._currentCategory;
  }

  setSymbolToPath(symbol: Symbol) {

    if (this.pathTo === 'Full-Market') {
      this.router.navigate(['/terminal/full-market-depth', {symbolId: symbol.SymbolId, closeAvailable: false, chartUrl: '/terminal/charts'}]).then();
    }

    if (this.pathTo === 'Mobile-TV') {
      this.router.navigate(['/terminal/charts', {symbolName: symbol.SymbolName, symbolId: symbol.SymbolId}]).then();
    }

    if (this.pathTo === 'TradingViewDesktop') {
      this.chartService.setSymbol(symbol.SymbolName).then();
      this.router.navigate(['/terminal', {outlet: {modal: null}}]).then();
    }
  }

  get IsTradingViewDesktop(): boolean {
    return this.modalWindowName === 'TradingViewDesktop';
  }

  private createPlaceholderSearch(): void {
    this._placeholderSearch = translate('Quotes_QuotesComponent_SearchForAssets');
  }

  public get PlaceholderSearch(): string {
    return this._placeholderSearch;
  }

  public translateSymbolsCategories(category: string, isToUpperCase: boolean = false): string {
    let categoryTranslate = translate(`Quotes_QuotesComponent_${category}`);
    if (categoryTranslate === undefined) {
      categoryTranslate = category;
    }
    return isToUpperCase ? categoryTranslate.toUpperCase() : categoryTranslate;
  }
}


