export const CurrencyConfig = {
  eur: {
    icon: '€',
    pattern: '{amount} €'
  },
  usd: {
    icon: '$',
    pattern: '$ {amount}'
  }
};
