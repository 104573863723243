<div>
  <button class="disable-connection-button" (click)="disableConnection()">Disable Connection</button>
  <button class="enable-connection-button" (click)="enableConnection()">Enable Connection</button>
  <button class="disconnect-button" (click)="disconnect()">Disconnect</button>
  <button class="reset" (click)="reset()">Reset</button>
  <button (click)="toggle()">Toggle Board</button>
</div>
<div *ngIf="ShowDashboard">
  <hr/>

  Events
  <div>
    <span>
      Emitters Count: {{EmittersCount}}
    </span>
    |
    <span>
      Subs Count: {{SubscriptionsCount}}
    </span>
    |
    <span>
      Subs Per Emitter: {{MiddleSubscriptionsCount | decimal}}
    </span>
    |
    <span>
      Events Per Second: {{EventsPerSecond | decimal}}
    </span>
  </div>

  Timing
  <div>
    <span>
      Total Calls: {{CallsCount}}
    </span>
    |
    <span>
      Total Exec Time: {{TotalTime | decimal}} ms
    </span>
    |
    <span>
      Middle Exec Time: {{MiddleTime | decimal}} ms
    </span>
    |
    <span>
      Scan Time: {{ElapsedSeconds | decimal}} s
    </span>
  </div>

  Trades
  <div>
    <span>
      Total Count: {{TradesCount}}
    </span>
  </div>

  Symbols
  <div>
    <span>
      Total Count: {{SymbolsCount}}
    </span>
  </div>
</div>
