<div class="page-container">
  <mat-grid-list cols="1" rowHeight="100%">
    <mat-grid-tile [colspan]="1" [rowspan]="1">
      <div class="agreement-container">
        <mat-grid-list cols="1" rowHeight="10%" class="agreement-background-color">
          <mat-grid-tile [colspan]="1" [rowspan]="3" class="header-border">
            <span class="title-text">Agreement</span>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <div class="agreement-text-container">
              <span class="agreement-text">
                Please review and accept the terms and conditions
              </span>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <div class="agreement-url-container">
              <span class="agreement-text">Terms of Use: <a href="/pdf" target="_blank">agreement.pdf</a></span>
            </div>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="2">
            <button class="button button-success c-button" (click)="agreeClick()">Agree</button>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1" [rowspan]="1">

          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
