import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {StopType} from '../../utils/calculations/sltp/stop-type';
import {TradeBound, TradeBoundFactory} from '../../models/trade-bound';
import {TradeDTO} from '../../models/trade-d-t-o';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {StopsCorrector} from '@common/trade/utils/calculations/stops.corrector';
import {Accounting} from '@common/trader/models/accounting.service';
import {Observable} from 'rxjs';
import {AppConfig} from '@common/configuration/app-config';
import {Settings} from '@common/trader/models/settings';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Environment} from '@common/environment';
import {LocaleModule} from '@common/locale/locale.module';
import {SharedModule} from '@common/shared/shared.module';
import {TradeModule} from '@common/trade/trade.module';
import {NgClass, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UserInterfaceModule} from '@common/user-interface/user-interface.module';

@Component({
  selector: 'app-sltp-input',
  templateUrl: './sltp-input.component.html',
  styleUrls: ['./sltp-input.component.scss'],
  standalone: true,
  imports: [
    LocaleModule,
    SharedModule,
    TradeModule,
    NgIf,
    NgClass,
    FormsModule,
    UserInterfaceModule
  ]
})
export class SlTpInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() trade: TradeDTO;
  @Input() price: number;
  @Input() disabled: boolean;

  private validity = new EventEmitter<boolean>();
  public _validityError = new EventEmitter<boolean>();
  public variants: Map<number, string>;

  @Output('validityError')
  public get validityError(): Observable<boolean> {
    return this._validityError;
  }

  private firstClickSL = true;
  private firstClickTP = true;
  private firstClickTS = true;

  private checkTPValidity = false;
  private checkSLValidity = false;
  private checkTSValidity = false;

  private clickZeroingOutTP = false;
  private clickZeroingOutSL = false;
  private clickZeroingOutTS = false;

  private isHiddenSL = true;
  private isHiddenTP = true;
  private isHiddenTS = true;

  private minStopPipsBreakEven: number;
  private minStopPipsInterrupt: number;
  private pricesBreakEven: number;
  private usePipsBreakEven = false;


  @Output()
  public get StopLossPips(): Observable<boolean> {
    return this.validity.asObservable();
  }

  @Input() appName: string;

  private corrector: StopsCorrector;

  @Input()
  public set stopType(v: StopType) {
    this.chooseStopType(v);
  }
  private _stopType: StopType;

  private stopLoss: TradeBound;
  private takeProfit: TradeBound;
  private trailingStop = 0;


  public get TrailingStop(): number {
    return this.trailingStop;
  }
  public set TrailingStop(v: number) {
    this.trailingStop = v;
    this.trade.TrailingStop = v;
    this.onChangeTS(v);
    this.clickZeroingOutTS = false;
    this.checkTSValidity = true;
    this._validityError.emit(this.checkSLTTValidity());
  }

  public get StopLoss(): number  {
    if (!this.stopLoss) { return 0; }
    return this.stopLoss.NativeValue;
  }
  public set StopLoss(s: number) {
    let v = s;
    if (this.StopType === 1 && this.UsePipsBreakEven) {
      v = v - v * 2;
    }

    console.log('usePipsBreakEven', this.UsePipsBreakEven);

    if (v == null || v === 0) {
      this.stopLoss.clear();
      return;
    }

    if (this.StopType === 1 && this.trade.StopLoss === 0) {
      this.stopLoss.NativeValue = this.calculationInitialPips(v, 'SL');
    } else {
      this.stopLoss.NativeValue = v;
    }

    this.clickZeroingOutSL = false;
    this.trade.StopLoss = this.stopLoss.Price;
    this.checkSLValidity = true;
    this._validityError.emit(this.checkSLTTValidity());
    this.usePipsBreakEven = false;
  }
  public get SLValidity(): boolean {

    if (this.clickZeroingOutSL) {
      return true;
    }

    if (this.UsePipsBreakEven && this.StopType === 1) {
      return true;
    }

    if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
      if (this.checkSLValidity && this.SL.Price < this.trade.OpenPrice) {
        return this.SL.Price > this.price;
      }
      return this.SL.Pips >= this.minStopPipsInterrupt
        || this.SL.Pips === 0 || this.SL.Price === 0;
    } else {
      if (this.checkSLValidity && this.SL.Price > this.trade.OpenPrice) {
        return this.SL.Price < this.price;
      }
      return this.SL.Pips >= this.minStopPipsInterrupt
        || this.SL.Pips === 0 || this.SL.Price === 0;
    }

  }

  public get TSValidity(): boolean {

    if (this.clickZeroingOutTS) {
      return true;
    }

    if (this.checkTSValidity && this.TrailingStop > 0 ) {
      return this.TrailingStop >= this.minStopPipsInterrupt;
    }

    return this.TrailingStop === 0;

  }

  public get TakeProfit(): number {
    if (!this.takeProfit) { return 0; }
    return this.takeProfit.NativeValue;
  }
  public set TakeProfit(v: number) {
    if (v === 0) {
      this.takeProfit.clear();
      return;
    }

    if (this.StopType === 1 && this.trade.TakeProfit === 0) {
      this.takeProfit.NativeValue = this.calculationInitialPips(v, 'TP');
    } else {
      this.takeProfit.NativeValue = v;
    }

    this.clickZeroingOutTP = false;
    this.trade.TakeProfit = this.takeProfit.Price;
    this.checkTPValidity = true;
    this._validityError.emit(this.checkSLTTValidity());
  }

  public get TPValidity(): boolean {
    if (this.clickZeroingOutTP) {
      return true;
    }

    if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
      if (this.checkTPValidity && this.TP.Price > this.trade.OpenPrice) {
        return false;
      }
      return this.TP.Pips >= this.minStopPipsInterrupt
        || this.TP.Pips === 0 || this.TP.Price === 0;
    }

    if (this.checkTPValidity && this.TP.Price < this.trade.OpenPrice) {
      return false;
    }

    return this.TP.Pips >= this.minStopPipsInterrupt
      || this.TP.Pips === 0 || this.TP.Price === 0;
  }

  public get StopType(): StopType {
    return this._stopType;
  }

  public get SL(): TradeBound {
    return this.stopLoss;
  }

  public get TP(): TradeBound {
    return this.takeProfit;
  }

  public get TS(): number {
    return this.trailingStop;
  }

  constructor(
    private settings: Settings,
    private translator: TranslatorService,
    private changeDetector: ChangeDetectorRef,
    private accounting: Accounting,
    private config: AppConfig) {

    this.variants = new Map<number, string>([
        [StopType.ByPrice, this.translator.getLocalPhrase('TradeModule_SlTpInputComponent_RateRadioLabel')],
        [StopType.ByPips, this.translator.getLocalPhrase('TradeModule_SlTpInputComponent_PipsRadioLabel')],
        // [StopType.ByProfit, this.translator.getLocalPhrase('TradeModule_SlTpInputComponent_ProfitRadioLabel')]
      ]
    );
  }

  public get IncrementStep(): number {
    return this._stopType === StopType.ByPrice ? this.trade.Symbol.PipSize / 10 : 0.1;
  }

  ngOnInit() {
    sessionStorage.setItem('PageModify', JSON.stringify(true));

    this.corrector = new StopsCorrector(this.trade);

    if (this._stopType === undefined) {
      this.setDefaultType();
    }

    this.setDefaultValues();
    this.changeDetector.detectChanges();

    if (this.config.Settings.trading ) {
      if ( this.config.Settings.trading.MinStopPipsBreakEven ) {
        this.minStopPipsBreakEven = this.config.Settings.trading.MinStopPipsBreakEven;
      } else {
        this.minStopPipsBreakEven = 0;
      }
      if (this.config.Settings.trading.MinStopPipsInterrupt) {
        this.minStopPipsInterrupt = this.config.Settings.trading.MinStopPipsInterrupt;
      } else {
        this.minStopPipsInterrupt = 0;
      }
    }

    if (this.trade && this.trade.Type !== undefined) {
      if (this.trade.Type === 0) {
        this.pricesBreakEven = this.trade.OpenPrice - this.minStopPipsBreakEven * this.trade.Symbol.PipSize;
      }

      if (this.trade.Type === 1) {
        this.pricesBreakEven = this.minStopPipsBreakEven * this.trade.Symbol.PipSize + this.trade.OpenPrice;
      }
    }
  }

  public onSLBlur() {
    if (this.stopLoss.IsClear) {
      return;
    }
    this.correctSL();
    this.trade.StopLoss = this.stopLoss.Price;
  }

  public onTPBlur() {
    if (this.takeProfit.IsClear) {
      return;
    }
    this.correctTP();
    this.trade.TakeProfit = this.takeProfit.Price;
  }

  public onTSBlur() {
    this.trade.TrailingStop = this.trailingStop;
  }

  public onChangeTS(v: number) {
    this.trailingStop = Number(v);
  }

  private correctTP() {
    this.takeProfit.Price = this.corrector.correctTP(this.takeProfit.Price, this.takeProfit.Pips);
  }

  private correctSL() {
    this.stopLoss.Price = this.corrector.correctSL(this.stopLoss.Price, this.stopLoss.Pips);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['price'].firstChange) { return; }

    this.onPriceChange();
  }

  private onPriceChange() {
    this.trade.StopLoss = this.stopLoss.Price;
    this.trade.TakeProfit = this.takeProfit.Price;
  }

  public chooseStopType(type: StopType) {
    this._stopType = type;

    this.stopLoss = TradeBoundFactory.GetBound(type, this.trade, this.accounting, false);

    if (this.trade.StopLoss) {
      this.stopLoss.Price = this.trade.StopLoss;
    }

    this.takeProfit = TradeBoundFactory.GetBound(type, this.trade, this.accounting, true);

    if (this.trade.TakeProfit) {
      this.takeProfit.Price = this.trade.TakeProfit;
    }

    this.usePipsBreakEven = false;
    this.trade.UseBreakEven = false;
  }

  ngOnDestroy(): void {
    if (this.trade) {
      this.trade.TakeProfit = 0;
      this.trade.StopLoss = 0;
      this.trade.TrailingStop = 0;
    }
    sessionStorage.removeItem('PageModify');
  }

  private setDefaultValues() {
    if (this.settings.RegularSLTP && this.trade.TakeProfit === 0 && this.trade.StopLoss === 0 && this.trade.TrailingStop === 0
      && this.settings.OneClickTS && this.settings.OneClickTP && this.settings.OneClickSL && this._stopType === StopType.ByPips) {

      const settings = this.settings;

      this.TakeProfit = settings.OneClickTP;
      this.StopLoss = settings.OneClickSL;
      this.TrailingStop = settings.OneClickTS;
    } else {
      this.TP.Price = this.trade.TakeProfit;
      this.SL.Price = this.trade.StopLoss;
    }

    if (this.trade.TrailingStop) {
      this.trailingStop = this.trade.TrailingStop;
    }
  }
  private setDefaultType() {
    this.chooseStopType(StopType.ByPrice);
  }

  // при модификации ордера, если SL и TP не выставлены, при клике на инпут выставляется минимальное значение для SL и TP
  // 1в зависимости от типа ордера

  public onFocus(volume: string) {
    if (volume === 'SL' && this.firstClickSL && this.trade.StopLoss === 0 ) {
      let priceSL: number;
      if (this.trade.Type === 1 || this.trade.Type === 4 || this.trade.Type === 5) {
        priceSL = this.trade.OpenPrice - (this.settings.OneClickSL * this.trade.Symbol.PipSize);
      } else {
        priceSL = this.trade.OpenPrice + (this.settings.OneClickSL * this.trade.Symbol.PipSize);
      }

      this.firstClickSL = false;
      this.StopLoss = Number(priceSL.toFixed(this.trade.Symbol.DecimalPlaces));

    } else if (volume === 'TP' && this.firstClickTP && this.trade.TakeProfit === 0) {
      let priceTP: number;
      if (this.trade.Type === 1 || this.trade.Type === 4 || this.trade.Type === 5) {
        priceTP = this.trade.OpenPrice + (this.settings.OneClickTP * this.trade.Symbol.PipSize);
      } else {
        priceTP = this.trade.OpenPrice - (this.settings.OneClickTP * this.trade.Symbol.PipSize);
      }

      this.firstClickTP = false;
      this.TakeProfit = Number(priceTP.toFixed(this.trade.Symbol.DecimalPlaces));
    } else if (volume === 'TS' && this.firstClickTS && this.trade.TrailingStop === 0) {
      this.firstClickTS = false;
      this.TrailingStop = this.settings.OneClickTS;
    }

    switch (volume) {
      case 'SL':
        this.isHiddenSL = false;
        break;
      case 'TP':
        this.isHiddenTP = false;
        break;
      case 'TS':
        this.isHiddenTS = false;
        break;
    }
  }

  public getOpenPrice() {
    return this.trade.OpenPrice;
  }


  public getProfit(value: number, currentPrice: number, type: string, typePips = true) {
    let pips = true;
    let profit: number;

    if (typePips) {
      if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
        pips = type === 'TP' ? currentPrice < this.trade.OpenPrice : (currentPrice > this.trade.OpenPrice || currentPrice > this.price);
      } else {
        pips = type === 'TP' ? currentPrice > this.trade.OpenPrice : (currentPrice < this.trade.OpenPrice || currentPrice < this.price);
      }
    }

    if (pips) {
      if (this.settings.UseLotTrading) {
        profit = OperationsWithVolume.convertFromVolumeToLots(value, this.trade.Symbol.ContractSize);
      } else {
        profit = value;
      }

      if (type === 'SL') {
        if (this.UsePipsBreakEven && !typePips && (this.trade.Type === 0 || this.trade.Type === 1)) {
          return OperationsWithVolume.checkVolume(profit);
        }

        if ((this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) && currentPrice > this.trade.OpenPrice) {
          return profit - 2 * profit;
        }
        if (this.trade.Type === 1 || this.trade.Type === 4 || this.trade.Type === 5) {
          if (this.StopType === 0 && currentPrice < this.trade.OpenPrice) {
            return profit - 2 * profit;
          }
          if (this.StopType === 1 && currentPrice > 0) {
            return profit - 2 * profit;
          }

        }
      }

      return OperationsWithVolume.checkVolume(profit);
    }
    return 0;
  }

  public getPips(value: number, currentPrice: number, type: string) {
    if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
      if (type === 'TP' && currentPrice < this.trade.OpenPrice) {
        return value;
      } else if (type === 'SL') {
        if (currentPrice > this.trade.OpenPrice) {
          return value - 2 * value;
        }
        if (currentPrice < this.trade.OpenPrice && currentPrice > this.price) {
          return value;
        }
      }
    } else {
      if (type === 'TP' && currentPrice > this.trade.OpenPrice) {
        return value;
      } else if (type === 'SL') {
        if (currentPrice < this.trade.OpenPrice) {
          return value - 2 * value;
        }
        if (currentPrice > this.trade.OpenPrice && currentPrice < this.price) {
          return value;
        }
      }
    }
    return 0;
  }

  private checkSLTTValidity(): boolean {
    if (!this.SLValidity ) {
      return true;
    } else if (!this.TSValidity) {
      return true;
    }
    return !this.TPValidity;
  }

  // при не установленных Sl и TP высчитывается и устанавливается дефолтное значение
  private calculationInitialPips(v: number, type: string): number {
    let pips = 0;
    if (type === 'SL') {
      if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
        pips = (v - this.trade.OpenPrice) / this.trade.Symbol.PipSize;
      } else {
        pips = (this.trade.OpenPrice - v) / this.trade.Symbol.PipSize;
      }
    } else if (type === 'TP') {
      if (this.trade.Type === 0 || this.trade.Type === 2 || this.trade.Type === 3) {
        pips = (this.trade.OpenPrice - v) / this.trade.Symbol.PipSize;
      } else {
        pips = (v - this.trade.OpenPrice) / this.trade.Symbol.PipSize;
      }
    }

    return Number(pips.toFixed(2));

  }

  public zeroingOut(event: any, type: string, v = 0) {
    event.preventDefault();
    if (event.pointerType === 'mouse' || event.pointerType === 'touch' || Environment.IsMobileVersion) {

      if (type === 'SL') {

        this.clickZeroingOutSL = true;
        this.usePipsBreakEven = false;
        this.trade.UseBreakEven = false;
        this.isHiddenSL = true;
        this.stopLoss.NativeValue = v;
        this.trade.StopLoss = v;

      } else if (type === 'TP') {

        this.clickZeroingOutTP = true;
        this.isHiddenTP = true;
        this.takeProfit.NativeValue = v;
        this.trade.TakeProfit = v;

      } else if (type === 'TS') {

        this.clickZeroingOutTS = true;
        this.checkTSValidity = false;
        this.isHiddenTS = true;
        this.TrailingStop = v;

      }

      this._validityError.emit(this.checkSLTTValidity());
    }
  }

  public getIsHidden(volume: number, type: string): boolean {
    if (volume === 0 ) {
      if (type === 'SL') {
        return this.isHiddenSL;
      } else if (type === 'TP') {
        return this.isHiddenTP;
      } else if (type === 'TS') {
        return this.isHiddenTS;
      }
    }
    return false;
  }

  isBlur(type: string) {
    switch (type) {
      case 'SL':
        this.isHiddenSL = true;
        break;
      case 'TP':
        this.isHiddenTP = true;
        break;
      case 'TS':
        this.isHiddenTS = true;
        break;
    }
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  get ValidBtnBreakEven(): boolean {
    if (this.trade.Type === 0) {
      return this.price > this.pricesBreakEven;
    }

    if (this.trade.Type === 1) {
      return this.price < this.pricesBreakEven;
    }

    return true;
  }

  get IsShowBtnBreakEven(): boolean {
    return this.trade.Type === 0 || this.trade.Type === 1;
  }

  public getClassShowBtnBreakEven(): string {
    if (this.IsShowBtnBreakEven) {
      if (Environment.IsMobileVersion) {
        return 'profit-width-with-be-is-mobile';
      }
      return 'profit-width-with-be';
    }
  }

  public get UsePipsBreakEven(): boolean {
    return this.usePipsBreakEven;
  }

  public breakEvenClick(event) {

    if (event.pointerType === 'mouse' || event.pointerType === 'touch') {
      if (this._stopType === 1) {
        this.stopLoss.NativeValue = -this.minStopPipsBreakEven;
        this.usePipsBreakEven = true;
        this.clickZeroingOutSL = false;
      } else {
        this.stopLoss.NativeValue = this.pricesBreakEven;
      }

      this.trade.UseBreakEven = true;
      this.trade.StopLoss = this.pricesBreakEven;
      this._validityError.emit(this.checkSLTTValidity());
    }
  }

}
