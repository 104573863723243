<app-modal-placeholder [header]="Header" (onClose)="close()">
  <div class="app-modal-mobile-header-container">
    <div class="title-right">
    </div>
  </div>
  <div class="c-form-container">
    <form name="orderForm" action="#" [formGroup]="pendingForm" class="c-form order-form highlited-inputs" (ngSubmit)="submit()">

      <div class="sector-header">
        <span>
          <l-unit [key]="'Modal_PendingOrderFormComponent_OrderParametersTitle'"></l-unit>
        </span>
      </div>

      <div class="container">
        <div class="first-column column">
          <div class="c-form-group">
            <label for="selected-symbol" class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_SymbolTitle'"></l-unit></label>
            <select name="selected-symbol" (change)="onSymbolChange($event.target.value)" id="selected-symbol" class="c-select" formControlName="symbol">
              <option *ngFor="let symbol of Symbols; let i = index" [value]="i">{{symbol.SymbolName}}</option>
            </select>
          </div>

          <div class="c-form-group">
            <label for="pending-type" class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_TypeTitle'"></l-unit></label>
            <select name="pending-type" (change)="onTypeChange($event.target.value)" id="pending-type" class="c-select" formControlName="pending-type">
              <option *ngFor="let type of types" [value]="type.value">{{type.name}}</option>
            </select>
          </div>

          <div class="c-form-group exp-date-group" [class.incorrect]="expDate.invalid && expDate.dirty">
            <label for="expDate" class="c-label">
              <l-unit [key]="'Modal_PendingOrderFormComponent_ExpirationDateTitle'"></l-unit>
            </label>
            <input id="expDate" class="c-input" name="expirationDate" type="datetime-local" [min]="CurrentDate" formControlName="expiration-date" (change)="onDateChange($event.target.value)"/>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectDateError'"></l-unit>
            </span>
          </div>
        </div>
        <div class="second-column column">
          <div class="c-form-group" [class.incorrect]="amount.invalid && amount.dirty">
            <label class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_AmountTitle'"></l-unit></label>
            <number-input _step="1" name="volume" (changeObservable)="onAmountChange($event.target.value)"
                          formControlName="amount"></number-input>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectAmountError'"></l-unit>
            </span>
          </div>

          <div class="c-form-group rate-group" [class.incorrect]="rate.invalid && type.dirty">
            <label class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_RateTitle'"></l-unit></label>
            <number-input [step]="Step" name="rate" (changeObservable)="onRateChange($event.target.value)"
                          formControlName="open-price"></number-input>
            <span class="error">
              <l-unit [key]="'Modal_PendingOrderFormComponent_IncorrectPriceError'"></l-unit>
            </span>
          </div>

          <div class="c-form-group">
            <label class="c-label"><l-unit [key]="'Modal_PendingOrderFormComponent_CurrentPriceTitle'"></l-unit>: </label>
            <div class="current-price-value"><span>{{CurrentRate}}</span></div>
          </div>
        </div>

      </div>

      <div class="sector-header">
        <span>
          <l-unit [key]="'Modal_PendingOrderFormComponent_StopOrdersTitle'"></l-unit>
        </span>
      </div>

      <div class="container">
          <app-sltp-input [trade]="Order" [price]="CurrentRate"></app-sltp-input>
      </div>

      <red-alert class="alert" *ngIf="Error">
        {{Error}}
      </red-alert>

      <div style="width: 100%; text-align: center">
        <input type="submit" class="c-button c-form-button-submit order-button" [value]="'Modal_PendingOrderFormComponent_SubmitButton' | translation"  [attr.disabled]=" pendingForm.invalid ? 'disabled' : null" />
      </div>
    </form>
  </div>
</app-modal-placeholder>
