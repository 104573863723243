<div class="date-top">
  <div class="left-button month-button" (click)="onPrevMonthClick()">

  </div>
  <div class="date">
    <span class="day">{{DayPreview}}</span><span class="month-and-year">{{MonthAndYearPreview}}</span>
  </div>
  <div class="right-button month-button" (click)="onNextMonthClick()">

  </div>
</div>
<div class="days">
  <div>
    Mo
  </div>
  <div>
    Tu
  </div>
  <div>
    We
  </div>
  <div>
    Th
  </div>
  <div>
    Fr
  </div>
  <div>
    Sa
  </div>
  <div>
    Su
  </div>
</div>
<div class="dates">
  <div class="week" *ngFor="let week of weeks">
    <div class="date" *ngFor="let day of week" [class.inactive]="lessThenToday(day)" [class.prev-month]="isPrevMonthDate(day)" [class.next-month]="isNextMonthDate(day)" (click)="onDateClick(day)">
      <span *ngIf="isSelected(day)" class="selected">
      </span>
      <span *ngIf="isToday(day)" class="today">
      </span>
      {{day.getDate()}}
    </div>
  </div>
</div>
