import {AbstractMessageProcessor} from './abstract-message-processor';
import {Answer_CustomSessionError, Answer_LoginSuccess} from '../../connection/classes.g';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {AuthenticationService} from '../../../auth/services/authentication.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionMessageProcessor extends AbstractMessageProcessor {
  public constructor(serverConnection: ServerInteractionService, private authService: AuthenticationService){
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToSessionErrors();
    this.subscribeToLoginSuccess();
  }

  private subscribeToSessionErrors(): void {
    const subscription = this.serverConnection.on(Answer_CustomSessionError.GetMessageType(),
      (answer) => {
      console.log('answer', 23, answer);
      this.onSessionError(answer);
    });

    this.saveSubscription(Answer_CustomSessionError.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Answer_CustomSessionError.GetMessageType()}`);
  }

  private onSessionError(answer: Answer_CustomSessionError): void {
    this.authService.onLoginError(Number(answer.Error.Code));
    this.logger.error('handle', answer.Error.Message);
  }

  private subscribeToLoginSuccess(): void {
    const subscription = this.serverConnection.on(Answer_LoginSuccess.GetMessageType(),
      (answer) => this.onLoginSuccess(answer)
    );

    this.saveSubscription(Answer_LoginSuccess.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Answer_LoginSuccess.GetMessageType()}`);
  }

  private onLoginSuccess(answer: Answer_LoginSuccess): void {
    // console.log('SessionMessageProcessor / onLoginSuccess', 23, answer);
    this.authService.onLoginSuccess(answer);
  }
}
