export class MailMessage {
  private _from: string;
  private _isNews: boolean;
  private _mailId: number;
  private _sendTime: Date;
  private _senderLoginName: string;
  private _serverTime: Date;
  private _subject: string;
  private _isRead: boolean;

  get IsRead(): boolean {
    return this._isRead;
  }

  get From(): string {
    return this._from;
  }

  get IsNews(): boolean {
    return this._isNews;
  }

  get MailId(): number {
    return this._mailId;
  }

  get SendTime(): Date {
    return this._sendTime;
  }

  get SenderLoginName(): string {
    return this._senderLoginName;
  }

  get ServerTime(): Date {
    return this._serverTime;
  }

  get Subject(): string {
    return this._subject;
  }

  constructor(from: string,
              isNews: boolean,
              mailId: number,
              sendTime: Date,
              senderLoginName: string,
              serverTime: Date,
              subject: string,
              isRead: boolean) {
    this._from = from;
    this._isNews = isNews;
    this._mailId = mailId;
    this._sendTime = sendTime;
    this._senderLoginName = senderLoginName;
    this._serverTime = serverTime;
    this._subject = subject;
    this._isRead = isRead;
  }
}
