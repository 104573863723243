import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {AppConfig} from '../../configuration/app-config';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta) {
    this.initializeDefaults();
  }

  private initializeDefaults() {
    // Common
    this.meta.addTag({ name: 'description', content: AppConfig.settings.common.AppName });
    this.meta.addTag({ name: 'author', content: AppConfig.settings.common.CompanyName });
    // OpenGraph
    this.meta.addTag({ property: 'og:title', content: AppConfig.settings.common.AppName });
    this.meta.addTag({ property: 'og:description', content: AppConfig.settings.common.AppName + ' ' + AppConfig.settings.common.CompanyName});
    this.meta.addTag({ property: 'og:image', content: this.getImageUrl() });
    this.meta.addTag({ property: 'og:url', content: this.getSiteUrl() });
    this.meta.addTag({ property: 'og:site_name', content: AppConfig.settings.common.AppName});
    // Twitter
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:image:alt', content: AppConfig.settings.common.AppName });
  }

  private getImageUrl(): string {
    return 'http://webtrader.demo.condor-fx.com:800/assets/img/site_card.png'; // TODO: Move to config
  }

  private getSiteUrl(): string {
    return 'http://webtrader.demo.condor-fx.com:800/'; // TODO: Move to config
  }
}
