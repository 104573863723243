import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
  selector: '[one-lower-case]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: OneLowerCaseValidator,
    multi: true
  }]
})
export class OneLowerCaseValidator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const check = /[a-z]/.test(control.value);
    return check ? null : {
      haventLowerCase : true
    }
  }
}
