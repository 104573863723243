import {ServerInteractionService} from '../connection/server-interaction.service';
import {ProtoV2MessageBase} from '../connection/proto-v2-message-base';
import {ICommandPromise} from '../connection/command-answer-service';
import {Message_CustomCommand} from '@common/communication/connection/classes.g';

export abstract class AbstractCommandSender {
  protected constructor(private serverConnection: ServerInteractionService) {}

  protected sendCommand(command: Message_CustomCommand): ICommandPromise {
    return this.serverConnection.sendCommand(command);
  }
  protected sendMessage(command: ProtoV2MessageBase): ICommandPromise {
    return this.serverConnection.sendMessage(command)
  }
}
