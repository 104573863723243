import {Symbol} from '../../../symbol/models/symbol';
import {isBuyTrade, TradeType} from '../../models/trade-type';
import {TradeStateSnapshot} from '../../models/trade-state-snapshot';
import {TradeProfitSign} from '../../models/trade-profit-sign';
import {ProfitCalculator} from './profit-calculator';
import {StateType} from '../../models/trade-state-enum';
import {DateField} from '../../../common/models/date-field';
import {JsonProperty} from '@common/shared/utils/json-decorators';

export class TradeCalculateObject {
  @JsonProperty(ProfitCalculator)
  private _profitCalculator: ProfitCalculator;

  public wasInited = false;

  private _isTemp = false; // if true volume must be replaced in future

  private _tradeId: number;

  private _symbol: Symbol;

  private _openTime: Date;

  private _closeTime: Date;

  private _type: TradeType;

  private _volume = 0;

  private _stopLoss: number;

  private _takeProfit: number;

  private _currentPrice: number;

  private _openPrice: number;

  private _rollover: number = 0;

  private _closePrice: number;

  private _transactionFee: number;

  private _subaccountFee: number;

  private _margin: number;

  private _trailingStop: number;

  private _trailingStopValue: number;

  private _expirationDate: DateField;

  private _commandMargin: number;

  private _commandVolume: number;

  private _commandVolumeUSD: number;

  private _commandVolumeAC: number;

  private _volumeUSD: number;

  private _volumeAC: number;


  private _sign: TradeProfitSign;
  public get Sign(): TradeProfitSign {
    return this._sign;
  }

  private _comment: string;

  private _upl = 0;

  private _profit: number;

  public constructor() {
    this._expirationDate = new DateField();
  }

  public recalculate(state: TradeStateSnapshot): void {
    if (!this.wasInited) {
      this.setFromState(state);
      this.wasInited = true;
    } else {
      this.modifyFromState(state);
    }
  }

  private setFromState(state: TradeStateSnapshot): void {
    this._tradeId = state.OrderId;
    this._symbol = state.Symbol;
    this.modifyVolume(state);
    this.setType(state.OrderType);
    this._openTime = state.CommandTime;
    this._stopLoss = state.StopLoss;
    this._takeProfit = state.TakeProfit;
    this._openPrice = state.OpenPrice;
    this._margin = state.Margin;
    this._expirationDate = state.ExpirationDate;
    this._trailingStop = state.TrailingStop;
    this._trailingStopValue = state.TrailingStopValue;
    this._transactionFee = state.TransactionFee;
    this._subaccountFee = state.SubaccountFee;
    this._rollover += state.Storage;
    this._comment = state.Comment;
    this._profit = state.Profit || 0;
    this._commandMargin = state.CommandMargin;
    this._commandVolume = state.CommandVolume;
    this._commandVolumeUSD = state.CommandVolumeUSD;
    this._commandVolumeAC = state.CommandVolumeAC;
    this._volumeUSD = state.VolumeUSD;
    this._volumeAC = state.VolumeAC;

  }

  private modifyFromState(state: TradeStateSnapshot): void {
    this.modifyVolume(state);
    this._openPrice = state.OpenPrice;
    this._stopLoss = state.StopLoss;
    this._trailingStop = state.TrailingStop;
    this._trailingStopValue = state.TrailingStopValue;
    this._expirationDate = state.ExpirationDate;
    this._takeProfit = state.TakeProfit;
    this._margin += state.CommandMargin;
    this._transactionFee += state.TransactionFee;
    this._subaccountFee += state.SubaccountFee;
    this.setType(state.OrderType);
    this._rollover += state.Storage;
    this._comment = state.Comment;
    this._commandMargin = state.CommandMargin;
    this._commandVolume = state.CommandVolume;
    this._commandVolumeUSD = state.CommandVolumeUSD;
    this._commandVolumeAC = state.CommandVolumeAC;
    this._volumeUSD = state.VolumeUSD;
    this._volumeAC = state.VolumeAC;

    this._profit += state.Profit;

    if(this._volume === 0) {
      this._closeTime = state.CommandTime;
      this._closePrice = state.CommandPrice;
    }
  }

  private setType(type: TradeType) {
    this._type = type;
    this._profitCalculator = new ProfitCalculator(this.Symbol, this.Type);

    if (isBuyTrade(this._type)) {
      this._sign = TradeProfitSign.Positive;
    } else {
      this._sign = TradeProfitSign.Negative;
    }
  }

  private modifyVolume(state: TradeStateSnapshot) {
    if (state.StateType !== StateType.ResumePendingOrder && ! this._isTemp) {
      this._volume = this.calculationForFloatingPointNumbers(this._volume, state);
    } else {
      this._volume = state.Volume;
    }
    this._isTemp = state.StateType === StateType.MarketProviderConfirm;
  }

  public takeSnapshot(): TradeCalculateObject {
    return this.deepCopy();
  }

  private deepCopy(): TradeCalculateObject {
    const copy = new TradeCalculateObject();

    copy.wasInited = true;
    copy._tradeId = this._tradeId;
    copy._symbol = this._symbol;
    copy._openTime = this._openTime;
    copy._closeTime = this._closeTime;
    copy.setType(this._type);
    copy._volume = this._volume;
    copy._stopLoss = this._stopLoss;
    copy._takeProfit = this._takeProfit;
    copy._currentPrice = this._currentPrice;
    copy._openPrice = this._openPrice;
    copy._closePrice = this._closePrice;
    copy._transactionFee = this._transactionFee;
    copy._subaccountFee = this._subaccountFee;
    copy._margin = this._margin;
    copy._trailingStop = this._trailingStop;
    copy._trailingStopValue = this._trailingStopValue;
    copy._comment = this._comment;
    copy._commandMargin = this._commandMargin;
    copy._commandVolume = this.CommandVolume;
    copy._commandVolumeUSD = this.CommandVolumeUSD;
    copy._commandVolumeAC = this.CommandVolumeAC;

    return copy;
  }

  public get TradeId(): number {
    return this._tradeId;
  }

  public get ProfitCalculator(): ProfitCalculator {
    return this._profitCalculator;
  }

  public get Symbol(): Symbol {
    return this._symbol;
  }

  public get OpenTime(): Date {
    return this._openTime;
  }

  public get CloseTime(): Date {
    return this._closeTime;
  }

  public get Type(): TradeType {
    return this._type;
  }

  public get Volume(): number {
    return this._volume;
  }

  public get StopLoss(): number {
    return this._stopLoss;
  }

  public get TakeProfit(): number {
    return this._takeProfit;
  }

  public get CurrentPrice(): number {
    return this._currentPrice;
  }

  public get Rollover(): number {
    return this._rollover;
  }

  public get OpenPrice(): number {
    return this._openPrice;
  }

  public get ClosePrice(): number {
    return this._closePrice;
  }

  public get TransactionFee(): number {
    return this._transactionFee;
  }

  public get SubaccountFee(): number {
    return this._subaccountFee;
  }

  public get TrailingStop(): number {
    return this._trailingStop;
  }

  public get TrailingStopValue(): number {
    return this._trailingStopValue;
  }

  public get Margin(): number {
    return this._margin;
  }

  public get CommandMargin(): number {
    return this._commandMargin;
  }

  public get CommandVolume(): number {
    return this._commandVolume;
  }

  public get CommandVolumeUSD(): number {
    return this._commandVolumeUSD;
  }

  public get CommandVolumeAC(): number {
    return this._commandVolumeAC;
  }

  public get VolumeUSD(): number {
    return this._volumeUSD;
  }

  public get VolumeAC(): number {
    return this._volumeAC;
  }

  public get ExpirationDate(): Date {
    return <Date> this._expirationDate.Date;
  }

  public get Comment(): string {
    return this._comment;
  }

  public get UPL(): number {
    return this._upl;
  }

  public set UPL(v: number) {
    this._upl = v;
  }

  public get Profit(): number {
    return this._profit;
  }

  // метод корректного расчета для значения ордера, чтобы избежать погрешностей для чисел с плавающий запятой

  private calculationForFloatingPointNumbers(volume: number, state: TradeStateSnapshot): number {

    const tradingStep = state.Symbol.TradingStep.toString().split('.');
    let result: number;

    if (tradingStep.length == 1) {
      result = volume + state.CommandVolume;
      return result;
    } else {
      const totalDigitsAfterDecimalPoint = tradingStep[1].split('');
      const multiply = Math.pow(10, totalDigitsAfterDecimalPoint.length);
      result = (volume * multiply + state.CommandVolume * multiply ) / multiply;
      return Number(result.toFixed(totalDigitsAfterDecimalPoint.length));
    }
  }

  // дополнительная проверка полученного значения (т.к. если 0.14 * 100 000 = 14 000.000000002) и идетокрушления либо к боьшему, либо к меньшему числу

  private checkCorrectVolume(volume: number) {

    const number = volume.toString().split('.');
    let digits = Math.abs(volume);

    if (number.length > 1) {

      const secondNumber = number[1].split('');

      if (secondNumber[0] == '0') {
        digits = Math.floor(digits);
      } else {
        digits = Math.ceil(digits);
      }

      if (volume < 0) {
        return digits - digits * 2;
      } else {
        return digits;
      }
    } else {
      return volume;
    }
  }
}
