import {ServerInteractionService} from '../connection/server-interaction.service';
import {Injectable} from '@angular/core';
import {
  Command_GetOpenOrderStates,
  Command_TicketClose,
  Command_TicketDelete,
  Command_TicketOpen,
  Command_TicketUpdatePendingOrder,
  Command_TicketUpdateStops
} from '../connection/classes.g';
import {ICommandPromise} from '../connection/command-answer-service';
import {TradeDTO} from '../../trade/models/trade-d-t-o';
import {AbstractCommandSender} from './abstract-command-sender';
import {ITradePromise, TradePromise} from '@common/trade/models/trade-promise';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {TradeDispatcherService} from '@common/trade/services/trade-dispatcher.service';

@Injectable({
  providedIn: 'root'
})
export class TradeCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger('TradeCommandSender');
  public constructor(serverConnection: ServerInteractionService, private tradeDispatcher: TradeDispatcherService){

    super(serverConnection);
  }

  public loadAll(): ICommandPromise {
    const command = new Command_GetOpenOrderStates();

    return this.sendCommand(command);
  }

  public getTrades(): ICommandPromise {
    return this.sendCommand(new Command_GetOpenOrderStates());
  }

  private createTicketOpenCommand(order: TradeDTO): Command_TicketOpen {
    const command = new Command_TicketOpen();

    command.Volume = order.Volume;
    command.Comment = order.Comment;
    command.ItemID = order.Symbol.SymbolId;
    command.TakeProfit = order.TakeProfit;
    command.StopLoss = order.StopLoss;
    command.OpenPrice = order.OpenPrice;
    command.RequestPrice = order.OpenPrice;
    command.OrderType = order.Type;

    command.ExpirationDate = <any>order.ExpirationDate.NativeDate;
    command.TrailingStop = order.TrailingStop;

    return command;
  }

  public openTradeWithTradePromise(order: TradeDTO, tradePromise: TradePromise): Promise<TradePromise> {
    const command = this.createTicketOpenCommand(order);

    const result = new Promise<TradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log('success2', answer);
          this.saveDealToTradePromise(answer.DealID, tradePromise);
          resolve(tradePromise);
        })
        .failed(message => {
            console.log('reject2', message);
            reject(message);
          }
        );
    });
    return result;
  }

  public openTrade(order: TradeDTO): Promise<ITradePromise> {
    const command = this.createTicketOpenCommand(order);

    console.log('command', 23, command);

    const result = new Promise<ITradePromise>((resolve, reject) => {
      console.log('sending open order command', command);
      this.sendCommand(command)
        .success(answer => {
          console.log('success1', answer);
          resolve(this.saveDeal(answer.DealID));
        })
        .failed(message => {
          console.log('reject1', message);
          reject(message);
        }
        );
    });

    return result;
  }

  private createTicketCloseCommand(trade: TradeDTO): Command_TicketClose {
    const command = new Command_TicketClose();

    command.Volume = trade.Volume;
    command.OrderID = trade.TradeId;
    command.Comment = trade.Comment;
    command.Deviation = 0;
    command.LotsCount = 0;
    command.RequestPrice = trade.CurrentPrice;

    return command;
  }

  public async closeTradeWithTradePromise(trade: TradeDTO, tradePromise: TradePromise): Promise<ITradePromise>  {
    const command = this.createTicketCloseCommand(trade);

    const result = new Promise<ITradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          this.saveDealToTradePromise(answer.DealID, tradePromise);
          resolve(tradePromise);
        })
        .failed(message => reject(message));
    });

    return result;
  }

  public async closeTrade(trade: TradeDTO): Promise<ITradePromise> {
    const command = this.createTicketCloseCommand(trade);
    console.log('command', 23, command);

    const result = new Promise<ITradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(this.saveDeal(answer.DealID)))
        .failed(message => reject(message));
    });

    return result;
  }

  public async deletePendingOrder(trade: TradeDTO, comment: string): Promise<ITradePromise> {
    const command = new Command_TicketDelete();

    command.OrderID = trade.TradeId;
    command.Comment = comment;

    const result = new Promise<ITradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(this.saveDeal(answer.DealID)))
        .failed(message => reject(message));
    });

    return result;
  }

  public async updateTrade(trade: TradeDTO): Promise<ITradePromise> {
    const command = new Command_TicketUpdateStops();

    command.ActualDealType = trade.Type;
    command.Comment = trade.Comment;
    command.OrderID = trade.TradeId;
    command.StopLoss = trade.StopLoss;
    command.TakeProfit = trade.TakeProfit;
    command.TrailingStop = trade.TrailingStop ? trade.TrailingStop : undefined;

    const result = new Promise<ITradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(this.saveDeal(answer.DealID)))
        .failed(message => reject(message));
    });

    return result;
  }

  public async updatePendingOrder(trade: TradeDTO): Promise<ITradePromise> {
    const command = new Command_TicketUpdatePendingOrder();

    command.OpenPrice = trade.OpenPrice;
    command.Volume = trade.Volume;
    command.Comment = trade.Comment;
    command.OrderID = trade.TradeId;
    command.StopLoss = trade.StopLoss;
    command.TakeProfit = trade.TakeProfit;
    command.TrailingStop = trade.TrailingStop ? trade.TrailingStop : undefined;
    command.OrderType = trade.Type;
    command.Volume = trade.Volume;
    command.VolumeDecimalPlaces = trade.Symbol.VolumeDecimalPlaces;

    command.ExpirationDate = <any>trade.ExpirationDate.NativeDate;

    const result = new Promise<ITradePromise>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => resolve(this.saveDeal(answer.DealID)))
        .failed(message => reject(message));
    });

    return result;
  }

  private saveDealToTradePromise(id: number, tradePromise: TradePromise) {
    tradePromise.id = id;
    this.tradeDispatcher.save(tradePromise);
    console.log(this.tradeDispatcher);
  }

  private saveDeal(id: number): ITradePromise {
    id = Number(id);
    const result = new TradePromise();
    result.id = id;
    this.tradeDispatcher.save(result);
    return result;
  }
}
