import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements OnInit {
  @Input()
  public kvMap: Map<number, string>;

  @Input()
  public initial: number = 0;

  @Input() disabled: boolean;

  public get keys(){
    return Array.from(this.kvMap.keys());
  }

  @Output()
  public onSelect: EventEmitter<number> = new EventEmitter<number>();

  public selected: number;

  constructor() { }

  ngOnInit() {
    this.select(this.keys[this.initial]);
  }

  public select(key: number){
    key = Number(key);
    this.selected = key;
    this.onSelect.emit(key);
  }
}
