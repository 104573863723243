import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SymbolIcons {
  private iconsMap: Map<string, string> = new Map<string, string>();

  constructor() {
    this.initMap();
  }

  private static getIconURL(iconName: string): string {
    return './assets/img/symbol-icons/' + iconName + '.svg';
  }

  public getIcon(iconName: string) {
    return this.iconsMap.get(iconName);
  }

  private initMap() {
    // A
    this.iconsMap.set('AUDUSD', SymbolIcons.getIconURL('AUDUSD'));
    this.iconsMap.set('AUD/USD', SymbolIcons.getIconURL('AUDUSD'));
    this.iconsMap.set('AUDCAD', SymbolIcons.getIconURL('AUDCAD'));
    this.iconsMap.set('AUD/CAD', SymbolIcons.getIconURL('AUDCAD'));
    this.iconsMap.set('AUDCHF', SymbolIcons.getIconURL('AUDCHF'));
    this.iconsMap.set('AUD/CHF', SymbolIcons.getIconURL('AUDCHF'));
    this.iconsMap.set('AUDJPY', SymbolIcons.getIconURL('AUDJPY'));
    this.iconsMap.set('AUD/JPY', SymbolIcons.getIconURL('AUDJPY'));
    this.iconsMap.set('AUDNZD', SymbolIcons.getIconURL('AUDNZD'));
    this.iconsMap.set('AUD/NZD', SymbolIcons.getIconURL('AUDNZD'));
    this.iconsMap.set('AUDPLN', SymbolIcons.getIconURL('AUDPLN'));
    this.iconsMap.set('AUD/PLN', SymbolIcons.getIconURL('AUDPLN'));
    this.iconsMap.set('AUDCNH', SymbolIcons.getIconURL('AUDCNH'));
    this.iconsMap.set('AUD/CNH', SymbolIcons.getIconURL('AUDCNH'));
    this.iconsMap.set('AUDSGD', SymbolIcons.getIconURL('AUDSGD'));
    this.iconsMap.set('AUD/SGD', SymbolIcons.getIconURL('AUDSGD'));
    this.iconsMap.set('AUDNOK', SymbolIcons.getIconURL('AUDNOK'));
    this.iconsMap.set('AUD/NOK', SymbolIcons.getIconURL('AUDNOK'));
    this.iconsMap.set('AUDSEK', SymbolIcons.getIconURL('AUDSEK'));
    this.iconsMap.set('AUD/SEK', SymbolIcons.getIconURL('AUDSEK'));
    this.iconsMap.set('AUS_200', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('AUS200', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('AUS200.v', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('AUS200.c', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('AUS200.d', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('1AUS200', SymbolIcons.getIconURL('AUS_200'));
    this.iconsMap.set('AAPL.OQ', SymbolIcons.getIconURL('AAPL.OQ'));
    this.iconsMap.set('AAPL', SymbolIcons.getIconURL('AAPL.OQ'));
    this.iconsMap.set('AIG.N', SymbolIcons.getIconURL('AIG.N'));
    this.iconsMap.set('AIG', SymbolIcons.getIconURL('AIG.N'));
    this.iconsMap.set('AMZN.OQ', SymbolIcons.getIconURL('AMZN.OQ'));
    this.iconsMap.set('AMZN', SymbolIcons.getIconURL('AMZN.OQ'));
    this.iconsMap.set('AXP.N', SymbolIcons.getIconURL('AXP.N'));
    this.iconsMap.set('AXP', SymbolIcons.getIconURL('AXP.N'));
    this.iconsMap.set('ADSGn.DE', SymbolIcons.getIconURL('ADSGn.DE'));
    this.iconsMap.set('AIRF.PA', SymbolIcons.getIconURL('AIRF.PA'));
    this.iconsMap.set('ALVG.DE', SymbolIcons.getIconURL('ALVG.DE'));
    this.iconsMap.set('AAVEUSDT', SymbolIcons.getIconURL('AAVEUSDT'));
    this.iconsMap.set('ADAUSDT', SymbolIcons.getIconURL('ADAUSDT'));
    this.iconsMap.set('ALGOUSDT', SymbolIcons.getIconURL('ALGOUSDT'));
    this.iconsMap.set('ATOMUSDT', SymbolIcons.getIconURL('ATOMUSDT'));
    this.iconsMap.set('AVAXUSDT', SymbolIcons.getIconURL('AVAXUSDT'));
    this.iconsMap.set('AXSUSDT', SymbolIcons.getIconURL('AXSUSDT'));
    this.iconsMap.set('AAVEUSD', SymbolIcons.getIconURL('AAVEUSD'));
    this.iconsMap.set('ADAUSD', SymbolIcons.getIconURL('ADAUSD'));
    this.iconsMap.set('ALGOUSD', SymbolIcons.getIconURL('ALGOUSD'));
    this.iconsMap.set('ATOMUSD', SymbolIcons.getIconURL('ATOMUSD'));
    this.iconsMap.set('AVAXUSD', SymbolIcons.getIconURL('AVAXUSD'));
    this.iconsMap.set('AXSUSD', SymbolIcons.getIconURL('AXSUSD'));
    this.iconsMap.set('AAL.OQ', SymbolIcons.getIconURL('AAL.OQ'));
    this.iconsMap.set('AAL', SymbolIcons.getIconURL('AAL.OQ'));
    this.iconsMap.set('ABNB.OQ', SymbolIcons.getIconURL('ABNB.OQ'));
    this.iconsMap.set('ABNB', SymbolIcons.getIconURL('ABNB.OQ'));
    this.iconsMap.set('ATHM.N', SymbolIcons.getIconURL('ATHM.N'));
    this.iconsMap.set('AIR.PA', SymbolIcons.getIconURL('AIR.PA'));
    this.iconsMap.set('AV.L', SymbolIcons.getIconURL('AV.L'));
    this.iconsMap.set('AZN.L', SymbolIcons.getIconURL('AZN.L'));
    this.iconsMap.set('AZN', SymbolIcons.getIconURL('AZN.L'));
    this.iconsMap.set('AA', SymbolIcons.getIconURL('AA'));
    this.iconsMap.set('ABEV', SymbolIcons.getIconURL('ABEV'));
    this.iconsMap.set('ABT', SymbolIcons.getIconURL('ABT'));
    this.iconsMap.set('ACB', SymbolIcons.getIconURL('ACB'));
    this.iconsMap.set('ADBE', SymbolIcons.getIconURL('ADBE'));
    this.iconsMap.set('ADS', SymbolIcons.getIconURL('ADS'));
    this.iconsMap.set('ADT', SymbolIcons.getIconURL('ADT'));
    this.iconsMap.set('AEP', SymbolIcons.getIconURL('AEP'));
    this.iconsMap.set('AER', SymbolIcons.getIconURL('AER'));
    this.iconsMap.set('AES', SymbolIcons.getIconURL('AES'));
    this.iconsMap.set('AGNC', SymbolIcons.getIconURL('AGNC'));
    this.iconsMap.set('AI', SymbolIcons.getIconURL('AI'));
    this.iconsMap.set('AMAT', SymbolIcons.getIconURL('AMAT'));
    this.iconsMap.set('AMC', SymbolIcons.getIconURL('AMC'));
    this.iconsMap.set('AMD', SymbolIcons.getIconURL('AMD'));
    this.iconsMap.set('AMGN', SymbolIcons.getIconURL('AMGN'));
    this.iconsMap.set('AMGN.OQ', SymbolIcons.getIconURL('AMGN'));
    this.iconsMap.set('AMLP', SymbolIcons.getIconURL('AMLP'));
    this.iconsMap.set('AMRN', SymbolIcons.getIconURL('AMRN'));
    this.iconsMap.set('ANTM', SymbolIcons.getIconURL('ANTM'));
    this.iconsMap.set('APO', SymbolIcons.getIconURL('APO'));
    this.iconsMap.set('AR', SymbolIcons.getIconURL('AR'));
    this.iconsMap.set('ARKK', SymbolIcons.getIconURL('ARKK'));
    this.iconsMap.set('ARNC', SymbolIcons.getIconURL('ARNC'));
    this.iconsMap.set('ATI', SymbolIcons.getIconURL('ATI'));
    this.iconsMap.set('ATVI', SymbolIcons.getIconURL('ATVI'));
    this.iconsMap.set('ATVI.OQ', SymbolIcons.getIconURL('ATVI'));
    this.iconsMap.set('AUY', SymbolIcons.getIconURL('AUY'));
    this.iconsMap.set('APA', SymbolIcons.getIconURL('APA'));

    // B
    this.iconsMap.set('BTCUSD', SymbolIcons.getIconURL('BTCUSD'));
    this.iconsMap.set('BTC/USD', SymbolIcons.getIconURL('BTCUSD'));
    this.iconsMap.set('BTCUSD.b', SymbolIcons.getIconURL('BTCUSD'));
    this.iconsMap.set('BTCUSDc', SymbolIcons.getIconURL('BTCUSD'));
    this.iconsMap.set('1BTCEUR', SymbolIcons.getIconURL('BTCEUR'));
    this.iconsMap.set('1BTCGBP', SymbolIcons.getIconURL('BTCGBP'));
    this.iconsMap.set('1BTCJPY', SymbolIcons.getIconURL('BTCJPY'));
    this.iconsMap.set('BCHUSD', SymbolIcons.getIconURL('BCHUSD'));
    this.iconsMap.set('1BCHBTC', SymbolIcons.getIconURL('BCHBTC'));
    this.iconsMap.set('1BITUSD', SymbolIcons.getIconURL('BITUSD'));
    this.iconsMap.set('BABA.N', SymbolIcons.getIconURL('BABA.N'));
    this.iconsMap.set('BABA', SymbolIcons.getIconURL('BABA.N'));
    this.iconsMap.set('BA.N', SymbolIcons.getIconURL('BA.N'));
    this.iconsMap.set('BA', SymbolIcons.getIconURL('BA.N'));
    this.iconsMap.set('BAC.N', SymbolIcons.getIconURL('BAC.N'));
    this.iconsMap.set('BAC', SymbolIcons.getIconURL('BAC.N'));
    this.iconsMap.set('BAYGn.DE', SymbolIcons.getIconURL('BAYGn.DE'));
    this.iconsMap.set('BBVA.MC', SymbolIcons.getIconURL('BBVA.MC'));
    this.iconsMap.set('BKIA.MC', SymbolIcons.getIconURL('BKIA.MC'));
    this.iconsMap.set('BMWG.DE', SymbolIcons.getIconURL('BMWG.DE'));
    this.iconsMap.set('BNPP.PA', SymbolIcons.getIconURL('BNPP.PA'));
    this.iconsMap.set('BATUSDT', SymbolIcons.getIconURL('BATUSDT'));
    this.iconsMap.set('BCHUSDT', SymbolIcons.getIconURL('BCHUSDT'));
    this.iconsMap.set('BNBUSDT', SymbolIcons.getIconURL('BNBUSDT'));
    this.iconsMap.set('BTCUSDT', SymbolIcons.getIconURL('BTCUSDT'));
    this.iconsMap.set('BNBUSD', SymbolIcons.getIconURL('BNBUSD'));
    this.iconsMap.set('BONDUSD', SymbolIcons.getIconURL('BONDUSD'));
    this.iconsMap.set('BTCBUSD', SymbolIcons.getIconURL('BTCBUSD'));
    this.iconsMap.set('BTTUSD', SymbolIcons.getIconURL('BTTUSD'));
    this.iconsMap.set('BBY.N', SymbolIcons.getIconURL('BBY.N'));
    this.iconsMap.set('BBY', SymbolIcons.getIconURL('BBY.N'));
    this.iconsMap.set('BIDU.OQ', SymbolIcons.getIconURL('BIDU.OQ'));
    this.iconsMap.set('BIDU', SymbolIcons.getIconURL('BIDU.OQ'));
    this.iconsMap.set('BIIB.OQ', SymbolIcons.getIconURL('BIIB.OQ'));
    this.iconsMap.set('BIIB', SymbolIcons.getIconURL('BIIB.OQ'));
    this.iconsMap.set('BRKb.N', SymbolIcons.getIconURL('BRKb.N'));
    this.iconsMap.set('BRK.B', SymbolIcons.getIconURL('BRKb.N'));
    this.iconsMap.set('BYND.OQ', SymbolIcons.getIconURL('BYND.OQ'));
    this.iconsMap.set('BYND', SymbolIcons.getIconURL('BYND.OQ'));
    this.iconsMap.set('BA.L', SymbolIcons.getIconURL('BA.L'));
    this.iconsMap.set('BARC.L', SymbolIcons.getIconURL('BARC.L'));
    this.iconsMap.set('BATS.L', SymbolIcons.getIconURL('BATS.L'));
    this.iconsMap.set('BP.L', SymbolIcons.getIconURL('BP.L'));
    this.iconsMap.set('BP', SymbolIcons.getIconURL('BP.L'));
    this.iconsMap.set('BT-A.L', SymbolIcons.getIconURL('BT-A.L'));
    this.iconsMap.set('BLDP', SymbolIcons.getIconURL('BLDP'));
    this.iconsMap.set('BMA', SymbolIcons.getIconURL('BMA'));
    this.iconsMap.set('BMY', SymbolIcons.getIconURL('BMY'));
    this.iconsMap.set('BNGO', SymbolIcons.getIconURL('BNGO'));
    this.iconsMap.set('BSX', SymbolIcons.getIconURL('BSX'));
    this.iconsMap.set('BAP', SymbolIcons.getIconURL('BAP'));
    this.iconsMap.set('BAX', SymbolIcons.getIconURL('BAX'));
    this.iconsMap.set('BB', SymbolIcons.getIconURL('BB'));
    this.iconsMap.set('BBBY', SymbolIcons.getIconURL('BBBY'));
    this.iconsMap.set('BBD', SymbolIcons.getIconURL('BBD'));
    this.iconsMap.set('BCH', SymbolIcons.getIconURL('BCH'));
    this.iconsMap.set('BCS', SymbolIcons.getIconURL('BARC.L'));
    this.iconsMap.set('BILI', SymbolIcons.getIconURL('BILI'));
    this.iconsMap.set('BK', SymbolIcons.getIconURL('BK'));
    this.iconsMap.set('BKR', SymbolIcons.getIconURL('BKR'));

    // C
    this.iconsMap.set('CADJPY', SymbolIcons.getIconURL('CADJPY'));
    this.iconsMap.set('CAD/JPY', SymbolIcons.getIconURL('CADJPY'));
    this.iconsMap.set('CADCHF', SymbolIcons.getIconURL('CADCHF'));
    this.iconsMap.set('CAD/CHF', SymbolIcons.getIconURL('CADCHF'));
    this.iconsMap.set('CADSGD', SymbolIcons.getIconURL('CADSGD'));
    this.iconsMap.set('CAD/SGD', SymbolIcons.getIconURL('CADSGD'));
    this.iconsMap.set('CADPLN', SymbolIcons.getIconURL('CADPLN'));
    this.iconsMap.set('CAD/PLN', SymbolIcons.getIconURL('CADPLN'));
    this.iconsMap.set('CADHKD', SymbolIcons.getIconURL('CADHKD'));
    this.iconsMap.set('CAD/HKD', SymbolIcons.getIconURL('CADHKD'));
    this.iconsMap.set('CHFJPY', SymbolIcons.getIconURL('CHFJPY'));
    this.iconsMap.set('CHF/JPY', SymbolIcons.getIconURL('CHFJPY'));
    this.iconsMap.set('CHFNOK', SymbolIcons.getIconURL('CHFNOK'));
    this.iconsMap.set('CHF/NOK', SymbolIcons.getIconURL('CHFNOK'));
    this.iconsMap.set('CHFDKK', SymbolIcons.getIconURL('CHFDKK'));
    this.iconsMap.set('CHF/DKK', SymbolIcons.getIconURL('CHFDKK'));
    this.iconsMap.set('CHFCNH', SymbolIcons.getIconURL('CHFCNH'));
    this.iconsMap.set('CHF/CNH', SymbolIcons.getIconURL('CHFCNH'));
    this.iconsMap.set('CHFPLN', SymbolIcons.getIconURL('CHFPLN'));
    this.iconsMap.set('CHF/PLN', SymbolIcons.getIconURL('CHFPLN'));
    this.iconsMap.set('CHFSEK', SymbolIcons.getIconURL('CHFSEK'));
    this.iconsMap.set('CHF/SEK', SymbolIcons.getIconURL('CHFSEK'));
    this.iconsMap.set('CHFSGD', SymbolIcons.getIconURL('CHFSGD'));
    this.iconsMap.set('CHF/SGD', SymbolIcons.getIconURL('CHFSGD'));
    this.iconsMap.set('CHFTRY', SymbolIcons.getIconURL('CHFTRY'));
    this.iconsMap.set('CHF/TRY', SymbolIcons.getIconURL('CHFTRY'));
    this.iconsMap.set('CNHJPY', SymbolIcons.getIconURL('CNHJPY'));
    this.iconsMap.set('CNH/JPY', SymbolIcons.getIconURL('CNHJPY'));
    this.iconsMap.set('COPPER.fUSD', SymbolIcons.getIconURL('COPPER.fUSD'));
    this.iconsMap.set('CL_BRENT', SymbolIcons.getIconURL('CL_BRENT'));
    this.iconsMap.set('CSCO.OQ', SymbolIcons.getIconURL('CSCO.OQ'));
    this.iconsMap.set('CSCO', SymbolIcons.getIconURL('CSCO.OQ'));
    this.iconsMap.set('CVX.N', SymbolIcons.getIconURL('CVX.N'));
    this.iconsMap.set('CVX', SymbolIcons.getIconURL('CVX.N'));
    this.iconsMap.set('CC.f', SymbolIcons.getIconURL('CC.f'));
    this.iconsMap.set('CHINA50.f', SymbolIcons.getIconURL('CHINAH.f'));
    this.iconsMap.set('CHINAH.f', SymbolIcons.getIconURL('CHINAH.f'));
    this.iconsMap.set('CN50U', SymbolIcons.getIconURL('CHINAH.f'));
    this.iconsMap.set('COPPER.f', SymbolIcons.getIconURL('COPPER.f'));
    this.iconsMap.set('CT.f', SymbolIcons.getIconURL('CT.f'));
    this.iconsMap.set('COMPUSDT', SymbolIcons.getIconURL('COMPUSDT'));
    this.iconsMap.set('CAKEUSD', SymbolIcons.getIconURL('CAKEUSD'));
    this.iconsMap.set('COMPUSD', SymbolIcons.getIconURL('COMPUSD'));
    this.iconsMap.set('CROUSD', SymbolIcons.getIconURL('CROUSD'));
    this.iconsMap.set('C.N', SymbolIcons.getIconURL('C.N'));
    this.iconsMap.set('C', SymbolIcons.getIconURL('C.N'));
    this.iconsMap.set('CGC.N', SymbolIcons.getIconURL('CGC.N'));
    this.iconsMap.set('CGC', SymbolIcons.getIconURL('CGC.N'));
    this.iconsMap.set('CMG.N', SymbolIcons.getIconURL('CMG.N'));
    this.iconsMap.set('CMG', SymbolIcons.getIconURL('CMG.N'));
    this.iconsMap.set('COIN.OQ', SymbolIcons.getIconURL('COIN.OQ'));
    this.iconsMap.set('CRON.OQ', SymbolIcons.getIconURL('CRON.OQ'));
    this.iconsMap.set('CRON', SymbolIcons.getIconURL('CRON.OQ'));
    this.iconsMap.set('CAN', SymbolIcons.getIconURL('CAN'));
    this.iconsMap.set('CAT', SymbolIcons.getIconURL('CAT'));
    this.iconsMap.set('CCL', SymbolIcons.getIconURL('CCL'));
    this.iconsMap.set('CF', SymbolIcons.getIconURL('CF'));
    this.iconsMap.set('CIG', SymbolIcons.getIconURL('CIG'));
    this.iconsMap.set('CLF', SymbolIcons.getIconURL('CLF'));
    this.iconsMap.set('CLOV', SymbolIcons.getIconURL('CLOV'));
    this.iconsMap.set('CLX', SymbolIcons.getIconURL('CLX'));
    this.iconsMap.set('CMCSA', SymbolIcons.getIconURL('CMCSA'));
    this.iconsMap.set('CMCSA.OQ', SymbolIcons.getIconURL('CMCSA'));
    this.iconsMap.set('COP', SymbolIcons.getIconURL('COP'));
    this.iconsMap.set('COST', SymbolIcons.getIconURL('COST'));
    this.iconsMap.set('COST.OQ', SymbolIcons.getIconURL('COST'));
    this.iconsMap.set('COTY', SymbolIcons.getIconURL('COTY'));
    this.iconsMap.set('CPG', SymbolIcons.getIconURL('CPG'));
    this.iconsMap.set('CRM', SymbolIcons.getIconURL('CRM'));
    this.iconsMap.set('CSIQ', SymbolIcons.getIconURL('CSIQ'));
    this.iconsMap.set('CVE', SymbolIcons.getIconURL('CVE'));
    this.iconsMap.set('CVS', SymbolIcons.getIconURL('CVS'));
    this.iconsMap.set('CVS.N', SymbolIcons.getIconURL('CVS'));
    this.iconsMap.set('CX', SymbolIcons.getIconURL('CX'));
    this.iconsMap.set('CUSOPPER.f', SymbolIcons.getIconURL('CUSOPPER.f'));
    this.iconsMap.set('CBKG.DE', SymbolIcons.getIconURL('CBKG.DE'));
    this.iconsMap.set('CN50U.v', SymbolIcons.getIconURL('CN50U.v'));

    // D
    this.iconsMap.set('DOGEUSD', SymbolIcons.getIconURL('DOGEUSD'));
    this.iconsMap.set('DKKJPY', SymbolIcons.getIconURL('DKKJPY'));
    this.iconsMap.set('DKK/JPY', SymbolIcons.getIconURL('DKKJPY'));
    this.iconsMap.set('1DASHBTC', SymbolIcons.getIconURL('DASHBTC'));
    this.iconsMap.set('1DASHUSD ', SymbolIcons.getIconURL('DASHUSD'));
    this.iconsMap.set('DQS', SymbolIcons.getIconURL('DQS'));
    this.iconsMap.set('DAIGn.DE', SymbolIcons.getIconURL('DAIGn.DE'));
    this.iconsMap.set('DANO.PA', SymbolIcons.getIconURL('DANO.PA'));
    this.iconsMap.set('DBKGn.DE', SymbolIcons.getIconURL('DBKGn.DE'));
    this.iconsMap.set('DB', SymbolIcons.getIconURL('DBKGn.DE'));
    this.iconsMap.set('DPWGn.DE', SymbolIcons.getIconURL('DPWGn.DE'));
    this.iconsMap.set('DX.f', SymbolIcons.getIconURL('DX.f'));
    this.iconsMap.set('DE30', SymbolIcons.getIconURL('GER'));
    this.iconsMap.set('DE30.v', SymbolIcons.getIconURL('GER'));
    this.iconsMap.set('DE30.c', SymbolIcons.getIconURL('GER'));
    this.iconsMap.set('DE30.f', SymbolIcons.getIconURL('GER'));
    this.iconsMap.set('DE30.d', SymbolIcons.getIconURL('GER'));
    this.iconsMap.set('DJ30', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DJ30.v', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DJ30.c', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DJ30.f', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DJ30.d', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DJ30s.v', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('DETEC30.f', SymbolIcons.getIconURL('DETEC30.f'));
    this.iconsMap.set('DASHUSDT', SymbolIcons.getIconURL('DASHUSDT'));
    this.iconsMap.set('DOGEUSDT', SymbolIcons.getIconURL('DOGEUSDT'));
    this.iconsMap.set('DOTUSDT', SymbolIcons.getIconURL('DOTUSDT'));
    this.iconsMap.set('DAIUSD', SymbolIcons.getIconURL('DAIUSD'));
    this.iconsMap.set('DASHUSD', SymbolIcons.getIconURL('DASHUSD'));
    this.iconsMap.set('DOTUSD', SymbolIcons.getIconURL('DOTUSD'));
    this.iconsMap.set('DAL.N', SymbolIcons.getIconURL('DAL.N'));
    this.iconsMap.set('DAL', SymbolIcons.getIconURL('DAL.N'));
    this.iconsMap.set('DIS.N', SymbolIcons.getIconURL('DIS.N'));
    this.iconsMap.set('DIS', SymbolIcons.getIconURL('DIS.N'));
    this.iconsMap.set('DOCU.OQ', SymbolIcons.getIconURL('DOCU.OQ'));
    this.iconsMap.set('DGE.L', SymbolIcons.getIconURL('DGE.L'));
    this.iconsMap.set('D', SymbolIcons.getIconURL('D'));
    this.iconsMap.set('DDD', SymbolIcons.getIconURL('DDD'));
    this.iconsMap.set('DE', SymbolIcons.getIconURL('DE'));
    this.iconsMap.set('DESP', SymbolIcons.getIconURL('DESP'));
    this.iconsMap.set('DISCA', SymbolIcons.getIconURL('DISCA'));
    this.iconsMap.set('DISCK', SymbolIcons.getIconURL('DISCK'));
    this.iconsMap.set('DKNG', SymbolIcons.getIconURL('DKNG'));
    this.iconsMap.set('DVN', SymbolIcons.getIconURL('DVN'));

    // E
    this.iconsMap.set('EURGBP', SymbolIcons.getIconURL('EURGBP'));
    this.iconsMap.set('EUR/GBP', SymbolIcons.getIconURL('EURGBP'));
    this.iconsMap.set('EURAUD', SymbolIcons.getIconURL('EURAUD'));
    this.iconsMap.set('EUR/AUD', SymbolIcons.getIconURL('EURAUD'));
    this.iconsMap.set('EURCAD', SymbolIcons.getIconURL('EURCAD'));
    this.iconsMap.set('EUR/CAD', SymbolIcons.getIconURL('EURCAD'));
    this.iconsMap.set('EURCHF', SymbolIcons.getIconURL('EURCHF'));
    this.iconsMap.set('EUR/CHF', SymbolIcons.getIconURL('EURCHF'));
    this.iconsMap.set('EURDKK', SymbolIcons.getIconURL('EURDKK'));
    this.iconsMap.set('EUR/DKK', SymbolIcons.getIconURL('EURDKK'));
    this.iconsMap.set('EURHKD', SymbolIcons.getIconURL('EURHKD'));
    this.iconsMap.set('EUR/HKD', SymbolIcons.getIconURL('EURHKD'));
    this.iconsMap.set('EURJPY', SymbolIcons.getIconURL('EURJPY'));
    this.iconsMap.set('EUR/JPY', SymbolIcons.getIconURL('EURJPY'));
    this.iconsMap.set('EURNOK', SymbolIcons.getIconURL('EURNOK'));
    this.iconsMap.set('EUR/NOK', SymbolIcons.getIconURL('EURNOK'));
    this.iconsMap.set('EURNZD', SymbolIcons.getIconURL('EURNZD'));
    this.iconsMap.set('EUR/NZD', SymbolIcons.getIconURL('EURNZD'));
    this.iconsMap.set('EURPLN', SymbolIcons.getIconURL('EURPLN'));
    this.iconsMap.set('EUR/PLN', SymbolIcons.getIconURL('EURPLN'));
    this.iconsMap.set('EURSEK', SymbolIcons.getIconURL('EURSEK'));
    this.iconsMap.set('EUR/SEK', SymbolIcons.getIconURL('EURSEK'));
    this.iconsMap.set('EURSGD', SymbolIcons.getIconURL('EURSGD'));
    this.iconsMap.set('EUR/SGD', SymbolIcons.getIconURL('EURSGD'));
    this.iconsMap.set('EURTRY', SymbolIcons.getIconURL('EURTRY'));
    this.iconsMap.set('EUR/TRY', SymbolIcons.getIconURL('EURTRY'));
    this.iconsMap.set('EURUSD', SymbolIcons.getIconURL('EURUSD'));
    this.iconsMap.set('EUR/USD', SymbolIcons.getIconURL('EURUSD'));
    this.iconsMap.set('EURCNH', SymbolIcons.getIconURL('EURCNH'));
    this.iconsMap.set('EUR/CNH', SymbolIcons.getIconURL('EURCNH'));
    this.iconsMap.set('EURHUF', SymbolIcons.getIconURL('EURHUF'));
    this.iconsMap.set('EUR/HUF', SymbolIcons.getIconURL('EURHUF'));
    this.iconsMap.set('EURMXN', SymbolIcons.getIconURL('EURMXN'));
    this.iconsMap.set('EUR/MXN', SymbolIcons.getIconURL('EURMXN'));
    this.iconsMap.set('EURZAR', SymbolIcons.getIconURL('EURZAR'));
    this.iconsMap.set('EUR/ZAR', SymbolIcons.getIconURL('EURZAR'));
    this.iconsMap.set('EUR/CZK', SymbolIcons.getIconURL('EURCZK'));
    this.iconsMap.set('ETHUSD', SymbolIcons.getIconURL('ETHUSD'));
    this.iconsMap.set('ETH/USD', SymbolIcons.getIconURL('ETHUSD'));
    this.iconsMap.set('ETHUSD.b', SymbolIcons.getIconURL('ETHUSD'));
    this.iconsMap.set('ETHUSDc', SymbolIcons.getIconURL('ETHUSD'));
    this.iconsMap.set('1ETHBTC', SymbolIcons.getIconURL('ETHBTC'));
    this.iconsMap.set('1ETHEUR', SymbolIcons.getIconURL('ETHEUR'));
    this.iconsMap.set('1ETHGBP', SymbolIcons.getIconURL('ETHGBP'));
    this.iconsMap.set('EUR', SymbolIcons.getIconURL('EUR'));
    this.iconsMap.set('EOSUSD', SymbolIcons.getIconURL('EOSUSD'));
    this.iconsMap.set('1EOSBIT', SymbolIcons.getIconURL('EOSBIT'));
    this.iconsMap.set('EDOUSD', SymbolIcons.getIconURL('EDOUSD'));
    this.iconsMap.set('1EDOBIT', SymbolIcons.getIconURL('EDOBIT'));
    this.iconsMap.set('EBAY.OQ', SymbolIcons.getIconURL('EBAY.OQ'));
    this.iconsMap.set('EBAY', SymbolIcons.getIconURL('EBAY.OQ'));
    this.iconsMap.set('EONGn.DE', SymbolIcons.getIconURL('EONGn.DE'));
    this.iconsMap.set('ES35.c', SymbolIcons.getIconURL('ES35.c'));
    this.iconsMap.set('1EUR50', SymbolIcons.getIconURL('1EUR50'));
    this.iconsMap.set('EGLDUSDT', SymbolIcons.getIconURL('EGLDUSDT'));
    this.iconsMap.set('ENJUSDT', SymbolIcons.getIconURL('ENJUSDT'));
    this.iconsMap.set('EOSUSDT', SymbolIcons.getIconURL('EOSUSDT'));
    this.iconsMap.set('ETCUSDT', SymbolIcons.getIconURL('ETCUSDT'));
    this.iconsMap.set('ETHUSDT', SymbolIcons.getIconURL('ETHUSDT'));
    this.iconsMap.set('EGLDUSD', SymbolIcons.getIconURL('EGLDUSD'));
    this.iconsMap.set('ETCUSD', SymbolIcons.getIconURL('ETCUSD'));
    this.iconsMap.set('EXPE.OQ', SymbolIcons.getIconURL('EXPE.OQ'));
    this.iconsMap.set('EFA', SymbolIcons.getIconURL('EEM.EFA.FXI'));
    this.iconsMap.set('EWZ', SymbolIcons.getIconURL('EEM.EFA.FXI'));
    this.iconsMap.set('EEM', SymbolIcons.getIconURL('EEM.EFA.FXI'));
    this.iconsMap.set('EFX', SymbolIcons.getIconURL('EFX'));
    this.iconsMap.set('EL', SymbolIcons.getIconURL('EL'));
    this.iconsMap.set('EMR', SymbolIcons.getIconURL('EMR'));
    this.iconsMap.set('EPD', SymbolIcons.getIconURL('EPD'));
    this.iconsMap.set('ERIC', SymbolIcons.getIconURL('ERIC'));
    this.iconsMap.set('ET', SymbolIcons.getIconURL('ET'));
    this.iconsMap.set('EXC', SymbolIcons.getIconURL('EXC'));
    this.iconsMap.set('EA', SymbolIcons.getIconURL('EA'));
    this.iconsMap.set('EC', SymbolIcons.getIconURL('EC'));
    this.iconsMap.set('1ESP35', SymbolIcons.getIconURL('1ESP35'));
    this.iconsMap.set('ESP35.d', SymbolIcons.getIconURL('1ESP35'));
    this.iconsMap.set('1ETHJPY', SymbolIcons.getIconURL('1ETHJPY'));

    // F
    this.iconsMap.set('FRA_40', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('1FRA40', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('F40', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('F40.v', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('F40.c', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('F40.d', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('FRA_40', SymbolIcons.getIconURL('FRA_40'));
    this.iconsMap.set('FB.OQ', SymbolIcons.getIconURL('FB.OQ'));
    this.iconsMap.set('FB', SymbolIcons.getIconURL('FB.OQ'));
    this.iconsMap.set('FDX.N', SymbolIcons.getIconURL('FDX.N'));
    this.iconsMap.set('FDX', SymbolIcons.getIconURL('FDX.N'));
    this.iconsMap.set('FGBL.f', SymbolIcons.getIconURL('FGBL.f'));
    this.iconsMap.set('FLG.f', SymbolIcons.getIconURL('FLG.f'));
    this.iconsMap.set('FILUSDT', SymbolIcons.getIconURL('FILUSDT'));
    this.iconsMap.set('FTMUSDT', SymbolIcons.getIconURL('FTMUSDT'));
    this.iconsMap.set('FTTBUSD', SymbolIcons.getIconURL('FTTBUSD'));
    this.iconsMap.set('FILUSD', SymbolIcons.getIconURL('FILUSD'));
    this.iconsMap.set('FLOWUSD', SymbolIcons.getIconURL('FLOWUSD'));
    this.iconsMap.set('FTMUSD', SymbolIcons.getIconURL('FTMUSD'));
    this.iconsMap.set('FTTUSD', SymbolIcons.getIconURL('FTTUSD'));
    this.iconsMap.set('F.N', SymbolIcons.getIconURL('F.N'));
    this.iconsMap.set('F', SymbolIcons.getIconURL('F.N'));
    this.iconsMap.set('FCEL', SymbolIcons.getIconURL('FCEL'));
    this.iconsMap.set('FCX', SymbolIcons.getIconURL('FCX'));
    this.iconsMap.set('FHN', SymbolIcons.getIconURL('FHN'));
    this.iconsMap.set('FLR', SymbolIcons.getIconURL('FLR'));
    this.iconsMap.set('FOXA', SymbolIcons.getIconURL('FOXA'));
    this.iconsMap.set('FSLR', SymbolIcons.getIconURL('FSLR'));
    this.iconsMap.set('FSR', SymbolIcons.getIconURL('FSR'));
    this.iconsMap.set('FTCH', SymbolIcons.getIconURL('FTCH'));
    this.iconsMap.set('FTI', SymbolIcons.getIconURL('FTI'));
    this.iconsMap.set('FUBO', SymbolIcons.getIconURL('FUBO'));
    this.iconsMap.set('FUTU', SymbolIcons.getIconURL('FUTU'));
    this.iconsMap.set('FXI', SymbolIcons.getIconURL('EEM.EFA.FXI'));

    // G
    this.iconsMap.set('GAU/USD', SymbolIcons.getIconURL('XAUUSD'));
    this.iconsMap.set('GAUUSD', SymbolIcons.getIconURL('XAUUSD'));
    this.iconsMap.set('GBPAUD', SymbolIcons.getIconURL('GBPAUD'));
    this.iconsMap.set('GBP/AUD', SymbolIcons.getIconURL('GBPAUD'));
    this.iconsMap.set('GBPCAD', SymbolIcons.getIconURL('GBPCAD'));
    this.iconsMap.set('GBP/CAD', SymbolIcons.getIconURL('GBPCAD'));
    this.iconsMap.set('GBPCHF', SymbolIcons.getIconURL('GBPCHF'));
    this.iconsMap.set('GBP/CHF', SymbolIcons.getIconURL('GBPCHF'));
    this.iconsMap.set('GBPJPY', SymbolIcons.getIconURL('GBPJPY'));
    this.iconsMap.set('GBP/JPY', SymbolIcons.getIconURL('GBPJPY'));
    this.iconsMap.set('GBPNOK', SymbolIcons.getIconURL('GBPNOK'));
    this.iconsMap.set('GBP/NOK', SymbolIcons.getIconURL('GBPNOK'));
    this.iconsMap.set('GBPNZD', SymbolIcons.getIconURL('GBPNZD'));
    this.iconsMap.set('GBP/NZD', SymbolIcons.getIconURL('GBPNZD'));
    this.iconsMap.set('GBPSGD', SymbolIcons.getIconURL('GBPSGD'));
    this.iconsMap.set('GBP/SGD', SymbolIcons.getIconURL('GBPSGD'));
    this.iconsMap.set('GBPJPY', SymbolIcons.getIconURL('GBPJPY'));
    this.iconsMap.set('GBPUSD', SymbolIcons.getIconURL('GBPUSD'));
    this.iconsMap.set('GBP/USD', SymbolIcons.getIconURL('GBPUSD'));
    this.iconsMap.set('GBPZAR', SymbolIcons.getIconURL('GBPZAR'));
    this.iconsMap.set('GBP/ZAR', SymbolIcons.getIconURL('GBPZAR'));
    this.iconsMap.set('GBP/DKK', SymbolIcons.getIconURL('GBPDKK'));
    this.iconsMap.set('GBP/PLN', SymbolIcons.getIconURL('GBPPLN'));
    this.iconsMap.set('GBP/SEK', SymbolIcons.getIconURL('GBPSEK'));
    this.iconsMap.set('GBP/TRY', SymbolIcons.getIconURL('GBPTRY'));
    this.iconsMap.set('GBR_100', SymbolIcons.getIconURL('GBR_100'));
    this.iconsMap.set('GER_30', SymbolIcons.getIconURL('GER_30'));
    this.iconsMap.set('1GER30', SymbolIcons.getIconURL('GER_30'));
    this.iconsMap.set('GE.N', SymbolIcons.getIconURL('GE.N'));
    this.iconsMap.set('GE', SymbolIcons.getIconURL('GE.N'));
    this.iconsMap.set('GM.N', SymbolIcons.getIconURL('GM.N'));
    this.iconsMap.set('GM', SymbolIcons.getIconURL('GM.N'));
    this.iconsMap.set('GOOG.OQ', SymbolIcons.getIconURL('GOOG.OQ'));
    this.iconsMap.set('GOOG', SymbolIcons.getIconURL('GOOG.OQ'));
    this.iconsMap.set('GOOGL', SymbolIcons.getIconURL('GOOG.OQ'));
    this.iconsMap.set('GS.N', SymbolIcons.getIconURL('GS.N'));
    this.iconsMap.set('GS', SymbolIcons.getIconURL('GS.N'));
    this.iconsMap.set('GBR_100', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('GALAUSDT', SymbolIcons.getIconURL('GALAUSDT'));
    this.iconsMap.set('GRTUSDT', SymbolIcons.getIconURL('GRTUSDT'));
    this.iconsMap.set('GRTUSD', SymbolIcons.getIconURL('GRTUSD'));
    this.iconsMap.set('GRUB.N', SymbolIcons.getIconURL('GRUB.N'));
    this.iconsMap.set('GLEN.L', SymbolIcons.getIconURL('GLEN.L'));
    this.iconsMap.set('GSK.L', SymbolIcons.getIconURL('GSK.L'));
    this.iconsMap.set('GDX', SymbolIcons.getIconURL('GDX'));
    this.iconsMap.set('GFI', SymbolIcons.getIconURL('GFI'));
    this.iconsMap.set('GGAL', SymbolIcons.getIconURL('GGAL'));
    this.iconsMap.set('GGB', SymbolIcons.getIconURL('GGB'));
    this.iconsMap.set('GILD', SymbolIcons.getIconURL('GILD'));
    this.iconsMap.set('GILD.OQ', SymbolIcons.getIconURL('GILD'));
    this.iconsMap.set('GIS', SymbolIcons.getIconURL('GIS'));
    this.iconsMap.set('GME', SymbolIcons.getIconURL('GME'));
    this.iconsMap.set('GOL', SymbolIcons.getIconURL('GOL'));
    this.iconsMap.set('GOLD', SymbolIcons.getIconURL('GOLD'));
    this.iconsMap.set('GPS', SymbolIcons.getIconURL('GPS'));
    this.iconsMap.set('GSAT', SymbolIcons.getIconURL('GSAT'));
    this.iconsMap.set('GT', SymbolIcons.getIconURL('GT'));
    this.iconsMap.set('GOLD.f', SymbolIcons.getIconURL('GOLD.f'));

    // H
    this.iconsMap.set('HKDCNH', SymbolIcons.getIconURL('HKDCNH'));
    this.iconsMap.set('HKD/CNH', SymbolIcons.getIconURL('HKDCNH'));
    this.iconsMap.set('HKDJPY', SymbolIcons.getIconURL('HKDJPY'));
    this.iconsMap.set('HKD/JPY', SymbolIcons.getIconURL('HKDJPY'));
    this.iconsMap.set('HLT.N', SymbolIcons.getIconURL('HLT.N'));
    this.iconsMap.set('HLT', SymbolIcons.getIconURL('HLT.N'));
    this.iconsMap.set('HPQ.N', SymbolIcons.getIconURL('HPQ.N'));
    this.iconsMap.set('HPQ', SymbolIcons.getIconURL('HPQ.N'));
    this.iconsMap.set('HK50.f', SymbolIcons.getIconURL('HK50.f'));
    this.iconsMap.set('HKG50', SymbolIcons.getIconURL('HK50.f'));
    this.iconsMap.set('HKG50.v', SymbolIcons.getIconURL('HK50.f'));
    this.iconsMap.set('HKG50.d', SymbolIcons.getIconURL('HK50.f'));
    this.iconsMap.set('1HKG50', SymbolIcons.getIconURL('HK50.f'));
    this.iconsMap.set('HBARUSDT', SymbolIcons.getIconURL('HBARUSDT'));
    this.iconsMap.set('HNTUSDT', SymbolIcons.getIconURL('HNTUSDT'));
    this.iconsMap.set('HBARUSD', SymbolIcons.getIconURL('HBARUSD'));
    this.iconsMap.set('HD.N', SymbolIcons.getIconURL('HD.N'));
    this.iconsMap.set('HD', SymbolIcons.getIconURL('HD.N'));
    this.iconsMap.set('HYG', SymbolIcons.getIconURL('EEM.EFA.FXI'));
    this.iconsMap.set('HSBA.L', SymbolIcons.getIconURL('HSBA.L'));
    this.iconsMap.set('HAL', SymbolIcons.getIconURL('HAL'));
    this.iconsMap.set('HBAN', SymbolIcons.getIconURL('HBAN'));
    this.iconsMap.set('HBI', SymbolIcons.getIconURL('HBI'));
    this.iconsMap.set('HL', SymbolIcons.getIconURL('HL'));
    this.iconsMap.set('HLF', SymbolIcons.getIconURL('HLF'));
    this.iconsMap.set('HOG', SymbolIcons.getIconURL('HOG'));
    this.iconsMap.set('HON', SymbolIcons.getIconURL('HON'));
    this.iconsMap.set('HPE', SymbolIcons.getIconURL('HPE'));

    // I
    this.iconsMap.set('IOTAUSD', SymbolIcons.getIconURL('IOTAUSD '));
    this.iconsMap.set('IBM.N', SymbolIcons.getIconURL('IBM.N'));
    this.iconsMap.set('IBM', SymbolIcons.getIconURL('IBM.N'));
    this.iconsMap.set('ILMN.OQ', SymbolIcons.getIconURL('ILMN.OQ'));
    this.iconsMap.set('ILMN', SymbolIcons.getIconURL('ILMN.OQ'));
    this.iconsMap.set('INTC.OQ', SymbolIcons.getIconURL('INTC.OQ'));
    this.iconsMap.set('INTC', SymbolIcons.getIconURL('INTC.OQ'));
    this.iconsMap.set('IBE.MC', SymbolIcons.getIconURL('IBE.MC'));
    this.iconsMap.set('IND50.f', SymbolIcons.getIconURL('IND50.f'));
    this.iconsMap.set('IND50.v', SymbolIcons.getIconURL('IND50.f'));
    this.iconsMap.set('ITA40.z', SymbolIcons.getIconURL('ITA40.z'));
    this.iconsMap.set('ITA40.c', SymbolIcons.getIconURL('ITA40.z'));
    this.iconsMap.set('ICPUSDT', SymbolIcons.getIconURL('ICPUSDT'));
    this.iconsMap.set('ICXUSDT', SymbolIcons.getIconURL('ICXUSDT'));
    this.iconsMap.set('IOTAUSDT', SymbolIcons.getIconURL('IOTAUSDT'));
    this.iconsMap.set('ICPUSD', SymbolIcons.getIconURL('ICPUSD'));
    this.iconsMap.set('IAG.L', SymbolIcons.getIconURL('IAG.L'));
    this.iconsMap.set('ITV.L', SymbolIcons.getIconURL('ITV.L'));
    this.iconsMap.set('IBN', SymbolIcons.getIconURL('IBN'));
    this.iconsMap.set('INFY', SymbolIcons.getIconURL('INFY'));
    this.iconsMap.set('ING', SymbolIcons.getIconURL('ING'));
    this.iconsMap.set('INGR', SymbolIcons.getIconURL('INGR'));
    this.iconsMap.set('IQ', SymbolIcons.getIconURL('IQ'));
    this.iconsMap.set('ITUB', SymbolIcons.getIconURL('ITUB'));
    this.iconsMap.set('1IOTABIT', SymbolIcons.getIconURL('1IOTABIT'));

    // J
    this.iconsMap.set('JPN225.vJPY', SymbolIcons.getIconURL('JPN225.vJPY'));
    this.iconsMap.set('JPN225', SymbolIcons.getIconURL('JPN225'));
    this.iconsMap.set('JPN225.v', SymbolIcons.getIconURL('JPN225'));
    this.iconsMap.set('JPN225.c', SymbolIcons.getIconURL('JPN225'));
    this.iconsMap.set('JPN225.d', SymbolIcons.getIconURL('JPN225'));
    this.iconsMap.set('JNJ.N', SymbolIcons.getIconURL('JNJ.N'));
    this.iconsMap.set('JNJ', SymbolIcons.getIconURL('JNJ.N'));
    this.iconsMap.set('JPM.N', SymbolIcons.getIconURL('JPM.N'));
    this.iconsMap.set('JPM', SymbolIcons.getIconURL('JPM.N'));
    this.iconsMap.set('JD.OQ', SymbolIcons.getIconURL('JD.OQ'));
    this.iconsMap.set('JD', SymbolIcons.getIconURL('JD.OQ'));
    this.iconsMap.set('JBLU', SymbolIcons.getIconURL('JBLU'));
    this.iconsMap.set('JKS', SymbolIcons.getIconURL('JKS'));

    // K
    this.iconsMap.set('KO.N', SymbolIcons.getIconURL('KO.N'));
    this.iconsMap.set('KO', SymbolIcons.getIconURL('KO.N'));
    this.iconsMap.set('KC.f', SymbolIcons.getIconURL('KC.f'));
    this.iconsMap.set('KLAYUSDT', SymbolIcons.getIconURL('KLAYUSDT'));
    this.iconsMap.set('KLAYUSD', SymbolIcons.getIconURL('KLAYUSD'));
    this.iconsMap.set('KSMUSD', SymbolIcons.getIconURL('KSMUSD'));
    this.iconsMap.set('KER.PA', SymbolIcons.getIconURL('KER.PA'));
    this.iconsMap.set('KEY', SymbolIcons.getIconURL('KEY'));
    this.iconsMap.set('KGC', SymbolIcons.getIconURL('KGC'));
    this.iconsMap.set('KMI', SymbolIcons.getIconURL('KMI'));
    this.iconsMap.set('KR', SymbolIcons.getIconURL('KR'));

    // L
    this.iconsMap.set('LTCUSD', SymbolIcons.getIconURL('LTCUSD'));
    this.iconsMap.set('LTCUSD.b', SymbolIcons.getIconURL('LTCUSD'));
    this.iconsMap.set('LTCUSDc', SymbolIcons.getIconURL('LTCUSD'));
    this.iconsMap.set('LHAG.DE', SymbolIcons.getIconURL('LHAG.DE'));
    this.iconsMap.set('LVMH.PA', SymbolIcons.getIconURL('LVMH.PA'));
    this.iconsMap.set('LRC.f', SymbolIcons.getIconURL('LRC.f'));
    this.iconsMap.set('LCC.f', SymbolIcons.getIconURL('LCC.f'));
    this.iconsMap.set('LSU.f', SymbolIcons.getIconURL('LSU.f'));
    this.iconsMap.set('LINKUSDT', SymbolIcons.getIconURL('LINKUSDT'));
    this.iconsMap.set('LITUSDT', SymbolIcons.getIconURL('LITUSDT'));
    this.iconsMap.set('LRCUSDT', SymbolIcons.getIconURL('LRCUSDT'));
    this.iconsMap.set('LTCUSDT', SymbolIcons.getIconURL('LTCUSDT'));
    this.iconsMap.set('LUNAUSDT', SymbolIcons.getIconURL('LUNAUSDT'));
    this.iconsMap.set('LINKUSD', SymbolIcons.getIconURL('LINKUSD'));
    this.iconsMap.set('LUNAUSD', SymbolIcons.getIconURL('LUNAUSD'));
    this.iconsMap.set('L.N', SymbolIcons.getIconURL('L.N'));
    this.iconsMap.set('LI.OQ', SymbolIcons.getIconURL('LI.OQ'));
    this.iconsMap.set('LI', SymbolIcons.getIconURL('LI.OQ'));
    this.iconsMap.set('LK.OQ', SymbolIcons.getIconURL('LK.OQ'));
    this.iconsMap.set('LUV.N', SymbolIcons.getIconURL('LUV.N'));
    this.iconsMap.set('LUV', SymbolIcons.getIconURL('LUV.N'));
    this.iconsMap.set('LYFT.OQ', SymbolIcons.getIconURL('LYFT'));
    this.iconsMap.set('LGEN.L', SymbolIcons.getIconURL('LGEN.L'));
    this.iconsMap.set('LLOY.L', SymbolIcons.getIconURL('LLOY.L'));
    this.iconsMap.set('LYG', SymbolIcons.getIconURL('LLOY.L'));
    this.iconsMap.set('LMT', SymbolIcons.getIconURL('LMT'));
    this.iconsMap.set('LMT.N', SymbolIcons.getIconURL('LMT'));
    this.iconsMap.set('LNG', SymbolIcons.getIconURL('LNG'));
    this.iconsMap.set('LULU', SymbolIcons.getIconURL('LULU'));
    this.iconsMap.set('LUMN', SymbolIcons.getIconURL('LUMN'));
    this.iconsMap.set('LVS', SymbolIcons.getIconURL('LVS'));
    this.iconsMap.set('LYFT', SymbolIcons.getIconURL('LYFT'));
    this.iconsMap.set('1LTCBTC', SymbolIcons.getIconURL('1LTCBTC'));

    // M
    this.iconsMap.set('MXN/JPY', SymbolIcons.getIconURL('MXNJPY'));
    this.iconsMap.set('MCD.NUSD', SymbolIcons.getIconURL('MCD.NUSD'));
    this.iconsMap.set('MA.N', SymbolIcons.getIconURL('MA.N'));
    this.iconsMap.set('MA', SymbolIcons.getIconURL('MA.N'));
    this.iconsMap.set('MCD.N', SymbolIcons.getIconURL('MCD.N'));
    this.iconsMap.set('MCD', SymbolIcons.getIconURL('MCD.N'));
    this.iconsMap.set('MSFT.OQ', SymbolIcons.getIconURL('MSFT.OQ'));
    this.iconsMap.set('MSFT', SymbolIcons.getIconURL('MSFT.OQ'));
    this.iconsMap.set('MAP.MC', SymbolIcons.getIconURL('MAP.MC'));
    this.iconsMap.set('MDE50.f', SymbolIcons.getIconURL('MDE50.f'));
    this.iconsMap.set('MANAUSDT', SymbolIcons.getIconURL('MANAUSDT'));
    this.iconsMap.set('MATICUSDT', SymbolIcons.getIconURL('MATICUSDT'));
    this.iconsMap.set('MKRUSDT', SymbolIcons.getIconURL('MKRUSDT'));
    this.iconsMap.set('MATICUSD', SymbolIcons.getIconURL('MATICUSD'));
    this.iconsMap.set('MIOTAUSD', SymbolIcons.getIconURL('MIOTAUSD'));
    this.iconsMap.set('MKRUSD', SymbolIcons.getIconURL('MKRUSD'));
    this.iconsMap.set('MGM.N', SymbolIcons.getIconURL('MGM.N'));
    this.iconsMap.set('MGM', SymbolIcons.getIconURL('MGM.N'));
    this.iconsMap.set('MOMO.OQ', SymbolIcons.getIconURL('MOMO.OQ'));
    this.iconsMap.set('MRK.N', SymbolIcons.getIconURL('MRK.N'));
    this.iconsMap.set('MRK', SymbolIcons.getIconURL('MRK.N'));
    this.iconsMap.set('MRNA.OQ', SymbolIcons.getIconURL('MRNA.OQ'));
    this.iconsMap.set('MRNA', SymbolIcons.getIconURL('MRNA.OQ'));
    this.iconsMap.set('MKS.L', SymbolIcons.getIconURL('MKS.L'));
    this.iconsMap.set('MRW.L', SymbolIcons.getIconURL('MRW.L'));
    this.iconsMap.set('M', SymbolIcons.getIconURL('M'));
    this.iconsMap.set('MAC', SymbolIcons.getIconURL('MAC'));
    this.iconsMap.set('MANU', SymbolIcons.getIconURL('MANU'));
    this.iconsMap.set('MAR', SymbolIcons.getIconURL('MAR'));
    this.iconsMap.set('MARA', SymbolIcons.getIconURL('MARA'));
    this.iconsMap.set('MDLZ', SymbolIcons.getIconURL('MDLZ'));
    this.iconsMap.set('MELI', SymbolIcons.getIconURL('MELI'));
    this.iconsMap.set('MET', SymbolIcons.getIconURL('MET'));
    this.iconsMap.set('MMM', SymbolIcons.getIconURL('MMM'));
    this.iconsMap.set('MMM.N', SymbolIcons.getIconURL('MMM'));
    this.iconsMap.set('MNST', SymbolIcons.getIconURL('MNST'));
    this.iconsMap.set('MO', SymbolIcons.getIconURL('MO'));
    this.iconsMap.set('MO.N', SymbolIcons.getIconURL('MO'));
    this.iconsMap.set('MPC', SymbolIcons.getIconURL('MPC'));
    this.iconsMap.set('MRO', SymbolIcons.getIconURL('MRO'));
    this.iconsMap.set('MRVL', SymbolIcons.getIconURL('MRVL'));
    this.iconsMap.set('MS', SymbolIcons.getIconURL('MS'));
    this.iconsMap.set('MT', SymbolIcons.getIconURL('MT'));
    this.iconsMap.set('MU', SymbolIcons.getIconURL('MU'));

    // N
    this.iconsMap.set('NZDCAD', SymbolIcons.getIconURL('NZDCAD'));
    this.iconsMap.set('NZD/CAD', SymbolIcons.getIconURL('NZDCAD'));
    this.iconsMap.set('NZDCHF', SymbolIcons.getIconURL('NZDCHF'));
    this.iconsMap.set('NZD/CHF', SymbolIcons.getIconURL('NZDCHF'));
    this.iconsMap.set('NZDJPY', SymbolIcons.getIconURL('NZDJPY'));
    this.iconsMap.set('NZD/JPY', SymbolIcons.getIconURL('NZDJPY'));
    this.iconsMap.set('NZDSGD', SymbolIcons.getIconURL('NZDSGD'));
    this.iconsMap.set('NZD/SGD', SymbolIcons.getIconURL('NZDSGD'));
    this.iconsMap.set('NZDUSD', SymbolIcons.getIconURL('NZDUSD'));
    this.iconsMap.set('NZD/USD', SymbolIcons.getIconURL('NZDUSD'));
    this.iconsMap.set('NOK/DKK', SymbolIcons.getIconURL('NOKDKK'));
    this.iconsMap.set('NOK/JPY', SymbolIcons.getIconURL('NOKJPY'));
    this.iconsMap.set('NOK/SEK', SymbolIcons.getIconURL('NOKSEK'));
    this.iconsMap.set('NEOUSD', SymbolIcons.getIconURL('NEOUSD'));
    this.iconsMap.set('NAS100', SymbolIcons.getIconURL('NAS100'));
    this.iconsMap.set('NGAS', SymbolIcons.getIconURL('NGAS'));
    this.iconsMap.set('1NGAS', SymbolIcons.getIconURL('NGAS'));
    this.iconsMap.set('NGAS.f', SymbolIcons.getIconURL('NGAS'));
    this.iconsMap.set('NFLX.OQ', SymbolIcons.getIconURL('NFLX.OQ'));
    this.iconsMap.set('NFLX', SymbolIcons.getIconURL('NFLX.OQ'));
    this.iconsMap.set('N25.c', SymbolIcons.getIconURL('N25.c'));
    this.iconsMap.set('NEARUSDT', SymbolIcons.getIconURL('NEARUSDT'));
    this.iconsMap.set('NEOUSDT', SymbolIcons.getIconURL('NEOUSDT'));
    this.iconsMap.set('NEARUSD', SymbolIcons.getIconURL('NEARUSD'));
    this.iconsMap.set('NKE.N', SymbolIcons.getIconURL('NKE.N'));
    this.iconsMap.set('NKE', SymbolIcons.getIconURL('NKE.N'));
    this.iconsMap.set('NKLA.OQ', SymbolIcons.getIconURL('NKLA.OQ'));
    this.iconsMap.set('NKLA', SymbolIcons.getIconURL('NKLA.OQ'));
    this.iconsMap.set('NTES.OQ', SymbolIcons.getIconURL('NTES.OQ'));
    this.iconsMap.set('NVDA.OQ', SymbolIcons.getIconURL('NVDA.OQ'));
    this.iconsMap.set('NVDA', SymbolIcons.getIconURL('NVDA.OQ'));
    this.iconsMap.set('NG.L', SymbolIcons.getIconURL('NG.L'));
    this.iconsMap.set('NCLH', SymbolIcons.getIconURL('NCLH'));
    this.iconsMap.set('NEE', SymbolIcons.getIconURL('NEE'));
    this.iconsMap.set('NIO', SymbolIcons.getIconURL('NIO'));
    this.iconsMap.set('NLOK', SymbolIcons.getIconURL('NLOK'));
    this.iconsMap.set('NLY', SymbolIcons.getIconURL('NLY'));
    this.iconsMap.set('NOK', SymbolIcons.getIconURL('NOK'));
    this.iconsMap.set('NVAX', SymbolIcons.getIconURL('NVAX'));
    this.iconsMap.set('1NEOBTC', SymbolIcons.getIconURL('1NEOBTC'));

    // O
    this.iconsMap.set('OMGUSD', SymbolIcons.getIconURL('OMGUSD'));
    this.iconsMap.set('ORCL.N', SymbolIcons.getIconURL('ORCL.N'));
    this.iconsMap.set('ORCL', SymbolIcons.getIconURL('ORCL.N'));
    this.iconsMap.set('OMGUSDT', SymbolIcons.getIconURL('OMGUSDT'));
    this.iconsMap.set('ONEUSDT', SymbolIcons.getIconURL('ONEUSDT'));
    this.iconsMap.set('ONEUSD', SymbolIcons.getIconURL('ONEUSD'));
    this.iconsMap.set('OR.PA', SymbolIcons.getIconURL('OR.PA'));
    this.iconsMap.set('OLED', SymbolIcons.getIconURL('OLED'));
    this.iconsMap.set('OPEN', SymbolIcons.getIconURL('OPEN'));
    this.iconsMap.set('OXY', SymbolIcons.getIconURL('OXY'));
    this.iconsMap.set('1OMGBIT', SymbolIcons.getIconURL('1OMGBIT'));

    // P
    this.iconsMap.set('PLN/JPY', SymbolIcons.getIconURL('PLNJPY'));
    this.iconsMap.set('PFE.N', SymbolIcons.getIconURL('PFE.N'));
    this.iconsMap.set('PFE', SymbolIcons.getIconURL('PFE.N'));
    this.iconsMap.set('PG.N', SymbolIcons.getIconURL('PG.N'));
    this.iconsMap.set('PG', SymbolIcons.getIconURL('PG.N'));
    this.iconsMap.set('PDD.OQ', SymbolIcons.getIconURL('PDD.OQ'));
    this.iconsMap.set('PDD', SymbolIcons.getIconURL('PDD.OQ'));
    this.iconsMap.set('PINS.N', SymbolIcons.getIconURL('PINS.N'));
    this.iconsMap.set('PINS', SymbolIcons.getIconURL('PINS.N'));
    this.iconsMap.set('PLTR.N', SymbolIcons.getIconURL('PLTR.N'));
    this.iconsMap.set('PLTR', SymbolIcons.getIconURL('PLTR.N'));
    this.iconsMap.set('PLUG.OQ', SymbolIcons.getIconURL('PLUG.OQ'));
    this.iconsMap.set('PLUG', SymbolIcons.getIconURL('PLUG.OQ'));
    this.iconsMap.set('PTON.OQ', SymbolIcons.getIconURL('PTON.OQ'));
    this.iconsMap.set('PTON', SymbolIcons.getIconURL('PTON.OQ'));
    this.iconsMap.set('PYPL.OQ', SymbolIcons.getIconURL('PYPL.OQ'));
    this.iconsMap.set('PYPL', SymbolIcons.getIconURL('PYPL.OQ'));
    this.iconsMap.set('PBR', SymbolIcons.getIconURL('PBR'));
    this.iconsMap.set('PCG', SymbolIcons.getIconURL('PCG'));
    this.iconsMap.set('PEP', SymbolIcons.getIconURL('PEP'));
    this.iconsMap.set('PEP.N', SymbolIcons.getIconURL('PEP'));
    this.iconsMap.set('PNC', SymbolIcons.getIconURL('PNC'));
    this.iconsMap.set('PPL', SymbolIcons.getIconURL('PPL'));
    this.iconsMap.set('PZZA', SymbolIcons.getIconURL('PZZA'));
    this.iconsMap.set('PM.N', SymbolIcons.getIconURL('PM'));

    // Q
    this.iconsMap.set('QCOM.OQ', SymbolIcons.getIconURL('QCOM.OQ'));
    this.iconsMap.set('QCOM', SymbolIcons.getIconURL('QCOM.OQ'));
    this.iconsMap.set('QTUMUSDT', SymbolIcons.getIconURL('QTUMUSDT'));
    this.iconsMap.set('QNTUSD', SymbolIcons.getIconURL('QNTUSD'));
    this.iconsMap.set('QQQ.OQ', SymbolIcons.getIconURL('QQQ.OQ'));
    this.iconsMap.set('QQQ', SymbolIcons.getIconURL('QQQ.OQ'));
    this.iconsMap.set('QS', SymbolIcons.getIconURL('QS'));

    // R
    this.iconsMap.set('RACE.N', SymbolIcons.getIconURL('RACE.N'));
    this.iconsMap.set('RACE', SymbolIcons.getIconURL('RACE.N'));
    this.iconsMap.set('RUNEUSDT', SymbolIcons.getIconURL('RUNEUSDT'));
    this.iconsMap.set('RUNEUSD', SymbolIcons.getIconURL('RUNEUSD'));
    this.iconsMap.set('RCL.N', SymbolIcons.getIconURL('RCL.N'));
    this.iconsMap.set('RCL', SymbolIcons.getIconURL('RCL.N'));
    this.iconsMap.set('RB.L', SymbolIcons.getIconURL('RB.L'));
    this.iconsMap.set('RDSA.L', SymbolIcons.getIconURL('RDSA.L'));
    this.iconsMap.set('RDS.A', SymbolIcons.getIconURL('RDSA.L'));
    this.iconsMap.set('RIO.L', SymbolIcons.getIconURL('RIO.L'));
    this.iconsMap.set('RMG.L', SymbolIcons.getIconURL('RMG.L'));
    this.iconsMap.set('RR.L', SymbolIcons.getIconURL('RR.L'));
    this.iconsMap.set('REGN', SymbolIcons.getIconURL('REGN'));
    this.iconsMap.set('RENN', SymbolIcons.getIconURL('RENN'));
    this.iconsMap.set('RF', SymbolIcons.getIconURL('RF'));
    this.iconsMap.set('RIG', SymbolIcons.getIconURL('RIG'));
    this.iconsMap.set('RIOT', SymbolIcons.getIconURL('RIOT'));
    this.iconsMap.set('RKT', SymbolIcons.getIconURL('RKT'));
    this.iconsMap.set('RL', SymbolIcons.getIconURL('RL'));
    this.iconsMap.set('RLX', SymbolIcons.getIconURL('RLX'));

    // S
    this.iconsMap.set('SEKNOK', SymbolIcons.getIconURL('SEKNOK'));
    this.iconsMap.set('SEK/NOK', SymbolIcons.getIconURL('SEKNOK'));
    this.iconsMap.set('SEK/JPY', SymbolIcons.getIconURL('SEKJPY'));
    this.iconsMap.set('SGDJPY', SymbolIcons.getIconURL('SGDJPY'));
    this.iconsMap.set('SGD/JPY', SymbolIcons.getIconURL('SGDJPY'));
    this.iconsMap.set('SPX500', SymbolIcons.getIconURL('SPX500'));
    this.iconsMap.set('SAN.MC', SymbolIcons.getIconURL('SAN.MC'));
    this.iconsMap.set('SAN', SymbolIcons.getIconURL('SAN.MC'));
    this.iconsMap.set('SIEGn.DE', SymbolIcons.getIconURL('SIEGn.DE'));
    this.iconsMap.set('SOGN.PA', SymbolIcons.getIconURL('SOGN.PA'));
    this.iconsMap.set('SB.f', SymbolIcons.getIconURL('SB.f'));
    this.iconsMap.set('SING.f', SymbolIcons.getIconURL('SING.f'));
    this.iconsMap.set('STOXX50.v', SymbolIcons.getIconURL('1EUR50'));
    this.iconsMap.set('STOXX50.c', SymbolIcons.getIconURL('1EUR50'));
    this.iconsMap.set('STOXX50.d', SymbolIcons.getIconURL('1EUR50'));
    this.iconsMap.set('SWI20.c', SymbolIcons.getIconURL('SWI20.c'));
    this.iconsMap.set('1SWI20', SymbolIcons.getIconURL('SWI20.c'));
    this.iconsMap.set('SANDUSDT', SymbolIcons.getIconURL('SANDUSDT'));
    this.iconsMap.set('SHIUSDT', SymbolIcons.getIconURL('SHIUSDT'));
    this.iconsMap.set('SOLUSDT', SymbolIcons.getIconURL('SOLUSDT'));
    this.iconsMap.set('SOLUSD', SymbolIcons.getIconURL('SOLUSD'));
    this.iconsMap.set('SBUX.OQ', SymbolIcons.getIconURL('SBUX.OQ'));
    this.iconsMap.set('SBUX', SymbolIcons.getIconURL('SBUX.OQ'));
    this.iconsMap.set('SNAP.N', SymbolIcons.getIconURL('SNAP.N'));
    this.iconsMap.set('SNAP', SymbolIcons.getIconURL('SNAP.N'));
    this.iconsMap.set('SPCE.N', SymbolIcons.getIconURL('SPCE.N'));
    this.iconsMap.set('SPCE', SymbolIcons.getIconURL('SPCE.N'));
    this.iconsMap.set('SPOT.N', SymbolIcons.getIconURL('SPOT.N'));
    this.iconsMap.set('SPOT', SymbolIcons.getIconURL('SPOT.N'));
    this.iconsMap.set('SQ.N', SymbolIcons.getIconURL('SQ.N'));
    this.iconsMap.set('SQ', SymbolIcons.getIconURL('SQ.N'));
    this.iconsMap.set('SQQQ', SymbolIcons.getIconURL('TQQQ.OQ'));
    this.iconsMap.set('SABR', SymbolIcons.getIconURL('SABR'));
    this.iconsMap.set('SAVE', SymbolIcons.getIconURL('SAVE'));
    this.iconsMap.set('SDC', SymbolIcons.getIconURL('SDC'));
    this.iconsMap.set('SE', SymbolIcons.getIconURL('SE'));
    this.iconsMap.set('SID', SymbolIcons.getIconURL('SID'));
    this.iconsMap.set('SIRI', SymbolIcons.getIconURL('SIRI'));
    this.iconsMap.set('SKLZ', SymbolIcons.getIconURL('SKLZ'));
    this.iconsMap.set('SLB', SymbolIcons.getIconURL('SLB'));
    this.iconsMap.set('SM', SymbolIcons.getIconURL('SM'));
    this.iconsMap.set('SNOW', SymbolIcons.getIconURL('SNOW'));
    this.iconsMap.set('SNPS', SymbolIcons.getIconURL('SNPS'));
    this.iconsMap.set('SO', SymbolIcons.getIconURL('SO'));
    this.iconsMap.set('SPG', SymbolIcons.getIconURL('SPG'));
    this.iconsMap.set('SPWR', SymbolIcons.getIconURL('SPWR'));
    this.iconsMap.set('SPY', SymbolIcons.getIconURL('SPY'));
    this.iconsMap.set('SQM', SymbolIcons.getIconURL('SQM'));
    this.iconsMap.set('SRNE', SymbolIcons.getIconURL('SRNE'));
    this.iconsMap.set('SU', SymbolIcons.getIconURL('SU'));
    this.iconsMap.set('SWN', SymbolIcons.getIconURL('SWN'));
    this.iconsMap.set('SYF', SymbolIcons.getIconURL('SYF'));
    this.iconsMap.set('SOYB.f', SymbolIcons.getIconURL('SOYB.f'));

    // T
    this.iconsMap.set('TRY/JPY', SymbolIcons.getIconURL('TRYJPY'));
    this.iconsMap.set('TEF.MCEUR', SymbolIcons.getIconURL('TEF.MCEUR'));
    this.iconsMap.set('TSLA.OQUSD', SymbolIcons.getIconURL('TSLA.OQUSD'));
    this.iconsMap.set('TRXUSD', SymbolIcons.getIconURL('TRXUSD'));
    this.iconsMap.set('T.N', SymbolIcons.getIconURL('T.N'));
    this.iconsMap.set('T', SymbolIcons.getIconURL('T.N'));
    this.iconsMap.set('TEVA.P', SymbolIcons.getIconURL('TEVA.P'));
    this.iconsMap.set('TEVA', SymbolIcons.getIconURL('TEVA.P'));
    this.iconsMap.set('TSLA.OQ', SymbolIcons.getIconURL('TSLA.OQ'));
    this.iconsMap.set('TSLA', SymbolIcons.getIconURL('TSLA.OQ'));
    this.iconsMap.set('TWTR.N', SymbolIcons.getIconURL('TWTR.N'));
    this.iconsMap.set('TWTR', SymbolIcons.getIconURL('TWTR.N'));
    this.iconsMap.set('TEF.MC', SymbolIcons.getIconURL('TEF.MC'));
    this.iconsMap.set('TOTF.PA', SymbolIcons.getIconURL('TOTF.PA'));
    this.iconsMap.set('TY.f', SymbolIcons.getIconURL('TY.f'));
    this.iconsMap.set('THETAUSDT', SymbolIcons.getIconURL('THETAUSDT'));
    this.iconsMap.set('TRXUSDT', SymbolIcons.getIconURL('TRXUSDT'));
    this.iconsMap.set('THETAUSD', SymbolIcons.getIconURL('THETAUSD'));
    this.iconsMap.set('TGT.N', SymbolIcons.getIconURL('TGT.N'));
    this.iconsMap.set('TGT', SymbolIcons.getIconURL('TGT.N'));
    this.iconsMap.set('TLRY.OQ', SymbolIcons.getIconURL('TLRY.OQ'));
    this.iconsMap.set('TLRY', SymbolIcons.getIconURL('TLRY.OQ'));
    this.iconsMap.set('TME.N', SymbolIcons.getIconURL('TME.N'));
    this.iconsMap.set('TQQQ.OQ', SymbolIcons.getIconURL('TQQQ.OQ'));
    this.iconsMap.set('TSCO.L', SymbolIcons.getIconURL('TSCO.L'));
    this.iconsMap.set('TW.L', SymbolIcons.getIconURL('TW.L'));
    this.iconsMap.set('TAL', SymbolIcons.getIconURL('TAL'));
    this.iconsMap.set('TEO', SymbolIcons.getIconURL('TEO'));
    this.iconsMap.set('TIGR', SymbolIcons.getIconURL('TIGR'));
    this.iconsMap.set('TJX', SymbolIcons.getIconURL('TJX'));
    this.iconsMap.set('TME', SymbolIcons.getIconURL('TME.N'));
    this.iconsMap.set('TRV', SymbolIcons.getIconURL('TRV'));
    this.iconsMap.set('TS', SymbolIcons.getIconURL('TS'));
    this.iconsMap.set('TSM', SymbolIcons.getIconURL('TSM'));
    this.iconsMap.set('TTWO', SymbolIcons.getIconURL('TTWO'));
    this.iconsMap.set('TXN', SymbolIcons.getIconURL('TXN'));
    this.iconsMap.set('TY10.f', SymbolIcons.getIconURL('TY10.f'));

    // U
    this.iconsMap.set('USD/COP', SymbolIcons.getIconURL('USDCOP'));
    this.iconsMap.set('USDCOP', SymbolIcons.getIconURL('USDCOP'));
    this.iconsMap.set('USD/TWD', SymbolIcons.getIconURL('USDTWD'));
    this.iconsMap.set('USDTWD', SymbolIcons.getIconURL('USDTWD'));
    this.iconsMap.set('USD/CLP', SymbolIcons.getIconURL('USDCLP'));
    this.iconsMap.set('USDCLP', SymbolIcons.getIconURL('USDCLP'));
    this.iconsMap.set('USDCAD', SymbolIcons.getIconURL('USDCAD'));
    this.iconsMap.set('USD/CAD', SymbolIcons.getIconURL('USDCAD'));
    this.iconsMap.set('USDCHF', SymbolIcons.getIconURL('USDCHF'));
    this.iconsMap.set('USD/CHF', SymbolIcons.getIconURL('USDCHF'));
    this.iconsMap.set('USDJPY', SymbolIcons.getIconURL('USDJPY'));
    this.iconsMap.set('USD/JPY', SymbolIcons.getIconURL('USDJPY'));
    this.iconsMap.set('USDCNH', SymbolIcons.getIconURL('USDCNH'));
    this.iconsMap.set('USD/CNH', SymbolIcons.getIconURL('USDCNH'));
    this.iconsMap.set('USDDKK', SymbolIcons.getIconURL('USDDKK'));
    this.iconsMap.set('USD/DKK', SymbolIcons.getIconURL('USDDKK'));
    this.iconsMap.set('USDHKD', SymbolIcons.getIconURL('USDHKD'));
    this.iconsMap.set('USD/HKD', SymbolIcons.getIconURL('USDHKD'));
    this.iconsMap.set('USDMXN', SymbolIcons.getIconURL('USDMXN'));
    this.iconsMap.set('USD/MXN', SymbolIcons.getIconURL('USDMXN'));
    this.iconsMap.set('USDNOK', SymbolIcons.getIconURL('USDNOK'));
    this.iconsMap.set('USD/NOK', SymbolIcons.getIconURL('USDNOK'));
    this.iconsMap.set('USDPLN', SymbolIcons.getIconURL('USDPLN'));
    this.iconsMap.set('USD/PLN', SymbolIcons.getIconURL('USDPLN'));
    this.iconsMap.set('USDRUB', SymbolIcons.getIconURL('USDRUB'));
    this.iconsMap.set('USD/RUB', SymbolIcons.getIconURL('USDRUB'));
    this.iconsMap.set('USDSEK', SymbolIcons.getIconURL('USDSEK'));
    this.iconsMap.set('USD/SEK', SymbolIcons.getIconURL('USDSEK'));
    this.iconsMap.set('USDSGD', SymbolIcons.getIconURL('USDSGD'));
    this.iconsMap.set('USD/SGD', SymbolIcons.getIconURL('USDSGD'));
    this.iconsMap.set('USDTRY', SymbolIcons.getIconURL('USDTRY'));
    this.iconsMap.set('USD/TRY', SymbolIcons.getIconURL('USDTRY'));
    this.iconsMap.set('USDZAR', SymbolIcons.getIconURL('USDZAR'));
    this.iconsMap.set('USD/ZAR', SymbolIcons.getIconURL('USDZAR'));
    this.iconsMap.set('USD', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('USDHUF', SymbolIcons.getIconURL('USDHUF'));
    this.iconsMap.set('USD/HUF', SymbolIcons.getIconURL('USDHUF'));
    this.iconsMap.set('USD/CZK', SymbolIcons.getIconURL('USDCZK'));
    this.iconsMap.set('USD/ILS', SymbolIcons.getIconURL('USDILS'));
    this.iconsMap.set('USD/THB', SymbolIcons.getIconURL('USDTHB'));
    this.iconsMap.set('USD/INR', SymbolIcons.getIconURL('USDINR'));
    this.iconsMap.set('USD/IDR', SymbolIcons.getIconURL('USDIDR'));
    this.iconsMap.set('USD/KRW', SymbolIcons.getIconURL('USDKRW'));
    this.iconsMap.set('USD/BRL', SymbolIcons.getIconURL('USDBRL'));
    this.iconsMap.set('US30', SymbolIcons.getIconURL('US30'));
    this.iconsMap.set('USOIL', SymbolIcons.getIconURL('USOIL'));
    this.iconsMap.set('USOIL.f', SymbolIcons.getIconURL('USOIL'));
    this.iconsMap.set('USOIL.d', SymbolIcons.getIconURL('USOIL'));
    this.iconsMap.set('USTEC.v', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('USTEC.c', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('USTEC.f', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('USTEC.d', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('1UKO', SymbolIcons.getIconURL('CL_BRENT'));
    this.iconsMap.set('UKOIL.c', SymbolIcons.getIconURL('CL_BRENT'));
    this.iconsMap.set('UKOIL.f', SymbolIcons.getIconURL('CL_BRENT'));
    this.iconsMap.set('UKOIL.d', SymbolIcons.getIconURL('CL_BRENT'));
    this.iconsMap.set('1USO', SymbolIcons.getIconURL('1USO'));
    this.iconsMap.set('USO', SymbolIcons.getIconURL('1USO'));
    this.iconsMap.set('US500', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US500.v', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US500.c', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US500.f', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US500.d', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US500s.v', SymbolIcons.getIconURL('USD'));
    this.iconsMap.set('US2000.c', SymbolIcons.getIconURL('US2000.c'));
    this.iconsMap.set('US2000.v', SymbolIcons.getIconURL('US2000.c'));
    this.iconsMap.set('US2000.d', SymbolIcons.getIconURL('US2000.c'));
    this.iconsMap.set('1UK100', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('UK100', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('UK100.v', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('UK100.c', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('UK100.d', SymbolIcons.getIconURL('GBP'));
    this.iconsMap.set('UNIUSDT', SymbolIcons.getIconURL('UNIUSDT'));
    this.iconsMap.set('UNIUSD', SymbolIcons.getIconURL('UNIUSD'));
    this.iconsMap.set('USDCUSD', SymbolIcons.getIconURL('USDCUSD'));
    this.iconsMap.set('UBER.N', SymbolIcons.getIconURL('UBER.N'));
    this.iconsMap.set('UBER', SymbolIcons.getIconURL('UBER.N'));
    this.iconsMap.set('UAA', SymbolIcons.getIconURL('UAA'));
    this.iconsMap.set('UAL', SymbolIcons.getIconURL('UAL'));
    this.iconsMap.set('UMC', SymbolIcons.getIconURL('UMC'));
    this.iconsMap.set('UNP', SymbolIcons.getIconURL('UNP'));
    this.iconsMap.set('UPS', SymbolIcons.getIconURL('UPS'));
    this.iconsMap.set('USB', SymbolIcons.getIconURL('USB'));
    this.iconsMap.set('UVXY', SymbolIcons.getIconURL('UVXY'));
    this.iconsMap.set('UWMC', SymbolIcons.getIconURL('UWMC'));
    this.iconsMap.set('USCORN.f', SymbolIcons.getIconURL('USCORN.f'));
    this.iconsMap.set('USCOFFEE.f', SymbolIcons.getIconURL('USCOFFEE.f'));
    this.iconsMap.set('UKBRENT.f', SymbolIcons.getIconURL('UKBRENT.f'));
    this.iconsMap.set('USWHEAT.f', SymbolIcons.getIconURL('USWHEAT.f'));
    this.iconsMap.set('US400.v', SymbolIcons.getIconURL('US400.v'));

    // V
    this.iconsMap.set('VOWG_p.DE', SymbolIcons.getIconURL('VOWG_p.DE'));
    this.iconsMap.set('VETUSDT', SymbolIcons.getIconURL('VETUSDT'));
    this.iconsMap.set('VETUSD', SymbolIcons.getIconURL('VETUSD'));
    this.iconsMap.set('V.N', SymbolIcons.getIconURL('V.N'));
    this.iconsMap.set('V', SymbolIcons.getIconURL('V.N'));
    this.iconsMap.set('VIPS.N', SymbolIcons.getIconURL('VIPS.N'));
    this.iconsMap.set('VOD.L', SymbolIcons.getIconURL('VOD.L'));
    this.iconsMap.set('VIV', SymbolIcons.getIconURL('TEF.MC'));
    this.iconsMap.set('VALE', SymbolIcons.getIconURL('VALE'));
    this.iconsMap.set('VIAC', SymbolIcons.getIconURL('VIAC'));
    this.iconsMap.set('VLO', SymbolIcons.getIconURL('VLO'));
    this.iconsMap.set('VST', SymbolIcons.getIconURL('VST'));
    this.iconsMap.set('VTRS', SymbolIcons.getIconURL('VTRS'));
    this.iconsMap.set('VXX', SymbolIcons.getIconURL('VXX'));
    this.iconsMap.set('VZ', SymbolIcons.getIconURL('VZ'));
    this.iconsMap.set('VZ.N', SymbolIcons.getIconURL('VZ'));
    this.iconsMap.set('VIX.c', SymbolIcons.getIconURL('VIX.c'));

    // W
    this.iconsMap.set('WTI_OIL', SymbolIcons.getIconURL('USOIL'));
    this.iconsMap.set('WAVESUSDT', SymbolIcons.getIconURL('WAVESUSDT'));
    this.iconsMap.set('WAVESUSD', SymbolIcons.getIconURL('WAVESUSD'));
    this.iconsMap.set('WMT.N', SymbolIcons.getIconURL('WMT.N'));
    this.iconsMap.set('WMT', SymbolIcons.getIconURL('WMT.N'));
    this.iconsMap.set('WUBA.N', SymbolIcons.getIconURL('WUBA.N'));
    this.iconsMap.set('WYNN.OQ', SymbolIcons.getIconURL('WYNN.OQ'));
    this.iconsMap.set('WYNN', SymbolIcons.getIconURL('WYNN.OQ'));
    this.iconsMap.set('WDC', SymbolIcons.getIconURL('WDC'));
    this.iconsMap.set('WFC', SymbolIcons.getIconURL('WFC'));
    this.iconsMap.set('WHR', SymbolIcons.getIconURL('WHR'));
    this.iconsMap.set('WISH', SymbolIcons.getIconURL('WISH'));
    this.iconsMap.set('WLL', SymbolIcons.getIconURL('WLL'));
    this.iconsMap.set('WMB', SymbolIcons.getIconURL('WMB'));
    this.iconsMap.set('WU', SymbolIcons.getIconURL('WU'));

    // X
    this.iconsMap.set('XAG', SymbolIcons.getIconURL('XAG'));
    this.iconsMap.set('XAU', SymbolIcons.getIconURL('XAU'));
    this.iconsMap.set('1XAL', SymbolIcons.getIconURL('XAL'));
    this.iconsMap.set('1XCU', SymbolIcons.getIconURL('XCU'));
    this.iconsMap.set('1XPB', SymbolIcons.getIconURL('XPB'));
    this.iconsMap.set('1XZN', SymbolIcons.getIconURL('XZN'));
    this.iconsMap.set('1XNI', SymbolIcons.getIconURL('XNI'));
    this.iconsMap.set('XAU/GBP', SymbolIcons.getIconURL('XAUGBP'));
    this.iconsMap.set('XAUGBP', SymbolIcons.getIconURL('XAUGBP'));
    this.iconsMap.set('XAU/AUD', SymbolIcons.getIconURL('XAUAUD'));
    this.iconsMap.set('XAUAUD', SymbolIcons.getIconURL('XAUAUD'));
    this.iconsMap.set('XAGEUR', SymbolIcons.getIconURL('XAGEUR'));
    this.iconsMap.set('XAG/EUR', SymbolIcons.getIconURL('XAGEUR'));
    this.iconsMap.set('XAUEUR', SymbolIcons.getIconURL('XAUEUR'));
    this.iconsMap.set('XAU/EUR', SymbolIcons.getIconURL('XAUEUR'));
    this.iconsMap.set('XPT/USD', SymbolIcons.getIconURL('XPTUSD'));
    this.iconsMap.set('XPD/USD', SymbolIcons.getIconURL('XPDUSD'));
    this.iconsMap.set('XAGUSD', SymbolIcons.getIconURL('XAGUSD'));
    this.iconsMap.set('XAG/USD', SymbolIcons.getIconURL('XAGUSD'));
    this.iconsMap.set('XAUUSD', SymbolIcons.getIconURL('XAUUSD'));
    this.iconsMap.set('XAU/USD', SymbolIcons.getIconURL('XAUUSD'));
    this.iconsMap.set('XBRUSD', SymbolIcons.getIconURL('XBRUSD'));
    this.iconsMap.set('XNGUSD', SymbolIcons.getIconURL('XNGUSD'));
    this.iconsMap.set('XTIUSD', SymbolIcons.getIconURL('XTIUSD'));
    this.iconsMap.set('XRPUSD', SymbolIcons.getIconURL('XRPUSD'));
    this.iconsMap.set('XRPUSD.b', SymbolIcons.getIconURL('XRPUSD'));
    this.iconsMap.set('XRPUSDc', SymbolIcons.getIconURL('XRPUSD'));
    this.iconsMap.set('XMRUSD', SymbolIcons.getIconURL('XMRUSD'));
    this.iconsMap.set('XLMUSDT', SymbolIcons.getIconURL('XLMUSDT'));
    this.iconsMap.set('XMRUSDT', SymbolIcons.getIconURL('XMRUSDT'));
    this.iconsMap.set('XRPUSDT', SymbolIcons.getIconURL('XRPUSDT'));
    this.iconsMap.set('XTZUSDT', SymbolIcons.getIconURL('XTZUSDT'));
    this.iconsMap.set('XECUSD', SymbolIcons.getIconURL('XECUSD'));
    this.iconsMap.set('XTZUSD', SymbolIcons.getIconURL('XTZUSD'));
    this.iconsMap.set('XOM.N', SymbolIcons.getIconURL('XOM.N'));
    this.iconsMap.set('XOM', SymbolIcons.getIconURL('XOM.N'));
    this.iconsMap.set('XPEV.N', SymbolIcons.getIconURL('XPEV.N'));
    this.iconsMap.set('XPEV', SymbolIcons.getIconURL('XPEV.N'));
    this.iconsMap.set('XOP', SymbolIcons.getIconURL('SPY'));
    this.iconsMap.set('X', SymbolIcons.getIconURL('X'));
    this.iconsMap.set('XLF', SymbolIcons.getIconURL('XLF'));
    this.iconsMap.set('XRX', SymbolIcons.getIconURL('XRX'));
    this.iconsMap.set('1XMRBTC', SymbolIcons.getIconURL('1XMRBTC'));
    this.iconsMap.set('1XRPBIT', SymbolIcons.getIconURL('1XRPBIT'));

    // Y
    this.iconsMap.set('YNDX.OQ', SymbolIcons.getIconURL('YNDX.OQ'));
    this.iconsMap.set('YUM.N', SymbolIcons.getIconURL('YUM.N'));
    this.iconsMap.set('YPF', SymbolIcons.getIconURL('YPF'));

    // Z
    this.iconsMap.set('ZARJPY', SymbolIcons.getIconURL('ZARJPY'));
    this.iconsMap.set('ZAR/JPY', SymbolIcons.getIconURL('ZARJPY'));
    this.iconsMap.set('ZECUSD', SymbolIcons.getIconURL('ZECUSD'));
    this.iconsMap.set('ZECUSDT', SymbolIcons.getIconURL('ZECUSDT'));
    this.iconsMap.set('ZENUSDT', SymbolIcons.getIconURL('ZENUSDT'));
    this.iconsMap.set('ZILUSDT', SymbolIcons.getIconURL('ZILUSDT'));
    this.iconsMap.set('ZM.OQ', SymbolIcons.getIconURL('ZM.OQ'));
    this.iconsMap.set('ZM', SymbolIcons.getIconURL('ZM.OQ'));
    this.iconsMap.set('ZNGA', SymbolIcons.getIconURL('ZNGA'));
    this.iconsMap.set('1ZECBTC', SymbolIcons.getIconURL('1ZECBTC'));

  }
}
