import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TradeService} from '@common/trade/services/trade.service';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {Symbol} from '@common/symbol/models/symbol';
import {translate} from '@common/locale/servises/translator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalOpenPendingOrderComponent} from '../modal-open-pending-order/modal-open-pending-order.component';
import {ITradePromise} from '@common/trade/models/trade-promise';
import moment from 'moment';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {TradeEventsAggregatorService} from '@common/trade/services/trade-events-aggregator.service';
import {SettingsService} from '@common/trader/services/settings.service';
import {AppConfig} from '@common/configuration/app-config';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';
import {ReactiveFormsModule} from '@angular/forms';
import {LocaleModule} from '@common/locale/locale.module';
import {NgForOf, NgIf} from '@angular/common';
import {SharedModule} from '@common/shared/shared.module';
import {TradeModule} from '@common/trade/trade.module';
import {SlTpInputComponent} from '@common/trade/components/sltp-input/sltp-input.component';
import {Trader} from '@common/trader/models/trader';
import {NotificationService} from '@common/notification/services/notification.service';
import {Settings} from '@common/trader/models/settings';

@Component({
  selector: 'modal-modify-open-pending-order',
  templateUrl: './modal-modify-pending-order.component.html',
  styleUrls: ['./modal-modify-pending-order.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    ModalPlaceholderComponent,
    ReactiveFormsModule,
    LocaleModule,
    NgForOf,
    SharedModule,
    NgIf,
    TradeModule,
    SlTpInputComponent
  ],
  standalone: true
})
export class ModalModifyPendingOrderComponent extends ModalOpenPendingOrderComponent implements OnInit {
  public Header: string = translate('Modal_ModifyPendingOrderModalComponent_Header');
  resetDateHidden: boolean;
  private triggerNotification: boolean;

  public appName = '';

  public get Symbols(): Symbol[] {
    return this.symbolStorage.Symbols;
  }

  private showPicker: boolean;

  public get ShowPicker(): boolean {
    return this.showPicker;
  }

  constructor(symbolStorage: SymbolStorageService,
              tradeStorage: TradeStorage,
              tradeService: TradeService,
              router: Router,
              trader: Trader,
              notificationService: NotificationService,
              settings: Settings,
              private route: ActivatedRoute,
              private tradeEventsAggregatorService: TradeEventsAggregatorService,
              protected settingsService: SettingsService,
              private appConfig: AppConfig) {
    super(symbolStorage, tradeStorage, tradeService, router, trader, notificationService, settings);
    this.resetDateHidden = true;
    this.triggerNotification = true;
    this.tradeEventsAggregatorService.PendingActivated.subscribe(() => {
      this.triggerNotification = false;
    });

    if (this.appConfig.Settings.common !== undefined && this.appConfig.Settings.common.AppName !== undefined) {
      this.appName = this.appConfig.Settings.common.AppName;
    }
  }

  public get DatePreview(): string {
    if (this.order.ExpirationDate.IsEmpty) {
      return translate('Modal_PendingOrderFormComponent_UnsetDate');
    } else {
      const date = new Date(this.order.ExpirationDate.Date);
      return moment(date).format('DD MMM YYYY, HH:mm');
    }
  }

  protected initOrder() {
    const id = this.route.snapshot.params['id'];
    const trade = this.tradeStorage.getTrade(Number(id));

    this.order = new TradeDTO().readFromTrade(trade);

    if (!this.order.ExpirationDate.IsEmpty) {
      this.resetDateHidden = false;
      console.log(this.order.ExpirationDate.IsEmpty);
    }
  }

  protected formInit() {
    super.formInit();

    this.amount.setValue(this.getVolume(this.order.Volume));
    this.type.setValue(this.order.Type);

    this.type.disable();
    this.symbol.disable();

    this.rate.setValue(this.order.OpenPrice);
    const formattedDate = moment(this.order.ExpirationDate.Date).format('YYYY-MM-DDTHH:mm');
    this.expDate.setValue(formattedDate);

    this.subscribeToSymbol();
  }

  protected onSubmitSuccess() {
    this.close();
  }

  protected orderAction(): Promise<ITradePromise> {
    return this.tradeService.updatePosition(this.order);
  }

  public close() {
    this.router.navigate(['/terminal', {outlet: {modal: null}}]);
  }

  public onDateClick(event: MouseEvent) {
    // event.stopPropagation();
    if (this.triggerNotification) {
      this.openDateTimePicker();
    }
  }

  public onResetDate(event: MouseEvent): void {
    if (!this.order.ExpirationDate.IsEmpty) {
      this.order.ExpirationDate.IsEmpty = true;
      this.resetDateHidden = true;
    }
  }

  public onDateChange(v: Date) {
    const formattedDate = moment(v).format('YYYY-MM-DDTHH:mm');
    this.expDate.setValue(formattedDate);

    super.onDateChange(v);
  }

  private openDateTimePicker() {
    this.showPicker = true;
  }

  public closeDateTimePicker() {
    this.showPicker = false;
  }

  public onSelectDate(date: Date) {
    this.onDateChange(date);
    this.closeDateTimePicker();

    if (!this.order.ExpirationDate.IsEmpty) {
      this.resetDateHidden = false;
    }
  }

  public isHiddenTriggerNotification(): boolean {
    return this.triggerNotification;
  }

  public isDisabledButtons(): boolean {
    return !this.triggerNotification;
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

}

