import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Trader} from '@common/trader/models/trader';
import {TradeType} from '@common/trade/models/trade-type';
import {AuthenticationService} from '@common/auth/services/authentication.service';
import {AppConfig} from '@common/configuration/app-config';
import {SaveViewService} from '@common/shared/services/save-view.service';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {Notification} from '@common/notification/models/notification';
import {Settings} from '@common/trader/models/settings';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ThemeService} from '@common/shared/services/theme';
import {CheckingSleepModeService} from '@common/shared/services/checking-sleep-mode.service';
import {OperationsWithUrl} from '@common/trade/utils/operations-with-url';
import {AlertChangesPriceService} from '@common/shared/services/alert-changes-price.service';
import {TradeService} from '@common/trade/services/trade.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import { Symbol } from '@common/symbol/models/symbol';
import {UpdatingDataByTime} from '@common/shared/services/updating-data-by-time';
import {TradeHistoryService} from '@common/shared/services/History/trade-history.service';
import {TransactionsService} from '@common/shared/services/Transactions/transactions-service';
import {Accounting} from '@common/trader/models/accounting.service';
import {BrowserPageService} from '@common/trade/services/browser-page.service';

@Component({
  selector: 'terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
  providers: [SaveViewService]
})
export class TerminalComponent implements OnInit, OnDestroy {

  private newMessage: Notification[] = [];
  private hideMessage = false;
  private textBodyChallenge = [];
  private userInactivityTime: number;
  private timeInactivity;
  private isShowWindowModeDebugUsers = false;
  private _showButtonTheme = false;

  private _showButtonModeDebugUsers = false;
  private _showButtonBOAnalytics = false;

  public appName = '';
  private hiddenIconTopMenu: boolean;

  private _showChangePassword = true;

  private readonly _topUpYourDeposit: string;

  public get IsShowChangePassword(): boolean {
    return this._showChangePassword;
  }

  private get ShowInstallButton() {
    return window['deferredPrompt'] && !this.localStorage.getInstallDeclined();
  }

  get IsReadOnlyAccount(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  get TraderName(): string {
    return this.trader.TraderName;
  }

  get TradeGroupName(): string {
    return this.trader.TradeGroupName;
  }

  get ShowTradeGroupName(): boolean {
    if (this.appConfig.Settings.showTradeGroupName !== undefined) {
      return this.appConfig.Settings.showTradeGroupName;
    } else {
      return false;
    }
  }

  get IsViewsMenuAvailable(): boolean {
    return this.appConfig.Settings.platformLayout.IsViewsMenuAvailable;
  }

  get ShowButtonModeDebugUsers(): boolean {
    return this._showButtonModeDebugUsers;
  }

  get ShowButtonBOAnalytics(): boolean {
    return this._showButtonBOAnalytics;
  }

  get IsShowWindowModeDebugUsers(): boolean {
    return this.isShowWindowModeDebugUsers;
  }

  public ShowWindowModeDebugUsers() {
    this.isShowWindowModeDebugUsers = !this.isShowWindowModeDebugUsers;
  }

  constructor(private router: Router,
              private auth: AuthenticationService,
              private localStorage: LocalStorageService,
              private trader: Trader,
              private appConfig: AppConfig,
              private saveViewService: SaveViewService,
              private translatorService: TranslatorService,
              protected settings: Settings,
              private swUpdate: SwUpdate,
              private http: HttpClient,
              private themeService: ThemeService,
              private checkingSleepModeService: CheckingSleepModeService,
              private alertChangesPriceService: AlertChangesPriceService,
              private tradeService: TradeService,
              private symbolStorage: SymbolStorageService,
              private updatingDataByTime: UpdatingDataByTime,
              private tradeHistoryService: TradeHistoryService,
              private transactionsService: TransactionsService,
              public accounting: Accounting,
              private browserPageService: BrowserPageService) {

    this.checkingSleepModeService.run();

    if (this.swUpdate.isEnabled) {
      let dateRelease: number;
      this.getJSON('./ngsw.json').subscribe((data) => {
        if (data !== undefined && data.timestamp !== undefined) {
          dateRelease = data.timestamp;
        } else {
          dateRelease = new Date().getTime();
        }
      });

      const runTimer = setTimeout(() => {
        const result = JSON.parse(sessionStorage.getItem('needReloadAppNewVersion'));
        console.log('result', result);
        if (result !== null && result) {
          this.router.navigate(['/terminal', {outlets: {modal: ['update-app']}}]);
        }
      }, 5000);

      console.log('start runTimer()');

      this.swUpdate.versionUpdates.subscribe((ev: VersionEvent) => {
        console.log(ev);
        if (ev.type === 'VERSION_READY') {
          const version = this.appConfig.Settings.AppVersion ? this.appConfig.Settings.AppVersion : '1.0.0.0';
          this.localStorage.saveInfoUpdateApp(dateRelease, version);
          this.router.navigate(['/terminal', {outlets: {modal: ['update-app']}}]);
          clearTimeout(runTimer);
          console.log('stop runTimer()');
        }
      });
    }

    if (this.trader.ShouldChangePassword) {
      setTimeout(() => {
        this.router.navigate(['/terminal', {outlets: {modal: ['change-password']}}]);

        this.trader.ChangePasswordEvent.subscribe((answer) => {
          if (answer === 'Success') {
            this.tryShowInstallPrompt();
          }
        });
      }, 200);
    } else {
      this.tryShowInstallPrompt();
    }

    if (this.appConfig.Settings.colorThemeChangeMode !== undefined && this.appConfig.Settings.colorThemeChangeMode || this.appConfig.Settings.common.AppName === 'Zetradex') {
      this._showButtonTheme = true;
    }

    this._showButtonBOAnalytics = this.trader.checkUsersWhoHaveAccess('showButtonBOAnalytics', 'listOfUsersWhoHaveAccessBOAnalytics');
    this._showButtonModeDebugUsers = this.trader.checkUsersWhoHaveAccess('modeDebugUsers', 'listUsersAccessModeDebug');
    sessionStorage.setItem('ModeDebugUsers', JSON.stringify(this._showButtonModeDebugUsers));

    if (this.appConfig.Settings.common !== undefined && this.appConfig.Settings.common.AppName !== undefined) {
      this.appName = this.appConfig.Settings.common.AppName;

    }

    if (this.appConfig.Settings.hiddenIconTopMenu !== undefined) {
      this.hiddenIconTopMenu = this.appConfig.Settings.hiddenIconTopMenu;
    }

    if (this.appConfig.Settings.customIntegrationSetting && this.appConfig.Settings.customIntegrationSetting.topUpYourDeposit ) {
      this._topUpYourDeposit = this.appConfig.Settings.customIntegrationSetting.topUpYourDeposit;
    }

    if (this.appConfig.Settings.timeToReRequestBalance !== undefined && this.appConfig.Settings.timeToReRequestBalance !== 0 ) {
      setInterval(() => this.accounting.loadBalance(), this.appConfig.Settings.timeToReRequestBalance );
    }
  }

  public  get IsHiddenIconTopMenu(): boolean {
    return !this.hiddenIconTopMenu;
  }

  private getJSON(path: string): Observable<any> {
    return this.http.get(path);
  }



  private tryShowInstallPrompt() {
    if (this.ShowInstallButton) {
      setTimeout(() => {
        this.router.navigate(['/terminal', {outlets: {modal: ['install']}}]);
      }, 200);
    }
  }

  openBuyModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.Buy, {volume: this.getVolumeForOpenOrders(TradeType.Buy) }]}}]);
  }

  openSellModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.Sell, {volume: this.getVolumeForOpenOrders(TradeType.Sell) }]}}]);
  }

  openSettingsModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['settings']}}]);
  }

  openPendingOrderModal($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.router.navigate(['/terminal', {outlets: {modal: ['multi-order', TradeType.BuyLimit, {volume: this.getVolumeForOpenOrders(TradeType.BuyLimit) }]}}]);
  }

  private getVolumeForOpenOrders(type: TradeType) {
    const symbol = this.getSymbolForTrader();
    const price = symbol.Bid;
    return this.tradeService.getVolumeForOpenOrders(symbol, price, type );
  }

  private getSymbolForTrader() {
    const tempArrayChart: any[] = JSON.parse(localStorage.getItem('chartSymbolNameForTrader'));
    let check = false;
    let symbol: Symbol;

    if (tempArrayChart !== null) {
      tempArrayChart.forEach((e) => {
        if (e.traderID === this.trader.TraderId) {
          symbol = this.symbolStorage.findSymbolByNameSync(e.symbol);
          check = true;
        }
      });

      if (!check) {
        symbol = this.symbolStorage.getFirstSymbol();
      }

    } else {
      symbol = this.symbolStorage.getFirstSymbol();
    }

    return symbol;
  }

  openBackoffice($event: MouseEvent, key: string) {
    $event.stopImmediatePropagation();
    const sessionID = this.localStorage.getSessionKey();
    OperationsWithUrl.goToBackoffice(this.appConfig.Settings, this.trader, this.translatorService.CurrentLocale, sessionID, key);
  }

  topUpYourDeposit() {
    const sessionID = this.localStorage.getSessionKey();
    let url = this._topUpYourDeposit;
    if (!this.IsZeTradex) {
      url = url + `?SessionID=${sessionID}`;
    }
    OperationsWithUrl.openWindow(url);
  }

  get IsUrlPathDeposit(): boolean {
    return this._topUpYourDeposit && !this.IsReadOnlyAccount ;
  }

  async signOut(event: MouseEvent) {
    event.stopPropagation();
    this.checkingSleepModeService.logout();
    this.tradeHistoryService.destroyArray();
    this.transactionsService.destroyArray();
    this.localStorage.clearTemplatesToDisplay();
    this.auth.clearUsersInSystem(this.trader.TraderName);
    await this.auth.logout();
  }

  async changePassword(event: MouseEvent) {
    event.stopPropagation();
    await this.router.navigate(['/terminal', {outlets: {modal: ['change-password']}}]);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.auth.sendLogout().then();
  }

  ngOnInit() {
    if (this.appConfig.Settings.userInactivityTime !== undefined && this.appConfig.Settings.userInactivityTime > 0) {
      this.userInactivityTime = 60000 * this.appConfig.Settings.userInactivityTime;
      this.inactivityTime();
      this.resetTimer();
    }

    if (this.appConfig.Settings.alertPriceChange !== undefined && this.appConfig.Settings.alertPriceChange ) {
      this.alertChangesPriceService.init(true);
    }

    this.tradeHistoryService.run('WebTrader', 99999);
    this.transactionsService.run('WebTrader', 99999);
    this.updatingDataByTime.init();
    localStorage.removeItem('ArrayConnectionUrlsBalancedLengths');

    if (this.appConfig.Settings.showChangePassword !== undefined) {
      this._showChangePassword = this.appConfig.Settings.showChangePassword;
    }

    this.browserPageService.initCount();
  }

  ngOnDestroy(): void {
    this.browserPageService.closeWindow();
    this.saveViewService.destroy();
  }

  // отображение данных сообщений
  public get Header(): string {
    return this.newMessage['header'];
  }

  public get Date(): Date {
    return this.newMessage['time'];
  }

  public get Body(): any {
    return this.textBodyChallenge;
  }

  // закрыть модальное окно

  public onCloseMessageClick() {
    this.textBodyChallenge = [];
    this.hideMessage = false;
  }

  // метод для проверки показа сообщения

  public showMessage(): boolean {
    if (sessionStorage.getItem('MessageChallenge') !== null) {
      const messageChallenge = JSON.parse(sessionStorage.getItem('MessageChallenge'));
      this.showMessageChallenge(messageChallenge);
      sessionStorage.removeItem('MessageChallenge');
    }
    return this.hideMessage;
  }

  public showMessageChallenge(message) {

    const textBody = message.body.split('Your');
    textBody.splice(0, 1);
    textBody.forEach((e) => {
      this.textBodyChallenge.push('Your' + e);
    });

    this.newMessage = message;
    this.hideMessage = true;
  }

  public stopPropagationForMessageChallenge(event: MouseEvent): void {
    event.stopPropagation();
  }

  // Слушатель активности пользователя на графике т.к. это iframe и обычные слушатели не улавливают активность на нем

  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: any): void {
    if (this.appConfig.Settings.userInactivityTime !== undefined && this.appConfig.Settings.userInactivityTime > 0) {
      this.resetTimer();
    }
  }

  // события для проверки активности пользователя

  private inactivityTime() {
    // сюда можно добавить любой ивент.
    document.addEventListener('mousemove', this.resetTimer);
    document.addEventListener('keypress', this.resetTimer);
    document.addEventListener('click', this.resetTimer);
  }

  // метод для сброса счетчика не активности пользователя, время не активности 10 минут.

  private resetTimer() {
    clearTimeout(this.timeInactivity);
    this.timeInactivity = setTimeout(() => {
      // console.log('There is no user activity in the app.');
      if (this.auth !== undefined) {
        this.auth.logout().then();
      }
    }, this.userInactivityTime);
  }


  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {

    const pageState = {
      traderName: this.trader.TraderName,
      pageReload: true
    };
    sessionStorage.setItem('pageReloadEvent', JSON.stringify(pageState));
    // $event.returnValue = 'Your data will be lost!';
    this.auth.logout().then();
  }

  // theme
  get ShowButtonTheme() {
    return this._showButtonTheme;
  }

  public changeThemeOrShowOptions() {
    if (this.IsZeTradex) {
      const active = this.themeService.getActiveTheme();
      if (active.name === 'tvZetradexDarkTheme') {
        this.changeTheme('tvZetradexLightTheme');
      } else if (active.name === 'tvZetradexLightTheme') {
        this.changeTheme('tvZetradexDarkTheme');
      }
    }
  }

  public changeTheme (theme: string) {
    this.themeService.setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  get ShowNotificationCheckConnection() {
    return this.checkingSleepModeService.ShowNotificationCheckConnection;
  }

  public  getLogoCurrentThemeColor() {
    if ( this.themeService.getActiveTheme().category === 'dark' ) {
      return 'logo-dark-theme';
    } else {
      return 'logo-light-theme';
    }
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  get IsFXLiveMarketsTrader(): boolean {
    return this.appName === 'FXLiveMarkets Trader' ;
  }

}
