import {environment} from '../environments/environment';
import {LogLevel} from '@common/common/utils/logging/log-level.enum';

export class Environment {
  public static get IsMultiChartAllowed(): boolean {
    return environment.chart !== 'tv';
  }

  // public static get IsUseAutoTradeSizingAllowed(): boolean {
  //   return environment.chart !== 'tv';
  // }

  public static get ChartType(): string {
    return environment.chart;
  }

  public static get ChartCount(): number[] {
    return environment.chartCountAvailable;
  }

  public static get Name(): string {
    return environment.name;
  }

  public static get IsDevelopment(): boolean {
    return !environment.production;
  }

  public static get IsProduction(): boolean {
    return environment.production;
  }

  public static get IsTesting(): boolean {
    return this.IsProduction && this.Name == 'e2e';
  }

  public static get LoggerConfig(): { [id: string]: LogLevel } {
    // @ts-ignore
    return environment.logConfig || {};
  }

  public static get IsMobileVersion() {
    return environment.isMobileVersion;
  }

  public static get Platform(): string {
    return environment.platform;

  }
}
