import {Component} from '@angular/core';
import {LocationPositionService, Region, RegionsConnectEnum} from '@common/trade/services/location-position.service';

@Component({
  selector: 'app-l-switch-region-connect',
  templateUrl: './l-switch-region-connect.component.html',
  styleUrls: ['./l-switch-region-connect.component.css']
})
export class LSwitchRegionConnectComponent {

  public get Region(): RegionsConnectEnum {
    return this.locationPositionService.CurrentRegionConnect;
  }

  public get Regions(): Region[] {
    return this.locationPositionService.Regions;
  }

  public set Region(v: RegionsConnectEnum) {
    this.locationPositionService.changeRegion(v);
  }

  constructor(private locationPositionService: LocationPositionService) {}

}
