import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
  selector: '[passwords-match]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: PasswordsMatchValidator,
    multi: true
  }]
})
export class PasswordsMatchValidator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const checkNew = control.get('newPassword');
    const checkRepeat = control.get('repeatPassword');

    if(checkNew && checkRepeat){
      return checkRepeat.value == checkNew.value ? null : {
        match: true
      }
    }
    else return null;
  }
}
