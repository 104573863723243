import {EventEmitter, Injectable} from '@angular/core';
import {AuthenticationService} from '@common/auth/services/authentication.service';
import {MessageService} from '@common/shared/services/messageServices/message.service';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {AppConfig} from '@common/configuration/app-config';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Timer} from '@common/common/utils/timer';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {LoginErrorCode} from '@common/auth/models/login-error-codes';
import {Trader} from '@common/trader/models/trader';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _error = '';
  protected loginRequestTimer: Timer;
  protected loginErrorHandler: LoginErrorHandler;
  private _failEmitter: EventEmitter<void> = new EventEmitter<void>();
  protected errorSubscription: ISmartSubscription;
  protected successSubscription: ISmartSubscription;
  protected logger = LoggerFactory.getLogger('LoginService');

  set ErrorText(v: string) {
    this.checkInvalidToken(v);
    this._error = v;
  }
  get ErrorText(): string {
    return  this._error;
  }

  // проверка на Invalid Token и удаление его из стороджа

  private checkInvalidToken(v: string) {
    if (v === 'Invalid Token' || v === 'Неверный Токен' || v === '令牌无效') {
      localStorage.removeItem('Session');
      console.log('ErrorText', v);
      this.clearUsersInSystem(this.trader.TraderName);
    }
  }

  get FailEmitter(): EventEmitter<void> {
    return this._failEmitter;
  }

  constructor(protected authService: AuthenticationService,
              protected messageService: MessageService,
              protected localStorage: LocalStorageService,
              private translator: TranslatorService,
              private config: AppConfig,
              private trader: Trader) {
  }

  static getBooleanValue(v) {
    switch (v) {
      case 'False': {
        return false;
      }
      case 'True': {
        return true;
      }
    }
  }

  /*-------------------------------------------------------------------*/
  async initService(isCheckLoginByAccessToken = true) {
    this.errorSubscription = this.authService.LoginError.subscribe((msg) => this.onError(msg));
    this.successSubscription = this.authService.LoginSuccess.subscribe(() => this.onLogin());
/*    document.body.classList.add('body-bg');
    this.logger.info('Created');*/
    if (isCheckLoginByAccessToken) {
      await this.tryLoginByAccessToken();
    }
  }


  destroyService() {
    // this.errorSubscription.unsubscribe();
    // this.successSubscription.unsubscribe();
    this.removeTimer();
  }

  async tryLogin(login: string, password: string) {
    console.log('try login');

    OperationsWithDate.initTimestamp('Send Login/Password in enter');

    this.loginErrorHandler = new LoginErrorHandler(this.translator);
    this.clearAll();

    if (this.checkCredentials(login, password)) {
      // this.localStorage.removeSession();
      try {
        await this.sendLoginPassword(login, password);
      } catch (ex) {
        this.logger.error('error while sending credentials: ', ex);
      }
    }
  }

  async tryLoginByTokenLink(token: string) {
    console.log('try login by link');
    OperationsWithDate.initTimestamp('Send token/link in enter');
    this.loginErrorHandler = new LoginErrorHandler(this.translator);
    this.clearAll();

    try {
      await this.sendTokenLink(token);
    } catch (ex) {
      this.logger.error('error while sending credentials: ', ex);
    }
  }

  async signOut() {
    await this.authService.logout();
  }

  private onError(code: number) {
    if (!this.loginErrorHandler) {
      this.loginErrorHandler = new LoginErrorHandler(this.translator);
    }
    const error = this.loginErrorHandler.onError(code);

    if (error) {
      this.ErrorText = error;
    }

    this.removeTimer();
    this.abortConnection();
    this._failEmitter.emit();
  }

  private onLogin() {
    this.removeTimer();
//    this.dataLoaderService.doLoad();
  }

  public saveUserInSystem(login: string, password: string, staySignedIn: boolean): void {
    this.authService.saveUserInSystems(login, password, staySignedIn);
  }


  public async tryLoginByAccessToken() {
    this.logger.info('Try login by access token');
    const session = this.localStorage.getSession();
    if (!this.loginErrorHandler) {
      this.loginErrorHandler = new LoginErrorHandler(this.translator);
    }

    this.checkSignatureUserAgreement();

    if (this.getContainsCookie(session)) {
      await this.sendAccessToken(session.AccessToken, session.Login);
    } else {
      this.logger.debug('Token not found');
      this._failEmitter.emit();
    }
  }

  public async sendAccessToken(token: string, login: string) {
    this.runLoginTimer();
    await this.authService.loginByAccessToken(token, login);
  }

  protected runLoginTimer() {
    if (this.loginRequestTimer) {
      this.removeTimer();
      // this.abortConnection();
    }
    this.loginRequestTimer = this.setTimer();
    this.logger.debug('timer started');
  }

  private removeTimer() {
    if (this.loginRequestTimer) {
      this.loginRequestTimer.remove();
      this.loginRequestTimer = null;
    }
  }

  abortConnection() {
    this.removeTimer();
    this.authService.abortConnection();
  }

  private setTimer() {
    const timer = new Timer(() => {
      this.ErrorText = this.translator.getLocalPhrase('AuthModule_SignInComponent_LoginTimeout');
      this.onLogin();
    }, this.LoginTimeout || 20000);

    // timer.start();

    return timer;
  }

  private clearAll() {
    this.ErrorText = '';
  }

  private checkCredentials(login: string, password: string): boolean {
    if (!login || login.trim().length === 0) {
      this.messageService.error(this.translator.getLocalPhrase('AuthModule_SignInComponent_LoginIsNotSpecified'));
      this.ErrorText = this.translator.getLocalPhrase('AuthModule_SignInComponent_LoginIsNotSpecified');
      return false;
    } else if (!password || password.trim().length === 0) {
      this.messageService.error(this.translator.getLocalPhrase('AuthModule_SignInComponent_PasswordIsNotSpecified'));
      this.ErrorText = this.translator.getLocalPhrase('AuthModule_SignInComponent_PasswordIsNotSpecified');
      return false;
    } else {
      return true;
    }
  }

  private async sendLoginPassword(login: string, password: string) {
    this.runLoginTimer();
    await this.authService.login(login, password);
  }

  private async sendTokenLink(token: string) {
    this.runLoginTimer();
    await this.authService.loginByTokenLink(token);
  }

  checkSignatureUserAgreement() {
    if (this.config.Settings.connection.ShowAgreement) {
      this.authService.LoginSuccess.subscribe((v) => {
        if (!LoginService.getBooleanValue(v.IsSignedAgreement)) {
          this.trader.urlToOpenAfterLogin = 'agreement';
        }
      });
    }
  }

  /*-------------------------------------------------------------------*/
  protected get LoginTimeout(): number {
    return this.config.Settings.connection.LoginTimeout;
  }
  public get isConnecting(): boolean {
    return this.authService.IsConnecting;
  }
  public get isConnected(): boolean {
    return this.authService.IsConnected;
  }
  public get isReconnecting(): boolean {
    return this.authService.IsReconnecting;
  }

  public get LoginDisabled(): boolean {
    return this.isConnecting || this.isReconnecting || this.isConnected;
  }

  protected getContainsCookie(session): boolean {

    if (session && session.AccessToken) {
      session.prolongTime();
      this.localStorage.saveSession(session);
      return true;
    } else {
      // перенес return в блок else чтоб отрабатывалось одно из них
      return false;
    }

    // return false;

  }

  get isForgotPasswordLinkAvailable(): boolean {
    return this.config.Settings.urls.ForgotPasswordUrl !== undefined &&
      this.config.Settings.urls.ForgotPasswordUrl !== '';
  }

  get IsSignInAsGuestAvailable(): boolean {
    if (!this.config.Settings.connection.AllowLoginAsGuest) {
      return false;
    }

    return this.config.Settings.connection.GuestAccountLogin !== null &&
      this.config.Settings.connection.GuestAccountLogin.length > 0 &&
      this.config.Settings.connection.GuestAccountPassword !== null;
  }

  public loginAsGuest() {
    this.sendLoginPassword(this.config.Settings.connection.GuestAccountLogin, this.config.Settings.connection.GuestAccountPassword);
  }

  public async onForgotPassword($event: MouseEvent) {
    $event.stopPropagation();

    window.open(this.config.Settings.urls.ForgotPasswordUrl);
  }

  public isAnswerLoginSuccessReceived(): boolean {
    return this.authService.isAnswerLoginSuccessReceived;
  }

  public isAnswerLoginSuccessReceivedChange(): void {
    this.authService.isAnswerLoginSuccessReceived = false;
  }

  public isTraderLoaded(): boolean {
    return this.trader.Loaded;
  }

  public clearUsersInSystem(traderName: string): void {
    this.authService.clearUsersInSystem(traderName);
  }

}


class LoginErrorHandler {
  private state: LoginErrorCode;


  public constructor(private translator: TranslatorService) {
    this.state = null;
  }

  /**error description or null
   * @param code of error
   */
  public onError(code: number): string {
    switch (+code) {
      case LoginErrorCode.TokenExpired: {
        return this.onTokenExpired();
      }
      case LoginErrorCode.InvalidToken: {
        return this.onInvalidToken();
      }
      case LoginErrorCode.InvalidPool: {
        return this.onInvalidPool();
      }
      case LoginErrorCode.SessionExpired: {
        return this.onSessionExpired();
      }
      case LoginErrorCode.RemoteDisconnected: {
        return this.onRemoteDisconnect(); // this.onCantConnect();
      }
      case LoginErrorCode.IncorrectCredentials: {
        return this.onIncorrectCredentials(); // this.onCantConnect();
      }
      case LoginErrorCode.AccountIsLocked: {
        return this.onAccountIsLocked(); // this.onCantConnect();
      }
      case LoginErrorCode.SessionLimitReached: {
        return this.onSessionLimitReached();
      }
      case LoginErrorCode.AccountExpired: {
        return this.onAccountExpired();
      }
      default: {
        return this.onLoginFailed();
      }
    }
  }

  private onTokenExpired(): string {
    this.state = LoginErrorCode.TokenExpired;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_TokenExpired');
  }

  private onInvalidToken(): string {
    this.state = LoginErrorCode.InvalidToken;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_InvalidToken');
  }

  private onInvalidPool(): string {
    this.state = LoginErrorCode.InvalidPool;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_InvalidPool');
  }

  private onSessionExpired(): string {
    this.state = LoginErrorCode.InvalidToken;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_SessionClosed');
  }

  private onRemoteDisconnect(): string {
    this.state = LoginErrorCode.RemoteDisconnected;

    return this.translator.getLocalPhrase('AuthModule_SignInComponent_Disconnected');
  }

  private onIncorrectCredentials(): string {
    this.state = LoginErrorCode.IncorrectCredentials;

    return this.translator.getLocalPhrase('AuthModule_SignInComponent_IncorrectCredentials');
  }

  private onAccountIsLocked(): string {
    this.state = LoginErrorCode.AccountIsLocked;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_AccountLocked');
  }

  private onLoginFailed(): string {
    this.state = LoginErrorCode.LoginFailed;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_LoginFailed');
  }

  private onSessionLimitReached(): string {
    this.state = LoginErrorCode.SessionLimitReached;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_SessionLimitReached');
  }

  private onAccountExpired(): string {
    this.state = LoginErrorCode.AccountExpired;
    return this.translator.getLocalPhrase('AuthModule_SignInComponent_AccountExpired');
  }


}

