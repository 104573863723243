import {Component, Input, OnInit} from '@angular/core';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';


@Component({
  selector: 'app-symbol-name',
  templateUrl: './symbol-name.component.html',
  styleUrls: ['./symbol-name.component.scss']
})

export class SymbolNameComponent  {

  private _symbolId: number;

  firstName: string;
  secondName: string;

  @Input() set symbolId(value: number) {
    this.firstName = '';
    this.secondName = '';
    this._symbolId = value;
    this.getSymbolNameArray(this._symbolId);
  }


  constructor(private symbolStorageService: SymbolStorageService) {
  }

  getSymbolNameArray(symbolID: number): void {
    const symbolName = this.symbolStorageService.findSymbolById(symbolID).SymbolName;

    const temp = symbolName.split('/');

    this.firstName = temp[0];

    if (temp.length > 1) {
      this.secondName = '/' + temp[1];
    }
  }

}
