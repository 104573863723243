
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";

export abstract class ProfitCalcBase implements IProfitCalc {

	constructor(symbol: IProfitCalcSymbol) {
		this.Symbol = symbol;
	}

	public Symbol: IProfitCalcSymbol;

	public abstract getProfitCalcType(): ProfitCalcType;

	public Calculate(signedAmount: number, openPrice: number, closePrice: number): number {
		return this.CalculateInternal(signedAmount, openPrice, closePrice);
	}

	public CalculatePositionProfit(signedAmount: number, currentPrice: number, weightedPriceNumerator: number, conversion: number): number {
		return (signedAmount * currentPrice - weightedPriceNumerator) * conversion;
	}

	public CalculateWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number {
		return this.CalculateInternalWithConvertion(signedAmount, openPrice, closePrice, conversion);
	}

	public CalculatePriceAtProfit(signedAmount: number, openPrice: number, profit: number): number {
		return this.CalculatePriceAtProfitInternal(signedAmount, openPrice, profit);
	}

	protected abstract CalculateInternal(signedAmount: number, openPrice: number, closePrice: number): number;

	protected abstract CalculateInternalWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number;

	protected abstract CalculatePriceAtProfitInternal(signedAmount: number, openPrice: number, profit: number): number;

}
			