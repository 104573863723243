import {Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {
  Command_BlackthornAuthenticate, Command_GetTraderChallengeEODSnapshots, Command_GetTraderChallengeSettings,
  Command_GetTraderGainSummary,
  Command_GetTraderOrderHistory,
  Command_GetTraderTransferHistory, Command_GetTradingCentralToken, Message_CustomCommand
} from '@common/communication/connection/classes.g';


@Injectable({
  providedIn: 'root'
})
export class ReportCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger('ReportCommandSender');

  public constructor(serverConnection: ServerInteractionService) {
    super(serverConnection);
  }

  private createPromiseToRequestResponse(command: Message_CustomCommand): Promise<any> {
    return new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log(answer);
          resolve(answer);
        })
        .failed(
          message => {
            console.log(message);
            reject(message);
          }
        );
    });
  }

  public traderOrderHistoryGet(pages: number, rows: number): Promise<any> {
    const command = new Command_GetTraderOrderHistory();
    command.Page = pages;
    command.Rows = rows;
    console.log('sending OrderHistoryGet');
    return this.createPromiseToRequestResponse(command);
  }

  public async traderTransferHistoryGet(pages: number, rows: number): Promise<any> {
    const command = new Command_GetTraderTransferHistory();
    command.Page = pages;
    command.Rows = rows;
    console.log('sending TransferHistoryGet');
    return this.createPromiseToRequestResponse(command);
  }

  public async traderGainSummaryGet(): Promise<any> {
    const command = new Command_GetTraderGainSummary();
    console.log('sending GainSummaryGet');
    return this.createPromiseToRequestResponse(command);
  }


  public async TCTokenGet(): Promise<any> {
    const command = new Command_GetTradingCentralToken();
    console.log('sending TCTokenGet');
    return this.createPromiseToRequestResponse(command);
  }

  public async blachThornAuthorize(
    orderNumber: string,
    orderAmount: string,
    orderCurrency: string,
    orderDescription: string,
    successURL: string,
    cancelURL:  string,
    customerName: string ): Promise<any> {

    const command = new Command_BlackthornAuthenticate();
    command.OrderNumber = orderNumber;
    command.OrderAmount = orderAmount;
    command.OrderCurrency = orderCurrency;
    command.OrderDescription = orderDescription;
    command.SuccessURL = successURL;
    command.CancelURL = cancelURL;
    command.CustomerName = customerName;

    console.log('sending blt auth');
    return this.createPromiseToRequestResponse(command);
  }

  // запрос в репорт центр для получения данных о Challenge
  public async traderChallengeSettings(): Promise<any> {
    const command = new Command_GetTraderChallengeSettings();
    console.log('sending GetTraderChallengeSettings');
    return this.createPromiseToRequestResponse(command);
  }

  // запрос в репорт центр для получения данных о ChallengeEODSnapshot
  public  async traderChallengeEODSnapshot(): Promise<any> {
    const command = new Command_GetTraderChallengeEODSnapshots();
    console.log('sending GetTraderChallengeEODSnapshots');
    return this.createPromiseToRequestResponse(command);
  }
}
