import { Theme } from '../symbols';

export const tvLightTheme: Theme = {
  name: 'tvLightTheme',
  category: 'light',
  properties: {
    '--input-background-color': '#f1f1f1',
    '--input-background-color-0': '#f1f1f1',
    '--footer-background-color': '#F9F9FA',
    '--main-page-light-color': '#FFFFFF',
    '--main-page-dark-color': '#f1f1f1',
    '--blue-0': '#F9F9FA',
    '--background-light': '#f1f1f1',
    '--background-light-2': '#FFFFFF',
    '--background': '#f1f1f1',
    '--blue-1': '#F9F9FA',
    '--blue-2': '#e7ebee',
    '--blue-3': '#e7ebee',
    '--blue-4': '#F9F9FA',
    '--blue-5': '#FFFFFF',
    '--blue-6': '#979eb3',
    '--blue-9': '#2f355c',
    '--blue-10': '#767E9C',
    '--blue-13': 'rgba(6, 11, 34, 0.7)',
    '--blue-15': '#f9f9fa',
    '--blue-20': '#F9F9FA',
    '--blue-22': '#EDF6FC',
    '--blue-23': '#f1f1f1',
    '--blue-25': '#5A6689',
    '--blue-26': '#e7ebee',

    '--blue-24': '#F3F4F6',
    '--blue-7': '#77829c',
    '--blue-8': '#848fad',
    '--blue-11': '#111122',
    '--blue-12': '#00badb',
    '--blue-14': '#606a85',
    '--blue-16': '#2e75bd',
    '--blue-17': '#f1f1f1',
    '--blue-18': '#313a67',
    '--blue-19': '#374174',
    '--blue-21': 'rgb(59, 153, 252)',

    '--blue-btn': '#33BA86',
    '--blue-btn-hover': '#30b17f',

    '--red': '#E51849',
    '--red-e': '#E24704',
    '--red-fa': '#fa5d00',
    '--red-f': '#9E0000',
    '--red-0': '#ca6868',
    '--red-1': '#671a1a',
    '--red-2': '#960000',
    '--red-3': 'rgba(142, 6, 3, 0.4)',
    '--red-4': '#cf282c',
    '--red-5': '#ff4300',
    '--red-6': 'rgb(232, 0, 8)',
    '--red-7': '#b30404',
    '--red-8': '#f74f43',
    '--red-9': '#d74304',
    '--red-10': '#EE5615',
    '--red-11': '#ed2c03',
    '--red-text': '#E51849',

    '--green-1': '#1bc68b',
    '--green-3': '#33BA86',
    '--green-5': '#33ba8661',
    '--green-0': '#008000',
    '--green-4': '#0bffa1a8',
    '--green-6': '#30b17f',
    '--green-7': '#31b482',
    '--green-8': '#1d6a4b',
    '--green-text': '#13AD7F',

    '--yellow-f': '#FFFF00',
    '--yellow-0': '#CCA866',

    '--white': '#1e212b',
    '--white-0': '#474D65',
    '--white-1': '#eeeeee',
    '--white-2': '#374151',
    '--white-3': '#efefef',

    '--black': '#000000',

    '--grey': '#aaaaaa',
    '--grey-0': '#dddddd',
    '--grey-1': '#A9A9A9',
    '--grey-2': '#bfbfbf',
    '--grey-3': '#888888',

    '--bg-color-table': '#f1f1f1',
    '--bg-color-item': '#FFFFFF',
    '--bg-light-fon': '#FFFFFFAA',
    '--bg-light-fon-2': 'rgba(7,7,7,.6)',

    '--color-icon-theme': '#5A6689',

    '--check-box-color': '#33BA86',
    '--color-active-text-btn': '#ffffff',
    '--bg-spread': '#f1f1f1',

    '--bg-dark-fon': '#9199B1AA',

    '--bg-item': '#FFFFFF',


    '--bg-context-menu-chart': '#fff',
    '--shadow-context-menu-chart': '#0003',
    '--color-context-menu-chart': '#131722',
    '--hover-context-menu-chart': '#f0f3fa',
    '--border-color-grey': '#979eb3',
    '--text-color-4': '#0B0E14',
    '--text-color': '#1e212b',

    '--full-market-color-bg': '#F9F9FA',

  }
};

