import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Symbol} from '@common/symbol/models/symbol';
import {isPendingTrade, TradeType} from '@common/trade/models/trade-type';
import {Accounting} from '@common/trader/models/accounting.service';
import {StopType} from '@common/trade/utils/calculations/sltp/stop-type';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import moment from 'moment';
import {TradeService} from '@common/trade/services/trade.service';
import {VolumeType} from '@common/trade/utils/volume/volume-calculator';
import {Settings} from '@common/trader/models/settings';
import {SettingsService} from '@common/trader/services/settings.service';
import {AppConfig} from '@common/configuration/app-config';
import {translate} from '@common/locale/servises/translator.service';
import {OpeningType, TradeFormSettings} from '@common/shared/trade/classes/TradeFormSettings';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {FormsModule, NgForm} from '@angular/forms';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {NotificationService} from '@common/notification/services/notification.service';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Trader} from '@common/trader/models/trader';
import {Environment} from '@common/environment';
import {Factory} from '@common/shared/calculators/Margin/Factory.g';
import {LocaleModule} from '@common/locale/locale.module';
import {NgClass, NgIf, NgStyle} from '@angular/common';
import {TradeModule} from '@common/trade/trade.module';
import {SharedModule} from '@common/shared/shared.module';
import {IMarginCalc} from '@common/shared/calculators/Margin/IMarginCalc.g';
import {ConversionCalculatorService} from '@common/shared/services/ConversionCalculator/conversion-calculator.service';
import {IRolloverCalc} from '@common/shared/calculators/Rollover/IRolloverCalc.g';
import {Factory as RolloverFactory} from '@common/shared/calculators/Rollover/Factory.g';
import {CurrencyModule} from '@common/currency/currency.module';

type SizeMode = 'small' | 'normal';


@Component({
  selector: 'app-multi-order-form',
  templateUrl: './multi-order-form.component.html',
  styleUrls: ['./multi-order-form.component.scss'],
  standalone: true,
  imports: [
    LocaleModule,
    NgClass,
    TradeModule,
    SharedModule,
    FormsModule,
    NgIf,
    CurrencyModule,
    NgStyle
  ]
})
export class MultiOrderFormComponent implements OnInit, OnDestroy {
  // private marginCalculator: MarginCalc;
  private marginCalculator: IMarginCalc;
  private rolloverCalculator: IRolloverCalc;
  private possibleMargin = 0;
  private possibleCommission = 0;
  private possibleRolloverSell = 0;
  private possibleRolloverBuy = 0;
  private spreadValueInAC = 0;
  private pipValue = 0;
  private subscribeChangeVolumeOrSymbol: Subscription;
  private subscribeChangePriceSymbol: ISmartSubscription;
  private subscribeGetLastMessage: ISmartSubscription;
  private formSettings: TradeFormSettings;
  private sizeMode: SizeMode;
  private showPicker = false;
  public dateOptions = [
    {value: 'UNSET', title: translate('TradeModule_MultiOrderFormComponent_unset-option-header')},
    {value: 'DAY', title: translate('TradeModule_MultiOrderFormComponent_day-option-header')},
    {value: 'WEEK', title: translate('TradeModule_MultiOrderFormComponent_week-option-header')},
    {value: 'MONTH', title: translate('TradeModule_MultiOrderFormComponent_month-option-header')},
    {value: 'GTD', title: translate('TradeModule_MultiOrderFormComponent_gdt-option-header')},
  ];
  private buttonDisabled = false;
  private hiddenLastMessage = true;

  private lastMessage: string;
  private colorLastMessage: string;

  private messageTimeOut: number;
  public appName = '';

  @Input()
  public set symbol(v: Symbol) {
    this.formSettings.CurrentSymbol = v;
    // console.log(this.formSettings.CurrentSymbol);
    this.checkSubscribePrice(v);
  }

  private successfullySubmitted = new EventEmitter<void>();
  @Output()
  public get submittedSuccessfully(): Observable<void> {
    return this.successfullySubmitted.asObservable();
  }

  @Input()
  public set type(v: TradeType) {
    this.formSettings.OrderType = v;
    if (isPendingTrade(this.OrderType)) {
      // при открытии пендинг ордеров через график передается цена открытия в форму мульти ордера
      setTimeout(() => {
        if (this.pricesPendingTrade !== undefined) {
          this.FormSettings.OpenPrice = this.pricesPendingTrade;
        }
      }, 150 );
    }
  }

  private pricesPendingTrade: number;

  @Input()
  private set prices(v: number) {
    this.pricesPendingTrade = v;

  }

  @Input()
  public set volume(v: number) {
    this.formSettings.Volume = v;
  }

  @Input()
  public set SizeMode(v: SizeMode) {
    this.sizeMode = v;

    if (!this.NormalSizeMode) {
      // this.FormSettings.setMarketOpening();
      this.FormSettings.StopLoss.Enabled = false;
      this.FormSettings.TakeProfit.Enabled = false;
    }
  }
  public get SizeMode() {
    return this.sizeMode;
  }

  @Output()
  public get symbolChange(): Observable<Symbol> {
    return this.FormSettings.OnSymbolChange;
  }

  private firsVolumeCurrency = true;
  private tempVolumeCurrency: number;
  private volumeCurrency = 0;
  private firsVolumePercent = true;
  private tempVolumePercent: number;
  private volumePercent = 0;
  @ViewChild('f') multiOrderForm: NgForm;

  private isShowAPreliminaryCalculationOfData = false;


  constructor(private accounting: Accounting,
              private tradeService: TradeService,
              private settingsService: SettingsService,
              private config: AppConfig,
              protected settings: Settings,
              private symbolService: SymbolService,
              private notificationService: NotificationService,
              private trader: Trader,
              private conversionCalculatorService: ConversionCalculatorService) {

    this.formSettings = new TradeFormSettings(this.accounting);

    if (this.settingsService.Settings.OneClickSL === 0) {
      this.formSettings.StopLoss.Pips = config.Settings.trading.MinStopPipsInterrupt;
    } else {
      this.formSettings.StopLoss.Pips = this.settingsService.Settings.OneClickSL;
    }

    if (this.settingsService.Settings.OneClickTP === 0) {
      this.formSettings.TakeProfit.Pips = config.Settings.trading.MinStopPipsInterrupt;
    } else {
      this.formSettings.TakeProfit.Pips = this.settingsService.Settings.OneClickTP;
    }

    this.appName = this.config.Settings.common.AppName;

    if (this.config.Settings.isShowAPreliminaryCalculationOfDataForOpeningAnOrder) {
      this.isShowAPreliminaryCalculationOfData = this.config.Settings.isShowAPreliminaryCalculationOfDataForOpeningAnOrder;
    }
  }

  public get MinStopPips(): number {
    return this.config.Settings.trading.MinStopPipsInterrupt;
  }

  public get IsShowAPreliminaryCalculationOfData(): boolean {
    return this.isShowAPreliminaryCalculationOfData && this.sizeMode === 'normal';
  }

  public setPriceStopLoss() {
    this.FormSettings.StopLoss.setPriceType();
  }

  public setPipsStopLoss() {
    this.FormSettings.StopLoss.setPipsType();
  }

  public setProfitStopLoss() {
    if (this.VolumeType !== VolumeType.ByUnits) {
      this.setUnitsVolume();
    }
    this.FormSettings.StopLoss.setProfitType();
  }

  public setPercentStopLoss() {
    if (this.VolumeType !== VolumeType.ByUnits) {
      this.setUnitsVolume();
    }
    this.FormSettings.StopLoss.setPercentType();
  }

  public setPriceTakeProfit() {
    this.FormSettings.TakeProfit.setPriceType();
  }

  public setProfitTakeProfit() {
    this.FormSettings.TakeProfit.setProfitType();
  }

  public setPipsTakeProfit() {
    this.FormSettings.TakeProfit.setPipsType();
  }

  public setPercentTakeProfit() {
    this.FormSettings.TakeProfit.setPercentType();
  }

  public setUnitsVolume() {
    this.FormSettings.setVolumeUnits();
  }

  public setCurrencyRiskVolume() {
    if ((this.FormSettings.StopLoss.StopType === StopType.ByProfit) || (this.FormSettings.StopLoss.StopType === StopType.ByPercent)) {
      this.FormSettings.StopLoss.setPipsType();
    }
    this.FormSettings.setVolumeCurrency();
  }

  public setPercentRiskVolume() {
    if ((this.FormSettings.StopLoss.StopType === StopType.ByProfit) || (this.FormSettings.StopLoss.StopType === StopType.ByPercent)) {
      this.FormSettings.StopLoss.setPipsType();
    }
    this.FormSettings.setVolumePercent();
  }

  public get OpenPrice(): number {
    return this.FormSettings.OpenPrice;
  }

  public get Volume(): number {
    return this.FormSettings.Volume;
  }

  public get VolumePreview(): string {
    return this.Volume.toFixed(this.DecimalPlaces);
  }

  public get StopLoss(): number {
    if (this.FormSettings.StopLoss.Enabled) {
      return this.FormSettings.StopLoss.Price;
    } else {
      return 0;
    }
  }

  public get TakeProfit(): number {
    if (this.FormSettings.TakeProfit.Enabled) {
      return this.FormSettings.TakeProfit.Price;
    } else {
      return 0;
    }
  }

  public get NormalSizeMode(): boolean {
    return this.sizeMode === 'normal';
  }

  public get DatePreview(): string {
    return moment(this.FormSettings.SelectedDate).format('DD MMM \'YY, HH:mm');
  }

  public get OrderType(): TradeType {
    return this.FormSettings.OrderType;
  }

  public get Symbol(): Symbol {
    return this.FormSettings.CurrentSymbol;
  }

  public get ExpirationDate(): Date {
    return this.FormSettings.SelectedDate;
  }

  public get FormSettings(): TradeFormSettings {
    return this.formSettings;
  }

  public get VolumeCurrency() {
    if (this.Settings.UseLotTrading) {
      if (!this.firsVolumeCurrency && this.tempVolumeCurrency !== this.FormSettings.VolumeCurrency) {
        this.firsVolumeCurrency = true;
      }
      if (this.firsVolumeCurrency) {
        this.volumeCurrency = OperationsWithVolume.checkVolume(this.FormSettings.VolumeCurrency * this.Symbol.ContractSize) / this.trader.LotDelimeter;
        this.tempVolumeCurrency = this.FormSettings.VolumeCurrency;
        this.firsVolumeCurrency = false;
        return this.volumeCurrency;
      } else {
        return  this.volumeCurrency;
      }
    } else {
      return this.FormSettings.VolumeCurrency;
    }
  }

  public set VolumeCurrency(v) {
    if (this.Settings.UseLotTrading) {
      this.FormSettings.VolumeCurrency = OperationsWithVolume.convertFromVolumeToLots(v, this.Symbol.ContractSize) ;
    } else {
      this.FormSettings.VolumeCurrency = v;
    }
  }

  public get VolumePercent() {
    if (this.Settings.UseLotTrading) {
      if (!this.firsVolumePercent && this.tempVolumePercent !== this.FormSettings.VolumePercent) {
        this.firsVolumePercent = true;
      }
      if (this.firsVolumePercent) {
        this.volumePercent = OperationsWithVolume.checkVolume(this.FormSettings.VolumePercent * this.Symbol.ContractSize) / this.trader.LotDelimeter;
        this.tempVolumePercent = this.FormSettings.VolumePercent;
        this.firsVolumePercent = false;
        return this.volumePercent;
      } else {
        return  this.volumePercent;
      }
    } else {
      return this.FormSettings.VolumePercent;
    }
  }

  public set VolumePercent(v: number) {
    if (this.Settings.UseLotTrading) {
      this.FormSettings.VolumePercent = OperationsWithVolume.convertFromVolumeToLots(v, this.Symbol.ContractSize);
    } else {
      this.FormSettings.VolumePercent = v;
    }
  }

  public get Settings(): Settings {
    return this.settingsService.Settings;
  }

  public get VolumeType(): VolumeType {
    return this.FormSettings.VolumeType;
  }

  public get ShowPicker(): boolean {
    return this.showPicker;
  }

  public get ButtonDisabled(): boolean {
    return this.buttonDisabled;
  }

  @HostListener('document:keydown', ['$event'])
  private async onKeyboardKeyDown(event: KeyboardEvent) {
    // добавил дополнительное условия для невалидного ордера
    if (event.key.toLowerCase() === 'enter' && !this.multiOrderForm.form.invalid) {
      event.preventDefault();

      if (this.ButtonDisabled) {
        return;
      }

      await this.onSubmitClick();
    }
  }

  ngOnInit() {
    sessionStorage.setItem('modalWindowIsOpen', JSON.stringify(true));
    this.subscribeGetLastMessage = this.subGetLastMessage();
    this.FormSettings.OpenPrice = TradePriceProvider.getTradeCurrentPrice(this.Symbol, this.OrderType);

    // if (isPendingTrade(this.OrderType)) {
    //   this.FormSettings.OpenPrice = TradePriceProvider.getTradeCurrentPrice(this.Symbol, this.OrderType);
    // }

    this.symbolChange.subscribe(symbol => {
      if (isPendingTrade(this.OrderType)) {
        this.FormSettings.OpenPrice = TradePriceProvider.getTradeCurrentPrice(symbol, this.OrderType);
      }
    });

    if (!this.FormSettings.Volume || this.FormSettings.Volume === 0) {
      this.FormSettings.Volume = this.tradeService.getVolumeForOpenOrders(this.formSettings.CurrentSymbol, this.pricesPendingTrade, this.formSettings.OrderType);
    }

    // выставляет значение для регуляторов SL и TP полученных из настройки пользователя
    this.FormSettings.StopLoss.Enabled = this.settings.RegularSLTP;
    this.FormSettings.TakeProfit.Enabled = this.settings.RegularSLTP;

    this.initMarginCalc();
    this.initRolloverCalc();
    this.initCalculate('FirstEnter');


    this.subscribeChangeVolumeOrSymbol = this.FormSettings.EventChangeVolumeOrSymbol().subscribe((typeEvent: string) => {
      console.log(typeEvent);
      if (typeEvent === 'Symbol') {

        if (this.subscribeChangePriceSymbol !== undefined) {
          this.subscribeChangePriceSymbol.unsubscribe();
          this.spreadValueInAC = 0;
          this.subscribeChangePriceSymbol = undefined;
        }

        this.initMarginCalc();
        this.initRolloverCalc();
        this.initCalculate('ChangeSymbol');
      }
      this.initCalculate();

    });

  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('modalWindowIsOpen');
    if (this.subscribeGetLastMessage !== undefined) {
      this.subscribeGetLastMessage.unsubscribe();
    }
    if (this.subscribeChangeVolumeOrSymbol !== undefined) {
      this.subscribeChangeVolumeOrSymbol.unsubscribe();
    }
    if (this.subscribeChangePriceSymbol !== undefined) {
      this.subscribeChangePriceSymbol.unsubscribe();
    }

  }

  public onOpenPriceClick() {
    this.FormSettings.OpenPrice = this.FormSettings.OpenPrice;
  }

  public getSelectedOpening(): object {
    return {
      market: this.FormSettings.Opening === OpeningType.Market,
      limit: this.FormSettings.Opening === OpeningType.Limit,
      stop: this.FormSettings.Opening === OpeningType.Stop,
    };
  }

  public getPriceClass(): object {
    return {
      relative: this.FormSettings.PriceMode === 'relative',
      absolute: this.FormSettings.PriceMode === 'absolute'
    };
  }

  public onDateClick(event: MouseEvent) {
    // event.stopPropagation();
    this.openDateTimePicker();
  }

  private openDateTimePicker() {
    this.showPicker = true;
  }

  public closeDateTimePicker() {
    this.showPicker = false;
  }

  public onSelectDate(date: Date) {
    this.FormSettings.SelectedDate = date;
    this.closeDateTimePicker();
  }

  public async onSubmitClick() {
    this.disableButton();

    const trade = new TradeDTO();
    const minLength = this.DecimalPlaces;

    if (this.settings.UseLotTrading) {
      trade.Volume = OperationsWithVolume.checkVolume(Number(this.Volume.toFixed(minLength)) * this.Symbol.ContractSize, minLength) / this.trader.LotDelimeter;
    } else {
      trade.Volume = OperationsWithVolume.checkVolume(this.Volume, minLength);
    }

    trade.OpenPrice = this.OpenPrice;
    trade.Symbol = this.Symbol;
    trade.Type = this.OrderType;
    trade.StopLoss = this.StopLoss;
    trade.TakeProfit = this.TakeProfit;
    trade.ExpirationDate.Date = this.ExpirationDate;

    try {
      const promise = await this.tradeService.openOrder(trade);

      promise.success((data) => {
        this.successfullySubmitted.emit();
      });
      // promise.finally(() => this.finallyOpening());
    } catch (e) {
    } finally {
      this.finallyOpening();
    }
  }

  private finallyOpening() {
    setTimeout(() => this.releaseButton(), 1000);
  }

  private disableButton() {
    this.buttonDisabled = true;
  }

  private releaseButton() {
    this.buttonDisabled = false;
  }

  // при включении торговать в лотах идет конвертация
  public showStep() {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.convertFromVolumeToLots(this.Symbol.TradingStep, this.Symbol.ContractSize, this.trader.LotDelimeter);
    }
    return this.Symbol.TradingStep;
  }

  public get DecimalPlaces(): number {
    if (this.Settings.UseLotTrading) {
      return OperationsWithVolume.numberOfDigitsAfterDot(this.showStep());
    }
    return this.Symbol.VolumeDecimalPlaces;
  }

  // при включенном торговать в лотах на кнопке вместо символов показывается значение лот
  public showNameForButton() {
    if (this.Settings.UseLotTrading) {
      return 'lot' + ' ' + this.FormSettings.CurrentSymbol.SymbolName;
    } else {
      return this.FormSettings.CurrentSymbol.SymbolName;
    }
  }

  // при прокрутке таблицы символов идет проверка на подписку изминения цен, если её нет, то подписывается
  private checkSubscribePrice(v: Symbol): void {
    if (v.LastQuote === undefined) {
      this.symbolService.subscribeToSymbol(v.SymbolId).then();
    }

    // проверка на подписку для конвертирующей валюты
    if (!v.ProfitCalcSymbol.getCCConvertSymbol().getIsValidQuote()) {

      const convertSymbol = v.ProfitCalcSymbol.getCCConvertSymbol()['_symbolModel'];

      console.log( `Subscription for converting currency ${convertSymbol._symbolMetadata['_symbolName']}`);

      this.subscribeToConvertSymbol(convertSymbol._symbolId).then();

    }
  }

  // подписка на конвертирующую валюту
  private async subscribeToConvertSymbol(symbolId: number): Promise<void> {
    await this.symbolService.subscribeToSymbol(symbolId);
  }

  public ErrorMessage(spread: string, pips: number): boolean {
    if (Number(spread) > pips) {
      return true;
    } else {
      return false;
    }
  }

  private subGetLastMessage(): ISmartSubscription {
    return this.notificationService.NotificationReceived.subscribe((notification) => {

      if (this.messageTimeOut !== undefined) {
        clearTimeout(this.messageTimeOut);
      }

      this.messageTimeOut = setTimeout(() => {
        this.hiddenLastMessage = true;
        this.messageTimeOut = undefined;
      }, 3000);

      this.lastMessage = this.checkLongName(notification.ShowMessage);
      this.colorLastMessage = notification.ColorLastMessage;

      this.hiddenLastMessage = false;
    });
  }

  checkLongName(text: string) {
    if (text == null) {
      return '';
    } else {
      const width = 40;
      if (text.length < width) {
        return text;
      } else {
        return text.substr(0, width).trim() + '...';
      }
    }
  }

  get HiddenLastMessage(): boolean {
    return this.hiddenLastMessage;
  }

  messageWasRead(): void {
    this.notificationService.messageWasRead();
    this.hiddenLastMessage = true;
  }

  getColorLastMessage(): string {
    return this.colorLastMessage;
  }

  get LastNotificationBody(): string {
    return this.lastMessage;
  }

  get FontSizeLastMessage(): number {
    if (this.lastMessage !== undefined && this.lastMessage.length > 37) {
      return 13;
    }
    return 16;
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex';
  }

  get IsZeTradexDesktop(): boolean {
    return this.IsZeTradex && !Environment.IsMobileVersion;
  }

  public InActiveButton(typeButton: string): string {
    if (this.IsZeTradex) {
      if (this.FormSettings.Position !== typeButton && typeButton === 'Sell') {
        return 'z-sell-inactive';
      }

      if (this.FormSettings.Position !== typeButton && typeButton === 'Buy') {
        return 'z-buy-inactive';
      }

    }
    if (this.FormSettings.Position !== typeButton) {
      return 'inactive';
    }
  }

  get ShowExpDateBlock(): boolean {
    return (this.FormSettings.Opening === 'Limit' || this.FormSettings.Opening === 'Stop') && !this.IsZeTradex;

  }

  public getColorStylesButtonOpenOrders(invalidForm: boolean): string {
    if (this.IsZeTradex) {

      if (this.FormSettings.Position === 'Sell' && invalidForm) {
        return 'z-sell-button-disabled';
      }

      if (this.FormSettings.Position === 'Buy' && invalidForm) {
        return 'z-buy-button-disabled';
      }

      if (this.FormSettings.Position === 'Sell') {
        return 'z-sell-button-danger';
      }

      if (this.FormSettings.Position === 'Buy') {
        return 'z-buy-button-success';
      }

    } else {
      if (this.FormSettings.Position === 'Sell') {
        return 'button-danger';
      }

      if (this.FormSettings.Position === 'Buy') {
        return 'button-success';
      }
    }
  }

  private initMarginCalc(): void {
    if (this.marginCalculator !== null) {
      this.marginCalculator = null;
    }
    this.marginCalculator = new Factory().createCalculator(this.Symbol.MarginCalcType, this.Symbol.MarginCalcSymbol);
  }

  private initRolloverCalc(): void {
    if (this.rolloverCalculator !== null) {
      this.rolloverCalculator = null;
    }
    this.rolloverCalculator = new RolloverFactory().CreateCalculator(this.Symbol.RolloverCalcType, this.Symbol.RolloverCalcSymbol);
  }

  private initCalculate(activeType?: string): void {

    if (activeType === 'FirstEnter') {
      setTimeout(() => {
        this.calculationOfPossibleMargin();
        this.calculationSpreadValue();
      }, 1000);
    }

    if (activeType === 'ChangeSymbol') {
      setTimeout(() => {
        this.calculationOfPossibleMargin();
      }, 1000);
    }

    if (activeType === undefined ) {
      this.calculationOfPossibleMargin();
    }

    if (this.subscribeChangePriceSymbol === undefined) {
      this.subscribeChangePriceSymbol = this.Symbol.PriceChange.subscribe(() => {
        this.calculationSpreadValue();
      });
    }
  }

  private calculationOfPossibleMargin(): void {

    let volume = this.Volume;

    if (this.Settings.UseLotTrading) {
      volume = OperationsWithVolume.convertFromLotToVolume(this.Volume, this.Symbol.ContractSize) ;
    }

    let margin = this.marginCalculator.Calculate(volume, this.conversionCalculatorService.getAveragePrice(this.Symbol));

    margin = Number(margin.toString().slice(0, 10));

    this.possibleMargin =  this.conversionCalculatorService.getVolumeInAC(margin);

    this.calculationCommission(volume);
    this.calculationPipValue(volume);
    this.calculationRollover(volume);
  }

  private calculationCommission(volume: number) {
    const commission = (2 / 1000000) * this.conversionCalculatorService.getTransFeeValueInAC(this.Symbol, volume, this.FormSettings.Position);
    this.possibleCommission = this.conversionCalculatorService.getVolumeInAC(commission);
  }

  private calculationRollover(volume: number) {
    const lastPrice = this.conversionCalculatorService.getAveragePrice(this.Symbol);

    const possibleRolloverSell = this.rolloverCalculator.Calculate((volume - volume * 2), lastPrice);
    this.possibleRolloverSell = this.conversionCalculatorService.getVolumeInAC(possibleRolloverSell);

    const possibleRolloverBuy = this.rolloverCalculator.Calculate(volume, lastPrice);
    this.possibleRolloverBuy = this.conversionCalculatorService.getVolumeInAC(possibleRolloverBuy);
  }

  private calculationPipValue(volume: number): void {
    this.pipValue = this.conversionCalculatorService.getPipValueInAC(this.Symbol, volume);
  }

  private calculationSpreadValue(): void {
    this.spreadValueInAC = this.conversionCalculatorService.getSpreadValueInAC(this.Symbol, Number(this.FormSettings.Spread));
  }

  public get SpreadValueInAC(): number {
    return this.spreadValueInAC;
  }

  public get PossibleCommission(): number {
    return this.possibleCommission;
  }

  public get PossibleRolloverBuy(): number {
    return this.possibleRolloverBuy;
  }

  public get PossibleRolloverSell(): number {
    return this.possibleRolloverSell;
  }

  public get PossibleMargin(): number {
    return this.possibleMargin;
  }

  public get PipValue(): number {
    return this.pipValue;
  }
}

