import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@common/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {LocaleModule} from '@common/locale/locale.module';
import {ChartingModule as CommonChatModule} from '@common/charting/charting.module';
import {RouterModule} from '@angular/router';
import {SymbolModule} from '@common/symbol/symbol.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ThemeModule} from '@common/shared/services/theme';
import {MatGridListModule} from '@angular/material/grid-list';
import {SearchComponent} from '@common/symbol-search/components/search/search.component';
import {SymbolSearchComponent} from '@common/symbol-search/components/symbol/symbol-search.component';

@NgModule({
  declarations: [
    SymbolSearchComponent,
    SearchComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        LocaleModule,
        CommonChatModule,
        RouterModule,
        SymbolModule,
        DragDropModule,
        ThemeModule,
        MatGridListModule
    ],
    exports: [
      SearchComponent
    ],
  providers: [
  ]
})
export class SymbolSearchModule { }
