import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";


export class Indirect_index extends ProfitCalcBase {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.Indirect_index;
	}
        
	public CalculateInternal(signedAmount: number, openPrice: number, closePrice: number): number {
		
	if (this.Symbol.getTickSize() * this.Symbol.getContractSize() == 0)
			{
				return 0;
			}
				return (signedAmount / this.Symbol.getContractSize()) * (closePrice - openPrice) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize());
}

	public CalculateInternalWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number {
	
				return this.CalculateInternal(signedAmount, openPrice, closePrice);
}

	public CalculatePriceAtProfitInternal(signedAmount: number, openPrice: number, profit: number): number {
		
	if (this.Symbol.getTickSize() * this.Symbol.getContractSize() == 0 || ((signedAmount / this.Symbol.getContractSize()) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()) == 0))
			{
				return 0;
			}
				return openPrice + (profit / (signedAmount / this.Symbol.getContractSize()) * (this.Symbol.getTickPrice() / this.Symbol.getTickSize()));
}

	
}