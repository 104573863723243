import {Pipe, PipeTransform} from '@angular/core';
import {NumberFormatter} from '@common/trade/utils/number-formatter';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  transform(value: any, decimalPlaces = 2, separator?: string, decimalSeparator?: string): any {
    return NumberFormatter.format(Number(value), decimalPlaces, separator, decimalSeparator);
  }
}
