import {Component, Input, OnInit} from '@angular/core';
import {SettingsService} from '@common/trader/services/settings.service';

@Component({
  selector: 'app-one-click-trading-icon',
  templateUrl: './one-click-trading-icon.component.html',
  styleUrls: ['./one-click-trading-icon.component.css']
})
export class OneClickTradingIconComponent implements OnInit {

  @Input() appName: string;

  public get IsOneClickTrading(): boolean {
    return this.settingsService.Settings.OneClickTrading;
  }
  constructor(private settingsService: SettingsService) { }

  ngOnInit() {
  }

  public get IsZeTradex() {
    return this.appName === 'Zetradex';
  }

}
