import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {TranslateManagerService} from '../../servises/translate-manager.service';
import {LocalComponent} from '../local-component';
import {TranslatorService} from '../../servises/translator.service';
import {LocaleEnum} from '../../servises/locale';

@Component({
  selector: 'l-date',
  templateUrl: './l-date.component.html',
  styleUrls: ['./l-date.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LDateComponent extends LocalComponent {
  @Input() public date: Date;
  @Input() public format: string;
  @Input() public isUtc: boolean;

  public get Locale(): LocaleEnum {
    return this.translator.CurrentLocale;
  }

  constructor(detector: ChangeDetectorRef, translateManager: TranslateManagerService, private translator: TranslatorService) {
    super(detector, translateManager);
  }
}
