import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleProgressBarComponent } from '@common/shared/components/circle-progress-bar/circle-progress-bar.component';
import {ThemeModule} from '@common/shared/services/theme';

@NgModule({
  declarations: [
    CircleProgressBarComponent
  ],
  exports: [
    CircleProgressBarComponent
  ],
  imports: [
    CommonModule,
    ThemeModule
  ]
})

export class CircleProgressBarModule {}
