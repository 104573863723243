import {Injectable} from '@angular/core';
import {WatchListService} from '@common/watchlist/services/watch-list.service';
import {WatchListMap, WatchListStorageService} from '@common/watchlist/services/watch-list-storage.service';
import {WatchListLocalStorage} from '@common/shared/services/watch-list-local-storage';
import {WatchList} from '@common/watchlist/model/watch-list';
import {Trader} from '@common/trader/models/trader';

export interface WatchListToSave {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class WatchListViewControlService {
  constructor(private watchlistService: WatchListService,
              private watchlistStorage: WatchListStorageService,
              private watchListLocalStorage: WatchListLocalStorage) {
  }

  public async createWatchList(name: string, trader: Trader): Promise<string> {
    if (!this.watchlistStorage.checkWatchListNameRepeat(name)) {
      const result = new Promise<string>((resolve, reject) => {
        if (trader.IsGuest) {
          this.watchlistStorage.addNewWatchList(trader.getNextWatchListID(), name);
          this.watchListLocalStorage.saveWatchLists(trader.TraderId);
          resolve('submitted');
        } else {
          this.watchlistService.createWatchList(name, trader)
            .then(id => {
              resolve('submitted');
              this.watchlistStorage.addNewWatchList(id, name);
            })
            .catch(message => reject(message));
        }
      });
      return result;
    } else {
      return 'WatchList is contained in the data base';
    }
  }

  public async updateWatchList(watchListId: number, watchListName: string, trader: Trader): Promise<string> {
    if (!this.watchlistStorage.checkWatchListNameRepeat(watchListName)) {
      const result = new Promise<any>(((resolve, reject) => {
        if (trader.IsGuest) {
          this.watchlistStorage.updateWatchList(watchListId, watchListName);
          this.watchListLocalStorage.saveWatchLists(trader.TraderId);
          resolve('submitted');
        } else {
          this.watchlistService.updateWatchList(watchListId, watchListName)
            .then(() => {
              this.watchlistStorage.updateWatchList(watchListId, watchListName);
              resolve('submitted');
            })
            .catch(message => reject(message));
        }
      }));
      return result;
    } else {
      return 'This name is contained';
    }


  }

  public async deleteWatchList(id: number, trader: Trader): Promise<number> {
    const result = new Promise<number>((resolve, reject) => {
      if (trader.IsGuest) {
        this.watchlistStorage.removeWatchlist(id);
        this.watchListLocalStorage.saveWatchLists(trader.TraderId);
      } else {
        this.watchlistService.deleteWatchList(id)
          .then(iWatchListPromise => {
            const idWatchlistToOpen = this.watchlistStorage.removeWatchlist(id);
            resolve(idWatchlistToOpen);
          })
          .catch(message => reject(message));
      }
    });

    return result;
  }

  public async addSymbolToWatchList(watchlistID: number, symbolID: number, trader: Trader): Promise<boolean> {
    if (!this.watchlistStorage.symbolIsContainedInWatchList(watchlistID, symbolID)) {
      if (trader.IsGuest) {
        this.watchlistStorage.addSymbolToWatchList(watchlistID, trader.getNextWatchListContentID(), symbolID);
        this.watchlistStorage.addSymbolToWatchList(watchlistID, 1, symbolID);
        this.watchListLocalStorage.saveWatchLists(trader.TraderId);
      } else {
        const result = new Promise<boolean>((resolve, reject) => {
          this.watchlistService.addSymbolToWatchList(watchlistID, symbolID)
            .then(watchlistContentID => {
              this.watchlistStorage.addSymbolToWatchList(watchlistID, watchlistContentID, symbolID);
              resolve(true);
            })
            .catch(message => reject(message));
        });

        return result;
      }
    } else {
      console.log('Symbol is contained in the watch list');
      return false;
    }
  }

  public async removeSymbolFromWatchList(watchlistID: number, contentID: number, trader: Trader): Promise<boolean> {
    const result = new Promise<any>((resolve, reject) => {
      if (trader.IsGuest) {
        this.watchlistStorage.removeSymbolFromWatchList(watchlistID, contentID);
        this.watchListLocalStorage.saveWatchLists(trader.TraderId);
        resolve(true);
      } else {
        this.watchlistService.removeSymbolFromWatchList(contentID)
          .then(iWatchListPromise => {
            this.watchlistStorage.removeSymbolFromWatchList(watchlistID, contentID);
            resolve(true);
          })
          .catch(message => reject(message));
      }
    });

    return result;
  }

  public getTraderWatchLists(): WatchListMap[] {
    return this.watchlistStorage.WatchlistMap;
  }

  public async copyWLFromLocalStorageToDataBase(trader: Trader) {
    this.watchlistStorage.removeWatchListsFromFrontEnd();
    const watchLists: WatchList[] = this.watchListLocalStorage.getWatchLists();

    if (watchLists !== null) {
      const watchListsToSave: WatchListToSave[] = [];
      for (const watchList of watchLists) {
        if (!watchListsToSave.find((watchListToSave) => watchListToSave.name === watchList['_watchListName'])) {
          await this.watchlistService.createWatchList(watchList['_watchListName'], trader).then(async (ID) => {

            if (watchList['_symbolID'] !== 0) {
              const wlToSave: WatchListToSave = {
                id: ID,
                name: watchList['_watchListName']
              };

              watchListsToSave.push(wlToSave);
              await this.watchlistService.addSymbolToWatchList(ID, watchList['_symbolID']);
            }
          });
        } else {
          const ID = watchListsToSave.find((wlToSave) => wlToSave.name === watchList['_watchListName']).id;
          await this.watchlistService.addSymbolToWatchList(ID, watchList['_symbolID']);
        }
      }

      this.watchlistStorage.MyWatchLists = watchLists;
      this.watchlistStorage.loadWatchlistMapFromLocalStorage();
      this.watchListLocalStorage.deleteWatchLists();
    }
  }
}
