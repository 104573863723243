import {EventEmitter, Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {Command_SaveError} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class ErrorCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger(this);

  public saveErrorEvent: EventEmitter<Command_SaveError> = new EventEmitter<Command_SaveError>();

  public constructor(serverConnection: ServerInteractionService) {
    super(serverConnection);
  }

  public errorSave(sessionId: string, loginName: string, error: Error, timeStamp: string, context: string, appVersion: string): ICommandPromise {
    const command = new Command_SaveError();
    command.SessionID = sessionId;
    command.LoginName = loginName;
    command.Message = error.message;
    command.StackTrace = error.stack;
    command.Timestamp = timeStamp;
    command.Context = context;
    command.AppVersion = appVersion;
    command.Application = 'Error';

    // далее идет проверка о повторяющихся ошибках, при уникальной ошибке она отправляется в базу и пишется в сессию стородж, повторные ошибки игнорируются

    let check = false;

    if (sessionStorage.getItem('temporaryErrorStorage') == null) {
      sessionStorage.setItem('temporaryErrorStorage', JSON.stringify([command]));
    } else {
      let errorArray: any[] = JSON.parse(sessionStorage.getItem('temporaryErrorStorage'));


      errorArray.forEach((err: any) => {
        if (err.Message === command.Message) {
          check = true;
        }
      });

      if (!check) {
        const newItem: any = command;
        errorArray = [... errorArray, newItem];
        sessionStorage.setItem('temporaryErrorStorage', JSON.stringify(errorArray));
      }
    }

    if (!check) {
      console.log(command);
      this.saveErrorEvent.emit(command);
      return this.sendCommand(command);
    }
  }
}
