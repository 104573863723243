import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '@common/configuration/app-config';
import {DataBaseLocalStorageTimedCache} from '@common/shared/services/Cache/BaseCacheComponent';
import {BrowserPageService} from '@common/trade/services/browser-page.service';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {environment} from '../../../environments/environment';
import {ConnectionConfig} from '@common/communication/connection/connection.service';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {UsersGeoData} from '@common/trade/services/location-position.service';

export interface ConnectionStatusData {
  personalAccountId: number;
  loginName: string;
  ip: string;
  timestamp: string;
  application: string;
  appVersion: string;
  context: any;
  message: string;
  stackTrace: string;
}

@Injectable({
  providedIn: 'root'
})

export class EntrySpotCommandSender {
  private answer = false;
  private topGainersLosersArray: any = [];
  private changeTopGainersLosersArray: EventEmitter<string> = new EventEmitter<string>();
  private ip = '';

  public constructor(private http: HttpClient,
                     private appConfig: AppConfig,
                     private dataBaseLocalStorageCache: DataBaseLocalStorageTimedCache,
                     private localStorageService: LocalStorageService) {
  }

  private get(api: string, titleLog: string = '') {
    return new Promise<string>((resolve, reject) => {
      this.http.get(api).subscribe({
        next: (res: any) => {
          console.log(titleLog, res);
          resolve(res);
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        }
      });
    });
  }

  private post(api: string, responseType: any, body: any, titleLog: string, modifiedResponse: boolean = false, precondition: string = undefined) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(api, body, {responseType: responseType} ).subscribe({
        next: (res: any) => {
          console.log(titleLog, res);
          resolve(res);
        },
        error: (err: any) => {
          if (precondition === 'answerRegisterAccount') {
            if (modifiedResponse) {
              console.log(JSON.parse(err.error).title);
              reject(JSON.parse(err.error).title);
            } else {
              console.log(JSON.parse(err.error).detail);
              reject(JSON.parse(err.error).detail);
            }
          } else {
            console.log(err);
            reject(err);
          }
        }
      });
    });
  }

  public async GetTradingSchedule(tradeGroupId: number = 0): Promise<any> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/TradingSchedule/GetTradingScheduleTVFormatForTradeGroup?tradeGroupId=${tradeGroupId}`;
    return this.get(api, 'TradingSchedule');
  }

  public async GetServerTime(): Promise<any> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Time/GetServerTime`;
    return this.get(api, 'GetServerTime :');
  }

  public async createExternalAccount(externalAccountID: string, email: string, passwordHash: string): Promise<string | void> {
    // const emailReplace = email.replace('@', '%40');
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Registration/RegisterAccount?email=${email}&pwdHash=${passwordHash}&externalAccountID=${externalAccountID}`;
    const result = this.post(api, 'text', '', 'ExternalAccount');
    const guid = await result;
    sessionStorage.setItem('guid', JSON.stringify(guid));
    return result;
  }

  public  async registrationContinue(code: string): Promise<string | void> {
    const guid = JSON.parse(sessionStorage.getItem('guid'));
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Registration/ContinueRegistration?guid=${guid}&registrationCode=${code}`;
    return this.answerRegisterAccount(api);
  }

  // запрос на сервер создание нового регистрационно кода
  public  async resendRegistrationCode(): Promise<string | void> {
    const guid = JSON.parse(sessionStorage.getItem('guid'));
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Registration/ResendRegistrationCode?guid=${guid}`;
    return this.answerRegisterAccount(api);
  }

  public  async registerAccountWithoutRegCode(externalAccountID: string, email: string, passwordHash: string, phoneNumber: string, firstName: string, lastName: string): Promise<string | void> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Registration/RegisterAccountWithoutRegCode?email=${email}
                                  &pwdHash=${passwordHash}&externalAccountID=${externalAccountID}&phoneNumber=${phoneNumber}
                                  &firstName=${firstName}&lastName=${lastName}`;
    return this.answerRegisterAccount(api, true);
  }

  public async retrieveSymbolMarketData(symbolId: number): Promise<any> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/MarketData/RetrieveSymbolMarketData?symbolId=${symbolId}`;
    return this.get(api, 'retrieveSymbolMarketData');
  }

  public async GetSymbolEodStates(tradeGroupId: number): Promise<any> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/MarketData/GetSymbolEodStates?tradeGroupId=${tradeGroupId}`;
    return this.get(api, 'GetSymbolEodStates');
  }

  private async getListGainersLosers(tradeGroupId: number, directOrder: boolean): Promise<any> {
    let count = 10;
    if (this.appConfig.Settings.lengthArrayTopGainersTopLosers !== undefined && this.appConfig.Settings.lengthArrayTopGainersTopLosers.Count ) {
      count = this.appConfig.Settings.lengthArrayTopGainersTopLosers.Count;
    }
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/MarketData/TopGainersLosers?tradeGroupID=${tradeGroupId}&directOrder=${directOrder}&topRecordsCount=${count}`;
    return this.get(api, 'ListGainersLosers');
  }

  // данные для графика на странице маркет
  public async retrieveMarketWidgetSymbols(tradeGroupId: number): Promise<any> {
    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/MarketData/RetrieveMarketWidgetCharts?tradeGroupID=${tradeGroupId}`;
    return this.get(api, 'MarketWidgetSymbol');
  }

  public saveToErrorDB(type: string, config: ConnectionConfig, regionConnection: string,  error?: Error) {
    if (this.ip.length === 0 && this.localStorageService.getUsersGeoData()) {
      const usersGeoData: UsersGeoData  = this.localStorageService.getUsersGeoData();
      this.ip = usersGeoData.ip ? usersGeoData.ip : '';
    }

    const api = `${this.appConfig.Settings.entrySpotUrls.EntrySpotBaseUrls}/Errors/Save`;
    const data = this.createDataForSendSave(type, config, regionConnection, error);
    this.post(api, 'json', data, 'SaveToErrorDB').then();
  }

  private answerRegisterAccount(api: string, modifiedResponse = false) {
    return this.post(api, 'text', '', 'RegisterAccount', modifiedResponse, 'answerRegisterAccount');
  }

  // запрос для получения данных для top-gainers и top-losers
  public async requestTopGainersLosers(tradeGroupId: number, markets?: boolean) {
    await this.getDataForTimedCache(tradeGroupId, 'TopLosers', markets, false);
    await this.getDataForTimedCache(tradeGroupId, 'TopGainers', markets, true);
    await this.getDataForTimedCache(tradeGroupId, 'ChartsMarketWidgetSymbols', markets);
  }

  // метод получает данные для таблиц top-losers top-gainers и сохраняет их в локалстородже, предварительно проверя на актуальность локол и расширяет мап src иконок
  private async getDataForTimedCache(tradeGroupId: number, key: string, markets?: boolean, directOrder?: boolean): Promise<any> {
    const dataTimedCache = this.dataBaseLocalStorageCache.getItems(tradeGroupId);

    if (dataTimedCache === undefined || dataTimedCache[key] === undefined) {
      console.log(`Data obtained ${key} for the trading group ${tradeGroupId}`);
      if (key === 'ChartsMarketWidgetSymbols') {
        this.requestChartsMarketWidgetSymbols(tradeGroupId, key);
      } else {
        this.requestDataForTopGainersLosers(tradeGroupId, directOrder, key);
      }
    } else {
      const currentData = new Date();
      const elapsed = currentData.getTime() - new Date(dataTimedCache[key].date).getTime();
      const minutes = Math.round(elapsed / 1000 / 60  );
      console.log(`The data for  ${key}  was updated ${minutes} minutes back, trade group ${tradeGroupId}`);
      if (minutes > 15) {
        console.log(`Data for  ${key}  the trading group ${tradeGroupId} has been updated `);
        if (key === 'ChartsMarketWidgetSymbols') {
          this.requestChartsMarketWidgetSymbols(tradeGroupId, key, markets);
        } else {
          this.requestDataForTopGainersLosers(tradeGroupId, directOrder, key, markets);
        }
      }
    }
  }

  // запрос на получении данных для top-losers top-gainers
  private async getTop(tradeGroupId: number, directOrder: boolean): Promise<any> {
    return this.getListGainersLosers(tradeGroupId, directOrder).then((data) => {
      const t = Object.values(data);
      this.topGainersLosersArray = this.filterSymbolsForMarketsOnly(t[0]);
      if (this.topGainersLosersArray.length === 0) {
        this.answer = true;
      } else {
        this.topGainersLosersArray.forEach((e: any) => {
          if ( e.symbolCategoryName === 'PreciousMetals') {
            e.symbolCategoryName = 'Metals';
          } else if ( e.symbolCategoryName === 'DollarPairs') {
            e.symbolCategoryName = 'Forex';
          }
        });
      }
    }).catch((error) => {
      console.log(error);
      this.answer = true;
    });
  }

  // метод не добавляет символы из графика маркет
  private filterSymbolsForMarketsOnly(arr: any) {
    if ( this.appConfig.Settings.symbolsForMarketsOnly !== undefined) {
      const symbolsForMarketsOnly: any[] = this.appConfig.Settings.symbolsForMarketsOnly;
      const arrTemp = [];
      const arrTempBlackList = [];
      arr.forEach((e: any) => {
        const symbolId = e.symbolId;
        if (!symbolsForMarketsOnly.includes(symbolId)) {
          arrTemp.push(e);
        } else {
          arrTempBlackList.push(e);
        }
      });
      console.log('List of ignore characters', arrTempBlackList);
      return arrTemp;
    } else {
      return arr;
    }
  }

  public get ChangeTopGainersLosersArrayEmitter(): EventEmitter<string> {
    return this.changeTopGainersLosersArray;
  }

  private requestDataForTopGainersLosers(tradeGroupId: number, directOrder: boolean, key: string, markets?: boolean) {
    this.getTop(tradeGroupId, directOrder).then(() => {
      const topGainersLosersArray = {
        'date': new Date(),
        'tradeGroupId': tradeGroupId,
        'answer': this.answer,
        'TopGainersLosers': this.topGainersLosersArray
      };
      this.setDataLocalStorageCache(tradeGroupId, key, topGainersLosersArray, markets);

    });
  }

  private requestChartsMarketWidgetSymbols(tradeGroupId: number, key: string, markets?: boolean) {
    this.retrieveMarketWidgetSymbols(tradeGroupId).then((answer) => {
      const dataBase = {
        'date': new Date(),
        'RetrieveMarketWidgetSymbols': answer
      };
      this.setDataLocalStorageCache(tradeGroupId, key, dataBase, markets);

    });
  }

  private setDataLocalStorageCache(tradeGroupId: number, key: string, data: any, markets?: boolean) {
    this.dataBaseLocalStorageCache.setDataLocalStorageTimedCache(tradeGroupId, key, data);
    if (markets) {
      this.changeTopGainersLosersArray.emit(key);
    }
  }

  private createDataForSendSave(type: string, config: ConnectionConfig, regionConnection: string = '',  error?: Error): ConnectionStatusData {
    return {
      personalAccountId: 0,
      loginName: config.login,
      ip: this.ip + ' / ' + regionConnection,
      timestamp: OperationsWithDate.createTimeStamp(),
      application: type,
      appVersion: environment.platform,
      context: this.createContextObj(config),
      message: error ? error.message : this.createConnectionObj(),
      stackTrace: error ? error.stack : new Error().stack,
    };

  }

  private createContextObj(config: ConnectionConfig): string {
    const LoginDetails = BrowserPageService.getGuidPageData();
    return JSON.stringify({
      loginDetails: LoginDetails !== null ? LoginDetails : null,
      browser: BrowserPageService.getBrowserName(window.navigator.userAgent),
      sessionId: config.sessionId,
      connectionUri: config.connectionUri,
      accessToken: config.accessToken ? config.accessToken : null,
    });
  }

  private createConnectionObj(): string {
    const connection = (navigator as any).connection;
    return JSON.stringify({
      downlink: connection['downlink'],
      effectiveType: connection['effectiveType'],
      rtt: connection['rtt']
    });
  }

}
