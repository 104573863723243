import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {Notification_Balance} from '../../connection/classes.g';
import {Accounting} from '../../../trader/models/accounting.service';
import {Injectable} from '@angular/core';
import {Trader} from '@common/trader/models/trader';

@Injectable({
  providedIn: 'root'
})
export class BalanceMessageProcessor extends AbstractMessageProcessor {

  private _notificationID = 0;
  public constructor(serverConnection: ServerInteractionService, private accounting: Accounting,
                     private trader: Trader) {
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToNotificationBalance();
  }

  private subscribeToNotificationBalance(): void {
    const subscription = this.serverConnection.on(Notification_Balance.GetMessageType(),
      (answer: Notification_Balance) => this.onBalanceChanged(answer),
      (error: any) => this.logger.error('Balance handling error:', error));

    this.saveSubscription(Notification_Balance.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${Notification_Balance.GetMessageType()}`);
  }

  private onBalanceChanged(answer: Notification_Balance) {
    console.log(answer);
    if (this._notificationID === 0 || Number(answer.NotificationID) > this._notificationID) {
      this._notificationID = Number(answer.NotificationID);
      this.logger.debug('Got balance');
      this.accounting.Balance = Number(answer.Balance);
      this.accounting.CreditIssued = Number(answer.CreditIssued);
      this.accounting.EmitNotificationBalance();
      this.trader.onNotificationBalance(answer.Balance);
    }
  }

  public resetCount() {
    this._notificationID = 0;
  }
}
