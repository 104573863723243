export enum AccountingDocumentType {
  UPL = -1,
  Deposit = 1,
  Withdrawal = 2,
  Profit = 3,
  Storage = 4,
  Commission = 6,
  Miscalculation = 8,
  PoolIn = 9,
  PoolOut = 10,
  IncomeTransfers = 11,
  Margin = 12,
  Credit = 15,
  Rebate = 16,
  GST = 17,
  Withholding = 18,
  TransactionFee = 20,
  SubaccountFee = 21
}
