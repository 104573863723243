import {ProtoV2MessageBase} from './proto-v2-message-base';
import {Injectable} from '@angular/core';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {Notification_FullMarketDepth, Notification_ItemTick} from './classes.g';
import {CommunicationModule} from '@common/communication/communication.module';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

@Injectable({
  /*providedIn: CommunicationModule*/
  providedIn: 'root'
})
export class MessageDispatcherService
{
  private _deliveryLogger = LoggerFactory.getLogger('MessageDeliveryLogger');
  private _eventEmitters: Map<string, SmartEmitter<ProtoV2MessageBase>> = new Map<string, SmartEmitter<ProtoV2MessageBase>>();

  public onMessageFromConnection(message: ProtoV2MessageBase)
  {
    /*if (message.MessageType !== Notification_ItemTick.GetMessageType())
      console.log(message);*/

    if (message.MessageType !== Notification_ItemTick.GetMessageType() &&
      message.MessageType !== Notification_FullMarketDepth.GetMessageType()) {
      this._deliveryLogger.info(`received message`, message);
    }
    const emitter = this._eventEmitters.get(message.MessageType);
    if (emitter) {
      emitter.emit(message);
    }
  }

  public on(messageClassName: string, callback?: any, error?: any): ISmartSubscription {
    // TODO: React on Notification_SymbolSettingsUpdated


    let emitter = this._eventEmitters.get(messageClassName);
    if (!emitter) {
      emitter = new SmartEmitter<ProtoV2MessageBase>();
      this._eventEmitters.set(messageClassName, emitter);
    }

    const subscription = emitter.subscribe(callback, error);

    return subscription;
  }

  public unsubscribeAll(){
    this._eventEmitters.forEach(item => item.kill());

    this._eventEmitters.clear();
  }
}
