<div class="c-form-container">
  <form name="orderForm" action="#" class="c-form order-form" (ngSubmit)="submit()">
    <div class="c-form-group">
      <label for="selected-symbol" class="c-label"><l-unit [key]="'Modal_ModifyOrderModalComponent_SymbolTitle'"></l-unit></label>
      <select name="selected-symbol" id="selected-symbol" class="c-select" [(ngModel)]="Symbol">
        <option *ngFor="let symbol of Symbols" [ngValue]="symbol">{{symbol.SymbolName}}</option>
      </select>
    </div>

    <div class="c-form-group">
      <label class="c-label"><l-unit [key]="'Modal_ModifyOrderModalComponent_AmountTitle'"></l-unit></label>
      <number-input name="volume" [(ngModel)]="Amount" decimalPlaces="2"></number-input>
    </div>


    <div class="c-form-group">
      <label class="c-label"><l-unit [key]="'Modal_OpenOrderModalComponent_RateTitle'"></l-unit></label>
      <input type="text" class="c-input" name="rate" disabled [ngModel]="Rate" [(ngModel)]="Order.OpenPrice" />
    </div>

    <app-sltp-input [trade]="Order" [price]="Rate"></app-sltp-input>

    <red-alert *ngIf="Error">
      {{Error}}
    </red-alert>

    <input type="submit" class="c-button c-form-button-submit order-button" value="{{ButtonText}}"  [attr.disabled]=" ButtonDisabled ? 'disabled' : null" />
  </form>
</div>
