import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppConfig} from '@common/configuration/app-config';
import {ThemeService} from '@common/shared/services/theme';

@Component({
  selector: 'circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss']
})
export class CircleProgressBarComponent implements OnInit, OnChanges {

  @Input() progress: number;
  @Input() color: string;

  conicGradient: string;

  background: string;

  constructor(private appConfig: AppConfig,
              private themeService: ThemeService ) {

    this.background = this.appConfig.Settings.carbonTheme ? '#434343' : '#131722';
    // this.background = this.appConfig.Settings.carbonTheme ? '#434343' : '#33395b';

    this.themeService.getThemeChange().subscribe((value) => {
      this.currentBgColor(value.name);
    });

    const activeTheme = localStorage.getItem('theme');

    if (activeTheme !== null) {
      this.currentBgColor(activeTheme);
    } else {
      this.background = '#131722';
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    return  this.conicGradient = `conic-gradient(${this.color} ${this.progress}%, ${this.background} ${this.progress}%)`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  private currentBgColor(activeTheme: string) {
    if (activeTheme === 'tvZetradexDarkTheme' || activeTheme === 'tvDarkTheme') {
      this.background = '#131722';
    } else if (activeTheme === 'tvZetradexLightTheme' || activeTheme === 'tvLightTheme') {
      this.background = '#f1f1f1';
    } else {
      this.background = '#131722';
      // this.background = '#33395b';
    }
  }
}
