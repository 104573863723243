import {AfterContentInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {VisibilitySyncService} from '@common/shared/services/visibility-sync.service';

type Position = {top: number, right: number, bottom: number, left: number};

@Directive({
  selector: '[visible]',
  exportAs: 'visible'
})
export class IsVisibleDirective implements OnDestroy, AfterContentInit, OnInit {
  private el: HTMLElement;

  private visibleEmitter = new EventEmitter<boolean>();

  @Input() debounce = 0;

  private shouldCheckSubscription: ISmartSubscription;

  public visibility = undefined;

  private get DebounceNum(): number {
    return Number(this.debounce);
  }

  @Output('visible')
  public get visibleEvent(): Observable<boolean> {
    if(this.DebounceNum) {
      return this.visibleEmitter.asObservable();
    }
    else return this.visibleEmitter.asObservable()
  }

  constructor(private elRef: ElementRef, private vSync: VisibilitySyncService, private detector: ChangeDetectorRef) {
    this.el = elRef.nativeElement;
  }

  ngOnInit(): void {
    if(this.vSync.IsFrameReady) {
      this.checkVisibility();
      this.emitVisible();
    }
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {
    if (this.shouldCheckSubscription) {
      this.shouldCheckSubscription.unsubscribe();
    }
  }

  public emitVisibilityState(): boolean {
    this.emitVisible();
    return this.visibility;
  }

  public setVisibility(v: boolean) {
    this.visibility = v;
    this.emitVisible();
  }

  public checkVisibility() {
    this.visibility = this.IsVisible;
  }

  private emitVisible() {
    this.visibleEmitter.emit(this.visibility);
    this.detector.detectChanges();
  }

  private elementPosition(el: HTMLElement): Position {
    const result = {
      top: window.pageYOffset + el.getBoundingClientRect().top,
      right: window.pageXOffset + el.getBoundingClientRect().right,
      bottom: window.pageYOffset + el.getBoundingClientRect().bottom,
      left: window.pageXOffset + el.getBoundingClientRect().left,
    };

    return result
  }

  public get IsVisible(): boolean {
    const current = this.elementPosition(this.el);
    const parent = this.elementPosition(this.el.parentElement);

    const result = parent.top > current.top && parent.bottom < current.top ||
      parent.bottom < current.bottom && parent.top > current.bottom ||
      parent.bottom >= current.bottom && parent.top <= current.top;

    return result;
  }
}
