import {Injectable} from '@angular/core';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Symbol} from 'src/app/symbol/models/symbol';
import {SymbolIcons} from '@common/shared/services/symbol-icons';

export interface Info extends SymbolInfo {
  textIcon: string;
  symbolIcon: string;
}

export interface SymbolInfo {
  [symbolId: number]: Info;
}

@Injectable({
  providedIn: 'root'
})

export class SymbolInfoService {
  private symbolsInfo: SymbolInfo[] = [];

  constructor(private symbolStorageService: SymbolStorageService,
              private symbolIcons: SymbolIcons) {
  }

  private static getIconName(symbol: Symbol): string {
    let iconName: string;

    iconName = symbol.SymbolName;

    // при загрузке приложения присвоение название валютной пары переменной
    // if ( symbol.CounterCurrency === '') {
    //   iconName = symbol.SymbolName;
    // } else {
    //   iconName = symbol.BaseCurrency + symbol.CounterCurrency;
    // }

    // if (symbol.BaseCurrency === symbol.CounterCurrency || symbol.CounterCurrency === '') {
    //   iconName = symbol.BaseCurrency;
    // } else {
    //   iconName = symbol.BaseCurrency + symbol.CounterCurrency;
    // }
    return iconName;
  }

  private static getIconLetter(symbolName: string): string {
    return symbolName['0'].toUpperCase();
  }

  public getSymbolIcon(symbol: Symbol): string {
    return this.symbolsInfo[symbol.SymbolId]['symbolIcon'];
  }

  public getSymbolIconById(symbolId: number): string {
    return this.symbolsInfo[symbolId]['symbolIcon'];
  }

  public getTextIcon(symbol: Symbol): string {
    return this.symbolsInfo[symbol.SymbolId]['textIcon'];
  }

  public getTextIconById(symbolId: number): string {
    return this.symbolsInfo[symbolId]['textIcon'];
  }

  public init() {
    this.loadSymbolInfo();
  }

  // метод обновляет массив src ссылок для иконок при переходе на /markets/top-losers и markets/top-gainers

  public loadSymbolInfoTop(array) {
    array.forEach(symbol => {
      const info: Info = {
        symbolIcon: this.symbolIcons.getIcon(symbol.symbolName),
        textIcon: SymbolInfoService.getIconLetter(symbol.symbolName)
      };
      this.symbolsInfo[symbol.symbolId] = info;
    });
  }

  private loadSymbolInfo() {
    this.symbolStorageService.Symbols.forEach(symbol => {
      const info: Info = {
        symbolIcon: this.symbolIcons.getIcon(SymbolInfoService.getIconName(symbol)),
        textIcon: SymbolInfoService.getIconLetter(symbol.SymbolName)
      };
      this.symbolsInfo[symbol.SymbolId] = info;
    });
    // repeating the same action
    this.symbolStorageService.InternalSymbols.forEach(symbol => {
      const info: Info = {
        symbolIcon: this.symbolIcons.getIcon(SymbolInfoService.getIconName(symbol)),
        textIcon: SymbolInfoService.getIconLetter(symbol.SymbolName)
      };
      this.symbolsInfo[symbol.SymbolId] = info;
    });
  }

  public getMarketMainTopSixSymbols(): Symbol[] {
    return this.symbolStorageService.Symbols.slice(0, 6);
  }
}





