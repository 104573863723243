import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {TradeParser} from '@common/trade/utils/trade.parser';
import {Answer_TicketAccept, Notification_OrderStates, Notification_TicketDecline} from '../../connection/classes.g';
import {TradeDispatcherService} from '@common/trade/services/trade-dispatcher.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TradeStateMessageProcessor extends AbstractMessageProcessor {
  public constructor(serverConnection: ServerInteractionService,
                     private tradeStorage: TradeStorage,
                     private symbolStorage: SymbolStorageService,
                     private tradeDispatcher: TradeDispatcherService) {
    super(serverConnection);
  }

  public subscribe(): void {
    this.subscribeToTradesChanging();
    this.subscribeToTicketDecline();
    this.subscribeToTicketAccept();
  }
  private subscribeToTradesChanging(){
    const subscription =  this.serverConnection.on(Notification_OrderStates.GetMessageType(),
      (answer) => this.onStatesHandle(answer),
      error => this.logger.error('Trade state handling error:', error));

    this.saveSubscription(Notification_OrderStates.GetMessageType(), subscription);

    this.logger.debug(`Subscribed for ${Notification_OrderStates.GetMessageType()}`);
  }
  private subscribeToTicketDecline(){
    const subscription =  this.serverConnection.on(Notification_TicketDecline.GetMessageType(),
      (answer) => this.onTicketDecline(answer));

    this.saveSubscription(Notification_TicketDecline.GetMessageType(), subscription);

    this.logger.debug(`Subscribed for ${Notification_TicketDecline.GetMessageType()}`);
  }
  private subscribeToTicketAccept(){
    const subscription =  this.serverConnection.on(Answer_TicketAccept.GetMessageType(),
      (answer) => this.onTicketAccept(answer));

    this.saveSubscription(Answer_TicketAccept.GetMessageType(), subscription);

    this.logger.debug(`Subscribed for ${Answer_TicketAccept.GetMessageType()}`);
  }

  private async onStatesHandle(answer: Notification_OrderStates){
    const allStates = new TradeParser(this.symbolStorage).parseStates(answer.OrderStates);
    console.log(allStates);
    await this.tradeStorage.onTradesChanged(allStates);
  }
  private async onTicketDecline(answer: Notification_TicketDecline){
    const promise = this.tradeDispatcher.release(answer.DealID);
    promise.releaseDecline(answer);
    promise.doFinaly();
  }
  private async onTicketAccept(answer: Answer_TicketAccept) {
    console.log('ticket accept deal', answer);
    console.log('trade dispatcher2:', this.tradeDispatcher);
    const promise = this.tradeDispatcher.release(answer.DealID);
    console.log(promise);
    if (promise !== undefined) {
      try {
        promise.releaseAccept(answer);
      } catch (e) {
        console.error(e);
      } finally {
        promise.doFinaly();
      }
    }
  }
}
