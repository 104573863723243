import {ITransport} from '../transport/transport-interface.js';
import {TransportEventHandlerInterface} from '../transport/transport-event-handler-interface.js';
import {ProtocolBase} from './protocol-base';
import {ProtoV2MessageBase} from '../proto-v2-message-base';

export class TransportToProtocol extends ProtocolBase implements TransportEventHandlerInterface {
  private _transport: ITransport;

  constructor(transport: ITransport)
  {
    super();
    this._transport = transport;
    this._transport.setEventHandler(this);
  }

  public send(message: ProtoV2MessageBase)
  {
    const encodedMessage = JSON.stringify(message);
    this._transport.send(encodedMessage);
  }

  public connect()
  {
    this._transport.connect();
  }

  public disconnect(msg?: string)
  {
    this._transport.disconnect(msg);
  }

  onMessageReceived(message: string)
  {
    message = message.replace(/\n/g, ' ');

    let decodedMessage: any;

    try {
      decodedMessage = JSON.parse(message);
      this._logger.info(decodedMessage);
    } catch (ex) {
      console.error('Error while parsing JSON message:', ex);
      console.error('Original JSON:', message);

      return;
    }
    decodedMessage.BinaryID = Number(decodedMessage.BinaryID);
    this.getEventHandler().onMessageReceived(decodedMessage);
  }
  onDisconnected(msg: string)
  {
    this.getEventHandler().onDisconnected(msg);
  }
  onConnectionEstablished()
  {
    this.getEventHandler().onConnectionEstablished();
  }
  onConnectionEstablishedError(msg)
  {
    this.getEventHandler().onConnectionEstablishedError(msg);
  }
}
