import {Component, OnInit} from '@angular/core';
import {Locale, LocaleEnum} from '../../servises/locale';
import {ChartingProviderService} from '@common/charting/services/charting-provider.service';
import {TranslatorService} from '@common/locale/servises/translator.service';

@Component({
  selector: 'app-l-switch',
  templateUrl: './l-switch.component.html',
  styleUrls: ['./l-switch.component.css']
})
export class LSwitchComponent implements OnInit {

  public get Locale(): LocaleEnum {
    return this.translator.CurrentLocale;
  }

  public get Locales(): Locale[] {
    return this.translator.Locales;
  }

  public set Locale(v: LocaleEnum) {
    this.translator.changeLocale(v);
    this.chartingService.setLanguage(String(v));
  }

  constructor(private translator: TranslatorService,
              private chartingService: ChartingProviderService
              ) { }

  ngOnInit() {
  }

}
