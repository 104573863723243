import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeModule} from '@common/shared/services/theme';
import {ProgressBarCompletionComponent} from '@common/shared/components/progress-bar-completion/progress-bar-completion.component';


@NgModule({
  declarations: [
    ProgressBarCompletionComponent
  ],
  exports: [
    ProgressBarCompletionComponent
  ],
  imports: [
    CommonModule,
    ThemeModule
  ]
})

export class ProgressBarCompletionModule {}
