export enum SymbolCategory {
  DollarPairs = 1,
  CrossPairs = 2,
  CFDs = 4,
  PreciousMetals = 5,
  Cryptos = 6,
  Equities,
  Energies,
  Futures,
  Indices
}
