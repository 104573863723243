import {PriceDirection} from '@common/symbol/models/last-quote-info';
import {Symbol} from '@common/symbol/models/symbol';

export class SymbolPriceFormatter {

  private _priceInfo: PriceDirection;
  private _symbol: Symbol;

  private lenAfterPoint = 0;
  private lenToPoint = 0;
  private basisLen = 0;
  // сразу присвоил значение для переменной pipsLen т.к. она не меняется
  private pipsLen = 2;
  private pointsLen = 0;

  private shouldUseWithPoint: boolean;

  public get PriceInfo(): PriceDirection {
    return this._priceInfo;
  }
  public set PriceInfo(value: PriceDirection) {
    this._priceInfo = value;
  }

  public get Symbol(): Symbol {
    return this._symbol;
  }
  public set Symbol(value: Symbol) {
    this._symbol = value;
  }

  public get Price(): string {
    if (!this._priceInfo.Price) {
      return '';
    }
    return this._priceInfo.Price.toFixed(this._symbol.DecimalPlaces);
  }

  public get Basis(): string {
    return this.Price.slice(0, this.getBasisLen());
  }
  public get Last2Pips(): string {
    return this.Price.slice(this.getBasisLen(), this.getBasisLen() + this.pipsLen);
  }
  public get Tick(): string {
    const pointsLen = (this.Price.length - this.Price.indexOf('.') - 1) > this.Symbol.PipDecimalPlaces ? 1 : 0;
    return this.Price.slice(this.getBasisLen() + this.pipsLen, this.getBasisLen() + this.pipsLen + pointsLen);
  }

  private getBasisLen(): number {
    return this.Price.indexOf('.') + this.Symbol.DecimalPlaces - 2;
  }

  public get PriceIsCorrect(): boolean {
    return !isNaN(this.PriceInfo.Price) && isFinite(this.PriceInfo.Price);
  }

  constructor(symbol: Symbol, priceInfo: PriceDirection) {
    this._symbol = symbol;
    this._priceInfo = priceInfo;
  }

  // возможно методы ниже больше не нужны т.к. расчет значение для отображение тем происходит сразу в методах запроса
  // для корректного отображение цен удолять и заккоментировать не стал

  public recalculateData() {
    this.lenAfterPoint = this.Price.length - this.Price.indexOf('.') - 1;
    this.lenToPoint = this.Price.indexOf('.');
    this.shouldUseWithPoint = this.lenAfterPoint > this.Symbol.PipDecimalPlaces;

    if (this.shouldUseWithPoint) {
      this.calculateForUsageWithPoints();
    } else {
      this.calculateForUsageWithoutPoints();
    }
  }

  private calculateForUsageWithPoints() {
    this.basisLen = this.lenToPoint + this.Symbol.DecimalPlaces - 2;
    this.pipsLen = 2;
    this.pointsLen = 1;
  }
  private calculateForUsageWithoutPoints() {
    this.basisLen = this.lenToPoint + this.Symbol.DecimalPlaces - 1;
    this.pipsLen = 2;
    this.pointsLen = 0;
  }
}
