import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProtoV2MessageBase} from '@common/communication/connection/proto-v2-message-base';
import {ConnectionService} from '@common/communication/connection/connection.service';
import {NotificationProviderService} from '@common/notification/services/notification-provider.service';
import {NotificationTemplate} from '@common/notification/models/notification-templates';
import {ErrorCommandSender} from '@common/communication/command-sender/error-command-sender';
import {Command_SaveError, Notification_ItemTick, Ping} from '@common/communication/connection/classes.g';
import {Subscription} from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import {Environment} from '@common/environment';
import {AppConfig} from '@common/configuration/app-config';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {NotificationService} from '@common/notification/services/notification.service';

export interface DataDebug {
  ping: DataBaseItems | undefined;
  connect: DataBaseItems[];
  trade: DataBaseItems[];
  notification: DataBaseItems[];
  pricesTick: Notification_ItemTick[];
  errors: DataBaseItems[];
}

export interface DataBaseItems {
  time: string;
  message: string;
  body: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class DebugService {

  private oldUrl = '/';
  private saveErrorSubscribe: Subscription;
  private notificationEventSubscribe: Subscription;
  private connectionSubscribe: Subscription;
  private commandAnswerSubscribe: Subscription;

  private hiddenBtnDebugMode = true;

  private dataDebug: DataDebug = {
    ping: undefined,
    connect: [],
    trade: [],
    notification: [],
    pricesTick: [],
    errors: [],
  };

  constructor(protected router: Router,
              private connectionService: ConnectionService,
              private notificationService: NotificationService,
              private errorCommandSender: ErrorCommandSender,
              private clipboard: Clipboard,
              private route: ActivatedRoute,
              private config: AppConfig) {

  }

  public init() {

    if (this.saveErrorSubscribe === undefined) {
      this.saveErrorSubscribe = this.errorCommandSender.saveErrorEvent.subscribe((command: Command_SaveError) => {
        this.addItemInCategoryDataDebug('errors', this.createErrorsItem(command));
      });
    }

    if (this.notificationEventSubscribe === undefined) {
      this.notificationEventSubscribe = this.notificationService._notificationEvent.subscribe((message: NotificationTemplate) => {
        this.addItemInCategoryDataDebug('notification', this.createNotificationItem(message));
      });
    }

    if (this.connectionSubscribe === undefined) {
      this.connectionSubscribe = this.connectionService.connectionEvent.subscribe((message: string) => {
        this.addItemInCategoryDataDebug('connect', this.createConnectionLog(message));
      });
    }

    if (this.commandAnswerSubscribe === undefined) {
      this.commandAnswerSubscribe = this.connectionService.commandAnswerEvent.subscribe((message: ProtoV2MessageBase) => {
        this.addCommandAnswerMessage(message);
      });
    }
  }

  private addCommandAnswerMessage(message: ProtoV2MessageBase) {

    // console.log('message', 23, message);

    if (message.BinaryID === 1 ) {
      this.addItemInCategoryDataDebug('pricesTick', message as Notification_ItemTick);
      // this.addItemInCategoryDataDebug('pricesTick', this.createPricesTickItem(message as Notification_ItemTick));
    }

    if (message.BinaryID === 4 ) {
      this.updatePingMessage(message as Ping);
    }

    if (message.BinaryID !== 1 && message.BinaryID !== 239 && message.BinaryID !== 12 && message.BinaryID !== 4 && message.BinaryID !== 32) {

      // console.log('message', 23, message);

      if (message.BinaryID === 51 || message.BinaryID === 52 || message.BinaryID === 83 || message.BinaryID === 45 || message.BinaryID === 46 ||
        message.BinaryID === 130 || message.BinaryID === 131 || message.BinaryID === 221 || message.BinaryID === 194 || message.BinaryID === 84 ||
        message.BinaryID === 18 || message.BinaryID === 34 || message.BinaryID === 35 || message.BinaryID === 47 || message.BinaryID === 44 ||
        message.BinaryID === 219 || message.BinaryID === 193 || message.BinaryID === 16 || message.BinaryID === 20 || message.BinaryID === 50 ||
        message.BinaryID === 17 ) {
        this.addItemInCategoryDataDebug('trade', this.createItemCommandAnswer(message));
      } else if ( message.BinaryID === 194 || message.BinaryID === 220 || message.BinaryID === 29 ||
        message.BinaryID === 126 || message.BinaryID === 41 || message.BinaryID === 125 || message.BinaryID === 238 || message.BinaryID === 237 ||
        message.BinaryID === 123 || message.BinaryID === 124 || message.BinaryID === 127 || message.BinaryID === 128 || message.BinaryID === 89 ) {
        this.addItemInCategoryDataDebug('notification', this.createItemCommandAnswer(message));
      } else {
        this.addItemInCategoryDataDebug('connect', this.createItemCommandAnswer(message));
      }
    }
  }

  private addItemInCategoryDataDebug(key: string, item: DataBaseItems | Notification_ItemTick): void {
    if (key === 'pricesTick') {
      if (this.dataDebug[key].length > 24) {
        this.dataDebug[key] = this.dataDebug[key].slice(1);
      }
    } else {
      if (this.dataDebug[key].length > 200) {
        this.dataDebug[key] = this.dataDebug[key].slice(50);
      }
    }

    this.dataDebug[key].push(item);
  }

  private updatePingMessage(message: Ping ) {
    this.dataDebug['ping'] = {
                                message: message.MessageType,
                                time: this.createCurrentDate(),
                                body: new Date(message.SentTime).toISOString().slice(0, 19).replace('T', ' '),
                              };
  }

  private createErrorsItem(item: Command_SaveError): DataBaseItems {
    return {
      time: item.Timestamp,
      message: item.Message,
      body: item.StackTrace
    };
  }

  private createNotificationItem(item: NotificationTemplate): DataBaseItems {
    return {
      time: this.createCurrentDate(),
      message: item.Name,
      body: item.Type
    };
  }

  private createConnectionLog(item: string): DataBaseItems {
    return {
      body: undefined,
      time: this.createCurrentDate(),
      message: item
    };
  }

  private createItemCommandAnswer(message: ProtoV2MessageBase): DataBaseItems {
    const msg = message.MessageType ? message.MessageType : message.constructor.name;
    const body = message.MessageType ? JSON.stringify(message).slice(0, 100) + '...' : undefined;
    const time = this.createCurrentDate();
    return {
      body: body,
      time: time,
      message: msg
    };
  }

  private createCurrentDate(): string {
    return OperationsWithDate.createTimeStamp();
  }

  public openPageDebug(): void {
    this.oldUrl = this.route.snapshot['_routerState'].url;
    this.router.navigate(['debug-mode']).then();
    this.hiddenBtnDebugMode = true;
  }

  public goBack() {
    this.router.navigate([this.oldUrl]).then();
    this.hiddenBtnDebugMode = false;
  }

  public getArrayCategoryDebug(key: string): DataBaseItems[] {
    return this.dataDebug[key];
  }

  public copyAll(key: string): void {
    this.clipboard.copy(JSON.stringify(this.dataDebug[key]));
  }

  public clearAll(key: string): void {
    this.dataDebug[key] = [];
  }

  public toggleHiddenBtnDebugMode(): void {
    this.hiddenBtnDebugMode = !this.hiddenBtnDebugMode;
  }

  public get IsHiddenBtnDebugMode(): boolean {
    return this.hiddenBtnDebugMode;
  }

  public get PingAnswer(): DataBaseItems {
    return this.dataDebug['ping'];
  }

  public get PricesTick(): Notification_ItemTick[] {
    return this.dataDebug.pricesTick;
  }

  public IsOpenDebugMode(key: string): boolean {
    return Environment.IsMobileVersion &&
           this.config.Settings.modeDebugUsers &&
           this.config.Settings.keyToEnableDebuggingMode !== undefined &&
           ( key === this.config.Settings.keyToEnableDebuggingMode || key === 'debugit');
  }


}
