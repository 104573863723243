import {Component} from '@angular/core';
import {ApplicationService} from '@common/application.service';
import {Environment} from '@common/environment';
import {Trader} from '@common/trader/models/trader';
import {ConnectionService} from 'ng-connection-service';
import {BrowserPageService} from '@common/trade/services/browser-page.service';
import {LocationPositionService} from '@common/trade/services/location-position.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'desktop';

  private isConnectedInternet = true;
  private _noInternetConnection = false;
  private _isConnectedInternet = true;

  private _isConnected = false;

  public get IsTesting(): boolean {
    return Environment.IsTesting;
  }

  public get IsConnected(): boolean {
    return this._isConnected;
  }

  public get noInternetConnection(): boolean {
    return this._noInternetConnection;
  }

  public get IsConnectedInternet() {
    return this._isConnectedInternet;
  }

  public constructor(private app: ApplicationService,
                     private trader: Trader,
                     private connectionService: ConnectionService,
                     private guidPageService: BrowserPageService,
                     private locationPositionService: LocationPositionService) {

    this.trader.ConnectionChangedEmitter.subscribe(val => {
      this._isConnected = val;
    });

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnectedInternet = isConnected.hasNetworkConnection;
      if (this.isConnectedInternet) {
        this._noInternetConnection = false;
        this._isConnected = true;
        setTimeout(() => this._isConnectedInternet = true, 3000);
      } else {
        this._noInternetConnection = true;
        this._isConnectedInternet = false;
      }
    });

    this.guidPageService.createItem();
    this.locationPositionService.init();
  }

}
