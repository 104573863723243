import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {Environment} from '@common/environment';
import {SymbolInfoService} from '@common/shared/services/symbol-info.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {PriceDirection} from '@common/symbol/models/price-direction';

@Component({
  selector: 'app-symbol-header-section',
  templateUrl: './symbol-header-section.component.html',
  styleUrls: ['./symbol-header-section.component.scss']
})


export class SymbolHeaderSectionComponent implements OnInit {

  @Input() hiddenClose: boolean;
  @Input() availableSymbols: Symbol[];
  @Input() currentSymbol: Symbol;
  @Input() currentPage: string;
  @Input() priceDirection: PriceDirection;

  @Output() changeCurrentSymbolName: EventEmitter<string> = new EventEmitter<string>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChartEvent: EventEmitter<void> = new EventEmitter<void>();

  private _textIcon: string;

  private _showSelectSymbols = false;


  constructor(private symbolSelector: SymbolSelectService, private symbolInfoService: SymbolInfoService, private symbolStorage: SymbolStorageService) {
  }

  ngOnInit() {
    this._textIcon = this.Symbol.SymbolName['0'].toUpperCase();
    this.checkSymbolIconPath();
  }

  public get AvailableSymbols(): Symbol[] {
    return this.availableSymbols;
  }

  close($event: MouseEvent) {
    $event.stopPropagation();
    this.onClose.emit();
  }

  openChart($event: MouseEvent) {
    $event.stopPropagation();
    this.openChartEvent.emit();
  }

  public toggleSelect(): void {
    this._showSelectSymbols = true;
  }

  public async setCurrentSymbolName(value: string) {
    this._showSelectSymbols = false;
    this.changeCurrentSymbolName.emit(value);
    await this.changeSymbol(value);
    this.checkSymbolIconPath();
    this._textIcon = this.Symbol.SymbolName['0'].toUpperCase();
  }

  private async changeSymbol(symbolName: string): Promise<void> {
    this.currentSymbol = await this.symbolStorage.findSymbolByName(symbolName);

  }

  private checkSymbolIconPath() {
    if (this.Symbol.getSymbolIconPath() === undefined) {
      this.Symbol.setSymbolIconPath(this.symbolInfoService.getSymbolIcon(this.Symbol));
    }
  }

  public get ShowSelectSymbols(): boolean {
    return this._showSelectSymbols;
  }

  get TextIcon(): string {
    return this._textIcon;
  }

  public get SymbolId(): number {
    return this.Symbol.SymbolId;
  }

  public get SymbolIconPath(): string {
    return this.Symbol.getSymbolIconPath();
  }

  public get IsFavorite(): boolean {
    return this.symbolSelector.isFavorite(this.Symbol.SymbolId);
  }

  public async toggleFavorite(event: MouseEvent) {
    event.stopPropagation();
    await this.symbolSelector.toggleFavorites(this.Symbol.SymbolId);
  }

  public get CurrentPage(): boolean {
    return this.currentPage === 'Full-Market';
  }

  get Symbol(): Symbol {
    return this.currentSymbol;
  }

  public getPercentChangeFormatted(symbol: Symbol): string {
    return `${Math.abs(Number(OperationsWithVolume.setFormatPercent(symbol.PercentChange)))}%`;
  }

  public get PriceClass(): any {
    if (!this.Symbol) {
      return {};
    }

    let positive = this.Symbol.LastQuoteInfo.Ask.IsUp;
    let negative = this.Symbol.LastQuoteInfo.Ask.IsDown;
    let neutral = !this.Symbol.LastQuoteInfo.Ask.IsDown && !this.Symbol.LastQuoteInfo.Ask.IsUp;


    if (this.priceDirection === PriceDirection.Bid) {
      positive = this.Symbol.LastQuoteInfo.Bid.IsUp;
      negative = this.Symbol.LastQuoteInfo.Bid.IsDown;
      neutral = !this.Symbol.LastQuoteInfo.Bid.IsDown && !this.Symbol.LastQuoteInfo.Bid.IsUp;
    }

    return {
      'z-positive': positive,
      'z-negative': negative,
      'z-neutral': neutral
    };
  }

  public get Price(): number {
    if (this.priceDirection === PriceDirection.Bid) {
      return this.Symbol.Bid;
    } else {
      return this.Symbol.Ask;
    }
  }

  get IsMobileVersion(): boolean {
    return Environment.IsMobileVersion;
  }

}
