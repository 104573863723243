
import {IProfitCalcFactory} from "@common/shared/calculators/Profit/IProfitCalcFactory.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";
import {Direct} from "@common/shared/calculators/Profit/Direct.g";
import {Indirect} from "@common/shared/calculators/Profit/Indirect.g";
import {USDCC_USDBC} from "@common/shared/calculators/Profit/USDCC_USDBC.g";
import {BCUSD_CCUSD} from "@common/shared/calculators/Profit/BCUSD_CCUSD.g";
import {BCUSD_USDCC} from "@common/shared/calculators/Profit/BCUSD_USDCC.g";
import {CFD_CCUSD} from "@common/shared/calculators/Profit/CFD_CCUSD.g";
import {CFD_CCUSD_index} from "@common/shared/calculators/Profit/CFD_CCUSD_index.g";
import {CFD_USDCC} from "@common/shared/calculators/Profit/CFD_USDCC.g";
import {CFD_USDCC_index} from "@common/shared/calculators/Profit/CFD_USDCC_index.g";
import {Zero} from "@common/shared/calculators/Profit/Zero.g";
import {Indirect_index} from "@common/shared/calculators/Profit/Indirect_index.g";

export class Factory implements IProfitCalcFactory {
	createCalculator(type: ProfitCalcType, symbol: IProfitCalcSymbol): IProfitCalc {
		switch (type) {
			case ProfitCalcType.Direct:
				return new Direct(symbol);
			case ProfitCalcType.Indirect:
				return new Indirect(symbol);
			case ProfitCalcType.USDCC_USDBC:
				return new USDCC_USDBC(symbol);
			case ProfitCalcType.BCUSD_CCUSD:
				return new BCUSD_CCUSD(symbol);
			case ProfitCalcType.BCUSD_USDCC:
				return new BCUSD_USDCC(symbol);
			case ProfitCalcType.CFD_CCUSD:
				return new CFD_CCUSD(symbol);
			case ProfitCalcType.Indirect_index:
				return new Indirect_index(symbol);
			case ProfitCalcType.CFD_CCUSD_index:
				return new CFD_CCUSD_index(symbol);
			case ProfitCalcType.CFD_USDCC:
				return new CFD_USDCC(symbol);
			case ProfitCalcType.CFD_USDCC_index:
				return new CFD_USDCC_index(symbol);
			default:
				return new Zero();
		}
	}
}
                