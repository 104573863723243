import {ProtocolMediatorBase} from './protocol-mediator-base';
import {IProtocol} from './protocol-interface';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Ping} from '../classes.g';
import {Repeater} from '@common/common/utils/repeater';

export class ProtocolPing extends ProtocolMediatorBase
{
  private repeater: Repeater;
  private timeToDisconnect = 60;
  private checkDisconnectInterval = 5;
  private idlePeriod = 0;

  public constructor(subProtocol: IProtocol)
  {
    super(subProtocol);
  }

  private checkLostPings() {
    if (this.idlePeriod === this.timeToDisconnect) {
      this.disconnect('disconnect on no pings or other calls');
      this.connect();
    } else {
      this.idlePeriod += this.checkDisconnectInterval;
    }
  }

  onDisconnected(msg: string) {
    if (this.repeater) {
      this.repeater.stop();
    }
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onDisconnected(msg);
    }
  }

  onConnectionEstablished() {
    this.idlePeriod = 0;
    this.repeater = new Repeater(() => this.checkLostPings(), this.checkDisconnectInterval * 1000);
    this.repeater.start();
    const eh = this.getEventHandler();
    if (eh != null) {
      eh.onConnectionEstablished();
    }
  }

  onMessageReceived(message: ProtoV2MessageBase) {
    this.idlePeriod = 0;
    if (message.BinaryID == Ping.GetBinaryID()) {
      this.send(message);
      return;
    }
    super.onMessageReceived(message);
  }
}
