export enum LocaleEnum {
  English = 'en-us',
  Russian = 'ru-ru',
  Chinese = 'zh-cn',
  Italian = 'it-it',
  Czech = 'cz-cz',
  CzechEvertrader = 'cz-cz-evertrader',
  Slovak = 'sk-sk',
  Thai = 'th-th',
  Vietnamese = 'vi-vi',
  Portuguese = 'pt-pt',
  Spanish = 'es-es',
  Swahili = 'swa-swa'
}

export interface Locale {
  code: LocaleEnum;
  name: string;
}
