import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Directive({
  selector: '[onClickOutside]'
})
export class OnClickOutsideDirective implements OnInit {
  public _onClickOutside = new EventEmitter<void>();

  @Output('onClickOutside')
  public get onClickOutside(): Observable<void> {
    return this._onClickOutside.asObservable();
  }

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:mousedown', ['$event'])
  private onDown(event: MouseEvent) {
    if(!this.elementRef.nativeElement.contains(event.target)){
      this.emitClose();
    }
  }
  private emitClose() {
    this._onClickOutside.emit()
  }

  ngOnInit(): void {
    const header = document.querySelector('lib-modal-movable-placeholder .app-modal-header');

    if(header){
      header.addEventListener('click', () => this.emitClose());
    }
  }
}
