

export class OperationsWithDate {

  public static getDateUTC(): Date {
    const localTimeZone = new Date().getTimezoneOffset() * 60 * 1000;
    return new Date(Date.now() + localTimeZone);
  }

  public static getCorrectTime(time: string): string {
    let res = '';
    const firstSplit = time.split('-');
    const secondSplit = firstSplit[2].split('T');
    res += firstSplit[0] + '-' + this.getCorrectMonth(firstSplit[1]) + '-' + secondSplit[0]  + ' ' + secondSplit[1];

    return res;
  }

  public static getCorrectMonth(month: string): string {
    switch (month) {
      case '01':
        return 'jan';
      case '02':
        return 'feb';
      case '03':
        return 'mar';
      case '04':
        return 'arp';
      case '05':
        return 'may';
      case '06':
        return 'jun';
      case '07':
        return 'jul';
      case '08':
        return 'aug';
      case '09':
        return 'sep';
      case '10':
        return 'oct';
      case '11':
        return 'nov';
      case '12':
        return 'dec';
    }
  }

  public static createTimeStamp(): string {
    return (new Date()).toISOString().slice(0, 19).replace('T', ' ');
  }

  public static initTimestamp(text: string, isSaveTimestamp: boolean = false): void {
    const time = new Date();
    if (isSaveTimestamp) {
      this.saveTimeStamp(time);
    }
    console.log(text, '-', time.getHours(), ':', time.getMinutes(), ':', time.getSeconds());
  }

  private static saveTimeStamp(time: Date): void {
    sessionStorage.setItem('timeStamp', JSON.stringify(time));
  }

  public static getItTookSecondsToConnect(): void {
    const timestamp = JSON.parse(sessionStorage.getItem('timeStamp'));
    if (timestamp !== null) {
      const start = new Date(timestamp);
      const now = new Date();
      const total: number = ( Number(now) - Number(start) ) / 1000;
      console.log('It took seconds to connect', total);
      sessionStorage.removeItem('timeStamp');
    } else {
      console.log('Timestamp is not save');
    }
  }

}
