<span class="menu-text" theme>
  <l-unit [key]="'UserInterface_SavedViewButtonComponent_saved-template'"></l-unit>
</span>
<div class="dropdown">
  <ul class="saved-view-dropdown saved-view-menu-border">
    <li class="title">
      <l-unit [key]="'UserInterface_SavedViewButtonComponent_saved-template-is-open'"></l-unit>
    </li>

    <li class="menu-item" *ngFor="let key of LayoutKeys">
      <span class="name-saved-view" [textContent]="checkLengthKey(key)" (click)="loadLayout(key)"
            [attr.title]="'Apply selected template'"></span>
        <div class="buttons-block">
          <button class="saved-view-type-button-delite delete-view-button"
                  [disabled]="getTraderOrGuest()"
                  [class.disabled-button]="getTraderOrGuest()"
                  [attr.title]="'Remove selected template'"
                  (click)="deleteView(key)">
          </button>
          <button class="saved-view-type-button open-in-new-tab-button"
                  [attr.title]="'Open selected template in a new browser window'"
                  (click)="openInNewTab(key)">
          </button>
          <button class="saved-view-type-button resave-button"
                  [disabled]="getTraderOrGuest()"
                  [class.disabled-button]="getTraderOrGuest()"
                  [attr.title]="'Save current template'"
                  (click)="reSave(key)">
          </button>
          <div class="saved-view-type-button">
            <input type="checkbox" class="custom-checkbox"
                   [attr.id]="key" [checked]="checked(key)" (change)="changeDefaultViews($event, key)"/>
            <label [attr.for]="key" title="Mark as favorite"></label>
          </div>
        </div>
    </li>

    <div class="message-box">
      <l-unit class="green" [hidden]="notificationSaveTemplate" [key]="'Modal_SaveView_Template-Saved'"></l-unit>
    </div>

    <li class="down-buttons">

      <div class="open-all-in-new-tab-border" [hidden]="openAllHide()">
      <span class="open-in-new-tab-button open-all-views-button"
            [attr.title]="'Open all of your saved template, each in a separate window'"
            (click)="openAllViewsInNewTab()">
      </span>
      </div>

      <div class="save-button" >
        <button class="save-text button-save"
                (click)="openSaveView($event)"
                [class.disabled-button-color]="getTraderOrGuest()"
                [disabled]="getTraderOrGuest()"
                [textContent]="getSaveButtonText()">
        </button>
      </div>
    </li>

  </ul>
</div>

