import {Directive, Input} from '@angular/core';
import {ChangeableValidator} from '@common/shared/directives/validators/changeable-validator';
import {Symbol} from '@common/symbol/models/symbol';
import {isPendingTrade, isSellTrade, TradeType} from '@common/trade/models/trade-type';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';

@Directive({
  selector: '[stop-loss]'
})
export class StopLossValidatorDirective extends ChangeableValidator {

  @Input()
  symbol: Symbol;

  @Input()
  type: TradeType;

  @Input()
  currentPrice: number;

  constructor() {
    super()
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = Number(control.value);

    const limit = !isPendingTrade(this.type) ? TradePriceProvider.getTradeCurrentPrice(this.symbol, this.type) : this.currentPrice;

    if(isSellTrade(this.type)){
      return value > limit ?
        null :
        {
          minValue: {limit: limit, actual: value}
        }
    }
    else {
      return value < limit ?
        null :
        {
          minValue: {limit: limit, actual: value}
        }
    }
  }
}
