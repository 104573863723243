import {Component, Input, OnInit} from '@angular/core';
import {CustomNumber} from '@common/trade/utils/custom-number';

@Component({
  selector: '[app-spread]',
  templateUrl: './spread.component.html',
  styleUrls: ['./spread.component.scss']
})
/**
 * Component for showing spread value at quotes table
 * Should use with {@link SymbolPriceComponent | symbol-price component}
 */
export class SpreadComponent implements OnInit {

  @Input()
  public value: number;

  @Input()
  public newVariantShow: boolean;

  get Value(): string {
    let temp = CustomNumber.roundForPlace(this.value, 1);

    if(temp == -0){
      temp = 0;
    }

    return temp.toFixed(1);
  }

  public get PriceIsCorrect(): boolean {
    return !isNaN(this.value) && isFinite(this.value);
  }

  constructor() { }

  ngOnInit() {
  }

  get VariantShow(): boolean {
    return this.newVariantShow;
  }

}
