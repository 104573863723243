export enum StateType {
  OpenPendingOrder = 10,
  DeletePendingOrder = 11,
  UpdatePendingOrder = 12,
  ResumePendingOrder = 13,
  SetStopLoss = 14,
  SetTakeProfit = 15,
  Opened = 16,
  PartialClosed = 16.5,
  Closed = 17,
  Storage = 18,
  // ...
  SetTrailingStop = 20,
  MarketProviderConfirm
}

export const StateTypeStrings = {
  10: 'TradeModule_StateTypeStrings_OpenPendingOrder',
  11: 'TradeModule_StateTypeStrings_DeletePendingOrder',
  12: 'TradeModule_StateTypeStrings_UpdatePendingOrder',
  13: 'TradeModule_StateTypeStrings_ResumePendingOrder',
  14: 'TradeModule_StateTypeStrings_SetStopLoss',
  15: 'TradeModule_StateTypeStrings_SetTakeProfit',
  16: 'TradeModule_StateTypeStrings_MarketOrderOpened',
  16.5: 'TradeModule_StateTypeStrings_MarketOrderPartialClosed',
  17: 'TradeModule_StateTypeStrings_MarketOrderClosed',
  18: 'TradeModule_StateTypeStrings_Rollover',
  20: 'TradeModule_StateTypeStrings_SetTrailingStop',
};
