import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TradingViewNotificationService {
  private hidden = true;
  private symbol: string;

  constructor() {

  }

  public showNotification(): void {
    this.hidden = false;
    setTimeout(() => {
      this.hidden = true;
    }, 3000);
  }

  public get hiddenValue(): boolean {
    return this.hidden;
  }

  public set symbolName(symbol: string) {
    this.symbol = symbol;
  }

  public get textNotification(): string {
    return 'Invalid Symbol: ' + this.symbol;
  }
}
