import {ProtocolMediatorBase} from './protocol-mediator-base';
import {IProtocol} from './protocol-interface';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {
  Answer_CustomSessionError,
  Answer_RefreshToken,
  Answer_RefreshToken2,
  Command_RefreshToken,
  Command_RefreshToken2
} from '../classes.g';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Session} from '@common/auth/models/session';
import {DateUtils} from '@common/common/utils/date-utils';
import {Repeater} from '@common/common/utils/repeater';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';

export class ProtocolRefreshToken extends ProtocolMediatorBase {
  private refresher: Repeater;
  private localStorage: LocalStorageService;
  private _refreshInterval = 30 * 60 * 1000; // 10 * 1000; //30 * 60 * 1000; // 30 minutes

  private traderId: number;
  private refreshToken2 = false;

  public constructor(subProtocol: IProtocol, usersInSystemsService: UsersInSystemsService) {
    super(subProtocol);
    // Be careful, u should use only sessions methods
    this.localStorage = new LocalStorageService(usersInSystemsService);
  }

  onMessageReceived(message: ProtoV2MessageBase) {
    /*if (message.MessageType === Command_RefreshToken.GetMessageType()) {
      this.saveNewSession(message);
      return;
    } else */

    if (message.BinaryID === Answer_RefreshToken.GetBinaryID()) {
      console.log('received answer refresh token : ', message);
      console.log('updating access token');
      this.updateToken(message as Answer_RefreshToken);
      return;
    }

    if (message.BinaryID === Answer_RefreshToken2.GetBinaryID()) {
      console.log('received answer refresh token 2: ', message);
      console.log('updating access token');
      this.updateToken(message as Answer_RefreshToken2);
      return;
    }

    if (message.BinaryID === Answer_CustomSessionError.GetBinaryID()) {
      if ((message instanceof Answer_CustomSessionError) && (message.Error.Code === 5 || message.Error.Code === 6) ) {

        console.log('SessionError: ', message);

        if (this.traderId === undefined) {
          this.traderId = this.localStorage.getSession().TraderId;
        }

        if (this.refreshToken2) {
          this.onDisconnected('RefreshToken2 failed');
          // this.localStorage.removeSession();
        }
        this.sendRefresh2();
      }
    }

    super.onMessageReceived(message);
  }

  private updateToken(message: Answer_RefreshToken | Answer_RefreshToken2) {

    const session = new Session(
      message.NewAccessToken,
      DateUtils.addMinutes(new Date(), 15),
        15,
        this.traderId,
        message.LoginName
      );

    this.localStorage.saveSession(session);

    console.log('message', message);
    console.log('session', session);

    this._logger.info('Token Refreshed');
  }

  send(message: ProtoV2MessageBase) {
    super.send(message);
  }

  private setRefreshTimer() {
    this._logger.debug('Set refresh timer');
    this.refresher = new Repeater( () => {
      this.sendRefresh();
      // this.sendRefresh2();
    }, this._refreshInterval).start();
  }
  private killRefreshTimer() {
    this._logger.debug('Kill refresh timer');
    if (this.refresher && this.refresher.IsActive) {
      this.refresher.stop();
    }
  }

  private isNullOrEmpty(str?: string): boolean {
    return str === null || str === undefined || str === '' || str === ' ';
  }

  private sendRefresh() {
    const command = new Command_RefreshToken();

    const session = this.localStorage.getSession();

/*    console.log('!!! SESSION: !!!', session);
    if (session === null) {
      console.log('!!! SESSION IS NULL !!!');
    }*/


    if (session !== null &&
      !this.isNullOrEmpty(session.AccessToken) &&
      session.TraderId !== 0) {
      this.traderId = session.TraderId;

      command.AccessToken = session.AccessToken;
      command.LoginName = session.Login;
      command.TraderId = session.TraderId;

      this.send(command);
      this._logger.info('Send refresh');
    } else {
      this._logger.error('No Session Found!');
      this.localStorage.removeSession();
    }
  }

  private sendRefresh2() {
    this.refreshToken2 = true;
    const cmd = new Command_RefreshToken2();
    const session = this.localStorage.getSession();

    if (this.isNullOrEmpty(session.Login)) {
      console.log('WARINING: session.Login is empty!');
    }

    if (session !== null) {

      cmd.AccessToken = session.AccessToken;
      cmd.LoginName = session.Login;

      console.log('sending refresh 2 for session:', session);
      this.send(cmd);
    } else {
      console.log('session is null!');
    }
  }

  onConnectionEstablished() {
    super.onConnectionEstablished();
    this.setRefreshTimer();
  }

  onConnectionEstablishedError(msg) {
    super.onConnectionEstablishedError(msg);
    this._logger.debug(`ConnectionEstablishedError: ${msg}`);
    this.killRefreshTimer();
  }

  onDisconnected(msg: string) {
    super.onDisconnected(msg);
    this._logger.debug(`Disconnected: ${msg}`);
    this.killRefreshTimer();
  }
}
