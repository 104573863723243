<div *ngIf="!VariantShow">
  <span class="title">S<span class="full-title">pread</span>: </span>
  <span class="price-block" *ngIf="PriceIsCorrect">
    <span class="spread price">{{Value}}</span>
  </span>
  <span class="price" *ngIf="!PriceIsCorrect">
    <div class="unsupported-price">
      <span></span>
    </div>
  </span>
</div>
<div *ngIf="VariantShow">
  <span class="spread price">{{Value}}</span>
</div>

