import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Symbol} from '@common/symbol/models/symbol';
import {isBuyTrade, isPendingTrade, TradeType} from '@common/trade/models/trade-type';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {ChangeableValidator} from '@common/shared/directives/validators/changeable-validator';

@Directive({
  selector: '[take-profit]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: TakeProfitValidatorDirective,
    multi: true
  }]
})
export class TakeProfitValidatorDirective extends ChangeableValidator {
  @Input()
  symbol: Symbol;

  @Input()
  type: TradeType;

  @Input()
  currentPrice: number;

  constructor() {
    super();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = Number(control.value);

    const limit = !isPendingTrade(this.type) ? TradePriceProvider.getTradeCurrentPrice(this.symbol, this.type) : this.currentPrice;

    if(isBuyTrade(this.type)){
      return value > limit ?
        null :
        {
          minValue: {limit: limit, actual: value}
        }
    }
    else {
      return value < limit ?
        null :
        {
          minValue: {limit: limit, actual: value}
        }
    }
  }
}
