export enum ProfitCalcType {
  Zero = 0,
  Direct = 1,
  Indirect = 2,
  USDCC_USDBC = 3,
  BCUSD_CCUSD = 4,
  BCUSD_USDCC = 5,
  CFD_CCUSD = 6,
  CFD_USDCC = 7,
  Indirect_index = 8,
  CFD_CCUSD_index = 9,
  CFD_USDCC_index = 10
}
