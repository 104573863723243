import {StopType} from './stop-type';
import {StopCalculator, StopCalculatorsFactory} from './algorithms/calculator/stop.calculator';
import {TradeDTO} from '../../../models/trade-d-t-o';
import {Accounting} from '@common/trader/models/accounting.service';

export class StopConverter {
  private calculator: StopCalculator;

  public constructor(trade: TradeDTO, type: StopType, accounting: Accounting){
    this.calculator = StopCalculatorsFactory.getCalculator(trade, type, accounting);
  }

  public getPips(value: number): number {
    value = Number(value);
    return this.calculator.calculateToPips(value);
  }

  public getPrice(value: number): number {
    value = Number(value);
    return Math.abs(this.calculator.calculateToPrice(value));
  }

  public getProfit(value: number): number {
    value = Number(value);
    return this.calculator.calculateToProfit(value);
  }

  public getPercent(value: number): number {
    value = Number(value);
    return this.calculator.calculateToPercent(value);
  }

  public convertPips(pips: number): number {
    pips = Number(pips);
    return this.calculator.convertPips(pips);
  }

  public convertProfit(profit: number): number {
    profit = Number(profit);
    return this.calculator.convertProfit(profit);
  }

  public convertPrice(closePrice: number): number {
    closePrice = Number(closePrice);
    return this.calculator.convertPrice(closePrice);
  }

  public convertPercent(percent: number): number {
    percent = Number(percent);
    return this.calculator.convertPercent(percent);
  }
}
