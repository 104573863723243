import {Trade} from './trade';
import {TradeSet} from './trade-set';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {OpenTradeSet} from './open-trade-set';
import {TradeStateSnapshot} from './trade-state-snapshot';
import {EventEmitter, Injectable} from '@angular/core';
import {JsonProperty} from '@common/shared/utils/json-decorators';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {AppConfig} from '@common/configuration/app-config';
import {NettedTradeMap} from '@common/trade/models/netted-trade-map';

@Injectable({
  providedIn: 'root'
})
export class TradeStorage {

  protected _logger = LoggerFactory.getLogger('TradeStorage');

  public changeTradesArray: EventEmitter<void> = new EventEmitter<void>();
  public symbolNotActive: EventEmitter<void> = new EventEmitter<void>();
  public closeOrderEvent: EventEmitter<number> = new EventEmitter<number>();
  public updateTradeEvent: EventEmitter<Trade> = new EventEmitter<Trade>();

  get OpenedTrades(): Trade[] {
    return this._openTrades.Trades;
  }

  get ClosedTrades(): Trade[] {
    return this._closedTrades.Trades;
  }

  public getRunningPendingTrades(): Trade[] {
    const result = [];
    this._openTrades.Trades.forEach(value => {
      if (value.IsPending) {
        result.push(value);
      }
    });
    return result;
  }

  public onTradeOpened(callback: (trade: Trade) => void): ISmartSubscription {
    return this._onTradeOpenedEventEmitter.subscribe(callback);
  }

  public onTradeClosed(callback: (trade: Trade) => void): ISmartSubscription {
    return this._onTradeClosedEventEmitter.subscribe(callback);
  }

  public onTradeChanged(callback: (trade: Trade) => void): ISmartSubscription {
    return this._onTradeChangedEventEmitter.subscribe(callback);
  }

  public onTradeChangedWithTradeSent(callback: (trade: Trade) => void): ISmartSubscription {
    return this._onTradeChangedWithTradeEventEmitter.subscribe(callback);
  }

  @JsonProperty(TradeSet)
  private _allTrades: TradeSet;

  @JsonProperty(OpenTradeSet)
  private _openTrades: TradeSet;

  @JsonProperty(TradeSet)
  private _closedTrades: TradeSet;

  private _onTradeOpenedEventEmitter: SmartEmitter<Trade>;
  private _onTradeClosedEventEmitter: SmartEmitter<Trade>;
  private _onTradeUpdatedEventEmitter: SmartEmitter<Trade>;
  private _onTradeChangedEventEmitter: SmartEmitter<Trade>;
  private _onTradeChangedWithTradeEventEmitter: SmartEmitter<Trade>;

  private _nettedTrades: NettedTradeMap  = new NettedTradeMap();

  get nettedTrades() {
    return this._nettedTrades.getAsArray();
  }

  public constructor(private appConfig: AppConfig) {
    this._allTrades = new TradeSet();
    this._openTrades = new OpenTradeSet();
    this._closedTrades = new TradeSet();

    this._onTradeOpenedEventEmitter = new SmartEmitter<Trade>();
    this._onTradeClosedEventEmitter = new SmartEmitter<Trade>();
    this._onTradeUpdatedEventEmitter = new SmartEmitter<Trade>();
    this._onTradeChangedEventEmitter = new SmartEmitter<Trade>();
    this._onTradeChangedWithTradeEventEmitter = new SmartEmitter<Trade>();
  }

  private isSubsribeSymbolInStorage() {
    if (this.appConfig.Settings.cfaMigration !== undefined
      && this.appConfig.Settings.cfaMigration.SymbolSubscribeInStorage !== undefined) {
      return this.appConfig.Settings.cfaMigration.SymbolSubscribeInStorage;
    }
    return false;
  }

  public clearTrades() {
    this._allTrades.clear();
    this._openTrades.clear();
    this._closedTrades.clear();
    this._nettedTrades.clear();
  }

  public async update(trades: Trade[]) {
    this._logger.debug('Update started');

    await this.updateOpened(trades);
    await this.updateClosed(trades);
    await this.updateAll(trades);

    this._logger.debug('Update finished');
  }

  public async onTradesChanged(states: TradeStateSnapshot[]) {
    for (const state of states) {
      await this.tradeChanged(state);
    }
  }

  private async tradeChanged(state: TradeStateSnapshot) {
    const trade = this.getTrade(state.OrderId);
    if (!trade) {
      this.pushNewTrade(state);
      return;
    }

    const wasOpen = trade.IsOpen;

    trade.updateState(state);

    if (wasOpen && trade.IsClosed) {
      this._openTrades.deleteById(trade.TradeId);
      this._nettedTrades.remove(trade);
      this._closedTrades.createOrUpdate(trade);

      if (this.isSubsribeSymbolInStorage) {
        trade.unsubscribeFromSymbol();
      }
      this._onTradeClosedEventEmitter.emit(trade);
      this.closeOrderEvent.emit(trade.TradeId);
    }
    if (!wasOpen && trade.IsOpen) {
      this._closedTrades.deleteById(trade.TradeId);
      this._openTrades.createOrUpdate(trade);
      this._nettedTrades.put(trade);
    }

    this._onTradeChangedEventEmitter.emit(trade);
    this._onTradeChangedWithTradeEventEmitter.emit(trade);
    this.changeTradesArray.emit();

    if (!state.Symbol.IsActive) {
      this.symbolNotActive.emit();
    }

    this.updateTradeEvent.emit(trade);
  }

  public getTrade(id: number): Trade {
    let result = this._openTrades.getById(id);

    if (result) {
      return result;
    }

    result = this._closedTrades.getById(id);

    return result;
  }

  public get PositionClosedSubscription(): SmartEmitter<string> {
    return this._nettedTrades.PositionClosedSubscription;
  }

  private pushNewTrade(state: TradeStateSnapshot) {
    console.log('push new trade');
    const trade = new Trade();
    trade.updateState(state);

    if (trade.IsOpen) {
      this._openTrades.createOrUpdate(trade);
      console.log('putting to the netted trades');
      this._nettedTrades.put(trade);
    } else {
      this._closedTrades.createOrUpdate(trade);
    }

    this._allTrades.createOrUpdate(trade);

    if (this.isSubsribeSymbolInStorage) {
      trade.subscribeToSymbol();
    }
    this._onTradeOpenedEventEmitter.emit(trade);
    this.changeTradesArray.emit();

    this.updateTradeEvent.emit(trade);
  }

  private async updateOpened(trades: Trade[]) {
    const opened = trades.filter(trade => trade.IsOpen);

    const addedTrades = await this._openTrades.update(opened);

    addedTrades.forEach((trade: Trade) => {
      if (this.isSubsribeSymbolInStorage) {
        trade.subscribeToSymbol();
      }
      this._nettedTrades.put(trade);
      this._onTradeOpenedEventEmitter.emit(trade);
    });
  }

  private async updateClosed(trades: Trade[]) {
    const closed = trades.filter(trade => !trade.IsOpen);

    this._closedTrades.update(closed);

    closed.forEach((trade: Trade) => {

      if (this.isSubsribeSymbolInStorage) {
        trade.unsubscribeFromSymbol();
      }
      this._onTradeClosedEventEmitter.emit(trade);
      this.changeTradesArray.emit();
    });
  }

  private async updateAll(trades: Trade[]) {
    this._allTrades.update(trades);
  }


}

