import {ProtocolMediatorBase} from './protocol-mediator-base';
import {IProtocol} from './protocol-interface';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Notification_ItemTick} from '../classes.g';
import {EventEmitter} from '@angular/core';

export class ProtocolLogger extends ProtocolMediatorBase {

  public _commandAnswerEvent: EventEmitter<ProtoV2MessageBase>;
  private _messagesToIngore: number[];

  public constructor(subProtocol: IProtocol, messagesToIgnore: number[], commandAnswerEvent: EventEmitter<ProtoV2MessageBase>)
  {
    super(subProtocol);
    this._messagesToIngore = messagesToIgnore;
    this._commandAnswerEvent = commandAnswerEvent;
  }

  public send(message: ProtoV2MessageBase)
  {
    this._commandAnswerEvent.emit(message);

    if (this.isMessageNotIgnore(message)) {
      this._logger.debug(`Sending message:`, message);
    }
    super.send(message);
  }

  onMessageReceived(message: ProtoV2MessageBase)
  {

    this._commandAnswerEvent.emit(message);

    if (this.isMessageNotIgnore(message)) {
      this._logger.debug(`Received message :`, message);
    }
    super.onMessageReceived(message);
  }

  private isMessageNotIgnore(message: ProtoV2MessageBase)
  {
    return this._messagesToIngore.indexOf(message.BinaryID) == -1 || message.MessageType != Notification_ItemTick.GetMessageType();
  }

  private messageToString(message: ProtoV2MessageBase)
  {
    return JSON.stringify(message);
  }
}
