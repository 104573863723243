import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Trader} from '@common/trader/models/trader';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {ChallengeService, ChartObjData} from '@common/shared/services/Challenge/challenge.service';
import {MatGridList, MatGridTile} from '@angular/material/grid-list';
import {LocaleModule} from '@common/locale/locale.module';
import {NgIf} from '@angular/common';
import {CircleProgressBarModule} from '@common/shared/components/circle-progress-bar/circle-progress-bar.module';
import {TooltipCustomModule} from '@common/shared/components/tooltips/tooltip.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';

@Component({
  selector: 'lib-challenge-page',
  templateUrl: './challenge-page.component.html',
  styleUrls: ['./challenge-page.component.scss'],
  standalone: true,
  imports: [
    MatGridTile,
    LocaleModule,
    MatGridList,
    NgIf,
    CircleProgressBarModule,
    TooltipCustomModule,
    NgxChartsModule
  ]
})
export class ChallengePageComponent implements OnInit, OnDestroy {

  public view = [400, 145];
  private checkWidthCharts = false;
  barPadding = 10;

  constructor(private trader: Trader,
              private translator: TranslatorService,
              private challengeService: ChallengeService) {
  }

  ngOnInit(): void {
    this.challengeService.challengeInit();
  }

  ngOnDestroy(): void {
    this.challengeService.challengeDestroy();
  }

  public getInitialsBalance() {
    return this.challengeService.getInitialsBalance();
  }

  public getStarDateChallenge() {
    return this.challengeService.getStarDateChallenge();
  }

  public getEndDateChallenge() {
    return this.challengeService.getEndDateChallenge();
  }

  public getDaysPassed() {
    return this.challengeService.getDaysPassed();
  }

  public getDaysPassedProgress() {
    return this.challengeService.getDaysPassedProgress();
  }

  public getDeadline(): boolean {
    return this.challengeService.Deadline;
  }

  public getTotalDays(): number | string {
    return this.challengeService.TotalDays;
  }

  public getConfigCompletionChallengeInDollar() {
    return this.challengeService.getConfigCompletionChallengeInDollar();
  }

  public getConfigCompletionChallengeInPercent() {
    return this.challengeService.getConfigCompletionChallengeInPercent();
  }

  public getCompletionChallengeProgressInDollar(): number | string {
    return this.challengeService.getCompletionChallengeProgressInDollar();
  }

  public getCompletionChallengeProgressInPercent(): number | string {
    return this.challengeService.getCompletionChallengeProgressInPercent();
  }

  public getCompletionChallengeProgressBarInPercent() {
    return this.challengeService.getCompletionChallengeProgressBarInPercent();
  }

  public getCompletionChallengeFinish(): boolean {
    return this.challengeService.getCompletionChallengeFinish();
  }

  public getConfigLosingChallengeInDollar() {
    return this.challengeService.getConfigLosingChallengeInDollar();
  }

  public getConfigLosingChallengeInPercent() {
    return this.challengeService.getConfigLosingChallengeInPercent();
  }

  public getLosingChallengeProgressInDollar(): number | string {
    return this.challengeService.getLosingChallengeProgressInDollar();
  }

  public getLosingChallengeProgressInPercent(): number | string {
    return this.challengeService.getLosingChallengeProgressInPercent();
  }

  public getLosingChallengeProgressBarInPercent() {
    return this.challengeService.getLosingChallengeProgressBarInPercent();
  }

  public getConfigDailyDrawdownInDollar() {
    return this.challengeService.getConfigDailyDrawdownInDollar();
  }

  public getConfigDailyDrawdownPercent() {
    return this.challengeService.getConfigDailyDrawdownPercent();
  }

  public getLosingChallengeFinish(): boolean {
    return this.challengeService.getLosingChallengeFinish();
  }

  public showChallengeBox(): boolean {
    return this.challengeService.showChallengeBox();
  }

  public getLosingChallengeFinishToday(): boolean {
    return this.challengeService.getLosingChallengeFinishToday();
  }

  public getLosingChallengeProgressBarInPercentToday() {
    return this.challengeService.getLosingChallengeProgressBarInPercentToday();
  }

  public getTodayGainLossInDollar() {
    return this.challengeService.getTodayGainLossInDollar();
  }

  public getTodayGainLossPercent() {
    return this.challengeService.getTodayGainLossPercent();
  }

  public get MultiArray() {
    if (!this.checkWidthCharts && this.challengeService.Multi.length > 7) {
      this.checkWidthCharts = true;
      this.view = [this.challengeService.Multi.length * 50, this.view[1]];
    }
    return  this.challengeService.Multi;
  }

  public showGraph(): boolean {
    return this.challengeService.showGraph();
  }

  public getTradeDayStartBalance() {
    return this.challengeService.getTradeDayStartBalance();
  }

  public getSowInstantFundingGroupsChallenge() {
    return this.challengeService.getSowInstantFundingGroupsChallenge();
  }

  public getSowInstantFundingGroupsChallengeProfit() {
    return this.challengeService.getSowInstantFundingGroupsChallengeProfit();
  }

  public get IsReadOnlyAccount() {
    return this.trader.IsReadOnlyAccount;
  }

  public get ShowDataDailyLossIsReadOnlyAccount() {
    return this.challengeService.getShowDataDailyLossIsReadOnlyAccount();
  }

  public getDateCloseAccountChallenge() {
    return this.challengeService.getDateCloseAccountChallenge();
  }

  public getLocalPhrase(value: string) {
    return this.translator.getLocalPhrase(value);
  }

  public get CustomColors(): ChartObjData[] {
    return this.challengeService.CustomColors;
  }

  public get CustomColorsYAxis(): ChartObjData[] {
    return this.challengeService.CustomColorsYAxis;
  }

  public get ColorLosing(): string {
    return this.challengeService.ColorLosing;
  }

  public get ColorCompletion(): string {
    return this.challengeService.ColorCompletion;
  }

  public get ShowXAxis(): boolean {
    return this.challengeService.ShowXAxis;
  }

  public get ShowYAxis(): boolean {
    return this.challengeService.ShowYAxis;
  }

  public get Gradient(): boolean {
    return this.challengeService.Gradient;
  }

  public get ShowLegend(): boolean {
    return this.challengeService.ShowLegend;
  }

  public get ShowXAxisLabel(): boolean {
    return this.challengeService.ShowXAxisLabel;
  }

  public get ShowYAxisLabel(): boolean {
    return this.challengeService.ShowYAxisLabel;
  }

  public get Animations(): boolean {
    return this.challengeService.Animations;
  }

  public get ShowDataLabel(): boolean {
    return this.challengeService.ShowDataLabel;
  }

  public get TooltipDisabled(): boolean {
    return this.challengeService.TooltipDisabled;
  }

  public get NoBarWhenZero(): boolean {
    return this.challengeService.NoBarWhenZero;
  }

}
