import {Injectable} from '@angular/core';
import {UsersGeoData} from '@common/trade/services/location-position.service';

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService {
  private static viewToOpenKey = 'viewToOpen';
  private static viewDataToOpenKey = 'viewDataToOpen';

  private save(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }
  private remove(key: string): void {
    sessionStorage.removeItem(key);
  }
  private get(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  public getDataFromStorage(storageKey: string): string | null {
    return this.get(storageKey);
  }
  public saveDataInStorage(storageKey: string, data: string): void {
    this.save(storageKey, data);
  }
  public deleteDataInStorage(storageKey: string): void {
    this.remove(storageKey);
  }

  public getViewToOpen(): string {
    return this.get(SessionStorageService.viewToOpenKey);
  }

  public setViewToOpen(viewName: string): void {
    this.save(SessionStorageService.viewToOpenKey, viewName);
  }

  public clearViewToOpen(): void {
    this.remove(SessionStorageService.viewToOpenKey);
  }

  public getViewDataToOpen(): string {
    return this.get(SessionStorageService.viewDataToOpenKey);
  }

  public setViewDataToOpen(viewName: string): void {
    this.save(SessionStorageService.viewDataToOpenKey, viewName);
  }

  public clearViewDataToOpen(): void {
    this.remove(SessionStorageService.viewDataToOpenKey);
  }

  public saveRegionConnect(region: string): void {
    this.save('RegionConnect', region);
  }
}
