import {TradeValidator} from './trade.validator';
import {TradeDTO} from '../models/trade-d-t-o';
import {TradeType} from '../models/trade-type';
import {IncorrectPriceError} from '../models/exceptions/incorrect-price.error';
import {TradePriceProvider} from './price-provider/trade-price-provider';


export class PendingTradeValidator extends TradeValidator {
  public validate(order: TradeDTO): TradeValidator {
    return this.checkPrice(order).checkBounds(order);
  }

  public checkPrice(order: TradeDTO): TradeValidator {
    let result: boolean;

    switch (order.Type) {
      case TradeType.BuyLimit: {
        result = this.checkPriceBuyLimit(order);
        break;
      }
      case TradeType.BuyStop: {
        result = this.checkPriceBuyStop(order);
        break;
      }
      case TradeType.SellLimit: {
        result = this.checkPriceSellLimit(order);
        break;
      }
      case TradeType.SellStop: {
        result = this.checkPriceSellStop(order);
        break;
      }
      default: {
        result = false;
        break;
      }
    }

    if (result) {
      return this;
    } else {
      throw new IncorrectPriceError('Incorrect price', 'IncorrectPrice');
    }
  }

  private checkPriceBuyLimit(order: TradeDTO): boolean {
    const symbol = order.Symbol;
    return order.OpenPrice < TradePriceProvider.getTradeCurrentPrice(symbol, order.Type);
  }

  private checkPriceBuyStop(order: TradeDTO): boolean {
    const symbol = order.Symbol;
    return order.OpenPrice > TradePriceProvider.getTradeCurrentPrice(symbol, order.Type);
  }

  private checkPriceSellLimit(order: TradeDTO): boolean {
    const symbol = order.Symbol;
    return order.OpenPrice > TradePriceProvider.getTradeCurrentPrice(symbol, order.Type);
  }

  private checkPriceSellStop(order: TradeDTO): boolean {
    const symbol = order.Symbol;
    return order.OpenPrice < TradePriceProvider.getTradeCurrentPrice(symbol, order.Type);
  }
}
