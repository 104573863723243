import {Trade} from '../../trade/models/trade';
import {EventEmitter, Injectable} from '@angular/core';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {Repeater} from '@common/common/utils/repeater';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {Answer_GetBalance} from '@common/communication/connection/classes.g';
import {MarginStatusService} from '@common/trader/services/margin-status.service';
import {BalanceCommandSender} from '@common/communication/command-sender/balance-command-sender';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {Quote} from '@common/symbol/models/quote';

const EPSILON = 0.01;

@Injectable({
  providedIn: 'root'
})
export class Accounting {

  private _notificationBalanceEmitter: EventEmitter<void> = new EventEmitter<void>();
  private _changBalance: EventEmitter<number> = new EventEmitter<number>();
  private _changUPL: EventEmitter<number> = new EventEmitter<number>();
  private _balance: number;
  private marginSub: ISmartSubscription;
  private _creditIssued: number;
  private _equity: number | null = null;
  private _upl: number | null = null;
  private _uplOld: number | null = null;
  private _freeMargin: number | null = null;
  private _usedMargin: number | null = null;

  private repeater = new Repeater(async () => {
    this.recount();
  }, 1000 ).start();

  public EmitNotificationBalance() {
    this._notificationBalanceEmitter.emit();
  }

  get Equity(): number {
    if (this._equity === null) {
      this.recount2Sync();
    }
    // return CustomNumber.roundForPlace(this._equity, 2);

    const equity = CustomNumber.roundForPlace(this._equity, 2);
    this._changBalance.emit(equity);
    return equity;
  }

  get UPL(): number {
    if (this._upl === null) {
      this.recount2Sync();
    }
    const upl = CustomNumber.roundForPlace(this._upl, 2);

    if (this._uplOld !== upl) {
      this._uplOld = upl;
      this._changUPL.emit(upl);
    }

    return upl;
  }

  get FreeMargin(): number {
    if (this._freeMargin === null) {
      this.recount2Sync();
    }
    return this._freeMargin;
  }

  get UsedMargin(): number {
    if (this._usedMargin === null) {
      this.recount2Sync();
    }
    return this._usedMargin;
  }

  // метод возвращает значение для Margin Level в процентах из расчета this._equity * 100 / this._usedMargin
  get MarginLevel(): string {
    if ( this._balance === null || this._equity === null || this._usedMargin === null || this._freeMargin === null) {
      return '-';
    } else {
      if (this._balance <  EPSILON || this._usedMargin < EPSILON || Math.abs(this._balance - this._freeMargin) < EPSILON) {
        return '-';
      } else {
        const marginLevel = this._equity * 100 / this._usedMargin;
        return `${marginLevel.toFixed(2)} %`;
      }
    }

  }

  get NotificationBalanceEmitter() {
    return this._notificationBalanceEmitter;
  }

  get ChangBalanceEmitter() {
    return this._changBalance;
  }

  get ChangUPLEmitter() {
    return this._changUPL;
  }

  get Balance(): number {
    return CustomNumber.roundForPlace(this._balance, 2);
  }

  set Balance(balance: number) {
    this._balance = balance;
    this.recountAsync();
  }

  private get Trades(): Trade[] {
    return this.tradeStorage.OpenedTrades;
  }

  get CreditIssued(): number {
    return CustomNumber.roundForPlace(this._creditIssued, 2);
  }

  set CreditIssued(value: number) {
    this._creditIssued = value;
  }

  public constructor(private tradeStorage: TradeStorage,
                     private marginService: MarginStatusService,
                     private balanceCommandSender: BalanceCommandSender) {
    this.marginSub = this.marginService.StatusChanged.subscribe(() => this.balanceCommandSender.load());
  }

  private recount(): void {
    // this.subscribeToSymbol(this.Trades);
    const uplMarginTuple = this.getUPLAndUserMargin(this.Trades);
    this._upl = uplMarginTuple[0];
    this._usedMargin = uplMarginTuple[1];
    this._equity = this.getEquity();
    this._freeMargin = this.getFreeMargin();
  }

  private async recountAsync() {
    this.recount();
  }

  public recount2Sync() {
    const uplMarginTuple = this.getUPLAndUserMarginSync(this.Trades);
    this._upl = uplMarginTuple[0];
    this._usedMargin = uplMarginTuple[1];
    this._equity = this.getEquitySync();
    this._freeMargin = this.getFreeMarginSync();
  }

  private getUPLAndUserMargin(trades: Trade[]): [number, number] {
    let upl = 0;
    let usedMargin = 0;

    trades.filter(item => !item.IsPending).forEach(trade => {
      upl += trade.UPL;
      usedMargin += trade.Margin;
    });

    return [upl, usedMargin];
  }

  private getUPLAndUserMarginSync(trades: Trade[]): [number, number] {
    let upl = 0;
    let usedMargin = 0;

    trades.forEach(trade => {
      if (!trade.IsPending) {
        const currentPrice = TradePriceProvider.getTradeClosingPrice(trade.Symbol, trade.Type);
        const uplCalculated = trade.ProfitCalculator.calculateProfit(trade.Volume, trade.OpenPrice, currentPrice);
        upl += CustomNumber.roundForPlace(uplCalculated, 2);
        usedMargin += trade.Margin;
      }

    });

    return [upl, usedMargin];
  }

  private getUsedMargin(trades: Trade[]): number {
    let margin = 0;

    trades.forEach(trade => {
      margin += trade.Margin;
    });

    return margin;
  }

  private getEquity(): number {
    return CustomNumber.roundForPlace(this.Balance, 2)  + CustomNumber.roundForPlace(this.UPL, 2) ;
  }

  private getEquitySync(): number {
    return CustomNumber.roundForPlace(this.Balance, 2)  + CustomNumber.roundForPlace(this.UPL, 2) ;
  }

  private getFreeMargin(): number {
    if (this.UPL > 0) {
      return(this.Balance - this.UsedMargin);
    } else {
      return (this.Equity - this.UsedMargin);
    }
  }

  private getFreeMarginSync(): number {
    if (this.UPL > 0) {
      return(this.Balance - this.UsedMargin);
    } else {
      return (this.Equity - this.UsedMargin);
    }
  }

  public clear(): void {
    this._balance = 0;
  }

  public async loadBalance(): Promise<void> {
    const answer = await this.balanceCommandSender.load().toTypedNativePromise<Answer_GetBalance>();
    this.Balance = Number(answer.Balance);
    this.CreditIssued = Number(answer.CreditIssued);
    console.log(answer);
  }

  public subscribeToUpdateUpl(): void {
    this.Trades.forEach(trade => trade.subscribeToSymbol());
  }

  public unsubscribeToUpdateUpl(): void {
    this.Trades.forEach(trade => trade.unsubscribeFromSymbol());
  }
}
