import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '@common/notification/services/notification.service';
import {Notification} from '@common/notification/models/notification';
import {debounceTime} from 'rxjs/operators';
import {Timer} from '@common/common/utils/timer';
import {NotificationHistoryComponent} from '@common/notification/component/notification-history/notification-history.component';
import {MailMessageProcessor} from '@common/communication/message-processor/processors/mail-message-processor';
import {AppConfig} from '@common/configuration/app-config';
import {Trader} from '@common/trader/models/trader';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {TranslatorService} from '@common/locale/servises/translator.service';

@Component({
  selector: 'app-notification-tape',
  templateUrl: './notification-tape.component.html',
  styleUrls: ['./notification-tape.component.scss']
})
export class NotificationTapeComponent implements OnInit, OnDestroy {
  private tape: Map<number, Notification> = new Map<number, Notification>();
  private showHistory: boolean;
  private tapeUpdated = new EventEmitter();
  private historyUpdated = new EventEmitter();
  private indexMessagesSwitcherTab: number;
  private _tapeMaxLen = 10;
  public notifications: Notification[] = [];
  public isOpenNotificationsMap: Map<number, boolean> = new Map();
  private localeChangedSubscribe: ISmartSubscription;


  @Input()
  public set tapeMaxLen(v: number) {
    this._tapeMaxLen = v;
  }
  public get tapeMaxLen(): number {
    return Number(this._tapeMaxLen);
  }

  @Input()
  public openInNewTab = true;

  private get History(): HTMLDivElement {
    return this.elementRef.nativeElement.querySelector('.notification-history');
  }

  constructor(private notificationService: NotificationService,
              private elementRef: ElementRef,
              private changeDetector: ChangeDetectorRef,
              private mailmessageprocessor: MailMessageProcessor,
              private appConfig: AppConfig,
              private translationService: TranslatorService,
              private trader: Trader) {
    this.historyUpdated.pipe(debounceTime(10)).subscribe(() => this.scrollToLastInHistory());
    this.getIndexMessages();
  }

  public delete(notification: Notification) {
    this.tape.delete(notification.Id);
  }

  ngOnInit() {
    this.notificationService.start();
    this.notifications = this.notificationService.Notifications;

    this.localeChangedSubscribe = this.translationService.LocaleChanged.subscribe(() => {
      this.notificationService.translateNotifications();
      this.notifications = this.notificationService.Notifications;
    });
  }

  ngOnDestroy() {
    if (this.localeChangedSubscribe) {
      this.localeChangedSubscribe.unsubscribe();
      this.localeChangedSubscribe = undefined;
    }
  }


  private pushNotification(notification: Notification) {
    if (this.tape.size > this.tapeMaxLen) {
      const first = this.tape.keys().next().value;
      this.tape.delete(first);
    }
    this.tape.set(notification.Id, notification);
  }

  private scrollToLastInHistory() {
    const timer = new Timer(() => {
      const tape = this.History;

      if (this.notifications.length !== 0) {
        if (tape) {
          tape.querySelector('.notification:first-child').scrollIntoView(true);
        }
      }
    }).start();
  }

  public toggleHistory(event: MouseEvent): void {
    event.stopPropagation();

    if (this.openInNewTab) {
      this.notificationService.OpenMessageSwitcherTab.emit(this.indexMessagesSwitcherTab);
    } else {
      if (this.showHistory) {
        this.closeHistory();
      } else {
        this.openHistory();
      }
    }
    this.notificationService.resetUnreadNotifications();
  }

  public onHistoryUpdated() {
    this.historyUpdated.emit();
  }

  public onTapeUpdated() {
    this.tapeUpdated.emit();
  }

  public async clickNotificationHistory(event: MouseEvent, notification) {
    event.stopPropagation();
    this.isOpenNotificationsMap[notification.id] = !this.isOpenNotificationsMap[notification.id];
    this.notifications.forEach( (e) => {
      if (e.id === notification.id) {
        const a = this.notifications.indexOf(e);
        if (a !== -1) {
          this.notifications[a].isOpen = this.isOpenNotificationsMap[notification.id];
        }
      }
    });
    if (notification.isRead === false) {
      if (notification.From !== 'System') {
        await this.mailmessageprocessor.readMessage(notification.id);
      }

      this.notificationService.readMessage(notification.id);
    }
  }

  public closeHistory(): void {
    this.showHistory = false;
  }
  private openHistory(): void {
    this.showHistory = true;

    this.tape.clear();

    this.changeDetector.detectChanges();

    this.scrollToLastInHistory();
  }

  public get Notifications(): Notification[] {
    return Array.from(this.tape.values());
  }

  public get ShowTape(): boolean {
    return this.tape.size > 0;
  }

  public get NotificationLog(): Notification[] {
    return this.notifications.sort((a, b) => a.Time < b.Time ? 1 : -1);
  }

  public get ShowHistory(): boolean {
    return this.showHistory;
  }

  public get UnreadPreview(): string {
    return this.Unread > 9 ? '9+' : this.Unread.toString();
  }

  public get Unread(): number {
    return this.notificationService.CountOfUnreadNotifications;
  }

  public trackByNotificationId(index, notification: Notification) {
    return notification.Id;
  }

  // определение какой назначить индекс для раздела сообщений
  public getIndexMessages() {
    let index = 4;
    if (this.trader.IsChallengeAccount) {
      ++index;
    }

    if (this.appConfig.Settings.alertPriceChange) {
      ++index;
    }

    this.indexMessagesSwitcherTab = index;
  }

  public isZeTradex(): boolean {
    return this.appConfig.Settings.common !== undefined && this.appConfig.Settings.common.AppName !== undefined && this.appConfig.Settings.common.AppName === 'Zetradex';
  }
}
