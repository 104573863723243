import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class BCUSD_CCUSD extends MarginCalcBase {
  
	constructor(symbol: IMarginCalcSymbol) {
		super(symbol);
	}
        
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.BCUSD_CCUSD;
	}
        
  public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return unsignedAmount  * this.Symbol.getMarginRate() * this.Symbol.getBCConvertSymbol().getAsk();
}

  public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return unsignedAmount * this.Symbol.getBCConvertSymbol().getBid() * this.Symbol.getMarginRate();
}

  public CalculateMaxLotCountInternalLong(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getBCConvertSymbol().getAsk() * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin / (unsignedVolume * this.Symbol.getBCConvertSymbol().getAsk() * this.Symbol.getMarginRate());
}

  public CalculateMaxLotCountInternalShort(unsignedVolume: number, openPrice: number, freeMargin: number): number {
  	
	if (unsignedVolume * this.Symbol.getBCConvertSymbol().getBid() * this.Symbol.getMarginRate() == 0)
			{
				return 0;
			}
  			return freeMargin / (unsignedVolume * this.Symbol.getBCConvertSymbol().getBid() * this.Symbol.getMarginRate());
}

  }