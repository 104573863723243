<div class="toolbar" >
  <div class="settings-block" (onClickOutside)="closeSettings()">
    <div class="quotes-settings colored-body split-border" *ngIf="SettingsOpened">
      <div class="view-settings-group">

        <div *ngFor="let category of Category; let index = index" class="settings-line">
          <span class="header">{{translateSymbolsCategories(category) }}</span>
          <app-check-box (click)="ChangeShowCategory(category)" [ngModel]="ShowCategory(category)"></app-check-box>
        </div>

      </div>
    </div>
    <div class="gear-icon icon"  [class.z-gear-icon]="isZeTradex()" (click)="toggleSettings()">

    </div>
  </div>
  <div class="search-block" (onClickOutside)="tryCloseSearch()" [class.search-block-ml]="SearchBlockMarginLeft" >
    <div class="icon-container-search" [class.z-icon-container-search]="isZeTradex()">
      <div class="search-icon icon" (click)="openDebugMode()"></div>
      <input class="c-input search-input opened" placeholder="{{PlaceholderSearch}}" [(ngModel)]="symbolSearch.Pattern" />
    </div>
  </div>
</div>
<div class="tabs">
  <div class="all_symbols" [class.active]="ViewMode == 'All' || ViewMode == undefined" (click)="viewAllSymbols()">
    <l-unit [key]="'Quotes_QuotesComponent_All'"></l-unit>
  </div>
  <div [class.active]="ViewMode == 'Favorites'" (click)="viewFavorites()">
    <l-unit [key]="'Quotes_QuotesComponent_Favorites'"></l-unit>
  </div>
</div>

<div class="quotes-header zetradex-grid" *ngIf="isZeTradex()">
  <div class="zetradex-symbol" ><l-unit [upperCase]="isZeTradex()" [key]="'Quotes_QuotesComponent_Symbol'"></l-unit></div>
  <div class="zetradex-change"><l-unit [upperCase]="isZeTradex()" [key]="'Quotes_QuotesComponent_Change'"></l-unit></div>
  <div class="zetradex-bid"><l-unit [upperCase]="isZeTradex()" [key]="'Quotes_QuotesComponent_Bid'"></l-unit></div>
  <div class="zetradex-ask"><l-unit [upperCase]="isZeTradex()" [key]="'Quotes_QuotesComponent_Ask'"></l-unit></div>
  <div class="zetradex-stars"><div class="stars-icon-header"></div></div>
</div>

<div class="quotes-table custom-scroll-container" [class.quotes-table-expectations]="isShowExpectations()" [class.zetradex-quotes-table]="addClassForZetradex()">
  <hr class="table-split split-border" />

  <div *ngIf="isShowExpectations() && VisibleSymbolsWithCategory.length > 0" class="container-expectations">
    <app-spinner></app-spinner>
  </div>

  <div *ngIf="getShowVisibleSymbols() && getShowSymbolsWithCategory()"  class="quotes-list custom-scroll custom-scroll-wd0" [checkOnChange]="VisibleSymbols" visibility-frame resize-sensor bufferPercent="60">
    <div *ngFor="let item of VisibleSymbolsWithCategory; let index = index">
      <div class="list-item-category" >{{translateSymbolsCategories(item.categories, true)}}</div>
      <ul class="quotes-list list">
        <li class="symbol-component list-row" *ngFor="let symbol of item.arraySymbols; trackBy: trackBySymbolId"
            [class.z-list-row]="isZeTradex()">
          <div app-symbol [symbolModel]="symbol" (countSymbols)="countSymbols()"></div>
        </li>
      </ul>
    </div>
    <div class="list-item-text  symbol-component no-symbols-message" *ngIf="VisibleSymbolsWithCategory.length == 0">
      <l-unit [key]="'Quotes_QuotesComponent_NoSymbols'"></l-unit>
    </div>
  </div>

  <ul *ngIf="getShowVisibleSymbols() && !getShowSymbolsWithCategory()" class="quotes-list custom-scroll custom-scroll-wd0 list" [checkOnChange]="VisibleSymbols" visibility-frame resize-sensor bufferPercent="60">
    <li class="symbol-component list-row" (visible)="ref.visibility = $event" #ref *ngFor="let symbol of VisibleSymbols; trackBy: trackBySymbolId"
        [class.z-list-row]="isZeTradex()">
      <div *ngIf="ref.visibility" app-symbol [symbolModel]="symbol" ></div>
      <div style="height: 41px" *ngIf="!ref.visibility"></div>
    </li>

    <li class="list-row  symbol-component no-symbols-message" *ngIf="VisibleSymbols.length == 0">
      <l-unit [key]="'Quotes_QuotesComponent_NoSymbols'"></l-unit>
    </li>
  </ul>





</div>
