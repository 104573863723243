import {TradeDTO} from '../trade/models/trade-d-t-o';
import {Injectable} from '@angular/core';
import {Trade} from '../trade/models/trade';
import {AbstractTradeStrategyFactory} from './strategies/trade/abstract-trade-strategy.factory';
import {Router} from '@angular/router';
import {ITradePromise} from '@common/trade/models/trade-promise';

@Injectable({
  providedIn: 'root'
})
export class TradeAction {
  public constructor(private tradeStrategyFactory: AbstractTradeStrategyFactory, private router: Router) {}

  public async openOrder(order?: TradeDTO) {
    const strategy = this.tradeStrategyFactory.getStrategy();
    await strategy.openOrder(order);
  }
  public async closeTrade(trade: Trade): Promise<ITradePromise> {
    const strategy = this.tradeStrategyFactory.getStrategy();
    return await strategy.closeTrade(trade);
  }
  public modifyTrade(trade: Trade) {
    if (trade.IsPending) {
      this.router.navigate(['/terminal', {outlets: { modal: ['modify-pending', trade.TradeId] }} ]);
    } else {
      this.router.navigate(['/terminal', {outlets: { modal: ['modify', trade.TradeId] }} ]);
    }
  }

  public modifyTradeMobile(trade: Trade) {
    if (trade.IsPending) {
      this.router.navigate(['/terminal/trading/modify-pending', trade.TradeId]);
    } else {
      this.router.navigate(['/terminal/trading/modify', trade.TradeId]);
    }
  }
}
