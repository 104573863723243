import {Injectable} from '@angular/core';
import {Trader} from '@common/trader/models/trader';
import {AppConfig} from '@common/configuration/app-config';
import {ChallengeService} from '@common/shared/services/Challenge/challenge.service';
import {DataLoaderService} from '@common/shared/services/data-loader.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';


@Injectable({
  providedIn: 'root'
})

export class UpdatingDataByTime {

  private readonly appDataUpdateTimeZone: string;
  private readonly appDataUpdateTime: string;
  private checkingTimeInterval: number;

  constructor(private trader: Trader,
              private appConfig: AppConfig,
              private challengeService: ChallengeService,
              private dataLoaderService: DataLoaderService,
              private symbolStorageService: SymbolStorageService) {

    if (this.appConfig.Settings.appDataUpdateTime !== undefined) {
      this.appDataUpdateTime = this.appConfig.Settings.appDataUpdateTime.time;
      this.appDataUpdateTimeZone = this.appConfig.Settings.appDataUpdateTime.timezone;
    }
  }

  public init(): void {
    if (this.appDataUpdateTimeZone && this.appDataUpdateTime) {
      this.checkingTimeInterval = setInterval(() => { this.checkingTime(); }, 60000);
    }
  }


  private checkingTime() {
    const date = new Date().toLocaleString('en-GB', { timeZone: this.appDataUpdateTimeZone });
    const parsingDateArray = date.split(' ');
    const hoursAndMinutesCurrentDateArray = parsingDateArray[1].slice(0, 5);

    if (hoursAndMinutesCurrentDateArray === this.appDataUpdateTime) {
      console.log('Time to update the data ' + this.appDataUpdateTime + ' timeZone: ' + this.appDataUpdateTimeZone);

      if (this.trader.IsChallengeAccount) {
        this.challengeService.reRequestingData();
      }

      this.dataLoaderService.loadTrades().then();
      this.symbolStorageService.getTradingSchedule();

    }
  }
}
