import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NotificationTapeComponent} from './component/notification-tape/notification-tape.component';
import {NotificationComponent} from './component/notification/notification.component';
import {NotificationHistoryComponent} from './component/notification-history/notification-history.component';
import {LocaleModule} from '@common/locale/locale.module';
import {SharedModule} from '@common/shared/shared.module';
import {MarginCallNotificationComponent} from './component/margin-call-notification/margin-call-notification.component';
import {TradeModule} from '@common/trade/trade.module';

@NgModule({
  declarations: [NotificationTapeComponent, NotificationComponent, NotificationHistoryComponent, MarginCallNotificationComponent],
    imports: [
        CommonModule,
        LocaleModule,
        SharedModule,
        TradeModule,
        NgOptimizedImage
    ],
  exports: [NotificationTapeComponent, MarginCallNotificationComponent, NotificationHistoryComponent, NotificationComponent]
})
export class NotificationModule { }
