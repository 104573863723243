import {AbstractMessageProcessor} from './abstract-message-processor';
import {ServerInteractionService} from '../../connection/server-interaction.service';
import {ConnectionState, InternalNotificationConnectionStateChanges} from '../../connection/connection.state';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Trader} from '@common/trader/models/trader';

@Injectable({
  providedIn: 'root'
})
export class ConnectionStateMessageProcessor extends AbstractMessageProcessor {

  public constructor(serverConnection: ServerInteractionService,
                     private trader: Trader,
                     private router: Router,
                     private ngZone: NgZone) {
    super(serverConnection);
  }

  public subscribe(): void {
    const subscription = this.serverConnection.on(InternalNotificationConnectionStateChanges.GetMessageType(),
      async (state) => await this.stateChanged(state));

    this.saveSubscription(InternalNotificationConnectionStateChanges.GetMessageType(), subscription);
    this.logger.debug(`Subscribed for ${InternalNotificationConnectionStateChanges.GetMessageType()}`);
  }

  private async stateChanged(state: InternalNotificationConnectionStateChanges): Promise<void> {
    const value = state.getState();

    switch (+value) {
      case ConnectionState.Connected: {
        await this.onConnected();
        this.trader.onConnectionRestored();
        break;
      }
      case ConnectionState.Disconnected: {
        this.trader.onConnectionLost();
        await this.onLogout();
        break;
      }
      case ConnectionState.ConnectionEstablishingError: {
        await this.onConnectionEstablishingError();
        break;
      }
      case ConnectionState.RemoteDisconnected: {
        await this.onRemoteDisconnected();
        break;
      }
      case ConnectionState.LoggedOut: {
        await this.onLogout();
        break;
      }

      case ConnectionState.InvalidConnection: {
        this.trader.onConnectionRestored();
        await this.onLogout();
        break;
      }

      case ConnectionState.Reset: {
        this.trader.onConnectionRestored();
        break;
      }
    }

    this.logger.info(state.MessageType);
  }

  private async onRemoteDisconnected() {
    this.trader.logout();
    await this.ngZone.run(async () => await this.router.navigate(['/sign-in']));

    this.logger.debug('onRemoteDisconnected');
  }

  private async onConnected(): Promise<void> {
    // this.trader.login();
//    await this.ngZone.run(async () => await this.router.navigate(['/terminal']));
    this.logger.debug('OnLogin');
  }

  private async onLogout(): Promise<void> {
    this.trader.logout();
    await this.ngZone.run(async () => await this.router.navigate(['/sign-in']));
    this.logger.debug('onLogout');
  }
  private async onConnectionEstablishingError(): Promise<void> {
    this.logger.debug('onConnectionEstablishingError');
  }
}
