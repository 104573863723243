
import { IMarginCalc } from "@common/shared/calculators/Margin/IMarginCalc.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import { MarginCalcType } from "@common/shared/calculators/Margin/MarginCalcType.g";
import {CustomNumber} from "@common/trade/utils/custom-number"			

export abstract class MarginCalcBase implements IMarginCalc {

	Symbol: IMarginCalcSymbol;

	public abstract getMarginCalcType(): MarginCalcType 

	constructor(symbol: IMarginCalcSymbol) {
		this.Symbol = symbol;
	}

	protected CalculateInternal(signedAmount: number, openPrice: number): number {
		if (signedAmount >= 0) {
			return this.CalculateInternalLong(Math.abs(signedAmount), openPrice);
		} else {
			return this.CalculateInternalShort(Math.abs(signedAmount), openPrice);
		}
	}

	protected CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
		throw new Error("Method 'calculateInternalLong' not implemented.");
	}

	protected CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
		throw new Error("Method 'calculateInternalShort' not implemented.");
	}

	protected CalculateMaxLotCountInternal(signedVolume: number, openPrice: number, freeMargin: number): number {
		if (signedVolume >= 0) {
			return this.CalculateMaxLotCountInternalLong(Math.abs(signedVolume), openPrice, freeMargin);
		} else {
			return this.CalculateMaxLotCountInternalShort(Math.abs(signedVolume), openPrice, freeMargin);
		}
	}

	protected CalculateMaxLotCountInternalLong(unsignedAmount: number, openPrice: number, freeMargin: number): number {
		throw new Error("Not implemented");
	}

	protected CalculateMaxLotCountInternalShort(unsignedVolume: number, openPrice: number, freeMargin: number): number {
		throw new Error("Not implemented");
	}

	public Calculate(signedAmount: number, openPrice: number): number {
		const result = this.CalculateInternal(signedAmount, openPrice);
		return CustomNumber.roundForPlace(result, 2);
	}

	public CalculateMaxLotCount(signedVolume: number, openPrice: number, freeMargin: number): number {
		const result = this.CalculateMaxLotCountInternal(signedVolume, openPrice, freeMargin);
		return Math.trunc(result);
	}
}
			