import { Injectable } from '@angular/core';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {Trader} from '@common/trader/models/trader';
import {EquityChartResolution} from '@common/shared/services/equity-chart/equity-chart-resolution';
import {Command_GetEquityChartData} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class EquityChartCommandSenderService extends AbstractCommandSender {

  private _logger = LoggerFactory.getLogger('EquityChartCommandSenderService');

  public constructor(serverConnection: ServerInteractionService, private trader: Trader) {
    super(serverConnection);
  }

  public getEquityChartData(resolution: EquityChartResolution, periodBegin?: Date, periodEnd?: Date) {
    const traderId = this.trader.TraderId;

    const command = new Command_GetEquityChartData();

    command.TraderID = traderId;
    command.Resolution = <number> resolution;
    command.PeriodBegin = periodBegin ? this.toUnixTimestamp(periodBegin).toString() : '';
    command.PeriodEnd = periodEnd ? this.toUnixTimestamp(periodEnd).toString() : '';

    return new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(message => {
          resolve(message.Result);
        })
        .failed(message => reject(message));
    });
  }

  private toUnixTimestamp(date: Date) {
    return Math.floor(date.getTime() / 1000);
  }
}
