import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuOptionComponent} from '@common/user-interface/components/menu-option/menu-option.component';
import {MenuSelectComponent} from '@common/user-interface/components/menu-select/menu-select.component';
import {MenuSelectOptionsComponent} from '@common/user-interface/components/menu-select-options/menu-select-options.component';
import {ToggleButtonComponent} from '@common/user-interface/components/toggle-button/toggle-button.component';
import {RadioButtonComponent} from './components/radio-button/radio-button.component';
import {RadioGroupComponent} from './components/radio-group/radio-group.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    MenuOptionComponent,
    MenuSelectComponent,
    MenuSelectOptionsComponent,
    ToggleButtonComponent,
    RadioGroupComponent,
    RadioButtonComponent
  ],
    imports: [
        CommonModule,
        FormsModule
    ],
  exports: [
    MenuOptionComponent,
    MenuSelectComponent,
    MenuSelectOptionsComponent,
    ToggleButtonComponent,
    RadioGroupComponent
  ]
})
export class UserInterfaceModule { }
