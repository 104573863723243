import * as crypto from 'crypto-js';

const SECRET_KEY = 'YourSecretKeyForEncryption&Descryption230';
export class OperationsEncryptDecryptData {

  public static encryptText( value: string): string {
    return crypto.AES.encrypt(value, SECRET_KEY.trim()).toString();
  }

  public static decryptText(textToDecrypt: string): string {
    return crypto.AES.decrypt(textToDecrypt, SECRET_KEY.trim()).toString(crypto.enc.Utf8);
  }

}
