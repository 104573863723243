import {LogLevel} from './log-level.enum';
import * as moment from 'moment/moment';

export class Logger {
  private name: string;
  private level: LogLevel;

  public constructor(name: string, level: LogLevel) {
    this.name = name;
    this.level = level;
  }

  public debug(message: any, ... params: any[]) {
    this.write(console.log, LogLevel.Debug, 'DEBUG', message, params);
  }
  public debugWithCondition(condition: boolean, message: any, ... params: any[]) {
    if(!condition) return;
    this.write(console.log, LogLevel.Debug, 'DEBUG', message, params);
  }
  public info(message: any, ... params: any[]) {
    this.write(console.log, LogLevel.Info, 'INFO', message, params);
  }
  public infoWithCondition(condition: boolean, message: any, ... params: any[]) {
    if(!condition) return;
    this.write(console.log, LogLevel.Info, 'INFO', message, params);
  }
  public warn(message: any, ... params: any[]) {
    this.write(console.warn, LogLevel.Warning, 'WARN', message, params);
  }
  public warnWithCondition(condition: boolean, message: any, ... params: any[]) {
    if(!condition) return;
    this.write(console.warn, LogLevel.Warning, 'WARN', message, params);
  }
  public error(message: any, ... params: any[]) {
    this.write(console.error, LogLevel.Error, 'ERROR', message, params);
  }
  public errorWithCondition(condition: boolean, message: any, ... params: any[]) {
    if(!condition) return;
    this.write(console.error, LogLevel.Error, 'ERROR', message, params);
  }
  public fatal(message: any, ... params: any[]) {
    this.write(console.error, LogLevel.Fatal, 'FATAL', message, params);
  }
  public fatalWithCondition(condition: boolean, message: any, ... params: any[]) {
    if(!condition) return;
    this.write(console.error, LogLevel.Fatal, 'FATAL', message, params);
  }

  private write(func: (message?: any, ... optParams: any[]) => void, level: LogLevel, decodedLevel: string, message: any, ... params: any[]): void {
    if (level >= this.level) {
      func(`${decodedLevel} - ${this.name} - ${moment().format('D-MM-YYYY HH:mm:ss')} - ${message.toString()}`);

      if (params[0].length === 0) return;

      params.forEach(item => {
        func(item[0]);
      });
    }
  }
}
