<div class="image">
  <div class="menu-icon-notifcations menu-icon-notifcations-mobile"></div>
</div>
<span class="remove-message-icon" (click)="onRemoveMessageClick($event, notification)"></span>
<span class="notification-header" [ngClass]="getHeaderClass()">
  {{Header}}
</span>
<span class="marker" [class.read]="Read" [class.always-read]="IsStatusAlwaysRead"></span>
<span class="notification-body">
  <span *ngIf="!getIsOpened()"  [textContent]="Body" ></span>
</span>
<span class="notification-time">
  <l-date [date]="Date"></l-date>
</span>

<div *ngIf="getIsOpened()" class="notification-body-box">
  <span *ngIf="!notification.messageUrl" class="body-text">{{ notification.text }}</span>
  <span *ngIf="notification.messageUrl" class="notification-body-span">
    <span class="body-text">{{ notification.text }}</span>
    <a *ngIf="notification.link" class="body-text-link" href="{{ notification.link}}">Links..</a>
    <img class="notification-img-url" ngSrc="{{notification.link}}" alt="">
  </span>
</div>
