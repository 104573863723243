import {MarginCalcBase} from "@common/shared/calculators/Margin/MarginCalcBase.g";
import {MarginCalcType} from "@common/shared/calculators/Margin/MarginCalcType.g";
import {IMarginCalcSymbol} from "@common/shared/calculators/Margin/IMarginCalcSymbol.g";
import {IMarginCalc} from "@common/shared/calculators/Margin/IMarginCalc.g";

export class Zero implements IMarginCalc {
  
  
	public getMarginCalcType(): MarginCalcType {
		return MarginCalcType.Zero;
	}
        
  public Calculate(signedVolume: number, openPrice: number): number {
  
  			return 0;
}

  public CalculateMaxLotCount(signedVolume: number, openPrice: number, freeMargin: number): number {
  
  			return 0;
}

  
  
  }