import {isBuyTrade} from '@common/trade/models/trade-type';
import {PriceCalculator} from '@common/trade/utils/price-calculator';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';


export class StopsCorrector {
  private readonly MaxBound = 1000;
  private readonly MinBound = 10;

  public constructor(private trade: TradeDTO) {}

  public correctSL( sl: number, slInPips: number): number {
    if(isBuyTrade(this.trade.Type)) return this.correctStopLoss(sl, slInPips);
    else return this.correctTakeProfit(sl, slInPips);
  }

  public correctTP(tp: number, tpInPips: number): number {
    if(isBuyTrade(this.trade.Type)) return this.correctTakeProfit(tp, tpInPips);
    else return this.correctStopLoss(tp, tpInPips);
  }

  private correctTakeProfit(value: number, valueInPips: number): number {
    return this.correctSimpleTakeProfit(value, valueInPips);
  }

  private correctSimpleTakeProfit(value: number, valueInPips: number): number {
    if(valueInPips === 0 && this.trade.CurrentPips === 0) {
      return this.trade.CurrentPrice + PriceCalculator.pipsToPrice(this.MinBound, this.trade.Symbol);
    }
    else if(valueInPips === 0){
      return this.trade.CurrentPrice + ((this.trade.CurrentPrice - this.trade.OpenPrice) / this.trade.CurrentPips) * this.MinBound;
    }
    else if(valueInPips > this.MaxBound){
      return this.trade.CurrentPrice + (value - this.trade.CurrentPrice) / valueInPips * this.MaxBound;
    }
    else if(valueInPips < this.MinBound){
      return this.trade.CurrentPrice + (value - this.trade.CurrentPrice) / valueInPips * this.MinBound;
    }
    else return value;
  }


  private correctStopLoss(value: number, valueInPips: number): number {
    return this.correctSimpleStopLoss(value, valueInPips);
  }

  private correctSimpleStopLoss(value: number, valueInPips: number): number {
    if(valueInPips === 0 && this.trade.CurrentPips === 0) {
      return this.trade.CurrentPrice - PriceCalculator.pipsToPrice(this.MinBound, this.trade.Symbol);
    }
    else if(valueInPips === 0){
      return this.trade.CurrentPrice - ((this.trade.CurrentPrice - this.trade.OpenPrice) / this.trade.CurrentPips) * this.MinBound;
    }
    else if(valueInPips < -this.MaxBound){
      return this.trade.CurrentPrice - (((value - this.trade.CurrentPrice) / valueInPips) * this.MaxBound);
    }
    else if(valueInPips > -this.MinBound){
      return this.trade.CurrentPrice - (((value - this.trade.CurrentPrice) / valueInPips) * this.MinBound);
    }
    else return value;
  }
}
