import {RolloverCalcBase} from "@common/shared/calculators/Rollover/RolloverCalcBase.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";

export class ByInterest extends RolloverCalcBase {
	
	constructor(symbol: IRolloverCalcSymbol) {
		super(symbol);
	}
        
	
	getRolloverCalcType(): RolloverCalcType {
		return RolloverCalcType.ByInterest;
	}
        
	public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return (Math.abs(unsignedAmount) * this.Symbol.getRolloverBuy() / this.Symbol.getContractSize() / 100 / 360) * ((this.Symbol.getBid() + this.Symbol.getAsk()) / 2) * this.Symbol.getConversionToUSD();
}

	public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return (Math.abs(unsignedAmount) * this.Symbol.getRolloverSell() / this.Symbol.getContractSize() / 100 / 360) * ((this.Symbol.getBid() + this.Symbol.getAsk()) / 2) * this.Symbol.getConversionToUSD();
}

	
	
}