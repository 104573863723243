<form #f="ngForm" [class.z-multi-order-form]="IsZeTradex" >
  <div class="button-group"  [class.z-multi-order-form-button-group]="IsZeTradex" >
    <div class="button-danger sell-button trade-type-button" [class.z-multi-order-form-sell-button]="IsZeTradex" [ngClass]="InActiveButton('Sell')"
         (click)="FormSettings.setSellPosition()">
      <div class="block-header">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_sell-header'"></l-unit>
      </div>
      <div class="price">
        {{FormSettings.BidPrice | price: FormSettings.CurrentSymbol}}
      </div>
    </div>
    <div class="spread" [class.z-multi-order-form-spread]="IsZeTradex">
      {{FormSettings.Spread}}
    </div>
    <div class="button-success buy-button trade-type-button" [class.z-multi-order-form-buy-button]="IsZeTradex" [ngClass]="InActiveButton('Buy')"
         (click)="FormSettings.setBuyPosition()">
      <div class="block-header">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_buy-header'"></l-unit>
      </div>
      <div class="price">
        {{FormSettings.AskPrice | price: FormSettings.CurrentSymbol}}
      </div>
    </div>
  </div>

  <div class="opening-tab-menu block split-border" [class.z-multi-order-form-opening-tab-menu]="IsZeTradex" *ngIf="NormalSizeMode">

    <div class="tabs" [ngClass]="getSelectedOpening()" *ngIf="!IsZeTradex; else zetradexTabs">
      <div (click)="FormSettings.setMarketOpening()" [class.active]="FormSettings.Opening == 'Market'">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_market-header'"></l-unit>
      </div>
      <div (click)="FormSettings.setLimitOpening()" [class.active]="FormSettings.Opening == 'Limit'">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_limit-header'"></l-unit>
      </div>
      <div (click)="FormSettings.setStopOpening()" [class.active]="FormSettings.Opening == 'Stop'">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_stop-header'"></l-unit>
      </div>
    </div>

    <ng-template #zetradexTabs>
      <div class="z-tabs" [ngClass]="getSelectedOpening()">
        <div (click)="FormSettings.setMarketOpening()" [class.z-tab-active]="FormSettings.Opening == 'Market'">
          <l-unit [key]="'TradeModule_MultiOrderFormComponent_market-header'"></l-unit>
        </div>
        <div (click)="FormSettings.setLimitOpening()" [class.z-tab-active]="FormSettings.Opening == 'Limit'">
          <l-unit [key]="'TradeModule_MultiOrderFormComponent_limit-header'"></l-unit>
        </div>
        <div (click)="FormSettings.setStopOpening()" [class.z-tab-active]="FormSettings.Opening == 'Stop'">
          <l-unit [key]="'TradeModule_MultiOrderFormComponent_stop-header'"></l-unit>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="price-block block" *ngIf="FormSettings.Opening == 'Limit' || FormSettings.Opening == 'Stop'">
    <span class="block-header">
      <l-unit [key]="'TradeModule_MultiOrderFormComponent_open-price-header'"></l-unit>
    </span>
    <div class="input-group horizontal-input-group" [ngClass]="getPriceClass()">
      <div>
        <number-input
          non-zero
          class="absolute-price"
          name="absolute-price"
          [decimalPlaces]="FormSettings.CurrentSymbol.DecimalPlaces"
          [(ngModel)]="FormSettings.OpenPrice"
          order-price
          [symbol]="Symbol"
          [type]="OrderType"
          [askPrice]="Symbol.Ask"
          [bidPrice]="Symbol.Bid"
          [blockFreeChange]="FormSettings.VolumeType != 0"
          (click)="onOpenPriceClick()"
        ></number-input>
      </div>
      <div>
        <selectable-input
          class="relative-price"
          name="relative-price"
          step="1"
          [filler]="FormSettings.Position == 'Buy' ? 'Ask' : 'Bid'"
          [(ngModel)]="FormSettings.RelativePrice"
        ></selectable-input>
      </div>
    </div>
    <div class="titles">
      <span>
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_absolute-price-header'"></l-unit>
      </span>
      <span>
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_relative-price-header'"></l-unit>
      </span>
    </div>
  </div>

  <div class="market-block block block-margin" *ngIf="NormalSizeMode">
    <div class="quantity">
      <span class="block-header">
        <l-unit *ngIf="!Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_quantity-amount-header'"></l-unit>&nbsp;
        <l-unit *ngIf="Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_lot-header'"></l-unit>&nbsp;
        <span [hidden]="Symbol.TradingStep === 0">(<l-unit [key]="'TradeModule_MultiOrderFormComponent_Step'"></l-unit> {{showStep()}})</span>
      </span>
      <div class="horizontal-input-group input-group">
        <div [class.selected]="VolumeType == 0 && FormSettings.StopLoss.Enabled">
          <app-volume-input (focusin)="setUnitsVolume()"
                            [Symbol]="Symbol"
                            [decimalPlaces]="DecimalPlaces"
                            [blockFreeChange]="FormSettings.VolumeType != 0"
                            name="volume-units"
                            [(ngModel)]="FormSettings.Volume"
                            non-zero
                            [appName]="appName">
          </app-volume-input>
        </div>
        <div *ngIf="FormSettings.StopLoss.Enabled && Settings.UseAdvancedVolume" [class.selected]="VolumeType == 2">
          <number-input (focusin)="setCurrencyRiskVolume()"
                        name="risk-usd"
                        [arrowsStepHidden]="false"
                        [blockFreeChange]="FormSettings.VolumeType != 2"
                        [ngModel]="VolumeCurrency"
                        (changed)="FormSettings.StopLoss.Profit = $event"
                        non-zero
                        [min-value]="0.000001"
                        step="0.01"
          ></number-input>
        </div>
        <div *ngIf="FormSettings.StopLoss.Enabled && Settings.UseAdvancedVolume" [class.selected]="VolumeType == 1">
          <number-input (focusin)="setPercentRiskVolume()"
                        [arrowsStepHidden]="false"
                        [blockFreeChange]="FormSettings.VolumeType != 1"
                        name="risk-percent"
                        [(ngModel)]="VolumePercent"
                        non-zero
                        [min-value]="0.000001"
                        step="0.01"
          ></number-input>
        </div>
      </div>
    </div>
  </div>

  <div class="titles titles-use-advanced-volume block-margin" *ngIf="NormalSizeMode">
    <span><l-unit [key]="'TradeModule_MultiOrderFormComponent_units-amount-header'"></l-unit></span>
    <span *ngIf="FormSettings.StopLoss.Enabled && Settings.UseAdvancedVolume"><app-account-currency-icon></app-account-currency-icon> <l-unit
      [key]="'TradeModule_MultiOrderFormComponent_risk-amount-header'"></l-unit></span>
    <span *ngIf="FormSettings.StopLoss.Enabled && Settings.UseAdvancedVolume">% <l-unit
      [key]="'TradeModule_MultiOrderFormComponent_risk-amount-header'"></l-unit></span>
  </div>

      <!--  new block Basic for the trade-->

  <div *ngIf="IsShowAPreliminaryCalculationOfData" class="block-data-basic">
    <div class="left-block">
      <div class="items-data-basic">
        <span class="title"><l-unit [key]="'TradeModule_MultiOrderFormComponent_ContractSize'"></l-unit>:</span>
        <span> ≈ {{Symbol.ContractSize}}</span>
      </div>
      <div class="items-data-basic">
        <span class="title"><l-unit [upperCase]="IsZeTradex" [key]="'TradeModule_PositionListComponent_margin-cell'"></l-unit> (<app-account-currency-title></app-account-currency-title>):</span>
        <span> ≈ {{PossibleMargin}}</span>
      </div>
      <div class="items-data-basic">
        <span class="title"><l-unit [key]="'TradeModule_MultiOrderFormComponent_Spreed'"></l-unit> (<app-account-currency-title></app-account-currency-title>):  </span>
        <span>{{SpreadValueInAC}}</span>
        <span>{{FormSettings.Spread | pips}} <l-unit [key]="'TradeModule_OpenTradeLineComponent_PipsLabel'"></l-unit> </span>
      </div>
    </div>
    <div class="right-block">
      <div class="items-data-basic">
        <span class="title"><l-unit [key]="'TradeModule_MultiOrderFormComponent_TransFee'"></l-unit> (<app-account-currency-title></app-account-currency-title>): </span>
        <span>≈ {{PossibleCommission}} </span>
      </div>
      <div class="items-data-basic">
        <span class="title"><l-unit [key]="'TradeModule_MultiOrderFormComponent_PipValue'"></l-unit> (<app-account-currency-title></app-account-currency-title>): </span>
        <span>{{PipValue}}</span>
      </div>
      <div class="items-data-basic">
        <span class="title"><l-unit [key]="'TradeModule_MultiOrderFormComponent_Swaps'"></l-unit> (<app-account-currency-title></app-account-currency-title>): </span>
        <span class="swap-box-info">
          <span class="swap-info-sell">
            <span><l-unit [key]="'TradeModule_MultiOrderFormComponent_Sell'"></l-unit>:</span>
            <span>{{PossibleRolloverSell}}</span>
          </span>
          <span class="swap-info-buy">
            <span><l-unit [key]="'TradeModule_MultiOrderFormComponent_Buy'"></l-unit>:</span>
            <span>{{PossibleRolloverBuy}}</span>
          </span>
        </span>
      </div>
    </div>
  </div>

  <div class="stops-block block " *ngIf="NormalSizeMode" [class.z-stops-block]="IsZeTradex" [class.z-stops-block-desktop]="IsZeTradexDesktop">

<!--       Take Profit Section-->

    <div class="take-profit-column column stop-column">
      <div class="line block-header" [class.z-position-tp-label-box]="IsZeTradex">
        <input [(ngModel)]="FormSettings.TakeProfit.Enabled" name="take-profit-enable" class="input" type="checkbox"/>
        <span [class.z-selected]="FormSettings.TakeProfit.StopType == 1 && FormSettings.TakeProfit.Enabled && IsZeTradex">
          <l-unit [key]="'TradeModule_MultiOrderFormComponent_take-profit-header'"></l-unit>
        </span>
      </div>
      <div class="vertical-input-group input-group">
        <div [class.selected]="FormSettings.TakeProfit.StopType == 1 && FormSettings.TakeProfit.Enabled">
          <number-input (focusin)="setPipsTakeProfit()"
                        non-zero
                        [selectedItems]="FormSettings.TakeProfit.StopType"
                        [typeInput]="1"
                        [min-value]="MinStopPips"
                        [min]="MinStopPips"
                        [disabled]="!FormSettings.TakeProfit.Enabled"
                        [ngModel]="FormSettings.TakeProfit.Pips"
                        (changed)="FormSettings.TakeProfit.Pips = $event"
                        class="line"
                        name="tp-pips" step="1">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.TakeProfit.StopType == 0 && FormSettings.TakeProfit.Enabled">
          <number-input (focusin)="setPriceTakeProfit()"
                        take-profit non-zero [min-value]="0"
                        [disabled]="!FormSettings.TakeProfit.Enabled"
                        [selectedItems]="FormSettings.TakeProfit.StopType"
                        [typeInput]="0"
                        [type]="OrderType"
                        [symbol]="Symbol"
                        [currentPrice]="OpenPrice"
                        [ngModel]="FormSettings.TakeProfit.Price"
                        (changed)="FormSettings.TakeProfit.Price = $event"
                        class="line"
                        name="tp-price"
                        [decimalPlaces]="FormSettings.CurrentSymbol.DecimalPlaces">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.TakeProfit.StopType == 2 && FormSettings.TakeProfit.Enabled">
          <number-input (focusin)="setProfitTakeProfit()"
                        non-zero
                        [arrowsStepHidden]="false"
                        [ngModel]="FormSettings.TakeProfit.Profit"
                        [selectedItems]="FormSettings.TakeProfit.StopType"
                        [typeInput]="2"
                        (changed)="FormSettings.TakeProfit.Profit = $event"
                        [min-value]="0"
                        [disabled]="!FormSettings.TakeProfit.Enabled"
                        class="line"
                        name="tp-profit" step="0.01">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.TakeProfit.StopType == 3 && FormSettings.TakeProfit.Enabled">
          <number-input (focusin)="setPercentTakeProfit()"
                        [arrowsStepHidden]="false"
                        [disabled]="!FormSettings.TakeProfit.Enabled"
                        [selectedItems]="FormSettings.TakeProfit.StopType"
                        [typeInput]="3"
                        [ngModel]="FormSettings.TakeProfit.Percent"
                        (changed)="FormSettings.TakeProfit.Percent = $event"
                        class="line"
                        name="tp-percent"
                        step="0.01">
          </number-input>
        </div>
      </div>
    </div>

<!--    Legends-->

    <div class="description-column column">
      <div class="line"></div>
      <div class="line line-text">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_description-pips-header'"></l-unit>
      </div>
      <div class="line line-text">
        <l-unit [key]="'TradeModule_MultiOrderFormComponent_description-price-header'"></l-unit>
      </div>
      <div class="line line-text">
        <app-account-currency-icon></app-account-currency-icon>
      </div>
      <div class="line line-text">%</div>
    </div>

<!--    Stop Loss Section-->

    <div class="stop-loss-column column stop-column">
      <div class="line block-header" [class.z-position-sl-label-box]="IsZeTradex">
        <span [class.z-selected]="FormSettings.StopLoss.StopType == 1 && FormSettings.StopLoss.Enabled && IsZeTradex">
          <l-unit [key]="'TradeModule_MultiOrderFormComponent_stop-loss-header'"></l-unit>
        </span>
        <input [(ngModel)]="FormSettings.StopLoss.Enabled" name="stop-loss-enable" class="input" type="checkbox"/>
      </div>
      <div class="vertical-input-group input-group">
        <div [class.selected]="FormSettings.StopLoss.StopType == 1 && FormSettings.StopLoss.Enabled">
          <number-input (focusin)="setPipsStopLoss()"
                        non-zero stop-loss-pips
                        [currentSpread]="FormSettings.Spread"
                        [selectedItems]="FormSettings.StopLoss.StopType"
                        [typeInput]="1"
                        [min-value]="MinStopPips"
                        [min]="MinStopPips"
                        [followChanges]="true"
                        [ngModel]="FormSettings.StopLoss.Pips"
                        (changed)="FormSettings.StopLoss.Pips = $event"
                        class="line"
                        [disabled]="!FormSettings.StopLoss.Enabled"
                        name="sl-pips" step="1">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.StopLoss.StopType == 0 && FormSettings.StopLoss.Enabled">
          <number-input (focusin)="setPriceStopLoss()"
                        stop-loss non-zero
                        [min-value]="0"
                        [disabled]="!FormSettings.StopLoss.Enabled"
                        [selectedItems]="FormSettings.StopLoss.StopType"
                        [typeInput]="0"
                        [type]="OrderType"
                        [ngModel]="FormSettings.StopLoss.Price"
                        (changed)="FormSettings.StopLoss.Price = $event"
                        [symbol]="Symbol"
                        [decimalPlaces]="FormSettings.CurrentSymbol.DecimalPlaces"
                        [currentPrice]="OpenPrice"
                        class="line" name="sl-price">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.StopLoss.StopType == 2 && FormSettings.StopLoss.Enabled">
          <number-input (focusin)="setProfitStopLoss()"
                        non-zero
                        [arrowsStepHidden]="false"
                        [disabled]="!FormSettings.StopLoss.Enabled"
                        [selectedItems]="FormSettings.StopLoss.StopType"
                        [typeInput]="2"
                        [min-value]="0"
                        [ngModel]="FormSettings.StopLoss.Profit"
                        (changed)="FormSettings.StopLoss.Profit = $event"
                        class="line"
                        name="sl-profit"
                        step="0.01">
          </number-input>
        </div>
        <div [class.selected]="FormSettings.StopLoss.StopType == 3 && FormSettings.StopLoss.Enabled">
          <number-input (focusin)="setPercentStopLoss()"
                        [arrowsStepHidden]="false"
                        [disabled]="!FormSettings.StopLoss.Enabled"
                        [selectedItems]="FormSettings.StopLoss.StopType"
                        [typeInput]="3"
                        [ngModel]="FormSettings.StopLoss.Percent"
                        (changed)="FormSettings.StopLoss.Percent = $event"
                        class="line"
                        name="sl-percent"
                        step="0.01">
          </number-input>
        </div>
      </div>
    </div>
  </div>

  <div class="exp-date-block block" *ngIf="ShowExpDateBlock">
    <span class="period-header"><l-unit
      [key]="'TradeModule_MultiOrderFormComponent_time-in-force-header'"></l-unit></span>
    <div class="period-selection">
      <custom-select [options]="dateOptions" name="period-type" [(ngModel)]="FormSettings.DateTimeMode"
                     length="5"></custom-select>
    </div>
    <div class="period-value" [hidden]="this.FormSettings.DateTimeMode != 'GTD'" (click)="onDateClick($event)">
      {{DatePreview}}
    </div>
    <div class="datetime-picker" *ngIf="ShowPicker">
      <datetime-picker alwaysOnScreen name="expiration-date" (onClickOutside)="closeDateTimePicker()"
                       (datetime)="onSelectDate($event)"></datetime-picker>
    </div>
  </div>

  <red-alert *ngIf="ErrorMessage(FormSettings.Spread, FormSettings.StopLoss.Pips) && FormSettings.StopLoss.Enabled"> <l-unit [key]="'TradeModule_MultiOrderFormComponent_pips-is-below-spread'"></l-unit> </red-alert>

  <button class="block submit-button" [ngClass]="getColorStylesButtonOpenOrders(f.invalid)" (click)="onSubmitClick()"
          [disabled]="ButtonDisabled || f.invalid">
    <span>
      <l-unit class="space" *ngIf="FormSettings.Position == 'Sell'" [key]="'TradeModule_MultiOrderFormComponent_sell-header'"></l-unit>
      <l-unit class="space" *ngIf="FormSettings.Position == 'Buy'" [key]="'TradeModule_MultiOrderFormComponent_buy-header'"></l-unit>
       {{VolumePreview}}   {{showNameForButton()}}   {{FormSettings.ShortOpeningType}}
    </span>
  </button>

  <span class=" last-messages last-messages-app-modal" [hidden]="HiddenLastMessage" (click)="messageWasRead()">
        <span class="border-last-messages" [ngClass]="getColorLastMessage()">
          <span class="last-messages-text" [ngStyle]="{'font-size.px': FontSizeLastMessage }"  [ngClass]="getColorLastMessage()"
                [textContent]="LastNotificationBody"></span>
        </span>
  </span>

</form>
