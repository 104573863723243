import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AccountCurrency} from '@common/currency/services/account.currency';

@Component({
  selector: 'app-account-currency-title',
  templateUrl: './account-currency-title.component.html',
  styleUrls: ['./account-currency-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCurrencyTitleComponent implements OnInit {
  ngOnInit() {
  }

  public get CurrencyName(): string {
    return AccountCurrency.Instance.ISOCurrencyName;
  }
}
