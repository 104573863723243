
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";

export abstract class RolloverCalcBase implements IRolloverCalc {

	public Symbol: IRolloverCalcSymbol;

	public abstract getRolloverCalcType(): RolloverCalcType;

	constructor(symbol: IRolloverCalcSymbol) {
		this.Symbol = symbol;
	}

	public CalculateInternal(signedAmount: number, openPrice: number): number {
		if (signedAmount >= 0) {
			return this.CalculateInternalLong(Math.abs(signedAmount), openPrice);
		} else {
			return this.CalculateInternalShort(Math.abs(signedAmount), openPrice);
		}
	}

	public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
		throw new Error('Not implemented');
	}

	public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
		throw new Error('Not implemented');
	}

	public Calculate(signedAmount: number, openPrice: number): number {
		return this.CalculateInternal(signedAmount, openPrice);
	}

}
