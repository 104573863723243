import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Logger} from '@common/common/utils/logging/logger';
import {Router} from '@angular/router';
import {AppConfig} from '@common/configuration/app-config';
import {Trader} from '@common/trader/models/trader';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Symbol} from '@common/symbol/models/symbol';

export abstract class AbstractModal {
  public readonly name: string;
  protected logger: Logger;


  public appName = '';

  public get Header() {
    return this._header;
  }
  public set Header(header: string) {
    this._header = header;
  }
  private _header: string;

  public close() {
    this.router.navigate(['/terminal', {outlet: {modal: null}}]).then();
    this.logger.debug('Closed');

    if (document.fullscreenElement !== null) {
      this.closeFullscreen();
    }

  }

  protected constructor(protected router: Router, protected appConfig: AppConfig) {
    this.logger = LoggerFactory.getLogger(this);

    if (this.appConfig.Settings.common !== undefined && this.appConfig.Settings.common.AppName !== undefined) {
      this.appName = this.appConfig.Settings.common.AppName;
    }
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        const chart = document.documentElement.querySelector('#tradingview_11111');
        this.openFullscreen(chart.lastElementChild);
      });
    }
  }

  openFullscreen(element: Element) {
    if (element.requestFullscreen) {
      element.requestFullscreen().then();
    }
  }

  // при открытии окна торгов, при условии что символ не определен, проверяется запись в локале для графика и передается тот символ что открыт на графике
  protected getSymbolForOpenOrders(trader: Trader, symbolStorage: SymbolStorageService) {
    if (localStorage.getItem('chartSymbolNameForTrader') !== null ) {

      const tempArray: any[] = JSON.parse(localStorage.getItem('chartSymbolNameForTrader'));
      let check = false;
      let symbol: Symbol;

      tempArray.forEach((e) => {
        if (e.traderID === trader.TraderId) {
          symbol = symbolStorage.findSymbolByNameSync(e.symbol);
          if (symbol !== undefined) {
            check = true;
          }
        }
      });

      if (!check) {
        symbol = symbolStorage.Symbols[0];
      }

      return symbol;

    } else {
      // при первом входе, если нет сохраненного последнего символа, отображается дефолтный символ
      const defaultSymbolName = this.appConfig.resolveDefaultSymbol(trader.TradeGroupId);
      return symbolStorage.Symbols.find((e: Symbol) => e.SymbolName === defaultSymbolName);
    }
  }
}
