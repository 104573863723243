import {Answer_GetItems, Answer_GetItems_Item} from '@common/communication/connection/classes.g';
import {Symbol} from '@common/symbol/models/symbol';
import {IncomingSymbol} from '@common/symbol/models/incoming-symbol';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {SymbolSettings} from '@common/symbol/models/symbol-settings';
import {SymbolMetadata} from '@common/symbol/models/symbol-metadata';
import {SymbolCategory} from '@common/symbol/models/symbol-category';
import {RolloverCalcSymbol} from '@common/symbol/models/rollover-calc-symbol';
import {IProfitCalcSymbol} from '@common/trade/utils/calculations/iprofit-calc-symbol';
import {IMarginCalcSymbol} from '@common/trade/utils/calculations/imargin-calc-symbol';
import {ProfitCalcSymbol} from '@common/symbol/models/profit-calc-symbol';
import {MarginCalcSymbol} from '@common/symbol/models/margin-calc-symbol';
import {ProfitCalcType} from '@common/trade/utils/calculations/profit-calc-type.enum';
import {MarginCalcType} from '@common/trade/utils/calculations/margin-calc-type.enum';
import {AppConfig} from '@common/configuration/app-config';
import {ISymbolRepository} from '@common/symbol/services/symbol-storage.service';
import {TradingViewItemHistoryConnectionService} from '@common/trading-view-charts/services/trading-view-item-history-connection.service';
import {RolloverCalcType} from '@common/shared/calculators/Rollover/RolloverCalcType.g';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';

export class SymbolParser {
  private logger = LoggerFactory.getLogger('SymbolParser');

  public constructor(private appConfig: AppConfig,
                     private symbolsRepository: ISymbolRepository,
                     private itemHistoryService: TradingViewItemHistoryConnectionService) {

  }

  public parseInternalSymbols(answer: Answer_GetItems): Symbol[] {
    const items = answer.Items;
    const result: Symbol[] = [];

    items.forEach((item: Answer_GetItems_Item) => {
      // console.log('item', item);
      this.logger.debug('creating item: ' + item.ItemName);
      if (String(item.Internal).toLowerCase() === 'true' || String(item.Internal) === 'True') {
        const symbol = this.createSymbol(new IncomingSymbol(item));
        result.push(symbol);
      }
    });

    return result;
  }

  public parseSymbols(answer: Answer_GetItems): Symbol[] {
    const items = answer.Items;

    const result: Symbol[] = [];
    let sortOrderSum = 0;

    items.forEach((item: Answer_GetItems_Item) => {
      this.logger.debug('creating item: ' + item.ItemName);
      if (String(item.Internal).toLowerCase() === 'false' || String(item.Internal) === 'False') {
        const symbol = this.createSymbol(new IncomingSymbol(item));
        sortOrderSum += symbol.SortOrder;
        if (symbol.SortOrder === 0) {
          symbol.SortOrder = Number.MAX_SAFE_INTEGER;
        }
        result.push(symbol);
      }
    });

    if (sortOrderSum > 0) { // Don't sort symbols if SortOrder is not set
      result.sort((a: Symbol, b: Symbol) => {
        if (a.SortOrder > b.SortOrder) {
          return 1;
        }
        if (a.SortOrder < b.SortOrder) {
          return -1;
        }
        return 0;
      });
    }

    return result;
  }


  private createSymbol(incomingSymbol: IncomingSymbol): Symbol {
    let symbolSettings: SymbolSettings;
    let symbolMetadata: SymbolMetadata;

    try {
      symbolSettings = this.createSymbolSettings(incomingSymbol);
      symbolMetadata = this.createSymbolMetadata(incomingSymbol);
    } catch (e) {
      this.logger.error('Error while creating a new symbol (id: ' + incomingSymbol.SymbolId + '): ', e);
    }

    const symbol: Symbol = new Symbol(
      incomingSymbol.SymbolId,
      incomingSymbol.MinimumSpread,
      incomingSymbol.MinVolume,
      incomingSymbol.MaxVolume,
      incomingSymbol.ContractSize,
      incomingSymbol.IsActive,
      incomingSymbol.TickSize,
      incomingSymbol.TickPrice,
      incomingSymbol.TradingStep,
      <ProfitCalcType>incomingSymbol.ProfitCalcTypeInt,
      <MarginCalcType>incomingSymbol.MarginCalcTypeInt,
      <RolloverCalcType>incomingSymbol.RolloverCalcType,
      (s: Symbol) => this.createProfitCalcSymbol(s, incomingSymbol.BCConvertSymbolId, incomingSymbol.CCConvertSymbolId),
      (s: Symbol) => this.createMarginCalcSymbol(s, incomingSymbol.BCConvertSymbolId, incomingSymbol.CCConvertSymbolId),
      (s: Symbol) => this.createRolloverCalcSymbol(s),
      symbolSettings,
      symbolMetadata,
      this.itemHistoryService,
      incomingSymbol.Percentage,
      incomingSymbol.SortOrder,
      incomingSymbol.MinTransactionFee,
      incomingSymbol.TransactionFee,
      incomingSymbol.RolloverBuy,
      incomingSymbol.RolloverSell
    );

    this.logger.debug(symbol);
    this.logger.debug(`Symbol arrived, SymbolName: ${incomingSymbol.SymbolName} (ID: ${incomingSymbol.SymbolId})`);

    return symbol;
  }

  private createSymbolSettings(incomingSymbol: IncomingSymbol): SymbolSettings {
    const symbolSettings: SymbolSettings = new SymbolSettings();
    symbolSettings.PipSize = Number(incomingSymbol.PipSize);
    symbolSettings.InitialMarginRate = Number(incomingSymbol.InitialMarginRate);
    symbolSettings.MaintenanceMarginRate = Number(incomingSymbol.MaintenanceMarginRate);
    symbolSettings.DecimalPlaces = Number(incomingSymbol.DecimalPlaces);

    let volumeDecimalPlaces = OperationsWithVolume.numberOfDigitsAfterDot(Number(incomingSymbol.TradingStep));
    if (volumeDecimalPlaces === 0) {
      volumeDecimalPlaces = 2;
    }
    symbolSettings.VolumeDecimalPlaces = volumeDecimalPlaces;

    // const trading = this.appConfig.Settings.trading;
    //
    // if (trading) {
    //   if (trading.DefaultVolumeDecimalPlaces) {
    //     symbolSettings.VolumeDecimalPlaces = Number(this.appConfig.Settings.trading.DefaultVolumeDecimalPlaces);
    //   }
    //
    //   if (trading.SymbolOverrides) {
    //     const overrides = this.appConfig.Settings.trading.SymbolOverrides.find(o => o.symbolName === incomingSymbol.SymbolName);
    //
    //     if (overrides && overrides.overrides) {
    //       if (overrides.overrides.volumeDecimalPlaces) {
    //         symbolSettings.VolumeDecimalPlaces = Number(overrides.overrides.volumeDecimalPlaces);
    //       }
    //       // Here you can add more overrides (don't forget to update IAppConfig, config.dev.json and config.prod.json!)
    //     }
    //   }
    // }

    return symbolSettings;
  }

  private createSymbolMetadata(incomingSymbol: IncomingSymbol): SymbolMetadata {
    const metadata = new SymbolMetadata(
      incomingSymbol.SymbolName,
      incomingSymbol.LongName,
      incomingSymbol.BaseCurrency,
      incomingSymbol.CounterCurrency,
      <SymbolCategory>incomingSymbol.SymbolCategoryInt,
      incomingSymbol.DecimalPlaces,
      incomingSymbol.ExternalName,
      incomingSymbol.SymbolCategoryName);

    return metadata;
  }

  private createRolloverCalcSymbol(symbol: Symbol): RolloverCalcSymbol | null {
    return new RolloverCalcSymbol(symbol);
  }

  private getOrCreateProfitCalcSymbol(symbolId: number | null): IProfitCalcSymbol | null {
    if (symbolId && symbolId !== 0) {
      const symbol = this.symbolsRepository.findSymbolById(symbolId);

      if (!symbol) {
        throw new Error('Invalid profit calc symbol id: ' + symbolId);
      }

      return symbol.ProfitCalcSymbol;
    }

    return null;
  }

  private getOrCreateMarginCalcSymbol(symbolId: number | null): IMarginCalcSymbol | null {
    if (symbolId && symbolId !== 0) {
      const symbol = this.symbolsRepository.findSymbolById(symbolId);

      if (!symbol) {
        throw new Error('Invalid margin calc symbol id: ' + symbolId);
      }

      return symbol.MarginCalcSymbol;
    }

    return null;
  }

  private createProfitCalcSymbol(symbol: Symbol, bcSymbolId: number, ccSymbolId: number): ProfitCalcSymbol | null {
    const bcProfit: IProfitCalcSymbol = this.getOrCreateProfitCalcSymbol(bcSymbolId);
    const ccProfit: IProfitCalcSymbol = this.getOrCreateProfitCalcSymbol(ccSymbolId);

    return new ProfitCalcSymbol(symbol, bcProfit, ccProfit);
  }

  private createMarginCalcSymbol(symbol: Symbol, bcSymbolId: number, ccSymbolId: number): MarginCalcSymbol | null {
    const bcMargin: IMarginCalcSymbol = this.getOrCreateMarginCalcSymbol(bcSymbolId);
    const ccMargin: IMarginCalcSymbol = this.getOrCreateMarginCalcSymbol(ccSymbolId);

    return new MarginCalcSymbol(symbol, bcMargin, ccMargin);
  }
}
