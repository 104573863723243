import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DateUtils} from '@common/common/utils/date-utils';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  public weeks = [];

  private dateSetEvent = new EventEmitter<Date>();

  private _selectedDate: Date;

  private readonly today = this.getToday();

  private currentMonth = this.Today.getMonth();
  private currentYear = this.Today.getFullYear();

  private set selectedDate(v: Date){
    this._selectedDate = v;
    this.dateSetEvent.emit(v);
  }
  private get selectedDate(): Date {
    return this._selectedDate;
  }

  @Output()
  get date(): Observable<Date>{
    return this.dateSetEvent.asObservable();
  }

  constructor() { }

  public isToday(date: Date): boolean {
    return this.Today.valueOf() == date.valueOf();
  }

  public isSelected(date: Date): boolean {
    return this.selectedDate.valueOf() == date.valueOf();
  }


  public generateFor(date: Date) {
    const weeks = [];
    let dates = [];

    date = new Date(date);
    date.setDate(1);

    const firstDay = (date.getDay() + 6) % 7;

    for(let i = firstDay; i > 0; i--){
      dates.push(DateUtils.addDays(date, -i));
    }

    dates.push(date);

    for(let i = firstDay + 1; i < 42; i++) {
      if (i % 7 == 0){
        weeks.push(dates);

        dates = [];
      }

      dates.push(DateUtils.addDays(date, i - firstDay));
    }

    weeks.push(dates);

    this.weeks = weeks;
  }

  public onDateClick(date: Date){
    this.selectDate(date);
  }

  public selectNextMonth(){
    if(this.currentMonth == 11){
      this.currentYear += 1;
      this.currentMonth = 0;
    }
    else {
      this.currentMonth += 1;
    }

    this.generateFor(new Date(this.currentYear, this.currentMonth, 1));
  }
  public selectPrevMonth(){
    if(this.currentMonth == 0){
      this.currentYear -= 1;
      this.currentMonth = 11;
    }
    else {
      this.currentMonth -= 1;
    }

    this.generateFor(new Date(this.currentYear, this.currentMonth, 1));
  }

  public onPrevMonthClick() {
    this.selectPrevMonth();

    this.selectedDate = new Date(this.currentYear, this.currentMonth, this.selectedDate.getDate());
  }
  public onNextMonthClick() {
    this.selectNextMonth();

    this.selectedDate = new Date(this.currentYear, this.currentMonth, this.selectedDate.getDate());
  }

  public lessThenToday(date: Date): boolean {
    return date < this.today;
  }

  public isNextMonthDate(date: Date): boolean {
    if(this.selectedDate.getFullYear() < date.getFullYear()){
      return true
    }
    else if(this.selectedDate.getFullYear() > date.getFullYear()){
      return false
    }
    if(this.selectedDate.getMonth() < date.getMonth()) {
      return true
    }
    else return false;
  }
  public isPrevMonthDate(date: Date): boolean {
    if(this.selectedDate.getFullYear() > date.getFullYear()){
      return true
    }
    if(this.selectedDate.getFullYear() < date.getFullYear()){
      return false;
    }
    if(this.selectedDate.getMonth() > date.getMonth()) {
      return true
    }
    else return false;
  }

  private selectDate(date: Date){
    if(this.lessThenToday(date)) return;

    if(this.isPrevMonthDate(date)){
      this.selectPrevMonth();
    }
    else if(this.isNextMonthDate(date)){
      this.selectNextMonth();
    }

    this.selectedDate = date;
  }

  ngOnInit() {
    this.selectedDate = DateUtils.addDays(this.Today, 1);
    this.generateFor(this.Today);
  }

  public get DayPreview(): string {
    return  moment(this.selectedDate).format('DD');
  }

  public get MonthAndYearPreview(): string {
    return  moment(this.selectedDate).format('MMM \'YY');
  }

  private getToday(): Date {
    const today = OperationsWithDate.getDateUTC();

    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();

    return new Date(year, month, day, 0, 0, 0, 0);
  }

  public get Today(): Date {
    return this.today;
  }
}
