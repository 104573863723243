import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";


export class USDCC_USDBC extends ProfitCalcBase {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.USDCC_USDBC;
	}
        
	public CalculateInternal(signedAmount: number, openPrice: number, closePrice: number): number {
		
	if (!this.Symbol.getCCConvertSymbol().getIsValidQuote() || ((this.Symbol.getCCConvertSymbol().getAsk()+this.Symbol.getCCConvertSymbol().getBid()) / 2) == 0 )
			{
				return 0;
			}
				return signedAmount * (closePrice - openPrice) / ((this.Symbol.getCCConvertSymbol().getAsk() + this.Symbol.getCCConvertSymbol().getBid()) / 2);
}

	public CalculateInternalWithConvertion(signedAmount: number, openPrice: number, closePrice: number, conversion: number): number {
		
	if (!this.Symbol.getCCConvertSymbol().getIsValidQuote())
				{
				return 0;
			}
				return signedAmount * (closePrice - openPrice) * conversion;
}

	public CalculatePriceAtProfitInternal(signedAmount: number, openPrice: number, profit: number): number {
		
	if (!this.Symbol.getCCConvertSymbol().getIsValidQuote() || signedAmount == 0 )
				{
				return 0;
			}
				return openPrice + (profit * ((this.Symbol.getCCConvertSymbol().getAsk() + this.Symbol.getCCConvertSymbol().getBid()) / 2) / signedAmount);
}

	
}