import {Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
import {Time} from '@common/shared/components/time-picker/time-picker.component';

import * as moment from 'moment';
import {Observable} from 'rxjs';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimePickerComponent),
    multi: true
  }]
})
export class DatetimePickerComponent extends ValueAccessorBase<Date> implements OnInit {
  @Output()
  private get datetime(): Observable<Date> {
    return this.dateSetted.asObservable();
  }

  private dateSetted: EventEmitter<Date> = new EventEmitter<Date>();

  private date: Date;
  private time: Time;

  public get ButtonText(): string {
    if(!this.date || !this.time) return '';

    return`Set GTD ${moment(this.selected).format('DD MMM \'YY, HH:mm')}`;
  }

  private selected: Date;

  public set Date(v: Date) {
    this.date = v;
    this.updateTime();
  }

  public set Time(v: Time){
    this.time = v;
    this.updateTime();
  }

  constructor() {
    super()
  }

  ngOnInit() {
    this.updateTime();
  }

  private updateTime(){
    if(!this.date || !this.time) return;
    this.selected = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), this.time.hours, this.time.minutes)
  }

  public onClick(){
    this.value = this.selected;
    this.dateSetted.emit(this.value);
  }
}
