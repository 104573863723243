import {TradeStateSnapshot} from './trade-state-snapshot';
import {TradeCalculateObject} from '../utils/calculations/trade-calculate-object';
import {
  AbstractTradeInternalState,
  CloseTradeState,
  DeletedPendingTradeState,
  OpenTradeState,
  PendingTradeState
} from '../utils/states/abstract-trade-internal-state';
import {StateType} from './trade-state-enum';
import {JsonProperty} from '@common/shared/utils/json-decorators';

export class TradeSnapshotStorage {

  @JsonProperty(TradeStateSnapshot, true)
  private snapshots: TradeStateSnapshot[] = [];

  @JsonProperty(TradeCalculateObject)
  private calculationObject: TradeCalculateObject = new TradeCalculateObject();

  private lastState: AbstractTradeInternalState = undefined;

  public constructor(calc?: TradeCalculateObject){
    if (calc) {
      this.calculationObject = calc;
    }
  }

  public push(snapshot: TradeStateSnapshot): AbstractTradeInternalState {
    if (this.snapshots.filter(snp => snp.StateId === snapshot.StateId).length > 0) {
      return this.getStateObject(this.snapshots[this.snapshots.length - 1]);
    }

    this.snapshots.push(snapshot);
    this.calculationObject.recalculate(snapshot);

    this.lastState = this.getStateObject(snapshot);

    return this.lastState;
  }

  private getStateObject(snapshot: TradeStateSnapshot): AbstractTradeInternalState {
    switch (snapshot.StateType) {
      case StateType.Closed: {
        return new CloseTradeState(this.calculationObject, snapshot.DealId);
      }
      case StateType.DeletePendingOrder: {
        return new DeletedPendingTradeState(this.calculationObject, snapshot.DealId);
      }
      case StateType.OpenPendingOrder: {
        return new PendingTradeState(this.calculationObject, snapshot.DealId);
      }
      case StateType.UpdatePendingOrder: {
        return new PendingTradeState(this.calculationObject, snapshot.DealId);
      }
      case StateType.ResumePendingOrder: {
        return new OpenTradeState(this.calculationObject, snapshot.DealId);
      }
      default: {
        if (this.lastState) {
          return this.lastState;
        } else {
          return new OpenTradeState(this.calculationObject, snapshot.DealId);
        }
      }
    }

    if (snapshot.StateType !== StateType.Closed && snapshot.StateType !== StateType.DeletePendingOrder) {
      return new OpenTradeState(this.calculationObject, snapshot.DealId);
    } else {
      return new CloseTradeState(this.calculationObject, snapshot.DealId);
    }
  }

  public get States(): TradeStateSnapshot[] {
    return this.snapshots;
  }
}
