import {Injectable} from '@angular/core';
import {Trader} from '@common/trader/models/trader';
import {Settings} from '@common/trader/models/settings';
import {LocalStorageService, SettingsStorage} from '@common/auth/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsManagerService {
  private readonly settingsDictionary: SettingsStorage;

  constructor(private localStorage: LocalStorageService) {
    this.settingsDictionary = localStorage.getSettingsStorage();
  }

  loadSettings(trader: Trader): Settings {
    if (this.settingsDictionary.has(trader.TraderId)) {
      return this.settingsDictionary.get(trader.TraderId);
    }
  }

  saveSettings(trader: Trader, settings: Settings) {
    this.settingsDictionary.set(trader.TraderId, settings);

    this.localStorage.saveSettingsStorage(this.settingsDictionary);
  }
}
