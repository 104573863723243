import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@common/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {LocaleModule} from '@common/locale/locale.module';
import {ChartingModule as CommonChatModule} from '@common/charting/charting.module';
import {SymbolComponent} from '@common/quote-board/components/symbol/symbol.component';
import {QuotesComponent} from '@common/quote-board/components/quotes/quotes.component';
import {RouterModule} from '@angular/router';
import {SymbolModule} from '@common/symbol/symbol.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SymbolPriceComponent} from '@common/quote-board/components/symbol-price/symbol-price.component';
import {SpreadComponent} from '@common/quote-board/components/spread/spread.component';
import {ThemeModule} from '@common/shared/services/theme';
import {MatGridListModule} from '@angular/material/grid-list';

@NgModule({
  declarations: [
    SymbolComponent,
    QuotesComponent,
    SymbolPriceComponent,
    SpreadComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        LocaleModule,
        CommonChatModule,
        RouterModule,
        SymbolModule,
        DragDropModule,
        ThemeModule,
        MatGridListModule
    ],
    exports: [
        QuotesComponent,
        SpreadComponent,
    ],
  providers: [
  ]
})
export class QuoteBoardModule { }
