import {Symbol} from '@common/symbol/models/symbol';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';

export interface Position {index: number; symbolId: number; }
export type ViewMode = 'All' | 'Favorites';

export class SymbolsSettingsStorage {
  public showMetals: boolean | undefined = true;
  public showCFD: boolean | undefined = true;
  public showForex: boolean | undefined = true;
  public showCryptos: boolean | undefined = true;
  public showEqities: boolean | undefined = true;
  public showEnergies: boolean | undefined = true;
  public showFutures: boolean | undefined = true;
  public showIndices: boolean | undefined = true;
  public symbolsOrder: Array<Position>;

  public viewMode: ViewMode = 'All';
}

export class SymbolsSettings {
  private storage = new SymbolsSettingsStorage();

  /**
   * @deprecated
   * Used only for saving at localstorage
   */
  public get SettingsStorage(): SymbolsSettingsStorage {
    return this.storage;
  }
  public set SettingsStorage(v: SymbolsSettingsStorage) {
    this.storage = v;
  }

  public get ViewMode(): ViewMode {
    return this.storage.viewMode;
  }
  public set ViewMode(v: ViewMode) {
    this.storage.viewMode = v;
  }

  private settingsChanged = new SmartEmitter<void>();

  public constructor(symbols?: Symbol[]) {
    if(symbols) {
      this.storage.symbolsOrder = symbols.map<Position>((item, index) => {
        return {
          index: index,
          symbolId: item.SymbolId
        }
      })
    }
  }

  public get ShowMetals(): boolean {
    return this.storage.showMetals;
  }

  public set ShowMetals(v: boolean) {
    this.storage.showMetals = v;
    this.settingsChanged.emit();
  }

  public get ShowCFD(): boolean {
    return this.storage.showCFD;
  }

  public set ShowCFD(v: boolean) {
    this.storage.showCFD = v;
    this.settingsChanged.emit();
  }

  public get ShowForex(): boolean {
    return this.storage.showForex;
  }

  public set ShowForex(v: boolean) {
    this.storage.showForex = v;
    this.settingsChanged.emit();
  }

  public get ShowCryptos(): boolean {
    return this.storage.showCryptos;
  }

  public set ShowCryptos(v: boolean) {
    this.storage.showCryptos = v;
    this.settingsChanged.emit();
  }

  public get ShowEquities(): boolean {
    return this.storage.showEqities;
  }

  public set ShowEquities(v: boolean) {
    this.storage.showEqities = v;
    this.settingsChanged.emit();
  }

  public get ShowEnergies(): boolean {
    return this.storage.showEnergies;
  }

  public set ShowEnergies(v: boolean) {
    this.storage.showEnergies = v;
    this.settingsChanged.emit();
  }

  public get ShowFutures(): boolean {
    return this.storage.showFutures;
  }

  public set ShowFutures(v: boolean) {
    this.storage.showFutures = v;
    this.settingsChanged.emit();
  }

  public get ShowIndices(): boolean {
    return this.storage.showIndices;
  }

  public set ShowIndices(v: boolean) {
    this.storage.showIndices = v;
    this.settingsChanged.emit();
  }

  public get SymbolsOrder(): Position[] {
    return this.storage.symbolsOrder;
  }

  public get SettingChanged(): ISmartObserver<void> {
    return this.settingsChanged;
  }


  public swapSymbols(firstId: number, secondId: number){
    const fInd = this.storage.symbolsOrder.findIndex(val => val.symbolId == firstId);
    const sInd = this.storage.symbolsOrder.findIndex(val => val.symbolId == secondId);

    if(fInd < sInd){
      for(let i = fInd; i <= sInd - 1; i++){
        const buff = this.storage.symbolsOrder[i];
        this.storage.symbolsOrder[i] = this.storage.symbolsOrder[i + 1];
        this.storage.symbolsOrder[i + 1] = buff;
      }
    }
    if(fInd > sInd){
      for(let i = fInd; i > sInd; i--){
        const buff = this.storage.symbolsOrder[i];
        this.storage.symbolsOrder[i] = this.storage.symbolsOrder[i - 1];
        this.storage.symbolsOrder[i - 1] = buff;
      }
    }

    this.settingsChanged.emit();
  }
}
