<div class="time-preview">
  <span [class.active]="Mode == 'hours'" (click)="setHoursMode()">
    {{format(Hour)}}
  </span>
  :
  <span [class.active]="Mode == 'minutes'" (click)="setMinutesMode()">
    {{format(Minute)}}
  </span>
</div>
<div class="time-input">
  <div class="background">
    <div class="clock">
      <div class="am" *ngIf="Mode == 'hours'" (mousedown)="onMouseDown()">
        <div *ngFor="let hour of AMHours" (mouseover)="selectHour(hour)" (mouseup)="selectHourByClick(hour)">
          <div class="line" [attr.hidden]="!isHourSelected(hour) ? true : null">
            <div class="circle"></div>
          </div>
          <span>{{hour}}</span>
        </div>
      </div>
      <div class="pm" *ngIf="Mode == 'hours'" (mousedown)="onMouseDown()">
        <div *ngFor="let hour of PMHours" (mouseover)="selectHour(hour)" (mouseup)="selectHourByClick(hour)">
          <div class="line" [attr.hidden]="!isHourSelected(hour) ? true : null">
            <div class="circle"></div>
          </div>
          <span>{{hour}}</span>
        </div>
      </div>
      <div class="minutes" *ngIf="Mode == 'minutes'" (mousedown)="onMouseDown()">
        <div *ngFor="let minute of Minutes;let i=index" (mouseover)="selectMinute(minute)" (mouseup)="selectMinuteByClick(minute)">
          <div class="line" [attr.hidden]="!isMinuteSelected(minute) ? true : null">
            <div class="circle"></div>
          </div>
          <span *ngIf="(i + 1) % 5 == 0">{{minute}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
