import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {SymbolSelectService} from '@common/shared/services/SymbolSelect/symbol-select.service';
import {Symbol} from '@common/symbol/models/symbol';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ViewMode} from '@common/shared/services/SymbolSelect/symbol-settings';
import {VisibilitySyncService} from '@common/shared/services/visibility-sync.service';
import {ChartService} from '@common/charting/services/chart.service';
import {Trader} from '@common/trader/models/trader';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {unique} from 'ng-packagr/lib/utils/array';
import {AppConfig} from '@common/configuration/app-config';
import {Environment} from '@common/environment';
import {SymbolSearch} from '@common/shared/services/SymbolSelect/symbol-search';
import {DebugService} from '@common/shared/services/debug.service';
import { Subscription } from 'rxjs';
import {translate, TranslatorService} from '@common/locale/servises/translator.service';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';

interface Category {
  nameCategory: string;
  show: boolean;
}

interface SymbolsIntoCategories {
  categories: string;
  arraySymbols: Symbol[];
}

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css'],
  providers: [VisibilitySyncService]
})
export class QuotesComponent implements OnInit, OnDestroy, AfterViewInit {

  public visibleSymbols: Symbol[];
  private tempVisibleSymbols: Symbol[];
  private _symbolsForCategory: Symbol[];
  public symbolSearch = new SymbolSearch();
  private _settingsOpened = false;
  private _searchOpened = false;
  private _hiddenSearchInput = true;
  private _uniqueCategories = [];
  private _settingCategory = [];
  private showVisibleSymbols = true;
  private symbolNotActive: Subscription;
  private _symbolsIntoCategories: SymbolsIntoCategories[] = [];
  private totalSymbols: number;
  private receivedSymbols: number;
  private _isShowExpectations = false;

  private subscribeEventChangeFavoriteSymbols: Subscription;

  private _placeholderSearch: string;

  private localeChangedSubscribe: ISmartSubscription;

  constructor(private chartService: ChartService,
              private symbolSelector: SymbolSelectService,
              private vSync: VisibilitySyncService,
              private detector: ChangeDetectorRef,
              private trader: Trader,
              private localStorage: LocalStorageService,
              private readonly tradeStorage: TradeStorage,
              private appConfig: AppConfig,
              private debugService: DebugService,
              private translationService: TranslatorService) {
    this.filterSymbols();
    this.getSymbolsForCategory();
    this.createPlaceholderSearch();

    this.symbolSelector.SymbolChange.subscribe(() => {
       this.filterSymbols();
      this.detector.detectChanges();
    });

    this.symbolSelector.SettingsChanged.subscribe(() => {
      this.filterSymbols();
      this.detector.detectChanges();
    });

    this.symbolSearch.PatternChanged.subscribe(() => {
      if (this.symbolSearch.Pattern.length === 0) {
        this.changeVisibleSymbols(true, true);
      } else {
        this.filterSymbols();
        this.visibleSymbols = this.tempVisibleSymbols;
        this.divisionOfSymbolsIntoCategories(this.visibleSymbols);
        this.detector.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    this.vSync.markFrameReady();
  }

  ngOnInit(): void {
    this.visibleSymbols = this.tempVisibleSymbols;

    console.log('Symbols count', this.visibleSymbols.length);

    this.uniqueCategories();
    this.settingCategory();
    this.changeVisibleSymbols(true, false);
    this.setTimeOutVisibleSymbols();

    this.symbolNotActive = this.tradeStorage.symbolNotActive.subscribe(() => {
      this.filterSymbols();
      this.changeVisibleSymbols(true, false);
    });

    this.subscribeEventChangeFavoriteSymbols = this.chartService.EventChangeFavoriteSymbols.subscribe(() => {
      this.addInFavorite();
    });

    this.localeChangedSubscribe = this.translationService.LocaleChanged.subscribe(() => this.createPlaceholderSearch());
  }

  ngOnDestroy(): void {
    this.detector.detach();
    if (this.symbolNotActive !== undefined && !this.symbolNotActive.closed) {
      this.symbolNotActive.unsubscribe();
    }

    if (this.subscribeEventChangeFavoriteSymbols !== undefined && !this.subscribeEventChangeFavoriteSymbols.closed) {
      this.subscribeEventChangeFavoriteSymbols.unsubscribe();
    }

    if (this.localeChangedSubscribe) {
      this.localeChangedSubscribe.unsubscribe();
      this.localeChangedSubscribe = undefined;
    }
  }

  public viewFavorites() {
    if (this.ViewMode !== 'Favorites') {
      this.ViewMode = 'Favorites';
      this.operationsForGeneratingListOfSymbols();
    }
  }

  public viewAllSymbols() {
    if (this.ViewMode !== 'All') {
      this.ViewMode = 'All';
      this.operationsForGeneratingListOfSymbols();
    }
  }

  private operationsForGeneratingListOfSymbols() {
    this.filterSymbols();
    this.getSymbolsForCategory();
    this.uniqueCategories();
    this.settingCategory();
    this.changeVisibleSymbols(true, false);
    this.setTimeOutVisibleSymbols();
  }

  public async openChart(symbolName: string) {
    await this.chartService.setSymbol(symbolName);
  }

  public filterSymbols() {
    const tempArray = this.symbolSelector.getSymbols(this.symbolSearch.Pattern);
    this.tempVisibleSymbols = this.symbolSelector.getSymbolsArray(tempArray);
  }

  public getSymbolsForCategory() {
    const tempArray = this.symbolSelector.getSymbols('');
    this._symbolsForCategory = this.symbolSelector.getSymbolsArray(tempArray);
  }

  public trackBySymbolId(index: any, item: Symbol): number {
    if (!item) { return null; } else {
      return item.SymbolId;
    }
  }

  public drop(event: CdkDragDrop<Symbol[]>) {
    const prevId = this.VisibleSymbols[event.previousIndex].SymbolId;
    const currId = this.VisibleSymbols[event.currentIndex].SymbolId;
    this.symbolSelector.swapSymbols(prevId, currId);
  }

  public toggleSettings() {
    // если массив для чек боксов пустой то выпадающее окно чек боксов не открывается
    if (this._uniqueCategories.length !== 0) {
      this._settingsOpened = !this._settingsOpened;
    }
  }

  public closeSettings() {
    this._settingsOpened = false;
  }

  public toggleSearch() {
    if (this._searchOpened) {
      this.symbolSearch.Pattern = '';
      this._searchOpened = false;
      setTimeout(() => this._hiddenSearchInput = true, 700);
    } else {
      this._searchOpened = true;
      this._hiddenSearchInput = false;
    }
  }

  // при закрытии инпута поиска иногда появятся артефакт
  // (https://forexdevelopment.myjetbrains.com/youtrack/issue/CONDOR-282/Search-doesnt-disappear-fully)
  // и чтоб его скрыт, инпуту присваивается дополнительный клас меняющий цвет бэкграунд а и бордеро
  public get HiddenSearchInput() {
    return this._hiddenSearchInput;
  }

  public tryCloseSearch() {
    if (this.symbolSearch.Pattern !== '') { return; }
    this._searchOpened = false;
  }

  get Delay(): number {
    return window.outerWidth <= 768 ? 350 : 50;
  }

  get VisibleSymbols(): Symbol[] {
    if (this.visibleSymbols !== undefined) {
      return this.visibleSymbols;
    } else {
      return [];
    }
  }

  get VisibleSymbolsWithCategory(): SymbolsIntoCategories[] {
    if (this._symbolsIntoCategories.length > 0 ) {
      return this._symbolsIntoCategories;
    } else {
      return [];
    }
  }

  public get SettingsOpened(): boolean {
    return this._settingsOpened;
  }
  public get SearchOpened(): boolean {
    return this._searchOpened;
  }

  public get ViewMode(): ViewMode {
    return this.symbolSelector.ViewMode;
  }
  public set ViewMode(v: ViewMode) {
    this.symbolSelector.ViewMode = v;
  }

  // возвращает массив имен всех категорий для группы
  get Category(): any[] {
    return this._uniqueCategories;
  }

  // возвращает булевое значение для чекбоксов категорий символов
  public ShowCategory(category: string): boolean {
    const findItem = this._settingCategory.find(i => i.nameCategory === category);
    if (findItem === undefined ) {
      return false;
    }
    return findItem.show;
  }

  // при переключении чекбокса меняет значение в массиве категории символов и изменяет массив символов для отображения
  public ChangeShowCategory(category: string) {
    this._settingCategory.forEach((e) => {
      if (category === e.nameCategory) {
        e.show = !e.show;
        this.setTimeOutVisibleSymbols();
        this.changeVisibleSymbols(e.show, false, category);
        this.localStorage.saveSymbolSettingsAdaptiveStorage(this.trader.TraderName, this._settingCategory);
      }
    });
  }

  // проверяет первый ли вход для проверки массива категории символов в локал стородже
  private settingCategory() {
    const localStorageSymbolSettingsAdaptive = this.localStorage.getSymbolSettingsAdaptiveStorage(this.trader.TraderName);

    if (localStorageSymbolSettingsAdaptive === undefined || localStorageSymbolSettingsAdaptive.length === 0) {
      this.createSettingCategory();
    } else {
      this._settingCategory = localStorageSymbolSettingsAdaptive;

      if (this._uniqueCategories === undefined && !this.checkingForSamenessNameCategory()) {
        this.createSettingCategory();
      }
    }
  }

  // добавил дополнительная проверка на идентичность категории символов, что приходить из локал стороджа и что создается при переборе символов
  // по этой задаче https://forexdevelopment.myjetbrains.com/youtrack/issue/CONDOR-383/symbol-filter-is-not-behaving-correctly
  private checkingForSamenessNameCategory(): boolean {
    const settingCategoryArray = [];
    this._settingCategory.forEach((e) => {
      settingCategoryArray.push(e.nameCategory);
    });
    if (settingCategoryArray.length === this._uniqueCategories.length) {
      const all = [...settingCategoryArray, ...this._uniqueCategories];
      return settingCategoryArray.length === unique(all).length;
    } else {
      return false;
    }
  }

  // создает массив категории символов со значениями для чекбокса
  private createSettingCategory() {
    this._settingCategory = [];
    this._uniqueCategories.forEach((e) => {
      const category: Category = {
        nameCategory: e,
        show: true,
      };
      this._settingCategory.push(category);
    });
    this.localStorage.saveSymbolSettingsAdaptiveStorage(this.trader.TraderName, this._settingCategory);
  }

  // создает уникальный массив имен для категорий символов торговой группы
  private uniqueCategories() {
    this._uniqueCategories = [];
    this._symbolsForCategory.forEach((e: Symbol) => {
      if (!this._uniqueCategories.includes(e.CategoryName)) {
        this._uniqueCategories.push(e.CategoryName);
      }
    });
  }

  public addInFavorite() {
    if (this.ViewMode === 'Favorites') {
      this.getSymbolsForCategory();
      this.uniqueCategories();
      this.settingCategory();
      this.changeVisibleSymbols(true, true);
    }
  }

  // метод показывает актуальное значение массива символов с учетом всех фильтров
  public changeVisibleSymbols(visible: boolean, isNeedFilterSymbols: boolean, category?: string) {
    if (!visible) {
      this.visibleSymbols = this.visibleSymbols.filter((e) => e.CategoryName !== category);
    } else {
      if (isNeedFilterSymbols) {
        this.filterSymbols();
      }
      const symbolArray = this.tempVisibleSymbols;
      let findSymbolArray = [];
      this._settingCategory.forEach((e) => {
        if (e.show === true) {
          const findCategorySymbols = symbolArray.filter((s) => s.CategoryName === e.nameCategory);
          findSymbolArray = [...findSymbolArray, ...findCategorySymbols];
        }
      });
      this.visibleSymbols = findSymbolArray;
    }

    if (this.getShowSymbolsWithCategory()) {
      this.divisionOfSymbolsIntoCategories(this.visibleSymbols);
    }
  }

  // при переключении на фаворитов и обратно, иногда происходят проблема со своевременной передачей данных,
  // чтобы этого избежать выставлена задержка в отображении
  private setTimeOutVisibleSymbols() {
    this.showVisibleSymbols = false;
    setTimeout(() => this.showVisibleSymbols = true, 300);
  }

  public getShowVisibleSymbols() {
    return this.showVisibleSymbols;
  }

  public getShowSymbolsWithCategory(): boolean {
    if (this.appConfig.Settings.showSymbolWithCategory !== undefined) {
      return this.appConfig.Settings.showSymbolWithCategory;
    }
    return false;
  }

  private divisionOfSymbolsIntoCategories(array: Symbol[]) {
    this._symbolsIntoCategories = [];
    const s = [];

    this._isShowExpectations = true;

    this.totalSymbols = array.length;
    this.receivedSymbols = 0;

    array.forEach((e: Symbol) => {
      if (!s.includes(e.CategoryName)) {
        s.push(e.CategoryName);
      }
    });

    for (let i = 0; i < s.length; i++) {
      const arr = array.filter((e: Symbol) => e.CategoryName.toLowerCase() === s[i].toLowerCase());

      const item: SymbolsIntoCategories = {
        categories: s[i],
        arraySymbols: arr,
      };
      this._symbolsIntoCategories.push(item);
    }
  }

  get SearchBlockMarginLeft() {
     return Environment.IsMobileVersion;
  }

  public isZeTradex() {
    return this.appConfig.Settings.common.AppName === 'Zetradex';

  }

  public addClassForZetradex() {
    return this.isZeTradex() && !Environment.IsMobileVersion;
  }

  public openDebugMode() {
    if ( this.debugService.IsOpenDebugMode(this.symbolSearch.Pattern)) {
      this.debugService.toggleHiddenBtnDebugMode();
    }
  }

  public isShowExpectations(): boolean {
    return this._isShowExpectations;
  }

  public countSymbols() {
    this.receivedSymbols++;

    if (this.totalSymbols == this.receivedSymbols) {
      this._isShowExpectations = false;
    }
  }

  private createPlaceholderSearch(): void {
    this._placeholderSearch = translate('Quotes_QuotesComponent_SearchForAssets');
  }

  public get PlaceholderSearch(): string {
    return this._placeholderSearch;
  }

  public translateSymbolsCategories(category: string, isToUpperCase: boolean = false): string {
    let categoryTranslate = translate(`Quotes_QuotesComponent_${category}`);
    if (categoryTranslate === undefined) {
      categoryTranslate = category;
    }
    return isToUpperCase ? categoryTranslate.toUpperCase() : categoryTranslate;
  }

}
