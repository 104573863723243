import {Trade} from '../models/trade';
import {Message_OrderState} from '../../communication/connection/classes.g';
import {TradeStateSnapshot} from '../models/trade-state-snapshot';
import {SymbolStorageService} from '../../symbol/services/symbol-storage.service';

export class TradeParser {
  private trades: Map<number, Trade> = new Map<number, Trade>();

  public constructor(private symbols: SymbolStorageService) {

  }

  public parseTrades(messageStates: Message_OrderState[]): Trade[] {
    if (!messageStates) { return; }

    const states = messageStates.sort((a, b) => {
      if (Number(a.StateID) < Number(b.StateID)) {
        return -1;
      } else if (Number(a.StateID) === Number(b.StateID)) {
        return 0;
      } else { return 1; }
    });

    states.forEach(item => {
      const state = new TradeStateSnapshot();
      state.loadFromItem(item, this.symbols);

      const trade = this.getTrade(state.OrderId);
      trade.updateState(state);
    });

    const result = Array.from(this.trades.values());

    this.trades.clear();

    return result;
  }

  public parseStates(messageStates: Message_OrderState[]): TradeStateSnapshot[] {
    const states = messageStates.sort((a, b) => {
      if (Number(a.StateID) < Number(b.StateID)) {
        return -1;
      } else if (Number(a.StateID) === Number(b.StateID)) {
        return 0;
      } else { return 1; }
    });

    const result: TradeStateSnapshot[] = [];

    states.forEach(item => {
      const state = new TradeStateSnapshot();
      state.loadFromItem(item, this.symbols);

      result.push(state);
    });

    return result;
  }

  private getTrade(id: number) {
    let result = this.trades.get(id);

    if (!result) {
      result = new Trade();
      this.trades.set(id, result);
    }

    return result;
  }
}
