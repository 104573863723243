<div class="symbol-row symbol-search-row" *ngIf="!IsZeTradex; else zetradex">

  <div class="symbol-name" (click)="setSymbolTo()">
    <span>{{ SymbolName }}</span>
  </div>

  <div class="symbol-description" (click)="setSymbolTo()">
    <span>
      {{ SymbolDescription }}
    </span>
  </div>

  <div class="category-favorite-box">
    <div class="symbol-category">
      {{ translateSymbolsCategories(SymbolCategory)  }}
    </div>

    <div class="button-group">
      <div class="column add-to-favorite icon" [class.active]="IsFavorite" (click)="toggleFavorite($event)"></div>
    </div>
  </div>


</div>

<ng-template #zetradex>

  <div class="symbol-row symbol-search-row">

    <div class="symbol-name" (click)="setSymbolTo()">
      <div class="symbol-icon search-symbol-icon-mr">
        <img *ngIf="SymbolIconPath !== undefined; else noImgIcon"  class="symbol-icon-quote" src="{{SymbolIconPath}}">
        <ng-template #noImgIcon>
          <div class="symbol-icon-quote text-icon">
            <span [textContent]="TextIcon"></span>
          </div>
        </ng-template>
      </div>

      <div class="symbol-name symbol-name-witch-icon">
        <app-symbol-name *ngIf="IsZeTradex" [symbolId]="SymbolId"></app-symbol-name>
      </div>
    </div>

    <div class="symbol-description" (click)="setSymbolTo()">
      <span>
        {{ SymbolDescription }}
      </span>
    </div>

    <div class="category-favorite-box">
      <div class="symbol-category">
        {{ translateSymbolsCategories(SymbolCategory) }}
      </div>

      <div class="button-group">
        <div class="z-stars" [class.z-active]="IsFavorite" (click)="toggleFavorite($event)"></div>
      </div>
    </div>

  </div>

</ng-template>


