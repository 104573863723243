import { Theme } from '../symbols';

export const lightTheme: Theme = {
  name: 'light',
  category: 'light',
  properties: {
    '--background': 'rgba(210, 213, 219, 0.2)',
    '--background-light': '#FFFFFF',
    '--text-color': '#15151B',
    '--border-color-grey': '#878787',
    '--border-chart-color': '#DFDDDD',
    '--background-order': '#D1D2D9',
    '--order-circle': '#FFFFFF',
    '--background-suggestion': 'rgba(210, 213, 219, 0.2)',
    '--background-button-add-watchList': '#131419',
  }
};
