export class QuoteTick {
  private readonly _ask: number | null;
  private readonly _bid: number | null;
  private readonly _serverTime: Date;

  constructor(ask: number | null, bid: number | null, serverTime: Date) {
    this._ask = ask;
    this._bid = bid;
    this._serverTime = serverTime;
  }

  public get Ask(): number | null {
    return this._ask;
  }

  public get Bid(): number | null {
    return this._bid;
  }

  public get ServerTime(): Date {
    return this._serverTime;
  }
}
