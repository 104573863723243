import {ServerInteractionService} from '../../connection/server-interaction.service';
import {Logger} from '../../../common/utils/logging/logger';
import {LoggerFactory} from '../../../common/utils/logging/logger-factory';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';

export abstract class AbstractMessageProcessor {
  protected logger: Logger;
  protected eventsSubscription: Map<string, ISmartSubscription> = new Map<string, ISmartSubscription>();
  protected constructor(protected serverConnection: ServerInteractionService){
    this.logger = LoggerFactory.getLogger(this.constructor.name);
  }

  public abstract subscribe(): void;

  protected saveSubscription(eventName: string, subs: ISmartSubscription){
    this.eventsSubscription.set(eventName, subs);
  }
  protected unsubscribe(eventName: string){
    this.eventsSubscription.get(eventName).unsubscribe();
    this.eventsSubscription.delete(eventName);
  }
  public unsubscribeAll(){
    this.eventsSubscription.forEach((sub, key) => {
      sub.unsubscribe();
      this.eventsSubscription.delete(key);
    })
  }
}
