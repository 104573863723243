import {Injectable} from '@angular/core';
import {GeneratorGuid} from '@common/trade/utils/generator-guid';
import {Environment} from '@common/environment';
import {OperationsWithDate} from '@common/trade/utils/operations-with-date';
import {Title} from '@angular/platform-browser';

export interface PageDetails {
  guidPage: string;
  timeCreate: string;
}

export interface PageDetailsAddEnter extends PageDetails {
  enter: string;
}

@Injectable({
  providedIn: 'root'
})

export class BrowserPageService {

  constructor(private titleService: Title) {
  }

  static getGuidPageData(): string {
    const details = sessionStorage.getItem('LoginDetails');
    return details !== null ? details : '';
  }

  static getBrowserName(browserInfo: string | string[]) {
    let browser: string;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox';
    } else {
      browser = 'unknown';
    }
    return browser;
  }

  public createItem(): void {
    const guid = GeneratorGuid.createGuid();
    const platform = Environment.Platform;
    const LoginDetails: PageDetails = {
      guidPage: platform + ' ' + guid,
      timeCreate: OperationsWithDate.createTimeStamp(),
    };
    sessionStorage.setItem('LoginDetails', JSON.stringify(LoginDetails));
    console.log('guidPage: ', platform + ' ' + guid);
  }

  public addLoginDetails() {
    const LoginDetails = JSON.parse(sessionStorage.getItem('LoginDetails'));
    let guidPageData = '';

    if (LoginDetails !== null) {
      guidPageData = LoginDetails.guidPage;
    } else {
      guidPageData = GeneratorGuid.createGuid() + ' ' + Environment.Platform;
    }

    const LoginDetails2: PageDetailsAddEnter = {
      guidPage: guidPageData,
      timeCreate: OperationsWithDate.createTimeStamp(),
      enter: 'Login by login and password'
    };

    sessionStorage.setItem('LoginDetails', JSON.stringify(LoginDetails2));
  }

  private getNumberOfAppWindowsOpen(): number | null {
    return JSON.parse(localStorage.getItem('numberOfAppWindowsOpen'));
  }

  private setNumberOfAppWindowsOpen(count: number): void {
    localStorage.setItem('numberOfAppWindowsOpen', JSON.stringify(count));
  }


  public initCount(): void {
    if (Environment.Platform === 'tv-desktop') {
      let count = this.getNumberOfAppWindowsOpen();
      if (count === null) {
        this.setNumberOfAppWindowsOpen(1);
      } else {
        count ++;
        this.setNumberOfAppWindowsOpen(count);
      }
    }
  }

  public closeWindow(): void {
    let count = this.getNumberOfAppWindowsOpen();
    if (count !== null) {
      if (count === 1) {
        localStorage.removeItem('numberOfAppWindowsOpen');
      } else {
        count --;
        this.setNumberOfAppWindowsOpen(count);
      }
    }
  }

  public setTitle(symbolName: string): void {
    if (Environment.Platform === 'tv-desktop') {
      const count = this.getNumberOfAppWindowsOpen();
      const oldTitle = this.titleService.getTitle();
      const firstSplit = oldTitle.split(' - ');

      if (count > 1) {
        const newTitle = firstSplit[0] + ' - ' + symbolName;
        this.titleService.setTitle(newTitle);
      }

      if (count === 1 && firstSplit[0].length > 0) {
        this.titleService.setTitle(firstSplit[0]);
      }
    }
  }

}
