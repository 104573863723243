import {ProtocolMediatorBase} from './protocol-mediator-base';
import {ProtoV2MessageBase} from '../proto-v2-message-base';
import {Answer_LoginSuccess, Message_CustomSession} from '../classes.g';
import {IProtocol} from './protocol-interface';

export class ProtocolSessionIDLevel extends ProtocolMediatorBase
{
  private _sessionID: string = null;

  constructor(subProtocol: IProtocol, sessionId?: string)
  {
    super(subProtocol);
    this._sessionID = sessionId;
  }

  public onMessageReceived(message: ProtoV2MessageBase)
  {
    if (message.BinaryID == Answer_LoginSuccess.GetBinaryID())
    {
      this._sessionID = (message as Answer_LoginSuccess).SessionID;
      this._logger.info('Session updated', this._sessionID);
    }

    super.onMessageReceived(message);
  }

  public send(message: ProtoV2MessageBase){
    if ("SessionID" in message)
    {
      (message as Message_CustomSession).SessionID = this._sessionID;
    }

    super.send(message);
  }
}
