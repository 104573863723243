import {Trade} from '../models/trade';
import {TradeType} from '@common/trade/models/trade-type';
import {Symbol} from '../../symbol/models/symbol';

export class NettedTrade {

  private _positionVolume: number = 0;
  private _openTradesMap: Map<number, Trade> = new Map<number, Trade>();
  private _symbolName;
  private _symbol: Symbol;
  private _averagePrice: number = 0;
  private _positionMarginUsed: number = 0;

  get AveragePrice(): number {
    return this.goodRound(this._averagePrice);
  }

  get PNL(): number {
    let totalUpl: number = 0;
    this._openTradesMap.forEach((value) => {
      totalUpl += value.UPL ;
    });
    return this.goodRound(totalUpl);
  }

  get CurrentValue(): number {
    const rawValue = this.PNL + this._positionMarginUsed;
    return this.goodRound(rawValue);
  }

  private goodRound(value: number): number {
    return Math.round(value * Math.pow(10, this._symbol.DecimalPlaces)) / Math.pow(10, this._symbol.DecimalPlaces);
  }

  private goodRound2(value: number): number {
    return Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  get CurrentPrice(): number {
    if (this._positionVolume >= 0) {
      return this.goodRound(this.Symbol.Ask);
    } else {
      return this.goodRound(this.Symbol.Bid);
    }
  }

  get Symbol(): Symbol {
    return this._symbol;
  }

  get PNLPercent(): number {
    return this.goodRound2(
      this.PNL * 100 / this._positionMarginUsed
    );
  }

  get MarginUsed(): number {
    return this._positionMarginUsed;
  }

  get NetVolume(): number {
    return Math.abs(this._positionVolume);
  }

  get SymbolName(): string {
    return this._symbolName;
  }

  get NetPositionType(): string {
    if (this._positionVolume > 0) {
      return 'BUY';
    } else if (this._positionVolume < 0) {
      return 'SELL';
    } else {
      return 'HEDGED';
    }
  }

  get OpenTradesList(): Trade[] {
    return Array.from(this._openTradesMap.values());
  }

  addOrder(order: Trade) {
    const startVolume = this._positionVolume;

    this._symbolName = order.SymbolName;
    this._symbol = order.Symbol;
    this._openTradesMap.set(order.TradeId, order);
    // calculating the total volume
    if (order.Type === TradeType.Buy) {
      this._positionVolume += order.Volume;
    } else if (order.Type === TradeType.Sell) {
      this._positionVolume -= order.Volume;
    }

    this._averagePrice = this.calculateWeightedPrice(this._averagePrice, startVolume, order.OpenPrice, order.Volume, order.Type);
    this._positionMarginUsed += order.Margin;
  }

  removeOrder(order: Trade) {
    const startVolume = this._positionVolume;
    this._openTradesMap.delete(order.TradeId);

    if (order.Type === TradeType.Buy) {
      this._positionVolume -= order.Volume;
    } else if (order.Type === TradeType.Sell) {
      this._positionVolume += order.Volume;
    }

    let invertedOrdeType: TradeType;
    if (order.Type === TradeType.Sell) {
      invertedOrdeType = TradeType.Buy;
    } else if (order.Type === TradeType.Buy) {
      invertedOrdeType = TradeType.Sell;
    }

    // treating closing sell as buy and vice versa
    this._averagePrice = this.calculateWeightedPrice(this._averagePrice, startVolume, order.ClosePrice, -order.Volume, invertedOrdeType);
    this._positionMarginUsed -= order.Margin;

  }

  private calculateWeightedPrice( currentAvgPrice: number, currentVolume: number,
                                  orderPrice: number, orderVolume: number,
                                  orderType: TradeType ): number {
    // calculating weighted price
    // if positions is empty - just use the order's price
    if (currentVolume === 0) {
      return orderPrice;
    } else {

      // Buy after buy or sell after sell
      if ((currentVolume > 0 && orderType === TradeType.Buy) || (currentVolume < 0 && orderType === TradeType.Sell)) {
        return (currentAvgPrice * Math.abs(currentVolume) + orderPrice * Math.abs(orderVolume))
          / ( Math.abs(currentVolume) + Math.abs(orderVolume));
      } else { // buy after sell or sell after buy
        // not crossing the zero
        if (Math.abs(currentVolume) > Math.abs(orderVolume)) {
          return currentAvgPrice;
        } else if (Math.abs(currentVolume) < Math.abs(orderVolume)){
          // when crossing the zero - order's price becomes average
          return orderPrice;
        } else {
          // need to do something with case 0
        }
      }
    }
  }

}
