import {AbstractCommandSender} from './abstract-command-sender';
import {ServerInteractionService} from '../connection/server-interaction.service';
import {Trader} from '../../trader/models/trader';
import {Command_GetSettings, Command_SetSettings} from '../connection/classes.g';
import {Settings} from '../../trader/models/settings';
import {Injectable} from '@angular/core';
import {LoggerFactory} from '../../common/utils/logging/logger-factory';
import {ICommandPromise} from '../connection/command-answer-service';

@Injectable({
  providedIn: 'root'
})
export class SettingsCommandSender extends AbstractCommandSender{
  private logger = LoggerFactory.getLogger(this);
  public constructor(serverConnection: ServerInteractionService){
    super(serverConnection);
  }

  public loadSettings(trader: Trader): ICommandPromise {
    const command = new Command_GetSettings();
    command.TraderID = trader.TraderId;
    this.logger.debug('Load settings');
    return this.sendCommand(command);
  }

  public saveSettings(trader: Trader, settings: Settings): ICommandPromise {
    this.logger.debug('Save settings');
    const command = new Command_SetSettings();

    command.TraderID = trader.TraderId;
    command.AskLine = settings.AskLine;
    command.BidLine = settings.BidLine;
    command.Sound = settings.Sound;
    command.TradesOnChart = settings.TradesOnChart;
    command.AlwaysShowDefaultView = settings.AlwaysShowDefaultView;
    command.OneClickTrading = settings.OneClickTrading;
    command.OneClickStopLoss = settings.OneClickSL;
    command.OneClickStopLossUnit = settings.OneClickSLUnits;
    command.OneClickTakeProfit = settings.OneClickTP;
    command.OneClickTakeProfitUnit = settings.OneClickTPUnits;
    command.OneClickTrailingStop = settings.OneClickTS;
    command.OneClickAmount = settings.OneClickAmount;
    command.UseSltpForNonOneClickOrders = settings.RegularSLTP;
    command.ChartCount = settings.ChartCount;
    command.CiqLayouts = settings.CiqLayouts;
    command.CiqPreferences = settings.CiqPreferences;
    command.CiqDrawings = settings.CiqDrawings;
    command.PendingOrderAccountRisk = settings.PendingOrderAccountRiskPercentage;
    // command.PendingOrderSlPips = settings.PendingOrderSlPips;
    // command.PendingOrderTpPips = settings.PendingOrderTpPips;
    command.UseAutoTradeSizing = settings.UseAutoTradeSizing;
    command.UseAdvancedVolumeSettings = settings.UseAdvancedVolume;
    command.TvLayout = settings.TvLayout;
    command.TvStudies = settings.TvStudies;
    command.ViewMode = <number>settings.ViewMode;
    command.FavoriteSymbols = JSON.stringify(Array.from(settings.FavoriteSymbols));
    command.LotTrading = settings.UseLotTrading;
    command.OneClickLot = settings.OneClickLot;

    console.log(command);

    return this.sendCommand(command);
  }
}
