import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricePipe} from './pipes/price.pipe';
import {PipsPipe} from './pipes/pips.pipe';
import {FormsModule} from '@angular/forms';
import {LocaleModule} from '../locale/locale.module';
import {SharedModule} from '../shared/shared.module';
import {UserInterfaceModule} from '@common/user-interface/user-interface.module';
import {RouterModule} from '@angular/router';
import {FormattingModule} from '@common/formatting/formatting.module';
import {MoneyPipe} from './pipes/money.pipe';
import {CurrencyModule} from '@common/currency/currency.module';
import {ThemeModule} from '@common/shared/services/theme';

@NgModule({
  declarations: [
    PricePipe,
    PipsPipe,
    MoneyPipe
  ],
  exports: [
    PricePipe,
    PipsPipe,
    MoneyPipe
  ],
    imports: [
        CommonModule,
        FormsModule,
        LocaleModule,
        SharedModule,
        UserInterfaceModule,
        RouterModule,
        FormattingModule,
        CurrencyModule,
        ThemeModule
    ]
})
export class TradeModule { }
