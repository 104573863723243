import {Injectable} from '@angular/core';
import {TfcTrade} from '@chartiq-core/trade-from-chart/models/tfc-trade';
import {ProfitCalculator} from '@common/trade/utils/calculations/profit-calculator';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeType} from '@common/trade/models/trade-type';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {Accounting} from '@common/trader/models/accounting.service';
import {ITfcSpecialApi} from '@chartiq-core/trade-from-chart/models/trade-from-chart-interfaces';
import {SettingsService} from '@common/trader/services/settings.service';
import {AccountRiskHandlerService} from '@chartiq-core/trade-from-chart/services/account-risk-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TradeFromChartCalculatorService {

  private _specialApi: ITfcSpecialApi;

  public set SpecialApi(value: ITfcSpecialApi) {
    this._specialApi = value;
  }
  public get SpecialApi(): ITfcSpecialApi {
    return this._specialApi;
  }

  constructor(private accounting: Accounting,
              private settings: SettingsService,
              private accountRiskHandlerService: AccountRiskHandlerService) {

  }

  public convertPixelToPrice(y: number): number {
    return this._specialApi.convertPixelToPrice(y);
  }
  public convertPriceToPixel(price: number): number {
    return this._specialApi.convertPriceToPixel(price);
  }

  public getStopOrderVolume(symbol: Symbol, basePrice: number, isSellOrder: boolean): number {
    if (this.settings.Settings.UseAutoTradeSizing && this.settings.Settings.OneClickSL > 0) {
      return this.calculateOneClickPipVolumeByOpenPrice(
        symbol,
        basePrice,
        isSellOrder ? TradeType.SellStop : TradeType.BuyStop,
        this.settings.Settings.PendingOrderAccountRiskPercentage,
        this.settings.Settings.OneClickSL
      );
    } else {
      return this.settings.Settings.OneClickAmount;
    }
  }

  public getLimitOrderVolume(symbol: Symbol, basePrice: number, isSellOrder: boolean): number {
    if (this.settings.Settings.UseAutoTradeSizing && this.settings.Settings.OneClickSL > 0) {
      return this.calculateOneClickPipVolumeByOpenPrice(
        symbol,
        basePrice,
        isSellOrder ? TradeType.SellLimit : TradeType.BuyLimit,
        this.settings.Settings.PendingOrderAccountRiskPercentage,
        this.settings.Settings.OneClickSL
      );
    } else {
      return this.settings.Settings.OneClickAmount;
    }
  }

  public getMarketBuyVolume(symbol: Symbol): number {
    if (this.settings.Settings.UseAutoTradeSizing && this.settings.Settings.OneClickSL > 0) {
      return this.calculateOneClickPipVolumeByOpenPrice(
        symbol,
        symbol.LastQuote.Ask,
        TradeType.Buy,
        this.settings.Settings.PendingOrderAccountRiskPercentage,
        this.settings.Settings.OneClickSL
      );
    } else {
      return this.settings.Settings.OneClickAmount;
    }
  }

  public getMarketSellVolume(symbol: Symbol): number {
    if (this.settings.Settings.UseAutoTradeSizing && this.settings.Settings.OneClickSL > 0) {
      return this.calculateOneClickPipVolumeByOpenPrice(
        symbol,
        symbol.LastQuote.Bid,
        TradeType.Sell,
        this.settings.Settings.PendingOrderAccountRiskPercentage,
        this.settings.Settings.OneClickSL
      );
    } else {
      return this.settings.Settings.OneClickAmount;
    }
  }

  // this.tfcCalculator.calculateTotalProfit(this.Trade.Volume, this.Trade.OpenPrice, this.Trade.TakeProfit);

  public calculateTotalProfit(volume: number, openPrice: number, takeProfit: number, symbol: Symbol, tradeType: TradeType) {
    const calculator = new ProfitCalculator(symbol, tradeType);

    return calculator.calculateProfit(volume, openPrice, takeProfit);
  }

  public recalculateAccountRiskPercentage(entryPoint: TfcTrade): number {
    const pips = Math.abs(entryPoint.OpenPrice - entryPoint.StopLoss) / entryPoint.Symbol.PipSize;
    const calculator = new ProfitCalculator(entryPoint.Symbol, entryPoint.Type);

    const pipValue = Math.abs(calculator.calculatePipValueForPending(entryPoint.Volume, entryPoint.OpenPrice));

    return this.calculateAccountRiskPercentageByPipValue(pipValue, pips);
  }

  public getOrCalculateAccountRiskPercentageForSl(entryPoint: TfcTrade): number | null {
    const accountRisk = this.accountRiskHandlerService.getAccountRiskPercentageForTradeId(entryPoint.TradeId);
    if (accountRisk) {
      return accountRisk;
    } else {
      return this.recalculateAccountRiskPercentage(entryPoint);
    }
  }

  /**
   * Calculates pip volume with specified symbol, open price and order type.
   * Account risk percentage and stop loss are taken from the trade settings
   * @param symbol
   * @param openPrice
   * @param type
   * @param accountRiskPercentage
   * @param slPips Stop Loss in pips
   */
  public calculateOneClickPipVolumeByOpenPrice(symbol: Symbol,
                                               openPrice: number,
                                               type: TradeType,
                                               accountRiskPercentage: number,
                                               slPips: number): number {
    return this.calculatePipVolumeByOpenPrice(
      symbol,
      accountRiskPercentage,
      openPrice,
      slPips,
      type
    );
  }

  /**
   * Calculates pip value for specified account risk percentage and stop loss in pips
   * @param accountRiskPercentage
   * @param stopLossPips stop loss in pips
   */
  public calculatePipValue(accountRiskPercentage: number, stopLossPips: number): number {
    if (stopLossPips === 0) {
      return 0;
    }

    const account = this.accounting.Equity / 100 * accountRiskPercentage;

    return account / stopLossPips;
  }

  /**
   * Calculates how much pip away must be Stop Loss for specified account risk percentage and pip value
   * @param accountRiskPercentage
   * @param pipValue
   */
  public calculatePipsByPipValue(accountRiskPercentage: number, pipValue: number): number {
    if (pipValue === 0) {
      return 0;
    }

    return (this.accounting.Equity / 100 * accountRiskPercentage) / pipValue;
  }

  /**
   * Calculates account risk percentage by specified pip value and stop loss in pips
   * @param pipValue
   * @param stopLossPips stop loss in pips
   */
  public calculateAccountRiskPercentageByPipValue(pipValue: number, stopLossPips: number): number {
    if (this.accounting.Equity === 0) {
      return 0;
    }
    return (100 * pipValue * stopLossPips) / this.accounting.Equity;
  }

  /**
   * Converts account risk in percents to account risk in dollars
   * @param accountRiskPercentage
   */
  public calculateAccountRiskDollarsByPercentage(accountRiskPercentage: number) {
    return this.accounting.Equity / 100 * accountRiskPercentage;
  }

  /**
   * Converts account risk in dollars to account risk in percents
   * @param accountRiskDollars
   */
  public calculateAccountRiskPercentageByDollars(accountRiskDollars: number) {
    if (this.accounting.Equity === 0) {
      return 0;
    }
    return accountRiskDollars * 100 / this.accounting.Equity;
  }

  public calculatePipVolumeByOpenPrice(symbol: Symbol,
                                       accountRiskPercentage: number,
                                       openPrice: number,
                                       stopLossPips: number,
                                       type: TradeType): number {
    const calc = new ProfitCalculator(symbol, type);

    const dollarsPerPip = this.calculatePipValue(accountRiskPercentage, stopLossPips);

    const pipVolume = CustomNumber.roundForPlace(
      Math.abs(calc.calculatePipVolume(dollarsPerPip, openPrice, openPrice + symbol.PipSize)),
      symbol.VolumeDecimalPlaces
    );

    return this.roundVolume(pipVolume, symbol);
  }

  public calculatePriceAtProfit(symbol: Symbol, type: TradeType, volume: number, openPrice: number, profit: number): number {
    const calc = new ProfitCalculator(symbol, type);

    return calc.calculatePriceAtProfit(volume, openPrice, profit);
  }

  private roundVolume(volume: number, symbol: Symbol): number {
    const volumeStep = symbol.ContractSize / 100;
    return Math.round(volume / volumeStep) * volumeStep;
  }
}
