import {Component, OnInit} from '@angular/core';
import {TooltipPosition} from '@common/shared/components/tooltips/tooltip.enum';


@Component({
  selector: 'tooltipCustom',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent implements OnInit {

  position: TooltipPosition = TooltipPosition.DEFAULT;
  tooltip = '';
  left = 0;
  top = 0;
  visible = false;

  constructor() {
  }

  ngOnInit() {
  }

}
