import {Injectable} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {SymbolCategory} from '@common/symbol/models/symbol-category';
import {SettingsService} from '@common/trader/services/settings.service';
import {SymbolsSettings, SymbolsSettingsStorage, ViewMode} from '@common/shared/services/SymbolSelect/symbol-settings';
import {Trader} from '@common/trader/models/trader';
import {ISmartObserver, ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {SymbolService} from '@common/symbol/services/symbol.service';

class SymbolsFilter {
  private symbolCache = new Map<number, Symbol>();

  public constructor(private symbols: Symbol[]) {
    symbols.forEach(item => this.symbolCache.set(item.SymbolId, item));
  }

  public getSymbols(settings: SymbolsSettings, pattern: string): Symbol[]{
    let symbols = this.getSymbolsByOrder(settings);

    symbols = symbols.filter(item => this.isSymbolVisible(item, settings));

    if (!pattern) { return symbols; }

    return symbols.filter(item =>
          item.SymbolName.toLowerCase().replace(/[^a-zа-я0-9]+/g, '')
              .indexOf(pattern.toLowerCase().replace(/[^a-zа-я0-9]+/g, '')) != -1);
  }
  public getSymbolsByOrder(settings: SymbolsSettings): Symbol[] {
    const symbols = settings.SymbolsOrder.map(item => {
      return this.symbolCache.get(item.symbolId);
    });
    return symbols;
  }

  private isSymbolVisible(symbol: Symbol, settings: SymbolsSettings){
    if (!symbol || !settings) {
      return true;
    }

    if (symbol.Category == SymbolCategory.CFDs) {
      return settings.ShowCFD;
    } else if (symbol.Category == SymbolCategory.PreciousMetals){
      return settings.ShowMetals;
    } else if (symbol.Category == SymbolCategory.Cryptos) {
      return settings.ShowCryptos;
    } else if (symbol.Category == SymbolCategory.Equities) {
      return settings.ShowEquities;
    } else if (symbol.Category == SymbolCategory.Energies) {
      return settings.ShowEnergies;
    } else if (symbol.Category == SymbolCategory.Futures) {
      return settings.ShowFutures;
    } else if (symbol.Category == SymbolCategory.Indices) {
      return settings.ShowIndices;
    } else {
      return settings.ShowForex;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class  SymbolSelectService {
  private symbols: Symbol[];

  private currentSymbol: Symbol;
  private symbolFilter: SymbolsFilter;
  public symbolSettings: SymbolsSettings;
  private symbolChange: SmartEmitter<Symbol> = new SmartEmitter<Symbol>();

  constructor(private trader: Trader,
              private symbolStorage: SymbolStorageService,
              private localStorage: LocalStorageService,
              private settingsService: SettingsService,
              private symbolService: SymbolService) {
    this.symbolStorage.subscribeToDataChanges((symbols) => this.createFilter(symbols));
    this.symbolStorage.onInvalidate(() => this.clearFilter());

    this.loadOrCreateSettings();

    this.symbolSettings.SettingChanged.subscribe(() => {
      this.saveSettings();
    });
  }


  public createFilter(symbols: Symbol[]) {
    this.symbols = symbols;

    this.symbolFilter = new SymbolsFilter(this.Symbols);
  }

  public clearFilter() {
    this.symbols = null;
    this.symbolFilter = null;
  }

  public selectSymbol(symbol: Symbol) {

    // проверка на подписку изминения цен, если её нет, то подписывается
    if (symbol.LastQuote == undefined) {
      this.symbolService.subscribeToSymbol(symbol.SymbolId).then();
    }

    this.currentSymbol = symbol;
    this.symbolChange.emit(symbol);
  }

  public swapSymbols(prevId: number, currId: number) {
    this.symbolSettings.swapSymbols(prevId, currId);
  }

  public getSymbols(pattern: string, isSearchFavorites: boolean = false): Symbol[] {
    this.saveSettings(); // сохранение в локолстородже актуальных валютных пар пользователя перед отрисовкой в webTrader
    if (this.ViewMode === 'Favorites' || isSearchFavorites ) {
      return  this.symbolFilter.getSymbolsByOrder(this.symbolSettings).filter(item => this.Favorites.has(item.SymbolId))
                                    .filter(item => item.SymbolName.toLowerCase().replace(/[^a-zа-я0-9]+/g, '').indexOf(pattern.toLowerCase().replace(/[^a-zа-я0-9]+/g, '')) !== -1);

      // return this.symbolFilter.getSymbolsByOrder(this.symbolSettings).filter(item => this.Favorites.has(item.SymbolId));
    } else {
      return this.symbolFilter.getSymbols(this.symbolSettings, pattern);
    }
  }

  public getSymbolsForSearchPageDesktop(pattern: string): Symbol[] {
    this.saveSettings(); // сохранение в локолстородже актуальных валютных пар пользователя перед отрисовкой в webTrader
    return this.symbolFilter.getSymbols(this.symbolSettings, pattern);
  }

  public reset() {
    this.currentSymbol = null;
  }

  public async toggleFavorites(id: number) {
    if (this.isFavorite(id)) {
      this.Favorites.delete(id);
    } else {
      this.Favorites.add(id);
    }

    await this.settingsService.save();
  }

  public async setFavorites(symbolIdArray: number[]): Promise<void> {
    /*this.symbolStorage.Symbols.forEach(symbol => {
      if (this.isFavorite(symbol.SymbolId)) {
        this.Favorites.delete(symbol.SymbolId);
      }
    });*/

    this.Favorites.clear();

    symbolIdArray.forEach(value => {
      this.Favorites.add(value);
    });

    this.symbolChange.emit(null);
    await this.settingsService.save();
  }

  public isFavorite(id: number): boolean {
    return this.Favorites.has(id);
  }

  private renewSettings(cachedSettings: SymbolsSettingsStorage) {
    const symbolDict = new Map<number, Symbol>();
    this.Symbols.forEach(item => symbolDict.set(item.SymbolId, item));

    let symbols = cachedSettings.symbolsOrder?.map(item => {
      return symbolDict.get(item.symbolId);
    });

    symbols = symbols.filter(item => item !== undefined);

    // add new symbols
    this.Symbols.forEach(item => {
      if (!symbols.find(symbol => symbol.SymbolId == item.SymbolId)) {
        symbols.push(item);
      }
    });

    cachedSettings.symbolsOrder = symbols?.map(((value, index) => {
      return {
        index: index,
        symbolId: value.SymbolId
      };
    }));

    const settings = new SymbolsSettings();
    settings.SettingsStorage = cachedSettings;
    this.symbolSettings = settings;
  }

  private saveSettings() {
    this.localStorage.saveSymbolSettingsStorage(this.trader.TraderName, this.symbolSettings.SettingsStorage);
    this.loadOrCreateSettings();
  }

  private loadOrCreateSettings() {
    this.symbolSettings = new SymbolsSettings(this.Symbols);
    const cachedSettings = this.localStorage.getSymbolSettingsStorage(this.trader.TraderName);

    if (cachedSettings && cachedSettings.symbolsOrder.length > 0) {
      this.renewSettings(cachedSettings);
      this.localStorage.saveSymbolSettingsStorage(this.trader.TraderName, cachedSettings);
    } else {
      this.symbolSettings = new SymbolsSettings(this.Symbols);
    }
  }

  public get CurrentSymbol() {
    return this.currentSymbol;
  }

  public get Symbols(): Symbol[] {
    return this.symbols;
  }

  public get ViewMode(): ViewMode {
    return this.symbolSettings.ViewMode;
  }
  public set ViewMode(v: ViewMode) {
    this.symbolSettings.ViewMode = v;
    this.saveSettings();
  }

  public get SettingsChanged(): ISmartObserver<void> {
    return this.symbolSettings.SettingChanged;
  }

  public get ShowForex(): boolean {
    return this.symbolSettings.ShowForex;
  }

  public set ShowForex(v: boolean) {
    this.symbolSettings.ShowForex = v;
  }

  public get ShowCFD(): boolean {
    return this.symbolSettings.ShowCFD;
  }

  public set ShowCFD(v: boolean) {
    this.symbolSettings.ShowCFD = v;
  }

  public get ShowMetals(): boolean {
    return this.symbolSettings.ShowMetals;
  }

  public set ShowMetals(v: boolean) {
    this.symbolSettings.ShowMetals = v;
  }

  public get ShowCryptos(): boolean {
    return this.symbolSettings.ShowCryptos;
  }

  public set ShowCryptos(v: boolean) {
    this.symbolSettings.ShowCryptos = v;
  }

  public get ShowEquities(): boolean {
    return this.symbolSettings.ShowEquities;
  }

  public set ShowEquities(v: boolean) {
    this.symbolSettings.ShowEquities = v;
  }

  public get ShowEnergies(): boolean {
    return this.symbolSettings.ShowEnergies;
  }

  public set ShowEnergies(v: boolean) {
    this.symbolSettings.ShowEnergies = v;
  }

  public get ShowFutures(): boolean {
    return this.symbolSettings.ShowFutures;
  }

  public set ShowFutures(v: boolean) {
    this.symbolSettings.ShowFutures = v;
  }

  public get ShowIndices(): boolean {
    return this.symbolSettings.ShowIndices;
  }

  public set ShowIndices(v: boolean) {
    this.symbolSettings.ShowIndices = v;
  }

  public get Favorites(): Set<number> {
    return this.settingsService.Settings.FavoriteSymbols;
  }

  public set Favorites(symbols) {
    this.settingsService.Settings.FavoriteSymbols = symbols;
  }

  public get SymbolChange(): ISmartObserver<Symbol> {
    return this.symbolChange;
  }

  public get SymbolsFilter(): SymbolsFilter {
    return this.symbolFilter;
  }

  public subscribeSymbolChange(callback: () => void): ISmartSubscription {
    return this.symbolChange.subscribe(callback);
  }

  public getSymbolsArray(arraySymbols: Symbol[]): Symbol[] {
    return this.symbolStorage.getSymbolsArray(arraySymbols);
  }
}
