<div class="c-row-content symbol-row select-denied" (click)="onClick($event)" *ngIf="!IsZeTradex; else zetradex">
  <div class="symbol-name">
    <span>{{ SymbolName }}</span>
  </div>
  <div></div>
  <div class="spread-price column">
    <div class="percent-change-value-container" *ngIf="getShowSymbolPercentChangDay()">
      <span *ngIf="Symbol.PercentChange > 0 " class="positive-percent-change-value">&#9650;{{ getPercentChangeFormatted(Symbol) }}</span>
      <span *ngIf="Symbol.PercentChange < 0 " class="negative-percent-change-value">&#9660;{{ getPercentChangeFormatted(Symbol) }}</span>
      <span *ngIf="Symbol.PercentChange == 0 " >{{ getPercentChangeFormatted(Symbol) }}</span>
      <span *ngIf="Symbol.PercentChange == null " > -- </span>
    </div>
  </div>
  <div class="bid-price column">
    <span class="full-title title">Bid:</span>
      <span class="price-block" [ngClass]="getColor(Bid)" app-symbol-price [priceInfo]="Bid" [symbol]="Symbol">
    </span>
  </div>
<!--  <div class="spread-price column" app-spread [value]="Spread" [newVariantShow]="false"></div>-->

  <div class="ask-price column">
    <span class="full-title title">Ask:</span>
    <span class="price-block" [ngClass]="getColor(Ask)" app-symbol-price [priceInfo]="Ask" [symbol]="Symbol"></span>
  </div>
  <div class="button-group">
    <div class="column add-to-favorite icon" [class.active]="IsFavorite" (click)="toggleFavorite($event)"></div>
    <div class="column open-chart icon" *ngIf="IsOpenChartAvailable; else anotherOption" (click)="openChart($event)"></div>
    <ng-template #anotherOption>
      <div class="column open-chart icon" [routerLink]="['/terminal/charts', {symbolName: Symbol.SymbolName, symbolId: Symbol.SymbolId}]"></div>
    </ng-template>

  </div>
</div>

<ng-template #zetradex>
  <div class="c-row-content symbol-row zetradex-grid select-denied" (click)="onClick($event)" *ngIf="!IsMobileVersion; else zetradexMobile">
    <div class="symbol-name symbol-name-witch-icon">
      <img *ngIf="SymbolIconPath !== undefined; else noImgIcon"  class="symbol-icon-quote" src="{{SymbolIconPath}}">
      <ng-template #noImgIcon>
        <div class="symbol-icon-quote text-icon symbol-icon-quote">
          <span [textContent]="TextIcon"></span>
        </div>
      </ng-template>
      <app-symbol-name *ngIf="IsZeTradex" [symbolId]="SymbolId"></app-symbol-name>
    </div>
    <div class="spread-price column">
      <div class="percent-change-value-container" *ngIf="getShowSymbolPercentChangDay()">
        <span *ngIf="Symbol.PercentChange > 0 " class="positive-percent-change-value">&#9650;{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange < 0 " class="negative-percent-change-value">&#9660;{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange == 0 " >{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange == null " > -- </span>
      </div>
    </div>
    <div class="bid-price column">
      <span class="full-title title">Bid:</span>
      <span class="price-block zetradex-agent" app-symbol-price [priceInfo]="Bid" [symbol]="Symbol">
    </span>
    </div>
    <div class="ask-price column">
      <span class="full-title title">Ask:</span>
      <span class="price-block zetradex-agent" app-symbol-price [priceInfo]="Ask" [symbol]="Symbol"></span>
    </div>
    <div class="button-group">
      <div class="column z-stars" [class.z-active]="IsFavorite" (click)="toggleFavorite($event)"></div>
      <div class="column icon z-open-chart" (click)="openChart($event)"></div>
    </div>
  </div>

  <ng-template #zetradexMobile>
    <div class="c-row-content symbol-row z-grid-mobile select-denied" (click)="onClick($event)">
      <div class="symbol-icon z-symbol-icon-mobile">
        <img *ngIf="SymbolIconPath !== undefined; else noImgIcon"  class="symbol-icon-quote" src="{{SymbolIconPath}}">
        <ng-template #noImgIcon>
          <div class="symbol-icon-quote text-icon">
            <span [textContent]="TextIcon"></span>
          </div>
        </ng-template>
      </div>

      <div class="symbol-name symbol-name-witch-icon z-symbol-name-mobile">
        <app-symbol-name *ngIf="IsZeTradex" [symbolId]="SymbolId"></app-symbol-name>
      </div>

      <div class="spread-price column z-symbol-percent-change-mobile">
        <div class="percent-change-value-container" *ngIf="getShowSymbolPercentChangDay()">
          <span *ngIf="Symbol.PercentChange > 0 " class="positive-percent-change-value">&#9650;{{ getPercentChangeFormatted(Symbol) }}</span>
          <span *ngIf="Symbol.PercentChange < 0 " class="negative-percent-change-value">&#9660;{{ getPercentChangeFormatted(Symbol) }}</span>
          <span *ngIf="Symbol.PercentChange == 0 " >{{ getPercentChangeFormatted(Symbol) }}</span>
          <span *ngIf="Symbol.PercentChange == null " > -- </span>
        </div>
      </div>

      <div class="bid-price column z-symbol-bid-name-mobile">
        <span [ngClass]="getColorTextForPercentChange()" ><l-unit [upperCase]="true" [key]="'SymbolModule_SymbolTradeFormComponent_SellButton'"></l-unit></span>
      </div>

      <div class="bid-price column z-symbol-bid-mobile">
        <span class="price-block zetradex-agent" app-symbol-price [priceInfo]="Bid" [symbol]="Symbol"></span>
      </div>

      <div class="bid-price column z-symbol-ask-name-mobile">
        <span [ngClass]="getColorTextForPercentChange()" ><l-unit [upperCase]="true" [key]="'SymbolModule_SymbolTradeFormComponent_BuyButton'"></l-unit></span>
      </div>

      <div class="ask-price column z-symbol-ask-mobile">
        <span class="price-block zetradex-agent" app-symbol-price [priceInfo]="Ask" [symbol]="Symbol"></span>
      </div>

      <div class="button-group z-button-group-mobile">
        <div class="column z-stars" [class.z-active]="IsFavorite" (click)="toggleFavorite($event)"></div>
      </div>

    </div>
  </ng-template>

</ng-template>

<div class="symbol-trade-form select-denied" [hidden]="!IsSelected || IsReadOnlyAccount" (mousedown)="onFormMouseDown($event)" (mouseup)="onFormMouseUp($event)"
     *ngIf="!IsZeTradex; else zetradexForm">

  <span class="row-options-icon-option-line"></span>
  <span class="row-options-icon-option row-options-icon-option-active"></span>

  <div class="loading-overlay" *ngIf="IsLoading">
    <span class="loading-icon"></span>
  </div>


  <form name="quotesOrderForm" action="#" class="c-form" style="width: 100%">
    <div class="c-form-group">
      <label class="c-label">
        <l-unit *ngIf="!Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_quantity-amount-header'"></l-unit>&nbsp;
        <l-unit *ngIf="Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_lot-header'"></l-unit>&nbsp;
        <span [hidden]="Symbol.TradingStep === 0">(<l-unit [key]="'TradeModule_MultiOrderFormComponent_Step'"></l-unit> {{showStep()}})</span>
      </label>
      <!--<money-input [min]="Symbol.MinVolume" [max]="Symbol.MaxVolume" [decimalPlaces]="Symbol.VolumeDecimalPlaces" class="amount-input" name="amount" [(ngModel)]="Amount" [step]="Symbol.getInputTradingStep()"></money-input>-->
      <app-volume-input [Symbol]="Symbol" [decimalPlaces]="DecimalPlaces" class="amount-input" name="amount" [(ngModel)]="Amount"></app-volume-input>
    </div>
  </form>

  <div class="trade-buttons" [class.trade-buttons-mobile]="IsMobileVersion">
    <div class="spread-box"  app-spread [value]="Spread" [newVariantShow]="true"></div>

    <button class="button-po" [routerLink]="['/terminal/trading/multi', 4, SymbolId, Symbol.LastQuoteInfo.Ask.Price, {volume: Amount}]">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_PO'"></l-unit>
    </button>

    <button class="button-danger" [disabled]="IsLoading" (click)="onSellClick($event)">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_SellButton'"></l-unit><app-one-click-trading-icon></app-one-click-trading-icon>
    </button>

    <button class="button-success" [disabled]="IsLoading" (click)="onBuyClick($event)">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_BuyButton'"></l-unit><app-one-click-trading-icon></app-one-click-trading-icon>
    </button>

<!--    <button class="button-chart" [routerLink]="['/terminal/charts', {symbolName: Symbol.SymbolName, symbolId: Symbol.SymbolId}]">-->
<!--      <span class="row-options-icon row-options-icon-chart"></span>-->
<!--    </button>-->

    <button class="button-full-md" [routerLink]="['/terminal/full-market-depth', {symbolId: Symbol.SymbolId, closeAvailable: false, chartUrl: '/terminal/charts'}]">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_FullMD'"></l-unit>
    </button>
  </div>
</div>

<ng-template #zetradexForm>
  <div class="symbol-trade-form select-denied" [hidden]="!IsSelected || IsReadOnlyAccount" (mousedown)="onFormMouseDown($event)" (mouseup)="onFormMouseUp($event)">

    <div class="loading-overlay" *ngIf="IsLoading">
      <span class="loading-icon"></span>
    </div>


    <form name="quotesOrderForm" action="#" class="c-form" style="width: 100%">
      <div class="c-form-group z-form-group-quotes">
        <label class="c-label z-label">
          <l-unit *ngIf="!Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_quantity-amount-header'"></l-unit>&nbsp;
          <l-unit *ngIf="Settings.UseLotTrading" [key]="'TradeModule_MultiOrderFormComponent_lot-header'"></l-unit>&nbsp;
          <span [hidden]="Symbol.TradingStep === 0">(<l-unit [key]="'TradeModule_MultiOrderFormComponent_Step'"></l-unit> {{showStep()}})</span>
        </label>
        <app-volume-input [Symbol]="Symbol" class="amount-input" name="amount" [decimalPlaces]="DecimalPlaces" [(ngModel)]="Amount" [appName]="appName"></app-volume-input>
      </div>
    </form>

    <div class="trade-buttons" [ngClass]="ClassForMobileVersion" >

      <button class="button-danger z-button-danger z-button z-button-quotes" [class.z-button-quotes-desktop]="!IsMobileVersion" [disabled]="IsLoading" (click)="onSellClick($event)">
        <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_SellButton'"></l-unit><app-one-click-trading-icon [appName]="appName"></app-one-click-trading-icon>
      </button>

      <button class="button-success z-button z-button-success z-button-quotes" [class.z-button-quotes-desktop]="!IsMobileVersion" [disabled]="IsLoading" (click)="onBuyClick($event)">
        <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_BuyButton'"></l-unit><app-one-click-trading-icon [appName]="appName"></app-one-click-trading-icon>
      </button>

      <button class="button-full-md z-button z-button-full-md z-button-quotes" [routerLink]="['/terminal/full-market-depth', {symbolId: Symbol.SymbolId, closeAvailable: false, chartUrl: '/terminal/charts'}]">
        <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_FullMD'"></l-unit>
      </button>

      <div class="column z-open-chart z-icon-position" *ngIf="IsMobileVersion" [routerLink]="['/terminal/charts', {symbolName: Symbol.SymbolName, symbolId: Symbol.SymbolId}]"></div>

    </div>
  </div>
</ng-template>
