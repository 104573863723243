import {translate} from '../../locale/servises/translator.service';

// @dynamic
export class ChartStrings {
  private static _showOnChartButtonTitle: string;
  private static _hideOnChartButtonTitle: string;
  private static _askOnChartButtonTitle: string;
  private static _bidOnChartButtonTitle: string;
  private static _lineOnChartButtonTitle: string;
  private static _showHideAskBidLineOnChartButtonTooltip: string;
  private static _openAlertChartButtonTitle: string;
  private static _openAlertChartButtonTooltip: string;
  private static _openAskChartButtonTitle: string;
  private static _openBidChartButtonTitle: string;
  private static _openAskChartButtonTooltip: string;
  private static _openBidChartButtonTooltip: string;
  private static _openSymbolSearch: string;
  private static _openBuyStop: string;
  private static _openSellStop: string;
  private static _openBuyLimit: string;
  private static _openSellLimit: string;
  private static _openSellMarket: string;
  private static _openBuyMarket: string;
  private static _openCreateNewMarketSellOrder: string;
  private static _openCreateNewMarketBuyOrder: string;
  private static _openSettings: string;
  private static _orderSellButtonTitle: string;
  private static _orderBuyButtonTitle: string;
  private static _orderSellButtonTooltip: string;
  private static _orderBuyButtonTooltip: string;
  private static _visibilityButtonTooltip: string;
  private static _securityInfo: string;
  private static _leverageMarginRequirements: string;
  private static _minCommissionCommission: string;
  private static _contractSize: string;
  private static _minMaxVolume: string;
  private static _tradingStep: string;
  private static _rolloverBuySell: string;
  private static _pipValue: string;

  private static _isLoaded = false;

  public static get ShowOnChartButtonTitle(): string {
    this.check();
    return this._showOnChartButtonTitle;
  }

  public static get HideOnChartButtonTitle(): string {
    this.check();
    return this._hideOnChartButtonTitle;
  }

  public static get AskOnChartButtonTitle(): string {
    this.check();
    return this._askOnChartButtonTitle;
  }

  public static get BidOnChartButtonTitle(): string {
    this.check();
    return this._bidOnChartButtonTitle;
  }

  public static get LineOnChartButtonTitle(): string {
    this.check();
    return this._lineOnChartButtonTitle;
  }

  public static get ShowHideAskBidLineOnChartButtonTooltip(): string {
    this.check();
    return this._showHideAskBidLineOnChartButtonTooltip;
  }

  public static get OpenAlertChartButtonTitle(): string {
    this.check();
    return this._openAlertChartButtonTitle;
  }

  public static get OpenAlertChartButtonTooltip(): string {
    this.check();
    return this._openAlertChartButtonTooltip;
  }

  public static get OpenAskChartButtonTitle(): string {
    this.check();
    return this._openAskChartButtonTitle;
  }

  public static get OpenBidChartButtonTitle(): string {
    this.check();
    return this._openBidChartButtonTitle;
  }

  public static get OpenAskChartButtonTooltip(): string {
    this.check();
    return this._openAskChartButtonTooltip;
  }

  public static get OpenBidChartButtonTooltip(): string {
    this.check();
    return this._openBidChartButtonTooltip;
  }

  public static get OpenSymbolSearch(): string {
    this.check();
    return this._openSymbolSearch;
  }

  public static get OpenBuyStop(): string {
    this.check();
    return this._openBuyStop;
  }

  public static get OpenBuyLimit(): string {
    this.check();
    return this._openBuyLimit;
  }

  public static get OpenSellStop(): string {
    this.check();
    return this._openSellStop;
  }

  public static get OpenSellLimit(): string {
    this.check();
    return this._openSellLimit;
  }

  public static get OpenSellMarket(): string {
    this.check();
    return this._openSellMarket;
  }

  public static get OpenBuyMarket(): string {
    this.check();
    return this._openBuyMarket;
  }

  public static get OpenCreateNewMarketSellOrder(): string {
    this.check();
    return this._openCreateNewMarketSellOrder;
  }

  public static get OpenCreateNewMarketBuyOrder(): string {
    this.check();
    return this._openCreateNewMarketBuyOrder;
  }

  public static get OpenSettings(): string {
    this.check();
    return this._openSettings;
  }

  public static get OrderSellButtonTitle(): string {
    this.check();
    return this._orderSellButtonTitle;
  }

  public static get OrderBuyButtonTitle(): string {
    this.check();
    return this._orderBuyButtonTitle;
  }

  public static get OrderSellButtonTooltip(): string {
    this.check();
    return this._orderSellButtonTooltip;
  }

  public static get OrderBuyButtonTooltip(): string {
    this.check();
    return this._orderBuyButtonTooltip;
  }

  public static get VisibilityButtonTooltip(): string {
    this.check();
    return this._visibilityButtonTooltip;
  }

  public static get SecurityInfo(): string {
    this.check();
    return this._securityInfo;
  }

  public static get LeverageMarginRequirements(): string {
    this.check();
    return this._leverageMarginRequirements;
  }

  public static get MinCommissionCommission(): string {
    this.check();
    return this._minCommissionCommission;
  }

  public static get ContractSize(): string {
    this.check();
    return this._contractSize;
  }

  public static get MinMaxVolume(): string {
    this.check();
    return this._minMaxVolume;
  }

  public static get TradingStep(): string {
    this.check();
    return this._tradingStep;
  }

  public static get RolloverBuySell(): string {
    this.check();
    return this._rolloverBuySell;
  }

  public static get PipValue(): string {
    this.check();
    return this._pipValue;
  }

  private static check(): void {
    if (!this._isLoaded) {
      this.update();
    }
  }

  public static update(): void {
    this._isLoaded = true;
    this._openAskChartButtonTitle = translate('ChartingModule_ChartStrings_OpenAskChartButtonTitle');
    this._openBidChartButtonTitle = translate('ChartingModule_ChartStrings_OpenBidChartButtonTitle');
    this._openAskChartButtonTooltip = translate('ChartingModule_ChartStrings_OpenAskChartButtonTooltip');
    this._openBidChartButtonTooltip = translate('ChartingModule_ChartStrings_OpenBidChartButtonTooltip');
    this._openSymbolSearch = translate('ChartingModule_ChartStrings_OpenSymbolSearch');
    this._openBuyStop = translate('ChartingModule_ChartStrings_OpenBuyStop');
    this._openSellStop = translate('ChartingModule_ChartStrings_OpenSellStop');
    this._openBuyLimit = translate('ChartingModule_ChartStrings_OpenBuyLimit');
    this._openSellLimit = translate('ChartingModule_ChartStrings_OpenSellLimit');
    this._openSellMarket = translate('ChartingModule_ChartStrings_OpenSellMarket');
    this._openBuyMarket = translate('ChartingModule_ChartStrings_OpenBuyMarket');
    this._openCreateNewMarketSellOrder = translate('ChartingModule_ChartStrings_OpenCreateNewMarketSellOrder');
    this._openCreateNewMarketBuyOrder = translate('ChartingModule_ChartStrings_OpenCreateNewMarketBuyOrder');
    this._openSettings = translate('ChartingModule_ChartStrings_OpenSettings');
    this._orderSellButtonTitle = translate('ChartingModule_ChartStrings_SellButtonTitle');
    this._orderBuyButtonTitle = translate('ChartingModule_ChartStrings_BuyButtonTitle');
    this._orderSellButtonTooltip = translate('ChartingModule_ChartStrings_SellButtonTooltip');
    this._orderBuyButtonTooltip = translate('ChartingModule_ChartStrings_BuyButtonTooltip');
    this._visibilityButtonTooltip = translate('ChartingModule_ChartStrings_VisibilityButtonTooltip');
    this._openAlertChartButtonTitle = translate('Alert_Chart_Title');
    this._openAlertChartButtonTooltip = translate('Alert_Chart_Tooltip');
    this._showHideAskBidLineOnChartButtonTooltip = translate('ChartingModule_ChartStrings_ShowHideAskBidLineOnChartTooltip');
    this._showOnChartButtonTitle = translate('ChartingModule_ChartStrings_ShowOnChartButtonTitle');
    this._hideOnChartButtonTitle = translate('ChartingModule_ChartStrings_HideOnChartButtonTitle');
    this._askOnChartButtonTitle = translate('ChartingModule_ChartStrings_AskOnChartButtonTitle');
    this._bidOnChartButtonTitle = translate('ChartingModule_ChartStrings_BidOnChartButtonTitle');
    this._lineOnChartButtonTitle = translate('ChartingModule_ChartStrings_LineOnChartButtonTitle');
    this._securityInfo = translate('ChartingModule_ChartStrings_SecurityInfo');
    this._leverageMarginRequirements = translate('ChartingModule_ChartStrings_LeverageMarginRequirements');
    this._minCommissionCommission = translate('ChartingModule_ChartStrings_MinCommissionCommission');
    this._contractSize = translate('ChartingModule_ChartStrings_ContractSize');
    this._minMaxVolume = translate('ChartingModule_ChartStrings_MinMaxVolume');
    this._tradingStep = translate('ChartingModule_ChartStrings_TradingStep');
    this._rolloverBuySell = translate('ChartingModule_ChartStrings_RolloverBuySell');
    this._pipValue = translate('ChartingModule_ChartStrings_PipValue');
  }
}

