import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {LocaleModule} from '@common/locale/locale.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '@common/shared/shared.module';
import {RouterModule} from '@angular/router';
import {LayoutButtonComponent} from './components/layout-button/layout-button.component';
import {NotificationModule} from '@common/notification/notification.module';
import {CurrencyModule} from '@common/currency/currency.module';
import {TradeModule} from '@common/trade/trade.module';
import { SavedViewsComponent } from './components/saved-views/saved-views.component';
import { ModalSaveViewComponent } from './components/modal-save-view/modal-save-view.component';
import {ModalModule} from '@common/modals/modal.module';
import {ThemeModule} from '@common/shared/services/theme';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';

@NgModule({
  declarations: [
    FooterComponent,
    LayoutButtonComponent,
    SavedViewsComponent,
    ModalSaveViewComponent
  ],
    imports: [
        CommonModule,
        LocaleModule,
        SharedModule,
        FormsModule,
        RouterModule,
        NotificationModule,
        CurrencyModule,
        TradeModule,
        ModalModule,
        ThemeModule,
        ModalPlaceholderComponent
    ],
  exports: [
    FooterComponent,
    LayoutButtonComponent,
    SavedViewsComponent
  ]
})
export class UserInterfaceModule { }
