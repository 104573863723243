import {Symbol} from '@common/symbol/models/symbol';
import {PriceCalculator} from '@common/trade/utils/price-calculator';
import {isBuyTrade, TradeType} from '@common/trade/models/trade-type';
import {ProfitCalculator} from '@common/trade/utils/calculations/profit-calculator';
import {Accounting} from '@common/trader/models/accounting.service';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {
  IRiskPipsProvider,
  OrderTypeComposer,
  PriceComposer,
  SymbolSelector
} from 'src/app/shared/trade/classes/TradeFormSettings';

export abstract class VolumeCalculator {
  private logger = LoggerFactory.getLogger(this);

  private value: number;

  private type: VolumeType;

  constructor(private symbolSelector: SymbolSelector,
              private typeComposer: OrderTypeComposer,
              private accounting: Accounting,
              type: VolumeType,
              private priceComposer: PriceComposer,
              private pipsProvider: IRiskPipsProvider) {
    this.type = type;
  }

  public get VolumeType(): VolumeType {
    return this.type;
  }

  public get PipRisk(): number {
    return this.pipsProvider.Pips;
  }

  protected abstract convertToUnits(): number;
  protected abstract convertToCurrency(): number;
  protected abstract convertToPercent(): number;

  protected abstract convertFromUnits(units: number): number;
  protected abstract convertFromCurrency(currency: number): number;
  protected abstract convertFromPercent(percent: number): number;

  protected convertUnitsToCurrency(units: number): number {
    const result = Math.abs(this.Calculator.calculateProfit(units, this.OpenPrice, this.ClosePrice));

    return result;
  }
  protected convertCurrencyToUnits(currency: number): number {
    const result = Math.abs(this.Calculator.calculatePipVolume(currency, this.OpenPrice, this.ClosePrice));

    return result;
  }
  protected convertPercentToCurrency(percent: number): number {
    return this.Equity / 100 * percent;
  }
  protected convertCurrencyToPercent(currency: number): number {
    return Math.abs(currency / this.Equity * 100);
  }

  public get VolumePercent(): number {
    return this.convertToPercent();
  }

  public set VolumePercent(v: number) {
    this.value = this.convertFromPercent(v);
  }

  public get VolumeUnits(): number {
    return this.convertToUnits();
  }

  public set VolumeUnits(v: number) {
    this.value = this.convertFromUnits(v);
  }

  public get VolumeCurrency(): number {
    return this.convertToCurrency();
  }

  public set VolumeCurrency(v: number) {
    this.value = this.convertFromCurrency(v);
  }

  protected get Value(): number {
    return this.value;
  }

  protected get Symbol(): Symbol {
    return this.symbolSelector.CurrentSymbol;
  }

  protected get OpenPrice(): number {
    return this.priceComposer.OpenPrice;
  }

  protected get ClosePrice(): number {
    const openPrice = this.OpenPrice;

    const mul = isBuyTrade(this.TradeType) ? -1 : 1;

    const closePrice = PriceCalculator.addPipsToPrice(openPrice, this.PipRisk * mul, this.Symbol);

    return closePrice;
  }

  protected get TradeType(): TradeType {
    return this.typeComposer.TradeType;
  }

  protected get Calculator(): ProfitCalculator {
    return new ProfitCalculator(this.Symbol, this.TradeType);
  }

  protected get Equity(): number {
    return this.accounting.Equity;
  }
}

class UnitsVolumeCalculator extends VolumeCalculator {
  protected convertFromCurrency(currency: number): number {
    return this.convertCurrencyToUnits(currency);
  }

  protected convertFromPercent(percent: number): number {
    const currency = this.convertPercentToCurrency(percent);
    return this.convertFromCurrency(currency);
  }

  protected convertFromUnits(units: number): number {
    return units;
  }

  protected convertToCurrency(): number {
    const result = this.convertUnitsToCurrency(this.Value);

    return result;
  }

  protected convertToPercent(): number {
    const currency = this.convertToCurrency();

    const result = this.convertCurrencyToPercent(currency);

    return result;
  }

  protected convertToUnits(): number {
    return this.Value;
  }
}

class CurrencyVolumeCalculator extends VolumeCalculator {
  protected convertFromCurrency(currency: number): number {
    return currency;
  }

  protected convertFromPercent(percent: number): number {
    const result = this.convertPercentToCurrency(percent);
    return result;
  }

  protected convertFromUnits(units: number): number {
    return this.convertUnitsToCurrency(units);
  }

  protected convertToCurrency(): number {
    return this.Value;
  }

  protected convertToPercent(): number {
    const currency = this.convertToCurrency();

    return this.convertCurrencyToPercent(currency);
  }

  protected convertToUnits(): number {
    return this.convertCurrencyToUnits(this.Value);
  }
}

class PercentVolumeCalculator extends VolumeCalculator {
  protected convertFromCurrency(currency: number): number {
    const percent = this.convertCurrencyToPercent(currency);

    return percent;
  }

  protected convertFromPercent(percent: number): number {
    return percent;
  }

  protected convertFromUnits(units: number): number {
    const currency = this.convertUnitsToCurrency(units);

    return this.convertFromCurrency(currency);
  }

  protected convertToCurrency(): number {
    return Math.abs(this.convertPercentToCurrency(this.Value));
  }

  protected convertToPercent(): number {
    return this.Value;
  }

  protected convertToUnits(): number {
    return this.convertCurrencyToUnits(this.convertToCurrency());
  }
}

export enum VolumeType {
  ByUnits,
  ByPercent,
  ByCurrency
}

export class VolumeCalculatorFactory {
  public constructor(private symbolSelector: SymbolSelector,
                     private typeComposer: OrderTypeComposer,
                     private priceComposer: PriceComposer,
                     private accounting: Accounting,
                     private pipsProvider: IRiskPipsProvider) {

  }

  public getCalculatorByType(type: VolumeType) {
    switch (+type) {
      case VolumeType.ByUnits:
        return new UnitsVolumeCalculator(
          this.symbolSelector,
          this.typeComposer,
          this.accounting,
          VolumeType.ByUnits,
          this.priceComposer,
          this.pipsProvider
        );
      case VolumeType.ByCurrency:
        return new CurrencyVolumeCalculator(
          this.symbolSelector,
          this.typeComposer,
          this.accounting,
          VolumeType.ByCurrency,
          this.priceComposer,
          this.pipsProvider
        );
      case VolumeType.ByPercent:
        return new PercentVolumeCalculator(
          this.symbolSelector,
          this.typeComposer,
          this.accounting,
          VolumeType.ByPercent,
          this.priceComposer,
          this.pipsProvider
        );
    }
  }
}
