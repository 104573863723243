import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export enum LayoutMode {
  Default,
  Positions,
  Quotes,
  ChartOnly,
  PositionsOnly,
  QuotesAndPositions
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private layoutMode: LayoutMode;
  public get LayoutMode(): LayoutMode {
    return this.layoutMode;
  }

  private changeModeEmitter = new EventEmitter<void>();
  public get ModeChanged(): Observable<void> {
    return this.changeModeEmitter.asObservable();
  }

  private _showQuotes: boolean = null;
  get ShowQuotes(): boolean {
    return this._showQuotes;
  }
  private _showPositions: boolean = null;
  get ShowPositions(): boolean {
    return this._showPositions;
  }
  private _showChart: boolean = null;
  get ShowChart(): boolean {
    return this._showChart;
  }

  private _showFullMarketDepth = false;
  get ShowFullMarketDepth(): boolean {
    return this._showFullMarketDepth;
  }
  set ShowFullMarketDepth(value: boolean) {
    this._showFullMarketDepth = value;
  }

  constructor() { }

  public defaultMode() {
    if (this._showPositions == null &&
      this._showQuotes == null &&
      this._showChart == null) {
      return;
    }

    this.layoutMode = LayoutMode.Default;
    this.setQuotes(true);
    this.setChart(true);
    this.setPositions(true);

    this.changeModeEmitter.emit();
  }

  public quotesMode() {
    this.layoutMode = LayoutMode.Quotes;
    this.setQuotes(true);
    this.setChart(true);
    this.setPositions(false);

    this.changeModeEmitter.emit();
  }

  public positionsMode() {
    this.layoutMode = LayoutMode.Positions;
    this.setQuotes(false);
    this.setChart(true);
    this.setPositions(true);

    this.changeModeEmitter.emit();
  }

  public chartMode() {
    this.layoutMode = LayoutMode.ChartOnly;
    this.setQuotes(false);
    this.setChart(true);
    this.setPositions(false);

    this.changeModeEmitter.emit();
  }

  public positionsOnlyMode() {
    this.layoutMode = LayoutMode.PositionsOnly;
    this.setQuotes(false);
    this.setChart(false);
    this.setPositions(true);

    this.changeModeEmitter.emit();
  }

  public quotesAndPositionsMode() {
    this.layoutMode = LayoutMode.QuotesAndPositions;
    this.setQuotes(true);
    this.setChart(false);
    this.setPositions(true);

    this.changeModeEmitter.emit();
  }

  public setLayout(layoutMode: LayoutMode): void {
    switch (layoutMode) {
      case LayoutMode.Default:
        this.defaultMode();
        break;
      case LayoutMode.ChartOnly:
        this.chartMode();
        break;
      case LayoutMode.Positions:
        this.positionsMode();
        break;
      case LayoutMode.PositionsOnly:
        this.positionsOnlyMode();
        break;
      case LayoutMode.Quotes:
        this.quotesMode();
        break;
      case LayoutMode.QuotesAndPositions:
        this.quotesAndPositionsMode();
        break;
    }
  }

  private setQuotes(v: boolean) {
    if(this._showQuotes != null){
      this._showQuotes = v;
    }
    else if(!v) {
      this._showQuotes = v;
    }
  }

  private setPositions(v: boolean) {
    this._showPositions = v;
  }

  private setChart(v: boolean) {
    this._showChart = v;
  }
}
