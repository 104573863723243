import {AbstractCommandSender} from './abstract-command-sender';
import {ServerInteractionService} from '../connection/server-interaction.service';
import {Symbol} from '../../symbol/models/symbol';
import {
  Command_GetItems, Command_RefreshToken2, Command_SubscribeFullMarketDepth,
  Command_SubscribeItemTick, Command_UnsubscribeFullMarketDepth,
  Command_UnsubscribeItem,
  Command_UnsubscribeItemHistory
} from '../connection/classes.g';
import {Injectable} from '@angular/core';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {Trader} from '@common/trader/models/trader';
import {LocalStorageService} from '@common/auth/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SymbolCommandSender extends AbstractCommandSender{
  public constructor(serverConnection: ServerInteractionService, private trader: Trader, private token: LocalStorageService){
    super(serverConnection);
  }

  public unsubscribeFromSymbols(symbols: Symbol[]): ICommandPromise[] {
    const result = symbols.map(symbol => this.unsubscribeFromSymbol(symbol));

    return result;
  }

  public unsubscribeFromSymbol(symbol: Symbol): ICommandPromise {
    const command = new Command_UnsubscribeItem();

    command.ItemID = symbol.SymbolId;

    return this.sendCommand(command);
  }

  public unsubscribeFromSymbolsHistories(symbols: Symbol[]): ICommandPromise[] {
    const result = symbols.map(symbol => this.unsubscribeFromSymbolHistory(symbol));

    return result;
  }

  public unsubscribeFromSymbolHistory(symbol: Symbol): ICommandPromise {
    const command = new Command_UnsubscribeItemHistory();

    command.ItemID = symbol.SymbolId;
    command.ZoomType = 4;

    return this.sendCommand(command);
  }

  public subscribeToSymbolQuotes(symbolId: number): ICommandPromise {
    const command = new Command_SubscribeItemTick();
    command.ItemID = symbolId;

    return this.sendCommand(command);
  }

  public subscribeToFullMarketDepth(symbolId: number): ICommandPromise {
    const command = new Command_SubscribeFullMarketDepth();
    command.ItemID = symbolId;

    return this.sendCommand(command);
  }

  public unsubscribeFullMarketDepth(symbolId: number): ICommandPromise {
    const command = new Command_UnsubscribeFullMarketDepth();
    command.ItemID = symbolId;

    return this.sendCommand(command);
  }

  public loadSymbols(): ICommandPromise {
    const command = new Command_GetItems();

    return this.sendCommand(command);
  }
}
