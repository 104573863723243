import {Injectable} from '@angular/core';
import {Trader} from '@common/trader/models/trader';
import {PortfolioService} from '@common/portfolio/portfolio.service';
import {Accounting} from '@common/trader/models/accounting.service';
import { Subscription } from 'rxjs';
import {translate, TranslatorService} from '@common/locale/servises/translator.service';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';

const MAXLENGTHARRAYCHARTS = 7;

export interface ChartObjData {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})

export class ChallengeService {

  private daysPassed = 0;
  private totalDays: number;
  private deadline = false;
  private multi: any[] = [];
  private colorNotificationChallengeResult = false;
  private traderEODSnapshotsArray = [];
  private answerSettings = false;
  private answerTraderEODSnapshots = false;
  private completionChallengeProgressBarInPercent = 0;
  private losingChallengeProgressBarInPercent = 0;
  private losingChallengeProgressBarInPercentToday = 0;
  private emitterAnswerTraderChallenge: Subscription;
  private subscribeChallenge = false;
  private readonly currentDate: Date;
  private subscribeUpdateArrayChartsChallenge: Subscription;

  // options charts
  private colorLosing = '#E51849';
  private colorCompletion = '#13AD7F';
  private transparent = 'rgba(255, 255, 255, 0)';
  private showXAxis = true;
  private showYAxis = true;
  private gradient = false;
  private showLegend = false;
  private showXAxisLabel = false;
  private showYAxisLabel = false;
  private animations = true;
  private showDataLabel = false;
  private tooltipDisabled = false;
  private noBarWhenZero = true;
  private rotateXAxisTicks = false;
  private customColors: ChartObjData[] = [];
  private customColorsYAxis: ChartObjData[] = [];

  private localeChangedSubscribe: ISmartSubscription;

  constructor(private trader: Trader,
              private portfolioService: PortfolioService,
              private translationService: TranslatorService,
              private accounting: Accounting) {
    this.currentDate = new Date();
  }

  public challengeInit(): void {
    this.createCustomColors();
    if (this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeEODSnapshot !== undefined &&
        this.portfolioService.traderChallengeEODSnapshot.traderEODSnapshots !== undefined) {
      this.traderEODSnapshotsArray = this.portfolioService.traderChallengeEODSnapshot.traderEODSnapshots;
      this.answerTraderEODSnapshots = true;
      this.answerSettings = true;
      this.runRenderChartsChallenge();
    } else {
      this.portfolioService.loadTraderChallengeSettings();
      this.portfolioService.loadTraderChallengeEODSnapshot();
      this.subscribeChallenge = true;
      this.emitterAnswerTraderChallenge = this.portfolioService.EmitterAnswerTraderChallenge.subscribe((data) => {
        if ( data === 'Settings') {
          this.answerSettings = true;
        } else if (data === 'EODSnapshot' && this.portfolioService.traderChallengeEODSnapshot.traderEODSnapshots !== undefined) {
          this.traderEODSnapshotsArray = this.portfolioService.traderChallengeEODSnapshot.traderEODSnapshots;
          this.answerTraderEODSnapshots = true;
        }
        this.runRenderChartsChallenge();
      });
    }

    if (this.localeChangedSubscribe === undefined) {
      this.localeChangedSubscribe = this.translationService.LocaleChanged.subscribe(() => {
        this.multi = [];
        this.createCustomColors();
        this.runRenderChartsChallenge();
      });
    }
  }

  private runRenderChartsChallenge() {
    if (this.answerSettings && this.answerTraderEODSnapshots) {
      this.getArrayChallengeEODSnapshot(this.traderEODSnapshotsArray);

      if (!this.IsReadOnlyAccount) {
        this.getLastDayChallengeArrayEODSnapshot();
      }

      if (this.subscribeUpdateArrayChartsChallenge === undefined) {
        this.subscribeUpdateArrayChartsChallenge = this.accounting.ChangUPLEmitter.subscribe(u => {
          this.getLastDayChallengeArrayEODSnapshot();
        });
      }
    }
    this.calculateDaysPassed();
  }

  private createCustomColors(): void {
    this.customColors = [];
    this.customColorsYAxis = [];

    this.customColors = [
      {
        name: translate('Challenge_ChallengeNotification_DrawdownLabel'),
        value: this.colorLosing
      },
      {
        name: translate('Challenge_ChallengeNotification_ProfitLabel'),
        value: this.colorCompletion
      }
    ];

    this.customColorsYAxis = [
      {
        name: translate('Challenge_ChallengeNotification_DrawdownLabel'),
        value: this.transparent
      },
      {
        name: translate('Challenge_ChallengeNotification_ProfitLabel'),
        value: this.transparent
      }
    ];
  }

  public challengeDestroy() {
    if (this.subscribeChallenge) {
      this.emitterAnswerTraderChallenge.unsubscribe();
      this.subscribeChallenge = false;
    }
    this.multi = [];
    this.answerSettings = false;
    this.answerTraderEODSnapshots = false;
    if (this.subscribeUpdateArrayChartsChallenge !== undefined && !this.subscribeUpdateArrayChartsChallenge.closed) {
      this.subscribeUpdateArrayChartsChallenge.unsubscribe();
      this.subscribeUpdateArrayChartsChallenge = undefined;
    }

    if (this.localeChangedSubscribe) {
      this.localeChangedSubscribe.unsubscribe();
      this.localeChangedSubscribe = undefined;
    }

  }

  public get Multi() {
    return this.multi;
  }

  private get IsReadOnlyAccount() {
    return this.trader.IsReadOnlyAccount;
  }

  // метод для получения даны и отображение её в блоке
  public getStarDateChallenge() {
    if (this.answerSettings) {
      if (this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.startDate !== undefined) {
        let res = '';
        const firstSplit = new Date(this.portfolioService.traderChallengeSettings.startDate).toDateString().split(' ');
        res += firstSplit[2] + '-' + firstSplit[1] + '-' + firstSplit[3];
        return res;
      } else {
        return 'NA';
      }

    } else {
      return '';
    }
  }

  public getEndDateChallenge() {
    if (this.answerSettings) {
      if (this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.endDate !== undefined
            && this.portfolioService.traderChallengeSettings.endDate !== null) {
        let res = '';
        const firstSplit = new Date(this.portfolioService.traderChallengeSettings.endDate).toDateString().split(' ');
        res += firstSplit[2] + '-' + firstSplit[1] + '-' + firstSplit[3];
        return res;
      } else {
        return 'NA';
      }

    } else {
      return '';
    }
  }

  public calculateDaysPassed() {
    if (this.answerSettings) {
      if (this.portfolioService.traderChallengeSettings.endDate !== null && this.portfolioService.traderChallengeSettings.endDate !== undefined) {
        const localTimeZone = new Date().getTimezoneOffset() * 60 * 1000;
        let currentDate: number;

        if (this.portfolioService.traderChallengeSettings.closeDate !== null) {
          currentDate = Date.parse(this.portfolioService.traderChallengeSettings.closeDate);
        } else {
          currentDate = this.trader.ServerTime !== undefined ? Date.parse(this.trader.ServerTime.toString()) - localTimeZone : Date.now();
        }

        const endDateChallenge = Date.parse(this.portfolioService.traderChallengeSettings.endDate);
        const startDateChallenge = Date.parse(this.portfolioService.traderChallengeSettings.startDate);
        const daysPassed = (endDateChallenge - currentDate + localTimeZone) / 1000 / 60 / 60 / 24;

        if (Math.ceil(daysPassed) > 0) {
          this.daysPassed = Math.ceil(daysPassed);
          const difference = Math.ceil((endDateChallenge - startDateChallenge) / 1000 / 60 / 60 / 24) - this.daysPassed;
          this.deadline = false;
          return difference;
        } else {
          if (this.portfolioService.traderChallengeSettings.closeReason === 'account expired') {
            this.deadline = true;
          }
        }
      }

    } else {
      return '--';
    }
  }

  public getDaysPassedProgress() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings.endDate !== undefined && this.portfolioService.traderChallengeSettings.startDate !== undefined
    && !this.IsReadOnlyAccount) {
      const startDateChallenge = Date.parse(this.portfolioService.traderChallengeSettings.startDate);
      const endDateChallenge = Date.parse(this.portfolioService.traderChallengeSettings.endDate);
      this.totalDays = Math.ceil((endDateChallenge - startDateChallenge) / 1000 / 60 / 60 / 24);
      if (this.daysPassed > 0) {
        return 100 - (this.daysPassed / this.totalDays) * 100;
      } else {
        return 100;
      }

    } else {
      return 0;
    }
  }

  public get Deadline(): boolean {
    return this.deadline;
  }

  public getDaysPassed() {
    if (this.daysPassed !== undefined && !this.IsReadOnlyAccount) {
      return this.daysPassed;
    } else {
      return '--';
    }
  }

  // показывает сколько всего дней испытания
  public get TotalDays(): number | string {
    if (this.totalDays !== undefined && !this.IsReadOnlyAccount) {
      return this.totalDays;
    } else {
      return '--';
    }
  }

  // метод для получения даных и отображения прогрессы выполнения
  public getConfigCompletionChallengeInDollar() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.profitTargetValue !== null) {
      return this.portfolioService.traderChallengeSettings.profitTargetValue;
    } else {
      return 'NA';
    }
  }

  public getConfigCompletionChallengeInPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.profitTargetPercent !== null) {
      return this.portfolioService.traderChallengeSettings.profitTargetPercent;
    } else {
      return 'NA';
    }
  }

  public getCompletionChallengeProgressInDollar(): number | string {
    if (this.answerSettings &&  this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== null ) {

      const progresse = this.getProgress();

      if (progresse > 0) {
        return Number(progresse.toFixed(2));
      } else {
        return 0;
      }

    } else {
      return 'NA';
    }
  }

  public getCompletionChallengeProgressInPercent(): number | string {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== null) {

      const progresse = this.getProgress();

      if (progresse === 0 || isNaN(progresse)) {
        return 0;
      }

      if (progresse > 0) {
        const p = (progresse / this.portfolioService.traderChallengeSettings.initialBalance) * 100;
        if (p > 100) {
          return '> 100';
        }
        return Number(p.toFixed(2));
      } else {
        return 0;
      }

    } else {
      return 'NA';
    }
  }

  private getProgress(): number {
    // return this.accounting.Balance - this.portfolioService.traderChallengeSettings.initialBalance + this.accounting.UPL;
    return this.accounting.Balance - this.portfolioService.traderChallengeSettings.initialBalance;
  }

  public getCompletionChallengeFinish(): boolean {
    return this.completionChallengeProgressBarInPercent > 99;
  }

  // метод для получения даных и отображения прогрессы выполнения
  public getConfigLosingChallengeInDollar() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.totalDrawdownValue !== null) {
      return this.portfolioService.traderChallengeSettings.totalDrawdownValue;
    } else {
      return 'NA';
    }
  }

  public getConfigLosingChallengeInPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.totalDrawdownPercent !== null) {
      return this.portfolioService.traderChallengeSettings.totalDrawdownPercent;
    } else {
      return 'NA';
    }
  }

  public getLosingChallengeProgressInDollar(): number | string {
    if (this.answerSettings &&  this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== null ) {

      const losingChallengeProgress =
        this.portfolioService.traderChallengeSettings.initialBalance - this.portfolioService.NetAccountValue;

      if (losingChallengeProgress < 0) {
        return 0;
      } else {
        return Number(losingChallengeProgress.toFixed(2));
      }

    } else {
      return 'NA';
    }
  }

  public getLosingChallengeProgressInPercent(): number | string {

    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== null) {

      const losingChallengeProgress = (
        (this.portfolioService.traderChallengeSettings.initialBalance - this.portfolioService.NetAccountValue)
        / this.portfolioService.traderChallengeSettings.initialBalance) * 100;

      const s = (Math.round(losingChallengeProgress * 100)) / 100;

      if (isNaN(s)) {
        return 0;
      }

      if (s < 0) {
        return 0;
      } else if (s > 100) {
        return 100;
      } else {
        return s;
      }

    } else {
      return 'NA';
    }
  }

  // данный для прогресса бара
  public getLosingChallengeProgressBarInPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.totalDrawdownValue !== null) {
      let progressInDollar;
      if (this.getLosingChallengeProgressInDollar() === 'NA') {
        return 0;
      } else {
        progressInDollar = this.getLosingChallengeProgressInDollar();
      }

      const losingChallengeProgress = progressInDollar / this.portfolioService.traderChallengeSettings.totalDrawdownValue * 100;

      const s = (Math.round(losingChallengeProgress * 100)) / 100;

      if (s < 0) {
        return 0;
      } else if (s > 100) {
        return 100;
      } else {
        this.losingChallengeProgressBarInPercent = s;
        return s;
      }
    } else {
      return 0;
    }
  }

  // метод для получения даных и отображения дневных условий
  public getConfigDailyDrawdownInDollar() {
    if (this.answerSettings &&
        this.portfolioService.traderChallengeSettings !== undefined &&
        this.portfolioService.traderChallengeSettings.dailyDrawdownPercent !== null &&
        this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC !== null) {
      const resultConfigDailyDrawdownInDollar = (this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC *
        this.portfolioService.traderChallengeSettings.dailyDrawdownPercent) / 100;

      return Number(resultConfigDailyDrawdownInDollar.toFixed(2));
    } else {
      return 'NA';
    }
  }

  public getConfigDailyDrawdownPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined &&
        this.portfolioService.traderChallengeSettings.dailyDrawdownPercent !== null) {
      return this.portfolioService.traderChallengeSettings.dailyDrawdownPercent;
    } else {
      return 'NA';
    }
  }

  // проверка условия достижения прогрессы для отображения иконки о завершении
  public getLosingChallengeFinish(): boolean {
    return this.losingChallengeProgressBarInPercent >= 100;
  }

  // если данные не поступают, то бокс скрывается и данные передаются в сеттинг для скрытия кнопки
  public showChallengeBox(): boolean {
    if (this.trader.IsChallengeAccount) {
      if (this.answerSettings) {
        return this.trader.IsChallengeAccount;
      }
      return false;
    }
  }

  // определяет наименьшее значения либо баланс либо эквити
  private getMinCurrentValueBalanceToEquity() {
    if (this.portfolioService.NetAccountValue > this.accounting.Balance) {
      return this.accounting.Balance;
    } else {
      return this.portfolioService.NetAccountValue;
    }
  }

  // проверка условия достижения прогрессы для отображения иконки о завершении
  public getLosingChallengeFinishToday(): boolean {
    if (this.IsReadOnlyAccount && this.portfolioService.traderChallengeSettings.closeReason === 'lost by daily criteria') {
      return true;
    } else {
      return this.losingChallengeProgressBarInPercentToday >= 100;
    }
  }

  // данный для прогресса бара
  public getLosingChallengeProgressBarInPercentToday() {
    if (this.answerSettings &&
      this.portfolioService.traderChallengeSettings !== undefined &&
      this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC !== null &&
      this.portfolioService.traderChallengeSettings.dailyDrawdownPercent !== null &&
      this.portfolioService.traderChallengeSettings.tradeDayStartBalanceAC !== null) {

      let progressInDollar;

      if (this.getTodayGainLossInDollar() === 'NA') {
        return 0;
      } else {
        progressInDollar = this.getTodayGainLossInDollar();
      }

      let losingChallengeProgressInDollar;
      if (this.getConfigDailyDrawdownInDollar() === 'NA') {
        return 0;
      } else {
        losingChallengeProgressInDollar = this.getConfigDailyDrawdownInDollar();
      }

      const losingChallengeProgress = Math.abs(progressInDollar) / losingChallengeProgressInDollar * 100;

      const s = (Math.round(losingChallengeProgress * 100)) / 100;

      if (s < 0) {
        return 0;
      } else if (s > 100) {
        return 100;
      } else {
        this.losingChallengeProgressBarInPercentToday = s;
        return s;
      }
    } else {
      return 0;
    }
  }

  // данные для секции текущего дня
  public getTodayGainLossInDollar() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC !== null) {
      let s: number;
      if (this.IsReadOnlyAccount && this.portfolioService.traderChallengeSettings.closeReason === 'lost by daily criteria' && this.portfolioService.traderChallengeSettings.onCloseEquityAC !== null) {
        s = this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC - this.portfolioService.traderChallengeSettings.onCloseEquityAC;
      } else {
        s = this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC - this.portfolioService.NetAccountValue;
      }

      if (s === 0 || s < 0) {
        return 0;
      }
      return Math.abs(Number(s.toFixed(2)));
    } else {
      return 'NA';
    }
  }

  public getTodayGainLossPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC !== null) {
      let d;
      if (this.IsReadOnlyAccount && this.portfolioService.traderChallengeSettings.closeReason === 'lost by daily criteria' && this.portfolioService.traderChallengeSettings.onCloseEquityAC !== null ) {
        d = this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC - this.portfolioService.traderChallengeSettings.onCloseEquityAC;
      } else {
        d = this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC - this.portfolioService.NetAccountValue;
      }

      if (d === 0 || d < 0) {
        return 0;
      } else {
        const challengeProgress = Math.abs(d) / this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC * 100;
        const s = (Math.round(challengeProgress * 100)) / 100;

        if (isNaN(s)) {
          return 0;
        } else {
          return s;
        }
      }
    } else {
      return 'NA';
    }
  }

  public getCompletionChallengeProgressBarInPercent() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.profitTargetValue !== null) {
      let progressInDollar;
      if (this.getCompletionChallengeProgressInDollar() === 'NA') {
        return 0;
      } else {
        progressInDollar = this.getCompletionChallengeProgressInDollar();
      }

      const progresse = progressInDollar / this.portfolioService.traderChallengeSettings.profitTargetValue * 100;
      const s = (Math.round(progresse * 100)) / 100;

      if (s < 0) {
        return 0;
      } else  {
        this.completionChallengeProgressBarInPercent = s;
        return s;
      }
    } else {
      return 0;
    }
  }

  public showGraph(): boolean {
    return this.answerTraderEODSnapshots && this.traderEODSnapshotsArray.length > 0;
  }

  public getTradeDayStartBalance() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC !== null) {
      return '$' + ' ' + this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'NA';
    }
  }

  public getSowInstantFundingGroupsChallenge() {
    return this.answerSettings && this.portfolioService.traderChallengeSettings.endDate == null;
  }


  public getSowInstantFundingGroupsChallengeProfit() {
    return this.answerSettings &&
      this.portfolioService.traderChallengeSettings.profitTargetValue !== null && this.portfolioService.traderChallengeSettings.profitTargetValue > 0 &&
      this.portfolioService.traderChallengeSettings.profitTargetPercent !== null && this.portfolioService.traderChallengeSettings.profitTargetPercent > 0 ;
  }

  public getShowDataDailyLossIsReadOnlyAccount() {
    return this.IsReadOnlyAccount && this.portfolioService.traderChallengeSettings.closeReason !== 'won the challenge';
  }

  public getDateCloseAccountChallenge() {
    if (this.answerSettings) {
      let res = '';
      let firstSplit;

      if (this.IsReadOnlyAccount && this.portfolioService.traderChallengeSettings.closeDate == null) {
        firstSplit = new Date().toDateString().split(' ');
      } else {
        firstSplit = new Date(this.traderEODSnapshotsArray[this.traderEODSnapshotsArray.length - 1].tradeDay).toDateString().split(' ');
      }

      res += firstSplit[2] + '-' + firstSplit[1] + '-' + firstSplit[3];
      return res;

    } else {
      return '';
    }
  }

  private getArrayChallengeEODSnapshot(arr: any[]): void {

    if (this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== undefined) {

      let balance = this.portfolioService.traderChallengeSettings.initialBalance;

      const d = [];
      let oldTime: string;

      arr.forEach((e) => {
        let nameResult: string;
        let s;
        if (e.balanceAC >= e.equityAC) {
          s = e.equityAC;
        } else {
          s = e.balanceAC;
        }
        const valuetradeDay = s - balance;

        balance = s;

        if (valuetradeDay > 0) {
          nameResult = translate('Challenge_ChallengeNotification_ProfitLabel');
        } else {
          nameResult = translate('Challenge_ChallengeNotification_DrawdownLabel');
        }

        const time = this.processingDateFormats(e.tradeDay);

        if (oldTime !== time) {
          const a = {name: time, series: [{name: nameResult, value: Number(valuetradeDay.toFixed(2))}]};
          d.push(a);
          oldTime = time;
        }
      });

      this.multi = [...d.slice(1)];
    }
  }

  private getLastDayChallengeArrayEODSnapshot() {

    const d = [...this.multi.slice(0, this.traderEODSnapshotsArray.length - 1)];

    if (this.portfolioService.traderChallengeSettings.closeDate == null || this.currentDate === new Date(this.portfolioService.traderChallengeSettings.closeDate) ) {

      const nowDate = this.getCurrentDate();
      const valueCurrentDay = this.portfolioService.NetAccountValue - this.portfolioService.traderChallengeSettings.tradeDayStartEquityAC;
      let nameCurrentDa: string;

      if (valueCurrentDay > 0) {
        nameCurrentDa = translate('Challenge_ChallengeNotification_ProfitLabel');
      } else {
        nameCurrentDa = translate('Challenge_ChallengeNotification_DrawdownLabel');
      }

      const currentValueCharts = {name: nowDate, series: [{name: nameCurrentDa, value: Number(valueCurrentDay.toFixed(2))}]};

      d.push(currentValueCharts);

    }

    let name = '';
    if (d.length < MAXLENGTHARRAYCHARTS) {
      const count = MAXLENGTHARRAYCHARTS - d.length;

      for (let i = 0; i < count; i++) {
        name = name + ' ';
        const s = {name: name, series: [{name: 'Profit', value: 0}]};
        d.push(s);
      }
    }

    this.multi = [...d];
  }

  private getCurrentDate() {
    const nowDate = new Date();
    const date = this.checkZeroAfter(nowDate.getDate());
    const month = this.checkZeroAfter(nowDate.getMonth() + 1);
    return `${date}/${month}`;
  }

  private checkZeroAfter(value: number): string {
    const s = value.toString();
    if (s.length < 2) {
      return `0${s}`;
    } else {
      return s;
    }
  }

  private processingDateFormats(time: string): string {
    let res = '';
    const firstSplit = time.split(' ');
    const secondSplit = firstSplit[0].split('/');
    res += secondSplit[1] + '/' + secondSplit[0];
    return res;
  }

  public getInitialsBalance() {
    if (this.answerSettings && this.portfolioService.traderChallengeSettings !== undefined && this.portfolioService.traderChallengeSettings.initialBalance !== null) {
      return '$' + ' ' + this.portfolioService.traderChallengeSettings.initialBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return 'NA';
    }
  }

  public getNotificationChallengeResult() {
    if (this.answerSettings) {
      if (this.portfolioService.traderChallengeSettings.closeReason === 'won the challenge') {
        this.colorNotificationChallengeResult = true;
        return translate('Challenge_ChallengeNotification_Congratulations');
      } else {
        return translate('Challenge_ChallengeNotification_Closed');
      }
    } else {
      return '';
    }
  }

  public get ColorNotificationChallengeResult(): boolean {
    return this.colorNotificationChallengeResult;
  }

  public reRequestingData() {
   this.portfolioService.destroyTraderChallengeEODSnapshot();
   this.emitterAnswerTraderChallenge.unsubscribe();
   this.subscribeChallenge = false;
   this.answerSettings = false;
   this.traderEODSnapshotsArray = [];
   this.answerTraderEODSnapshots = false;
   this.challengeInit();
  }

  public get CustomColors(): ChartObjData[] {
    return this.customColors;
  }

  public get CustomColorsYAxis(): ChartObjData[] {
    return this.customColorsYAxis;
  }

  public get ColorLosing(): string {
    return this.colorLosing;
  }

  public get ColorCompletion(): string {
    return this.colorCompletion;
  }

  public get ShowXAxis(): boolean {
    return this.showXAxis;
  }

  public get ShowYAxis(): boolean {
    return this.showYAxis;
  }

  public get Gradient(): boolean {
    return this.gradient;
  }

  public get ShowLegend(): boolean {
    return this.showLegend;
  }

  public get ShowXAxisLabel(): boolean {
    return this.showXAxisLabel;
  }

  public get ShowYAxisLabel(): boolean {
    return this.showYAxisLabel;
  }

  public get Animations(): boolean {
    return this.animations;
  }

  public get ShowDataLabel(): boolean {
    return this.showDataLabel;
  }

  public get TooltipDisabled(): boolean {
    return this.tooltipDisabled;
  }

  public get NoBarWhenZero(): boolean {
    return this.noBarWhenZero;
  }

  public get RotateXAxisTicks(): boolean {
    return this.rotateXAxisTicks;
  }

}
