export class Session {

  private readonly accessKey: string;
  private readonly login: string;
  private readonly traderId: number;
  private readonly timeValidity: number;
  private expirationTime: Date;

  public get AccessToken(): string {
    if (new Date() > this.ExpirationTime) {
      return null;
    } else {
      return this.accessKey;
    }
  }

  public get Login(): string {
    return this.login;
  }

  public get TraderId(): number {
    return this.traderId;
  }

  public get ExpirationTime() {
    return new Date(this.expirationTime);
  }

  public constructor(access: string, date: Date, timeValidityInMinutes: number, traderId: number, login: string) {
    this.accessKey = access;
    this.timeValidity = timeValidityInMinutes;
    this.expirationTime = date;
    this.traderId = traderId;
    this.login = login;
  }

  public prolongTime() {
    this.expirationTime = this.expirationTime !== undefined ? new Date(
      this.expirationTime.setMinutes(this.expirationTime.getMinutes() + this.timeValidity)) : new Date();
  }
}
