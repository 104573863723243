import {LoggerFactory} from '@common/common/utils/logging/logger-factory';

export class Timer {
  private timerId = undefined;
  private _IsExecuted = false;
  private logger = LoggerFactory.getLogger(this);
  public get IsExecuted(): boolean {
    return this._IsExecuted;
  }

  public get IsDeleted(): boolean {
    return this.timerId == null;
  }

  public get IsActive(): boolean {
    return this.timerId != null;
  }

  constructor(private func: () => void, private interrupt: number = null, private autokill = true) {
    this.logger.debug('created')
  }

  public start(): Timer {
    this.timerId = setTimeout(() => {
      this.func();

      this._IsExecuted = true;
      this.logger.debug('executed with id:', this.timerId);

      if(this.autokill){
        this.remove();
      }
    }, this.interrupt);
    this.logger.debug('Started with id:', this.timerId);

    return this;
  }

  public remove() {
    clearTimeout(this.timerId);
    this.logger.debug('Removed with id:', this.timerId);
    this.timerId = null;
  }
}
