import {HistoryBar} from './history-bar';
import {Metadata} from './metadata';

export class BarsAndMetadata {
  private _bars: HistoryBar[];
  private readonly _metadata: Metadata;

  public get Bars(): HistoryBar[] {
    return this._bars;
  }

  public get Metadata(): Metadata {
    return this._metadata;
  }

  public set Bars(v) {
    this._bars = v;
  }

  constructor(bars: HistoryBar[], metadata: Metadata) {
    this._bars = bars;
    this._metadata = metadata;
  }
}
