import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {AppConfig} from '@common/configuration/app-config';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {SessionStorageService} from '@common/auth/services/session-storage.service';

export interface UsersGeoData {
  country: string;
  ip: string;
  regionConnect: RegionsConnectEnum;
}

export enum RegionsConnectEnum {
  Europe = 'europe',
  Asia = 'asia',
  Africa = 'africa',
  America = 'america',
}

export interface Region {
  code: RegionsConnectEnum;
  name: string;
}


@Injectable({
  providedIn: 'root'
})
export class LocationPositionService {

  private regionsConnect: Region[] = [
    { code: RegionsConnectEnum.Europe, name: 'Europe'},
    { code: RegionsConnectEnum.Asia, name: 'Asia'},
    { code: RegionsConnectEnum.Africa, name: 'Africa'},
    { code: RegionsConnectEnum.America, name: 'America'},
  ];

  private readonly activeRegionsConnect: Region[] = [];
  private currentRegionConnect: RegionsConnectEnum = undefined;


  constructor(private http: HttpClient,
              private config: AppConfig,
              private localStorageService: LocalStorageService,
              private sessionStorageService: SessionStorageService) {

    this.activeRegionsConnect = this.createActiveRegionsConnect();

    if (this.localStorageService.getUsersGeoData()) {
      const usersGeoData: UsersGeoData = this.localStorageService.getUsersGeoData();
      this.currentRegionConnect = usersGeoData.regionConnect as RegionsConnectEnum;
    } else {
      if (this.activeRegionsConnect[0]) {
        this.currentRegionConnect = this.activeRegionsConnect[0].code;
      }
    }

  }

  public init() {
    if (!this.localStorageService.getUsersGeoData()) {
      this.getData('https://api.ipify.org?format=json').subscribe((data) => {
        this.getData('https://ipinfo.io/' + data['ip'] + '/json').subscribe(dataLocal => {
          const usersGeoData: UsersGeoData = {
            country: dataLocal.country,
            ip: dataLocal.ip,
            regionConnect: this.getRegion(dataLocal.timezone),
          };
          this.localStorageService.saveUsersGeoData(usersGeoData);
          this.changeRegion(usersGeoData.regionConnect);
        });
      });
    }
  }


  private getRegion(timezone: string): RegionsConnectEnum {
    const firstSplit = timezone.split('/');
    switch (firstSplit[0]) {
      case 'Asia':
        return 'asia' as RegionsConnectEnum;
      case 'America':
        return 'america' as RegionsConnectEnum;
      case 'Africa':
        return 'africa' as RegionsConnectEnum;
      default:
        return 'europe' as RegionsConnectEnum;
    }
  }

  private getData(path: string): Observable<any> {
    return this.http.get(path);
  }

  public get Regions(): Region[] {
    return this.activeRegionsConnect;
  }

  public get CurrentRegionConnect(): RegionsConnectEnum {
    return this.currentRegionConnect;
  }

  public changeRegion(region: RegionsConnectEnum): void {
    this.currentRegionConnect = region;
    this.changeRegionForSaveLocalStorage(region);
  }

  private changeRegionForSaveLocalStorage(region: RegionsConnectEnum): void {
    const usersGeoData: UsersGeoData = this.localStorageService.getUsersGeoData();
    if (usersGeoData !== undefined) {
      usersGeoData.regionConnect = region;
      this.localStorageService.saveUsersGeoData(usersGeoData);
    }

    this.sessionStorageService.saveRegionConnect(region);
  }

  private createActiveRegionsConnect(): Region[] {
    const tempArray: Region[] = [];

    if (this.config.Settings.connection.regionsToConnectTo) {
      const objKeys = objectKeys(this.config.Settings.connection.regionsToConnectTo);
      objKeys.forEach((e: string) => {
        this.regionsConnect.forEach((i: Region) => {
          if (e === i.code) {
            tempArray.push(i);
          }
        });
      });
    }

    return tempArray;

  }
}
