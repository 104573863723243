import {Injectable} from '@angular/core';
import {
  ContextMenuActionType,
  IChartContextMenu,
  IChartContextMenuItem
} from '@chartiq-core/trade-from-chart/models/trade-from-chart-interfaces';
import {SettingsService} from '@common/trader/services/settings.service';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeFromChartCalculatorService} from '@chartiq-core/trade-from-chart/services/trade-from-chart-calculator.service';
import {ChartStrings} from '@common/charting/models/chart-strings';

@Injectable({
  providedIn: 'root'
})
export class ChartContextMenuComposerService {

  constructor(private settingsService: SettingsService,
              private tfcCalculator: TradeFromChartCalculatorService) {
  }

  public composeMenu(symbol: Symbol, selectedPrice: number, lastPrice: number, volume: number): IChartContextMenu {
    const items: Array<IChartContextMenuItem> = [];
    const symbolName = symbol.SymbolName;

    if (selectedPrice > lastPrice) {
      // Buy Stop
      // Sell Limit
      items.push({
        label: ChartStrings.OpenBuyStop + ' ' + symbolName,
        description: this.getPendingLabel(selectedPrice, volume, symbol),
        actionType: ContextMenuActionType.BuyStop,
        volume: this.tfcCalculator.getStopOrderVolume(symbol, selectedPrice, false)
      });
      items.push({
        label: ChartStrings.OpenSellLimit + ' ' + symbolName,
        description: this.getPendingLabel(selectedPrice, volume, symbol),
        actionType: ContextMenuActionType.SellLimit,
        volume: this.tfcCalculator.getLimitOrderVolume(symbol, selectedPrice, true)
      });
    } else {
      // Sell Stop
      // Buy Limit
      items.push({
        label: ChartStrings.OpenSellStop + ' ' + symbolName,
        description: this.getPendingLabel(selectedPrice, volume, symbol),
        actionType: ContextMenuActionType.SellStop,
        volume: this.tfcCalculator.getStopOrderVolume(symbol, selectedPrice, true)
      });
      items.push({
        label: ChartStrings.OpenBuyLimit + ' ' + symbolName,
        description: this.getPendingLabel(selectedPrice, volume, symbol),
        actionType: ContextMenuActionType.BuyLimit,
        volume: this.tfcCalculator.getLimitOrderVolume(symbol, selectedPrice, false)
      });
    }

    // Market Orders...
    if (this.settingsService.Settings.OneClickTrading) {
      items.push({
        label: ChartStrings.OpenSellMarket + ' ' + symbolName,
        description: this.getMarketLabel(volume, symbol),
        actionType: ContextMenuActionType.MarketSellQuick,
        volume: this.tfcCalculator.getMarketSellVolume(symbol)
      });
      items.push({
        label: ChartStrings.OpenBuyMarket + ' ' + symbolName,
        description: this.getMarketLabel(volume, symbol),
        actionType: ContextMenuActionType.MarketBuyQuick,
        volume: this.tfcCalculator.getMarketBuyVolume(symbol)
      });
    } else { // One Click Trading DISABLED
      items.push({
        label: ChartStrings.OpenCreateNewMarketSellOrder,
        actionType: ContextMenuActionType.MarketSellDialog
      });
      items.push({
        label: ChartStrings.OpenCreateNewMarketBuyOrder,
        actionType: ContextMenuActionType.MarketBuyDialog
      });
    }

    // Trade Settings...
    items.push({
      label: ChartStrings.OpenSettings,
      actionType: ContextMenuActionType.TradeSettings
    });

    return {
      items: items,
      selectedPrice: selectedPrice,
      symbolName: symbolName
    };
  }


  private getPendingLabel(selectedPrice: number, volume: number, symbol: Symbol): string {
    return volume.toFixed(symbol.VolumeDecimalPlaces) + ' @ ' + selectedPrice.toFixed(symbol.DecimalPlaces);
  }

  private getMarketLabel(volume: number, symbol: Symbol): string {
    return volume.toFixed(symbol.VolumeDecimalPlaces);
  }
}
