import {Directive, Input} from '@angular/core';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeType} from '@common/trade/models/trade-type';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';
import {ChangeableValidator} from '@common/shared/directives/validators/changeable-validator';

@Directive({
  selector: '[order-price]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: OrderPriceValidatorDirective,
    multi: true
  }]
})
export class OrderPriceValidatorDirective extends ChangeableValidator{

  @Input()
  symbol: Symbol;

  @Input()
  type: TradeType;

  @Input()
  askPrice: number;

  @Input()
  bidPrice: number;

  constructor() {
    super()
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = Number(control.value);

    switch (this.type) {
      case TradeType.BuyStop: {
        return this.symbol.Ask < value ? null : {
          minValue: {limit: this.symbol.Ask, actual: value}
        }
      }
      case TradeType.BuyLimit: {
        return this.symbol.Ask > value ? null : {
          maxValue: {limit: this.symbol.Ask, actual: value}
        }
      }
      case TradeType.SellStop: {
        return this.symbol.Bid > value ? null : {
          maxValue: {limit: this.symbol.Ask, actual: value}
        }
      }
      case TradeType.SellLimit: {
        return this.symbol.Bid < value ? null : {
          minValue: {limit: this.symbol.Ask, actual: value}
        }
      }
      default: return null;
    }
  }
}
