import {LibrarySymbolInfo, ResolutionString} from '@tradingview/charting_library';
import {Injectable} from '@angular/core';
import {RetrieveBarsResult} from '@common/charting/models/retrieve-bars-result';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Trader} from '@common/trader/models/trader';
import {Logger} from '@common/common/utils/logging/logger';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {BarsAndMetadata} from '@common/trading-view-charts/models/bars-and-metadata';
import {TradingViewItemHistoryConnectionService} from '@common/trading-view-charts/services/trading-view-item-history-connection.service';
import {HistoryBar} from '@common/trading-view-charts/models/history-bar';
import {Metadata} from '@common/trading-view-charts/models/metadata';

export interface IBarProvider {
  retrieveBars(symbolInfo: LibrarySymbolInfo,
               resolution: ResolutionString,
               rangeStartDate: number,
               rangeEndDate: number,
               isFirstCall: boolean,
               priceDirection: PriceDirection): Promise<BarsAndMetadata>;
}

@Injectable({
  providedIn: 'root'
})
export class BarProviderService implements IBarProvider {

  private logger: Logger = LoggerFactory.getLogger('BarProviderService');

  public constructor(private symbolStorage: SymbolStorageService,
                     private itemHistoryService: TradingViewItemHistoryConnectionService,
                     private trader: Trader) {}

  public async retrieveBars(symbolInfo: LibrarySymbolInfo,
                            resolution: ResolutionString,
                            rangeStartDate: number,
                            rangeEndDate: number,
                            isFirstCall: boolean,
                            priceDirection: PriceDirection): Promise<BarsAndMetadata> {
    const symbol = await this.symbolStorage.findSymbolByName(symbolInfo.name);

    let retrieveBarsAnswer: RetrieveBarsResult;

    try {
      retrieveBarsAnswer = await this.itemHistoryService.retrieveBars(
        symbol.SymbolId,
        Number(this.trader.TradeGroupId),
        priceDirection,
        resolution,
        rangeStartDate,
        rangeEndDate,
        isFirstCall
      );
    } catch (err) {
      this.logger.error('Error while retrieving bars: ', err);
    }

    console.log('retrieveBarsAnswer', retrieveBarsAnswer);


    const bars: HistoryBar[] = retrieveBarsAnswer.Bars.map(value => {
      const result = new HistoryBar();

      result.open = value.OpenPrice;
      result.high = value.HighPrice;
      result.low = value.LowPrice;
      result.close = value.ClosePrice;
      result.volume = value.Volume;
      result.time = value.Time;

      return result;
    });

    const metadata = new Metadata(retrieveBarsAnswer.HistoryMetadata.NextTime,
      retrieveBarsAnswer.HistoryMetadata.NoData);

    return new BarsAndMetadata(bars, metadata);
  }
}
