import {Component, Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Directive()
abstract class Swipe {
  protected swipeStart: number;

  @Input()
  public _step: number = 10;

  protected eventEmitter = new EventEmitter<void>();

  @HostListener('touchstart', ['$event'])
  public touchStart(event: TouchEvent | any){
    this.writeClientY(event);
  }

  @HostListener('touchmove', ['$event'])
  public touchEnd(event: TouchEvent | any){
    event.preventDefault();
    event.stopPropagation();
    if(this.check(this.getClientY(event))) {
      this.eventEmitter.emit();
    }
    if(this.checkAny(this.getClientY(event))){
      this.writeClientY(event);
    }
  }

  protected checkAny(currentY: number): boolean {
    return (currentY - this._step) > this.swipeStart || (currentY + this._step) < this.swipeStart;
  }

  protected abstract check(currentY: number): boolean;

  protected writeClientY(event: TouchEvent | any){
    this.swipeStart = this.getClientY(event);
  }

  protected getClientY(event: TouchEvent){
    return event.changedTouches[0].clientY;
  }
}

@Directive({
  selector: '[swipeup]'
})
export class SwipeUpDirective extends Swipe{
  @Output('swipeup')
  get Emitter(): Observable<void> {
    return this.eventEmitter.asObservable()
  }

  @Input()
  set step(v: number){
    this._step = v;
  }

  protected check(currentY: number): boolean {
    return (currentY - this._step) > this.swipeStart
  }
}

@Directive({
  selector: '[swipedown]'
})
export class SwipeDownDirective extends Swipe{
  @Output('swipedown')
  get Emitter(): Observable<void> {
    return this.eventEmitter.asObservable()
  }

  @Input()
  set step(v: number){
    this._step = v;
  }

  protected check(currentY: number): boolean {
    return (currentY + this._step) < this.swipeStart
  }
}

@Directive({
  selector: '[height]',
  exportAs: 'height'
})
export class HeightOfDirective implements OnInit {
  public value: number;

  public constructor(private el: ElementRef){

  }

  public ngOnInit(): void {
    this.value = this.el.nativeElement.offsetHeight;
  }
}
