import {Component, OnInit} from '@angular/core';
import {AbstractModal} from '../abstract-modal';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {TradeService} from '@common/trade/services/trade.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TradeType} from '@common/trade/models/trade-type';
import {translate} from '@common/locale/servises/translator.service';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {AppConfig} from '@common/configuration/app-config';

export interface OrderModalInitData {
  orderType: TradeType,
  order: TradeDTO;
  header: string;
  buttonText: string;
}

@Component({
  selector: 'app-modal-open-order',
  templateUrl: './modal-open-order.component.html',
})
export class ModalOpenOrderComponent extends AbstractModal implements OnInit {
  public initData: OrderModalInitData;

  public constructor(private symbolStorage: SymbolStorageService,
                     private tradeService: TradeService,
                     route: ActivatedRoute,
                     router: Router,
                     protected appConfig: AppConfig) {
    super(router, appConfig);

    if(route) {
      this.initData = this.readRoute(route);
    }

    this.initialize();
  }

  protected initialize(){
    this.initModal(this.initData);
  }

  private readRoute(route: ActivatedRoute): OrderModalInitData{
    const params = route.snapshot.params;

    const symbol = this.readSymbol(params);
    const type = this.readType(params);

    const orderPrefilled = <boolean>params['order'];

    let order = undefined;

    if(orderPrefilled) {
      order = this.readOrder(params, type, symbol);
    }

    if(type == TradeType.Buy){
      return {
        orderType: TradeType.Buy,
        order: order,
        header: translate('Modal_OpenOrderModalComponent_BuyHeader'),
        buttonText: translate('Modal_OpenOrderModalComponent_BuyButton')
      }
    }
    else {
      return {
        orderType: TradeType.Sell,
        order: order,
        header: translate('Modal_OpenOrderModalComponent_SellHeader'),
        buttonText: translate('Modal_OpenOrderModalComponent_SellButton')
      }
    }
  }

  private readOrder(params: Params, type, symbol: Symbol): TradeDTO {
    const sl = Number(params['sl']);
    const tp = Number(params['tp']);
    const volume = Number(params['volume']);

    const result = new TradeDTO();

    result.StopLoss = sl;
    result.TakeProfit = tp;
    result.Type = type;
    result.Symbol = symbol;
    result.Volume = volume;

    return result;
  }
  private readType(params: Params): TradeType {
    const result = <TradeType>params['type'];

    return result;
  }
  private readSymbol(params: Params): Symbol {
    const result = params['symbol'];

    const symbol = this.symbolStorage.findSymbolById(result);

    return symbol;
  }

  private initModal(init: OrderModalInitData){
    this.Header = init.header;
  }

  ngOnInit() {
  }
}
