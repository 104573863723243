import {Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {Trader} from '@common/trader/models/trader';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import { Command_GetAllSavedViews, Command_GetSettings, Command_SetSavedView, Command_SetSettings } from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class SavedViewCommandSender extends AbstractCommandSender {
  private logger = LoggerFactory.getLogger(this);

  public constructor(serverConnection: ServerInteractionService) {
    super(serverConnection);
  }

  public loadSavedViews(trader: Trader): ICommandPromise {
    const command = new Command_GetAllSavedViews();

    command.TraderID = trader.TraderId;

    this.logger.debug('Load saved views');
    return this.sendCommand(command);
  }

  public saveView(trader: Trader, viewName: string, viewData: string): ICommandPromise {
    this.logger.debug('Save view');
    const command = new Command_SetSavedView();

    command.TraderID = trader.TraderId;
    command.ViewName = viewName;
    command.ViewData = viewData;

    return this.sendCommand(command);
  }
}
