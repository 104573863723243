import {isBuyTrade, isSellTrade, TradeType} from '../../models/trade-type';
import {Symbol} from '@common/symbol/models/symbol';


export class TradePriceProvider {
  public static getTradeCurrentPrice(symbol: Symbol, type: TradeType): number {
    if (isBuyTrade(type)) {
      return symbol.Ask;
    } else {
      return symbol.Bid;
    }
  }
  public static getTradeClosingPrice(symbol: Symbol, type: TradeType): number {
    if (isSellTrade(type)) {
      return symbol.Ask;
    } else {
      return symbol.Bid;
    }
  }
}
