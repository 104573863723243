import {TradeDTO} from '../models/trade-d-t-o';
import {isBuyTrade} from '../models/trade-type';
import {IncorrectTakeProfitError} from '../models/exceptions/incorrect-take-profit.error';
import {IncorrectStopLossError} from '../models/exceptions/incorrect-stop-loss.error';
import {IncorrectVolumeError} from '../models/exceptions/incorrect-volume-error';
import {AppConfig} from '@common/configuration/app-config';
import {IAppConfig} from '@common/configuration/app-config-interface';
import {MarginCalc, MarginCalcFactory} from '@common/trade/utils/calculations/margin/margin-calc-factory';
import {PriceFormatter} from '@common/trade/utils/price-formatter';
import {Accounting} from '../../trader/models/accounting.service';
import {PriceCalculator} from '@common/trade/utils/price-calculator';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import {IMarginCalc} from '@common/shared/calculators/Margin/IMarginCalc.g';
import {Factory} from '@common/shared/calculators/Margin/Factory.g';
import {IncorrectPriceError} from '@common/trade/models/exceptions/incorrect-price.error';
import {IncorrectBoundsError} from '@common/trade/models/exceptions/incorrect-bounds.error';
import {CustomValidationError} from '@common/trade/models/exceptions/custom-validation.error';
import {CurrencyConfig} from '@common/currency/utils/currency-config';
import {AccountCurrency} from '@common/currency/services/account.currency';
import {AdditionalData} from '@common/notification/services/notification-provider.service';
import {translate} from '@common/locale/servises/translator.service';

export class TradeValidator {
  private get Config(): IAppConfig {
    return AppConfig.settings;
  }

  public constructor() {
  }

  public checkPrice(order: TradeDTO): TradeValidator {
    if (order.OpenPrice === null || order.OpenPrice === undefined || order.OpenPrice <= 0) {
      throw new IncorrectPriceError(translate(`NotificationProvider_TradeValidator_RateCannotBeLessOrEqualToZero`), 'RateCannotBeLessOrEqualToZero');
    }
    return this;
  }

  private checkBoundsNAN(order: TradeDTO): boolean {
    if (isNaN(order.TakeProfit)) {
      throw new IncorrectBoundsError(translate(`NotificationProvider_TradeValidator_TPIsNAN`), 'TPIsNAN');
    }
    if (isNaN(order.StopLoss)) {
      throw new IncorrectBoundsError(translate(`NotificationProvider_TradeValidator_SLIsNAN`), 'SLIsNAN');
    }
    if (isNaN(order.TrailingStop)) {
      throw new IncorrectBoundsError(translate(`NotificationProvider_TradeValidator_TSisNAN`), 'TSisNAN');
    }

    return true;
  }

  public checkBounds(order: TradeDTO): TradeValidator {
    if (this.checkBoundsNAN(order)) {
      // continue if ok
    }
    if (order.TrailingStop < 0) {
      throw new CustomValidationError(translate(`NotificationProvider_TradeValidator_IncorrectTrailingStop`), 'IncorrectTrailingStop');
    }
    if (!this.isBoundsClean(order)) {
      if (isBuyTrade(order.Type)) {
        this.checkBuyBounds(order);
      } else {
        this.checkSellBounds(order);
      }
    }

    return this;
  }

  public checkVolume(order: TradeDTO): TradeValidator {

    if (order.Symbol.MinVolume > 0 && order.Volume < order.Symbol.MinVolume) {
      throw new IncorrectVolumeError('Specified Volume is too low', 'SpecifiedVolumeIsTooLow');
    } else if (order.Symbol.MaxVolume > 0 && order.Volume > order.Symbol.MaxVolume) {
      throw new IncorrectVolumeError('Specified Volume is too high', 'SpecifiedVolumeIsTooHigh');
    }

    let exponentiation = 1;
    const numberOfDigitsAfterDot = OperationsWithVolume.numberOfDigitsAfterDot(order.Symbol.TradingStep);

    if (numberOfDigitsAfterDot > 0) {
      exponentiation = Math.pow(10, numberOfDigitsAfterDot);
    }

    const mod = this.multiplicationOperation(order.Volume, exponentiation) % (order.Symbol.TradingStep * exponentiation);

    if (order.Symbol.TradingStep !== 0 && mod !== 0) {
      throw new IncorrectVolumeError('The volume should be a multiple of the step' , 'TheVolumeShouldBeAMultipleOfTheStep');
    }

    if (order.Volume <= 0) {
      throw new IncorrectVolumeError(translate(`NotificationProvider_TradeValidator_VolumeMustBeMoreThanZero`), 'VolumeMustBeMoreThanZero');
    }

    return this;
  }

  private multiplicationOperation(volume: number, multiplier: number): number {
    return OperationsWithVolume.checkVolume(volume * multiplier);
  }

  public checkMargin(order: TradeDTO, accounting: Accounting) {
    const freeMargin = accounting.FreeMargin;
    // const marginCalculator: MarginCalc = new MarginCalcFactory().createCalculator(order.Symbol.MarginCalcType, order.Symbol.MarginCalcSymbol);
    const marginCalculator: IMarginCalc = new Factory().createCalculator(order.Symbol.MarginCalcType, order.Symbol.MarginCalcSymbol);
    const neededMargin = marginCalculator.Calculate(order.Volume, order.OpenPrice);


    /* we need this margin check when open new order only if I'm correct
    * but this check is nonsense when we change just SL or TP or TS, we shouldn't check margin this case
    * so add the margin = 0 check to make sure it works only for trade opening
    * */

    if (order.Margin === 0 && neededMargin > freeMargin) {
      const additionalDataObj: AdditionalData = {
        icon: CurrencyConfig[AccountCurrency.Instance.ISOCurrencyName.toLowerCase()].icon,
        neededMargin: PriceFormatter.format(neededMargin, 2),
        freeMargin: PriceFormatter.format(freeMargin, 2),
        amount: PriceFormatter.format(order.Volume, order.Symbol.VolumeDecimalPlaces),
        symbol: order.Symbol.SymbolName,
      };

      throw new CustomValidationError(
        `Symbol: ${additionalDataObj.symbol}. Amount: ${additionalDataObj.amount}. Margin Required: ${additionalDataObj.icon} ${additionalDataObj.neededMargin}. Free Margin: ${additionalDataObj.icon} ${additionalDataObj.freeMargin}`,
        'SymbolAmountMarginRequiredFreeMargin',
        additionalDataObj);

    }
  }

  private checkBuyBounds(order: TradeDTO): void {
    if (this.isBoundsClean(order)) {
      return;
    }

    const tpPips = Math.abs(PriceCalculator.priceToPips(order.TakeProfit - order.OpenPrice, order.Symbol));
    // const slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.OpenPrice, order.Symbol));
    let slPips: number;

    if (order.CurrentPrice > order.OpenPrice) {
      slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.CurrentPrice, order.Symbol));
    } else {
      slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.OpenPrice, order.Symbol));
    }

    if (order.UseBreakEven) {
      const minPipsBreakEven = this.Config.trading.MinStopPipsBreakEven ? this.Config.trading.MinStopPipsBreakEven : 0;
      const pricesBreakEven = minPipsBreakEven * order.Symbol.PipSize + order.OpenPrice;

      if (order.CurrentPrice < pricesBreakEven) {
        throw new IncorrectStopLossError(translate(`NotificationProvider_TradeValidator_IncorrectStopLoss`), 'IncorrectStopLoss');
      }
    } else {
      if (order.StopLoss !== 0 && (order.CurrentPrice <= order.StopLoss || slPips < this.Config.trading.MinStopPipsInterrupt)) {
        throw new IncorrectStopLossError(translate(`NotificationProvider_TradeValidator_IncorrectStopLoss`), 'IncorrectStopLoss');
      }
    }

    if (order.TakeProfit !== 0 && (order.CurrentPrice >= order.TakeProfit || tpPips < this.Config.trading.MinStopPipsInterrupt)) {
      throw new IncorrectTakeProfitError(translate(`NotificationProvider_TradeValidator_IncorrectTakeProfit`), 'IncorrectTakeProfit');
    }

    return;
  }

  private checkSellBounds(order: TradeDTO): void {
    if (this.isBoundsClean(order)) {
      return;
    }

    const tpPips = Math.abs(PriceCalculator.priceToPips(order.TakeProfit - order.OpenPrice, order.Symbol));
    // const slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.OpenPrice, order.Symbol));
    let slPips: number;

    if (order.CurrentPrice < order.OpenPrice) {
      slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.CurrentPrice, order.Symbol));
    } else {
      slPips = Math.abs(PriceCalculator.priceToPips(order.StopLoss - order.OpenPrice, order.Symbol));
    }

    if (order.UseBreakEven) {
      const minPipsBreakEven = this.Config.trading.MinStopPipsBreakEven ? this.Config.trading.MinStopPipsBreakEven : 0;
      const pricesBreakEven = order.OpenPrice - minPipsBreakEven * order.Symbol.PipSize;

      if (order.CurrentPrice > pricesBreakEven) {
        throw new IncorrectStopLossError(translate(`NotificationProvider_TradeValidator_IncorrectStopLoss`), 'IncorrectStopLoss');
      }
    } else {
      if (order.StopLoss !== 0 && (order.CurrentPrice >= order.StopLoss || slPips < this.Config.trading.MinStopPipsInterrupt)) {
        throw new IncorrectStopLossError(translate(`NotificationProvider_TradeValidator_IncorrectStopLoss`), 'IncorrectStopLoss');
      }
    }

    if (order.TakeProfit !== 0 && (order.CurrentPrice <= order.TakeProfit || tpPips < this.Config.trading.MinStopPipsInterrupt)) {
      throw new IncorrectTakeProfitError(translate(`NotificationProvider_TradeValidator_IncorrectTakeProfit`), 'IncorrectTakeProfit');
    }

    return;
  }

  private isBoundsClean(order: TradeDTO) {
    return (0 === order.StopLoss && 0 === order.TakeProfit);
  }

}
