import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";


export class Zero implements IProfitCalc {
	
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.Zero;
	}
        
	public Calculate(signedVolume: number, openPrice: number, closePrice: number): number {
	
				return 0;
}

	public CalculateWithConvertion(signedVolume: number, openPrice: number, closePrice: number, conversion: number): number {
	
				return 0;
}

	public CalculatePriceAtProfit(signedVolume: number, openPrice: number, profit: number): number {
	
				return 0;
}

	public CalculatePositionProfit(signedAmount: number, currentPrice: number, weightedPriceNumerator: number, conversion: number): number {
	
				return 0;
}

}