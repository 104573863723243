import {ProfitCalcBase} from "@common/shared/calculators/Profit/ProfitCalcBase.g";
import {IProfitCalcSymbol} from "@common/shared/calculators/Profit/IProfitCalcSymbol.g";
import {ProfitCalcType} from "@common/shared/calculators/Profit/ProfitCalcType.g";
import {IProfitCalc} from "@common/shared/calculators/Profit/IProfitCalc.g";
import {BCUSD_CCUSD} from "@common/shared/calculators/Profit/BCUSD_CCUSD.g";

export class CFD_CCUSD extends BCUSD_CCUSD {
	
	constructor(symbol: IProfitCalcSymbol) {
	   super(symbol);
	}
        
	
	getProfitCalcType(): ProfitCalcType {
		return ProfitCalcType.CFD_CCUSD;
	}
        
	
	
	
	
}