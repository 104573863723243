import {Injectable} from '@angular/core';
import {AbstractCommandSender} from '@common/communication/command-sender/abstract-command-sender';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {ICommandPromise} from '@common/communication/connection/command-answer-service';
import {
  Command_CreateExternalAccount,
  Command_GetSumSubToken,
  Command_GetTraderInfo,
  Command_UpdateExternalAccount,
  Command_TraderSignAgreement
} from '@common/communication/connection/classes.g';

@Injectable({
  providedIn: 'root'
})
export class TraderCommandSender extends AbstractCommandSender {
  private _logger = LoggerFactory.getLogger('TraderCommandSender');

  public constructor(serverConnection: ServerInteractionService) {

    super(serverConnection);
  }

  public getTrader(): ICommandPromise {
    return this.sendCommand(new Command_GetTraderInfo());
  }

  public  async getSumSubRegistrationToken(externalAccount: string): Promise<string> {
    const command = new Command_GetSumSubToken();

    command.ExternalID = externalAccount;
    const result = new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log(answer);
          resolve(answer.Token);
        })
        .failed(
          message => reject(message)
        );
    });

    return result;
  }

  public  async createExternalAccount(externalAccountID: string, email: string, passwordHash: string): Promise<string> {
    const command = new Command_CreateExternalAccount();

    command.externalAccountID = externalAccountID;
    command.accountPersonalAccountEmail = email;
    command.accountPasswordHash = passwordHash;

    const result = new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log(answer);
          resolve(answer);
        })
        .failed(
          message => {
            console.log('error creating account');
            console.log(message);
            reject(message);
          }
        );
    });

    return result;
  }
  public async traderSignAgreement(traderID: number) {
    const command = new Command_TraderSignAgreement();
    command.TraderID = traderID;

    const result = new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log(answer);
        })
        .failed(
          message => {
            console.log(message);
            reject(message);
          }
        );
    });
  }

  public  async updateExternalAccount(externalAccountID: string, accountLogin: string): Promise<string> {
    const command = new Command_UpdateExternalAccount();

    command.externalAccountID = externalAccountID;
    command.accountLogin = accountLogin;

    const result = new Promise<string>((resolve, reject) => {
      this.sendCommand(command)
        .success(answer => {
          console.log(answer);
          resolve(answer);
        })
        .failed(
          message => {
            console.log('error updating account');
            console.log(message);
            reject(message);
          }
        );
    });

    return result;
  }

}
