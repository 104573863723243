import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';
import {StopType} from '@common/trade/utils/calculations/sltp/stop-type';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberInputComponent),
    multi: true
  }]
})
export class NumberInputComponent extends ValueAccessorBase<number> implements OnInit {
  @Input() min = -100000000000000;
  @Input() max = Number.MAX_VALUE;
  @Input() decimalSeparator = '.';
  @Input() separator = '';
  @Input() name = '';
  @Input() usePipsBreakEven = false;

  // переменные для проверки значения у стоп лоса и возможно для других инпутов, пока только на стоп лосе стоит
  @Input() followChanges = false;
  private followBlur = false;
  @ViewChild('mainInput', {}) nativeInput: HTMLInputElement;
  private modifyOpenPrice: number;
  public arrowsHidden = true;
  private _decimalPlaces = undefined;
  private _dpByStep: number;
  @Output() incDecrementEvent: EventEmitter<number> = new EventEmitter<number>();
  private _step = undefined;
  private _stepByDP: number;
  @Input() appName: string;
  @Input() selectedItems: StopType;
  @Input() typeInput: StopType;

  constructor() {
    super();
    this.innerValue = 0;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public increment(event) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    if (!this.checkBounds()) {
      return;
    }

    let value: number;

    if (this.modifyOpenPrice !== undefined && this.value === 0) {
      value = this.roundNumber(Number(this.modifyOpenPrice) + this.step);
    } else {
      value = this.roundNumber(Number(this.value) + this.step);
    }

    if (value <= this.max) {
      this.setValue(value);
    }

    this.incDecrementEvent.emit(value);
  }

  public decrement(event) {
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (!this.checkBounds()) {
      return;
    }

    let value;

    if (this.modifyOpenPrice !== undefined && this.value === 0 ) {
      value = this.roundNumber(Number(this.modifyOpenPrice) - this.step);
    } else {
      value = this.roundNumber(Number(this.value) - this.step);
    }

    if (value >= this.min) {
      this.setValue(value);
    }

    this.incDecrementEvent.emit(value);
  }

  private checkBounds(): boolean {
    if (this.value == null || this.value < this.min) {
      this.value = this.min;
      return false;
    }
    if (this.value > this.max) {
      this.value = this.max;
      return false;
    }

    return true;
  }

  private roundNumber(v: number) {
    const temp = CustomNumber.roundForPlace(v, this.decimalPlaces);
    return this.cutNumber(temp);
  }

  private cutNumber(v: number) {
    return CustomNumber.cutNumber(v, this.decimalPlaces);
  }

  blur() {

    // при blur-событии при выполнении условия (если оно срабатывает на инпуте для пипса стоп лосов) переназначается значение
    // равной минимальному из конфигов
    if (this.followChanges && this.followBlur) {
      this.followBlur = false;
      this.setValue(this.min);
    }

    if (this.innerValue == null) {
      this.innerValue = 0;
    }
    super.blur();

  }

  click() {
    if (this.value === 0) {
      this.value = null;
    }
  }

  change(value: number) {
    super.change(Number(value));
  }

  getValue(): number {
    return this.roundNumber(Number(super.getValue()));
  }

  setValue(s: number) {
    let v = s;

    if (this.name === 'SL') {
      v = Math.abs(v);
    }

    if (v == null) {
      v = 0;
    }
    // когда срабатывает blur-событие, при срабатывании условного оператора у стоп лосов значения пипс не переписывается и из-за этого
    // баг валидации, добавил атрибуты для дополнительной провренки которая учитывает изминения при blur-событие переназначает значение
    // для пипса стоп лоса на минимальные из конфигов

    if ((v < this.min || v > this.max) && v !== 0) {
      if (this.followChanges) {
        this.followBlur = true;
      }
      return;
    } else {
      if (this.followChanges) {
        this.followBlur = false;
      }
    }

    super.setValue(v);
  }

  public get decimalPlaces(): number {
    return this._decimalPlaces || this._dpByStep || 0;
  }

  @Input()
  public set decimalPlaces(v: number) {
    this._decimalPlaces = Number(v);
    this._stepByDP = this.roundNumber(Math.pow(10, -this._decimalPlaces));
  }

  // принимает булево значения для скрытия стрелок шага
  @Input() private set arrowsStepHidden(v: boolean) {
    this.arrowsHidden = v;
  }

  public get step(): number {
    return this._step || this._stepByDP || 1;
  }

  @Input()
  public set step(v: number) {
    this._step = Number(v);
    this._dpByStep = -Math.log10(this._step);
  }

  onInputFocus(event: any) {
    event.stopPropagation();
    console.log('on focus');
    window['stopListenToResize'] = new Date();
  }

  public changeValue(event) {
    console.log(event);
    console.log(this.value);
    console.log(this.step);
  }

  @Input() private set modify(v: number) {
    this.modifyOpenPrice = v;
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  get ShowArrowsFocus(): boolean {
    if (this.selectedItems !== undefined && this.typeInput !== undefined) {
      return this.selectedItems === this.typeInput && this.arrowsHidden;
    }
    return this.arrowsHidden;
  }

}
