import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Trader} from '@common/trader/models/trader';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';

@Directive({
  selector: '[valid-password-verification]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ValidPasswordVerification,
    multi: true
  }]
})
export class ValidPasswordVerification {

  private readonly _currentPassword: string;
  private readonly _isCurrentPassword: boolean;

  constructor(private trader: Trader,
              private usersInSystemsService: UsersInSystemsService) {
    // текущий пароль берется из локал стороджа для валидации
    const arrayUser = JSON.parse(this.usersInSystemsService.getFromLocalStorage());
    if (arrayUser !== null) {
      const currentUser = arrayUser.filter(value => value.user === this.trader.TraderName);
      if (currentUser.length > 0 && currentUser[0].password) {
        this._currentPassword = OperationsEncryptDecryptData.decryptText(currentUser[0].password);
        this._isCurrentPassword = true;
      } else {
        this._isCurrentPassword = false;
      }
    } else {
      this._isCurrentPassword = false;
    }

  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this._isCurrentPassword) {
      return control.value == this._currentPassword ? null : {
        validPasswordVerification: true
      };
    }
    return null;
  }
}
