export class WatchList {
  private _watchListID: number;
  private _watchListName: string;
  private _traderID: number;
  private _watchListContentID: number;
  private _symbolID: number;


  get watchListID(): number {
    return this._watchListID;
  }

  set watchListID(value: number) {
    this._watchListID = value;
  }

  get watchListName(): string {
    return this._watchListName;
  }

  set watchListName(value: string) {
    this._watchListName = value;
  }

  get traderID(): number {
    return this._traderID;
  }

  set traderID(value: number) {
    this._traderID = value;
  }

  get watchListContentID(): number {
    return this._watchListContentID;
  }

  set watchListContentID(value: number) {
    this._watchListContentID = value;
  }

  get symbolID(): number {
    return this._symbolID;
  }

  set symbolID(value: number) {
    this._symbolID = value;
  }
}
