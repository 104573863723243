import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalOpenOrderComponent} from './component/modals/modal-open-order/modal-open-order.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalOneClickTradingComponent} from './component/modals/modal-one-click-tradind/modal-one-click-trading.component';
import {CloseAllConfirmationDialogComponent} from './component/modals/close-all-confirmation-dialog/close-all-confirmation-dialog.component';
import {LocaleModule} from '@common/locale/locale.module';
import {ModalOpenPendingOrderComponent} from './component/modals/modal-open-pending-order/modal-open-pending-order.component';
import {SharedModule} from '@common/shared/shared.module';
import {ModalModifyPendingOrderComponent} from './component/modals/modal-modify-pending-order/modal-modify-pending-order.component';
import {RouterModule} from '@angular/router';
import {TradeModule} from '@common/trade/trade.module';
import {TradeModule as DesktopTradeModule} from '@desktop-core/trade/trade.module';
import {ModalInstallAppComponent} from './component/modals/modal-install-app/modal-install-app.component';
import {ModalMultiOrderComponent} from '@desktop-core/modal/component/modals/modal-multi-order/modal-multi-order.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CancelPendingsConfirmationDialogComponent} from './component/modals/cancel-pendings-confirmation-dialog/cancel-pendings-confirmation-dialog.component';
import {ModalModule as CommonModals} from '@common/modals/modal.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import {ThemeModule} from '@common/shared/services/theme';
import {ModalUpdateAppComponent} from '@desktop-core/modal/component/modals/modal-update-app/modal-update-app.component';
import {ModalAlertPriceComponent} from '@desktop-core/modal/component/modals/modal-alert-price/modal-alert-price.component';
import {
  ModalAlertPriceNotificationComponent
} from '@desktop-core/modal/component/modals/modal-alert-price-notification/modal-alert-price-notification.component';
import {ModalSearchSymbolComponent} from '@desktop-core/modal/component/modals/modal-search-symbol/modal-search-symbol.component';
import {SymbolSearchModule} from '@common/symbol-search/symbol-search.module';
import {MultiOrderFormComponent} from '@common/trade/components/multi-order-form/multi-order-form.component';
import {ModalPlaceholderComponent} from '@common/modals/components/modal-placeholder/modal-placeholder.component';
import {CloseAllPositiveConfirmationDialog} from '@desktop-core/modal/component/modals/close-all-positive-confirmation-dialog/close-all-positive-confirmation-dialog';
import {CloseAllNegativeConfirmationDialog} from '@desktop-core/modal/component/modals/close-all-negative-confirmation-dialog/close-all-negative-confirmation-dialog';
import {ModalMovablePlaceholderComponent} from '@desktop-core/modal/component/modal-movable-placeholder/modal-movable-placeholder.component';
import {AlertPriceFormComponent} from '@common/trade/components/alert-price-form/alert-price-form.component';


@NgModule({
  declarations: [
    ModalOneClickTradingComponent,
    CloseAllConfirmationDialogComponent,
    CloseAllPositiveConfirmationDialog,
    CloseAllNegativeConfirmationDialog,
    ModalInstallAppComponent,
    ModalMultiOrderComponent,
    ModalUpdateAppComponent,
    CancelPendingsConfirmationDialogComponent,
    ModalOpenOrderComponent,
    ModalAlertPriceComponent,
    ModalAlertPriceNotificationComponent,
    ModalSearchSymbolComponent
  ],
  imports: [
    LocaleModule,
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    TradeModule,
    DesktopTradeModule,
    RouterModule,
    DragDropModule,
    CommonModals,
    NgxChartsModule,
    BrowserAnimationsModule,
    MatGridListModule,
    ThemeModule,
    SymbolSearchModule,
    MultiOrderFormComponent,
    ModalPlaceholderComponent,
    ModalOpenPendingOrderComponent,
    ModalModifyPendingOrderComponent,
    ModalMovablePlaceholderComponent,
    AlertPriceFormComponent,
  ],
    exports: [
        ModalOneClickTradingComponent,
        ModalOpenOrderComponent,
        ModalOpenPendingOrderComponent,
        ModalModifyPendingOrderComponent,
    ]
})
export class ModalModule { }
