import {Pipe, PipeTransform} from '@angular/core';
import {MoneyPipe} from '@common/trade/pipes/money.pipe';

@Pipe({
  name: 'pips'
})
export class PipsPipe extends MoneyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value == Infinity || value ==-Infinity || isNaN(value)) {
      return '-'
    }

    if (value == 0) {
      return '0.0';
    }

    const temp = Number(value);

    return temp.toFixed(1);
  }
}
