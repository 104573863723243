import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
  selector: '[one-upper-case]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: OneUpperCaseValidator,
    multi: true
  }]
})
export class OneUpperCaseValidator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const check = /[A-Z]/.test(control.value);
    return check ? null : {
      haventUpperCase : true
    }
  }
}
