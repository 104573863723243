import {Answer_GetItems_Item} from '../../communication/connection/classes.g';

export class IncomingSymbol {
  /* These items contains in Answer_GetItems_Item but are not implemented in this class
  private EnableMicro: "True"
  private EnableMini: "True"
  private EnableStandard: "True"
  private Internal: "False"
  private ItemStatus: "3"
  private MarginCalcType: "1"
  private QuotationType: "0"
  private QuoteSource: ""
  private SourceSymbolName: ""
  private Spread: "0"
   */

  private readonly _answerItem: Answer_GetItems_Item;

  constructor(answerItem: Answer_GetItems_Item) {
    this._answerItem = answerItem;
  }

  get SymbolName(): string {
    return String(this._answerItem.ItemName);
  }

  get SymbolId(): number {
    return Number(this._answerItem.ItemID);
  }

  get RolloverCalcType(): number {
    return Number(this._answerItem.RolloverCalcType);
  }

  get RolloverBuy(): number {
    return Number(this._answerItem.RolloverBuy);
  }

  get RolloverSell(): number {
    return Number(this._answerItem.RolloverSell);
  }

  get DecimalPlaces(): number {
    return Number(this._answerItem.DecimalPlaces);
  }

  get MinimumSpread(): number {
    return Number(this._answerItem.MinimumSpread);
  }

  get InitialMarginRate(): number {
    return Number(this._answerItem.InitialMarginRate);
  }

  get MaintenanceMarginRate(): number {
    return Number(this._answerItem.MaintenanceMarginRate);
  }

  get PipSize(): number {
    return Number(this._answerItem.PipSize);
  }

  get MaxVolume(): number {
    return Number(this._answerItem.MaxVolume);
  }

  get LongName(): string {
    return String(this._answerItem.LongName);
  }

  get BaseCurrency(): string {
    return String(this._answerItem.BaseCurrency);
  }

  get CounterCurrency(): string {
    return String(this._answerItem.CounterCurrency);
  }

  get SymbolCategoryInt(): number {
    return Number(this._answerItem.SymbolCategory);
  }

  get SymbolCategoryName(): string {
    return String(this._answerItem.SourceSymbolName);
  }

  get ProfitCalcTypeInt(): number {
    return Number(this._answerItem.ProfitCalcType);
  }

  get MarginCalcTypeInt(): number {
    return Number(this._answerItem.MarginCalcType);
  }

  get BCConvertSymbolId(): number {
    return Number(this._answerItem.BCConvertSymbolID);
  }

  get CCConvertSymbolId(): number {
    return Number(this._answerItem.CCConvertSymbolID);
  }

  get ContractSize(): number {
    return Number(this._answerItem.ContractSize);
  }

  get IsActive(): boolean {
    return String(this._answerItem.IsActive).toLowerCase() === 'true';
  }

  get TickSize(): number {
    return Number(this._answerItem.TickSize);
  }

  get TickPrice(): number {
    return Number(this._answerItem.TickPrice);
  }

  get MinVolume(): number {
    return Number(this._answerItem.MinVolume);
  }

  get SortOrder(): number {
    return Number(this._answerItem.SortOrder);
  }

  get TradingStep(): number {
    return Number(this._answerItem.TradingStep);
  }

  get ExternalName(): string {
    return String(this._answerItem.ExternalName);
  }

  get Percentage(): number {
    return Number(this._answerItem.Percentage);
  }

  get MinTransactionFee(): number {
    return Number(this._answerItem.MinTransactionFee);
  }

  get TransactionFee(): number {
    return Number(this._answerItem.TransactionFee);
  }
}
