import {Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IAppConfig} from './app-config-interface';
import {Environment} from '@common/environment';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  public get Settings(): IAppConfig {
    return AppConfig.settings;
  }
  constructor(@Optional() private http: HttpClient) {}

  /**
   * @deprecated
   * Inject this type and use Settings getter
   */
  static settings: IAppConfig;

  public resolveDefaultSymbol(tradeGroupId?: number | null): string {
    const defaultSymbol = this.Settings.charts.DefaultSymbol;

    if (!tradeGroupId) {
      return defaultSymbol;
    }

    const symbolPerTradeGroup = this.Settings.charts.DefaultSymbolPerTradeGroup;

    if (symbolPerTradeGroup === null ||
      symbolPerTradeGroup === undefined) {
      return defaultSymbol;
    }

    const symbolName = symbolPerTradeGroup[tradeGroupId.toString()];

    if (!symbolName) {
      return defaultSymbol;
    }

    return symbolName;
  }
  async load() {
    const jsonFileGeneral = `assets/config/config.general.json`;
    await this.http.get(jsonFileGeneral).toPromise().then( async (responseGeneral: IAppConfig) => {

      const settingsGeneral = <IAppConfig>responseGeneral;

      const jsonFile = `assets/config/config.${Environment.Name}.json`;

      await this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
        AppConfig.settings = {...settingsGeneral, ...<IAppConfig>response};
      });

    });
  }
}
