import {HistoryMetadata} from '@tradingview/charting_library';

export class Metadata implements HistoryMetadata {
  nextTime: number | null;
  noData: boolean;

  constructor(nextTime: number | null, noData: boolean) {
    this.nextTime = nextTime;
    this.noData = noData;
  }
}
