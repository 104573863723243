import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocaleModule} from '@common/locale/locale.module';
import {FormsModule} from '@angular/forms';
import {OpenOrderFormComponent} from './components/forms/open-order-form/open-order-form.component';
import {TradeModule as CommonTradeModule} from '@common/trade/trade.module';
import {SharedModule} from '@common/shared/shared.module';
import {CurrencyModule} from '@common/currency/currency.module';
import {NotificationModule} from '@common/notification/notification.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import { HistoryPageComponent } from './components/content-tabs/history-page/history-page.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {TooltipCustomModule} from '@common/shared/components/tooltips/tooltip.module';
import {CircleProgressBarModule} from '@common/shared/components/circle-progress-bar/circle-progress-bar.module';
import {ProgressBarCompletionModule} from '@common/shared/components/progress-bar-completion/progress-bar-completion.module';
import {ModeDebugUsersModule} from '@common/shared/components/mode-debug-users/mode-debug-users.module';
import {ChallengePageComponent} from '@desktop-core/trade/components/content-tabs/challenge-page/challenge-page.component';
import {TradeLineComponent} from '@desktop-core/trade/components/content-tabs/trade-line/trade-line.component';
import {TableHeaderComponent} from '@desktop-core/trade/components/content-tabs/table-header/table-header.component';
import {SlTpInputComponent} from '@common/trade/components/sltp-input/sltp-input.component';


@NgModule({
  declarations: [
    OpenOrderFormComponent,
  ],
    imports: [
        CommonModule,
        CommonTradeModule,
        LocaleModule,
        FormsModule,
        SharedModule,
        CurrencyModule,
        NotificationModule,
        NgxChartsModule,
        BrowserAnimationsModule,
        MatGridListModule,
        ScrollingModule,
        TooltipCustomModule,
        CircleProgressBarModule,
        ProgressBarCompletionModule,
        ModeDebugUsersModule,
        ChallengePageComponent,
        TradeLineComponent,
        HistoryPageComponent,
        TableHeaderComponent,
        SlTpInputComponent
    ],
  exports: [
    OpenOrderFormComponent,
    CommonTradeModule,
  ]
})
export class TradeModule { }
