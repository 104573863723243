<div class="footer" [ngClass]="getAnimationClasses()">
  <span class="copyright">&copy; {{CurrentYear}} {{CompanyName}}</span>

  <div class="footer-client-data">
    <div class="footer-logo"></div>
    <div class="footer-column balance-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_balance-column'"></l-unit></div>
      <div class="row-title-right">{{ Balance | money | currencySign }}</div>
    </div>
    <div class="footer-column upl-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_upl-column'"></l-unit></div>
      <div class="row-title-right profit" [ngClass]="UplClass">{{ UPL | money | currencySign }}</div>
    </div>
    <div class="footer-column equity-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_equity-column'"></l-unit></div>
      <div class="row-title-right">{{ Equity | money }}</div>
    </div>
    <div class="footer-column used-margin-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_used-margin-column'"></l-unit></div>
      <div class="row-title-right">{{ UsedMargin | money }}</div>
    </div>
    <div class="footer-column free-margin-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_free-margin-column'"></l-unit></div>
      <div class="row-title-right">{{ FreeMargin | money }}</div>
    </div>
    <div class="footer-column free-margin-column">
      <div class="row-description-footer"><l-unit [key]="'Shared_FooterComponent_margin-level-column-mobile'"></l-unit></div>
      <div class="row-title-right">{{ MarginLevel }}</div>
    </div>
  </div>

  <div class="right-block" [ngSwitch]="MarginStatus">
    <app-margin-call-notification *ngSwitchDefault></app-margin-call-notification>
    <span class="server-time" *ngSwitchCase="0">
      <span>CP-{{ConnectUrlPort}} </span>
<!--      <span>{{TitleTime}} {{ serverTime | date:"dd MMM yyyy hh:mm:ss a":"UTC" }} UTC</span>-->
      <span>{{TitleTime}} <l-date [date]="serverTime" [format]="'DD MMM yyyy hh:mm:ss a'" [isUtc]="true" ></l-date> UTC</span>
    </span>
  </div>

  <div class="alarm" *ngIf="MarginStatus">
    <div class="filler"></div>
    <div class="filler"></div>
  </div>
</div>
