import {AfterViewInit, Component, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true
  }]
})
export class CustomSelectComponent extends ValueAccessorBase<any> implements OnInit, AfterViewInit {
  @Input()
  options: Array<{value: any, title: string}> = [];
  @Input()
  length = 9;

  @Output()
  select: Observable<number>;

  private inFocus: boolean;

  public sub: Subscription;

  private firstIndex: number;

  private _selectedIndex: number;
  private set selectedIndex(v: number) {
    this._selectedIndex = v;
  }
  private get selectedIndex(): number {
    return this._selectedIndex;
  }

  public opened: boolean;
  public list;

  public get inputValue(): string {
    const result = this.options.find(value => value.value == this.value);

    if(result){
      return result.title;
    }

    else return '';
  }

  constructor() {
    super();
  }

  public onSelect(key: any){
    this.value = key;

    this.selectedIndex = this.options.findIndex(value => value.value == key);

    this.close();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if(!this.value) {
      this.selectedIndex = 0;
    }
  }

  public onScrollUp(){
    if(this.firstIndex <= 0) return;
    this.firstIndex--;
    this.list = [this.options[this.firstIndex]].concat(this.list.slice(0, this.list.length - 1));
  }

  public onScrollDown(){
    if(this.firstIndex + this.length >= this.options.length) return;
    this.firstIndex++;
    this.list = this.list.slice(1).concat([this.options[this.firstIndex + this.length - 1]]);
  }



  public toggle(event: MouseEvent){
    event.stopPropagation();
    if(this.opened)
      this.close();
    else this.open();
  }
  public open() {
    this.generateList();
    this.opened = true;
  }

  public close(){
    this.list = [];
    this.opened = false;
  }

  private generateList() {
    if(this.options.length <= this.length){
      this.firstIndex = 0;
      this.list = this.options;
    }
    else if((this.selectedIndex + this.length) > this.options.length) {
      this.firstIndex = this.options.length - this.length;
      this.list = this.options.slice(this.options.length - this.length)
    }
    else {
      this.firstIndex = this.selectedIndex;
      this.list = this.options.slice(this.selectedIndex, this.selectedIndex + this.length);
    }
  }
}
