import {StopType} from '../../trade/utils/calculations/sltp/stop-type';
import {EventEmitter, Injectable} from '@angular/core';
import {Answer_GetSettings} from '@common/communication/connection/classes.g';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {ViewMode} from '@common/trader/models/view-mode.enum';

@Injectable({
  providedIn: 'root'
})
export class Settings {

  private _askLine: boolean;
  private _bidLine: boolean;
  private _oneClickTrading: boolean;
  private _regularSLTP: boolean;
  private _sound: boolean;
  public _onTradesOnChartChange: SmartEmitter<boolean> = new SmartEmitter<boolean>();
  private _tradesOnChart: boolean;
  private _oneClickAmount = 0;
  private _oneClickLot = 0;
  private _slType: StopType = StopType.ByPips;
  private _oneClickSL = 0;
  private _tpType: StopType = StopType.ByPips;
  private _oneClickTP = 0;
  private _oneClickTS = 0;
  private _onChartCountChange: SmartEmitter<number> = new SmartEmitter<number>();
  private _chartCount = 1;

  private _favoriteSymbols = new Set<number>();

  private _ciqLayouts = '';
  private _ciqPreferences = '';
  private _ciqDrawings = '';

  private _pendingOrderAccountRiskPercentage = 0;
  private _pendingOrderSlPips = 0;
  private _pendingOrderTpPips = 0;

  private _useAutoTradeSizing = false;
  private _useAdvancedVolume = false;
  private _useLotTrading: boolean;

  private _tvLayout = '';
  private _tvStudies = '';

  private _alwaysShowDefaultView: boolean;
  private _viewMode: ViewMode = ViewMode.OpenDefault;

  private _oneClickChanged = new SmartEmitter<boolean>();
  private _oneClickAmountChanged = new SmartEmitter<number>();
  private _oneClickLotChanged = new SmartEmitter<number>();
  private _useTradingChanged:  EventEmitter<void> = new EventEmitter<void>();

  private _challengeTrading = true;

  constructor() {
  }

  public isEquals(settings: Settings): boolean {
    return this._askLine === settings._askLine &&
      this._bidLine === settings._bidLine &&
      this._alwaysShowDefaultView === settings._alwaysShowDefaultView &&
      this._oneClickTrading === settings._oneClickTrading &&
      this._regularSLTP === settings._regularSLTP &&
      this._sound === settings._sound &&
      this._tradesOnChart === settings._tradesOnChart &&
      this._oneClickAmount === settings._oneClickAmount &&
      this._oneClickLot === settings._oneClickLot &&
      this._slType === settings._slType &&
      this._oneClickSL === settings._oneClickSL &&
      this._tpType === settings._tpType &&
      this._oneClickTP === settings._oneClickTP &&
      this._oneClickTS === settings._oneClickTS &&
      this._chartCount === settings._chartCount &&
      this._ciqLayouts === settings._ciqLayouts &&
      this._ciqPreferences === settings._ciqPreferences &&
      this._ciqDrawings === settings._ciqDrawings &&
      this._pendingOrderAccountRiskPercentage === settings._pendingOrderAccountRiskPercentage &&
      // this._pendingOrderSlPips === settings._pendingOrderSlPips &&
      // this._pendingOrderTpPips === settings._pendingOrderTpPips &&
      this._useAutoTradeSizing === settings._useAutoTradeSizing &&
      this._useAdvancedVolume === settings._useAdvancedVolume &&
      this._tvLayout === settings._tvLayout &&
      this._tvStudies === settings._tvStudies &&
      this._viewMode === settings._viewMode &&
      this._useLotTrading === settings._useLotTrading &&
      this.compareSets(this._favoriteSymbols, settings._favoriteSymbols);
  }

  private compareSets(as, bs) {
    if (as.size !== bs.size) {
      return false;
    }
    for (let a of as) {
      if (!bs.has(a)) {
        return false;
      }
    }
    return true;
  }

  public setFromModel(settings: Settings) {
    this.AskLine = settings.AskLine;
    this.BidLine = settings.BidLine;
    this.OneClickTrading = settings.OneClickTrading;
    this.AlwaysShowDefaultView = settings.AlwaysShowDefaultView;
    this.RegularSLTP = settings.RegularSLTP;
    this.Sound = settings.Sound;
    this.TradesOnChart = settings.TradesOnChart;
    this.OneClickAmount = settings.OneClickAmount;
    this.OneClickSL = settings.OneClickSL;
    this.OneClickTP = settings.OneClickTP;
    this.OneClickTS = settings.OneClickTS;
    this.ChartCount = settings.ChartCount;
    this.CiqLayouts = settings.CiqLayouts;
    this.CiqPreferences = settings.CiqPreferences;
    this.CiqDrawings = settings.CiqDrawings;
    this.PendingOrderAccountRiskPercentage = settings.PendingOrderAccountRiskPercentage;
    // this.PendingOrderSlPips = settings.PendingOrderSlPips;
    // this.PendingOrderTpPips = settings.PendingOrderTpPips;
    this.UseAutoTradeSizing = settings.UseAutoTradeSizing;
    this.UseAdvancedVolume = settings.UseAdvancedVolume;
    this.TvLayout = settings.TvLayout;
    this.TvStudies = settings.TvStudies;
    this.ViewMode = settings.ViewMode;
    this.UseLotTrading = settings.UseLotTrading;
    this.OneClickLot = settings.OneClickLot;
    if (settings._favoriteSymbols) {
      this._favoriteSymbols = new Set<number>(settings._favoriteSymbols);
    }
  }

  public static getFromGetSettingsAnswer(answer: Answer_GetSettings): Settings {
    const settings = new Settings();
    console.log(answer);

    settings.OneClickLot = Number(answer.OneClickLot);
    settings.UseLotTrading = answer.LotTrading.toString() === 'True';
    settings.TradesOnChart = answer.TradesOnChart.toString() === 'True';
    settings.Sound = answer.Sound.toString() === 'True';
    settings.BidLine = answer.BidLine.toString() === 'True';
    settings.AskLine = answer.AskLine.toString() === 'True';
    settings.RegularSLTP = answer.UseSltpForNonOneClickOrders.toString() === 'True';
    settings.OneClickTrading = answer.OneClickTrading.toString() === 'True';
    settings.AlwaysShowDefaultView = answer.AlwaysShowDefaultView.toString() === 'True';
    settings.OneClickAmount = Number(answer.OneClickAmount);
    settings.OneClickTP = Number(answer.OneClickTakeProfit);
    settings.OneClickTPUnits = Number(answer.OneClickTakeProfitUnit);
    settings.OneClickSL = Number(answer.OneClickStopLoss);
    settings.OneClickSLUnits = Number(answer.OneClickStopLossUnit);
    settings.OneClickTS = Number(answer.OneClickTrailingStop);
    settings.ChartCount = Number(answer.ChartCount);
    settings.CiqLayouts = answer.CiqLayouts;
    settings.CiqPreferences = answer.CiqPreferences;
    settings.CiqDrawings = answer.CiqDrawings;
    settings.PendingOrderAccountRiskPercentage = Number(answer.PendingOrderAccountRisk);
    // settings.PendingOrderSlPips = answer.PendingOrderSlPips;
    // settings.PendingOrderTpPips = answer.PendingOrderTpPips;
    settings.UseAutoTradeSizing = answer.UseAutoTradeSizing.toString() === 'True';
    settings.UseAdvancedVolume = answer.UseAdvancedVolumeSettings.toString() === 'True';
    settings.TvLayout = answer.TvLayout;
    settings.TvStudies = answer.TvStudies;
    settings.ViewMode = answer.ViewMode;


    if (answer.FavoriteSymbols) {
      const favoriteSymbols = JSON.parse(answer.FavoriteSymbols);
      settings._favoriteSymbols = new Set<number>(Array.from(favoriteSymbols));
    } else {
      settings._favoriteSymbols = new Set<number>();
    }

    return settings;
  }

  get OneClickAmount(): number {
    return this._oneClickAmount;
  }

  set OneClickAmount(value: number) {
    this._oneClickAmount = value;
    this._oneClickAmountChanged.emit(value);
    this._useTradingChanged.emit();
  }

  get OneClickAmountChanged(): ISmartObserver<number> {
    return this._oneClickAmountChanged;
  }

  get OneClickLot(): number {
    return this._oneClickLot;
  }

  set OneClickLot(value: number) {
    this._oneClickLot = value;
    this._oneClickLotChanged.emit(value);
    this._useTradingChanged.emit();
  }

  get OneClickLotChanged(): ISmartObserver<number> {
    return this._oneClickLotChanged;
  }

  get OneClickSLUnits(): StopType {
    return this._slType;
  }

  set OneClickSLUnits(value: StopType) {
    this._slType = value;
  }

  get OneClickSL(): number {
    return this._oneClickSL;
  }

  set OneClickSL(value: number) {
    this._oneClickSL = value;
  }

  get OneClickTP(): number {
    return this._oneClickTP;
  }

  set OneClickTP(value: number) {
    this._oneClickTP = value;
  }

  get OneClickTS(): number {
    return this._oneClickTS;
  }

  set OneClickTS(value: number) {
    this._oneClickTS = value;
  }

  get OneClickTPUnits(): StopType {
    return this._tpType;
  }

  set OneClickTPUnits(value: StopType) {
    this._tpType = value;
  }

  get AskLine(): boolean {
    return this._askLine;
  }

  set AskLine(value: boolean) {
    this._askLine = value;
  }

  get BidLine(): boolean {
    return this._bidLine;
  }

  set BidLine(value: boolean) {
    this._bidLine = value;
  }

  get AlwaysShowDefaultView(): boolean {
    return this._alwaysShowDefaultView;
  }

  set AlwaysShowDefaultView(value: boolean) {
    if (value === false && +this.ViewMode === ViewMode.OpenDefault) {
      this.ViewMode = ViewMode.OpenAllFavorites;
    }
    this._alwaysShowDefaultView = value;
  }

  get OneClickTrading(): boolean {
    return this._oneClickTrading;
  }

  set OneClickTrading(value: boolean) {
    this._oneClickTrading = value;
    this._oneClickChanged.emit(value);
  }

  get OneClickModeChanged(): ISmartObserver<boolean> {
    return this._oneClickChanged;
  }

  get RegularSLTP(): boolean {
    return this._regularSLTP;
  }

  set RegularSLTP(value: boolean) {
    this._regularSLTP = value;
  }

  get Sound(): boolean {
    return this._sound;
  }

  set FavoriteSymbols(symbols: Set<number>) {
    this._favoriteSymbols = symbols;
  }

  get FavoriteSymbols(): Set<number> {
    return this._favoriteSymbols;
  }

  set Sound(value: boolean) {
    this._sound = value;
  }

  get TradesOnChart(): boolean {
    return this._tradesOnChart;
  }

  set TradesOnChart(value: boolean) {
    this._tradesOnChart = value;
    this._onTradesOnChartChange.emit(value);
  }

  get TradeOnChartChange(): ISmartObserver<boolean> {
    return this._onTradesOnChartChange;
  }

  get ChartCount(): number {
    if (this._chartCount) {
      return this._chartCount;
    } else {
      return 1;
    }
  }

  set ChartCount(value: number) {
    this._chartCount = value;
    this._onChartCountChange.emit(value);
  }

  get ChartCountChange(): ISmartObserver<number> {
    return this._onChartCountChange;
  }

  get CiqLayouts(): string {
    return this._ciqLayouts;
  }

  set CiqLayouts(value: string) {
    this._ciqLayouts = value;
  }

  get CiqPreferences(): string {
    return this._ciqPreferences;
  }

  set CiqPreferences(value: string) {
    this._ciqPreferences = value;
  }

  get CiqDrawings(): string {
    return this._ciqDrawings;
  }

  set CiqDrawings(value: string) {
    this._ciqDrawings = value;
  }

  get PendingOrderAccountRiskPercentage(): number {
    return this._pendingOrderAccountRiskPercentage;
  }

  set PendingOrderAccountRiskPercentage(value: number) {
    this._pendingOrderAccountRiskPercentage = value;
    this._useTradingChanged.emit();
  }

  get UseAutoTradeSizing(): boolean {
    return this._useAutoTradeSizing;
  }

  set UseAutoTradeSizing(value: boolean) {
    this._useAutoTradeSizing = value;
  }

  get UseAdvancedVolume(): boolean {
    return this._useAdvancedVolume;
  }

  set UseAdvancedVolume(value: boolean) {
    // this._useAdvancedVolume = value;
  }

  get TvLayout(): string {
    return this._tvLayout;
  }

  set TvLayout(value: string) {
    this._tvLayout = value;
  }

  get TvStudies(): string {
    return this._tvStudies;
  }

  set TvStudies(value: string) {
    this._tvStudies = value;
  }

  get ViewMode(): ViewMode {
    return this._viewMode;
  }

  set ViewMode(value: ViewMode) {
    this._viewMode = value;
  }

  get UseLotTrading(): boolean {
    return this._useLotTrading;
  }

  set UseLotTrading(value: boolean) {
    sessionStorage.setItem('UseLotTrading', JSON.stringify(value));
    this._useLotTrading = value;
    this._useTradingChanged.emit();
  }

  public get UseTradingChangedEmitter(): EventEmitter<void> {
    return this._useTradingChanged;
  }


  // get ChallengeTrading(): boolean {
  //   return this._challengeTrading;
  // }
  //
  // set ChallengeTrading(value: boolean) {
  //   this._challengeTrading = value;
  // }
}

export function getSettingsMock(amount = 1000): Settings {
  return {
    AlwaysShowDefaultView: true,
    OneClickTrading: true,
    OneClickAmount: amount,
    OneClickSL: 1,
    OneClickTP: 2,
    RegularSLTP: false,
    UseAutoTradeSizing: false,
    PendingOrderAccountRiskPercentage: 1
  } as Settings;
}
