import {Quote} from './quote';
import {ProfitCalcType} from '../../trade/utils/calculations/profit-calc-type.enum';
import {ProfitCalcSymbol} from './profit-calc-symbol';
import {LastQuoteInfo} from './last-quote-info';
import {SymbolInfo} from './symbol-info';
import {QuoteTick} from './quote-tick';
import {IInformativeSymbol} from './informative-symbol-interface';
import {MinimumSpreadCorrector} from './minimum-spread-corrector';
import {SymbolSettings} from './symbol-settings';
import {SymbolMetadata} from './symbol-metadata';
import {RolloverCalcSymbol} from './rollover-calc-symbol';
import {SymbolCategory} from '@common/symbol/models/symbol-category';
import {MarginCalcType} from '@common/trade/utils/calculations/margin-calc-type.enum';
import {MarginCalcSymbol} from './margin-calc-symbol';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {JsonProperty} from '@common/shared/utils/json-decorators';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {MultiTierQuoteLevel} from '@common/symbol/models/multi-tier-quote-level';
import {TradingViewItemHistoryConnectionService} from '@common/trading-view-charts/services/trading-view-item-history-connection.service';
import {RolloverCalcType} from '@common/shared/calculators/Rollover/RolloverCalcType.g';

export class Symbol implements IInformativeSymbol {

  private readonly _symbolId: number;
  private readonly _percentage: number;
  private readonly _minimumSpread: number;
  private readonly _maxVolume: number;
  private readonly _minVolume: number;
  private _percentChange: number | null = null;
  private _priceChangeInDay: number | null = null;
  private _lastPrice: number | null = null;
  public _currentPrice: number | null = null;
  public _inGroup: boolean;
  private _contractSize: number;
  private _tickSize: number;
  private _tickPrice: number;
  private _tradingStep: number;
  private _sortOrder: number;
  private _isActive: boolean;
  private _isSubscribed = false;
  private readonly _marginCalcType: MarginCalcType;
  private readonly _profitCalcType: ProfitCalcType;
  private _profitCalcSymbol: ProfitCalcSymbol;
  private _marginCalcSymbol: MarginCalcSymbol;
  private readonly _profitCalcSymbolFunc: (symbol: Symbol) => (ProfitCalcSymbol | null);
  private readonly _marginCalcSymbolFunc: (symbol: Symbol) => (MarginCalcSymbol | null);
  private readonly _priceChange: SmartEmitter<Quote>;
  private readonly _fullMarketDepthChange: SmartEmitter<MultiTierQuoteLevel[]>;
  private _fullMarketDepth: MultiTierQuoteLevel[];
  private _subscribeRequestEventEmitter: SmartEmitter<void> = new SmartEmitter<void>();
  private _subscribeEmitted = false;
  private logger = LoggerFactory.getLogger('Symbol');
  private _minTransactionFee: number;
  private _transactionFee: number;
  private readonly _rolloverBuy: number;
  private readonly _rolloverSell: number;

  @JsonProperty(SymbolSettings)
  private _symbolSettings: SymbolSettings;

  @JsonProperty(SymbolMetadata)
  private readonly _symbolMetadata: SymbolMetadata;

  // @JsonProperty(RolloverCalcSymbol)
  // private _symbolRolloverData: RolloverCalcSymbol;

  private readonly _rolloverCalcType: RolloverCalcType;
  private _rolloverCalcSymbol: RolloverCalcSymbol;
  private readonly _rolloverCalcSymbolFunc: (symbol: Symbol) => (RolloverCalcSymbol | null);

  @JsonProperty(LastQuoteInfo)
  public _lastQuoteInfo: LastQuoteInfo = new LastQuoteInfo(); // заменил c private чтоб можно было считывать изминения

  @JsonProperty(MinimumSpreadCorrector)
  private readonly _spreadCorrector: MinimumSpreadCorrector;

  constructor(symbolId: number,
              minimumSpread: number,
              minVolume: number,
              maxVolume: number,
              contractSize: number,
              isActive: boolean,
              tickSize: number,
              tickPrice: number,
              tradingStep: number,
              profitCalcType: ProfitCalcType,
              marginCalcType: MarginCalcType,
              rolloverCalcType: RolloverCalcType,
              profitCalcSymbolFunc: (symbol: Symbol) => (ProfitCalcSymbol | null),
              marginCalcSymbolFunc: (symbol: Symbol) => (MarginCalcSymbol | null),
              rolloverCalcSymbolFunc: (symbol: Symbol) => (RolloverCalcSymbol | null),
              symbolSettings: SymbolSettings,
              symbolMetadata: SymbolMetadata,
              private itemHistoryService: TradingViewItemHistoryConnectionService,
              percentage: number,
              sortOrder: number = 0,
              minTransactionFee: number,
              transactionFee: number,
              rolloverBuy: number,
              rolloverSell: number) {
    this._percentage = percentage;
    this._symbolId = symbolId;
    this._minimumSpread = minimumSpread;
    this._minVolume = minVolume;
    this._maxVolume = maxVolume;
    this._contractSize = contractSize;
    this._isActive = isActive;
    this._tickSize = tickSize;
    this._tickPrice = tickPrice;
    this._tradingStep = tradingStep;
    this._symbolMetadata = symbolMetadata;
    this._symbolSettings = symbolSettings;
    this._profitCalcType = profitCalcType;
    this._marginCalcType = marginCalcType;
    this._rolloverCalcType = rolloverCalcType;
    this._rolloverCalcSymbolFunc = rolloverCalcSymbolFunc;
    this._profitCalcSymbolFunc = profitCalcSymbolFunc;
    this._marginCalcSymbolFunc = marginCalcSymbolFunc;
    this._sortOrder = sortOrder;
    this._priceChange = new SmartEmitter<Quote>();
    this._fullMarketDepthChange = new SmartEmitter<MultiTierQuoteLevel[]>();
    this._spreadCorrector = new MinimumSpreadCorrector();
    this._spreadCorrector.MinimumSpreadPoints = this.pipsToPoint(this._minimumSpread);
    this._transactionFee = !isNaN(transactionFee) ? transactionFee : 0;
    this._minTransactionFee = !isNaN(minTransactionFee) ? minTransactionFee : 0;
    this._rolloverSell = rolloverSell;
    this._rolloverBuy = rolloverBuy;
  }

  public get SubscribeRequest(): ISmartObserver<void> {
    return this._subscribeRequestEventEmitter;
  }

  public get PipSize(): number {
    return this._symbolSettings.PipSize;
  }

  public get PipDecimalPlaces(): number {
    return this._symbolSettings.PipDecimalPlaces;
  }

  public get ContractSize(): number {
    return this._contractSize;
  }

  public get TickSize(): number {
    return this._tickSize;
  }

  public get TickPrice(): number {
    return this._tickPrice;
  }

  public get FullMarketDepth(): MultiTierQuoteLevel[] {
    return this._fullMarketDepth;
  }

  public get SortOrder(): number {
    return this._sortOrder;
  }

  public set SortOrder(value: number) {
    this._sortOrder = value;
  }

  public get TradingStep(): number {
    return this._tradingStep;
  }

  public get ExternalName(): string {
    return this._symbolMetadata.ExternalName;
  }

  public get InGroup(): boolean {
    return this._inGroup;
  }

  get IsActive(): boolean {
    return this._isActive;
  }

  public get PriceChange(): ISmartObserver<Quote> {
    return this._priceChange;
  }

  public get FullMarketDepthChange(): ISmartObserver<MultiTierQuoteLevel[]> {
    return this._fullMarketDepthChange;
  }

  get Ask(): number {
    if (!this.IsSubscribed) {
      this.trySubscribe();

      if (!this.LastQuote) {
        return 0;
      }
    }
    if (!this.LastQuote) {
      return 0;
    }
    return this.LastQuote.Ask;
  }

  get Bid(): number {
    if (!this.IsSubscribed) {
      this.trySubscribe();

      if (!this.LastQuote) {
        return 0;
      }
    }
    if (!this.LastQuote) {
      return 0;
    }
    return this.LastQuote.Bid;
  }

  get ProfitCalcType(): ProfitCalcType {
    return this._profitCalcType;
  }

  get ProfitCalcSymbol(): ProfitCalcSymbol {
    if (!this._profitCalcSymbol) {
      this._profitCalcSymbol = this._profitCalcSymbolFunc(this);
    }
    return this._profitCalcSymbol;
  }

  get MarginCalcType(): MarginCalcType {
    return this._marginCalcType;
  }

  get MarginCalcSymbol(): MarginCalcSymbol {
    if (!this._marginCalcSymbol) {
      this._marginCalcSymbol = this._marginCalcSymbolFunc(this);
    }
    return this._marginCalcSymbol;
  }

  get RolloverCalcType(): RolloverCalcType {
    return this._rolloverCalcType;
  }

  get RolloverCalcSymbol(): RolloverCalcSymbol {
    if (!this._rolloverCalcSymbol) {
      this._rolloverCalcSymbol = this._rolloverCalcSymbolFunc(this);
    }
    return this._rolloverCalcSymbol;
  }

  get SymbolName(): string {
    return this._symbolMetadata.SymbolName;
  }

  get InitialMarginRate(): number {
    return this._symbolSettings.InitialMarginRate;
  }

  get MaintenanceMarginRate(): number {
    return this._symbolSettings.MaintenanceMarginRate;
  }

  get SymbolLongName(): string {
    return this._symbolMetadata.SymbolLongName;
  }

  get BaseCurrency(): string {
    return this._symbolMetadata.BaseCurrency;
  }

  get SymbolType() {
    return this._symbolMetadata.SymbolType;
  }

  get CounterCurrency(): string {
    return this._symbolMetadata.CounterCurrency;
  }

  get SymbolId(): number {
    return this._symbolId;
  }

  get Percentage(): number {
    return this._percentage;
  }

  get LastQuote(): Quote {
    return this._lastQuoteInfo.LastQuote;
  }

  get LastQuoteInfo(): LastQuoteInfo {
    return this._lastQuoteInfo;
  }

  get RolloverBuy(): number {
    return this._rolloverBuy;
  }

  get RolloverSell(): number {
    return this._rolloverSell;
  }

  get DecimalPlaces(): number {
    return this._symbolSettings.DecimalPlaces;
  }

  get MinVolume(): number {
    if (this.TradingStep !== 0 && this.TradingStep > this._minVolume) {
      return this.TradingStep;
    }
    return this._minVolume;
  }

  get MaxVolume(): number {
    return this._maxVolume;
  }

  get SymbolInfo(): SymbolInfo {
    return this._symbolMetadata.SymbolInfo;
  }

  get CategoryName(): string {
    return this._symbolMetadata.SymbolCategoryName;
  }

  get SpreadPips(): number {
    if (this.LastQuote !== undefined) {
      return (this.LastQuote.Spread * Math.pow(10, this.PipDecimalPlaces));
    }
  }

  get Spread(): number {
    return this.LastQuote.Spread;
  }

  get VolumeDecimalPlaces(): number {
    return this._symbolSettings.VolumeDecimalPlaces;
  }

  get Category(): SymbolCategory {
    return this._symbolMetadata.SymbolCategory;
  }

  public getSymbolInfo(): SymbolInfo {
    return this._symbolMetadata.SymbolInfo;
  }

  public getSymbolIconPath(): string {
    return this._symbolMetadata.SymbolIconPath;
  }

  public setSymbolIconPath(path: string) {
    this._symbolMetadata.SymbolIconPath = path;
  }

  get PercentChange(): number | null {
    return this._percentChange;
  }

  set PercentChange(value: number | null) {
    this._percentChange = value;
  }

  get priceChangeInDay(): number | null {
    return this._priceChangeInDay;
  }

  set priceChangeInDay(value: number | null) {
    this._priceChangeInDay = value;
  }

  get LastPrice(): number | null {
    return this._lastPrice;
  }

  set LastPrice(value: number | null) {
    this._lastPrice = value;
  }

  public get IsSubscribed(): boolean {
    return this._isSubscribed;
  }

  public set IsSubscribed(value: boolean) {
    this._isSubscribed = value;
  }

  public set SubscribeEmitted(value: boolean) {
    this._subscribeEmitted = value;
  }

  public get MinTransactionFee(): number {
    return this._minTransactionFee;
  }

  public get TransactionFee(): number {
    return this._transactionFee;
  }

  private trySubscribe(): void {
    if (this.IsSubscribed) {
      return;
    }

    if (!this._subscribeEmitted) {
      this._subscribeRequestEventEmitter.emit();
      this._subscribeEmitted = true;
    }
  }

  /**
   * @returns Trading step if not zero, otherwise ContractSize/100
   */
  public getInputTradingStep(): number {
    return this._tradingStep === 0 ? this.ContractSize / 100 : this._tradingStep;
  }

  public updateSymbolSettings(symbolSettings: SymbolSettings) {
    this._symbolSettings = symbolSettings;
  }

  public async quoteTick(quoteTick: QuoteTick) {
    if (!quoteTick) {
      throw new Error(`Undefined quote tick (SymbolID: ${this.SymbolId}, SymbolName: ${this.SymbolName})`);
    }

    await this._spreadCorrector.setOriginalQuote(quoteTick.Bid, quoteTick.Ask);

    const quote = await this.createQuoteFromQuoteTick(quoteTick);

    this._lastQuoteInfo.pushNewQuote(quote);

    this._priceChange.emit(quote);
    this.logger.debug('Emit price change');
  }

  public async fullMarketDepth(fullMd: MultiTierQuoteLevel[]) {
    if (!fullMd) {
      throw new Error(`Undefined Full Market Depth (SymbolID: ${this.SymbolId}, SymbolName: ${this.SymbolName})`);
    }

    this._fullMarketDepth = fullMd;

    this._fullMarketDepthChange.emit(fullMd);
  }

  public pipsToPoint(pips: number): number {
    return this._symbolSettings.pipsToPoint(pips);
  }

  private async createQuoteFromQuoteTick(quoteTick: QuoteTick): Promise<Quote> {
    const quote = new Quote(quoteTick.SymbolId,
      this._spreadCorrector.Bid / quoteTick.PriceToPointMul,
      this._spreadCorrector.Ask / quoteTick.PriceToPointMul,
      quoteTick.BidMarketDepth,
      quoteTick.AskMarketDepth,
      quoteTick.ServerTime);

    return quote;
  }
}
