import {Injectable} from '@angular/core';
import {IChartingProviderService} from '@common/charting/models/charting-provider-service-interface';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {IChartProvider} from '@common/charting/models/chart-provider-interface';
import {Symbol} from '@common/symbol/models/symbol';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChartingProviderService implements IChartingProviderService {

  private __priceDirection: PriceDirection;
  private __language: string;
  private __currentSymbolName: string;
  private __chartProvider: IChartProvider;
  private __externalChartingProvider: IChartingProviderService;

  private __onSymbolChanged: SmartEmitter<Symbol> = new SmartEmitter<Symbol>();

  get OnSymbolChanged(): ISmartObserver<Symbol> {
    return this.__onSymbolChanged;
  }

  get ExternalChartingProvider(): IChartingProviderService {
    return this.__externalChartingProvider;
  }
  set ExternalChartingProvider(value: IChartingProviderService) {
    if (!this.__externalChartingProvider) {
      this.__externalChartingProvider = value;
    }
    // TODO: Allow multi chart layout
  }

  constructor(private symbolStorage: SymbolStorageService) { }

  getPriceDirection(): PriceDirection {
    if (this.ExternalChartingProvider) {
      return this.ExternalChartingProvider.getPriceDirection();
    } else {
      return this.__priceDirection;
    }
  }
  setPriceDirection(value: PriceDirection): void {
    if (this.__externalChartingProvider) {
      this.ExternalChartingProvider.setPriceDirection(value);
    } else {
      this.__priceDirection = value;
    }
  }

  getCurrentSymbolName(): string {
    if (this.ExternalChartingProvider) {
      return this.ExternalChartingProvider.getCurrentSymbolName();
    } else {
      return this.__currentSymbolName;
    }
  }
  setCurrentSymbolName(value: string): void {
    if (this.__externalChartingProvider) {
      this.__externalChartingProvider.setCurrentSymbolName(value);
    } else {
      this.__currentSymbolName = value;
    }
    this.symbolStorage.findSymbolByName(value)
      .then((symbol) => this.__onSymbolChanged.emit(symbol));
  }

  getLanguage(): string {
    if (this.ExternalChartingProvider) {
      return this.ExternalChartingProvider.getLanguage();
    } else {
      return this.__language;
    }
  }
  setLanguage(value: string): void {
    if (this.__externalChartingProvider) {
      this.__externalChartingProvider.setLanguage(value);
    } else {
      this.__language = value;
    }
  }

  getChartProvider(): IChartProvider {
    if (this.ExternalChartingProvider) {
      return this.ExternalChartingProvider.getChartProvider();
    } else {
      return this.__chartProvider;
    }
  }
  setChartProvider(value: IChartProvider): void {
    if (this.__externalChartingProvider) {
      this.__externalChartingProvider.setChartProvider(value);
    } else {
      this.__chartProvider = value;
    }
  }

  openChart(symbol: string, resolution?: string, type?: 'bid' | 'ask', callback?: () => void): void {
    if (this.ExternalChartingProvider) {
      this.ExternalChartingProvider.openChart(symbol, resolution, type, callback);
    } else if (this.getChartProvider()) {
      this.getChartProvider().openChart(symbol, resolution, type, callback);
    } else {
      console.error('incorrect chart provider');
    }
  }
}
