import {RolloverCalcBase} from "@common/shared/calculators/Rollover/RolloverCalcBase.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";

export class ByPoints extends RolloverCalcBase {
	
	constructor(symbol: IRolloverCalcSymbol) {
		super(symbol);
	}
        
	
	getRolloverCalcType(): RolloverCalcType {
		return RolloverCalcType.ByPoints;
	}
        
	public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return 0.1 * this.Symbol.getPipSize() * this.Symbol.getRolloverBuy() * Math.abs(unsignedAmount) * this.Symbol.getConversionToUSD();
}

	public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return 0.1 * this.Symbol.getPipSize() * this.Symbol.getRolloverSell() * Math.abs(unsignedAmount) * this.Symbol.getConversionToUSD();
}

	
	
}