import {Symbol} from '../models/symbol';
import {SymbolInfo} from '../models/symbol-info';
import {ISymbolStorage} from '../models/symbol-storage-interface';
import {IInformativeSymbol} from '../models/informative-symbol-interface';
import {Injectable} from '@angular/core';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {JsonProperty} from '@common/shared/utils/json-decorators';
import {ISmartSubscription, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {IObservableStorage} from '@common/storages/i-observable-storage';
import {EntrySpotCommandSender} from '@common/communication/command-sender/entry-spot-command-sender';
import {Environment} from '@common/environment';
import {TradeStorage} from '@common/trade/models/trade-storage';
import {unique} from 'ng-packagr/lib/utils/array';
import {AppConfig} from '@common/configuration/app-config';
import {OperationsWithVolume} from '@common/trade/utils/operations-with-volume';
import { Subscription } from 'rxjs';

export interface ISymbolRepository {
  findSymbolById(id: number): Symbol;
}

@Injectable({
  providedIn: 'root'
})
export class SymbolStorageService implements ISymbolStorage, ISymbolRepository, IObservableStorage<Symbol[]> {
  @JsonProperty(Symbol, true)
  private _traderGroupId: number;
  private _symbols: Symbol[] = [];
  private _internalSymbols: Symbol[] = [];
  private _wasChanged = false;
  private _countCall = 0;
  private _logger = LoggerFactory.getLogger(this);
  private _symbolsChanged = new SmartEmitter<Symbol[]>();
  private _invalidate = new SmartEmitter<void>();
  private _subscribeChangeTrades: Subscription;
  private _symbolsDict: Map<number, Symbol> = new Map<number, Symbol>();

  constructor(private entrySpotCommandSender: EntrySpotCommandSender,
              private readonly tradeStorage: TradeStorage,
              private appConfig: AppConfig) {
  }

  set traderGroupId(value: number) {
    this._traderGroupId = value;
  }

  get TraderGroupId(): number {
    return this._traderGroupId;
  }

  get Symbols(): Symbol[] {
    if (this._countCall < 5) {
      this._countCall ++;
    }
    if (this._countCall === 4) {
      this._symbols = this.getSymbolsArray(Array.from(this._symbolsDict.values()));
    }
    if (this._wasChanged) {
      this._wasChanged = false;
      // this._symbols = Array.from(this._symbolsDict.values());
      this._symbols = this.getSymbolsArray(Array.from(this._symbolsDict.values()));
      this.subscribeChangeTradesArray();

      this._logger.debug('changed symbol cache');
    }

    return this._symbols;
  }

  public getFirstSymbol(): Symbol {
    return this.Symbols[0];
  }

  public subscribeToDataChanges(callback: (symbols: Symbol[]) => void): ISmartSubscription {
    const result = this._symbolsChanged.subscribe(callback);
    callback(this.Symbols);

    return result;
  }

  public onInvalidate(callback: () => void): ISmartSubscription {
    return this._invalidate.subscribe(callback);
  }

  public clearSymbols() {
    this._symbolsDict.clear();
    this._symbols = [];
    this._wasChanged = true;
    this._invalidate.emit();
    this._logger.info('Cleared symbols');
    this._subscribeChangeTrades.unsubscribe();
  }

  public async insertSymbols(tradeGroupId: number, ...symbols: Symbol[]) {

    for (const symbol of symbols) {
      this._symbolsDict.set(symbol.SymbolId, symbol);
    }

    this._logger.debug('Symbol inserted');
    this._wasChanged = true;

    this._symbolsChanged.emit(this.Symbols);

    // получение массива валютных пар с ценой предыдущего дня
    // добавлена проверка какая платформа запущена, для получения дополнительных данных
    if (Environment.Platform === 'cfa-mobile') {
      // запрос на получения данных для таблиц Top Gainers/Losers и данных для графиков на маркет
      await  this.entrySpotCommandSender.requestTopGainersLosers(tradeGroupId, false);
    }

    if (Environment.Platform === 'cfa-mobile' || ( this.appConfig.Settings.showSymbolPercentChangeDay !== undefined && this.appConfig.Settings.showSymbolPercentChangeDay)) {
      this.entrySpotCommandSender.GetSymbolEodStates(tradeGroupId).then((data) => {
        const t: any = Object.values(data);
        const s = Array.from(t[0]);
        this.symbolsPercentChangeInit(s, symbols);
        // дополнительно пробегает по тем символам что нет в торговой группе, чтобы присвоить изменение цен и
        // добавляет булевое значение что их нет в торговой группе для блокировки кнопки торгов
        this.symbolsPercentChangeInit(s, this._internalSymbols);
      });
    }

    this.getTradingSchedule();
  }

  private symbolsPercentChangeInit(arrayAnswer: any[], arraySymbols: Symbol[]) {
    for (const symbol of arraySymbols) {
      arrayAnswer.forEach((e: any) => {
        if (e.symbolId === symbol.SymbolId) {
          symbol.LastPrice = e.lastPrice;
          symbol._currentPrice = e.currentPrice;
          symbol._inGroup = true;

          const priceChangeInPercent = OperationsWithVolume.percentChange(e.lastPrice, e.currentPrice);
          if (isNaN(priceChangeInPercent)) {
            symbol.PercentChange = null;
          } else {
            symbol.PercentChange = priceChangeInPercent;
          }

          const priceChange = OperationsWithVolume.priceChange(e.lastPrice, e.currentPrice);
          if (isNaN(priceChange)) {
            symbol.priceChangeInDay = null;
          } else {
            symbol.priceChangeInDay = priceChange;
          }
        }
      });
    }
  }

  public getTradingSchedule(): void {
    this.entrySpotCommandSender.GetTradingSchedule(this.TraderGroupId).then((data) => {
      const closedMarket = this.setMarketIsClosed();
      if (data['schedules'].length > 0) {
        for (const symbol of this._symbols) {
          // symbol.SymbolInfo.session_holidays = closedMarket;
          data['schedules'].forEach((e: any) => {
            if (e.symbolId === symbol.SymbolId && e.schedule !== null) {
              symbol.SymbolInfo.session_display = e.schedule;
              symbol.SymbolInfo.subsessions = [{
                id: 'regular',
                description: 'Regular Trading Hours',
                session: e.schedule,
              }];
              symbol.SymbolInfo.session_holidays = undefined;
            }
          });
        }
      }
    });
  }

  private setMarketIsClosed(): string {
    const currentDate = new Date().toLocaleString('en-GB', { timeZone: 'UTC' });
    const firstSplit = currentDate.split(',');
    const secondSplit = firstSplit[0].split('/');
    return secondSplit.reverse().join('');
  }

  async getSymbolIdByName(symbolName: string): Promise<number> {
    let symbol: Symbol;

    try {
      symbol = await this.findSymbolByName(symbolName);
    } catch (e) {
      this._logger.error('Cannot find symbol ' + symbolName);
    }

    return symbol.SymbolId;
  }

  public async searchSymbols(searchString: string, exchange: string, symbolType: string): Promise<IInformativeSymbol[]> {
    return new Promise<Symbol[]>((resolve, reject) => {
      const symbolArray = this.Symbols;

      const resultArray: Symbol[] = [];

      for (const i in symbolArray) {
        const currSymbol = symbolArray[i];

        if ((exchange !== '' && currSymbol.SymbolInfo.exchange !== exchange) ||
          (symbolType !== '' && currSymbol.SymbolInfo.type !== symbolType)) {
          continue;
        }

        if (currSymbol.SymbolName.includes(searchString) || currSymbol.SymbolLongName.includes(searchString)) {
          resultArray.push(currSymbol);
        }
      }

      resolve(resultArray);
    });
  }

  public async searchSymbols2(searchString: string, exchange: string, symbolType: string): Promise<Symbol[]> {
    return new Promise<Symbol[]>((resolve, reject) => {
      const symbolArray = this.Symbols;
      const searchStringUpper = searchString.toUpperCase();
      const resultArray: Symbol[] = [];

      for (const i in symbolArray) {
        const currSymbol = symbolArray[i];

        if ((exchange !== '' && currSymbol.SymbolInfo.exchange !== exchange) ||
          (symbolType !== '' && currSymbol.SymbolInfo.type !== symbolType)) {
          continue;
        }

        if (currSymbol.SymbolName.toUpperCase().includes(searchStringUpper) || currSymbol.SymbolLongName.toUpperCase().includes(searchStringUpper)) {
          resultArray.push(currSymbol);
        }
      }

      resolve(resultArray);
    });
  }

  public async resolveSymbol(symbolName: string): Promise<SymbolInfo> {
    const symbol = await this.findSymbolByName(symbolName);

    return symbol.SymbolInfo;
  }

  public async findSymbolByName(name: string): Promise<Symbol> {
    const symbol = this.Symbols.find(value => value.SymbolName.toLowerCase() === name.toLowerCase());

    if (!symbol) {
      this._logger.error('Find failed: No symbol found with name ' + name);
      throw new Error('Find failed: No symbol found with name ' + name);
    }

    return symbol;
  }

  public async findSymbolsByPattern(pattern: string): Promise<Symbol[]> {
    return this.Symbols.filter(symbol => symbol.SymbolName.toLowerCase().replace(/[^a-zа-я0-9]+/g, '').includes(pattern.replace(/[^a-zа-я0-9]+/g, ''))
                || symbol.SymbolLongName.toLowerCase().replace(/[^a-zа-я0-9]+/g, '').includes(pattern.replace(/[^a-zа-я0-9]+/g, '')));
  }

  public findSymbolByNameSync(name: string): Symbol {
    let symbol = this.Symbols.find(value => value.SymbolName.toLowerCase() === name.toLowerCase());
    if (!symbol) {
      symbol = this.getSymbolsIsNotActive(name);
      if (!symbol) {
        this._logger.error('Find failed: No symbol found with name ' + name);
        return undefined;
      }
    }

    return symbol;
  }

  public findSymbolById(symbolId: number): Symbol {
    const symbol = this._symbolsDict.get(symbolId);

    if (!symbol) {
      // если в торговой группе символ не найден идет дополнителная проверка в конфигурационных символах и возврощет если что, то есть
      const symbols = this._internalSymbols.filter((e: Symbol) => e.SymbolId === symbolId);
      if (!symbols) {
        console.error('No internal symbol found with id ' + symbolId);
      } else {
        return symbols[0];
      }
    } else {
      return symbol;
    }
  }

  public findSymbolByIdNoError(symbolId: number): Symbol {
    const symbol = this._symbolsDict.get(symbolId);

    if (!symbol) {
      return undefined;
    } else {
      return symbol;
    }
  }

  public findInternalSymbolByIdNoError(symbolId: number): Symbol {

    for (let i = 0; i < this._internalSymbols.length; i++) {
      if (this._internalSymbols[i].SymbolId === symbolId) {
        return this._internalSymbols[i];
      }
    }

    return undefined;
  }

  public hasSymbolWithId(symbolId: number): boolean {
    const symbol = this._symbolsDict.get(symbolId);
    return symbol !== undefined;
  }

  public hasSymbolWithName(symbolName: string): boolean {
    return this.Symbols.find(s => s.SymbolName === symbolName) !== undefined;
  }

  public get InternalSymbols(): Symbol[] {
    return this._internalSymbols;
  }

  public setInternalSymbols(internalSymbols: Symbol[]) {
    this._internalSymbols = internalSymbols;
  }

  public getSymbolsArray(arraySymbols: Symbol[]): Symbol[] {
    let tempArray: Symbol[] = [];
    if (this.appConfig.Settings.showSymbolsIsActive !== undefined && this.appConfig.Settings.showSymbolsIsActive ) {
      tempArray = arraySymbols.filter(e => e.IsActive === true);
      const arraySymbolsOpenTraderIsNotActive = [];
      this.tradeStorage.OpenedTrades.forEach((e) => {
        if (e.Symbol.IsActive === false) {
          arraySymbolsOpenTraderIsNotActive.push(e.Symbol);
        }
      });
      const unigueSymbolsArray = unique(arraySymbolsOpenTraderIsNotActive);
      unigueSymbolsArray.forEach(e => tempArray.push(e));
    } else {
      tempArray = arraySymbols;
    }
    return tempArray;
  }

  private getSymbolsIsNotActive(symbolName: string): Symbol {
    const tradesWithIsNotActiveSymbol = this.tradeStorage.OpenedTrades.find((e) => e.Symbol.SymbolName.toLowerCase() === symbolName.toLowerCase());
    if (tradesWithIsNotActiveSymbol === undefined) {
      return undefined;
    }
    return tradesWithIsNotActiveSymbol.Symbol;
  }

  private subscribeChangeTradesArray() {
    this._subscribeChangeTrades = this.tradeStorage.changeTradesArray.subscribe(() => {
    this._symbols = this.getSymbolsArray(Array.from(this._symbolsDict.values()));
    });
  }

  public getSymbolTypesList(): string[] {
    const typesMap = new Map<string, string>();

    for (let i = 0; i < this.Symbols.length; i++) {
      typesMap.set(this.Symbols[i].SymbolType, this.Symbols[i].SymbolType);
    }

    const valuesToReturn: string[] = [];

    typesMap.forEach((value, key, map) => {
      valuesToReturn.push(key);
    });

    return  valuesToReturn;
  }


}
