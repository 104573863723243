import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {SharedModule} from '@common/shared/shared.module';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-modal-placeholder',
  templateUrl: './modal-placeholder.component.html',
  styleUrls: ['./modal-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    NgIf
  ]
})
export class ModalPlaceholderComponent implements OnInit, OnDestroy {
  @Input() public header: string;
  @Input() public appName: string;
  @Input() public setChange: boolean;
  @Input() public modalWindowName: string;

  private _closeEvent = new EventEmitter<void>();
  private _clickOutsideEvent = new EventEmitter<void>();
  private _clickOutsideHasSubscriptions: boolean;

  @Output('onClose')
  public get closeEvent(): Observable<void> {
    return this._closeEvent.asObservable();
  }

  @Output('onClickOutside')
  public get clickOutsideEvent(): Observable<void> {
    this._clickOutsideHasSubscriptions = true;
    return this._clickOutsideEvent.asObservable();
  }

  constructor() {
  }

  public close(): void {
    this._closeEvent.emit();
  }

  public clickOutside(): void {
    if (this._clickOutsideHasSubscriptions) {
      this._clickOutsideEvent.emit();
    } else {
      this._closeEvent.emit();
    }
  }

  ngOnInit(): void {
    sessionStorage.setItem('modalWindowIsOpen', JSON.stringify(true));
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('modalWindowIsOpen');
  }

  public listenStatsPage() {
    if (this.header === 'Contest stats') {
      return true;
    } else if (this.header === '比赛统计') {
      return true;
    } else if (this.header === 'Статистика соревнований') {
      return true;
    }
    return false;
  }

  get IsZeTradex(): boolean {
    return this.appName === 'Zetradex' ;
  }

  get IsSetChange(): boolean {
    return this.setChange ? this.setChange : false;
  }

  get IsModalClosePosition(): boolean {
    return this.modalWindowName === 'ClosePosition' || this.modalWindowName === 'ChangePassword' ;
  }

  get IsHiddenCloseButton(): boolean {
    return this.modalWindowName !== 'TradingViewDesktop';
  }

  get IsTradingViewDesktop(): boolean {
    return this.modalWindowName === 'TradingViewDesktop';
  }
}
