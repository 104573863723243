import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../../auth/services/local-storage.service';
import {Translator} from './translator';
import {Locale, LocaleEnum} from './locale';
import {AppConfig} from '../../configuration/app-config';
import {ISmartObserver} from '@common/shared/subscriptions/smart-emitter';

export function translate(key: string) {
  return Translator.Instance.getLocalPhrase(key);
}

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  public get LocaleChanged(): ISmartObserver<void> {
    return Translator.Instance.LocaleChanger;
  }

  public get CurrentLocale(): LocaleEnum {
    return Translator.Instance.CurrentLocale;
  }
  public set CurrentLocale(v: LocaleEnum) {
    Translator.Instance.changeLocale(v);
  }

  public get Locales(): Locale[] {
    return Translator.Instance.Locales;
  }
  public set Locales(v: Locale[]) {
    Translator.Instance.Locales = v;

  }

  constructor(private http: HttpClient, private storage: LocalStorageService) {

  }

  public changeLocale(locale: LocaleEnum): void {
    Translator.Instance.changeLocale(locale);
  }

  public async load() {
    const jsonFile = 'assets/translations/locales.json';
    await this.http.get(jsonFile).toPromise().then((response: Map<string, Map<string, string>>) => {

      const locale = this.getDefaultLocale();

      Translator.init(response, locale);
    });
  }

  private getDefaultLocale(): LocaleEnum {
    if (AppConfig.settings.localisation && AppConfig.settings.localisation.langs) {
      this.Locales = AppConfig.settings.localisation.langs;
    }

    let temp: LocaleEnum | string = this.storage.getLocale();

    if (temp == null) {
      if (AppConfig.settings.localisation && AppConfig.settings.localisation.defaultLang) {
        temp = AppConfig.settings.localisation.defaultLang;
      } else {
        temp = LocaleEnum.English;
      }
    }

    return <LocaleEnum>(temp);
  }

  public getLocalPhrase(key: string): string {
    return Translator.Instance.getLocalPhrase(key);
  }

  public saveLocale(): void {
    this.storage.saveLocale(Translator.Instance.CurrentLocale);
  }
}

