<div class="full-market-depth-container" *ngIf="IsAnySymbolAvailable"
     [class.full-container]="hiddenHeightContainer()"
     [class.z-full-market-depth-container]="IsZeTradex"
     [class.z-full-market-depth-container-is-mobile]="IsMobileVersion">
  <!--<select class="current-symbol" [value]="SymbolName" [title]="SymbolName">{{SymbolName}}</select>-->

  <div class="loading-overlay" [hidden]="!IsLoading">
    <span class="loading-icon"></span>
  </div>

  <app-symbol-header-section *ngIf="IsZeTradex && IsMobileVersion; else notZetradex"
    [hiddenClose]="closeAvailable"
    [availableSymbols]="AvailableSymbols"
    (onClose)="closeFullMarket()"
    [currentSymbol]="Symbol"
    [currentPage]="'Full-Market'"
    (changeCurrentSymbolName)="changeCurrentSymbolName($event)"
    (openChartEvent)="openChartNewSymbol()"></app-symbol-header-section>

  <ng-template #notZetradex>
    <select name="selected-type" id="selected-type" class="c-select form-select-type" [(ngModel)]="CurrentSymbolName">
      <option *ngFor="let symbol of AvailableSymbols" [value]="symbol.SymbolName">{{symbol.SymbolName}}</option>
    </select>

    <div class="right-button close-button icon" (click)="close($event)" [hidden]="!closeAvailable"></div>
    <div class="right-button open-chart icon" (click)="openChart($event)"></div>
  </ng-template>

  <div class="best-level-container">
    <div class="titles" [class.z-full-market-titles]="IsZeTradex">
      <div class="title-bid"><l-unit [key]="'ChartingModule_ChartStrings_OpenBidChartButtonTitle'"></l-unit></div>
      <div class="title-ask"><l-unit [key]="'ChartingModule_ChartStrings_OpenAskChartButtonTitle'"></l-unit></div>
    </div>
    <div class="best-prices">
      <div class="best-bid price" [ngClass]="BidPriceClass">
        <div class="best-base-price">
          {{BidPriceFormatter.Basis}}
        </div>
        <div class="best-end-price">
          <span class="last-two-pips">{{BidPriceFormatter.Last2Pips}}</span>
          <span class="tick">{{BidPriceFormatter.Tick}}</span>
        </div>
      </div>
      <div class="best-ask price" [ngClass]="AskPriceClass">
        <div class="best-base-price">
          {{AskPriceFormatter.Basis}}
        </div>
        <div class="best-end-price">
          <span class="last-two-pips">{{AskPriceFormatter.Last2Pips}}</span>
          <span class="tick">{{AskPriceFormatter.Tick}}</span>
        </div>
      </div>
    </div>
    <div class="best-spread" [class.z-full-market-best-spread]="IsZeTradex">
      {{formatToDecimalPlaces(BestLevel?.Spread)}}
    </div>
    <div class="best-market-depths">
      <div class="bid-md" [class.z-full-market-md]="IsZeTradex">MD: {{this.formatVolume(BestLevel?.BidVolume)}}</div>
      <div class="ask-md" [class.z-full-market-md]="IsZeTradex">MD: {{this.formatVolume(BestLevel?.AskVolume)}}</div>
    </div>
  </div>
  <form name="quotesOrderForm" action="#" class="c-form" style="width: 100%" *ngIf="!getTraderOrGuest()" >
    <div class="c-form-group">
      <label class="c-label">
        <l-unit *ngIf="!Settings.UseLotTrading" [key]="'SymbolModule_SymbolTradeFormComponent_AmountInput'"></l-unit>&nbsp;
        <l-unit *ngIf="Settings.UseLotTrading" [key]="'SymbolModule_SymbolTradeFormComponent_LotInput'"></l-unit>&nbsp;
      </label>
      <app-volume-input class="amount-input" name="amount" [(ngModel)]="Amount"  [decimalPlaces]="DecimalPlaces" [Symbol]="Symbol" [appName]="appName"></app-volume-input>
    </div>
  </form>
  <div class="trade-buttons" *ngIf="!getTraderOrGuest()" [class.z-trade-buttons-full-market]="IsZeTradex" >
    <button [ngClass]="getStylesButton('Sell')" [class.z-button-full-market]="IsZeTradex" (click)="onSellClick($event)">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_SellButton'"></l-unit><app-one-click-trading-icon [appName]="appName"></app-one-click-trading-icon>
    </button>
    <button [ngClass]="getStylesButton('Buy')" [class.z-button-full-market]="IsZeTradex" (click)="onBuyClick($event)">
      <l-unit [key]="'SymbolModule_SymbolTradeFormComponent_BuyButton'"></l-unit><app-one-click-trading-icon [appName]="appName"></app-one-click-trading-icon>
    </button>
  </div>
  <table class="full-market-depth">
    <tr class="headers">
      <th><l-unit [key]="'Quotes_QuotesComponent_Bid'"></l-unit> MD</th>
      <th><l-unit [key]="'Quotes_QuotesComponent_Bid'"></l-unit></th>
      <th><l-unit [key]="'TradeModule_MDComponent_Spread'"></l-unit></th>
      <th><l-unit [key]="'Quotes_QuotesComponent_Ask'"></l-unit></th>
      <th><l-unit [key]="'Quotes_QuotesComponent_Ask'"></l-unit> MD</th>
    </tr>
    <tr class="multi-tier-level" *ngFor="let fullMd of FullMarketDepth">
      <td class="market-depth">{{getBidVolumeForLevelFormatted(fullMd)}}</td>
      <td class="price bid-price" [ngClass]="BidPriceClass">{{getBidForLevelFormatted(fullMd)}}</td>
      <td class="spread">{{getSpreadForLevelFormatted(fullMd)}}</td>
      <td class="price ask-price" [ngClass]="AskPriceClass">{{getAskForLevelFormatted(fullMd)}}</td>
      <td class="market-depth">{{getAskVolumeForLevelFormatted(fullMd)}}</td>
    </tr>
  </table>
</div>
<div class="full-market-depth-container" *ngIf="!IsAnySymbolAvailable">
  <!--<select class="current-symbol" [value]="SymbolName" [title]="SymbolName">{{SymbolName}}</select>-->
  <div class="no-symbols-message">No Symbols Available</div>
</div>
