import {RolloverCalcBase} from "@common/shared/calculators/Rollover/RolloverCalcBase.g";
import {IRolloverCalcSymbol} from "@common/shared/calculators/Rollover/IRolloverCalcSymbol.g";
import {RolloverCalcType} from "@common/shared/calculators/Rollover/RolloverCalcType.g";
import {IRolloverCalc} from "@common/shared/calculators/Rollover/IRolloverCalc.g";

export class TomPoints extends RolloverCalcBase {
	
	constructor(symbol: IRolloverCalcSymbol) {
		super(symbol);
	}
        
	
	getRolloverCalcType(): RolloverCalcType {
		return RolloverCalcType.TomPoints;
	}
        
	public CalculateInternalLong(unsignedAmount: number, openPrice: number): number {
  
  			return -1 * this.Symbol.getRolloverBuy() * openPrice * Math.abs(unsignedAmount) / 1000000;
}

	public CalculateInternalShort(unsignedAmount: number, openPrice: number): number {
  
  			return this.Symbol.getRolloverSell() * openPrice * Math.abs(unsignedAmount) / 1000000;
}

	
	
}