export enum LoginErrorCode {
  LoginFailed = -1,
  InvalidPool = 8,
  IncorrectCredentials = 1,
  SessionExpired = 3,
  InvalidToken = 5,
  TokenExpired= 6,
  RemoteDisconnected,
  // SessionLimitReached = 49,
  SessionLimitReached = 50,
  AccountIsLocked = 50106,
  AccountExpired = 50018,
  CantConnect,
}
