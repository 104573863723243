import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyConfig} from '@common/currency/utils/currency-config';
import {AccountCurrency} from '@common/currency/services/account.currency';

@Pipe({
  name: 'currencySign'
})
export class CurrencySignPipe implements PipeTransform {
  public constructor() {

  }

  transform(value: any): any {
    const result = this.insertToPlaceholder(value.toString());
    return result;
  }

  private insertToPlaceholder(value: string): string {
    const placeholder = CurrencyConfig[AccountCurrency.Instance.ISOCurrencyName.toLowerCase()].pattern as string;

    const result = placeholder.replace('{amount}', value);

    return result;
  }
}
