import {ResolutionString} from '@tradingview/charting_library';

export class SupportedResolutions {
  public static get(): ResolutionString[] {
    return ['5S', '1', '5', '15', '30', '60', '240', '1D', '1W', '1M'] as ResolutionString[];
  }

  public static getAsString(): string[] {
    return ['5S', '1', '5', '15', '30', '60', '240', '1D', '1W', '1M'];
  }
}
