export enum DisconnectionCodes {
  Reset = 'Reset',
  Logout = 'Logout',
  RemoteDisconnect = 'RemoteDisconnected',
  Abort = 'Abort',
  IncorrectCredentials = 'IncorrectCredentials',
  IncorrectToken = 'IncorrectToken',
  AccountIsLocked = 'AccountIsLocked',
  AccountAlreadyInUse = 'AccountAlreadyInUse',
  SessionExpired = 'SessionExpires',
  Reconnect = 'Reconnect',
  ConnectionStopped = 'Connection Stopped',
  GatewayTimeout = 'Gateway Timeout',
  InvalidPool = 'Invalid Pool',
}
