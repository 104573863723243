import {EventEmitter, Injectable} from '@angular/core';
import {Notification, NotificationType, ReadingType} from '@common/notification/models/notification';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';
import {LocalStorageService} from '@common/auth/services/local-storage.service';
import {Trader} from '@common/trader/models/trader';
import {TradeSoundPlayerService} from '@common/player/trade-sound-player.service';
import {AppConfig} from '@common/configuration/app-config';
import {Settings} from '@common/trader/models/settings';
import {TemplatesStorage, TradeNotificationCreationArgs} from '@common/notification/services/notification-provider.service';
import {Trade} from '@common/trade/models/trade';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {NotificationTemplate} from '@common/notification/models/notification-templates';
import {translate, TranslatorService} from '@common/locale/servises/translator.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private storageTemplates: TemplatesStorage;
  private openMessagesSwitcherTab = new SmartEmitter<number>();
  public storage: Notification[] = [];
  private notificationReceived = new SmartEmitter<Notification>();
  private notificationRemoved = new SmartEmitter<Notification>();
  public _notificationEvent: EventEmitter<NotificationTemplate> = new EventEmitter<NotificationTemplate>();
  private countUnreadNotifications = 0;
  private firstEnter = true;
  private countOrdersExecuted = 0;
  private totalOrdersOfClosed = 0;

  public get UnreadCount(): number {
    return this.storage.reduce((acc: number, notification: Notification) => acc + (notification.Unread ? 1 : 0), 0);
  }

  // Do not delete "tradeEventAggregator"! It needed for correctly showing notifications
  constructor(private localStorageService: LocalStorageService,
              private trader: Trader,
              private tradeSoundPlayer: TradeSoundPlayerService,
              private appConfig: AppConfig,
              private localeService: TranslatorService,
              protected settings: Settings) {
  }

  public start() {
    // при отрисовки компонента проверяется локал и если условия выполняются то применяется метод для добавления сообщений в стородж
    if (localStorage.getItem('messagesFromSystems') !== null) {
      if (this.storage.length === 0) {
        this.NotificationMessageLocal();
      } else {
        if (this.storage.find((notification) => notification.From === 'System') === undefined) {
          this.NotificationMessageLocal();
        }
      }
    }
  }

  public translateNotifications(): void {
    this.storage.forEach((item: Notification) => {
      const template = this.getTemplate('TradeNotifications', item.Args.TemplateName);
      item.header = this.composeNotificationString(template.Header, item.Args);
      if (item.Args.KeyTranslate) {
        item.body = translate(`NotificationProvider_TradeValidator_${item.Args.KeyTranslate}`);
      } else {
        item.body = this.composeNotificationString(template.Body, item.Args);
      }

      item.body = this.replacingTemplateWithData(item.body, item.Args.AdditionalDataObj);
      item.text = item.body;
    });

    console.log('this.storage', 23, this.storage);
  }

  public pushTradeNotification(templateName: string, trade?: Trade | TradeDTO, reason?: string, keyTranslate?: string, additionalDataObj?: Object) {

    const template = this.getTemplate('TradeNotifications', templateName);
    const args = this.getTradeNotificationCreationArgs(templateName, trade, reason, keyTranslate, additionalDataObj );
    const notification = this.createNotification(template, args);

    // console.log('templateName', 23, templateName);
    // console.log('template', 23, template);
    // console.log('notification', 23, notification);

    this._notificationEvent.emit(template);
    this.push(notification);
  }

  public set StorageTemplates(response: Map<string, Map<string, NotificationTemplate>>) {
    this.storageTemplates = response;
  }

  private getTradeNotificationCreationArgs(templateName: string, trade?: Trade | TradeDTO, reason?: string, keyTranslate?: string, additionalDataObj?: Object): TradeNotificationCreationArgs {
    const args = {} as TradeNotificationCreationArgs;
    if (trade) {
      if (trade instanceof Trade) {
        args.ClosePrice = trade.ClosePrice;
      }
      args.SymbolName = trade.SymbolName;
      args.TradeId = trade.TradeId;
    }

    args.TemplateName = templateName;
    args.Reason = reason;
    args.KeyTranslate = keyTranslate;
    args.AdditionalDataObj = additionalDataObj;
    return args;
  }

  private getTemplate(bundleName: string, templateName: string): NotificationTemplate {
    return this.storageTemplates.get(bundleName).get(templateName);
  }

  private createNotification(template: NotificationTemplate, args: TradeNotificationCreationArgs, oldNotification?: Notification): Notification {
    const header = this.composeNotificationString(template.Header, args);
    let headerInEnglish: string;
    let body: string;
    let showMessage: string;
    let type: NotificationType;
    let readingStatus: ReadingType;
    let isRead: boolean;
    let time: Date;
    let id: number;
    let from: string;

    if (oldNotification) {
      from = oldNotification['from'];
      headerInEnglish = oldNotification['headerInEnglish'];
      id = oldNotification['id'];
      type = oldNotification['type'];
      readingStatus = oldNotification['readingStatus'];
      isRead = oldNotification['isRead'];
      time = new Date(oldNotification['time']);
    } else {
      headerInEnglish = template.Header['en-us'];
      id = Date.now();
      type = template.Type;
      readingStatus = template.ReadingType;
      isRead = false;
      time = new Date();
    }

    if (args.KeyTranslate) {
      if (args.KeyTranslate === 'CP') {
        from = args.KeyTranslate;
      } else {
        body = translate(`NotificationProvider_TradeValidator_${args.KeyTranslate}`);
        showMessage = translate(`NotificationProvider_TradeValidator_${args.KeyTranslate}`);

        body = this.replacingTemplateWithData(body, args.AdditionalDataObj);
        showMessage = this.replacingTemplateWithData(showMessage, args.AdditionalDataObj);
      }
    } else {
      body = this.composeNotificationString(template.Body, args);
    }

    const notification = new Notification( header, headerInEnglish, body, '', type, readingStatus,  isRead, time, id, args, from);
    notification.ShowMessage = showMessage;

    if (notification.HeaderInEnglish === 'Trade Opened' || notification.HeaderInEnglish === 'Trade Closed' || notification.Type === 'Info') {
      notification.ColorLastMessage = 'green';
    } else if (notification.HeaderInEnglish === 'Could Not Open Trade' || notification.Type === 'Error') {
      notification.ColorLastMessage = 'red';
    }

    if (notification.HeaderInEnglish === 'Could Not Open Trade') {

      if (notification.Args.Reason.includes('Not enough free margin')) {
        notification.ShowMessage = translate(`NotificationProvider_TradeValidator_NotEnoughFreeMarginIsRequired`).replace(`{neededMargin}`, notification.Args.AdditionalDataObj['neededMargin']);
      }

      if (notification.Args.Reason.includes('Margin')) {
        notification.ShowMessage = translate(`NotificationProvider_TradeValidator_InsufficientMargin`);
      }
    }

    if (notification.Args.Reason) {
      if (notification.Args.Reason.includes('Volume doesn\'t match trading step')) {
        const text = translate(`NotificationProvider_TradeValidator_TheVolumeShouldBeAMultipleOfTheStep`);
        notification.body = text;
        notification.text = text;
        notification.Args.KeyTranslate = 'TheVolumeShouldBeAMultipleOfTheStep';
      }

      if (notification.Args.Reason.includes('Invalid Lot Count.')) {
        const text = translate(`NotificationProvider_TradeValidator_InvalidLotCount`);
        notification.body = text;
        notification.text = text;
        notification.Args.KeyTranslate = 'InvalidLotCount';
      }

      if (notification.Args.Reason.includes('Invalid Order Price.')) {
        const text = translate(`NotificationProvider_TradeValidator_InvalidOrderPrice`);
        notification.body = text;
        notification.text = text;
        notification.Args.KeyTranslate = 'InvalidOrderPrice';
      }

      if (notification.Args.Reason.includes('Invalid price delta')) {
        const text = translate(`NotificationProvider_TradeValidator_InvalidPriceDelta`);
        notification.body = text;
        notification.text = text;
        notification.Args.KeyTranslate = 'InvalidPriceDelta';
      }

      if (notification.Args.Reason.includes('Order is already processing')) {
        const text = translate(`NotificationProvider_TradeValidator_OrderIsAlreadyProcessing`);
        notification.body = text;
        notification.text = text;
        notification.Args.KeyTranslate = 'OrderIsAlreadyProcessing';
      }
    }

    return notification;
  }

  private composeNotificationString(dict: any, args: TradeNotificationCreationArgs) {
    const line = dict[this.CurrentLocale];
    return this.replacingTemplateWithData(line, args);
  }

  private replacingTemplateWithData(line: string, args: Object): string {
    if (args !== undefined) {
      Object.keys(args).forEach(key => {
        if (line !== undefined) {
          line = line.replace(new RegExp(`{${key}}`, 'g'), args[key]);
        }
      });
    }
    return line;
  }

  public get CurrentLocale(): string {
    return this.localeService.CurrentLocale;
  }

  public push(notification: Notification) {
    const ignored = this.localStorageService.getIgnoredNotificationIds();

    if (ignored.indexOf(notification.Id) >= 0) {
      return;
    }

    if (notification.From !== 'Admin') {
      if (notification.Header !== 'Challenge result') {
        const m = [];
        if (localStorage.getItem('messagesFromSystems') !== null) {
          const s = (JSON.parse(localStorage.getItem('messagesFromSystems')));
          s.forEach((e: any) => {
            m.push(e);
          });
        }
        m.push({'userName': this.trader.TraderName, 'system': notification});
        localStorage.setItem('messagesFromSystems', JSON.stringify(m));
      }
    } else {
      notification.time = new Date(this.convertTimeMessage(notification));
    }

    if (notification.Header) {
      // проверка на сообщение о испытании, если такое есть то добавляется в сессион стородж для дальнейшего отображения
      const notificationChallenge = notification.Header.split(' ');
      if (notificationChallenge[0] === 'Challenge' || notificationChallenge[0] === 'Unfortunately,') {
        notification.header = translate(`NotificationProvider_TradeValidator_AccountClosed`);
        notification.headerInEnglish = 'Account Closed';
        notification.time = new Date(this.convertTimeMessage(notification));
        console.log('The challenge results were reported', notification);
        if (sessionStorage.getItem('MessageChallenge') == null) {
          sessionStorage.setItem('MessageChallenge', JSON.stringify(notification));
        }
      }
    }

    // как видимо это для кондора показывает количество не прочитанных сообщений без учета сообщений от системы
    if (notification.HeaderInEnglish !== 'Trade Opened' && notification.HeaderInEnglish !== 'Trade Closed') {
      if (notification.isRead === false) {
        this.countUnreadNotifications += 1;
      }
    }

    if (notification.HeaderInEnglish === 'Account Closed') {
      notification.ShowMessage = notification.Header;
    } else {
      notification.ShowMessage = notification.Body;
    }

    this.Notifications.push(notification);

    this.notificationReceived.emit(notification);

    if (this.appConfig.Settings.audioNotificationOfMessages !== undefined && this.appConfig.Settings.audioNotificationOfMessages &&
      (sessionStorage.getItem('disableSoundNotification') == null || this.checkingDeletionOfAllOrders())) {
      this.audioNotification(notification);
    }
  }

  private checkingDeletionOfAllOrders(): boolean {
    if (this.firstEnter) {
      this.totalOrdersOfClosed = Number(JSON.parse(sessionStorage.getItem('disableSoundNotification')));
      this.firstEnter = false;
      this.countOrdersExecuted ++;
    } else if (this.totalOrdersOfClosed !== this.countOrdersExecuted) {
      this.countOrdersExecuted ++;
      if (this.totalOrdersOfClosed === this.countOrdersExecuted) {
        sessionStorage.removeItem('disableSoundNotification');
        this.countOrdersExecuted = 0;
        this.totalOrdersOfClosed = 0;
        this.firstEnter = true;
      }
      return false;
    }

    return true;
  }

  // метод добавляет звуковое уведомление для поступления сообщения
  private audioNotification (notification: Notification) {
    if (notification.Type === 'Error') {
      this.tradeSoundPlayer.errorMessage().then();
    } else {
      this.tradeSoundPlayer.newMessage().then();
    }
  }

  // некоторые сообщения от сервера приходят уже со временем в UTC формате и при их обработке время смещается,
  // данный метод обрабатывает время с учетом временной зоны и возвращает нужную дату и время
  private convertTimeMessage(notification: Notification) {
    const dateTemp = Date.parse(notification.time.toString());
    const localTimeZone = new Date().getTimezoneOffset() * 60 * 1000;
    const result = dateTemp - localTimeZone;
    return result;
  }

  // если есть сообщения от системы добавляются в стородж
  public NotificationMessageLocal() {
    const systems = [];
    const currentData = new Date();
    const messagesFromSystems = JSON.parse(localStorage.getItem('messagesFromSystems'));
    messagesFromSystems.forEach((e: any) => {
      const elapsed = currentData.getTime() - new Date(e.system.time).getTime();
      const month = Math.round((elapsed / 1000 / 60 / 60 / 24));
      if (e.userName === this.trader.TraderName && month < 30) {
        systems.push(e);
      }
    });

    systems.forEach((e) => {
      const template = this.getTemplate('TradeNotifications', e.system.args.TemplateName);
      const notification = this.createNotification(template, e.system.args, e.system);
      this.Notifications.push(notification);
    });
  }

  public clear() {
    this.storage = [];
    this.countUnreadNotifications = 0;
  }

  public removeNotification(notification: Notification) {
    const index = this.storage.indexOf(notification);

    if (index < 0) {
      throw new Error('Undefined notification: ' + JSON.stringify(notification));
    }
    this.storage.splice(index, 1);
    this.notificationRemoved.emit(notification);

    if (notification.From !== 'System') {
      this.localStorageService.addIgnoreNotification(notification);
    }
  }

  public async onRemoveMessage(notification: Notification) {
    if ( notification.From !== 'Admin') {
      const m = JSON.parse(localStorage.getItem('messagesFromSystems'));
      m.forEach((e) => {
        if (e.system.id === notification.id) {
          const s = e;
          const a = m.indexOf(s);
          if (a !== -1) {
            m.splice(a, 1);
          }
        }
      });
      localStorage.removeItem('messagesFromSystems');
      if (m.length > 0) {
        localStorage.setItem('messagesFromSystems', JSON.stringify(m));
      }
    }

    this.removeNotification(notification);
  }

  public messageUrlRegExp(notification: Notification) {

    const regExp = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9\-]*\.?)*\.[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/;
    const text = notification.body.split(' ');
    let textStr = [];
    const linkArray = [];

    if (notification.HeaderInEnglish !== 'Account Closed') {
      text.forEach((e) => {
        if (e.match(regExp)) {
          const link = e.match(regExp).input;
          linkArray.push(link);
        } else {
          textStr.push(e);
        }
      });

    } else {
      textStr = [... text];
    }

    return {
      textStr: textStr,
      linkArray: linkArray,
    };
  }

  public deleteMessageIsArray(id) {
    this.storage.forEach((e) => {
      if (e.id === id) {
        const a = this.storage.indexOf(e);
        if (a !== -1) {
          this.storage.splice(a, 1);
        }
      }
    });
  }

  public readMessage(id: number) {
    this.storage.forEach((e) => {
      if (e.id === id) {
        const a = this.storage.indexOf(e);
        if (a !== -1) {
          this.storage[a].isRead = true;
          this.messageWasRead();
        }
      }
    });

    const messagesFromSystems = JSON.parse(localStorage.getItem('messagesFromSystems'));

    if (messagesFromSystems !== null) {
      messagesFromSystems.forEach((e: any) => {
        if (e.system.id === id) {
          e.system.isRead = true;
        }
      });
      localStorage.setItem('messagesFromSystems', JSON.stringify(messagesFromSystems));
    }
  }

  public get Notifications(): Notification[] {
    return this.storage.sort((a, b) => a.Time < b.Time ? 1 : -1);
  }

  public get NotificationReceived(): ISmartObserver<Notification> {
    return this.notificationReceived;
  }

  public get CountOfSubscribersNotificationRemoved() {
    return this.notificationReceived.CountOfSubscribers;
  }

  public get NotificationRemoved(): ISmartObserver<Notification> {
    return this.notificationRemoved;
  }

  public get OpenMessageSwitcherTab(): SmartEmitter<number> {
    return this.openMessagesSwitcherTab;
  }

  public get CountOfUnreadNotifications(): number {
    return this.countUnreadNotifications;
  }

  public resetUnreadNotifications(): void {
    // this.countUnreadNotifications = 0;
  }

  public messageWasRead(): void {
    if (this.countUnreadNotifications > 0) {
      this.countUnreadNotifications -= 1;
    }
  }

  public getNotificationByID(id: number): Notification {
    return this.Notifications.find((notification) => notification.Id === id);
  }
}
