import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
  selector: '[number-or-special-symbol]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NumberOrSpecialSymbolValidator,
    multi: true
  }]
})
export class NumberOrSpecialSymbolValidator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const check = /(?=.*[^A-Za-z\s\t\n])/.test(control.value);
    return check ? null : {
      haventSpecialSymbolOrNumber : true
    }
  }
}
