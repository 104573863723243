import {RateType} from '@common/currency/models/rate-type';
import {Symbol} from '@common/symbol/models/symbol';
import {Exchanger} from '@common/currency/utils/exchanger';

export class AccountCurrency {
  private static instance: AccountCurrency;

  private static createInstance(): AccountCurrency {
    AccountCurrency.instance = new AccountCurrency();
    return AccountCurrency.instance;
  }

  public static get Instance(): AccountCurrency {
    return AccountCurrency.instance || AccountCurrency.createInstance();
  }

  public ISOCurrencyId: number;
  public ISOCurrencyName: string;
  public RateAsk: number;
  public RateBid: number;
  public RateSymbolId: number;
  public RateType: RateType;
  public DocumentPrecision = 2;
  private convertSymbol: Symbol = undefined;

  private constructor() { }

  public setRateSymbol(v: Symbol) {
    this.convertSymbol = v;
  }

  public exchange(amount: number): number {
    return new Exchanger(this).exchange(amount);
  }

  public reverseExchange(amount: number): number {
    return new Exchanger(this).reverseExchange(amount);
  }

  public get ConvertBid(): number {
    return this.ConvertSymbol ? this.ConvertSymbol.Bid : 1;
  }

  public get ConvertAsk(): number {
    return this.ConvertSymbol ? this.ConvertSymbol.Ask : 1;
  }

  public get ConvertSymbol(): Symbol {
    return this.convertSymbol;
  }
}
