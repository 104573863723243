import { Injectable } from '@angular/core';
import {WatchlistCommandSender} from '@common/communication/command-sender/watchlist-command-sender';
import {IWatchListPromise} from '@common/watchlist/model/watch-list-promice';
import {WatchList} from '@common/watchlist/model/watch-list';
import {Trader} from '@common/trader/models/trader';

@Injectable({
  providedIn: 'root'
})
export class WatchListService {

  constructor(private commandSender: WatchlistCommandSender) { }

  public async createWatchList(watchListName: string, trader: Trader): Promise<number> {
    return this.commandSender.createWatchList(watchListName, trader);
  }

  public async deleteWatchList(watchListID: number): Promise<IWatchListPromise> {
    return this.commandSender.deleteWatchList(watchListID);
  }

  public async getTraderWatchLists(traderID: number): Promise<WatchList[]> {
    return this.commandSender.getTraderWatchLists(traderID);
  }

  public async addSymbolToWatchList(watchListID: number, symbolID: number): Promise<number> {
    return this.commandSender.addSymbolToWatchList(watchListID, symbolID);
  }

  public async removeSymbolFromWatchList(watchListContentID: number):  Promise<IWatchListPromise> {
    return this.commandSender.removeSymbolFromWatchList(watchListContentID);
  }

  public async getTraderSuggestionsWatchList(traderID: number): Promise<WatchList[]> {
    return this.commandSender.getTraderSuggestionsWatchList(traderID);
  }

  public async getTraderWatchListVersion(traderID: number): Promise<number> {
    return this.commandSender.getTraderWatchListVersion(traderID);
  }

  public async updateWatchList(watchListId: number, watchListName: string): Promise<IWatchListPromise> {
    return this.commandSender.updateWatchList(watchListId, watchListName);
  }
}
