import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {TerminalComponent} from './components/terminal/terminal.component';
import {SharedModule} from '@common/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocaleModule} from '@common/locale/locale.module';
import {TradeModule} from '../trade/trade.module';
import {ChartingModule as CommonChatModule} from '@common/charting/charting.module';
import {UserInterfaceModule} from '@desktop-core/user-interface/user-interface.module';
import {ModalModule} from '@desktop-core/modal/modal.module';
import {TestingModule} from '@common/testing/testing.module';
import {IsLoginGuard} from '@common/routing/guards/is-login.guard';
import { ChartModule} from '@desktop-core/terminal/solve/chart-solve';
import {NotificationModule} from '@common/notification/notification.module';
import {QuoteBoardModule} from '@common/quote-board/quote-board.module';
import {CommunicationModule} from '@common/communication/communication.module';
import {environment} from '../../../../../src/environments/environment';
import {DataResolver} from '@common/data-resolver';
import {LogoutGuard} from '@common/routing/guards/logout.guard';
import {AgreementPageComponent} from '@common/shared/agreement/agreement-page/agreement-page.component';
import {PdfPageComponent} from '@common/shared/agreement/pdf-page/pdf-page.component';
import {ErrorSaveService} from '@common/trader/services/error-save.service';
import {ThemeModule, tvCarbonTheme, tvDefaultTheme, tvZetradexDarkTheme, tvZetradexLightTheme, tvDarkTheme, tvLightTheme} from '@common/shared/services/theme';
import {TooltipCustomModule} from '@common/shared/components/tooltips/tooltip.module';
import {CircleProgressBarModule} from '@common/shared/components/circle-progress-bar/circle-progress-bar.module';
import {ProgressBarCompletionModule} from '@common/shared/components/progress-bar-completion/progress-bar-completion.module';
import {ModeDebugUsersModule} from '@common/shared/components/mode-debug-users/mode-debug-users.module';
import {SymbolSearchModule} from '@common/symbol-search/symbol-search.module';
import {FullMarketDepthComponent} from '@common/quote-board/components/full-market-depth/full-market-depth.component';
import {CloseAllPositiveConfirmationDialog} from '@desktop-core/modal/component/modals/close-all-positive-confirmation-dialog/close-all-positive-confirmation-dialog';
import {CloseAllNegativeConfirmationDialog} from '@desktop-core/modal/component/modals/close-all-negative-confirmation-dialog/close-all-negative-confirmation-dialog';


const desktop_routes: Routes = [
  {
    path: 'terminal',
    canDeactivate: [LogoutGuard],
    component: TerminalComponent,
    // @ts-ignore
    loadChildren: () => import('./children-terminal.module').then(m => m.ChildrenTerminalModule),
    resolve: { data: DataResolver },
    canActivateChild: [IsLoginGuard],
    canActivate: [IsLoginGuard] },
  { path: 'agreement', component: AgreementPageComponent},
  { path: 'pdf', component: PdfPageComponent},
  { path: '**', redirectTo: '/terminal'},
];

@NgModule({
  declarations: [
    TerminalComponent
  ],
  imports: [
    LocaleModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ModalModule,
    TradeModule,
    QuoteBoardModule,
    NotificationModule,
    BrowserAnimationsModule,
    UserInterfaceModule,
    CommonChatModule,
    RouterModule.forRoot(desktop_routes),
    ChartModule,
    CommunicationModule,
    environment.name === 'e2e' ? TestingModule : [],
    ThemeModule.forRoot({
      themes: [tvDefaultTheme, tvCarbonTheme, tvDarkTheme, tvLightTheme, tvZetradexDarkTheme, tvZetradexLightTheme],
      active: 'tvDefaultTheme',
    }),
    TooltipCustomModule,
    CircleProgressBarModule,
    ProgressBarCompletionModule,
    ModeDebugUsersModule,
    SymbolSearchModule,
    NgOptimizedImage,
    FullMarketDepthComponent
  ],
  exports: [
    TerminalComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorSaveService
    }
  ]
})
export class TerminalModule { }
