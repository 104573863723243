import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { PlatformSavedDataSettings, PlatformSavedDataSettingsMap,
         PlatformSavedDataSettingsMapAndFavoriteView, SaveViewService } from '@common/shared/services/save-view.service';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {Trader} from '@common/trader/models/trader';

@Component({
  selector: 'lib-saved-views',
  templateUrl: './saved-views.component.html',
  styleUrls: ['./saved-views.component.css']
})
export class SavedViewsComponent implements OnInit, OnDestroy {

  savedViewsData: PlatformSavedDataSettingsMapAndFavoriteView;
  tvLayout: PlatformSavedDataSettingsMap;
  private layoutKeys: string[];
  defaultViewsNames: string[];
  marked: boolean;
  notificationSaveTemplate = true;
  private readonly logger = LoggerFactory.getLogger('SavedViewsComponent');

  public get LayoutKeys(): string[] {
    if (!this.layoutKeys || !this.tvLayout) {
      return [];
    }
    return this.layoutKeys.filter(value => this.tvLayout[value] !== undefined);
  }

  constructor(private router: Router,
              private saveViewService: SaveViewService,
              private changeDetector: ChangeDetectorRef,
              private translatorService: TranslatorService,
              private trader: Trader) {
    this.tvLayout = {};
  }

  async ngOnInit() {
    this.updShowSavedViews();
    await this.saveViewService.initViewList();

    this.savedViewsData = this.saveViewService.SavedViews;
    this.tvLayout = this.savedViewsData.platformSavedDataSettingsMap || {};
    this.layoutKeys = Object.keys(this.tvLayout);

    this.defaultViewsNames = this.savedViewsData.favoritesViews;
    // this.marked = this.defaultViewsNames !== undefined;
    /*if (this.settingsService.Settings.TvLayout !== 'False' && this.settingsService.Settings.TvLayout !== '') {
      this.savedViewsData = JSON.parse(this.settingsService.Settings.TvLayout);
      this.tvLayout = this.savedViewsData.platformSavedDataSettingsMap;
      this.layoutKeys = Object.keys(this.tvLayout);

      this.defaultViewName = this.savedViewsData.favoriteView;
      this.marked = this.defaultViewName !== undefined;
    }*/

    /*setInterval(() => {
      if (this.localStorageService.getSavedViewData() !== null) {
        try {
          this.tvLayout = JSON.parse(this.localStorageService.getSavedViewData()).platformSavedDataSettingsMap;
          this.layoutKeys = Object.keys(this.tvLayout);
        } catch (ex) {
          this.logger.warn('error while parsing layout: ', ex);
        }
      }
    }, 1000);*/
  }

  ngOnDestroy() {
    this.updShowSavedViews().unsubscribe();
  }

  private updShowSavedViews(): ISmartSubscription {
    return this.saveViewService._showNewSavedView.subscribe((data: PlatformSavedDataSettings) => {
      this.updateViewData();
    });
  }

  async openSaveView(event: MouseEvent) {
    event.stopPropagation();
    await this.router.navigate(['/terminal', {outlets: {modal: ['save-view']}}]);
  }

  public async deleteView(key: string) {
    this.tvLayout[key] = undefined;
    delete this.tvLayout[key];
    this.layoutKeys = Object.keys(this.tvLayout);
    await this.saveViewService.deleteView(key);
    // this.updateViewData();
  }

  private updateViewData() {
    this.savedViewsData = this.saveViewService.SavedViews;
    this.tvLayout = this.savedViewsData.platformSavedDataSettingsMap;
    this.layoutKeys = Object.keys(this.tvLayout);
    this.changeDetector.detectChanges();
  }

  public async loadLayout(key: string) {
    await this.saveViewService.loadView(key);
  }

  public openInNewTab(key: string) {
    this.saveViewService.openInNewTab(key, true);
    this.saveViewService.isOpenOnlyOneWindow();
  }

  async openAllViewsInNewTab() {
    this.saveViewService.openAllViewInNewTab(true);
  }

  public openAllHide(): boolean {
    return this.layoutKeys === undefined || this.layoutKeys.length === 0;
  }

  public async reSave(key: string) {
    this.saveViewService.saveView(key).then(() => {
      this.notificationSaveTemplate = false;
      setTimeout(() => {
        this.notificationSaveTemplate = true;
      }, 1000);
    });
  }

  public async changeDefaultViews(e, key) {
    this.marked = e.target.checked;
    if (this.marked === true) {
      await this.saveViewService.setFavorite(key);
    } else {
      await this.saveViewService.deleteFavorite(key);
    }
  }

  public checked(key: string): boolean {
    return this.defaultViewsNames.indexOf(key) !== -1;
  }

  public getSaveButtonText(): string {
    return this.translatorService.getLocalPhrase('UserInterface_SavedViewButtonComponent_save');
  }

  // проверяет, если зашел гость то в разделе отображения скрываются кнопки манипуляции данных, можно только просматривать
  public getTraderOrGuest(): boolean {
    return this.trader.IsReadOnlyAccount;
  }

  // если сообщение слишком большое, то оно обрезается с добавлением ...
  public checkLengthKey(key: string) {
    if (key.length > 17) {
      return key.substr(0, 14) + '...';
    } else {
      return key;
    }
  }
}
