import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ThemeModule} from '@common/shared/services/theme';
import {ModeDebugUsersComponent} from '@common/shared/components/mode-debug-users/mode-debug-users.component';
import {LocaleModule} from '@common/locale/locale.module';
import {SharedModule} from '@common/shared/shared.module';

@NgModule({
  declarations: [
    ModeDebugUsersComponent
  ],
  exports: [
    ModeDebugUsersComponent
  ],
  imports: [
    CommonModule,
    ThemeModule,
    LocaleModule,
    SharedModule
  ]
})

export class ModeDebugUsersModule {}
