
<div class="symbol-header-section-container" [class.symbol-header-section-container-is-mobile]="IsMobileVersion"
     [class.symbol-header-section-container-is-chart]="currentPage === 'Chart-TV' ">
  <div class=" symbol-header-section-menu z-menu icon" ></div>

  <div class="symbol-header-section-body">
    <div class="symbol-header-section-body-symbol" [routerLink]="['/terminal/symbol-search', {symbolId: Symbol.SymbolId, symbolName: Symbol.SymbolName,  to: currentPage}]">
      <div class="symbol-icon symbol-icon-mr">
        <img *ngIf="SymbolIconPath !== undefined; else noImgIcon"  class="symbol-icon-quote" src="{{SymbolIconPath}}">
        <ng-template #noImgIcon>
          <div class="symbol-icon-quote text-icon">
            <span [textContent]="TextIcon"></span>
          </div>
        </ng-template>
      </div>

      <div class="symbol-name symbol-name-witch-icon">
        <app-symbol-name [symbolId]="SymbolId"></app-symbol-name>
      </div>
    </div>

<!--    <div class="symbol-header-section-select" *ngIf="ShowSelectSymbols">-->
<!--      <div class="symbol-name symbol-name-witch-icon symbol-header-section-select-item" *ngFor="let symbol of AvailableSymbols">-->
<!--        <app-symbol-name [symbolId]="symbol.SymbolId" (click)="setCurrentSymbolName(symbol.SymbolName)"></app-symbol-name>-->
<!--      </div>-->
<!--    </div>-->

    <div class="symbol-header-section-body-price" *ngIf="!CurrentPage">

      <div class="symbol-header-section-body-price-price" [ngClass]="PriceClass">
        {{Price}}
      </div>

      <div class="symbol-header-section-body-price-percent">
        <span *ngIf="Symbol.PercentChange > 0" class="z-positive">&#9650;{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange < 0" class="z-negative">&#9660;{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange == 0" class="z-neutral" >{{ getPercentChangeFormatted(Symbol) }}</span>
        <span *ngIf="Symbol.PercentChange == null " > -- </span>
      </div>
    </div>


  </div>

  <div class="symbol-header-section-button-group">
    <div class="z-right-button z-open-chart icon" (click)="openChart($event)" *ngIf="CurrentPage"></div>
    <div class="z-stars icon" [class.z-active]="IsFavorite" (click)="toggleFavorite($event)" *ngIf="!CurrentPage"></div>
    <div class="z-close-button icon" (click)="close($event)" [hidden]="!hiddenClose"></div>
  </div>

</div>

