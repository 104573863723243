import {Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomNumber} from '@common/trade/utils/custom-number';
import {NumberFormatter} from '@common/trade/utils/number-formatter';
import {ISmartObserver, SmartEmitter} from '@common/shared/subscriptions/smart-emitter';


class InputEvent {
  data?: string;
  inputType?: string;
}

class UpdateData {
  formatted?: string;
  number?: number;
  cursorPosition?: number;
}

class DecimalSeparatorSolver {
  private _jumpOverSeparator = new SmartEmitter<'forward' | 'backward'>();
  private _separatorDeleted = new SmartEmitter<void>();
  private _separatorAdded = new SmartEmitter<void>();

  public constructor(private info: DecimalSeparatorInfo) {

  }

  public scanForSeparator(line: string) {
    if (line.indexOf(this.decimalSeparator) != -1) {
      this._separatorAdded.emit();
    }
  }

  public Action(event: InputEvent) {
    if (this.decimalSeparator == event.data) {
      this.separatorRoutine();
    }

    if (this.checkDeleteSeparator(event)) {
      this._separatorDeleted.emit();
    }
  }

  private separatorRoutine() {
    if (this.checkJumpOverSeparator()) {
      this._jumpOverSeparator.emit('forward');
    }

    this.onDoubleSeparator();

    this._separatorAdded.emit();
  }

  private checkJumpOverSeparator(): boolean {
    return this.newValue[this.cursorPosition - 1] == this.decimalSeparator && this.newValue[this.cursorPosition] == this.decimalSeparator;
  }

  private onDoubleSeparator() {
    let count = 0;

    for (let i = 0; i < this.newValue.length; i++) {
      if (this.newValue[i] == this.decimalSeparator) {
        count++;
      }
    }

    if (count > 1) {
      throw new Error();
    }
  }

  private checkDeleteSeparator(type: InputEvent): boolean {
    const flag = (type.inputType == 'deleteContentForward' || type.inputType == 'deleteContentBackward') &&
      this.newValue.indexOf(this.decimalSeparator) == -1 &&
      this.oldValue.indexOf(this.decimalSeparator) != -1;

    return flag;
  }

  public get SeparatorDeleted(): ISmartObserver<void> {
    return this._separatorDeleted;
  }

  public get SeparatorAdded(): ISmartObserver<void> {
    return this._separatorAdded;
  }

  public get JumpOverSeparator(): ISmartObserver<'forward' | 'backward'> {
    return this._jumpOverSeparator;
  }

  private get cursorPosition(): number {
    return this.info.CursorPosition;
  }

  private get decimalSeparator(): string {
    return this.info.decimalSeparator;
  }

  private get oldValue(): string {
    return this.info.oldValue;
  }

  public get newValue(): string {
    return this.info.InputValue;
  }
}

class CursorCalculator {
  public calculate(oldValue: string, newValue: string, oldPosition: number) {
    const diffIdx = this.diffSearch(oldValue, newValue);

    return this.getIndexByDiff(oldValue, newValue, diffIdx, oldPosition);
  }

  private diffSearch(oldValue: string, newValue: string) {
    for (let i = 0; i < oldValue.length; i++) {
      if (i == newValue.length) {
        return i;
      }
      if (oldValue[i] !== newValue[i]) {
        return i;
      }
    }

    return -1;
  }

  private getIndexByDiff(oldValue: string, newValue: string, diffIdx: number, oldPosition: number): number {
    if (diffIdx == 0) {
      return 1;
    }

    const lengthDiff = newValue.length - oldValue.length;

    if (lengthDiff == 0) {
      return oldPosition;
    }

    if (diffIdx == 0 && oldPosition == 1 && lengthDiff < 0) {
      return 0;
    }

    if (lengthDiff == 2) {
      return oldPosition + 1;
    } else if (lengthDiff == 1 || lengthDiff == -1) {
      return oldPosition;
    } else if (lengthDiff == -2) {
      return oldPosition - 1;
    }
  }
}

class FormatSolver {
  private useSeparator: boolean;
  private DSSolver: DecimalSeparatorSolver;

  private converter: NumberFormatter;

  private cursorPositionTemp = null;
  private newValueTemp = null;
  private saveNewValueTemp = null;

  public constructor(private inputResource: InputResource) {
    this.DSSolver = new DecimalSeparatorSolver(inputResource);
    this.DSSolver.SeparatorAdded.subscribe(() => {
      this.useSeparator = true;
      this.cursorPositionTemp = this.InputValue.length;
    });
    this.DSSolver.SeparatorDeleted.subscribe(() => {
      this.useSeparator = false;
      this.newValueTemp = this.newValueTemp.slice(0, this.CursorPosition);
      this.cursorPositionTemp = this.CursorPosition + 1;
    });
    this.DSSolver.JumpOverSeparator.subscribe(() => {
      this.CursorPosition++;
    });

    this.converter = new NumberFormatter(inputResource);
  }

  public byNumber(number: number): string {
    this.newValueTemp = number.toString();

    this.DSSolver.scanForSeparator(this.newValueTemp);

    const newValueTemp = this.reconciliationOfValues(this.newValueTemp, this.saveNewValueTemp );

    this.cleanTemp();

    return this.converter.numberToFormatted(number, true, newValueTemp);
    // return this.converter.numberToFormatted(number);
  }

  private reconciliationOfValues(newValueTemp: string, saveNewValueTemp: string): string {
    if (newValueTemp !== null && saveNewValueTemp !== null) {
      this.saveNewValueTemp = null;
      if (newValueTemp === saveNewValueTemp) {
        return newValueTemp;
      }
      return saveNewValueTemp;
    }
    return newValueTemp;
  }

  public onValueChange(event: InputEvent): UpdateData {

    console.log('event', event);

    if (!this.InputValue) {
      return this.onEmptyLine();
    }

    if (this.InputValue == '-') {
      return this.onMines();
    }

    if (this.InputValue == '.') {
      return this.onPoint();
    }

    if (this.InputValue.startsWith(this.DecimalSeparator)) {
      return this.onStartWithSeparator();
    }

    if (/^\d$/.test(this.InputValue)) {
      this.cursorPositionTemp = 1;
    }
    if (/^\\-\d$/.test(this.InputValue)) {
      this.cursorPositionTemp = 2;
    }

    this.fillTemp();

    this.DSSolver.Action(event);

    const data = this.formatRoutine();

    this.cleanTemp();

    return data;
  }

  private onEmptyLine(): UpdateData {
    this.useSeparator = false;

    return {
      formatted: '',
      number: null,
      cursorPosition: this.cursorPositionTemp
    };
  }

  private onMines(): UpdateData {
    return {
      formatted: '-',
      number: -0,
      cursorPosition: this.cursorPositionTemp
    };
  }

  private onPoint(): UpdateData {
    return {
      formatted: '0.00',
      number: 0,
    };
  }

  private onStartWithSeparator(): UpdateData {
    return {
      formatted: this.InputValue,
      number: null
    };
  }

  private formatRoutine() {
    if (this.OldValue == this.InputValue) {
      return {};
    }

    const cursorPosition = this.CursorPosition;

    const num = this.converter.formattedStringToNumber(this.newValueTemp);

    // Прописал ограничение для суммы ордера т.к. при больших числах они переводятся в степень и записываются в формате 1‑е+20 (к примеру) и
    // не передаются дальше по вот этой таске
    // https://forexdevelopment.myjetbrains.com/youtrack/issue/CONDOR-334/You-cant-Copy-Paste-some-digits-like-e24-and-it-will-breake-display-of-amount

    if (isNaN(num) || num > 100000000000000000) {
      throw new Error();
    }

    const result = new UpdateData();

    const newValueTemp = this.newValueTemp;

    result.formatted = this.converter.numberToFormatted(num, true, newValueTemp);
    // result.formatted = this.converter.numberToFormatted(num);

    result.cursorPosition = this.cursorPositionTemp != null ?
      this.cursorPositionTemp :
      new CursorCalculator().calculate(this.OldValue, result.formatted, cursorPosition);

    result.number = CustomNumber.cutNumber(num, this.DecimalPlaces);

    return result;
  }

  private cleanTemp() {
    this.cursorPositionTemp = null;
    this.newValueTemp = null;
  }

  private fillTemp() {
    this.newValueTemp = this.InputValue;
    this.saveNewValueTemp = this.newValueTemp;
  }

  private get InputValue(): string {
    return this.inputResource.InputValue;
  }

  private get DecimalSeparator(): string {
    return this.inputResource.decimalSeparator;
  }

  private get CursorPosition(): number {
    return this.inputResource.CursorPosition;
  }

  private set CursorPosition(v: number) {
    this.inputResource.CursorPosition = v;
  }

  private get OldValue(): string {
    return this.inputResource.oldValue;
  }

  private get DecimalPlaces(): number {
    return this.inputResource.decimalPlaces;
  }

  public get UseSeparator(): boolean {
    return this.useSeparator;
  }
}

interface DecimalSeparatorInfo {
  decimalSeparator: string;
  CursorPosition: number;
  oldValue: string;
  InputValue: string;
}

interface InputResource extends DecimalSeparatorInfo {
  separator: string;
  decimalPlaces: number;

  InputValue: string;
}

@Directive({
  selector: '[number-mask]'
})
export class NumberMaskDirective implements OnInit, InputResource {
  private _decimalPlaces = undefined;
  private _separator = undefined;
  private _decimalSeparator = undefined;
  private _numberChange = new EventEmitter<number>();

  private numberRegExp: RegExp = undefined;

  public oldValue = '';

  private oldNum = 0;

  private formatter: FormatSolver;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  public set separator(v: string) {
    this._separator = v;
  }

  @Input()
  public set decimalPlaces(v: number) {
    this._decimalPlaces = v;
  }

  @Input()
  public set decimalSeparator(v: string) {
    this._decimalSeparator = v;
  }

  @Input('number')
  public set number(v) {
    if (v == null) {
      return;
    }

    const formatted = this.formatter.byNumber(v);
    this.InputValue = formatted;
    this.oldValue = formatted;
  }

  @Output('number')
  public get numberChange() {
    return this._numberChange.asObservable();
  }

  constructor(public elementRef: ElementRef) {
    this.formatter = new FormatSolver(this);

    // @ts-ignore
    this.Input.oninput = (e) => this.onValueChange(e);
    this.Input.onblur = () => this.onBlur();
  }

  public ngOnInit(): void {
    this.numberRegExp = new RegExp(`^(-?\\d{1,3})(\\${this.separator}\\d{3})*(\\${this.decimalSeparator}\\d+)?$`);

    this.numberChange.subscribe(num => this.oldNum = num);
  }

  private onBlur() {
    if (this.oldNum != null && this.oldNum != 0) {
      if (this.oldNum < this.min) {
        this.number = this.min;
      }
      if (this.oldNum > this.max) {
        this.number = this.max;
      }
    }
  }

  private onValueChange(event) {
    try {
      const data = this.formatter.onValueChange(event);

      if (!data) {
        return;
      }

      if (data.formatted != null) {
        this.InputValue = data.formatted;
        this.oldValue = data.formatted;
      }

      if (data.cursorPosition != null || data.cursorPosition !== undefined) {
        this.CursorPosition = data.cursorPosition;
      }

      if (data.number !== undefined) {
        this._numberChange.emit(data.number);
      }

    } catch (e) {
      this.fixValue();
    }
  }

  private fixValue() {
    const position = this.CursorPosition;

    this.Input.value = this.oldValue;

    this.CursorPosition = position - 1;
  }

  public get separator(): string {
    return this._separator != undefined ? this._separator : ',';
  }

  public get decimalPlaces(): number {
    return this._decimalPlaces != undefined && this.formatter.UseSeparator ? this._decimalPlaces : 0;
  }

  public get decimalSeparator(): string {
    return this._decimalSeparator != undefined ? this._decimalSeparator : '.';
  }

  private get Input(): HTMLInputElement {
    if (this.elementRef.nativeElement.nodeName == 'INPUT') {
      return this.elementRef.nativeElement;
    } else {
      return this.elementRef.nativeElement.querySelector('input');
    }
  }

  public get InputValue(): string {
    return this.Input.value;
  }

  public set InputValue(v: string) {
    this.Input.value = v;
  }

  public get CursorPosition(): number {
    return this.Input.selectionStart;
  }

  public set CursorPosition(v: number) {
    this.Input.focus();
    this.Input.setSelectionRange(v, v);
  }
}
