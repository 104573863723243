import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {DataLoaderService} from '@common/shared/services/data-loader.service';


@Injectable({
  providedIn: 'root'
})
export class DataResolver  {
  private logger = LoggerFactory.getLogger('DataResolver');

  public constructor(private dataLoaderService: DataLoaderService) {

  }

  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    await this.dataLoaderService.doLoad();
  }

}
