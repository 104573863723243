import {Trade} from '@common/trade/models/trade';
import {Symbol} from '@common/symbol/models/symbol';
import {TradeDTO} from '@common/trade/models/trade-d-t-o';
import {TradePriceProvider} from '@common/trade/utils/price-provider/trade-price-provider';
import {ProfitCalculator} from '@common/trade/utils/calculations/profit-calculator';
import {EventEmitter} from '@angular/core';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';


export enum OperationType {
  OpenOrder = 0,
  CloseOrder = 1
}
export class FifoTradeDeal implements  SetComparable<FifoTradeDeal> {
  dealID: number;
  trade: TradeDTO;
  profitCalculator: ProfitCalculator;

  public compare(other: FifoTradeDeal) {
    if (this.dealID === other.dealID) {
      return 0;
    }

    if (this.dealID > other.dealID) {
      return 1;
    }

    if (this.dealID < other.dealID) {
      return -1;
    }
  }

  public equals(other: FifoTradeDeal): boolean {
    if (this.trade.TradeId !== undefined && other.trade.TradeId !== undefined) {
      if (this.trade.TradeId === other.trade.TradeId) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.dealID === other.dealID) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export class FIFONettedTradeOperation {
  constructor (public order: TradeDTO, public operation: OperationType) {
    this.order = order;
    this.operation = operation;
  }

}

export interface SetComparable<T> {
  compare(other: T): number;
  equals(other: T): boolean;
}

export class SortedSet<T extends SetComparable<T>> {
  private list: Array<T> = new Array<T>();

  public get size() {
    return this.list.length;
  }

  forEach(callback: (value: T) => any) {
    for (let i = 0; i < this.list.length; i++) {
      const ret = callback(this.list[i]);
      if (ret === false) {
        return;
      }
    }
  }

  public replace(value: T) {
    const newList: Array<T> = new Array<T>();
    /* moving all the elements which are lesser than inserted element*/
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].equals(value)) {
        this.list[i] = value;
      }
    }
  }

  public remove(value: T) {
    const newList: Array<T> = new Array<T>();
    /* moving all the elements which are lesser than inserted element*/
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].equals(value)) {
        // skip moving object to anther array for the element equals to the deleted one
      } else {
        newList.push(this.list[i]);
      }
    }
    this.list = newList;
  }

  public get(value: T): T {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].equals(value)) {
        return this.list[i];
      }
    }
  }

  public add(value: T): number {
    const newList: Array<T> = new Array<T>();
    let insertedTOIndex: number;

    if (this.list.length === 0) {
      // we're starting with the empty list
      this.list.push(value);
      return 0;
    }

    let newElemAdded: boolean = false;

    /*need to keep the order here trades may come in unpredictable order
    * so we go here through the loop and insert into the new list values lesser that the inserted one
    * next inserted value and then all the values bigger. List supposed to be sorted at the start of this method*/
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].compare(value) < 0) {
        newList.push(this.list[i]);

      } else if (this.list[i].compare(value) === 0) {
        newList.push(value);
        insertedTOIndex = i;
        newElemAdded = true;
      } else {
        if (!newElemAdded) {
          newList.push(value);
          insertedTOIndex = i;
          newElemAdded = true;
        }
        newList.push(this.list[i]);
      }
    }
    /*special case if inserting the biggest value to the end of the list*/
    if (!newElemAdded) {
      newList.push(value);
    }

    this.list = newList;
    return insertedTOIndex;
  }
}

export class FIFONettedTrade {
  get buyTrades(): SortedSet<FifoTradeDeal> {
    return this._buyTrades;
  }

  get sellTrades(): SortedSet<FifoTradeDeal> {
    return this._sellTrades;
  }

  private uplInterval: any = undefined;
  private _lockCount: number = 0;
  private _lockObject: boolean = false;
  /* positive volume = buy, negative = sell*/
  private _positionVolume: number = 0;
  private _positionVolumeUSD: number = 0;
  private _positionVolumeAC: number = 0;
  // private _buyTrades: Set<FifoTradeDeal> = new Set<FifoTradeDeal>();
  // private _sellTrades: Set<FifoTradeDeal> = new Set<FifoTradeDeal>();

  private _buyTrades: SortedSet<FifoTradeDeal> = new SortedSet<FifoTradeDeal>();
  private _sellTrades: SortedSet<FifoTradeDeal> = new SortedSet<FifoTradeDeal>();

  private symbol: Symbol;
  private _averagePrice: number = 0;
  private _positionMarginUsed: number = 0;
  private _totalUPL: number = 0;
  private _profitCalculator: ProfitCalculator;

  private _logger = LoggerFactory.getLogger('FIFONettedTrade');

  constructor () {
    this.uplInterval = setInterval(() => {
      console.log('timer fired');
      this.recalcUPL();
    }, 2000);

  }

  public set Symbol(value: Symbol) {
    this.symbol = value;
  }

  public get Symbol() {
    return this.symbol;
  }

  public get SymbolName() {
    return this.symbol.SymbolName;
  }

  public get PositionMargin() {
    return this._positionMarginUsed;
  }

  public get GainPercent(): number {
    if (this.PositionMargin !== 0) {
      // перенес скобки для получения res, чтоб сначало вычислялась первая половина и после умножалась на 100 (судя по формулам в задаче)
      const res = (this.TotalUpl / this.PositionMargin) * 1000;
      // убрал умножение на 10 в округлении для получения профита в %
      return Math.round(res) / 100;
      // return Math.round(res * 10) / 10;
    } else {
      return 0;
    }
  }

  public lock() {
    // console.log('locking position');
    this._logger.info('locking position');
    this._lockObject = true;
  }

  public unlock() {
    // console.log('UNlocking position');
    this._logger.info('UNlocking position');
    this._lockObject = false;
    this._lockCount = 0;
  }

  public lockWithСount(count: number) {
    // console.log('locking with count: ' + count);
    this._logger.info(`locking with count: ${count}`);
    this._lockObject = true;
    this._lockCount = count;
  }

  private _reduceLockCount() {
    this._lockCount --;
    // console.log('new count: ' + this._lockCount);
    this._logger.info(`new count: ${this._lockCount}`);
    if (this._lockCount === 0) {
      // console.log('UNlocking position by zero count');
      this._logger.info('UNlocking position by zero count');
      this._lockObject = false;
    }
  }

  public get PositionVolume(): number {
    return this._positionVolume;
  }

  public get PositionVolumeUSD(): number {
    return Math.abs(this.goodRoundMoney(this._positionVolumeUSD));

  }

  public get PositionVolumeAC(): number {
    return Math.abs(this.goodRoundMoney(this._positionVolumeAC));
  }

  public get AvgPrice(): number {
    return this.goodRound(this._averagePrice);
  }

  public get TotalUpl(): number {
    return this.goodRoundMoney(this._totalUPL);
  }


  private goodRound(value: number): number {
    return Math.round(value * Math.pow(10, this.symbol.DecimalPlaces)) / Math.pow(10, this.symbol.DecimalPlaces);
  }

  private goodRoundMoney(value: number): number {
    return Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  public getCurrentBuy(trade: Trade, dealID: number) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    const queryObject = new FifoTradeDeal();
    queryObject.trade = tradeDTO;
    queryObject.dealID = dealID;
    return this._buyTrades.get(queryObject);
  }

  public getCurrentSell(trade: Trade, dealID: number) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    const queryObject = new FifoTradeDeal();
    queryObject.trade = tradeDTO;
    queryObject.dealID = dealID;
    return this._sellTrades.get(queryObject);
  }

  public doBuy(order: TradeDTO):  Set<FIFONettedTradeOperation> {
    const setToReturn = new Set<FIFONettedTradeOperation>();

    if (this._sellTrades.size === 0) {
      /*if no sell orders open - just doing buy*/
      const tradeCopyBuy = order.deepCopy();
      setToReturn.add(new FIFONettedTradeOperation(tradeCopyBuy, OperationType.OpenOrder));
    } else {
      /* then we need to look if requested buy volume more than the total sell
      volume we have here - we need just close all sell orders */
      if (Math.abs(order.Volume) >= Math.abs(this._positionVolume)) {
          this._sellTrades.forEach(value => {
            const tradeCopyCloseSell = value.trade.deepCopy();
            setToReturn.add(new FIFONettedTradeOperation(tradeCopyCloseSell, OperationType.CloseOrder));
          });

          const tradeCopyDoBuy = order.deepCopy();
          tradeCopyDoBuy.Volume = order.Volume - Math.abs(this._positionVolume);

          if (Math.abs(order.Volume) > Math.abs(this._positionVolume)) {
            setToReturn.add(new FIFONettedTradeOperation(tradeCopyDoBuy, OperationType.OpenOrder));
          }
      } else {
          let totalVolumeClosed = 0;
          this._sellTrades.forEach(sellTrade => {
            if (order.Volume > totalVolumeClosed) {
              if ( (order.Volume - totalVolumeClosed) > sellTrade.trade.Volume) {
                const sellTradeCopy = sellTrade.trade.deepCopy();
                setToReturn.add(new FIFONettedTradeOperation(sellTradeCopy, OperationType.CloseOrder));
                totalVolumeClosed += sellTrade.trade.Volume;
              } else {
                const sellTradeCopy = sellTrade.trade.deepCopy();
                sellTradeCopy.Volume = order.Volume - totalVolumeClosed;
                totalVolumeClosed += sellTradeCopy.Volume;
                setToReturn.add(new FIFONettedTradeOperation(sellTradeCopy, OperationType.CloseOrder));
              }
            }
          });
      }
    }
    return setToReturn;
  }


  public doSell(order: TradeDTO):  Set<FIFONettedTradeOperation>  {
    const setToReturn = new Set<FIFONettedTradeOperation>();

    if (this._buyTrades.size === 0) {
      /*if no sell orders open - just doing buy*/
      const tradeCopySell = order.deepCopy();
      setToReturn.add(new FIFONettedTradeOperation(tradeCopySell, OperationType.OpenOrder));
    } else {
      /* then we need to look if requested buy volume more than the total sell
      volume we have here - we need just close all sell orders */
      if (Math.abs(order.Volume) >= Math.abs(this._positionVolume)) {
        this._buyTrades.forEach(value => {
          const tradeCopyCloseBuy = value.trade.deepCopy();
          setToReturn.add(new FIFONettedTradeOperation(tradeCopyCloseBuy, OperationType.CloseOrder));
        });

        const tradeCopyDoSell = order.deepCopy();
        tradeCopyDoSell.Volume = order.Volume - Math.abs(this._positionVolume);

        if (Math.abs(order.Volume) > Math.abs(this._positionVolume)) {
          setToReturn.add(new FIFONettedTradeOperation(tradeCopyDoSell, OperationType.OpenOrder));
        }

      } else {
        let totalVolumeClosed = 0;
        this._buyTrades.forEach(buyTrade => {
          if (order.Volume > totalVolumeClosed) {
            if ( (order.Volume - totalVolumeClosed) > buyTrade.trade.Volume) {
              const buyTradeCopy = buyTrade.trade.deepCopy();
              setToReturn.add(new FIFONettedTradeOperation(buyTradeCopy, OperationType.CloseOrder));
              totalVolumeClosed += buyTrade.trade.Volume;
            } else {
              const buyTradeCopy = buyTrade.trade.deepCopy();
              buyTradeCopy.Volume = order.Volume - totalVolumeClosed;
              totalVolumeClosed += buyTradeCopy.Volume;
              setToReturn.add(new FIFONettedTradeOperation(buyTradeCopy, OperationType.CloseOrder));
            }
          }
        });
      }
    }
    return setToReturn;
  }


  public registerBuy(dealID: number) {
    // console.log('registering buy deal id: ' + dealID);
    this._logger.info(`registering buy deal id: ${dealID}`);
    const dealToRegister = new FifoTradeDeal();
    dealToRegister.dealID = dealID;
    this._buyTrades.add(dealToRegister);
    //  need to lock position here if haven't locked yet
    this.recalcPosition();
  }

  public registerSell(dealID: number) {
    // console.log('registering sell deal id: ' + dealID);
    this._logger.info(`registering sell deal id: ${dealID}`);
    const dealToRegister = new FifoTradeDeal();
    dealToRegister.dealID = dealID;
    this._sellTrades.add(dealToRegister);
    //  need to lock position here if haven't locked yet
    this.recalcPosition();
  }

  public applyOpenBuy(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);

    // console.log('applying open buy trade id: ' + trade.TradeId + ' volume: ' + trade.Volume + ' dealID: ' + dealID);
    this._logger.info(`applying open buy trade id: ${trade.TradeId} volume: ${trade.Volume} dealID: ${dealID}`);
    let isAdded = false;
    this._buyTrades.forEach(fifoDeal => {
      if (fifoDeal.dealID === dealID) {
        fifoDeal.trade = tradeDTO;
        isAdded = true;
        fifoDeal.profitCalculator = trade.ProfitCalculator;
      }
    });
    if (!isAdded) {
      const fifoTradeDeal = new FifoTradeDeal();
      fifoTradeDeal.dealID = dealID;
      fifoTradeDeal.trade = tradeDTO;
      fifoTradeDeal.profitCalculator = trade.ProfitCalculator;
      this._buyTrades.add(fifoTradeDeal);
    }
    this._reduceLockCount();
    this.recalcPosition();
    // try to unlock position now
  }

  public applyOpenSell(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    // console.log('applying open Sell trade id: ' + trade.TradeId + ' volume: ' + trade.Volume + ' dealID: ' + dealID);
    this._logger.info(`applying open Sell trade id: ${trade.TradeId} volume: ${trade.Volume} dealID: ${dealID}`);
    let isAdded = false;
    this._sellTrades.forEach(fifoDeal => {
      if (fifoDeal.dealID === dealID) {
        fifoDeal.trade = tradeDTO;
        fifoDeal.profitCalculator = trade.ProfitCalculator;
        isAdded = true;
      }
    });
    if (!isAdded) {
      const fifoTradeDeal = new FifoTradeDeal();
      fifoTradeDeal.dealID = dealID;
      fifoTradeDeal.trade = tradeDTO;
      fifoTradeDeal.profitCalculator = trade.ProfitCalculator;
      this._sellTrades.add(fifoTradeDeal);
    }
    this._reduceLockCount();
    this.recalcPosition();
    // try to unlock position now
  }


  public closeBuy(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    // console.log('closing buy trade id: ' + trade.TradeId + ' volume: ' + trade.Volume);
    this._logger.info(`closing buy trade id: ${trade.TradeId} volume: ${trade.Volume}`);
    const dropEntity = new FifoTradeDeal();
    dropEntity.trade = tradeDTO;
    dropEntity.dealID = dealID;
    dropEntity.profitCalculator = trade.ProfitCalculator;
    this._buyTrades.remove(dropEntity);

    this._reduceLockCount();
    this.recalcPosition();
  }

  public closeSell(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    // console.log('closing sell trade id: ' + trade.TradeId + ' volume: ' + trade.Volume);
    this._logger.info(`closing sell trade id: ${trade.TradeId} volume: ${trade.Volume}`);
    const dropEntity = new FifoTradeDeal();
    dropEntity.trade = tradeDTO;
    dropEntity.dealID = dealID;
    dropEntity.profitCalculator = trade.ProfitCalculator;
    this._sellTrades.remove(dropEntity);

    this._reduceLockCount();
    this.recalcPosition();
  }


  public replaceBuy(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    // console.log('partially close buy trade id: ' + trade.TradeId + ' volume: ' + trade.Volume);
    this._logger.info(`partially close buy trade id: ${trade.TradeId} volume: ${trade.Volume}`);
    const replaceEntity = new FifoTradeDeal();
    replaceEntity.trade = tradeDTO;
    replaceEntity.dealID = dealID;
    replaceEntity.profitCalculator = trade.ProfitCalculator;
    this._buyTrades.replace(replaceEntity);

    this._reduceLockCount();
    this.recalcPosition();
  }

  public replaceSell(dealID: number, trade: Trade) {
    const tradeDTO = new TradeDTO();
    tradeDTO.readFromTrade(trade);
    // console.log('partially close sell trade id: ' + trade.TradeId + ' volume: ' + trade.Volume);
    this._logger.info(`partially close sell trade id: ${trade.TradeId} volume: ${trade.Volume}`);
    const replaceEntity = new FifoTradeDeal();
    replaceEntity.trade = tradeDTO;
    replaceEntity.dealID = dealID;
    replaceEntity.profitCalculator = trade.ProfitCalculator;
    this._sellTrades.replace(replaceEntity);

    this._reduceLockCount();
    this.recalcPosition();
  }

  private recalcUPL() {
    let tempTotalUpl = 0;
    this._buyTrades.forEach(order => {
      if (order.trade !== undefined) {
        const currentPrice = TradePriceProvider.getTradeClosingPrice(order.trade.Symbol, order.trade.Type);
        // TODO check if profit calculator set and get it from the order storage if not
        const uplCalculated = order.profitCalculator.calculateProfit(order.trade.Volume, order.trade.OpenPrice, currentPrice);
        tempTotalUpl += uplCalculated;
      }
    });

    this._sellTrades.forEach(order => {
      if (order.trade !== undefined) {
        const currentPrice = TradePriceProvider.getTradeClosingPrice(order.trade.Symbol, order.trade.Type);
        // TODO check if profit calculator set and get it from the order storage if not
        const uplCalculated = order.profitCalculator.calculateProfit(order.trade.Volume, order.trade.OpenPrice, currentPrice);
        tempTotalUpl += uplCalculated;
      }
    });

    this._totalUPL = tempTotalUpl;
  }

  public recalcPosition() {
    this._positionVolume = 0;

    let buyTotalPriceVolumeMul = 0;
    let sellTotalPriceVolumeMul = 0;
    let positionTotalMargin = 0;

    let buyWtPrice = 0;
    let sellWtPrice = 0;

    let buyTotalVolume = 0;
    let sellTotalVolume = 0;
    let buyTotalVolumeUSD = 0;
    let sellTotalVolumeUSD = 0;
    let buyTotalVolumeAC = 0;
    let sellTotalVolumeAC = 0;


    this._buyTrades.forEach(order => {
      if (order.trade !== undefined) {
        this._positionVolume += order.trade.Volume;
        buyTotalPriceVolumeMul += order.trade.Volume * order.trade.OpenPrice;
        buyTotalVolume += order.trade.Volume;
        buyTotalVolumeUSD += order.trade.VolumeUSD;
        buyTotalVolumeAC += order.trade.VolumeAC;
        positionTotalMargin += order.trade.Margin;

      }
    });
    this._sellTrades.forEach(order => {
      if (order.trade !== undefined) {
        this._positionVolume -= order.trade.Volume;
        sellTotalPriceVolumeMul += order.trade.Volume * order.trade.OpenPrice;
        sellTotalVolume += order.trade.Volume;
        sellTotalVolumeUSD += order.trade.VolumeUSD;
        sellTotalVolumeAC += order.trade.VolumeAC;
        positionTotalMargin += order.trade.Margin;
      }
    });

    buyWtPrice = buyTotalPriceVolumeMul / buyTotalVolume;
    sellWtPrice = sellTotalPriceVolumeMul / sellTotalVolume;

    if (buyTotalVolume > sellTotalVolume) {
      this._averagePrice = buyWtPrice;
    } else if (buyTotalVolume < sellTotalVolume) {
      this._averagePrice = sellWtPrice;
    } else {
      this._averagePrice = 0;
    }

    this._positionMarginUsed = positionTotalMargin;

    this._positionVolumeUSD = buyTotalVolumeUSD - sellTotalVolumeUSD;
    this._positionVolumeAC =  buyTotalVolumeAC - sellTotalVolumeAC;

    this.recalcUPL();
    this.printPosition();
  }

  public printPosition() {
    // console.log('===================================================');
    if (this._buyTrades.size === 0) {
      // console.log('buys set is empty');
      this._logger.info('buys set is empty');
    } else {
      // console.log('buys set:');
      this._logger.info('buys set:');
    }
    this._buyTrades.forEach( trade => {
      if (trade.trade !== undefined) {
        // console.log('dealID: ' + trade.dealID
        //           + ' trade id: ' + trade.trade.TradeId
        //           + ' volume: ' + trade.trade.Volume
        //           + ' margin: ' + trade.trade.Margin
        //           + ' volumeUSD: ' + trade.trade.VolumeUSD
        //           + ' volumeAC: ' + trade.trade.VolumeAC
        // );
        this._logger.info(`dealID: ${trade.dealID}
                                   trade id: ${trade.trade.TradeId}
                                   volume: ${trade.trade.Volume}
                                   margin: ${trade.trade.Margin}
                                   volumeUSD: ${trade.trade.VolumeUSD}
                                   volumeAC: ${trade.trade.VolumeAC}`);
      } else {
        // console.log('dealID: ' + trade.dealID + ' no ticket yet ');
        this._logger.info(`dealID: ${trade.dealID} no ticket yet`);
      }

    });

    if (this._sellTrades.size === 0) {
      // console.log('sells set is empty');
      this._logger.info('sells set is empty');
    } else {
      // console.log('sells set:');
      this._logger.info('sells set:');
    }
    this._sellTrades.forEach( trade => {
      if (trade.trade !== undefined) {
        // console.log('dealID: ' + trade.dealID
        //           + ' trade id: ' + trade.trade.TradeId
        //           + ' volume: ' + trade.trade.Volume
        //           + ' margin: ' + trade.trade.Margin
        //           + ' volumeUSD: ' + trade.trade.VolumeUSD
        //           + ' volumeAC: ' + trade.trade.VolumeAC
        // );
        this._logger.info(`dealID: ${trade.dealID}
                                   trade id: ${trade.trade.TradeId}
                                   volume: ${trade.trade.Volume}
                                   margin: ${trade.trade.Margin}
                                   volumeUSD: ${trade.trade.VolumeUSD}
                                   volumeAC: ${trade.trade.VolumeAC}`);
      } else {
        // console.log('dealID: ' + trade.dealID + ' no trade yet');
        this._logger.info(`dealID: ${trade.dealID} no trade yet`);
      }

    });
    // console.log('---------------------------------------------------');
    // console.log('position net volume: ' + this._positionVolume);
    this._logger.info(`position net volume: ${this._positionVolume}`);
    // console.log('---------------------------------------------------');
    // console.log('position net USD volume: ' + this._positionVolumeUSD);
    this._logger.info(`position net USD volume: ${this._positionVolumeUSD}`);
    // console.log('---------------------------------------------------');
    // console.log('position net AC volume: ' + this._positionVolumeAC);
    this._logger.info(`position net AC volume: ${this._positionVolumeAC}`);
    // console.log('---------------------------------------------------');
    // console.log('position upl: ' + this._totalUPL);
    this._logger.info(`position upl: ${this._totalUPL}`);
    // console.log('---------------------------------------------------');
    // console.log('position margin: ' + this._positionMarginUsed);
    this._logger.info(`position margin: ${this._positionMarginUsed}`);
    // console.log('===================================================');
    // console.log('');
  }


}
