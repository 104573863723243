import {Injectable} from '@angular/core';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {Session} from '@common/auth/models/session';
import {BalanceLoaderService} from '@common/shared/balance-loader/balance-loader-service';

export interface UserInSystems {
  user: string;
  password?: string|null;
  pageReload: boolean;
  staySignedIn: boolean;
  accessKey?: string|null;
  connectUrl?: string|null;
}

@Injectable({
  providedIn: 'root'
})
export class UsersInSystemsService {

  private localStorageKey = 'usersInSystem';
  private _connectUrl = null;

  public constructor(private _balanceLoaderService: BalanceLoaderService) {
  }

  public get ConnectUrl(): string {
    return this._connectUrl;
  }

  public checkSaveConnectUrlForUsers(): void {
    this._connectUrl = this.getConnectionString();
    const pageState = JSON.parse(sessionStorage.getItem('pageReloadEvent'));

    if (pageState !== null) {
      const users = JSON.parse(this.getFromLocalStorage());
      if (users !== null && Array.isArray(users)) {
        const findUserArray: UserInSystems[] = users.filter((value: UserInSystems) => value.user === pageState['traderName']);
        if (findUserArray.length > 0 && findUserArray[0].connectUrl !== undefined) {
          this._connectUrl = findUserArray[0].connectUrl;
        }
      }
    }
  }

  private getConnectionString(): string {
    return this._balanceLoaderService.getConnectionString();
  }

  public saveUserInSystems(login: string, password: string, staySignedIn: boolean, accessKey: string = undefined): void {
    // данные пользователя имя и пароль сохраняются в localStorage, они нужны для валидации при смене пароля
    const users = JSON.parse(this.getFromLocalStorage());

    let currentUser: UserInSystems = {
      user: login,
      pageReload: false,
      staySignedIn: staySignedIn
    };

    if (password !== undefined) {
      currentUser.password = OperationsEncryptDecryptData.encryptText(password);
    }

    if (accessKey && users == null) {
      currentUser.accessKey = accessKey;
      currentUser.connectUrl = this._connectUrl;
    }

    if (users !== null && Array.isArray(users)) {

      let usersArray: UserInSystems[] = [];
      const findUserArray: UserInSystems[] = users.filter(value => value.user === login);

      if (findUserArray.length > 0 && findUserArray[0].password !== undefined) {
        currentUser = findUserArray[0];
      }

      if (findUserArray.length > 0 && findUserArray[0].password === undefined && accessKey !== null) {
        currentUser.accessKey = accessKey;
        currentUser.connectUrl = this._connectUrl;
      }

      usersArray = [...users.filter(value => value.user !== login), currentUser];

      if (this.checkForaTokenOrPassword(currentUser)) {
        this.saveInLocalStorage(JSON.stringify(usersArray));
      }
    } else {
      if (this.checkForaTokenOrPassword(currentUser)) {
        this.saveInLocalStorage(JSON.stringify([currentUser]));
      }
    }
  }

  private checkForaTokenOrPassword(currentUser: UserInSystems) {
    return !!(currentUser.password || currentUser.accessKey);
  }

  public saveUserInSystemsWithToken(token: Session): void {
    if (sessionStorage.getItem('logInUsingLoginLink')) {
      this.saveUserInSystems(token.Login, undefined, true, token.AccessToken);
      sessionStorage.removeItem('logInUsingLoginLink');
    }
  }

  public saveNewPasswordUsers(newPassword: string, traderName: string): void {
    const currentUser = JSON.parse(this.getFromLocalStorage());
    if (currentUser !== null) {
      const index = currentUser.findIndex((el: UserInSystems) => el.user === traderName);
      currentUser[index].password = OperationsEncryptDecryptData.encryptText(newPassword);
      currentUser[index].pageReload = true;

      if (currentUser[index].accessKey) {
        currentUser[index].accessKey = null;
      }

      this.saveInLocalStorage(JSON.stringify(currentUser));
    }
  }


  public clearUsersInSystem(traderName: string): void {
    // при ошибке данные пользователя уделяются из локал стороджа
    const currentUsers = JSON.parse(this.getFromLocalStorage());

    if ( currentUsers !== null) {
      if (currentUsers.length === 1) {
        this.removeFromLocalStorage();
      } else {
        const saveArrayUsers = currentUsers.filter((value: UserInSystems) => value.user !== traderName);
        this.saveInLocalStorage(JSON.stringify(saveArrayUsers));
      }
    }
  }

  public saveInLocalStorage(data: string): void {
    localStorage.setItem(this.localStorageKey, data);
  }

  public getFromLocalStorage(): string|null {
    return localStorage.getItem(this.localStorageKey);
  }

  public removeFromLocalStorage(): void {
    localStorage.removeItem(this.localStorageKey);
  }

}
